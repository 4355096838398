import { useSelector } from 'react-redux';
import {
  selectCrmOrg,
  selectDefaultCreationEnvironment,
} from '../../components/pages/environments/environmentsReducer';
import { selectShouldRefresh } from '../../reducers/global/globalReducer';
import { useCallback, useEffect } from 'react';
import { useFetchGlobal } from './useFetchGlobal';
import useObjectTypes from '../useObjectTypes';
import { useFeatureToggle } from '../../components/common/useFeatureToggle';
import { useParams } from 'react-router';
import useUserInfo from '../useUserInfo';

const useGlobalDataFetcherListener = () => {
  const { crmOrgId: paramsCrmOrgId } = useParams<{ crmOrgId: string }>();
  const { updateDefaultCreationCrmOrgId } = useUserInfo();

  const currentDefaultCreationEnvId = useSelector(selectDefaultCreationEnvironment)?.id;
  const crmOrgId = paramsCrmOrgId ?? currentDefaultCreationEnvId;

  useEffect(() => {
    if (paramsCrmOrgId && paramsCrmOrgId !== currentDefaultCreationEnvId) {
      updateDefaultCreationCrmOrgId(paramsCrmOrgId);
    }
  }, [currentDefaultCreationEnvId, paramsCrmOrgId, updateDefaultCreationCrmOrgId]);

  const crmOrg = useSelector(selectCrmOrg(crmOrgId));

  const shouldRefresh = useSelector(selectShouldRefresh(crmOrg?.id ?? ''));

  const { sfObjectTypes } = useFeatureToggle();
  const { fetchGlobal } = useFetchGlobal();
  const { fetchCrmOrgObjectTypes, fetchCrmOrgSalesforceObjects } = useObjectTypes({
    crmOrgId: crmOrgId ?? '',
  });

  //TODO - consider adding "ObjectTypes" to global response
  const fetchData = useCallback(
    (crmOrgId: string) => {
      fetchGlobal({ crmOrgId });
      fetchCrmOrgObjectTypes(crmOrgId);
      sfObjectTypes && fetchCrmOrgSalesforceObjects(crmOrgId);
    },
    //fetchGlobal has to be in the dep array, or it is called with outdated properties (SWEEP-4670)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetchGlobal],
  );

  //run on mount and every crmOrgId change
  useEffect(() => {
    if (crmOrgId) {
      fetchData(crmOrgId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crmOrgId, fetchData]);

  //run on every shouldRefresh
  useEffect(() => {
    if (crmOrgId && shouldRefresh) {
      fetchData(crmOrgId);
    }
  }, [crmOrgId, shouldRefresh, fetchData]);
};

export default useGlobalDataFetcherListener;
