import useSendBiEvent from '../../hooks/useSendBiEvent';
import { useEffect } from 'react';
import { useAutomationsContext } from './AutomationsContext';

const useSendOpenAutomationEvent = (automation?: Partial<AutomationStructureNew>) => {
  const sendBiEvent = useSendBiEvent();
  const dataByVariant = useAutomationsContext();
  useEffect(() => {
    if (automation) {
      const isEdit = !!automation.automationId;
      const isTemplate = !isEdit && !!automation.automationDetails;

      const eventName = dataByVariant.openEvent;
      const props = {
        type: isEdit ? 'edit' : isTemplate ? 'new_template' : 'new_blank',
      };
      sendBiEvent({ name: eventName, props });
    }
  }, [automation, dataByVariant.openEvent, sendBiEvent]);
};

export default useSendOpenAutomationEvent;
