import * as React from "react";
const SvgUserPlus = (props) => /* @__PURE__ */ React.createElement("svg", { width: 32, height: 32, viewBox: "0 0 32 32", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M4 28C5.16398 26.5904 6.61767 25.457 8.2589 24.6794C9.90014 23.9017 11.6891 23.4987 13.5 23.4987C15.3109 23.4987 17.0999 23.9017 18.7411 24.6794C20.3823 25.457 21.836 26.5904 23 28M20.1434 16.75C20.1434 20.4779 17.169 23.5 13.5 23.5C9.83097 23.5 6.85664 20.4779 6.85664 16.75C6.85664 13.0221 9.83097 10 13.5 10C17.169 10 20.1434 13.0221 20.1434 16.75Z", stroke: "#4B4B4B", style: {
  stroke: "#4B4B4B",
  stroke: "color(display-p3 0.2941 0.2941 0.2941)",
  strokeOpacity: 1
}, strokeWidth: 1.5, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M25 12.25C27.8995 12.25 30.25 9.8995 30.25 7C30.25 4.1005 27.8995 1.75 25 1.75C22.1005 1.75 19.75 4.1005 19.75 7C19.75 9.8995 22.1005 12.25 25 12.25Z", fill: "white", style: {
  fill: "white",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M25 3.9375V7H28.0625", fill: "white", style: {
  fill: "white",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M25 3.9375V7H28.0625M30.25 7C30.25 9.89949 27.8995 12.25 25 12.25C22.1005 12.25 19.75 9.89949 19.75 7C19.75 4.1005 22.1005 1.75 25 1.75C27.8995 1.75 30.25 4.1005 30.25 7Z", stroke: "#4B4B4B", style: {
  stroke: "#4B4B4B",
  stroke: "color(display-p3 0.2941 0.2941 0.2941)",
  strokeOpacity: 1
}, strokeWidth: 1.5, strokeLinecap: "round", strokeLinejoin: "round" }));
export default SvgUserPlus;
