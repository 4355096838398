export const SweepFullLogo = () => {
  return (
    <svg
      width="125"
      height="32"
      viewBox="0 0 125 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="16.6226"
        y="10.5195"
        width="3.98936"
        height="3.98936"
        transform="rotate(45 16.6226 10.5195)"
        fill="#F8F9FA"
      />
      <rect
        x="13.8022"
        y="13.341"
        width="3.98936"
        height="18.6154"
        transform="rotate(45 13.8022 13.341)"
        fill="#2C1A4F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6278 2.03723H18.6172V8.52487L23.2046 3.93744L26.0255 6.75834L21.4381 11.3457H27.9257V15.3351H21.4382L26.0256 19.9225L23.2047 22.7434L18.6172 18.1559V24.6436H14.6278V18.1576L16.6235 16.1619L16.6228 16.1611L19.4435 13.3404L16.6226 10.5195L13.8017 13.3404L13.8025 13.3412L11.8085 15.3351H5.31934V11.3457L11.807 11.3457L7.21954 6.75828L10.0404 3.93738L14.6278 8.52478V2.03723Z"
        fill="#FED044"
      />
      <path
        d="M31.0832 20.8999V24.6466H40.0845C44.8343 24.6466 48.1226 22.2888 48.1226 17.8315C48.1226 14.86 46.5615 11.2965 40.782 11.2965H38.0584C35.9326 11.2965 34.8033 10.1337 34.8033 8.55104C34.8033 6.96839 36.0323 5.78393 38.158 5.78393H46.5615V2.03723H38.3905C33.9397 2.03723 30.585 4.64285 30.585 8.74484C30.585 11.8779 32.5447 15.3016 37.992 15.3016H40.4167C42.9078 15.3016 43.871 16.4104 43.871 17.9284C43.871 19.6726 42.7085 20.8999 40.2174 20.8999H31.0832Z"
        fill="#2C1A4F"
      />
      <path
        d="M52.5921 24.6466H57.2755L60.4641 13.2127L63.6196 24.6466H68.3029L73.1523 8.88465H69.0336L65.8782 20.3509L62.7228 8.88465H58.1723L55.0168 20.3509L51.8614 8.88465H47.7427L52.5921 24.6466Z"
        fill="#2C1A4F"
      />
      <path
        d="M107.935 29.2713H111.887V23.0963C112.884 24.3882 114.678 25.1311 116.77 25.1311C121.387 25.1311 124.542 21.772 124.542 16.7656C124.542 11.7593 121.387 8.40017 116.77 8.40017C114.445 8.40017 112.452 9.27224 111.422 10.758V8.88465H107.935V29.2713ZM111.821 16.7656C111.821 13.8587 113.548 11.8885 116.139 11.8885C118.697 11.8885 120.457 13.8587 120.457 16.7656C120.457 19.6726 118.697 21.6428 116.139 21.6428C113.548 21.6428 111.821 19.6726 111.821 16.7656Z"
        fill="#2C1A4F"
      />
      <path
        d="M81.4253 25.1295C85.365 25.1295 87.848 23.1336 88.8412 20.0752H84.8022C84.2725 21.2019 83.1469 21.9102 81.4585 21.9102C78.9424 21.9102 77.3863 20.4937 77.1877 18.1114H89.0399C89.1061 17.4675 89.1061 16.9524 89.1061 16.4695C89.1061 11.5439 86.1265 8.45332 81.2267 8.45332C76.5587 8.45332 73.3142 11.6727 73.3142 16.6949C73.3142 21.7814 76.5256 25.1295 81.4253 25.1295ZM77.1877 15.1496C77.3201 12.9926 78.843 11.5439 81.2267 11.5439C83.7428 11.5439 85.1333 12.9926 85.1333 15.053V15.1496H77.1877Z"
        fill="#2C1A4F"
      />
      <path
        d="M98.4649 25.1295C102.405 25.1295 104.888 23.1336 105.881 20.0752H101.842C101.312 21.2019 100.186 21.9102 98.498 21.9102C95.9819 21.9102 94.4259 20.4937 94.2272 18.1114H106.079C106.146 17.4675 106.146 16.9524 106.146 16.4695C106.146 11.5439 103.166 8.45332 98.2662 8.45332C93.5982 8.45332 90.3538 11.6727 90.3538 16.6949C90.3538 21.7814 93.5651 25.1295 98.4649 25.1295ZM94.2272 15.1496C94.3596 12.9926 95.8826 11.5439 98.2662 11.5439C100.782 11.5439 102.173 12.9926 102.173 15.053V15.1496H94.2272Z"
        fill="#2C1A4F"
      />
    </svg>
  );
};
