import { MenuItem, SxProps, Divider, ListSubheader } from '@mui/material';
import { Typography, colors } from '@sweep-io/sweep-design';
import { useSelector } from 'react-redux';
import { selectUserInfoData } from '../../../reducers/userInfoReducer';
import {
  ShareNetwork as ShareNetworkIcon,
  Integrations as IntegrationsIcon,
  SignOut as SignOutIcon,
  User as UserIcon,
} from '@sweep-io/sweep-design/dist/icons';
import { AvatarPicture } from '../../avatar/AvatarPicture';
import useLogout from '../../../auth/useLogout';
import { UserInfo } from '../../../types/UserInfoTypes';
import { getIsUserInactive } from '../../../lib/getIsUserActive';

const MenuItemsUserDeprecated = ({
  openMyProfileDialog,
  openOrgDialog,
  closeMenu,
  openIntegrationsDialog,
}: {
  openMyProfileDialog: () => void;
  openOrgDialog: () => void;
  closeMenu: () => void;
  openIntegrationsDialog: () => void;
}) => {
  const { logout } = useLogout();
  const userInfo = useSelector(selectUserInfoData);

  return (
    <>
      {userInfo?.email && (
        <UserMenuItem
          text={userInfo.email}
          user={userInfo}
          MenuItemPropsSx={{ '&:hover': { background: 'transparent', cursor: 'default' } }}
        />
      )}
      <Divider />

      <ListSubheader sx={{ padding: '8px 12px', marginBottom: '8px', lineHeight: '20px' }}>
        <Typography variant="caption-bold" color={colors.grey[500]}>
          Account settings
        </Typography>
      </ListSubheader>

      <UserMenuItem
        text="My profile"
        callback={() => {
          closeMenu();
          openMyProfileDialog();
        }}
        IconComponent={UserIcon}
      />

      <UserMenuItem
        text="Workspace"
        callback={() => {
          closeMenu();
          openOrgDialog();
        }}
        IconComponent={ShareNetworkIcon}
      />

      <ListSubheader sx={{ padding: '8px 12px', marginBottom: '8px', lineHeight: '20px' }}>
        <Typography variant="caption-bold" color={colors.grey[500]}>
          App settings
        </Typography>
      </ListSubheader>

      <UserMenuItem
        text="Integrations"
        callback={() => {
          closeMenu();
          openIntegrationsDialog();
        }}
        IconComponent={IntegrationsIcon}
      />

      <UserMenuItem
        text="Log Out"
        MenuItemPropsSx={{
          color: colors.blush[600],
          '& svg path': {
            stroke: colors.blush[600],
          },
        }}
        callback={() => logout()}
        IconComponent={SignOutIcon}
      />
    </>
  );
};

const UserMenuItem = ({
  callback,
  text,
  MenuItemPropsSx,
  IconComponent,
  user,
}: {
  callback?: () => void;
  text: string;
  MenuItemPropsSx?: SxProps;
  IconComponent?: any;
  user?: UserInfo;
  isError?: boolean;
}) => {
  return (
    <MenuItem
      onClick={callback}
      sx={{ display: 'flex', alignItems: 'center', gap: '8px', ...MenuItemPropsSx }}
    >
      {user && (
        <AvatarPicture
          avatar={{ emoji: user.emoji, imageUrl: user.imageUrl }}
          clickable={false}
          dimension={28}
          isInactive={getIsUserInactive(user?.status)}
        />
      )}
      {IconComponent && <IconComponent width="15px" />}
      <Typography variant="body">{text}</Typography>
    </MenuItem>
  );
};

export { MenuItemsUserDeprecated };
