import { useConfigurationCanvas } from '../configuration-canvas/public/useConfigurationCanvas';
import { useDispatch, useSelector } from 'react-redux';
import useAutomationsCanvasInteractions from './useAutomationsCanvasInteractions';
import { useFeatureToggle } from '../../common/useFeatureToggle';
import { TabList } from '@sweep-io/sweep-design';
import { useCallback, useMemo, useState } from 'react';
import Alerts from '../../alerts/Alerts';
import {
  selectLeftPanelFilters,
  setLeftPanelFiltersValues,
} from '../../../reducers/leftPanelReducer';
import {
  ConfigurationCanvasLeftPanel,
  ConfigurationCanvasPanelContent,
  ConfigurationCanvasPanelHeader,
} from '../configuration-canvas-panel';
import ScheduledReports from '../../alerts/ScheduledReports';
import { MultipleRuleBuildersProvider } from '../../common/sweep-condition-rule-builder/MultipleRuleBuildersContext';
import {
  selectSlackIntegrationStatus,
  selectSlackUpgradeStatus,
  SlackConnectionStatus,
} from '../../../reducers/integrationsReducer';
import { useIntegrations } from '../../../hooks/useIntegrations';
import { useRunOnce } from '../../common/useRunOnce';
import { Box } from '@mui/material';

enum AlertsDialogTabTypes {
  ALERTS = 'Alerts',
  SCHEDULED_REPORTS = 'Scheduled reports',
}

export const CanvasAlertsPage = () => {
  const { slackReports } = useFeatureToggle();
  const [activeTab, setActiveTab] = useState(AlertsDialogTabTypes.ALERTS);

  const { canvasFunnelMap } = useConfigurationCanvas();
  const { funnelsData, recordTypesData } = canvasFunnelMap;
  const { getSlackStatus } = useIntegrations();
  const selectedFilters = useSelector(selectLeftPanelFilters);

  const slackStatus = useSelector(selectSlackIntegrationStatus);
  const shouldUpgradeSlack = useSelector(selectSlackUpgradeStatus);
  const withScheduledReports =
    slackReports && slackStatus === SlackConnectionStatus.Connected && !shouldUpgradeSlack;

  useRunOnce(() => {
    getSlackStatus();
  });

  const dispatch = useDispatch();
  const { setHoveredItem } = useAutomationsCanvasInteractions();

  const setSelectedFilters = useCallback(
    (filters: Partial<AutomationDialogFilters>) => {
      dispatch(setLeftPanelFiltersValues(filters));
    },
    [dispatch],
  );

  const tabList = useMemo(
    () => [
      {
        content: (
          <Alerts
            funnelsData={funnelsData}
            recordTypesData={recordTypesData}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            setHoveredItem={setHoveredItem}
          />
        ),
        label: AlertsDialogTabTypes.ALERTS,
        value: AlertsDialogTabTypes.ALERTS,
      },
      {
        content: (
          <ScheduledReports
            funnelsData={funnelsData}
            recordTypesData={recordTypesData}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            setHoveredItem={setHoveredItem}
          />
        ),
        label: AlertsDialogTabTypes.SCHEDULED_REPORTS,
        value: AlertsDialogTabTypes.SCHEDULED_REPORTS,
      },
    ],
    [setSelectedFilters, funnelsData, recordTypesData, selectedFilters, setHoveredItem],
  );

  return (
    <ConfigurationCanvasLeftPanel>
      <ConfigurationCanvasPanelHeader>Alerts</ConfigurationCanvasPanelHeader>
      <MultipleRuleBuildersProvider>
        <ConfigurationCanvasPanelContent>
          {/* by product definition we should display tabs only when more than one tab is visible */}
          <Box
            sx={{
              '& .MuiTabs-root': {
                display: withScheduledReports ? 'block' : 'none',
              },
            }}
          >
            <TabList
              value={activeTab}
              list={tabList}
              onChange={(event, tab: AlertsDialogTabTypes) => {
                setActiveTab(tab);
              }}
            />
          </Box>
        </ConfigurationCanvasPanelContent>
      </MultipleRuleBuildersProvider>
    </ConfigurationCanvasLeftPanel>
  );
};
