import { useDispatch } from 'react-redux';
import {
  Campaign,
  CampaignBase,
  useCampaignsApiFacade,
} from '../../../../../apis/facades/useCampaignsApiFacade';
import { addCampaign } from '../../../../../reducers/hubspotReducer';

export const useCampaigns = () => {
  const dispatch = useDispatch();
  const { create_campaign } = useCampaignsApiFacade();

  const createCampaign = async (campaign: CampaignBase): Promise<Campaign> => {
    const _campaign = await create_campaign(campaign);
    dispatch(addCampaign(_campaign));
    return _campaign;
  };

  return {
    createCampaign,
  };
};
