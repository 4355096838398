export type CommentCreateRequest = {
  funnelMapId: string;
  positionX: number;
  positionY: number;
  commentBody: string;
  mentionedUserIds: MentionedUser[];
};

export type CommentThreadUpdateRequest = {
  id: string;
  funnelMapId: string;
  positionX?: number;
  positionY?: number;
  isResolved?: boolean;
  isRead?: boolean;
};

export type CommentThreadProps = {
  id: string;
  funnelMapId: string;
  positionX: number;
  positionY: number;
  isRead?: boolean;
  isResolved: boolean;
  commentReplies: CommentReplyProps[];
  createdById: string;
  createdAt: string;
};

export type CommentReplyProps = {
  id?: string; //for the optimistic reducer, we don't have it yet
  transientId?: string; //for matching the reply coming from WS with the one the already exists in the optimistic reducer
  commentBody: string;
  mentionedUserIds: MentionedUser[];
  createdById: string;
  createdAt?: string; //for the optimistic reducer, we don't have it yet
  updatedAt?: string; //for the optimistic reducer, we don't have it yet
};

export type CommentReplyCreateRequest = {
  commentBody: string;
  commentThreadId: string;
  funnelMapId: string;
  userId: string;
  mentionedUserIds: MentionedUser[];
  isRead?: boolean;
};

export type CommentReplyDeleteRequest = {
  commentReplyId: string;
  commentThreadId: string;
  funnelMapId: string;
};

export type ReplyEditRequest = {
  commentReplyId: string;
  commentThreadId: string;
  funnelMapId: string;
  commentBody: string;
  mentionedUserIds: MentionedUser[];
};

export type MentionedUser = string;

//global filter options
export type FilterSortState = {
  filterBy: FilterByOptions;
  sortBy: SortByOptions;
  searchTerm: string;
};

export enum SortByOptions {
  SORT_BY_DATE = 'Sort by date',
  SORT_BY_UNREAD = 'Sort by unread',
}

export enum FilterByOptions {
  SHOW_ALL = 'All comments',
  ONLY_UNREAD = 'Only unread comments',
  ONLY_YOURS = 'Only your mentions and replies',
  ONLY_RESOLVED = 'Only resolved comments',
}
