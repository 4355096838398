import { EnvironmentTypes } from './EnvironmentTypeEnum';

export const envDescriptions = {
  [EnvironmentTypes.Production]: (
    <>
      Production (live) environment that stores business critical data and has active users that
      work in Salesforce such as Sales, Marketing etc.
      <br />
      <br />
      Production environment needs to be connected to Sweep in order to:
      <ul>
        <li>Deploy funnels for production</li>
        <li>Utilize Sweep assignment</li>
        <li>Utilize Sweep reporting</li>
      </ul>
    </>
  ),
  [EnvironmentTypes.Development]: (
    <>
      Development Environments can be used to build any configuration, and test Salesforce
      functionalities.
      <br />
      <br />
      Gives you the ability to run tests that you don’t want to perform in your Production or
      Sandbox environments,
    </>
  ),
  [EnvironmentTypes.Sandbox]: (
    <>
      Sandbox is a copy of your production in a separate environment that you can use for a variety
      of purposes, such as testing and training.
      <br />
      <br />
      You can have multiple sandboxes in your organization for different purposes and team members.
    </>
  ),
};
