import { Box } from '@mui/material';
import { AutomationAction } from './AutomationAction';
import { labelBoxDesign } from './AutomationsForm';
import { Typography, colors } from '@sweep-io/sweep-design';
import { AutomationActionPopupMenu } from './AutomationActionPopupMenu';
import { AutomationActionType } from '../../types/enums/AutomationActionType';
import { useCallback } from 'react';

interface AutomationsActionsWrapperProps {
  readonly?: boolean;
  crmOrgId: string;
  objectName: string;
  onChange: (action: Partial<AutomationAction>, idx?: number) => any;
  objectTypes?: ObjectTypeNameAndTypes[];
  actionList: DeepPartial<AutomationAction>[];
  excludeValueType?: (type: SelectorValueTypes) => boolean;
  isPlaybookAlerts?: boolean;
  isActionEnabled: boolean;
  isScheduledAssignment?: boolean;
  onClearAction: (idx: number) => void;
  onAddAction: (emptyAction: Partial<AutomationAction>, isBefore: boolean, idx: number) => void;
  isHideActionSelector: boolean;
  isNestedVerifiedUrl?: string;
}

export const AutomationsActionsWrapper = ({
  isHideActionSelector,
  onAddAction,
  onClearAction,
  isScheduledAssignment,
  onChange,
  objectName,
  readonly,
  actionList,
  excludeValueType,
  isPlaybookAlerts,
  objectTypes,
  crmOrgId,
  isActionEnabled,
  isNestedVerifiedUrl,
}: AutomationsActionsWrapperProps) => {
  const _onChange = useCallback((_action: any, idx: number) => onChange(_action, idx), [onChange]);
  return (
    <>
      {actionList?.length === 0 && (
        <AutomationAction
          isNestedVerifiedUrl={isNestedVerifiedUrl}
          excludeValueType={excludeValueType}
          isPlaybookAlerts={isPlaybookAlerts}
          objectTypes={objectTypes}
          isActionEnabled={isActionEnabled}
          readonly={readonly}
          crmOrgId={crmOrgId}
          objectName={objectName}
          onChange={onChange}
        />
      )}
      {actionList?.length > 0 &&
        actionList?.map((action, idx: number) => (
          <Box key={action?._id}>
            {idx !== 0 && (
              <>
                <Box sx={{ display: 'flex', gap: '8px', marginTop: '16px' }}>
                  <Box
                    sx={{
                      ...labelBoxDesign,
                      background: colors.gum[500],
                    }}
                  >
                    <Typography variant="caption-bold" textAlign="center" color={colors.white}>
                      AND THEN
                    </Typography>
                  </Box>
                  {!isPlaybookAlerts && !isScheduledAssignment && (
                    <AutomationActionPopupMenu
                      action={actionList && actionList[idx]}
                      readonly={readonly}
                      onClearAction={() => onClearAction(idx)}
                      onAddAction={(emptyAction, isBefore) =>
                        onAddAction(emptyAction, isBefore, idx)
                      }
                      isClearable={
                        actionList &&
                        actionList[idx]?.actionType !== AutomationActionType.AssignmentRules
                      }
                    />
                  )}
                </Box>
              </>
            )}

            <AutomationAction
              isNestedVerifiedUrl={isNestedVerifiedUrl}
              isHideActionSelector={isHideActionSelector}
              excludeValueType={excludeValueType}
              isPlaybookAlerts={isPlaybookAlerts}
              objectTypes={objectTypes}
              isActionEnabled={isActionEnabled}
              readonly={readonly}
              action={actionList?.[idx]}
              objectName={objectName}
              crmOrgId={crmOrgId}
              onChange={(action) => _onChange(action, idx)}
            />
          </Box>
        ))}
    </>
  );
};
