import { colors, Typography } from '@sweep-io/sweep-design';
import { DotDivider } from '../../../common/DotDivider';
import { CenteredRowWithGap } from '../atoms/CenteredRowWithGap';

export const WrapperWithDotDivider = ({ txt }: { txt: string }) => (
  <CenteredRowWithGap gap={0.5} ml={-0.5}>
    <DotDivider dotColor={colors.grey[700]} />
    <Typography variant="caption" color={colors.grey[700]} whiteSpace="nowrap">
      {txt}
    </Typography>
  </CenteredRowWithGap>
);
