import { appRoutes } from './appRoutes';

export const DEFAULT_TITLE = 'Sweep';

export const PAGE_TITLES = {
  [appRoutes.getStarted]: 'Get Started - Sweep',
  [appRoutes.funnelMapsPage]: 'Funnel Editor - Sweep',
  [appRoutes.sfChangeFeed]: 'Change Feed - Sweep',
  [appRoutes.rollups]: 'Rollups - Sweep',
  [appRoutes.automations]: 'Automations - Sweep',
  [appRoutes.documentation]: 'Documentation - Sweep',
  [appRoutes.routing]: 'Routing - Sweep',
  [appRoutes.alerts]: 'Alerts - Sweep',
  [appRoutes.devopsCenter]: 'Compare and Deploy - Sweep',
  [appRoutes.reports]: 'Reports - Sweep',
  [appRoutes.canvasUnited]: 'Funnel Editor - Sweep',
}
