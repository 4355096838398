import { useCallback } from 'react';
import { DashboardRoutes } from '../constants/appRoutes';
import { PAGE_VISIT_EVENTS } from '../services/events';
import useSendBiEventIfConnected from './useSendBiEventIfConnected';
import useOnLocationChange from './useOnLocationChange';
import { useSelector } from 'react-redux';
import { selectUserInfoData } from '../reducers/userInfoReducer';

//Note: if adding here a route with more than 1 segment, need to update the logic of "maybeSendEvent"
const RouteToPageVisit: { [key in DashboardRoutes]: PAGE_VISIT_EVENTS | undefined } = {
  [DashboardRoutes.getStarted]: undefined, //no need to send event for this route
  [DashboardRoutes.funnelMapsPage]: PAGE_VISIT_EVENTS.funnels,
  [DashboardRoutes.documentation]: PAGE_VISIT_EVENTS.documentation,
  [DashboardRoutes.sfChangeFeed]: PAGE_VISIT_EVENTS.changeFeed,
  [DashboardRoutes.rollups]: PAGE_VISIT_EVENTS.rollups,
  [DashboardRoutes.automations]: PAGE_VISIT_EVENTS.automations,
  [DashboardRoutes.alerts]: PAGE_VISIT_EVENTS.alerts,
  [DashboardRoutes.routing]: PAGE_VISIT_EVENTS.routing,
  [DashboardRoutes.reports]: PAGE_VISIT_EVENTS.reports,
  [DashboardRoutes.devopsCenter]: PAGE_VISIT_EVENTS.devopsCenter,
  [DashboardRoutes.aiAssistant]: PAGE_VISIT_EVENTS.aiAssistant,
  [DashboardRoutes.hubspot]: PAGE_VISIT_EVENTS.hubspot,
  [DashboardRoutes.dedup]: PAGE_VISIT_EVENTS.dedup,
};

const isNewPageVisit = (currentPath: string, previousPath?: string) => {
  const currRoute = currentPath.split('/')[1];
  const prevRoute = previousPath ? previousPath.split('/')[1] : undefined;
  return currRoute !== prevRoute;
};

const useSendPageVisitEvents = () => {
  //by product: send the "page visit" events only when the org is connected (happy state)
  const sendEvent = useSendBiEventIfConnected();
  const user = useSelector(selectUserInfoData);
  const panelMode = user?.preferences?.canvasPanelModeExpanded ? 'full page' : 'canvas view';

  const maybeSendEvent = useCallback(
    (currentPath: string, previousPath?: string) => {
      const split = currentPath.split('/');
      const route = '/' + split[1];
      const event = RouteToPageVisit[route as DashboardRoutes];
      if (!event) return;
      if (isNewPageVisit(currentPath, previousPath)) {
        sendEvent({ name: event, props: { panelMode } });
      }
    },
    [sendEvent, panelMode],
  );

  useOnLocationChange(maybeSendEvent);
};

export default useSendPageVisitEvents;
