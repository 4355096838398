import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';
import { RoleGroupInterface } from '@server/role-group-interface';

export interface RoleGroupState {
  roleGroups: RoleGroupInterface[];
  isLoading: boolean;
}

const initialState: RoleGroupState = {
  roleGroups: [],
  isLoading: false,
};

export const roleGroupsSlice = createSlice({
  name: 'roleGroups',
  initialState,
  reducers: {
    setRoleGroups: (state, action: PayloadAction<{ roleGroups: RoleGroupInterface[] }>) => {
      state.roleGroups = action.payload.roleGroups;
      state.isLoading = false;
    },
    setIsLoading: (state, action: PayloadAction<{ isLoading: boolean }>) => {
      state.isLoading = action.payload.isLoading;
    },
    addRoleGroup: (state, action: PayloadAction<{ roleGroup: RoleGroupInterface }>) => {
      state.roleGroups = [...state.roleGroups, action.payload.roleGroup];
    },
    deleteRoleGroup: (state, action: PayloadAction<{ roleGroupId: string }>) => {
      state.roleGroups = state.roleGroups.filter(
        (roleGroup) => roleGroup.id !== action.payload.roleGroupId,
      );
    },
    updateRoleGroup: (state, action: PayloadAction<{ newRoleGroup: RoleGroupInterface }>) => {
      const { newRoleGroup } = action.payload;
      state.roleGroups = state.roleGroups.map((_oldRoleGroup) => {
        if (_oldRoleGroup.id === newRoleGroup.id) {
          return { ..._oldRoleGroup, ...newRoleGroup };
        }

        return _oldRoleGroup;
      });
    },
  },
});

export const selectRoleGroups = (state: RootState) => state.roleGroups.roleGroups;
export const selectIsLoadingRoleGroups = (state: RootState) => state.roleGroups.isLoading;

export const { setRoleGroups, setIsLoading, addRoleGroup, updateRoleGroup, deleteRoleGroup } =
  roleGroupsSlice.actions;

export default roleGroupsSlice.reducer;
