import { Box } from '@mui/material';
import { IconButton } from '@sweep-io/sweep-design';
import { Send } from '@sweep-io/sweep-design/dist/icons';
import keyBy from 'lodash/keyBy';
import { useMemo, useState } from 'react';
import { Mention, MentionsInput, SuggestionDataItem } from 'react-mentions';
import { useSelector } from 'react-redux';
import { selectAccountUsers } from '../../reducers/accountUsersReducer';
import { MentionedUser } from '../../types/Comment';
import useEventListener from '../common/useEventListener';
import { hasMeaningfulText } from './helper';
import classNames from './NewCommentReply.module.css';
import { NewReplySuggestionItem } from './NewReplySuggestionItem';
import { getIsUserInactive } from '../../lib/getIsUserActive';

const NewCommentReply = ({
  onConfirm,
  placeholder,
  initialValue,
}: {
  onConfirm: (body: string, mentionedUserIds: MentionedUser[]) => void;
  placeholder?: string;
  initialValue?: string;
}) => {
  const users = useSelector(selectAccountUsers);
  const usersById = keyBy(users, 'id');
  const mentionsData: SuggestionDataItem[] = useMemo(() => {
    if (!users) {
      return [];
    }
    return users
      .filter((user) => !getIsUserInactive(user.status))
      .map(({ id, name, email }) => {
        return {
          id,
          display: !!name ? name : email,
        } as SuggestionDataItem;
      });
  }, [users]);
  const [body, setBody] = useState(initialValue ?? '');
  const [mentions, setMentions] = useState<MentionedUser[]>([]);
  const disableConfirm = body === '';

  const handleChange = (ev: any, markup: string, text: string, mentions: SuggestionDataItem[]) => {
    setBody(markup);
    const mentionedUserIds: MentionedUser[] = mentions.map((mention) => mention.id as string);
    setMentions(mentionedUserIds);
  };

  const handleConfirm = () => {
    if (hasMeaningfulText(body)) {
      onConfirm(body.trim(), mentions);
      setBody('');
    }
  };

  const onKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      if (body) {
        event.preventDefault(); //to prevent going down a line in the textarea
        handleConfirm();
      }
    }
  };

  useEventListener('keydown', onKeyDown);

  const renderSuggestion = ({ id }: SuggestionDataItem) => {
    const userInfo = usersById[id];
    return <NewReplySuggestionItem userInfo={userInfo} />;
  };

  return (
    <Box display="flex" gap={1} position="relative" alignItems="center">
      <MentionsInput
        placeholder={placeholder}
        className="mentions"
        classNames={classNames}
        value={body}
        autoFocus={true}
        suggestionsPortalHost={document.querySelector('#comment-popover') as Element}
        allowSuggestionsAboveCursor
        onChange={handleChange}
      >
        <Mention
          trigger="@"
          data={mentionsData}
          className={classNames.mentions__mention}
          renderSuggestion={renderSuggestion}
        />
      </MentionsInput>
      <Box
        sx={{
          position: 'absolute',
          right: '12px',
        }}
      >
        <IconButton onClick={handleConfirm} size="tiny" disabled={disableConfirm}>
          <Send />
        </IconButton>
      </Box>
    </Box>
  );
};

export default NewCommentReply;
