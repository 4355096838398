import cloneDeep from 'lodash/cloneDeep';
import { Box, FormControl, MenuItem, Popover, SelectChangeEvent } from '@mui/material';
import { useMemo, useState } from 'react';
import { uniqueId } from '../../lib/uniqueId';
import { ActionObjectTypesAutocomplete } from './ActionObjectTypesAutocomplete';
import { ChildRelationshipSelector } from './ChildRelationshipSelector';
import { AutomationActionType } from '../../types/enums/AutomationActionType';
import SweepSelect from '../common/SweepSelect';
import { AutomationNotifyEmail } from './AutomationNotifyEmail';
import { AutomationActionRows } from './AutomationActionRows';
import { SlackNotificationAction } from './slack/SlackNotificationAction';
import { Button, Typography, colors } from '@sweep-io/sweep-design';
import { SelectorValueTypes } from '../../types/enums/SelectorValueTypes';
import { CustomHeader } from '../common/rule-builder/CustomHeader';
import { SweepFieldsRuleBuilder } from '../common/sweep-condition-rule-builder/SweepConditionRuleBuilder';
import { useRefForMultipleRuleBuilders } from '../common/sweep-condition-rule-builder/MultipleRuleBuildersContext';
import { ConvertLeadActionNew } from './ConvertLeadActionNew';
import { ConvertLeadConvertedStatus } from './ConvertLeadConvertedStatus';
import { ContactRoleSelector, lookupFieldsTypes } from './ContactRoleSelector';
import { useSelector } from 'react-redux';
import {
  SlackConnectionStatus,
  selectSlackIntegrationStatus,
} from '../../reducers/integrationsReducer';
import { SlackDealRoomAction } from './slack/SlackDealRoomAction';
import { useRunOnce } from '../common/useRunOnce';
import { useIntegrations } from '../../hooks/useIntegrations';
import { SlackAddMemberAction } from './slack/SlackAddMemberAction';
import AssignmentRulesEditor from '../routing/AssignmentRulesEditor';
import { SlackNotConnected } from './slack/SlackNotConnected';
import { selectProductionCrmOrg } from '../pages/environments/environmentsReducer';
import { getOperatorsWithoutRecordsComparison } from './helper';
import slackSampleImg from './images/slackSample.png';
import { SendReportToSlackAction } from './slack/SendReportToSlackAction';
import { useFeatureToggle } from '../common/useFeatureToggle';
import { NestedFieldsSelector } from '../common/fieldsSelectors/NestedFieldsSelector';
import { SweepFieldTypes } from '../../types/enums/SweepFieldTypes';
import { useSweepFieldsLabels } from '../../sweep-fields/useSweepFieldsLabels';
import { VerifyUrlActionWrapper } from './VerifyUrlActionWrapper';

interface AutomationActionProps {
  action?: DeepPartial<AutomationAction>;
  onChange: (action: Partial<AutomationAction>) => any;
  objectName: string;
  crmOrgId: string;
  readonly?: boolean;
  isActionEnabled: boolean;
  objectTypes?: ObjectTypeNameAndTypes[];
  isPlaybookAlerts?: boolean;
  excludeValueType?: (type: SelectorValueTypes) => boolean;
  isHideActionSelector?: boolean;
  isNestedVerifiedUrl?: string;
}

type ThenTypesProps = {
  value: AutomationActionType;
  description: string;
  label: string;
};

const OPPORTUNITY = 'Opportunity';
const CONTACT = 'Contact';
const OPPORTUNITY_CONTACT_ROLE = 'OpportunityContactRole';
const OPPORTUNITY_LINE_ITEM = 'OpportunityLineItem';

export const AutomationAction = ({
  action,
  onChange,
  objectName,
  crmOrgId,
  readonly,
  isActionEnabled,
  objectTypes,
  isPlaybookAlerts,
  excludeValueType,
  isHideActionSelector,
  isNestedVerifiedUrl,
}: AutomationActionProps) => {
  const [recordTypesList, setRecordTypes] = useState<RecordTypeStruct[]>();
  const slackStatus = useSelector(selectSlackIntegrationStatus);
  const productionOrg = useSelector(selectProductionCrmOrg);
  const integrations = useIntegrations();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isSampleOpen = Boolean(anchorEl);
  const { verifyUrlAction } = useFeatureToggle();
  const { getEnrichedNestedPath } = useSweepFieldsLabels();

  useRunOnce(async () => {
    await integrations.getSlackStatus();
  });

  const _action: DeepPartial<AutomationAction> = useMemo(
    () =>
      action || {
        _id: uniqueId(),
        actionParams: undefined,
        actionType: undefined,
      },
    [action],
  );

  let ThenTypes: ThenTypesProps[] = [
    {
      value: AutomationActionType.CreateRecord,
      description: 'Create record',
      label: 'Create record',
    },
    {
      value: AutomationActionType.UpdateRecords,
      description: 'Update record',
      label: 'Update record',
    },
    {
      value: AutomationActionType.SendEmail,
      description: 'Send email',
      label: 'Send email',
    },
    {
      value: AutomationActionType.Slack,
      description: 'Post to Slack',
      label: 'Post to Slack',
    },
  ];

  if ('Lead' === objectName) {
    ThenTypes.push({
      value: AutomationActionType.ConvertLead,
      description: 'Convert lead',
      label: 'Convert lead',
    });
  }

  if (verifyUrlAction && !isNestedVerifiedUrl) {
    ThenTypes.push({
      value: AutomationActionType.VerifyURL,
      description: 'Verify URL',
      label: 'Verify URL',
    });
  }

  if (isPlaybookAlerts) {
    ThenTypes = [
      {
        value: AutomationActionType.SendEmail,
        description: 'Send email',
        label: 'Send email',
      },
      {
        value: AutomationActionType.Slack,
        description: 'Post to Slack',
        label: 'Post to Slack',
      },
    ];
  }

  if (slackStatus === SlackConnectionStatus.Connected) {
    ThenTypes.push(
      {
        value: AutomationActionType.SlackDealRoom,
        description: 'Create deal room',
        label: 'Create deal room',
      },
      {
        value: AutomationActionType.SlackAddMember,
        description: 'Add members to deal room',
        label: 'Add members to deal room',
      },
    );
  }

  const getActionRowObjectType = () => {
    return _action?.actionType === AutomationActionType.CreateRecord
      ? (_action as UpdateOrCreateAutomationAction).actionParams?.objectTypeName?.objectType
      : (_action as UpdateOrCreateAutomationAction).actionParams?.thisRecord
        ? objectName
        : (_action as UpdateOrCreateAutomationAction).actionParams?.childRelationshipFieldName
            ?.objectType;
  };

  const hasRecordTypes = () => {
    return (
      (_action as UpdateOrCreateAutomationAction).actionParams?.objectTypeName?.recordType ||
      (recordTypesList && recordTypesList.length > 1)
    );
  };

  const getRecordTypePerObject = (action: any) => {
    const currentObject = (action as UpdateOrCreateAutomationAction)?.actionParams?.objectTypeName
      ?.objectType;
    const result = objectTypes?.find((obj) => {
      return obj.objectType === currentObject;
    });
    return result?.recordTypes;
  };

  const criteria = useMemo(
    () => cloneDeep((_action as UpdateOrCreateAutomationAction).actionParams?.criteria),
    [_action],
  );

  const sweepCondition: SweepCondition | undefined = useMemo(() => {
    const criteria = cloneDeep((_action as UpdateOrCreateAutomationAction).actionParams?.criteria);
    if (!criteria) {
      return undefined;
    }
    return {
      criteria: criteria.criteria,
      criteriaLogic: criteria.criteriaLogic,
    };
  }, [_action]);

  const ruleBuilderRef = useRefForMultipleRuleBuilders();

  return (
    <>
      <Box
        className="autoThenSelect"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          flexWrap: 'wrap',
          rowGap: '10px',
        }}
      >
        {!isHideActionSelector && action?.actionType !== AutomationActionType.AssignmentRules && (
          <SweepSelect
            menuMaxHeight="320px"
            FormControlProps={{
              sx: { minWidth: '120px', marginRight: '8px' },
            }}
            SelectProps={{
              disabled: !isActionEnabled || readonly,
              placeholder: isPlaybookAlerts ? 'Choose how to notify' : 'Do This',
              value: action?.actionType ?? '',
              onChange: (event: SelectChangeEvent<unknown>) => {
                const val = event.target.value;
                onChange({
                  ..._action,
                  actionType: val as AutomationActionType,
                  actionParams: undefined,
                });
              },
              renderValue: (val: any) => {
                const output = ThenTypes.filter((d) => d.value === val);
                if (output.length > 0) {
                  return output[0].label ?? '';
                }
              },
            }}
          >
            {ThenTypes.map((type) => (
              <MenuItem key={type.value} value={type.value}>
                {type.description}
              </MenuItem>
            ))}
          </SweepSelect>
        )}

        {action?.actionType === AutomationActionType.CreateRecord && (
          <>
            <Box>
              <FormControl sx={{ minWidth: '160px', marginRight: '8px', width: '100%' }}>
                <ActionObjectTypesAutocomplete
                  _action={_action as UpdateOrCreateAutomationAction}
                  crmOrgId={crmOrgId}
                  disabled={readonly}
                  onChange={(action) => {
                    onChange(action as UpdateOrCreateAutomationAction);
                    setRecordTypes(getRecordTypePerObject(action));
                  }}
                />
              </FormControl>
            </Box>
          </>
        )}
        {action?.actionType === AutomationActionType.CreateRecord && hasRecordTypes() && (
          <>
            <Box>
              <SweepSelect
                FormControlProps={{
                  sx: { minWidth: '140px', marginLeft: '8px' },
                }}
                SelectProps={{
                  disabled: readonly,
                  placeholder: 'Choose Record Type',
                  value:
                    (_action as UpdateOrCreateAutomationAction).actionParams?.objectTypeName
                      ?.recordType?.name ?? '',
                  renderValue: (val: any) => {
                    if (!recordTypesList) {
                      setRecordTypes(getRecordTypePerObject(_action));
                    }
                    const recordType = recordTypesList?.find((type) => type?.name === val);
                    return recordType?.label ?? val ?? '';
                  },
                  onChange: (event: SelectChangeEvent<unknown>) => {
                    const val = event.target.value;
                    const getRecordTypeByName = recordTypesList?.find((type) => type?.name === val);
                    const newObjectTypeName = {
                      ...action.actionParams?.objectTypeName,
                      recordType: getRecordTypeByName,
                    };
                    onChange({
                      ...(_action as UpdateOrCreateAutomationAction),
                      actionParams: {
                        ...(_action as UpdateOrCreateAutomationAction).actionParams,
                        objectTypeName: newObjectTypeName as ObjectTypeName,
                      },
                    });
                  },
                }}
              >
                {recordTypesList?.map((type) => (
                  <MenuItem key={type.name} value={type.name}>
                    {type.label}
                  </MenuItem>
                ))}
              </SweepSelect>
            </Box>
          </>
        )}
        {action?.actionType === AutomationActionType.UpdateRecords && (
          <>
            <Box>
              <SweepSelect
                FormControlProps={{
                  sx: { minWidth: '140px', marginRight: '8px' },
                }}
                SelectProps={{
                  disabled: readonly,
                  placeholder: 'Choose a Record',
                  value: (_action as UpdateOrCreateAutomationAction).actionParams?.thisRecord ?? '',
                  onChange: (event: SelectChangeEvent<unknown>) => {
                    const val = event.target.value;
                    onChange({
                      ...(_action as UpdateOrCreateAutomationAction),
                      actionParams: {
                        ...(_action as UpdateOrCreateAutomationAction).actionParams,
                        thisRecord: val as boolean,
                        childRelationshipFieldName: undefined,
                        isCriteria: undefined,
                        criteria: undefined,
                        _fields: undefined,
                      },
                    });
                  },
                  renderValue: (val: any) => {
                    if (val) {
                      return 'This Record';
                    } else {
                      return 'Related Record';
                    }
                  },
                }}
              >
                {[
                  {
                    value: true,
                    label: 'This record and lookups from this record',
                    description: 'This Record',
                  },
                  {
                    value: false,
                    label: 'Lookups to this record',
                    description: 'Related Record',
                  },
                ].map((type) => (
                  <MenuItem key={type.value as any} value={type.value as any}>
                    {type.label}
                  </MenuItem>
                ))}
              </SweepSelect>
            </Box>
          </>
        )}
        {action?.actionType === AutomationActionType.VerifyURL && (
          <Box
            sx={{
              border: `1px solid ${colors.grey[300]}`,
              borderRadius: '4px',
              background: colors.white,
              maxWidth: '500px',
            }}
          >
            <NestedFieldsSelector
              placeholder="Select field"
              filterBy={(field: SweepField) => field?.fieldType === SweepFieldTypes.Url}
              crmOrgId={crmOrgId}
              objectType={objectName || ''}
              nestedPath={getEnrichedNestedPath(
                (_action as VerifyUrlAutomationAction).actionParams?.urlFieldId,
              )}
              readonly={readonly}
              onChange={(_sweepField) => {
                onChange({
                  ...action,
                  actionParams: {
                    ...(action as VerifyUrlAutomationAction).actionParams,
                    urlFieldId: _sweepField.fieldIds,
                  },
                });
              }}
            />
          </Box>
        )}
        {action?.actionType === AutomationActionType.UpdateRecords &&
          (_action as UpdateOrCreateAutomationAction).actionParams?.thisRecord === false && (
            <>
              <Box>
                <FormControl sx={{ minWidth: '120px', marginRight: '8px' }}>
                  <ChildRelationshipSelector
                    readonly={readonly}
                    ChildRelationshipObject={
                      (_action as UpdateOrCreateAutomationAction)?.actionParams
                        ?.childRelationshipFieldName
                    }
                    crmOrgId={crmOrgId}
                    objectName={objectName}
                    onChange={(value) => {
                      onChange({
                        ...action,
                        actionParams: {
                          ...action.actionParams,
                          childRelationshipFieldName: value,
                          criteria: undefined,
                          _fields: undefined,
                        },
                      });
                    }}
                  />
                </FormControl>
              </Box>
            </>
          )}
        {action?.actionType === AutomationActionType.UpdateRecords &&
          (_action as UpdateOrCreateAutomationAction).actionParams?.thisRecord === false &&
          (_action as UpdateOrCreateAutomationAction).actionParams?.childRelationshipFieldName && (
            <>
              <Box>
                <SweepSelect
                  FormControlProps={{
                    sx: { minWidth: '140px', marginRight: '8px' },
                  }}
                  SelectProps={{
                    disabled: readonly,
                    placeholder: 'Choose Criteria',
                    value:
                      (_action as UpdateOrCreateAutomationAction).actionParams?.isCriteria ?? '',
                    onChange: (event: SelectChangeEvent<unknown>) => {
                      const val = event.target.value;
                      onChange({
                        ...(_action as UpdateOrCreateAutomationAction),
                        actionParams: {
                          ..._action.actionParams,
                          isCriteria: val as boolean,
                        },
                      });
                    },
                    renderValue: (val: any) => {
                      if (val) {
                        return 'Only Records That Meet These Criteria';
                      } else {
                        return `No Criteria - update all related ${
                          (_action as UpdateOrCreateAutomationAction).actionParams
                            ?.childRelationshipFieldName?.objectTypeLabel
                        }`;
                      }
                    },
                  }}
                >
                  {[
                    {
                      value: false,
                      label: `No Criteria - update all related ${
                        (_action as UpdateOrCreateAutomationAction).actionParams
                          ?.childRelationshipFieldName?.objectTypeLabel
                      }`,
                    },
                    {
                      value: true,
                      label: 'Only Records That Meet These Criteria',
                    },
                  ].map((type) => (
                    <MenuItem key={type.value as any} value={type.value as any}>
                      {type.label}
                    </MenuItem>
                  ))}
                </SweepSelect>
              </Box>
            </>
          )}
        {'Lead' === objectName && action?.actionType === AutomationActionType.ConvertLead && (
          <ConvertLeadConvertedStatus
            readonly={readonly}
            crmOrgId={crmOrgId}
            onChange={(val) => {
              onChange({
                ...(_action as ConvertLeadAutomationAction),
                actionParams: {
                  ...(_action as ConvertLeadAutomationAction).actionParams,
                  convertedStatus: val,
                },
              });
            }}
            value={(_action as ConvertLeadAutomationAction).actionParams?.convertedStatus ?? ''}
          />
        )}

        {action?.actionType === AutomationActionType.Slack && (
          <Box sx={{ ml: '12px' }}>
            <Button
              onClick={(e) => setAnchorEl(e.currentTarget)}
              startIconName={isSampleOpen ? 'Hide' : 'Show'}
              variant="link"
            >
              {isSampleOpen ? 'Hide example' : 'Show example'}
            </Button>
            <Popover
              open={isSampleOpen}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <Box sx={{ p: '4px', pb: 0 }}>
                <img src={slackSampleImg} width={'665px'} />
              </Box>
            </Popover>
          </Box>
        )}
      </Box>
      {(action?.actionType === AutomationActionType.Slack ||
        action?.actionType === AutomationActionType.SendReportToSlack) &&
        (!productionOrg || slackStatus !== SlackConnectionStatus.Connected) && (
          <Box sx={{ mt: '16px' }}>
            <SlackNotConnected hasProduction={!!productionOrg} slackStatus={slackStatus} />
          </Box>
        )}
      {action?.actionType === AutomationActionType.SendEmail && (
        <AutomationNotifyEmail
          action={_action as EmailAutomationAction}
          crmOrgId={crmOrgId}
          onChange={onChange}
          objectName={objectName}
          readonly={readonly}
        />
      )}

      {'Lead' === objectName && action?.actionType === AutomationActionType.ConvertLead && (
        <ConvertLeadActionNew
          excludeValueType={excludeValueType}
          readonly={readonly}
          action={_action as ConvertLeadAutomationAction}
          crmOrgId={crmOrgId}
          onChange={onChange}
          objectTypes={objectTypes}
          initialObjectName={objectName}
          isNestedVerifiedUrl={isNestedVerifiedUrl}
        />
      )}

      {action?.actionType === AutomationActionType.Slack && (
        <SlackNotificationAction
          readonly={readonly}
          action={_action as SlackAutomationAction}
          crmOrgId={crmOrgId}
          onChange={onChange}
          objectName={objectName}
        />
      )}

      {action?.actionType === AutomationActionType.SlackDealRoom && (
        <SlackDealRoomAction
          readonly={readonly}
          action={_action as SlackDealRoomAutomationAction}
          crmOrgId={crmOrgId}
          onChange={onChange}
          objectName={objectName}
        />
      )}

      {action?.actionType === AutomationActionType.SlackAddMember && (
        <SlackAddMemberAction
          readonly={readonly}
          action={_action as SlackDealRoomAutomationAction}
          crmOrgId={crmOrgId}
          onChange={onChange}
          objectName={objectName}
        />
      )}

      {action?.actionType === AutomationActionType.CreateRecord &&
        objectName === CONTACT &&
        action.actionParams?.objectTypeName?.label === OPPORTUNITY && (
          <ContactRoleSelector
            lookupFieldsBy={lookupFieldsTypes.createContactOpp}
            showTooltip
            objectToSearch={OPPORTUNITY_CONTACT_ROLE}
            fieldToSearch={'Role'}
            actionOcrObject={action.actionParams?.relationsRecords?.find(
              (el) => el.objectType === OPPORTUNITY_CONTACT_ROLE,
            )}
            crmOrgId={crmOrgId}
            onChangeCheckbox={(isChecked, ocrElement) => {
              onChange({
                ...action,
                actionParams: {
                  ...action.actionParams,
                  relationsRecords: isChecked ? [ocrElement] : undefined,
                },
              });
            }}
            onSelectRole={(_relationsObject) => {
              onChange({
                ...action,
                actionParams: {
                  ...action.actionParams,
                  relationsRecords: [_relationsObject],
                },
              });
            }}
          />
        )}

      {action?.actionType === AutomationActionType.CreateRecord &&
        objectName === OPPORTUNITY &&
        action.actionParams?.objectTypeName?.label === OPPORTUNITY && (
          <ContactRoleSelector
            readonly={readonly}
            lookupFieldsBy={lookupFieldsTypes.CloneLineItem}
            objectToSearch={OPPORTUNITY_LINE_ITEM}
            label={'Copy products from original record'}
            actionOcrObject={action.actionParams?.relationsRecordsToClone?.find(
              (el) => el.objectType === OPPORTUNITY_LINE_ITEM,
            )}
            crmOrgId={crmOrgId}
            onChangeCheckbox={(isChecked, ocrElement) => {
              const relationsRecords = action.actionParams?.relationsRecordsToClone || [];
              onChange({
                ...action,
                actionParams: {
                  ...action.actionParams,
                  relationsRecordsToClone: isChecked
                    ? [...relationsRecords, ocrElement]
                    : relationsRecords.filter((el) => el.objectType !== OPPORTUNITY_LINE_ITEM),
                },
              });
            }}
            hideInnerSelection={true}
          />
        )}

      {action?.actionType === AutomationActionType.CreateRecord &&
        objectName === OPPORTUNITY &&
        action.actionParams?.objectTypeName?.label === OPPORTUNITY && (
          <ContactRoleSelector
            readonly={readonly}
            lookupFieldsBy={lookupFieldsTypes.cloneContactRole}
            objectToSearch={OPPORTUNITY_CONTACT_ROLE}
            label={'Copy contact roles from original record'}
            actionOcrObject={action.actionParams?.relationsRecordsToClone?.find(
              (el) => el.objectType === OPPORTUNITY_CONTACT_ROLE,
            )}
            crmOrgId={crmOrgId}
            onChangeCheckbox={(isChecked, ocrElement) => {
              const relationsRecords = action.actionParams?.relationsRecordsToClone || [];
              onChange({
                ...action,
                actionParams: {
                  ...action.actionParams,
                  relationsRecordsToClone: isChecked
                    ? [...relationsRecords, ocrElement]
                    : relationsRecords.filter((el) => el.objectType !== OPPORTUNITY_CONTACT_ROLE),
                },
              });
            }}
            hideInnerSelection={true}
          />
        )}

      {action?.actionType === AutomationActionType.AssignmentRules && (
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="body">Run the following assignment rules:</Typography>
          <AssignmentRulesEditor
            objectTypes={objectTypes}
            assignmentRules={
              (action.actionParams?.assignmentRules ?? []) as AutomationAssignmentRule[]
            }
            onChange={(assignmentRules: AutomationAssignmentRule[]) =>
              onChange({
                ...action,
                actionParams: { assignmentRules },
              })
            }
            crmOrgId={crmOrgId}
            objectName={objectName}
            readonly={readonly}
          />
        </Box>
      )}

      {action?.actionType === AutomationActionType.SendReportToSlack && (
        <SendReportToSlackAction
          readonly={readonly}
          action={_action as SendReportToSlackAction}
          crmOrgId={crmOrgId}
          onChange={onChange}
          objectName={objectName}
        />
      )}

      {action?.actionType === AutomationActionType.VerifyURL && (
        <VerifyUrlActionWrapper
          readonly={readonly}
          action={_action as VerifyUrlAutomationAction}
          crmOrgId={crmOrgId}
          onChange={onChange}
          objectName={objectName}
          objectTypes={objectTypes}
        />
      )}

      {((action?.actionType === AutomationActionType.UpdateRecords &&
        (_action as UpdateOrCreateAutomationAction).actionParams?.thisRecord) ||
        (action?.actionType === AutomationActionType.UpdateRecords &&
          (_action as UpdateOrCreateAutomationAction).actionParams?.thisRecord === false &&
          (_action as UpdateOrCreateAutomationAction).actionParams.isCriteria !== undefined) ||
        (action?.actionType === AutomationActionType.CreateRecord &&
          (_action as UpdateOrCreateAutomationAction).actionParams?.objectTypeName)) && (
        <Box sx={{ display: 'flex', marginTop: '8px', flexDirection: 'column' }}>
          {action?.actionType === AutomationActionType.UpdateRecords &&
            (_action as UpdateOrCreateAutomationAction).actionParams.isCriteria === true && (
              <Box
                sx={{
                  backgroundColor: colors.grey[100],
                  borderRadius: '6px',
                  marginBottom: '8px',
                  pt: '12px',
                }}
              >
                <>
                  <CustomHeader customHeader={'When these criteria are met:'} />
                  <SweepFieldsRuleBuilder
                    filterOperatorBy={getOperatorsWithoutRecordsComparison}
                    ref={ruleBuilderRef}
                    readOnly={readonly}
                    crmOrgId={crmOrgId ?? ''}
                    objectType={
                      (_action as UpdateOrCreateAutomationAction).actionParams
                        .childRelationshipFieldName?.objectType || ''
                    }
                    referenceObjectType={objectName}
                    sweepCondition={sweepCondition}
                    onChange={(newSweepCondition) => {
                      const exitCriteria = criteria || {
                        criteria: [],
                        criteriaLogic: '',
                      };
                      onChange({
                        ...(_action as UpdateOrCreateAutomationAction),
                        actionParams: {
                          ...(_action as UpdateOrCreateAutomationAction).actionParams,
                          criteria: {
                            ...exitCriteria,
                            ...newSweepCondition,
                          },
                        },
                      });
                    }}
                    disableResolvePolymorphic
                  />
                </>
              </Box>
            )}
          <Box sx={{ display: 'flex' }}>
            <Box
              sx={{
                marginRight: '12px',
                paddingTop: '10px',
                flex: '0 0 auto',
                maxWidth: '160px',
                width: '160px',
              }}
            >
              <Typography variant="body">
                {action?.actionType === AutomationActionType.CreateRecord ? (
                  <>
                    Set the new{' '}
                    <b>
                      {
                        (
                          _action as UpdateOrCreateAutomationAction as UpdateOrCreateAutomationAction
                        ).actionParams?.objectTypeName?.label
                      }
                    </b>
                    ’s field value(s):
                  </>
                ) : action?.actionParams?.thisRecord ? (
                  <>
                    Update these <b> {objectName} </b> field(s):
                  </>
                ) : (
                  <>
                    Update these
                    <b> {action.actionParams?.childRelationshipFieldName?.objectTypeLabel} </b>
                    field(s):
                  </>
                )}
              </Typography>
            </Box>
            <Box className="automationFieldLineWrapper" sx={{ width: '100%' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                <AutomationActionRows
                  showVerifiedUrlOption={isNestedVerifiedUrl}
                  excludeValueType={excludeValueType}
                  showCreatableFields={action?.actionType === AutomationActionType.CreateRecord}
                  disableLookupItems={action?.actionType === AutomationActionType.CreateRecord}
                  hideRecordTypeIdField={action?.actionType === 'CREATE_RECORD'}
                  readonly={readonly}
                  objectName={getActionRowObjectType()}
                  cmOrgId={crmOrgId}
                  initialObjectName={objectName}
                  onChange={(fields) => {
                    onChange({
                      ...action,
                      actionParams: {
                        ...action.actionParams,
                        _fields: fields,
                      },
                    });
                  }}
                  fields={action.actionParams?._fields || []}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
