import { TabContext } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { stageModel } from '../../../../../models/stageModel';
import { StageGatesTab } from './StageGatesTab';
import { StageSettingsTab } from './StageSettingsTab';
import { StageDialogTabTypes } from '../../../../../types/enums/StageDialogTabTypes';
import { TabList } from '@sweep-io/sweep-design';
import { useStageDialog } from './useStageDialog';
import usePermission from '../../../../common/permissions/usePermission';
import { Box } from '@mui/material';
import { MultipleRuleBuildersProvider } from '../../../../common/sweep-condition-rule-builder/MultipleRuleBuildersContext';
import useCalculateTabCounter from './useCalculateTabCounter';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { selectStageDialogHasChanges } from '../../../../../reducers/funnel-flow-page/stageDialogReducer';
import { Permission } from '@server/permissions';
import { RightPanelAutomationsTab } from './RightPanelAutomationsTab';
import { AutomationFormVariant } from '../../../../../types/enums/AutomationFormVariant';
import { selectAutomations } from '../../../../../reducers/global/automationReducers';
import { selectAlerts } from '../../../../../reducers/global/alertsReducers';
import { getAutomationsForStageId } from '../../../../Automations/helper';
import { AutomationTypes } from '../../../../../types/enums/AutomationTypes';
import { selectAssignments } from '../../../../../reducers/global/assignmentReducers';
import uniqueId from 'lodash/uniqueId';
import { AutomationActionType } from '../../../../../types/enums/AutomationActionType';
import {
  ConfigurationCanvasPanelContent,
  ConfigurationCanvasRightPanel,
} from '../../../configuration-canvas-panel';
import { StageDialogHeader } from './StageDialogHeader';
import { DialogLoader } from '../../../../common/dialogs/DialogLoader';
import { assignmentRuleAutomationContextData } from '../../../../routing/Assignments';
import { alertsAutomationContextData } from '../../../../alerts/Alerts';
import { automationsAutomationContextData } from '../../../canvas-pages/CanvasAutomationPage';

const StyledPanelTab = styled(Box)({
  marginTop: '48px',
});

interface StageDialogProps {
  readonly?: boolean;
  stage: SweepStage;
  crmOrgId: string;
  funnelId: string;
  closeDialog: () => void;
  setIsDirty: (isDirty: boolean) => void;
}

const StageDialog = ({
  readonly,
  stage,
  crmOrgId,
  funnelId,
  setIsDirty,
  closeDialog,
}: StageDialogProps) => {
  const {
    activeTab,
    showLoader,
    onStageNameConfirm,
    funnel,
    onStageMetadataChange,
    onGateSave,
    onGateDelete,
    leadingFieldMetadata,
    onStageChange,
    removeStageAndCloseDialog,
    opportunityTypes,
    tempStageName,
    onTabChange,
    setGateIsDirty,
  } = useStageDialog({ crmOrgId, stage, funnelId });

  const { funnelDetails } = funnel ?? {};
  const leadingObject = funnelDetails?.leadingObject ?? {
    objectName: 'Lead',
    _leadingFieldId: '',
    _leadingFieldLabels: [],
    fieldName: 'Lead',
  };
  const { calculateGatesTabCounter } = useCalculateTabCounter({ stage });
  const permissionString: Permission[] = ['edit:funnels'];
  const [isAllowedBtn] = usePermission(permissionString);

  const stageDialogHasChanges = useSelector(selectStageDialogHasChanges);
  const automationsList = useSelector(selectAutomations);
  const alertsList = useSelector(selectAlerts);
  const assignmentsList = useSelector(selectAssignments);

  useEffect(() => {
    setIsDirty(Boolean(stageDialogHasChanges));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stageDialogHasChanges]);

  if (!funnelDetails) {
    return <></>;
  }

  const shouldGatesBeCollapsed =
    stageModel(stage).countConnectedStages() +
      (stageModel(stage).getStageFunnelLinks()?.length || 0) ===
    1;

  const currentTabs: any = [
    {
      badgeLabel: String(calculateGatesTabCounter() || ''),
      content: (
        <StyledPanelTab>
          <StageGatesTab
            funnelStage={stage}
            readonly={readonly || !isAllowedBtn}
            funnelDetails={funnelDetails}
            defaultIsCollapsed={shouldGatesBeCollapsed}
            crmOrgId={crmOrgId}
            setGateIsDirty={setGateIsDirty}
            onGateSave={onGateSave}
            onGateDelete={onGateDelete}
            funnelId={funnelId}
          />
        </StyledPanelTab>
      ),
      label: 'Gates',
      value: StageDialogTabTypes.GATES,
    },
  ];
  if (!readonly) {
    currentTabs.push(
      ...[
        {
          content: (
            <StyledPanelTab>
              <RightPanelAutomationsTab
                automationContextData={automationsAutomationContextData}
                readonly={readonly || !isAllowedBtn}
                funnelDetails={funnelDetails}
                automations={getAutomationsForStageId(stage._stageId, automationsList)}
                onCreateData={{
                  newElement: {
                    objectName: leadingObject.objectName,
                    automationDetails: {
                      triggerType: AutomationTypes.StageEnter,
                      funnelId,
                      actions: [],
                      automationParams: {
                        stageEnter: {
                          toStage: stage,
                        },
                      },
                    },
                  },
                }}
                automationVariant={AutomationFormVariant.AUTOMATIONS}
                excludeAutomationsNotInFunnelMapObjects
              />
            </StyledPanelTab>
          ),
          label: 'Automations',
          value: StageDialogTabTypes.AUTOMATIONS,
          badgeLabel: String(
            getAutomationsForStageId(stage._stageId, automationsList).length || '',
          ),
        },
        {
          badgeLabel: String(getAutomationsForStageId(stage._stageId, alertsList).length || ''),
          content: (
            <StyledPanelTab>
              <RightPanelAutomationsTab
                automationContextData={alertsAutomationContextData}
                readonly={readonly}
                funnelDetails={funnelDetails}
                automationVariant={AutomationFormVariant.PLAYBOOK_ALERT}
                automations={getAutomationsForStageId(stage._stageId, alertsList)}
                excludeAutomationsNotInFunnelMapObjects
                onCreateData={{
                  newElement: {
                    objectName: leadingObject.objectName,
                    automationDetails: {
                      triggerType: AutomationTypes.StageEnter,
                      funnelId,
                      actions: [],
                      automationParams: {
                        stageEnter: {
                          toStage: stage,
                        },
                      },
                    },
                  },
                }}
              />
            </StyledPanelTab>
          ),
          label: 'Alerts',
          value: StageDialogTabTypes.ALERTS,
        },
        {
          content: (
            <StyledPanelTab>
              <RightPanelAutomationsTab
                automationContextData={assignmentRuleAutomationContextData}
                readonly={readonly}
                funnelDetails={funnelDetails}
                automationVariant={AutomationFormVariant.ASSIGNMENT_RULES}
                automations={getAutomationsForStageId(stage._stageId, assignmentsList)}
                excludeAutomationsNotInFunnelMapObjects
                onCreateData={{
                  newElement: {
                    objectName: leadingObject.objectName,
                    automationDetails: {
                      triggerType: AutomationTypes.StageEnter,
                      funnelId,
                      actions: [
                        {
                          _id: uniqueId(),
                          actionType: AutomationActionType.AssignmentRules,
                          actionParams: {
                            assignmentRules: [],
                          },
                        },
                      ],
                      automationParams: {
                        stageEnter: {
                          toStage: stage,
                        },
                      },
                    },
                  },
                }}
              />
            </StyledPanelTab>
          ),
          label: 'Assignments',
          value: StageDialogTabTypes.ASSIGNMENTS,
          badgeLabel: String(
            getAutomationsForStageId(stage._stageId, assignmentsList).length || '',
          ),
        },
      ],
    );
  }
  currentTabs.push({
    content: (
      <StyledPanelTab>
        <StageSettingsTab
          removeStage={removeStageAndCloseDialog}
          readonly={readonly || !isAllowedBtn}
          stage={stage}
          crmOrgId={crmOrgId}
          funnelDetails={funnelDetails}
          opportunityTypes={opportunityTypes}
          leadingFieldMetadata={leadingFieldMetadata}
          onStageChange={onStageChange}
          onStageMetadataChange={onStageMetadataChange}
        />
      </StyledPanelTab>
    ),
    label: 'Settings',
    value: StageDialogTabTypes.SETTINGS,
  });

  return (
    <>
      <TabContext value={activeTab}>
        <ConfigurationCanvasRightPanel>
          <StageDialogHeader
            stageName={tempStageName ?? stage.stageName}
            funnelDetails={funnelDetails}
            readonly={readonly || !isAllowedBtn}
            onStageNameConfirm={onStageNameConfirm}
            closeDialog={closeDialog}
          />

          <ConfigurationCanvasPanelContent>
            <TabList
              variant="scrollable"
              value={activeTab}
              list={currentTabs}
              onChange={onTabChange}
            />
          </ConfigurationCanvasPanelContent>
          <DialogLoader visible={showLoader} />
        </ConfigurationCanvasRightPanel>
      </TabContext>
    </>
  );
};

const StageDialogWithProviders = (props: StageDialogProps) => (
  <MultipleRuleBuildersProvider>
    <StageDialog {...props} />
  </MultipleRuleBuildersProvider>
);

export { StageDialogWithProviders as StageDialog };
