import {
  GetStartedActionIdDerived,
  GetStartedSection,
  RequireSfConnectionType,
  GetStartedSectionId,
} from './getStartedTypes';
import { ActionStatus, GetStartedActionIdDeprecated } from '@server/get-started';
import {
  Automations,
  Binoculars,
  Chats,
  HandWaving,
  Layout,
  Settings,
  SlackLogo,
  Users,
  Rocket,
} from '@sweep-io/sweep-design/dist/icons';
import { colors } from '@sweep-io/sweep-design';
import documentationTakeTour from './svgs/documentationTakeTour.png';
import DocumentationNavigate from './svgs/documentationNavigate.svg?react';
import adminChangeFeedNavigate from './svgs/adminChangeFeedNavigate.png';
import adminRollupsNavigate from './svgs/adminRollupsNavigate.png';
import AutomationsTakeTour from './svgs/automationsTakeTour.svg?react';
import AutomationsNavigate from './svgs/automationsNavigate.svg?react';
import slackTakeTour from './svgs/slackTakeTour.png';
import slackConnect from './svgs/slackConnect.png';
import slackNavigate from './svgs/slackNavigate.png';
import CollaborationTakeTour from './svgs/collaborationTakeTour.svg?react';
import CollaborationNavigate from './svgs/collaborationNavigate.svg?react';
import collaborationInvite from './svgs/collaborationInvite.png';
import routingTakeTour from './svgs/routingTakeTour.png';
import routingAssignmentsNavigate from './svgs/routingAssignmentsNavigate.png';
import RoutingSetupNavigate from './svgs/routingSetupNavigate.svg?react';
import RoutingDedupeNavigate from './svgs/routingDedupeNavigate.svg?react';
import templatesTakeTour from './svgs/templatesTakeTour.png';
import templatesNavigate from './svgs/templatesNavigate.png';
import introWatchVideo from './svgs/introWatchVideo.png';
import setupManagedPackage from './svgs/setupManagedPackage.png';
import setupPermissionSet from './svgs/setupPermissionSet.png';
import { GET_STARTED_EVENTS_deprecated } from '../../../services/events';
import { STORYLANE_URLS } from '../../common/url-consts';

export const DefaultDerivedStatus= {
    [GetStartedActionIdDerived.setupManagedPackage]: ActionStatus.NOT_COMPLETED,
    [GetStartedActionIdDerived.setupPermissionSet]: ActionStatus.NOT_COMPLETED,
    [GetStartedActionIdDerived.slackConnect]: ActionStatus.NOT_COMPLETED,
    [GetStartedActionIdDerived.collaborationInvite]: ActionStatus.NOT_COMPLETED,
};

export const DefaultUserLevelStatus = {
    [GetStartedActionIdDeprecated.introWatchVideo]: ActionStatus.NOT_COMPLETED,
    [GetStartedActionIdDeprecated.documentationNavigate]: ActionStatus.NOT_COMPLETED,
    [GetStartedActionIdDeprecated.documentationTakeTour]: ActionStatus.NOT_COMPLETED,
    [GetStartedActionIdDeprecated.adminRollupsNavigate]: ActionStatus.NOT_COMPLETED,
    [GetStartedActionIdDeprecated.adminChangeFeedNavigate]: ActionStatus.NOT_COMPLETED,
    [GetStartedActionIdDeprecated.automationsTakeTour]: ActionStatus.NOT_COMPLETED,
    [GetStartedActionIdDeprecated.automationsNavigate]: ActionStatus.NOT_COMPLETED,
    [GetStartedActionIdDeprecated.slackTakeTour]: ActionStatus.NOT_COMPLETED,
    [GetStartedActionIdDeprecated.slackNavigate]: ActionStatus.NOT_COMPLETED,
    [GetStartedActionIdDeprecated.collaborationTakeTour]: ActionStatus.NOT_COMPLETED,
    [GetStartedActionIdDeprecated.collaborationNavigate]: ActionStatus.NOT_COMPLETED,
    [GetStartedActionIdDeprecated.routingTakeTour]: ActionStatus.NOT_COMPLETED,
    [GetStartedActionIdDeprecated.routingSetupNavigate]: ActionStatus.NOT_COMPLETED,
    [GetStartedActionIdDeprecated.routingAssignmentsNavigate]: ActionStatus.NOT_COMPLETED,
    [GetStartedActionIdDeprecated.routingDedupeNavigate]: ActionStatus.NOT_COMPLETED,
    [GetStartedActionIdDeprecated.templatesTakeTour]: ActionStatus.NOT_COMPLETED,
    [GetStartedActionIdDeprecated.templatesNavigate]: ActionStatus.NOT_COMPLETED,
};

const imgStyle = { width: '400px', height: '230px', borderRadius: '8px' };

export const SECTIONS: GetStartedSection[] = [
  {
    id: GetStartedSectionId.INTRO,
    adminOnly: false,
    label: 'Get to know Sweep',
    icon: <HandWaving />,
    iconBgColor: colors.gum[300],
    actions: [
      {
        id: GetStartedActionIdDeprecated.introWatchVideo,
        label: 'Turn your Salesforce into a visual workspace',
        text: 'Sweep translates your entire Salesforce org into visual workspace where you can customize and scale any configuration, without the dev time.',
        img: <img src={introWatchVideo} alt={'Watch a Video'} style={imgStyle} />,
        minsToComplete: 2,
        requiredSfConnection: RequireSfConnectionType.NONE,
        requireFetched: false,
        requireManagedPackage: false,
        status: ActionStatus.NOT_COMPLETED,
        biEvent: GET_STARTED_EVENTS_deprecated.introWatchVideo,
      },
    ],
  },
  {
    id: GetStartedSectionId.DOCUMENTATION,
    adminOnly: false,
    label: 'Learn about AI-powered Documentation',
    icon: <Binoculars />,
    iconBgColor: colors.sun[300],
    actions: [
      {
        id: GetStartedActionIdDeprecated.documentationTakeTour,
        label: 'Visualize your Salesforce environment instantly',
        text: `Sweep's AI-powered documentation analyzes your Salesforce instance and generates comprehensive documentation, including dependency mapping, field utilization tracking, and process visualization.`,
        img: (
          <img
            src={documentationTakeTour}
            alt={'Visualize your Salesforce environment'}
            style={imgStyle}
          />
        ),
        minsToComplete: 2,
        requiredSfConnection: RequireSfConnectionType.NONE,
        requireFetched: false,
        requireManagedPackage: false,
        tourUrl: STORYLANE_URLS.documentation,
        status: ActionStatus.NOT_COMPLETED,
        biEvent: GET_STARTED_EVENTS_deprecated.documentationTakeTour,
      },
      {
        id: GetStartedActionIdDeprecated.documentationNavigate,
        label: 'Explore your first object',
        text: (
          <>
            Start exploring the visual version of your Salesforce.
            <br />
            <br />
            See all of your business processes in one place. Use our AI assistant to determine
            what's already been built in your Salesforce.
          </>
        ),
        img: <DocumentationNavigate />,
        minsToComplete: 2,
        requiredSfConnection: RequireSfConnectionType.ANY,
        requireFetched: true,
        requireManagedPackage: false,
        tourUrl: STORYLANE_URLS.documentation,
        status: ActionStatus.NOT_COMPLETED,
        secondaryBiEvent: GET_STARTED_EVENTS_deprecated.documentationTakeTour,
      },
    ],
  },
  {
    id: GetStartedSectionId.SETUP,
    adminOnly: true,
    label: 'Set yourself up for success with Sweep',
    icon: <Rocket />,
    iconBgColor: colors.sky[100],
    actions: [
      {
        id: GetStartedActionIdDerived.setupManagedPackage,
        label: 'Install Sweep Managed Package',
        text: (
          <>
            Install our Managed Package to deploy directly from Sweep.
            <br />
            It takes just a few minutes to uncover the power of Salesforce with Sweep.
          </>
        ),
        img: (
          <img src={setupManagedPackage} alt={'Install Sweep Managed Package'} style={imgStyle} />
        ),
        minsToComplete: 3,
        requiredSfConnection: RequireSfConnectionType.ANY,
        requireFetched: false,
        requireManagedPackage: false,
        status: ActionStatus.NOT_COMPLETED,
      },
      {
        id: GetStartedActionIdDerived.setupPermissionSet,
        label: 'Assign Sweep Permission Set Group',
        text: `Assign Sweep permission set group to Salesforce users to test Sweep, or take the full advantage of triggering Sweep configuration in Salesforce`,
        img: (
          <img
            src={setupPermissionSet}
            alt={'Assign Sweep Permission Set Group'}
            style={imgStyle}
          />
        ),
        minsToComplete: 2,
        requiredSfConnection: RequireSfConnectionType.ANY,
        requireFetched: false,
        requireManagedPackage: true,
        status: ActionStatus.NOT_COMPLETED,
      },
    ],
  },
  {
    id: GetStartedSectionId.ADMIN,
    adminOnly: false,
    label: 'Elevate your Admin experience',
    icon: <Settings />,
    iconBgColor: colors.lilac[300],
    actions: [
      {
        id: GetStartedActionIdDeprecated.adminRollupsNavigate,
        label: 'Reliable Rollups',
        text: `Create rollups for any object relationship, whether master-detail or lookup, eliminating mid-configuration frustration.`,
        img: <img src={adminRollupsNavigate} alt={'Reliable Rollups'} style={imgStyle} />,
        minsToComplete: 2,
        requiredSfConnection: RequireSfConnectionType.ANY,
        requireFetched: true,
        requireManagedPackage: false,
        tourUrl: STORYLANE_URLS.rollups,
        status: ActionStatus.NOT_COMPLETED,
        secondaryBiEvent: GET_STARTED_EVENTS_deprecated.adminRollupsTakeTour,
      },
      {
        id: GetStartedActionIdDeprecated.adminChangeFeedNavigate,
        label: 'Real-time Salesforce Change Feed',
        text: `Track every change in your environment in real-time and receive immediate notifications for critical updates.`,
        img: (
          <img
            src={adminChangeFeedNavigate}
            alt={'Real-time Salesforce Change Feed'}
            style={imgStyle}
          />
        ),
        minsToComplete: 2,
        requiredSfConnection: RequireSfConnectionType.ANY,
        requireFetched: true,
        requireManagedPackage: false,
        tourUrl: STORYLANE_URLS.changeFeed,
        status: ActionStatus.NOT_COMPLETED,
        secondaryBiEvent: GET_STARTED_EVENTS_deprecated.adminChangeFeedTakeTour,
      },
    ],
  },
  {
    id: GetStartedSectionId.AUTOMATIONS,
    adminOnly: false,
    label: 'Scale revenue with Automations',
    icon: <Automations />,
    iconBgColor: colors.seafoam[300],
    actions: [
      {
        id: GetStartedActionIdDeprecated.automationsTakeTour,
        label: 'Free up your bandwidth with Automations',
        text: `Build automations for any action across the pipeline with a native solution that moves every team forward.`,
        img: <AutomationsTakeTour />,
        minsToComplete: 2,
        requiredSfConnection: RequireSfConnectionType.NONE,
        requireFetched: false,
        requireManagedPackage: false,
        tourUrl: STORYLANE_URLS.automations,
        status: ActionStatus.NOT_COMPLETED,
        biEvent: GET_STARTED_EVENTS_deprecated.automationsTakeTour,
      },
      {
        id: GetStartedActionIdDeprecated.automationsNavigate,
        label: 'Create your first Automation',
        text: `Whether you want to update a close date, create a task, or generate renewal opportunities to ensure future revenue, Sweep makes it easy to set up Automations that improve your efficiency.`,
        img: <AutomationsNavigate />,
        minsToComplete: 2,
        requiredSfConnection: RequireSfConnectionType.ANY,
        requireFetched: true,
        requireManagedPackage: false,
        tourUrl: STORYLANE_URLS.automations,
        status: ActionStatus.NOT_COMPLETED,
        secondaryBiEvent: GET_STARTED_EVENTS_deprecated.automationsTakeTour,
      },
    ],
  },
  {
    id: GetStartedSectionId.SLACK,
    adminOnly: false,
    label: 'Connect Salesforce with Slack',
    icon: <SlackLogo />,
    iconBgColor: colors.blush[300],
    actions: [
      {
        id: GetStartedActionIdDeprecated.slackTakeTour,
        label: 'Get Started with Slack Alerts',
        text: `Keep your go-to-market teams engaged and informed through actionable alerts and Salesforce notifications on Slack. Save time and streamline communication by automating custom messages to your team based on actual Salesforce data.`,
        img: <img src={slackTakeTour} alt={'Get Started with Slack Alerts'} style={imgStyle} />,
        minsToComplete: 2,
        requiredSfConnection: RequireSfConnectionType.NONE,
        requireFetched: false,
        requireManagedPackage: false,
        tourUrl: STORYLANE_URLS.slack,
        status: ActionStatus.NOT_COMPLETED,
        biEvent: GET_STARTED_EVENTS_deprecated.slackTakeTour,
      },
      {
        id: GetStartedActionIdDerived.slackConnect,
        label: 'Set up the Slack integration with Salesforce',
        text: `Add the Slack integration to start generating actionable notifications with real-time Salesforce data.`,
        img: (
          <img
            src={slackConnect}
            alt={'Set up the Slack integration with Salesforce'}
            style={imgStyle}
          />
        ),
        minsToComplete: 2,
        requiredSfConnection: RequireSfConnectionType.PROD,
        requireFetched: false,
        requireManagedPackage: false,
        tourUrl: STORYLANE_URLS.slack,
        status: ActionStatus.NOT_COMPLETED,
      },
      {
        id: GetStartedActionIdDeprecated.slackNavigate,
        label: 'Customize your first Slack Alert',
        text: `It's simple to create Slack Alerts with Sweep. Turn key pipeline events like stage changes, record updates, important dates, and time in stage into actionable notifications with a couple of clicks.`,
        img: <img src={slackNavigate} alt={'Customize your first Slack Alert'} style={imgStyle} />,
        minsToComplete: 2,
        requiredSfConnection: RequireSfConnectionType.ANY,
        requireFetched: true,
        requireManagedPackage: false,
        tourUrl: STORYLANE_URLS.slack,
        status: ActionStatus.NOT_COMPLETED,
        secondaryBiEvent: GET_STARTED_EVENTS_deprecated.slackTakeTour,
      },
    ],
  },
  {
    id: GetStartedSectionId.COLLABORATION,
    adminOnly: false,
    label: 'Make Salesforce a team sport',
    icon: <Chats />,
    iconBgColor: colors.mint[100],
    actions: [
      {
        id: GetStartedActionIdDeprecated.collaborationTakeTour,
        label: 'Collaborate seamlessly with team members',
        text: `With Sweep, you can add comments, mention team members, and reply to existing threads in any funnel within your account.`,
        img: <CollaborationTakeTour />,
        minsToComplete: 2,
        requiredSfConnection: RequireSfConnectionType.NONE,
        requireFetched: false,
        requireManagedPackage: false,
        tourUrl: STORYLANE_URLS.collaboration,
        status: ActionStatus.NOT_COMPLETED,
        biEvent: GET_STARTED_EVENTS_deprecated.collaborationTakeTour,
      },
      {
        id: GetStartedActionIdDerived.collaborationInvite,
        label: 'Invite team members',
        text: `Create a shared Salesforce reality instantly.`,
        img: <img src={collaborationInvite} alt={'Invite team members'} style={imgStyle} />,
        minsToComplete: 2,
        requiredSfConnection: RequireSfConnectionType.NONE,
        requireFetched: false,
        requireManagedPackage: false,
        tourUrl: STORYLANE_URLS.collaboration,
        status: ActionStatus.NOT_COMPLETED,
      },
      {
        id: GetStartedActionIdDeprecated.collaborationNavigate,
        label: 'Add comments and mentions',
        text: `Add comments, mention team members, and reply to existing threads in any funnel within your account.`,
        img: <CollaborationNavigate />,
        minsToComplete: 2,
        requiredSfConnection: RequireSfConnectionType.ANY,
        requireFetched: true,
        requireManagedPackage: false,
        tourUrl: STORYLANE_URLS.collaboration,
        status: ActionStatus.NOT_COMPLETED,
        secondaryBiEvent: GET_STARTED_EVENTS_deprecated.collaborationTakeTour,
      },
    ],
  },
  {
    id: GetStartedSectionId.ROUTING,
    adminOnly: false,
    label: 'Manage Lead Routing and Assignments',
    icon: <Users />,
    iconBgColor: colors.sky[300],
    actions: [
      {
        id: GetStartedActionIdDeprecated.routingTakeTour,
        label: 'Get to know Sweep routing',
        text: `Easily create and manage groups, territories, and assignment rules for any Salesforce object with a suite of routing, matching and dedupe tools that will speed up your pipeline.`,
        img: <img src={routingTakeTour} alt={'Get to know Sweep routing'} style={imgStyle} />,
        minsToComplete: 2,
        requiredSfConnection: RequireSfConnectionType.NONE,
        requireFetched: false,
        requireManagedPackage: false,
        tourUrl: STORYLANE_URLS.routing,
        status: ActionStatus.NOT_COMPLETED,
        biEvent: GET_STARTED_EVENTS_deprecated.routingTakeTour,
      },
      {
        id: GetStartedActionIdDeprecated.routingSetupNavigate,
        label: 'Create and manage assignment groups',
        text: `Manage countless dynamic groups, set individual/group limits, distribute leads fairly, schedule vacations, and snooze activity—all in a few clicks.`,
        img: <RoutingSetupNavigate />,
        minsToComplete: 2,
        requiredSfConnection: RequireSfConnectionType.ANY,
        requireFetched: true,
        requireManagedPackage: false,
        tourUrl: STORYLANE_URLS.routing,
        status: ActionStatus.NOT_COMPLETED,
        secondaryBiEvent: GET_STARTED_EVENTS_deprecated.routingTakeTour,
      },
      {
        id: GetStartedActionIdDeprecated.routingAssignmentsNavigate,
        label: 'Create your first assignment rule',
        text: `Use your CRM data to route the right record to the right team, automatically.`,
        img: (
          <img
            src={routingAssignmentsNavigate}
            alt={'Create your first assignment rule'}
            style={imgStyle}
          />
        ),
        minsToComplete: 2,
        requiredSfConnection: RequireSfConnectionType.ANY,
        requireFetched: true,
        requireManagedPackage: false,
        tourUrl: STORYLANE_URLS.routing,
        status: ActionStatus.NOT_COMPLETED,
        secondaryBiEvent: GET_STARTED_EVENTS_deprecated.routingTakeTour,
      },
      {
        id: GetStartedActionIdDeprecated.routingDedupeNavigate,
        label: 'Ensure data hygiene with Dedupe and Matching',
        text: `Build automated Deduplication and Matching logic to avoid hours of data cleanups. Quickly identify misalignments and bottlenecks in your processes and make informed decisions to optimize your GTM strategies and data model.`,
        img: <RoutingDedupeNavigate />,
        minsToComplete: 2,
        requiredSfConnection: RequireSfConnectionType.ANY,
        requireFetched: true,
        requireManagedPackage: false,
        tourUrl: STORYLANE_URLS.routing,
        status: ActionStatus.NOT_COMPLETED,
        secondaryBiEvent: GET_STARTED_EVENTS_deprecated.routingTakeTour,
      },
    ],
  },
  {
    id: GetStartedSectionId.TEMPLATES,
    adminOnly: false,
    label: 'Maximize efficiency with templates and plug-ins ',
    icon: <Layout />,
    iconBgColor: '#FFCE00',
    actions: [
      {
        id: GetStartedActionIdDeprecated.templatesTakeTour,
        label: 'Explore Salesforce templates and plug-ins',
        text: `Take your Salesforce to the next level with Sweep’s templates that will help you streamline, document and operationalize your Salesforce organization. Use our plug-ins to add a range of capabilities to your funnels with just a click of a button.`,
        img: (
          <img
            src={templatesTakeTour}
            alt={'Explore Salesforce templates and plug-ins'}
            style={imgStyle}
          />
        ),
        minsToComplete: 2,
        requiredSfConnection: RequireSfConnectionType.NONE,
        requireFetched: false,
        requireManagedPackage: false,
        tourUrl: STORYLANE_URLS.templates,
        status: ActionStatus.NOT_COMPLETED,
        biEvent: GET_STARTED_EVENTS_deprecated.templatesTakeTour,
      },
      {
        id: GetStartedActionIdDeprecated.templatesNavigate,
        label: 'Browse the Templates gallery',
        text: (
          <>
            Use Sweep templates for any project or task at hand. <br />
            From a new process to optimizing your existing ones, these templates were designed to
            help you start, scale, or iterate quickly.
          </>
        ),
        img: <img src={templatesNavigate} alt={'Browse the Templates gallery'} style={imgStyle} />,
        minsToComplete: 2,
        requiredSfConnection: RequireSfConnectionType.ANY,
        requireFetched: true,
        requireManagedPackage: false,
        tourUrl: STORYLANE_URLS.templates,
        status: ActionStatus.NOT_COMPLETED,
        biEvent: GET_STARTED_EVENTS_deprecated.templatesNavigate,
        secondaryBiEvent: GET_STARTED_EVENTS_deprecated.templatesTakeTour,
      },
    ],
  },
];
