import usePaywall, {
  PaywallAction,
  PaywallSource,
} from '../components/dashboard/header/usePaywall';
import useQueryParams from './useQueryParams';
import { useRunOnceWhenTruthy } from '../components/common/useRunOnceWhenTruthy';
import {
  ACTIVATE_TRIAL_COOKIE_NAME,
  ACTIVATE_TRIAL_QUERY_PARAM,
} from '../components/dashboard/paywall/paywallConsts';
import { useCookies } from 'react-cookie';

const useAutoActivateTrial = () => {
  const { startTrial, paywallAction } = usePaywall();
  const [cookies] = useCookies();
  const query = useQueryParams();

  const activateTrialCookie = cookies[ACTIVATE_TRIAL_COOKIE_NAME];
  const activateTrialQuery = query.has(ACTIVATE_TRIAL_QUERY_PARAM);
  const shouldActivateTrial = activateTrialQuery || activateTrialCookie;
  const canStartTrial = paywallAction === PaywallAction.START_TRIAL;

  useRunOnceWhenTruthy(() => {
    startTrial(PaywallSource.AUTO);
  }, shouldActivateTrial && canStartTrial);
};

export default useAutoActivateTrial;
