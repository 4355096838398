import { useSelector, useDispatch } from 'react-redux';
import { selectAddEnvDialogOpen, closeAddEnvDialog } from '../environmentsReducer';
import { ConnectCrmOrgDialog } from './ConnectCrmOrgDialog';

export const ConnectedConnectCrmOrgDialog = () => {
  const dispatch = useDispatch();
  const isAddEnvDialogOpen = useSelector(selectAddEnvDialogOpen());

  const _closeAddEnvDialog = () => dispatch(closeAddEnvDialog());

  return <ConnectCrmOrgDialog isOpen={isAddEnvDialogOpen} onClose={_closeAddEnvDialog} />;
};
