import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { ReactNode, useCallback, useState } from 'react';
import {
  rectSortingStrategy,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Box } from '@mui/material';
import { CSS } from '@dnd-kit/utilities';
import { Reorder } from '@sweep-io/sweep-design/dist/icons';
import { colors } from '@sweep-io/sweep-design';

export type GridItem = {
  id: string;
  renderFunction: (dragHandle: ReactNode, isOverlay: boolean) => ReactNode;
  value: any;
};

const arrayMoveItem = (array: any[], oldIndex: number, newIndex: number) => {
  const res = [...array];
  const element = res[oldIndex];
  res.splice(oldIndex, 1);
  res.splice(newIndex, 0, element);
  return res;
};

const GridLayoutDnd = ({
  items,
  itemsPerRow,
  onReorder,
  readonly,
}: {
  items: GridItem[];
  onReorder: (i: any) => void;
  itemsPerRow: number;
  readonly: boolean;
}) => {
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  const [draggedItemId, setDraggedItemId] = useState<string | null>(null);
  const draggedItemIndex = items.findIndex((item) => item.id === draggedItemId);

  const handleDragStart = useCallback((event: DragStartEvent) => {
    setDraggedItemId(event.active.id + '');
  }, []);

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      if (active.id !== over?.id) {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over?.id);
        const newValuesArray = arrayMoveItem(
          items.map((item) => item.value),
          oldIndex,
          newIndex,
        );
        onReorder(newValuesArray);
      }
      setDraggedItemId(null);
    },
    [items, onReorder],
  );
  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
    >
      <SortableContext
        items={items}
        strategy={itemsPerRow === 1 ? verticalListSortingStrategy : rectSortingStrategy}
      >
        <Box display="grid" gridTemplateColumns={`repeat(${itemsPerRow}, 1fr)`} gap="12px">
          {items.map((item) => (
            <SortableItem key={item.id} {...item} disabled={readonly} isOverlay={false} />
          ))}
        </Box>
      </SortableContext>
      <DragOverlay adjustScale style={{ transformOrigin: '0 0 ' }}>
        {draggedItemId ? (
          <SortableItem {...items[draggedItemIndex]} disabled={readonly} isOverlay={true} />
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};

const SortableItem = ({
  id,
  disabled,
  renderFunction,
  isOverlay,
}: {
  id: string;
  disabled: boolean;
  isOverlay: boolean;
  renderFunction: (dragHandle: ReactNode, isOverlay: boolean) => ReactNode;
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id,
    disabled,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined,
    background: colors.white,
  };

  return (
    <Box ref={setNodeRef} style={style} {...attributes}>
      {renderFunction(
        <Reorder {...listeners} color={disabled ? colors.grey[500] : colors.grey[800]} />,
        isOverlay,
      )}
    </Box>
  );
};

export default GridLayoutDnd;
