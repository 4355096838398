export enum WorkflowActionType {
  ENROLL_CAMPAIGN = 'SET_SALESFORCE_CAMPAIGN_MEMBERSHIP',
}

export enum WorkflowWhenType {
  FILTERS = 'FILTERS',
  EVENT = 'EVENT',
}

interface WorkflowActionEnrollCampaignDto {
  _id: string;
  actionType: WorkflowActionType;
  campaignId: string;
  status: string;
}

export enum WorkflowWhenEventTypes {
  AD_INTERACTION = 'AD_INTERACTION',
  FORM_INTERACTION = 'FORM_INTERACTION',
  REPLIED_TO_EMAIL = 'REPLIED_TO_EMAIL',
}

interface WorkflowWhenEvent {
  _id: string;
  whenType: WorkflowWhenType.EVENT;
  event: WorkflowWhenEventTypes;
  filter?: SweepCondition;
}

interface WorkflowWhenFilters {
  _id: string;
  whenType: WorkflowWhenType.FILTERS;
  filter: SweepCondition;
}

export type WorkflowAction = WorkflowActionEnrollCampaignDto;
export type WorkflowWhen = WorkflowWhenFilters | WorkflowWhenEvent;

interface WorkflowDetailDto {
  when: WorkflowWhen;
  actions: WorkflowAction[];
  onlyEnrollsManually: boolean;
}

export interface WorkflowBaseDto {
  id?: string;
  name: string;
  workflowDetails: WorkflowDetailDto;
  objectName: string;
}

export interface WorkflowCreateDto {
  name: string;
  workflowDetails: WorkflowDetailDto;
  objectName: string;
}

export interface WorkflowUpdateDto {
  name: string;
  workflowId: string;
  workflowDetails: WorkflowDetailDto;
}

export interface WorkflowDto extends Required<WorkflowBaseDto> {
  hubspotWorkflowId: string;
  hubspotOrgId: string;
  accountId: string;
  isActive: boolean;
  createdById: string;
  createdAt: Date;
  updatedById: string;
  updatedAt: Date;
}
