import { Box } from '@mui/material';
import { ObjectTypeChip } from '../../../../../common/ObjectTypeChip';
import { humanizeDate, humanizeDateVariants } from '../../../../../helpers/humanizeDate';
import { Button, IconButton, Typography, colors } from '@sweep-io/sweep-design';
import { ArrowLeft } from '@sweep-io/sweep-design/dist/icons';
import { useSelector } from 'react-redux';
import { selectAccountUsers } from '../../../../../../reducers/accountUsersReducer';
import { ParsedRecordType } from '../../../../../documentation/ParserTypes';
import { NotDeployedTag } from '../main-view/NotDeployedTag';
import { TruncatedTextTooltip } from '../../../../../common/TruncatedTextTooltip';
import useObjectTypesWithFetch from '../../../../../../hooks/useObjectTypesWithFetch';

interface PreviewHeaderProps {
  isLoadingButton: boolean;
  funnelStructure?: ShortFunnel;
  selectedRecordType?: ParsedRecordType;
  backToMainView: () => void;
  onAddItemToMapClick?: (selectedRecordType?: ParsedRecordType, funnel?: ShortFunnel) => void;
  isError?: boolean;
  crmOrgId: string;
}

export const PreviewHeader = ({
  funnelStructure,
  selectedRecordType,
  onAddItemToMapClick,
  backToMainView,
  isLoadingButton,
  isError,
  crmOrgId,
}: PreviewHeaderProps) => {
  const users = useSelector(selectAccountUsers);
  const updatedByName = users?.find((user) => user.id === funnelStructure?.updatedById)?.name;
  const { objectTypesByName } = useObjectTypesWithFetch({ crmOrgId });
  if (!funnelStructure) return;

  const objectType = funnelStructure.funnelDetails.leadingObject.objectName;
  const objectLabel = objectTypesByName[objectType]?.label ?? objectType;

  return (
    <Box
      sx={{
        display: 'flex',
        alignContent: 'flex-start',
        width: '100%',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignContent: 'center',
          flexWrap: 'nowrap',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '8px',
            gap: 1,
          }}
        >
          <IconButton variant="flat" size="large" onClick={backToMainView}>
            <ArrowLeft />
          </IconButton>

          <Box
            sx={{
              maxWidth: '70%',
            }}
          >
            <Typography variant="h2">{funnelStructure.name}</Typography>
          </Box>
          <ObjectTypeChip height="20px" label={objectLabel} objectType={objectType} />
        </Box>

        {!isError && (
          <Box ml={1}>
            <Button
              startIconName="Plus"
              onClick={() =>
                onAddItemToMapClick && onAddItemToMapClick(selectedRecordType, funnelStructure)
              }
              loading={isLoadingButton}
            >
              Add
            </Button>
          </Box>
        )}
      </Box>

      {!isError && funnelStructure.id !== 'MAPPED_RECORD_TYPE' && (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1, minWidth: 0 }}>
          {!funnelStructure.isDeployed && <NotDeployedTag />}

          {(updatedByName || funnelStructure.updatedAt) && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <TruncatedTextTooltip variant="caption" color={colors.grey[800]}>
                Last edited
                {!!updatedByName ? ' by ' + updatedByName : ' '}
                <Box component="span" sx={{ pl: 0.5, pr: 0.5 }}>
                  •
                </Box>
                {humanizeDate({
                  dateOrTimestamp: funnelStructure.updatedAt ?? '',
                  variant: humanizeDateVariants.SHORT_WITH_TIME,
                })}
              </TruncatedTextTooltip>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
