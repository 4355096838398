import { ReactNode } from 'react';
import { GET_STARTED_EVENTS_deprecated } from '../../../services/events';
import { ActionStatus, GetStartedActionIdDeprecated } from '@server/get-started';

export enum GetStartedSectionId {
  INTRO = 'INTRO',
  DOCUMENTATION = 'DOCUMENTATION',
  SETUP = 'SETUP',
  ADMIN = 'ADMIN',
  AUTOMATIONS = 'AUTOMATIONS',
  SLACK = 'SLACK',
  COLLABORATION = 'COLLABORATION',
  ROUTING = 'ROUTING',
  TEMPLATES = 'TEMPLATES',
  AI_ASSISTANT = 'AI_ASSISTANT',
}

//These types to be used for apis:
export type GetStartedDto = {
  [actionId in GetStartedActionIdDeprecated]: ActionStatus;
};
//////////////

//These types are only relevant to the UI
export enum GetStartedActionIdDerived {
  setupManagedPackage = 'setupManagedPackage',
  setupPermissionSet = 'setupPermissionSet',
  slackConnect = 'slackConnect',
  collaborationInvite = 'collaborationInvite',
}

export type GetStartedActionIdExtended = GetStartedActionIdDeprecated | GetStartedActionIdDerived;

export enum RequireSfConnectionType {
  NONE = 'NONE',
  ANY = 'ANY',
  PROD = 'PROD',
}

export type GetStartedAction = {
  id: GetStartedActionIdExtended;
  label: string;
  text: ReactNode;
  img: ReactNode;
  minsToComplete: number;
  requiredSfConnection: RequireSfConnectionType;
  requireFetched: boolean;
  requireManagedPackage: boolean;
  status: ActionStatus;
  biEvent?: GET_STARTED_EVENTS_deprecated;
  tourUrl?: string;
  secondaryBiEvent?: GET_STARTED_EVENTS_deprecated;
};

export type GetStartedSection = {
  id: GetStartedSectionId;
  adminOnly: boolean;
  label: string;
  icon: ReactNode;
  iconBgColor: string;
  actions: GetStartedAction[];
};
