import { colors, Typography } from '@sweep-io/sweep-design';
import { Button as MUIButton, Box } from '@mui/material';
import { Sparkle as SparkleIcon } from '@sweep-io/sweep-design/dist/icons';
import { useState } from 'react';

const CopilotButton = ({
  onClick,
  variant,
}: {
  onClick: () => void;
  variant: 'primary' | 'secondary';
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const isPrimary = variant === 'primary';
  const primarySx = {
    background: colors.blue[500],
    border: 'none',
    color: colors.white,
    '&:hover': {
      background: colors.blue[300],
    },
    '&:active': {
      background: colors.blue[600],
    },
  };

  const secondarySx = {
    background: colors.white,
    border: `1ps solid ${colors.blue[500]}`,
    color: colors.blue[500],
    '&:hover, &:active': {
      background: colors.white,
      color: colors.blue[600],
      borderColor: colors.blue[600],
    },
  };

  const colorsSx = isPrimary ? primarySx : secondarySx;
  return (
    <MUIButton
      sx={{
        padding: '6px 12px 6px 6px',
        height: '32px',
        borderRadius: '4px',
        ...colorsSx,
      }}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Box display="flex" gap="5px" alignItems="center">
        <SparkleIcon color={isPrimary ? colors.white : colors.blue[isHovered ? 600 : 500]} />
        <Typography
          color={isPrimary ? colors.white : colors.blue[isHovered ? 600 : 500]}
          variant="body-medium"
        >
          Copilot
        </Typography>
      </Box>
    </MUIButton>
  );
};

export default CopilotButton;
