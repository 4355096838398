import { FormControl, MenuItem } from '@mui/material';
import { SelectorValueTypes } from '../../../../types/enums/SelectorValueTypes';
import SweepSelect from '../../../common/SweepSelect';
import { selectFirstValueTypeOption } from '../utils/selectFirstValueTypeOption';
import { isFormulaSupported } from '../utils/isFormulaSupported';
import { selectFieldByFieldId } from '../../../../reducers/global/globalReducer';
import { useSelector } from 'react-redux';
import { AutomationActionRowProps } from '../types';
import { SweepFieldTypes } from '../../../../types/enums/SweepFieldTypes';
import {
  getVerifiedUrlOptionsByType,
  UrlVerificationFieldTypes,
} from '../utils/getVerifiedUrlByType';

export const TypeSelect = ({
  readonly,
  onChange,
  _field,
  crmOrgId,
  excludeValueType,
  showVerifiedUrlOption,
  setFormulaEditorStatePerKey,
}: Pick<
  AutomationActionRowProps,
  'readonly' | 'onChange' | 'crmOrgId' | 'excludeValueType' | 'showVerifiedUrlOption'
> & {
  _field: AutomationActionField;
  setFormulaEditorStatePerKey:
    | (({ key, value }: { key: string; value: boolean }) => void)
    | undefined;
}) => {
  //for creating new actions, we have the "fieldInfo" available
  //for editing existing actions, we have the "fieldSummary" available
  const fieldSummary = useSelector(selectFieldByFieldId(crmOrgId, _field._fieldIds[0]));
  const showFormulaOption = isFormulaSupported({ fieldSummary, field: _field._fieldInfo });

  const showPriorValueTypeAutomation =
    excludeValueType && !excludeValueType(SelectorValueTypes.PRIOR);

  const isFieldTypeWithAnyOptions = !!getVerifiedUrlOptionsByType(_field, showVerifiedUrlOption)
    ?.length;

  const isShowUrlVerificationOption =
    showVerifiedUrlOption &&
    isFieldTypeWithAnyOptions &&
    UrlVerificationFieldTypes.includes(_field?.fieldType as SweepFieldTypes);

  return (
    <FormControl sx={{ width: '100%' }}>
      <SweepSelect
        SelectProps={{
          disabled: !_field._fieldIds.length || readonly,
          placeholder: 'Choose Type',
          value: (_field?.valueType || _field?.fieldType) ?? '',
          onChange: (event) => {
            const val = event.target.value;
            if (
              val === SelectorValueTypes.REFERENCE ||
              val === SelectorValueTypes.RELATIVE ||
              val === SelectorValueTypes.FORMULA ||
              val === SelectorValueTypes.PRIOR ||
              val === SelectorValueTypes.VERIFY_URL
            ) {
              onChange({
                ..._field,
                valueType: val as SelectorValueTypes,
                value: '',
              });
              if (val === SelectorValueTypes.FORMULA) {
                setFormulaEditorStatePerKey &&
                  setFormulaEditorStatePerKey({ key: _field._id, value: true });
              }
            } else {
              selectFirstValueTypeOption({ onChange, _field });
            }
          },
          renderValue: (val) => {
            if (
              val === SelectorValueTypes.RELATIVE ||
              val === SelectorValueTypes.REFERENCE ||
              val === SelectorValueTypes.FORMULA
            ) {
              return val;
            }
            if (val === SelectorValueTypes.LITERAL) {
              return _field?.fieldType;
            }
            if (val === SelectorValueTypes.PRIOR) {
              return 'Prior value of';
            }
            if (val === _field?.fieldType) {
              return val;
            }
            if (val === SelectorValueTypes.VERIFY_URL) {
              return 'URL verification results';
            }
          },
        }}
      >
        <MenuItem value={SelectorValueTypes.LITERAL} hidden></MenuItem>
        <MenuItem value={_field?.fieldType}>{_field?.fieldType}</MenuItem>
        <MenuItem value={SelectorValueTypes.REFERENCE}>
          Value from the record that triggered this automation
        </MenuItem>
        {(_field?.fieldType === 'DateTime' || _field?.fieldType === 'Date') && (
          <MenuItem value={SelectorValueTypes.RELATIVE}>Relative Date</MenuItem>
        )}
        {showFormulaOption && <MenuItem value={SelectorValueTypes.FORMULA}>Formula</MenuItem>}
        {showPriorValueTypeAutomation && (
          <MenuItem value={SelectorValueTypes.PRIOR}>Field's prior value</MenuItem>
        )}
        {isShowUrlVerificationOption && (
          <MenuItem value={SelectorValueTypes.VERIFY_URL}>URL verification results</MenuItem>
        )}
      </SweepSelect>
    </FormControl>
  );
};
