import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { DEFAULT_TITLE, PAGE_TITLES } from '../constants/pageTitles';

const useUpdatePageTitles = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const routesWithTitles = Object.keys(PAGE_TITLES);
    const relevantRouteWithTitle = routesWithTitles.find((route) => pathname.startsWith(route));
    const title = relevantRouteWithTitle ? PAGE_TITLES[relevantRouteWithTitle] : DEFAULT_TITLE;
    document.title = title;
  }, [pathname]);
};

export default useUpdatePageTitles;
