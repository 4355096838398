import { Box } from '@mui/material';
import { IconButton } from '@sweep-io/sweep-design';
import { PaywallSource } from '../header/usePaywall';
import spaceVertical from './images/spaceVertical.jpg';
import { Close } from '@sweep-io/sweep-design/dist/icons';
import { BaseDialog } from '../../common/dialogs/BaseDialog';
import { ReactNode } from 'react';
import StartTrial from './StartTrial';

const StartTrialDialog = ({
  closeDialog,
  title,
  text,
  onConfirmCb,
  source,
}: {
  closeDialog: () => void;
  onConfirmCb?: () => void;
  title: string | ReactNode;
  text: string | ReactNode;
  source: PaywallSource;
}) => {
  return (
    <BaseDialog
      open={true}
      onClose={closeDialog}
      PaperProps={{
        sx: {
          display: 'flex',
          height: '396px',
          overflow: 'hidden',
          maxWidth: '820px',
        },
      }}
    >
      <Box sx={{ position: 'absolute', right: '16px', top: '16px', zIndex: 2 }}>
        <IconButton variant="flat" size="large" onClick={closeDialog}>
          <Close />
        </IconButton>
      </Box>
      <Box display="flex" height="100%" flex={1}>
        <Box sx={{ height: '100%', flex: '0 0 296px' }}>
          <img
            src={spaceVertical}
            alt={'Start your free trial image'}
            style={{ maxWidth: '296px', width: '100%' }}
          />
        </Box>
        <StartTrial
          title={title}
          text={text}
          source={source}
          onConfirmCb={onConfirmCb}
          onFinishCb={closeDialog}
          isDialogLayout={true}
        />
      </Box>
    </BaseDialog>
  );
};

export default StartTrialDialog;
