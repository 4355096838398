import { useState } from 'react';
import CanvasRightPanel from './CanvasRightPanel';
import CopilotChatConnected from '../../../../copilot/CopilotChatConnected';
import CopilotButton from '../../../../dashboard/copilot/CopilotButton';

const CopilotChatCanvas = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openCopilot = () => {
    setIsOpen(true);
  };

  const closeCopilot = () => {
    setIsOpen(false);
  };

  return (
    <>
      <CopilotButton onClick={openCopilot} variant="secondary"/>
      <CanvasRightPanel isOpen={isOpen} closePanel={closeCopilot}>
        <CopilotChatConnected closeCopilot={closeCopilot} />
      </CanvasRightPanel>
    </>
  );
};

export default CopilotChatCanvas;
