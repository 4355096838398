import { Box, Grid, MenuItem, SelectChangeEvent } from '@mui/material';
import { Button, Switch, Typography } from '@sweep-io/sweep-design';
import { useCallback, useContext } from 'react';
import { FlexBox } from '../../../../common/FlexBox';
import SweepSelect from '../../../../common/SweepSelect';
import { DateSelector } from '../../../../common/rule-builder/selectors/DateSelector';
import { TextFieldSelector } from '../../../../common/rule-builder/selectors/TextFieldSelector';
import { DialogHeaderTextField } from '../../../../common/create-edit-dialog/DialogHeaderTextField';
import { SearchSelect } from '../../../../common/SearchSelect';
import { selectCampaigns } from '../../../../../reducers/hubspotReducer';
import { useSelector } from 'react-redux';
import { HubspotCampaignCtx } from './HubspotCampaignCtx';
import { CampaignBase } from '../../../../../apis/facades/useCampaignsApiFacade';

const SfCampaignSearchSelect = ({
  campaignId,
  onChange,
  id,
  disabled,
}: {
  id?: string;
  campaignId?: string;
  onChange: (campaignId: string) => void;
  disabled?: boolean;
}) => {
  const sfCampaigns = useSelector(selectCampaigns);

  return (
    <SearchSelect
      id={id}
      label="Parent Campaign"
      items={(sfCampaigns || [])?.map((campaign) => ({
        label: campaign.Name,
        value: campaign.Id,
      }))}
      onChange={(item) => onChange(item.value)}
      dataTestId="simple-field-selector"
      SelectProps={{
        value: campaignId,
        placeholder: 'Choose Campaign',
      }}
      FormControlProps={{ fullWidth: true }}
      valueRenderer={(item) => item?.label}
      disabled={disabled}
    />
  );
};

export const HubspotCampaignForm = ({
  campaign,
  onChange,
  onDeploy,
  isSaving = true,
}: {
  campaign: CampaignBase;
  onChange: (campaignPartial: Partial<CampaignBase>) => any;
  onDeploy: () => any;
  isSaving?: boolean;
}) => {
  const _isSaving = Boolean(isSaving);
  const { campaignStatusOptions, campaignTypeOptions } = useContext(HubspotCampaignCtx);

  const onChangeProperty = useCallback(
    (property: keyof CampaignBase) => (value: CampaignBase[typeof property]) => {
      onChange({ [property]: value });
    },
    [onChange],
  );
  const onChangeSelectProperty = useCallback(
    (property: keyof CampaignBase) => (event: SelectChangeEvent<unknown>) =>
      onChange({ [property]: event.target.value as string }),
    [onChange],
  );

  const onChangeNumberProperty = useCallback(
    (property: keyof CampaignBase) => (value: string) => {
      onChange({ [property]: parseInt(value) });
    },
    [onChange],
  );

  const isValid = campaign.Name.length > 0;

  return (
    <Box mt={3} display="flex" flexDirection="column" gap={3}>
      <Box
        display="flex"
        justifyContent="flex-end"
        gap={2}
        sx={{
          button: {
            whiteSpace: 'nowrap',
            padding: '0 20px',
          },
        }}
      >
        <DialogHeaderTextField
          value={campaign.Name}
          onChange={onChangeProperty('Name')}
          placeholder="Name this campaign"
          editDisabled={_isSaving}
        />
        <Button
          onClick={onDeploy}
          size="small"
          disabled={!isValid || _isSaving}
          loading={_isSaving}
        >
          Deploy to Production
        </Button>
      </Box>
      <FlexBox gap={1}>
        <Switch
          checked={campaign.IsActive}
          onChange={(e, checked) => onChangeProperty('IsActive')(checked)}
          disabled={_isSaving}
        />
        <Typography variant="body">Active</Typography>
      </FlexBox>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <SweepSelect
            label="Type"
            FormControlProps={{
              fullWidth: true,
            }}
            SelectProps={{
              placeholder: 'Type',
              value: campaign.Type,
              onChange: (event: SelectChangeEvent<unknown>) =>
                onChangeProperty('Type')(event.target.value as string),
            }}
            disabled={_isSaving}
          >
            {campaignTypeOptions.map((option) => (
              <MenuItem key={option.name} value={option.name}>
                {option.label}
              </MenuItem>
            ))}
          </SweepSelect>
        </Grid>
        <Grid item xs={6}>
          <SweepSelect
            label="Status"
            FormControlProps={{
              fullWidth: true,
            }}
            SelectProps={{
              placeholder: 'Status',
              value: campaign.Status,
              onChange: onChangeSelectProperty('Status'),
            }}
            disabled={_isSaving}
          >
            {campaignStatusOptions.map((option) => (
              <MenuItem key={option.name} value={option.name}>
                {option.label}
              </MenuItem>
            ))}
          </SweepSelect>
        </Grid>
        <Grid item xs={6}>
          <DateSelector
            initialValue={campaign.StartDate}
            onChange={onChangeProperty('StartDate')}
            shouldBeDisabled={_isSaving}
            label="Start Date"
          />
        </Grid>
        <Grid item xs={6}>
          <DateSelector
            initialValue={campaign.EndDate}
            onChange={onChangeProperty('EndDate')}
            shouldBeDisabled={_isSaving}
            label="End Date"
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldSelector
            id="hubspot-campaign-expected-revenue"
            shouldBeDisabled={_isSaving}
            onChange={onChangeNumberProperty('ExpectedRevenue')}
            selectorType="number"
            label="Expected Revenue"
            fullWidth
            initialValue={campaign.ExpectedRevenue}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldSelector
            id="hubspot-campaign-budgeted-cost"
            shouldBeDisabled={_isSaving}
            onChange={onChangeNumberProperty('BudgetedCost')}
            selectorType="number"
            label="Budgeted Cost"
            fullWidth
            initialValue={campaign.BudgetedCost}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldSelector
            id="hubspot-campaign-actual-cost"
            shouldBeDisabled={_isSaving}
            onChange={onChangeNumberProperty('ActualCost')}
            selectorType="number"
            label="Actual Cost in Campaign"
            fullWidth
            initialValue={campaign.ActualCost}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldSelector
            id="hubspot-campaign-expected-response"
            shouldBeDisabled={_isSaving}
            onChange={onChangeNumberProperty('ExpectedResponse')}
            selectorType="number"
            label="Expected Response"
            fullWidth
            initialValue={campaign.ExpectedResponse}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldSelector
            id="hubspot-campaign-number-sent"
            shouldBeDisabled={_isSaving}
            onChange={onChangeNumberProperty('NumberSent')}
            selectorType="number"
            label="Number Sent in Campaign"
            fullWidth
            initialValue={campaign.NumberSent}
          />
        </Grid>
        <Grid item xs={6}>
          <SfCampaignSearchSelect
            id="hubspot-campaign-parent"
            campaignId={campaign.ParentId}
            onChange={onChangeProperty('ParentId')}
            disabled={_isSaving}
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldSelector
            id="hubspot-campaign-description"
            shouldBeDisabled={_isSaving}
            onChange={onChangeNumberProperty('Description')}
            label="Description"
            fullWidth
            initialValue={campaign.Description}
            InputProps={{
              multiline: true,
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
