import { Box, Collapse, Grid } from '@mui/material';
import { useState } from 'react';
import { ChevronDown, ChevronUp, Target } from '@sweep-io/sweep-design/dist/icons';
import { Radio, Tag, Typography, colors } from '@sweep-io/sweep-design';
import { StyledRadioGroup } from '../../common/StyledComponents';
import { CustomHeader } from '../../common/rule-builder/CustomHeader';
import { SweepBinaryRuleBuilder } from '../../common/sweep-condition-rule-builder/SweepConditionRuleBuilder';
import { useRefForMultipleRuleBuilders } from '../../common/sweep-condition-rule-builder/MultipleRuleBuildersContext';
import { StyledFormControlLabel, greyLayout, settingsPanelLayout } from './helpers';
import { SweepFieldOperator } from '../../SweepFieldsOperatorSelector/sweepFieldsTypesAndOperators';
import { SweepFieldTypes } from '../../../types/enums/SweepFieldTypes';
import FieldLabel from '../../common/FieldLabel';

interface MatchingLogicFilterPanelProps {
  matchingLogic?: SweepCondition;
  readonly?: boolean;
  crmOrgId: string;
  objectType: string;
  onChange: (filter?: SweepCondition) => any;
  referenceObjectType?: string;
}

enum SweepFilterType {
  HAS = 'HAS',
  EMPTY = 'EMPTY',
}

const MatchingLogicFilterPanel = ({
  readonly,
  crmOrgId,
  objectType,
  onChange,
  referenceObjectType,
  matchingLogic,
}: MatchingLogicFilterPanelProps) => {
  const [isPanelExpanded, setIsPanelExpanded] = useState(!!matchingLogic);
  const [selectFilterType, setSelectFilterType] = useState<SweepFilterType>(
    matchingLogic ? SweepFilterType.HAS : SweepFilterType.EMPTY,
  );
  const ruleBuilderRefInclude = useRefForMultipleRuleBuilders();

  const filterOperatorBy = (
    operatorList: {
      label: string;
      operator: SweepFieldOperator;
    }[],
    fieldType?: SweepFieldTypes,
  ) => {
    const newOperators = [{ label: 'Exact', operator: SweepFieldOperator.EXACT }];

    if (fieldType && fieldType === SweepFieldTypes.Url) {
      newOperators.push({ label: 'Fuzzy', operator: SweepFieldOperator.FUZZY });
    }
    return [...newOperators];
  };

  const headerRowComponent = (
    <Box sx={{ display: 'flex', marginLeft: '36px', mb: '16px', pr: '36px' }}>
      <Grid container flexWrap="nowrap">
        <Grid item xs={4}>
          <Tag label={`Potential duplicate`} color={colors.grey[200]} />
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={5}>
          <Tag label={`Triggering ${objectType?.toLowerCase()}`} color={colors.grey[200]} />
        </Grid>
      </Grid>
    </Box>
  );

  const preFillOperators = (sweepCondition: SweepCondition) => {
    const conditionsWithOperator = sweepCondition?.criteria?.map((el) => {
      if (el?.fieldType && !el?.operator && el?.fieldType !== SweepFieldTypes.Url) {
        el.operator = SweepFieldOperator.EXACT;
        return el;
      } else return el;
    });
    return { criteriaLogic: sweepCondition.criteriaLogic, criteria: conditionsWithOperator };
  };

  return (
    <Box sx={{ ...settingsPanelLayout }}>
      <Collapse in={isPanelExpanded} collapsedSize={24}>
        <Box className="panelHeader" onClick={() => setIsPanelExpanded(!isPanelExpanded)}>
          <Box sx={{ display: 'flex', gap: '16px' }}>
            <Target variant="large" />
            <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '2px' }}>
              <Typography variant="body-bold">Duplicates logic</Typography>
              <Typography variant="body">
                Set the logic how to identify a potential duplicate
              </Typography>
            </Box>
          </Box>
          <Box>
            {isPanelExpanded ? <ChevronUp variant="large" /> : <ChevronDown variant="large" />}
          </Box>
        </Box>
        <Box sx={{ marginTop: '16px', marginLeft: '44px' }}>
          <StyledRadioGroup
            sx={{ marginTop: '12px' }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const value = (event.target as HTMLInputElement).value as SweepFilterType;
              setSelectFilterType(value);
              if (value === SweepFilterType.EMPTY) {
                onChange(undefined);
              }
            }}
            value={selectFilterType}
          >
            <StyledFormControlLabel
              disabled={readonly}
              value={SweepFilterType.EMPTY}
              control={<Radio />}
              label={
                <FieldLabel
                  label={'Default Sweep logic'}
                  showTooltip
                  infoTooltipTitle={
                    'We leverage a combination of exact and fuzzy logic on standard fields like email, name, company, phone, and more to ensure an accurate detection of duplicates.'
                  }
                />
              }
            />
            <StyledFormControlLabel
              disabled={readonly}
              value={SweepFilterType.HAS}
              control={<Radio />}
              label={<Typography variant="body">Custom logic</Typography>}
            />
            {selectFilterType === SweepFilterType.HAS && (
              <Box sx={{ ...greyLayout }}>
                <>
                  <CustomHeader customHeader="Set your custom logic to find duplicates" />
                  <SweepBinaryRuleBuilder
                    filterOperatorBy={filterOperatorBy}
                    headerRowComponent={headerRowComponent}
                    referenceObjectType={objectType}
                    readOnly={readonly}
                    crmOrgId={crmOrgId}
                    objectType={referenceObjectType ?? ''}
                    sweepCondition={matchingLogic}
                    ref={ruleBuilderRefInclude}
                    nestedSelectorFilterBy={(field) =>
                      field.fieldType !== SweepFieldTypes.LongTextArea
                    }
                    onChange={(sweepCondition) => {
                      const tempCondition = preFillOperators(sweepCondition);
                      onChange(tempCondition);
                    }}
                    disableResolvePolymorphic
                  />
                </>
              </Box>
            )}
          </StyledRadioGroup>
        </Box>
      </Collapse>
    </Box>
  );
};

export default MatchingLogicFilterPanel;
