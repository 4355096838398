import { useSweepApi } from '../sweep';
import { useCallback } from 'react';
import { UserInfo, UserPreferences } from '../../types/UserInfoTypes';
import { AccountOnboardingStep } from '@server/account-onboarding';

export const useUserApiFacade = () => {
  const sweepApi = useSweepApi();
  return {
    fetch_current_user: useCallback(async () => {
      const response = await sweepApi.get(`/users`);
      return response.data as UserInfo;
    }, [sweepApi]),
    delete_user: useCallback(
      async (_id: string) => {
        const response = await sweepApi.delete(`/users/${_id}`);
        return response.data as boolean;
      },
      [sweepApi],
    ),
    delete_invitation: useCallback(
      async (_id: string) => {
        const response = await sweepApi.delete(`/users/account/invitations/${_id}`);
        return response.data as boolean;
      },
      [sweepApi],
    ),
    patch_updateOnboardingStatus: useCallback(
      async (status: AccountOnboardingStep) => {
        const res = await sweepApi.patch('/users/account/onboarding', {
          status,
        });
        return res.data;
      },
      [sweepApi],
    ),
    update_userPreferences: useCallback(
      async (updatedPreferences: UserPreferences) => {
        const { getStartedDeprecated, getStarted } = updatedPreferences;
        const preferencesForApi = {
          ...updatedPreferences,
          getStartedDeprecated,
          getStarted,
        };
        const res = await sweepApi.put('/users/preferences', preferencesForApi);
        return res.data;
      },
      [sweepApi],
    ),
    post_accountTrial: useCallback(async () => {
      await sweepApi.post('/users/account/trial');
    }, [sweepApi]),
  };
};
