import { useEffect, useRef, useState } from 'react';
import { Box, Fade, ListItemButton, MenuList, Popover } from '@mui/material';
import { keyBy } from 'lodash';
import { Button, Tag, Typography, colors } from '@sweep-io/sweep-design';
import { TagItem, TextFieldWithTags } from '../../../common/TextFieldWithTags';
import { filterItemsBySearch } from '../../../../lib/filterItemsBySearch';
import { dataTableVariants } from '../../../common/table/dataTableVariants';
import { DataTableVariant } from '../../../common/table/TableTypes';

export interface InlineAutocompleteItem<T = any> {
  value: string;
  label: string;
  labelDecorator?: string;
  tagColor?: string;
  data?: T;
}

interface InlineAutocompleteProps<T> {
  items: InlineAutocompleteItem<T>[];
  selectedItemValues: string[];
  onSelectItem?: (item: InlineAutocompleteItem<T>) => void;
  onDeleteItem?: (item: InlineAutocompleteItem<T>) => void;
  onCreate?: (label: string) => void;
  placeholder?: string;
  createInProgress?: boolean;
  closeOnItemSelection?: boolean;
}

const getItemLabelWitLabelDecorator = (item: InlineAutocompleteItem) => {
  return `${item.labelDecorator ? item.labelDecorator + ' ' : ''}${item.label}`;
};

export function InlineAutocomplete<T = any>({
  items,
  selectedItemValues,
  onSelectItem,
  onDeleteItem,
  placeholder,
  onCreate,
  createInProgress,
  closeOnItemSelection,
}: InlineAutocompleteProps<T>) {
  const [currentSearch, setCurrentSearch] = useState('');
  const itemsByKey = keyBy(items, 'value');

  const [isOpen, setIsOpen] = useState(false);
  const inlineTextFieldRef = useRef<HTMLDivElement>(null);

  const nonSelectedItems = items.filter((item) => !selectedItemValues.includes(item.value));

  const filteredItems = filterItemsBySearch(nonSelectedItems, currentSearch, 'label');
  const currentSearchHasExactMatch = items.some((item) => item.label === currentSearch);

  useEffect(() => {
    setCurrentSearch('');
  }, [isOpen]);
  useEffect(() => {
    if (!selectedItemValues.length) {
      setIsOpen(false);
    }
  }, [selectedItemValues.length]);

  const tags: TagItem[] = selectedItemValues.map((value) => ({
    value,
    label: getItemLabelWitLabelDecorator(itemsByKey[value]),
    onDelete: () => {
      onDeleteItem && onDeleteItem(itemsByKey[value]);
    },
    color: itemsByKey[value].tagColor,
  }));
  if (createInProgress) {
    tags.push({
      value: 'create-in-progress',
      label: 'Creating...',
      isLoader: true,
    });
  }

  return (
    <Box>
      {selectedItemValues.length === 0 && (
        <Box padding="0 16px" display="flex" gap={1} alignItems="center" ref={inlineTextFieldRef}>
          <Typography
            variant={dataTableVariants[DataTableVariant.default].fontVariant}
            color={colors.grey[500]}
            whiteSpace="nowrap"
          >
            Member not assigned
          </Typography>
          <Box className="add-to-group" sx={{ '& .MuiButton-root': { whiteSpace: 'nowrap' } }}>
            <Button variant="link" onClick={() => setIsOpen(true)}>
              Add to group
            </Button>
          </Box>
        </Box>
      )}
      {selectedItemValues.length > 0 && (
        <TextFieldWithTags
          placeholder={placeholder}
          ref={inlineTextFieldRef}
          tags={tags}
          hideDeleteButton
          readonly={isOpen || createInProgress}
          onClick={() => {
            setIsOpen(true);
          }}
          sx={{
            opacity: isOpen ? 0 : 1,
            transition: isOpen ? 'opacity 2s' : '',
            input: {
              display: selectedItemValues.length ? 'none' : undefined,
            },
            '& .MuiInputBase-adornedStart': {
              paddingRight: selectedItemValues.length ? '12px' : '',
            },
            '& .MuiInputBase-root': {
              background: 'transparent',
              paddingRight: '80px',
              '&, &.Mui-disabled': {
                backgroundColor: colors.white,
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: 'transparent',
                },
              },

              '&:hover, &.Mui-focused': {
                background: colors.white,
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: colors.grey[300],
                },
              },
            },
          }}
        />
      )}

      <Popover
        open={isOpen}
        anchorEl={inlineTextFieldRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={() => {
          setIsOpen(false);
        }}
        slotProps={{
          paper: {
            sx: {
              marginLeft: '-12px',
              marginTop: '-12px',
            },
          },
        }}
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 0 }}
      >
        <Box>
          <Box padding="12px" display="flex" flexDirection="column" gap="20px">
            <TextFieldWithTags
              value={currentSearch}
              tags={tags}
              onChange={(e) => {
                setCurrentSearch(e.target.value);
              }}
              autoFocus
            />
            <Typography variant="caption-bold" color={colors.grey[500]}>
              Select a group or create one
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column">
            <MenuList dense sx={{ padding: '0 0 12px 0' }}>
              {filteredItems.map((item) => (
                <ListItemButton
                  key={item.value}
                  onClick={() => {
                    closeOnItemSelection && setIsOpen(false);
                    onSelectItem && onSelectItem(item);
                  }}
                >
                  <Tag label={getItemLabelWitLabelDecorator(item)} color={item.tagColor} />
                </ListItemButton>
              ))}
            </MenuList>
            {currentSearch && !currentSearchHasExactMatch && (
              <Box padding="0px 12px 12px 12px">
                <Button
                  startIconName="Plus"
                  variant="link"
                  onClick={() => {
                    onCreate && onCreate(currentSearch);
                    setIsOpen(false);
                  }}
                >
                  Create
                  <Tag label={currentSearch} size="medium" />
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Popover>
    </Box>
  );
}
