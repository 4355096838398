import { ButtonBase, Menu, MenuItem, Popover, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useRef, useState } from 'react';
import { DIMENSION_AVATAR_BIG, DIMENSION_AVATAR_BIG_MEDIUM } from '../../constants';
import { defaultEmojis } from '../../constants/defaultEmojis';
import { Camera as CameraIcon } from '@sweep-io/sweep-design/dist/icons';
import { colors } from '@sweep-io/sweep-design';
import { Emoji } from './Emoji';
import { AvatarPicture } from './AvatarPicture';
import { UploadImage } from './UploadImage';
import { AvatarType, EmojiType } from './avatarTypes';

const StyledSelectAvatar = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
  '.avatar-button': {
    '.shadow-circle': {
      background: '#000000',
      borderRadius: '50%',
      position: 'absolute',
      opacity: 0,
    },
    '.camera-icon': {
      display: 'none',
      position: 'absolute',
    },
    '&:hover, &.is-active': {
      cursor: 'pointer',
      '.shadow-circle': {
        opacity: 0.5,
      },
      '.camera-icon': {
        display: 'block',
        opacity: 1,
      },
    },
  },
});

export interface SelectAvatarProps {
  avatar?: AvatarType;
  avatarPictureSize?: number;
  avatarEmojiSize?: number;
  onChange: (avatar: AvatarType) => void | Promise<void>;
  emojis?: EmojiType[];
  disableImageUpload?: boolean;
}

const SelectAvatar = ({
  avatar,
  onChange,
  avatarPictureSize = DIMENSION_AVATAR_BIG,
  avatarEmojiSize = DIMENSION_AVATAR_BIG_MEDIUM,
  emojis = defaultEmojis,
  disableImageUpload,
}: SelectAvatarProps) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpened = Boolean(menuAnchorEl);
  const [isEditImageOpened, setIsEditImageOpened] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const cameraRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [selectedImageSrc, setSelectedImageSrc] = useState<null | string>(null);

  const [emojiBoxAnchorEl, setEmojiBoxAnchorEl] = React.useState<HTMLElement | null>(null);
  const isEmojiBoxOpened = Boolean(emojiBoxAnchorEl);

  const handleCameraClick = () => {
    setMenuAnchorEl(cameraRef.current);
    setIsActive(true);
  };

  const handleSelectEmojiClicked = () => {
    setEmojiBoxAnchorEl(cameraRef.current);
    setMenuAnchorEl(null);
  };

  const handleClose = () => {
    setIsActive(false);
    setMenuAnchorEl(null);
    setEmojiBoxAnchorEl(null);
    setIsEditImageOpened(false);
  };

  const handleUploadImageSelected = () => {
    inputRef.current.click();
  };

  const onImageSelection = ({ target: { files } }: React.ChangeEvent<HTMLInputElement>) => {
    setMenuAnchorEl(null);
    if (files) {
      const _image = files[0];
      setSelectedImageSrc(URL.createObjectURL(_image));
      setIsEditImageOpened(true);
    }
  };

  const _onConfirmImageUpload = async (imageBase64Blob: string) => {
    await onChange({
      emoji: undefined,
      imageUrl: imageBase64Blob,
      imageUploadBase64: imageBase64Blob,
    });
    handleClose();
  };

  const handleEmojiBoxClose = () => {
    setEmojiBoxAnchorEl(null);
  };

  const onEmojiSelection = async (index: number) => {
    await onChange({
      emoji: emojis[index],
      imageUrl: undefined,
    });
    handleClose();
  };

  return (
    <div data-testid="select-avatar">
      <StyledSelectAvatar className={`select-avatar ${isActive ? 'is-active' : ''}`}>
        <ButtonBase
          sx={{ padding: 0 }}
          disableRipple
          onClick={handleCameraClick}
          data-testid="current-avatar-button"
          className={`avatar-button ${isActive ? 'is-active' : ''}`}
        >
          <AvatarPicture
            avatar={avatar}
            dimension={avatarPictureSize}
            clickable={false}
            isInactive={false}
          />
          <div
            className="shadow-circle"
            style={{
              width: avatarPictureSize,
              height: avatarPictureSize,
            }}
          />
          <div
            ref={cameraRef}
            className="camera-icon"
            style={{
              top: avatarPictureSize / 2 - 7,
              left: avatarPictureSize / 2 - 11,
            }}
          >
            <CameraIcon variant="large" color={colors.white} />
          </div>
        </ButtonBase>

        <Menu
          id="avatar-menu"
          anchorEl={menuAnchorEl}
          open={isMenuOpened}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
            // @ts-ignore
            'data-testid': 'avatar-menu',
          }}
        >
          {!disableImageUpload && (
            <MenuItem
              disableRipple
              onClick={handleUploadImageSelected}
              data-testid="menu-item-image"
            >
              <>
                Upload Image
                <input
                  ref={inputRef}
                  data-testid="input-file"
                  hidden
                  type="file"
                  accept="image/*"
                  onChange={onImageSelection}
                />
              </>
            </MenuItem>
          )}
          <MenuItem disableRipple onClick={handleSelectEmojiClicked} data-testid="menu-item-emoji">
            Select Emoji
          </MenuItem>
        </Menu>

        {isEditImageOpened && selectedImageSrc && (
          <UploadImage
            originSrc={selectedImageSrc}
            onConfirmCb={_onConfirmImageUpload}
            onCancelCb={handleClose}
          />
        )}
        <Popover
          open={isEmojiBoxOpened}
          anchorEl={emojiBoxAnchorEl}
          onClose={handleEmojiBoxClose}
          sx={{ zIndex: 9999 }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{ horizontal: 'center', vertical: 'top' }}
          PaperProps={{
            sx: {
              boxShadow: '0px 30px 50px rgba(0, 0, 0, 0.1)',
              borderRadius: '16px',
              width: '395px',
              overflowY: 'hidden',
            },
          }}
          data-testid="emoji-select-wrapper"
        >
          <EmojiSelectBox
            callback={onEmojiSelection}
            avatarEmojiSize={avatarEmojiSize}
            emojis={emojis}
          />
        </Popover>
      </StyledSelectAvatar>
    </div>
  );
};

export { SelectAvatar };

interface EmojiSelectBoxProps {
  callback: (index: number) => void;
  avatarEmojiSize: number;
  emojis: EmojiType[];
}

const EmojiSelectBox = ({ callback, avatarEmojiSize, emojis }: EmojiSelectBoxProps) => {
  return (
    <Grid
      container
      height="100%"
      display="flex"
      gap="25px"
      padding="36px"
      sx={{
        overflowY: 'auto',
      }}
      data-testid="emoji-select-box"
    >
      {emojis.map((emoji, index) => {
        const { content, bgColor } = emoji;
        const specificCallback = () => callback(index);
        return (
          <Grid item key={index}>
            <Emoji
              index={index}
              content={content}
              bgColor={bgColor}
              onClick={specificCallback}
              dimension={avatarEmojiSize}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};
