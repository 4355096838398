import { useDispatch, useSelector } from 'react-redux';
import {
  selectCanvasFilterSelectedValuesFor,
  setCanvasFilterItemsDataFromFilterItems,
  setCanvasFilterSelectedValuesFor,
} from './canvasFiltersReducer';
import { ChangeEvent, useCallback, useEffect } from 'react';
import { Box } from '@mui/material';
import { Typography, Switch } from '@sweep-io/sweep-design';

interface ToggleFilterProps {
  label: string;
  checked: boolean;
  filterKey: string;
}

export function ToggleFilter({ filterKey, checked, label }: ToggleFilterProps) {
  const dispatch = useDispatch();
  const values = useSelector(selectCanvasFilterSelectedValuesFor(filterKey));
  const isChecked = values.length > 0 && values[0] === 'true';

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      dispatch(
        setCanvasFilterSelectedValuesFor({
          filterKey,
          selectedValues: [checked.toString()],
        }),
      );
    },
    [dispatch, filterKey],
  );

  useEffect(() => {
    dispatch(
      setCanvasFilterItemsDataFromFilterItems({
        filterKey,
        items: [
          {
            label,
            value: checked.toString(),
          },
        ],
      }),
    );
  }, [dispatch, filterKey, checked, label]);

  return (
    <Box display="flex" gap={1} alignItems="center">
      <Switch checked={isChecked} onChange={onChange} />
      <Typography whiteSpace="nowrap" variant={'caption'}>
        {label}
      </Typography>
    </Box>
  );
}
