import { Box, Card, CardActions, Typography } from '@mui/material';
import { colors } from '@sweep-io/sweep-design';

export const PageTemplateCard = ({
  stripeColor1,
  stripeColor2,
  headerContent,
  description,
  onCardClick,
  actionsContent,
}: {
  stripeColor1?: string;
  stripeColor2?: string;
  headerContent: React.ReactNode;
  description: string;
  onCardClick?: (event: any) => void;
  actionsContent: React.ReactNode;
}) => {
  const _color1 = stripeColor1 || colors.blue[100];
  const _color2 = stripeColor2 || stripeColor1;

  const background = `linear-gradient(90deg, ${_color1} 0%, ${_color2} 100%);`;
  return (
    <Box sx={{ maxWidth: '416px', width: '30%' }}>
      <Card
        variant="outlined"
        sx={{
          padding: '32px 24px 24px 24px',
          position: 'relative',
          '&::before': {
            position: 'absolute',
            content: '""',
            background,
            height: '8px',
            width: '100%',
            top: 0,
            left: 0,
          },
        }}
        onClick={onCardClick}
      >
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', height: '40px' }}>
          {headerContent}
        </Box>
        <Box sx={{ height: '128px', display: 'flex', alignItems: 'center' }}>
          <Typography variant="h2">{description}</Typography>
        </Box>
        <CardActions
          sx={{
            '& >.MuiBox-root': {
              width: '100%',
            },
            '& button': {
              width: '100%',
            },
          }}
        >
          {actionsContent}
        </CardActions>
      </Card>
    </Box>
  );
};
