import { ListItemButton, ListItemAvatar, ListItemText, Box, styled } from '@mui/material';
import { keyBy } from 'lodash';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactTimeAgo from 'react-time-ago';
import { useNotificationsFacade } from '../../../apis/facades/useNotificationsFacade';
import { selectAccountUsers } from '../../../reducers/accountUsersReducer';
import {
  NotificationDto,
  setNotificationAsRead,
} from '../../../reducers/notificationsCenterReducers';
import { AvatarPicture } from '../../avatar/AvatarPicture';
import useSweepNavigate from '../../common/useSweepNavigate';
import { TextWithMentions } from '../../comments/TextWithMentions';
import { Typography, colors } from '@sweep-io/sweep-design';
import { getIsUserInactive } from '../../../lib/getIsUserActive';

const RedDot = styled(Box)({
  borderRadius: '50%',
  width: '8px',
  height: '8px',
  backgroundColor: colors.blush[500],
});

export const NotificationCenterItem = ({
  notification,
  onClose,
}: {
  notification: NotificationDto;
  onClose: () => any;
}) => {
  const dispatch = useDispatch();
  const { navigate } = useSweepNavigate();
  const accountUsers = keyBy(useSelector(selectAccountUsers), 'id');
  const senderUserInfo = accountUsers[notification.senderId];
  const { post_notifications_events_read } = useNotificationsFacade();

  const readNotification = useCallback(async () => {
    const { totalUnread } = await post_notifications_events_read({
      notificationIds: [notification.id],
    });
    dispatch(setNotificationAsRead({ notificationId: notification.id, totalUnread }));
    const { origin } = new URL(notification.deepLink);
    const link = notification.deepLink.replace(origin, '');

    navigate(link, { replace: true, clearSearch: true });
    onClose();
  }, [
    dispatch,
    navigate,
    notification.deepLink,
    notification.id,
    onClose,
    post_notifications_events_read,
  ]);

  return (
    <ListItemButton alignItems="flex-start" sx={{ px: 3, py: 2 }} onClick={readNotification}>
      <ListItemAvatar>
        <Box position="relative" width="fit-content">
          <AvatarPicture
            avatar={{ emoji: senderUserInfo?.emoji, imageUrl: senderUserInfo?.imageUrl }}
            dimension={30}
            clickable={false}
            isInactive={getIsUserInactive(senderUserInfo?.status)}
          />
          {!notification.isRead && (
            <RedDot
              sx={{
                position: 'absolute',
                right: 0,
                top: 0,
              }}
            />
          )}
        </Box>
      </ListItemAvatar>

      <ListItemText
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
        }}
        primary={
          <Typography variant="body-bold" color={colors.grey[700]}>
            <TextWithMentions text={notification.title} highlightColor={colors.black} />
          </Typography>
        }
        secondary={
          <>
            <Typography variant="body" color={colors.grey[700]}>
              <TextWithMentions text={notification.body} disableHighlight />
            </Typography>
            <Typography variant="caption" color={colors.grey[700]}>
              <ReactTimeAgo date={new Date(notification.createdAt)} locale="en-US" />
            </Typography>
          </>
        }
      />
    </ListItemButton>
  );
};
