export type BiEvent = {
  name:
    | UI_EVENTS
    | GET_STARTED_EVENTS_deprecated
    | GET_STARTED_EVENTS
    | SIGN_UP_EVENTS
    | ACTIONS_EVENTS
    | PAGE_VISIT_EVENTS
    | PAYWALL_EVENTS;
  props?: Record<string, any>;
};

export enum UI_EVENTS {
  automationsDialogOpened = 'automations_dialog_opened',
  documentationShowMore = 'documentation:show_more',
  documentationAiEngage = 'documentation:ai_engage',
  automationsOpen = 'automations:open_card',
  alertsOpen = 'alerts:open_card',
  assignmentsOpen = 'assignments:open_card',
  matchingDedupeOpen = 'matching_dedupe:open_card',
  scheduledAssignmentsOpen = 'scheduled_assignments:open_card',
  scheduledReportsOpen = 'scheduled_reports:open_card',
  documentationOpen = 'documentation:open_card',
  funnelsOpen = 'funnels:open_canvas',
  rollupsOpen = 'rollups:open_card',
  completeSetupOpen = 'complete_setup:open_card',
}

export enum PAYWALL_EVENTS {
  clickTrialMain = 'trial_click_main',
  trialActivated = 'trial_activated',
  paywall = 'paywall',
}

export enum ACTIONS_EVENTS {
  inviteUser = 'actions:invite_user',
  documentationAddObject = 'documentation:add_object',
  documentationFullPageClick = 'documentation:full_page_click',
  documentationCanvasViewClick = 'documentation:canvas_view_click',
  funnelsAddFunnel = 'funnels:add_funnel',
  addComment = 'add_comment',
  slackConnect = 'integrations:connect_slack',
  hubspotConnect = 'integrations:connect_hubspot',
  visualizeRecordType = 'visualize_record_type',
  changeFeedAddNotification = 'change_feed:add_notification',
  automationsAdd = 'automations:add_automation',
  alertsAdd = 'alerts:add_alert',
  assignmentsAdd = 'assignments:add_assignment',
  matchingDedupeAdd = 'matching_dedupe:add_dm',
  assignmentGroupsAdd = 'assignment_groups:add_group',
  gatesAdd = 'gates:add_gate',
  scheduledAssignmentsAdd = 'scheduled_assignments:add_scheduled_assignment',
  territoriesAdd = 'territories:add_territory',
  rollupsAdd = 'rollups:add_rollup',
  automationsDeploySuccess = 'automations:deploy_success',
  alertsDeploySuccess = 'alerts:deploy_success',
  assignmentsDeploySuccess = 'assignments:deploy_success',
  matchingDedupeDeploySuccess = 'matching_dedupe:deploy_success',
  scheduledAssignmentsDeploySuccess = 'scheduled_assignments:deploy_success',
  rollupsDeploySuccess = 'rollups:deploy_success',
  funnelsDeploySuccess = 'funnels:deploy_success',
  fetchDoneInSession = 'fetch_done_in_session',
  sfSandboxConnect = 'sf_sandbox_connect',
  sfProdConnect = 'sf_prod_connect',
  devopsCenterDeploySuccess = 'devops_center:deploy_success',
  scheduledReportsAdd = 'scheduled_reports:add_scheduled_reports',
  scheduledReportsDeploySuccess = 'scheduled_reports:deploy_success',
  removeEnv = 'remove_env',
  assignSweepPsg = 'assign_sweep_psg', // psg => "permission set group"
  automationsDelete = 'automations:delete_automation',
  alertsDelete = 'alerts:delete_alerts',
  assignmentsDelete = 'assignments:delete_assignment',
  matchingDedupeDelete = 'matching_dedupe:delete_dm',
  scheduledAssignmentsDelete = 'scheduled_assignments:delete_scheduled_assignment',
  scheduledReportsDelete = 'scheduled_reports:delete_scheduled_reports',
  assignmentGroupsDelete = 'assignment_groups:delete_group',
  gatesDelete = 'gates:delete_gate',
  territoriesDelete = 'territories:delete_territory',
}

export enum GET_STARTED_EVENTS_deprecated {
  introWatchVideo = 'get_started:watch_welcome_video',
  documentationTakeTour = 'get_started:launch_documentation_tour',
  adminRollupsTakeTour = 'get_started:launch_rollups_tour',
  adminChangeFeedTakeTour = 'get_started:launch_change_feed_tour',
  automationsTakeTour = 'get_started:launch_automations_tour',
  slackTakeTour = 'get_started:launch_slack_alerts_tour',
  slackConnect = 'get_started:slack_connect',
  collaborationTakeTour = 'get_started:launch_collaboration_tour',
  routingTakeTour = 'get_started:launch_routing_tour',
  templatesTakeTour = 'get_started:launch_templates_tour',
  templatesNavigate = 'get_started:open_template_center',
  sfSandboxConnect = 'get_started:sf_sandbox_connect',
  sfProdConnect = 'get_started:sf_prod_connect',
  aiAssistantTakeTour = 'get_started:launch_ai_assistant_tour',
}

export enum GET_STARTED_EVENTS {
  documentationTakeTour = 'get_started:launch_documentation_tour',
  documentationNavigate = 'get_started:documentation_navigate',
  automationsTakeTour = 'get_started:launch_automations_tour',
  automationsNavigate = 'get_started:automations_navigate',
  slackTakeTour = 'get_started:launch_slack_alerts_tour',
  slackNavigate = 'get_started:slack_alerts_navigate',
  rollupsTakeTour = 'get_started:launch_rollups_tour',
  rollupsNavigate = 'get_started:rollups_navigate',
  assignmentTakeTour = 'get_started:launch_assignment_tour',
  assignmentNavigate = 'get_started:assignment_navigate',
  dedupeTakeTour = 'get_started:launch_dedupe_tour',
  dedupeNavigate = 'get_started:dedupe_navigate',
  visualizeTakeTour = 'get_started:launch_visualize_tour',
  visualizeNavigate = 'get_started:visualize_navigate',
  optimizeTakeTour = 'get_started:launch_optimize_tour',
  optimizeNavigate = 'get_started:optimize_navigate',
  changeFeedTakeTour = 'get_started:launch_change_feed_tour',
  changeFeedNavigate = 'get_started:change_feed_navigate',
  sfSandboxConnect = 'get_started:sf_sandbox_connect',
  sfProdConnect = 'get_started:sf_prod_connect',
}

export enum SIGN_UP_EVENTS {
  newUserLogin = 'signup:new_user_login',
  accountCreated = 'signup:account_created',
  sfSandboxConnect = 'signup:sf_sandbox_connect',
  sfProdConnect = 'signup:sf_prod_connect',
}

export enum PAGE_VISIT_EVENTS {
  funnels = 'visit_pages:visit_funnels',
  documentation = 'visit_pages:visit_documentation',
  changeFeed = 'visit_pages:visit_change_feed',
  rollups = 'visit_pages:visit_rollups',
  automations = 'visit_pages:visit_automations',
  alerts = 'visit_pages:visit_alerts',
  routing = 'visit_pages:visit_routing',
  reports = 'visit_pages:visit_reporting',
  devopsCenter = 'visit_pages:visit_devops_center',
  aiAssistant = 'visit_pages:ai_assistant',
  hubspot = 'visit_pages:hubspot',
  dedup = 'visit_pages:dedup',
}
