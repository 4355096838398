import { Box } from '@mui/material';
import { CenteredCircularProgress } from '../common/CenteredCircularProgress';
import { AutomationDialogContent } from '../Automations/AutomationDialogContent';
import { AutomationFormVariant } from '../../types/enums/AutomationFormVariant';
import { useSelector } from 'react-redux';
import { selectAssignments } from '../../reducers/global/assignmentReducers';
import { selectDefaultCreationEnvironment } from '../pages/environments/environmentsReducer';
import useAutomationApiPerType from '../Automations/useAutomationApiPerType';
import { ConfigurationCanvasFilters } from '../pages/configuration-canvas/ConfigurationCanvasFilters';
import { useEffect, useMemo } from 'react';
import { AutomationCanvasFilter } from '../pages/canvas-pages/canvasAutomationsTypes';
import {
  CanvasFilterTypes,
  SweepCanvasFiltersMap,
} from '../pages/configuration-canvas-filters/filterTypes';
import {
  AutomationsContextProvider,
  AutomationsContextType,
  useAutomationsContext,
} from '../Automations/AutomationsContext';
import DrawerEmptyImage from '../common/empty-state/svgs/drawerEmpty.svg?react';
import { WalkthroughPointType } from '@server/walkthrough';
import { UI_EVENTS } from '../../services/events';
import { AutomationType } from '../../types/enums/AutomationType';
import { useFeatureToggle } from '../common/useFeatureToggle';

export const assignmentRuleAutomationContextData: AutomationsContextType = {
  automationType: AutomationType.Assignment,
  permissions: {
    create: 'create:assignments',
    edit: 'edit:assignments',
    deploy: 'deploy:assignments',
    delete: 'delete:assignments',
  },
  emptyStateTitle: 'Create your first Assignment rule',
  emptyStateImage: <DrawerEmptyImage />,
  defaultName: 'New Assignment',
  placeholder: 'New Assignment',
  nameInMessage: 'assignment rules',
  nameInMessageSingular: 'assignment rule',
  walkthroughPointEmptyNew: WalkthroughPointType.assignmentsEmptyNew,
  walkthroughPointEdit: WalkthroughPointType.assignmentsEdit,
  walkthroughPointTrigger: WalkthroughPointType.assignmentsTrigger,
  openEvent: UI_EVENTS.assignmentsOpen,
  automationVariant: AutomationFormVariant.ASSIGNMENT_RULES,
  templateSupport: true,
};

const assignmentsSortOptions = [
  {
    label: 'Name',
    value: 'ASSIGNMENT_NAME',
    dataAccessor: 'name',
  },

  {
    label: 'Object',
    value: 'OBJECT',
    dataAccessor: 'objectName',
  },
];

export const assignmentRuleFilters: SweepCanvasFiltersMap = {
  [AutomationCanvasFilter.OBJECTS]: { type: CanvasFilterTypes.OBJECT, position: 0 },
  [AutomationCanvasFilter.RECORD_TYPES]: {
    type: CanvasFilterTypes.RECORD_TYPE,
    position: 1,
    objectsFilter: {
      type: 'reference',
      filterRef: AutomationCanvasFilter.OBJECTS,
    },
  },
  [AutomationCanvasFilter.STEPS]: {
    type: CanvasFilterTypes.STEP,
    recordTypeFilterRef: AutomationCanvasFilter.RECORD_TYPES,
    position: 2,
  },
};

export type AssignmentsProps = {
  recordTypesData: RecordTypesData;
  funnelsData: FunnelsData;
  externalFilter?: (assignment: AutomationStructureNew) => boolean;
  disableCanvasTemplates?: boolean;
  selectedFilters: AutomationDialogFilters;
  setSelectedFilters: (filters: Partial<AutomationDialogFilters>) => void;
  setHoveredItem?: (automationId?: string | undefined) => void;
  goToRoutingHome: () => void;
};

const useGetAssignmentFilters = () => {
  const { permissions } = useAutomationsContext();
  const { requestToDeploy } = useFeatureToggle();
  const filters = useMemo(() => {
    const assignmentRuleFilters: SweepCanvasFiltersMap = {
      [AutomationCanvasFilter.OBJECTS]: { type: CanvasFilterTypes.OBJECT, position: 0 },
      [AutomationCanvasFilter.RECORD_TYPES]: {
        type: CanvasFilterTypes.RECORD_TYPE,
        position: 1,
        objectsFilter: {
          type: 'reference',
          filterRef: AutomationCanvasFilter.OBJECTS,
        },
      },
      [AutomationCanvasFilter.STEPS]: {
        type: CanvasFilterTypes.STEP,
        recordTypeFilterRef: AutomationCanvasFilter.RECORD_TYPES,
        position: 2,
      },
    };
    if (requestToDeploy && permissions?.deploy) {
      assignmentRuleFilters[AutomationCanvasFilter.DEPLOYMENT_REQUEST] = {
        type: CanvasFilterTypes.TOGGLE_FILTER,
        position: 4,
        label: 'Show only deployment requests',
        checked: false,
      };
    }
    return assignmentRuleFilters;
  }, [permissions?.deploy, requestToDeploy]);
  return filters;
};

const Assignments = ({
  recordTypesData,
  funnelsData,
  externalFilter,
  disableCanvasTemplates,
  selectedFilters,
  setSelectedFilters,
  setHoveredItem,
  goToRoutingHome,
}: AssignmentsProps) => {
  const { assignmentsFte } = useFeatureToggle();
  const assignmentRules = useSelector(selectAssignments);
  const crmOrg = useSelector(selectDefaultCreationEnvironment);
  const { onSaveOrCreate, onToggleActivation, onDeleteAutomation, onDeployAutomation } =
    useAutomationApiPerType({ automationVariant: AutomationFormVariant.ASSIGNMENT_RULES });

  const assignmentsToDisplay = externalFilter
    ? assignmentRules?.filter(externalFilter)
    : assignmentRules;

  const filters = useGetAssignmentFilters();

  useEffect(() => {
    if (assignmentsFte && assignmentRules?.length === 0) {
      goToRoutingHome();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignmentRules, assignmentsFte]);

  if (!assignmentRules) return <CenteredCircularProgress />;

  return (
    <>
      <Box pt={2} pb={2} flex={1}>
        {crmOrg && (
          <AutomationDialogContent
            automations={assignmentsToDisplay ?? []}
            onDeleteAutomation={onDeleteAutomation}
            crmOrgId={crmOrg.id}
            onSaveOrCreate={onSaveOrCreate}
            recordTypesData={recordTypesData}
            funnelsData={funnelsData}
            onToggleActivation={onToggleActivation}
            sortOptions={assignmentsSortOptions}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            disableCanvasTemplates={disableCanvasTemplates}
            setHoveredItem={setHoveredItem}
            onDeployAutomation={onDeployAutomation}
          />
        )}
      </Box>
      <ConfigurationCanvasFilters filters={filters} automations={assignmentRules} />
    </>
  );
};

const AssignmentsWithContext = (assignmentProps: AssignmentsProps) => {
  return (
    <AutomationsContextProvider value={assignmentRuleAutomationContextData}>
      <Assignments {...assignmentProps} />
    </AutomationsContextProvider>
  );
};

export default AssignmentsWithContext;
