import { useEffect, useMemo } from 'react';
import useObjectTypes from './useObjectTypes';
import { useSelector } from 'react-redux';
import {
  selectIsLoadingObjectTypes,
  selectIsLoadingSalesforceObjectTypes,
} from '../components/pages/environments/environmentsReducer';
import keyBy from 'lodash/keyBy';
import { useFeatureToggle } from '../components/common/useFeatureToggle';

interface UseObjectTypesWithFetchProps {
  crmOrgId?: string;
  useSfObjects?: boolean;
}

//useSfObjects is necessary cause sf objects by product request should appear only in documentation regardless of FT
const useObjectTypesWithFetch = ({ crmOrgId = '', useSfObjects }: UseObjectTypesWithFetchProps) => {
  const { sfObjectTypes } = useFeatureToggle();
  const shouldFetchSf = useSfObjects && sfObjectTypes;
  const {
    objectTypes,
    salesforceObjectTypes,
    fetchCrmOrgObjectTypes,
    fetchCrmOrgSalesforceObjects,
  } = useObjectTypes({ crmOrgId });

  const isLoadingCrmOrgObjectTypes = useSelector(selectIsLoadingObjectTypes(crmOrgId));
  const isLoadingCrmOrgSalesforceObjectTypes = useSelector(
    selectIsLoadingSalesforceObjectTypes(crmOrgId),
  );

  useEffect(() => {
    if (!objectTypes && !isLoadingCrmOrgObjectTypes && crmOrgId && !shouldFetchSf) {
      fetchCrmOrgObjectTypes();
    }
  }, [fetchCrmOrgObjectTypes, shouldFetchSf, objectTypes, isLoadingCrmOrgObjectTypes, crmOrgId]);

  useEffect(() => {
    if (
      !salesforceObjectTypes &&
      !isLoadingCrmOrgSalesforceObjectTypes &&
      crmOrgId &&
      shouldFetchSf
    ) {
      fetchCrmOrgSalesforceObjects();
    }
  }, [
    fetchCrmOrgSalesforceObjects,
    salesforceObjectTypes,
    shouldFetchSf,
    isLoadingCrmOrgSalesforceObjectTypes,
    crmOrgId,
  ]);

  const objectTypesByName = useMemo(() => keyBy(objectTypes, 'objectType'), [objectTypes]);
  const salesforceObjectTypesByName = useMemo(
    () => keyBy(salesforceObjectTypes, 'objectType'),
    [salesforceObjectTypes],
  );

  const _objectTypes = useMemo(() => {
    return shouldFetchSf ? salesforceObjectTypes : objectTypes;
  }, [shouldFetchSf, salesforceObjectTypes, objectTypes]);

  const _objectTypesByName = useMemo(() => {
    return shouldFetchSf ? salesforceObjectTypesByName : objectTypesByName;
  }, [shouldFetchSf, salesforceObjectTypesByName, objectTypesByName]);

  const _isLoading = useMemo(() => {
    return shouldFetchSf ? isLoadingCrmOrgSalesforceObjectTypes : isLoadingCrmOrgObjectTypes;
  }, [shouldFetchSf, isLoadingCrmOrgObjectTypes, isLoadingCrmOrgSalesforceObjectTypes]);

  return {
    objectTypes: _objectTypes,
    isLoading: _isLoading,
    objectTypesByName: _objectTypesByName,
  };
};

export default useObjectTypesWithFetch;
