import React, { useCallback, useEffect, useState } from 'react';
import { useCrmOrgs } from '../../../environments/useCrmOrgs';
import DeployProgress from './DeployProgress';
import { useRunOnce } from '../../../../common/useRunOnce';
import { useSelector } from 'react-redux';
import { DEPLOY_STATUS } from '../../../../../types/enums/DeployStatus';
import {
  selectLastDeploymentErrors,
  selectLastDeploymentStatus,
  startDeployment,
} from '../../../../../reducers/deploymentsReducer';
import { useDispatch } from 'react-redux';
import { selectCrmOrgs } from '../../../environments/environmentsReducer';
import { useFunnelMapPage } from '../../useFunnelMapPage';
import { selectUserInfoData } from '../../../../../reducers/userInfoReducer';
import { useRequestChangesApiFacade } from '../../../../../apis/facades/useRequestChangesApiFacade';
import DeployContent from './DeployContent';
import { useSweepNotifications } from '../../../../notifications/useSweepNotifications';
import { SweepNotificationVariant } from '../../../../../reducers/notificationsReducer';
import { SweepRoles } from '../../../../../hooks/useUserInfo';

interface DeployDialogProps {
  open: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  initialCrmOrgId: string;
  funnels?: Funnel[];
  funnelMapId: string;
}

const DeployDialog = ({
  open: envDialogOpen,
  setIsOpen: setEnvDialogOpen,
  initialCrmOrgId,
  funnels,
  funnelMapId,
}: DeployDialogProps) => {
  const { deployFunnelDetail } = useFunnelMapPage();
  const { createRequestChange } = useRequestChangesApiFacade();
  const { addNotification } = useSweepNotifications();

  const dispatch = useDispatch();
  const deployStatusAsync = useSelector(selectLastDeploymentStatus);
  const deploymentMessages = useSelector(selectLastDeploymentErrors);
  const orgs = useSelector(selectCrmOrgs);
  const userInfo = useSelector(selectUserInfoData);

  const [selectedFunnelId, setSelectedFunnelId] = useState(funnels ? funnels[0]?.id : '');
  const [note, setNote] = useState('');

  const [selectedOrgId, setSelectedOrgId] = useState(initialCrmOrgId);

  const [progressDialogOpen, setProgressDialogOpen] = useState(
    deployStatusAsync === DEPLOY_STATUS.IN_PROGRESS,
  );

  const { getConnectedCrmOrgs } = useCrmOrgs();

  const isCollaborator = userInfo?.role === SweepRoles.Collaborator;

  const onFailedSaveCallback = useCallback(() => {
    setProgressDialogOpen(false);

    addNotification({
      message: `Funnel save failed`,
      variant: SweepNotificationVariant.Error,
    });
  }, [addNotification]);

  const onConfirmDeployAsync = useCallback(() => {
    if (!selectedOrgId) return;
    dispatch(startDeployment());
    setTimeout(async () => {
      setProgressDialogOpen(true);
      deployFunnelDetail({
        orgId: selectedOrgId,
        funnelId: selectedFunnelId,
        onFailedSaveCallback,
      });
    }, 100);
  }, [deployFunnelDetail, dispatch, onFailedSaveCallback, selectedFunnelId, selectedOrgId]);

  const onRequestChanges = useCallback(async () => {
    if (!selectedOrgId) return;
    setEnvDialogOpen(false);
    try {
      await createRequestChange({
        crmOrgId: selectedOrgId,
        funnelId: selectedFunnelId,
        note,
        funnelMapId,
      });
      addNotification({
        message: 'Your request was sent to your Sweep Admins',
        variant: SweepNotificationVariant.Success,
      });
    } catch (error) {
      addNotification({
        message: 'Ooooops, something went wrong',
        variant: SweepNotificationVariant.Error,
      });
    }
  }, [
    addNotification,
    createRequestChange,
    funnelMapId,
    note,
    selectedFunnelId,
    selectedOrgId,
    setEnvDialogOpen,
  ]);

  useRunOnce(() => {
    if (!orgs?.length) {
      getConnectedCrmOrgs();
    }
  });

  useEffect(() => {
    if (envDialogOpen) {
      setSelectedOrgId(initialCrmOrgId);
    }
  }, [envDialogOpen, initialCrmOrgId]);

  return (
    <>
      {!progressDialogOpen && funnels && (
        <DeployContent
          orgs={orgs}
          funnels={funnels}
          selectedOrgId={selectedOrgId}
          setSelectedOrgId={setSelectedOrgId}
          selectedFunnelId={selectedFunnelId}
          setSelectedFunnelId={setSelectedFunnelId}
          onConfirmDeploy={isCollaborator ? onRequestChanges : onConfirmDeployAsync}
          onConfirmTitle={isCollaborator ? 'Request to deploy' : 'Deploy'}
          showNote={isCollaborator}
          onNoteChange={setNote}
          onCancel={() => setEnvDialogOpen(false)}
          initialCrmOrgId={initialCrmOrgId}
        />
      )}

      {progressDialogOpen && (
        <DeployProgress
          orgs={orgs}
          deployStatus={deployStatusAsync}
          selectedOrgId={selectedOrgId}
          deployMessages={deploymentMessages}
          setIsOpen={() => {
            setProgressDialogOpen((prev) => !prev);
            setEnvDialogOpen(false);
          }}
        />
      )}
    </>
  );
};

export default DeployDialog;
