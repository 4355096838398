import { HubspotField } from '../../components/pages/canvas-pages/canvas-hubspot-page/hubspot.types';
import { useSweepApi } from '../sweep';
import { useCallback } from 'react';

export const useHubspotApiFacade = () => {
  const sweepApi = useSweepApi();
  return {
    create_hubspot_org: useCallback(async () => {
      const response = await sweepApi.post('/hubspot/new');
      return response.data as HubspotOrg;
    }, [sweepApi]),
    get_hubspot_org: useCallback(
      async (hsOrgId: string) => {
        const response = await sweepApi.get(`/hubspot/org/${hsOrgId}`);
        return response.data as HubspotOrg;
      },
      [sweepApi],
    ),
    get_hubspot_orgs: useCallback(async () => {
      const response = await sweepApi.get('/hubspot');
      return response.data as HubspotOrg[];
    }, [sweepApi]),
    delete_hubspot_org: useCallback(async () => {
      await sweepApi.delete(`/hubspot`);
    }, [sweepApi]),
    get_hubspot_fields: useCallback(
      async (hsOrgId: string, objectName: string) => {
        const response = await sweepApi.get(`/hubspot/fields/${hsOrgId}/${objectName}`);
        return response.data as HubspotField[];
      },
      [sweepApi],
    ),
  };
};
