import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCrmOrgObjects,
  selectCrmOrgSalesforceObjectTypes,
  selectIsLoadingObjectTypes,
  selectIsLoadingSalesforceObjectTypes,
  setIsLoadingObjectTypes,
  setIsLoadingSalesforceObjectTypes,
  setObjectTypes,
  setSalesforceObjectTypes,
} from '../components/pages/environments/environmentsReducer';
import {
  CrmOrgObjectTypesResponse,
  SalesforceObjectType,
  useCrmOrgsApiFacade,
} from '../apis/facades/useCrmOrgsApiFacade';
import { telemetry } from '../telemetry';

const useObjectTypes = ({ crmOrgId }: { crmOrgId: string }) => {
  const dispatch = useDispatch();
  const objectTypes = useSelector(selectCrmOrgObjects(crmOrgId));
  const salesforceObjectTypes = useSelector(selectCrmOrgSalesforceObjectTypes(crmOrgId));

  const _sfObjectTypes = useMemo(() => {
    return salesforceObjectTypes?.map((obj) => ({ label: obj.label, objectType: obj.name }));
  }, [salesforceObjectTypes]);

  const isLoadingCrmOrgObjectTypes = useSelector(selectIsLoadingObjectTypes(crmOrgId));
  const isLoadingCrmOrgSalesforceObjectTypes = useSelector(
    selectIsLoadingSalesforceObjectTypes(crmOrgId),
  );

  const { get_crmOrgsObjectTypesFromSalesforce, get_crmOrgsObjectTypes } = useCrmOrgsApiFacade();

  const handleIsLoadingObjects = useCallback(
    (crmOrgId: string, isLoading: boolean) => {
      dispatch(setIsLoadingObjectTypes({ crmOrgId, isLoading }));
    },
    [dispatch],
  );

  const handleIsLoadingSalesforceObjects = useCallback(
    (crmOrgId: string, isLoading: boolean) => {
      dispatch(setIsLoadingSalesforceObjectTypes({ crmOrgId, isLoading }));
    },
    [dispatch],
  );

  const handleSetObjects = useCallback(
    (objectTypes: ObjectTypeName[], crmOrgId: string) => {
      dispatch(
        setObjectTypes({
          objectTypes,
          crmOrgId,
        }),
      );
    },
    [dispatch],
  );

  const handleSetObjectsFromSalesforce = useCallback(
    (objectTypes: SalesforceObjectType[], crmOrgId: string) => {
      dispatch(
        setSalesforceObjectTypes({
          objectTypes,
          crmOrgId,
        }),
      );
    },
    [dispatch],
  );

  const fetchCrmOrgSalesforceObjects = useCallback(
    async (_crmOrgId?: string) => {
      if (isLoadingCrmOrgSalesforceObjectTypes || !!_sfObjectTypes?.length) {
        return;
      }

      const usedCrmOrgId = _crmOrgId ?? crmOrgId;

      handleIsLoadingSalesforceObjects(usedCrmOrgId, true);
      try {
        const response = await get_crmOrgsObjectTypesFromSalesforce({ orgId: usedCrmOrgId });
        handleSetObjectsFromSalesforce(response, usedCrmOrgId);
      } catch (e) {
        telemetry.captureError(e);
        handleSetObjectsFromSalesforce([], usedCrmOrgId); //so it will not cause infinite loop
      }
    },
    [
      crmOrgId,
      _sfObjectTypes,
      isLoadingCrmOrgSalesforceObjectTypes,
      handleIsLoadingSalesforceObjects,
      handleSetObjectsFromSalesforce,
      get_crmOrgsObjectTypesFromSalesforce,
    ],
  );

  const fetchCrmOrgObjectTypes = useCallback(
    async (_crmOrgId?: string): Promise<CrmOrgObjectTypesResponse> => {
      //isLoadingCrmOrgObjectTypes is relevant only in case crmOrgId exist
      //in case of using "_crmOrgId", we check is being done on a parent function
      if (isLoadingCrmOrgObjectTypes && crmOrgId) {
        return {
          objectTypes: [],
        };
      }
      const usedCrmOrgId = _crmOrgId ?? crmOrgId;

      handleIsLoadingObjects(usedCrmOrgId, true);

      try {
        const { objectTypes } = await get_crmOrgsObjectTypes({ orgId: usedCrmOrgId });
        const objectTypesSorted = objectTypes
          ?.map((obj) => ({ ...obj, label: obj.label.toString() }))
          .sort(sortByLabel);

        handleSetObjects(objectTypesSorted, usedCrmOrgId);

        return {
          objectTypes: objectTypesSorted,
        };
      } catch (e) {
        telemetry.captureError(e);
        handleSetObjects([], usedCrmOrgId); //so it will not cause infinite loop
        return {
          objectTypes: [],
        };
      }
    },
    [
      get_crmOrgsObjectTypes,
      crmOrgId,
      handleSetObjects,
      handleIsLoadingObjects,
      isLoadingCrmOrgObjectTypes,
    ],
  );

  return {
    objectTypes,
    salesforceObjectTypes: _sfObjectTypes,
    fetchCrmOrgObjectTypes,
    fetchCrmOrgSalesforceObjects,
    isLoadingCrmOrgObjectTypes,
  };
};

const getLabel = (obj?: ObjectTypeName) => obj?.label || obj?.objectType || '';

const sortByLabel = (objA: ObjectTypeName, objB: ObjectTypeName) =>
  getLabel(objA).localeCompare(getLabel(objB));

export default useObjectTypes;
