import _isEqual from 'lodash/isEqual';
import { Box, Checkbox, List, ListItemText, MenuItem, Popover, PopoverOrigin } from '@mui/material';

import { FlexBox } from '../FlexBox';
import { useCallback, useMemo, useState } from 'react';
import { Button, Typography, colors } from '@sweep-io/sweep-design';
import { TruncatedTextTooltip } from '../TruncatedTextTooltip';
import { SearchInput } from '../SearchInput';
import { filterItemsBySearch } from '../../../lib/filterItemsBySearch';
import {
  AdvancedFilterItem,
  AdvancedFilterItemValue,
  AdvancedFilterProps,
  itemToItemValue,
} from './DeprecatedAdvancedFilter';

const SHOW_ALL_FEATURES_FROM = 5;

export type AdvancedFilterPopoverTexts = {
  title: string;
  selectAll: string;
  onlyButton: string;
};

export const defaultTexts: AdvancedFilterPopoverTexts = {
  title: 'Filter',
  selectAll: 'Select All',
  onlyButton: 'Only',
};

interface AdvancedFilterPopoverProps<DataT = any>
  extends Pick<
    AdvancedFilterProps<DataT>,
    'items' | 'selectedItems' | 'onSelectedItemsChange' | 'hideLabels'
  > {
  anchorEl: HTMLElement | null;
  texts?: Partial<AdvancedFilterPopoverTexts>;
  onClose: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  emptyAsSelectAll?: boolean;
}

const filterItemsFromSet = (items: AdvancedFilterItem[], set: Set<string>) =>
  items.filter((item) => set.has(item.value)).map(itemToItemValue);

export function DeprecatedAdvancedFilterPopover<DataT = any>({
  items,
  selectedItems,
  anchorEl,
  onSelectedItemsChange,
  texts,
  onClose,
  anchorOrigin,
  transformOrigin,
  hideLabels = false,
  emptyAsSelectAll,
}: AdvancedFilterPopoverProps<DataT>) {
  const _texts: AdvancedFilterPopoverTexts = { ...defaultTexts, ...texts };
  const isOpen = Boolean(anchorEl);
  const isShowAllFeatures = items.length > SHOW_ALL_FEATURES_FROM;
  const [searchTxt, setSearchTxt] = useState('');

  const filteredItems = filterItemsBySearch(items, searchTxt, 'label');

  const toggleItem = useCallback(
    (item: AdvancedFilterItemValue) => {
      const selectedItemsSet = new Set(selectedItems);
      if (selectedItemsSet.has(item.value)) {
        selectedItemsSet.delete(item.value);
      } else {
        selectedItemsSet.add(item.value);
      }

      const selectedItemsArray = Array.from(selectedItemsSet);

      onSelectedItemsChange(
        selectedItemsArray,
        filterItemsFromSet(items, selectedItemsSet),
        items.length === selectedItemsArray.length,
      );
    },
    [selectedItems, onSelectedItemsChange, items],
  );

  const selectOnlyItem = useCallback(
    (item: AdvancedFilterItemValue) => {
      onSelectedItemsChange([item.value], [item], false);
    },
    [onSelectedItemsChange],
  );

  const selectAll = useCallback(() => {
    const itemsSet = new Set(items.map((item) => item.value));
    onSelectedItemsChange(Array.from(itemsSet), filterItemsFromSet(items, itemsSet), true);
  }, [items, onSelectedItemsChange]);

  const clearAll = useCallback(() => {
    onSelectedItemsChange([], [], true);
  }, [onSelectedItemsChange]);

  const clearOrSelectAll = useCallback(() => {
    if (emptyAsSelectAll) {
      clearAll();
    } else {
      selectAll();
    }
  }, [clearAll, emptyAsSelectAll, selectAll]);

  const areAllElementsSelected = useMemo(() => {
    if (emptyAsSelectAll && selectedItems.length === 0) return true;
    const selectedItemsSet = new Set(selectedItems);
    const itemsSet = new Set(items.map((item) => item.value));
    return _isEqual(selectedItemsSet, itemsSet);
  }, [items, selectedItems, emptyAsSelectAll]);

  return (
    <Popover
      open={isOpen}
      anchorEl={anchorEl}
      anchorOrigin={
        anchorOrigin || {
          vertical: 'bottom',
          horizontal: 'left',
        }
      }
      transformOrigin={
        transformOrigin || {
          vertical: 'top',
          horizontal: 'left',
        }
      }
      onClose={onClose}
    >
      <Box width={'340px'}>
        {isShowAllFeatures && (
          <FlexBox
            justifyContent="space-between"
            padding="20px 20px 0px"
            alignItems="center"
            gap="12px"
          >
            <SearchInput
              withFixedMagnifyingGlassIcon
              TextFieldProps={{
                value: searchTxt,
                placeholder: 'Search',
                onChange: (e) => {
                  setSearchTxt(e.target.value);
                },
              }}
              onClearButtonClick={() => setSearchTxt('')}
            />

            <Button variant="link" onClick={clearOrSelectAll} disabled={areAllElementsSelected}>
              Select all
            </Button>
          </FlexBox>
        )}
        <FlexBox
          sx={{
            overflow: 'auto',
            maxHeight: '300px',
            padding: 0,
          }}
        >
          <List sx={{ width: '100%' }} dense>
            {!filteredItems.length && (
              <FlexBox justifyContent="center" py={2}>
                <Typography variant="body" color={colors.grey[500]}>
                  No results found
                </Typography>
              </FlexBox>
            )}
            {filteredItems.map((item, index) => (
              <MenuItem
                value={item.value}
                key={item.value + index}
                onClick={() => toggleItem(itemToItemValue(item))}
                sx={{
                  padding: '4px 4px 4px 12px',
                  marginBottom: '4px',
                  '.button-only': {
                    opacity: 0,
                    transition: 'opacity 500ms',
                  },
                  '&:hover .button-only': {
                    opacity: 1,
                  },
                }}
              >
                <Checkbox
                  checked={
                    selectedItems.includes(item.value) ??
                    (emptyAsSelectAll && selectedItems.length == 0)
                  }
                />
                <ListItemText sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  {!hideLabels && (
                    <TruncatedTextTooltip variant="body">{item.label}</TruncatedTextTooltip>
                  )}
                  {item.labelDecoration}
                </ListItemText>
                <span className="button-only">
                  <Button
                    size="small"
                    variant="flat"
                    onClick={(e) => {
                      e.stopPropagation();
                      selectOnlyItem(itemToItemValue(item));
                    }}
                  >
                    {_texts.onlyButton}
                  </Button>
                </span>
              </MenuItem>
            ))}
          </List>
        </FlexBox>
      </Box>
    </Popover>
  );
}
