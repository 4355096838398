import { Box, Grid } from '@mui/material';
import { useState } from 'react';
import { colors, Button, Typography, IconButton } from '@sweep-io/sweep-design';
import FieldLabel from '../../../../common/FieldLabel';
import usePermission from '../../../../common/permissions/usePermission';
import RestrictedTooltip from '../../../../common/permissions/RestrictedTooltip';
import SweepTextField from '../../../../common/SweepTextField';
import { Permission } from '@server/permissions';
import { Close } from '@sweep-io/sweep-design/dist/icons';

const permissionString: Permission[] = ['edit:funnels'];

const LostStepStageInput = ({
  stageName,
  onChange,
  onDelete,
}: {
  stageName: string;
  onChange: (stageName: string) => any;
  onDelete: () => any;
}) => {
  const [showCloseButton, setShowCloseButton] = useState(false);
  const [isAllowedBtn] = usePermission(permissionString);

  const mouseEvents = {
    onMouseEnter: () => {
      setShowCloseButton(true);
    },
    onMouseLeave: () => {
      setShowCloseButton(false);
    },
  };
  return (
    <Box sx={{ position: 'relative' }}>
      <RestrictedTooltip
        to={permissionString}
        notAllowedTitle={'To change a lost step, please contact your admin.'}
      >
        <SweepTextField
          value={stageName}
          onChange={(e, value) => {
            onChange(value);
          }}
          changeOnlyOnBlur
          TextFieldProps={{
            disabled: !isAllowedBtn,
            fullWidth: true,
            ...mouseEvents,
            inputProps: {
              sx: {
                paddingRight: showCloseButton ? '40px' : undefined,
              },
            },
            placeholder: 'Name Step',
          }}
          autoFocus={false}
        />
      </RestrictedTooltip>
      {showCloseButton && isAllowedBtn && (
        <Box
          {...mouseEvents}
          sx={{
            position: 'absolute',
            right: 4,
            top: 8,
            zIndex: 1,
          }}
        >
          <IconButton variant="flat" size="tiny" onClick={onDelete}>
            <Close />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

interface LostStepStagesProps {
  lostStepStages: SweepStage[];
  onChangeStageName: (stageId: string, stageName: string) => any;
  onAddStage: () => any;
  onDeleteStage: (objectType: string, stageId: string) => any;
  objectType: string;
}

export const LostStepStages = ({
  lostStepStages,
  onChangeStageName,
  objectType,
  onAddStage,
  onDeleteStage,
}: LostStepStagesProps) => {
  const [isAllowedBtn] = usePermission(permissionString);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FieldLabel
            label="Lost Steps"
            id="lost-steps-label"
            showTooltip={true}
            // eslint-disable-next-line max-len
            infoTooltipTitle="Lost steps are added as a picklist value to the leading field of your funnel. If there are multiple objects in the same funnel, each Lost step will only apply to one object."
          />
        </Grid>

        {lostStepStages.map((lostStepStage) => {
          return (
            <Grid item xs={2} key={lostStepStage._stageId}>
              <LostStepStageInput
                stageName={lostStepStage?.stageName}
                onChange={(stageName) => {
                  onChangeStageName(lostStepStage._stageId, stageName.trim());
                }}
                onDelete={() => {
                  onDeleteStage(objectType, lostStepStage._stageId);
                }}
              />
            </Grid>
          );
        })}
        <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
          <RestrictedTooltip
            to={permissionString}
            notAllowedTitle={'To add a lost step, please contact your admin.'}
          >
            <Button
              disabled={!isAllowedBtn}
              variant="flat"
              onClick={() => {
                onAddStage();
              }}
            >
              <Typography variant="caption-bold" whiteSpace="nowrap" color={colors.blue[500]}>
                + Add Lost Step
              </Typography>
            </Button>
          </RestrictedTooltip>
        </Grid>
      </Grid>
    </>
  );
};
