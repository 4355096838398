import { useCallback, useState } from 'react';
import { DataTable } from '../../common/table/DataTable';
import { DataTableColumn } from '../../common/table/TableTypes';
import { useRunOnce } from '../../common/useRunOnce';
import { useSlackRecipients } from './useSlackRecipients';
import { Button, Tag, Tooltip, Typography, colors } from '@sweep-io/sweep-design';
import { humanizeDate, humanizeDateVariants } from '../../helpers/humanizeDate';
import { Box } from '@mui/material';
import { Info } from '@sweep-io/sweep-design/dist/icons';
import { useSweepApi } from '../../../apis/sweep';
import { useSelector } from 'react-redux';
import { selectSlackRecipients } from '../../../reducers/slackReducer';

enum AuditStatus {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  PARTIAL = 'PARTIAL',
}
enum RecipientStatus {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  EMAIL_MISMATCH = 'EMAIL_MISMATCH',
  MISSING_FIELD = 'MISSING_FIELD',
  INVALID_CHANNEL = 'INVALID_CHANNEL',
}

interface RecipientReport {
  id: string | null;
  status: string;
  email?: string;
}

interface SlackLogsContentProps {
  automation: AutomationStructureNew;
}

interface DataIntegrationMessageAudit {
  id: string;
  createdAt: string;
  recordUrl: string;
  status: string;
  recipientsReport: RecipientReport[];
}

const RECIPIENT_STATUS_TO_ERROR_MESSAGE = {
  [RecipientStatus.EMAIL_MISMATCH]: "Email wasn't found in Slack",
  [RecipientStatus.ERROR]: 'Error',
  [RecipientStatus.MISSING_FIELD]: 'Slack channel field is missing',
  [RecipientStatus.INVALID_CHANNEL]: 'Invalid channel ID'
}

export const SlackLogsContent = ({ automation }: SlackLogsContentProps) => {
  const slackRecipients = useSelector(selectSlackRecipients);
  const [slackAuditData, setSlackAuditData] = useState<DataIntegrationMessageAudit[]>();
  const sweepApi = useSweepApi();

  const getSlackAuditData = async (automationName: string) => {
    const response = await sweepApi.get(`/data-integrations/audit/${automationName}`);
    return (response?.data as DataIntegrationMessageAudit[])?.sort(
      (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    );
  };

  useRunOnce(async () => {
    const data = await getSlackAuditData(automation.name);
    setSlackAuditData(data);
  });

  const getStatusColorAndText = (status: string) => {
    switch (status) {
      case AuditStatus.SUCCESS:
        return [colors.mint[100], 'Sent'];
      case AuditStatus.ERROR:
        return [colors.blush[300], 'Not sent'];
      case AuditStatus.PARTIAL:
        return [colors.sun[300], 'Partially sent'];
      default:
        return [colors.mint[100], 'Sent'];
    }
  };

  const getRecipientTag = (recipient: RecipientReport, withTag: boolean) => {
    if (withTag) {
      switch (recipient.status) {
        case RecipientStatus.SUCCESS:
          return ` - Sent`;
        case RecipientStatus.ERROR:
        case RecipientStatus.EMAIL_MISMATCH:
        case RecipientStatus.MISSING_FIELD:
        case RecipientStatus.INVALID_CHANNEL:
          return (
            <>
              - Not sent
              <Tooltip
                title={
                  RECIPIENT_STATUS_TO_ERROR_MESSAGE[recipient.status] ?? ''
                }
              >
                <Info />
              </Tooltip>
            </>
          );
      }
    }
  };

  const getRecipientData = useCallback(
    (recipient: RecipientReport) => {
      if (recipient.id) {
        if (recipient.status === RecipientStatus.INVALID_CHANNEL) {
          return <Tag label={recipient.id} />;
        }
        const slackRecord = slackRecipients.find((el) => el.id === recipient.id);
        if (slackRecord) {
          return <Tag label={`${slackRecord.label} [${slackRecord.type}]`} />;
        } else {
          return <Tag label={'[Private Channel]'} />;
        }
      } else {
        const identifier = recipient.email || 'Custom field'; // hardcoded until we support passing field name
        return <Tag label={identifier} />;
      }
    },
    [slackRecipients],
  );

  const getRecipientContent = (data: DataIntegrationMessageAudit) => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        {data.recipientsReport.map((recipient) => {
          return (
            <Box key={recipient.id} sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              {getRecipientData(recipient)}
              {getRecipientTag(recipient, data.status !== AuditStatus.SUCCESS)}
            </Box>
          );
        })}
      </Box>
    );
  };

  useSlackRecipients({
    disableSalesforceRecipients: true,
  });

  const columns: DataTableColumn[] = [
    {
      field: 'createdAt',
      headerName: 'Time & date',
      width: '240px',
    },
    {
      field: 'recordUrl',
      headerName: 'Triggering record',
      width: '210px',
    },
    {
      field: 'status',
      headerName: 'Status',
      width: '180px',
    },
    {
      field: 'recipientsReport',
      headerName: 'Sent to',
    },
  ];

  if (!slackAuditData)
    return (
      <Typography color={colors.grey[800]} variant="body">
        No audit data found
      </Typography>
    );
  else {
    const rows = slackAuditData.map((data) => {
      return {
        id: data.id,
        createdAt: humanizeDate({
          dateOrTimestamp: data.createdAt ?? '',
          variant: humanizeDateVariants.TWO_DIGITS_WITH_TIME,
        }),
        recordUrl: (
          <Button
            endIconName="ViewInSalesforce"
            onClick={() => window.open(data.recordUrl, '_blank')}
            variant="link"
          >
            Show record
          </Button>
        ),
        status: (
          <Tag
            label={getStatusColorAndText(data.status)[1]}
            size="medium"
            textTheme="default"
            color={getStatusColorAndText(data.status)[0]}
          />
        ),
        recipientsReport: (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            {getRecipientContent(data)}
          </Box>
        ),
      };
    });
    return (
      <>
        <DataTable columns={columns} rows={rows} />
      </>
    );
  }
};
