import { DialogContent, Box, Stack } from '@mui/material';
import MembersList from '../pages/assignments/members/MembersList';
import { useAssignmentGroups } from '../pages/assignments/members/useAssignmentGroups';
import { useSelector } from 'react-redux';
import { IconButton, Typography } from '@sweep-io/sweep-design';
import { Close as CloseIcon } from '@sweep-io/sweep-design/dist/icons/Close';
import { CenteredCircularProgress } from '../common/CenteredCircularProgress';
import { NoManagedPackageError } from '../common/error-pages/NoManagedPackageError';
import { selectDefaultCreationCrmOrgId } from '../../reducers/userInfoReducer';
import { ActionsRequireMp } from '../common/install-managed-package/getInstallManagedPackageText';
import DialogOnCanvas from '../pages/canvas-pages/DialogOnCanvas';

const MembersDialog = ({
  closeDialog,
  transitionDuration,
}: {
  closeDialog: () => void;
  transitionDuration?: number;
}) => {
  const { crmOrgUsers, assignmentGroups, isManagedPackageError } = useAssignmentGroups();
  const crmOrgId = useSelector(selectDefaultCreationCrmOrgId);

  const isLoading = !assignmentGroups || !crmOrgUsers || !crmOrgId;

  return (
    <DialogOnCanvas onClose={closeDialog} open={true} transitionDuration={transitionDuration}>
      <DialogContent>
        <Stack gap={2} height="100%">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box>
              <Typography variant="h1">Members</Typography>
            </Box>
            <IconButton variant="flat" onClick={closeDialog}>
              <CloseIcon />
            </IconButton>
          </Box>
          {isManagedPackageError && (
            <Stack pt="10%">
              <NoManagedPackageError actionType={ActionsRequireMp.ASSIGN} />
            </Stack>
          )}
          {!isManagedPackageError && (
            <>
              {isLoading && (
                <Box flex={1}>
                  <CenteredCircularProgress />
                </Box>
              )}
              {!isLoading && (
                <MembersList
                  crmOrgUsers={crmOrgUsers}
                  assignmentGroups={assignmentGroups}
                  crmOrgId={crmOrgId}
                />
              )}
            </>
          )}
        </Stack>
      </DialogContent>
    </DialogOnCanvas>
  );
};

export default MembersDialog;
