import { Box } from '@mui/material';
import { CenteredCircularProgress } from '../common/CenteredCircularProgress';
import { AutomationDialogContent } from '../Automations/AutomationDialogContent';
import { AutomationFormVariant } from '../../types/enums/AutomationFormVariant';
import { useSelector } from 'react-redux';
import { selectDefaultCreationEnvironment } from '../pages/environments/environmentsReducer';
import useAutomationApiPerType from '../Automations/useAutomationApiPerType';
import { automationsSortOptions } from '../pages/canvas-pages/consts';
import { selectScheduledReports } from '../../reducers/global/scheduledReportReducers';
import {
  AutomationsContextProvider,
  AutomationsContextType,
} from '../Automations/AutomationsContext';
import { AutomationType } from '../../types/enums/AutomationType';
import { UI_EVENTS } from '../../services/events';
import DrawerEmptyImage from '../common/empty-state/svgs/drawerEmpty.svg?react';

const data: AutomationsContextType = {
  automationType: AutomationType.ScheduledReport,
  permissions: {
    create: 'create:alerts',
    edit: 'edit:alerts',
    deploy: 'deploy:alerts',
    delete: 'delete:alerts',
  },
  emptyStateTitle: 'Create your first Scheduled report',
  emptyStateImage: <DrawerEmptyImage />,
  defaultName: 'New Scheduled Report',
  placeholder: 'New Scheduled Report',
  nameInMessage: 'scheduled reports',
  nameInMessageSingular: 'scheduled report',
  openEvent: UI_EVENTS.scheduledReportsOpen,
  automationVariant: AutomationFormVariant.SCHEDULED_REPORTS,
  templateSupport: false,
};

const ScheduledReports = ({
  recordTypesData,
  funnelsData,
  externalFilter,
  disableCanvasTemplates,
  selectedFilters,
  setSelectedFilters,
  setHoveredItem,
}: {
  recordTypesData: RecordTypesData;
  funnelsData: FunnelsData;
  externalFilter?: (assignment: AutomationStructureNew) => boolean;
  disableCanvasTemplates?: boolean;
  selectedFilters: AutomationDialogFilters;
  setSelectedFilters: (filters: Partial<AutomationDialogFilters>) => void;
  setHoveredItem?: (automationId?: string | undefined) => void;
}) => {
  const scheduledReports = useSelector(selectScheduledReports);
  const crmOrg = useSelector(selectDefaultCreationEnvironment);
  const { onSaveOrCreate, onToggleActivation, onDeleteAutomation, onDeployAutomation } =
    useAutomationApiPerType({ automationVariant: AutomationFormVariant.SCHEDULED_REPORTS });

  const scheduledReportsToDisplay = externalFilter
    ? scheduledReports?.filter(externalFilter)
    : scheduledReports;

  if (!scheduledReports) return <CenteredCircularProgress />;

  return (
    <Box pt={2} pb={2} flex={1}>
      {crmOrg && (
        <AutomationsContextProvider value={data}>
          <AutomationDialogContent
            automations={scheduledReportsToDisplay ?? []}
            onDeleteAutomation={onDeleteAutomation}
            crmOrgId={crmOrg.id}
            onSaveOrCreate={onSaveOrCreate}
            recordTypesData={recordTypesData}
            funnelsData={funnelsData}
            onToggleActivation={onToggleActivation}
            sortOptions={automationsSortOptions}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            disableCanvasTemplates={disableCanvasTemplates}
            setHoveredItem={setHoveredItem}
            onDeployAutomation={onDeployAutomation}
          />
        </AutomationsContextProvider>
      )}
    </Box>
  );
};

export default ScheduledReports;
