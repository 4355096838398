import { TableProps, TableVirtuoso } from 'react-virtuoso';
import {
  DataTableProps,
  DataTableRow,
  DataTableSortStateProps,
  DataTableVariant,
} from './TableTypes';
import { TableHeadRow } from './TableHeadRow';
import { TableLoader } from './TableLoader';
import { StyledTableBody, StyledTableHead } from './StyledTableComponents';
import { Box, Table, TableRowProps, TableRow as MuiTableRow } from '@mui/material';
import { forwardRef, useCallback, useState } from 'react';
import TableRow from './TableRow';
import { SortOrder } from '../types';

/**
 * Supports out of the box support of virtual scrolling for smooth big amounts of data rendering
 */
export function VirtualScrollDataTable<TRow extends DataTableRow>({
  rows,
  columns: allColumns,
  onRowClick,
  containerRef,
  onSortChange,
  defaultSortState,
  TableEmptyStateComponent,
  sxRowFunction,
  actionableButtonsOnHover,
  isLoading,
  variant = DataTableVariant.default,
}: Pick<
  DataTableProps<TRow>,
  | 'onRowClick'
  | 'columns'
  | 'rows'
  | 'containerRef'
  | 'onSortChange'
  | 'defaultSortState'
  | 'TableEmptyStateComponent'
  | 'isLoading'
  | 'variant'
  | 'actionableButtonsOnHover'
  | 'sxRowFunction'
>) {
  const [sortState, setSortState] = useState<DataTableSortStateProps | undefined>(defaultSortState);
  const columns = allColumns.filter((column) => !column.hidden);

  const _sort = useCallback(
    (columnName: string) => {
      const newState = {
        sortBy: columnName,
        sortOrder: sortState?.sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC,
      };

      setSortState(newState);
      onSortChange && onSortChange(newState);
    },
    [onSortChange, sortState?.sortOrder],
  );

  return (
    <Box height="100%" ref={containerRef}>
      {isLoading && <TableLoader />}

      <TableVirtuoso
        totalCount={rows.length}
        data={rows}
        components={{
          Table: forwardRef<any, TableProps>(({ children, style }, ref) => {
            return (
              <Table
                ref={ref}
                style={
                  {
                    '--virtuosoPaddingTop': (style?.paddingTop ?? 0) + 'px',
                    '--virtuosoPaddingBottom': (style?.paddingBottom ?? 0) + 'px',
                  } as any
                }
              >
                {children}
              </Table>
            );
          }),
          TableHead: forwardRef<any, TableProps>(({ children }, ref) => {
            return <StyledTableHead ref={ref}>{children}</StyledTableHead>;
          }),
          TableBody: forwardRef<any, TableProps>((props, ref) => {
            return (
              <StyledTableBody ref={ref}>
                {!!rows.length ? (
                  props.children
                ) : (
                  <MuiTableRow
                    sx={{
                      position: 'absolute',
                      left: 0,
                      right: 0,
                      '&:hover': { backgroundColor: 'transparent !important' },
                    }}
                  >
                    {TableEmptyStateComponent}
                  </MuiTableRow>
                )}
              </StyledTableBody>
            );
          }),
          TableRow: forwardRef<any, TableRowProps>((props: any, ref) => {
            const row = props.item;
            const idx = props['data-index'];

            return (
              <TableRow
                data-index={idx}
                ref={ref}
                row={row}
                columns={columns}
                allowReorder={false}
                sxRowFunction={sxRowFunction}
                tableRowProps={{ id: `table_row_${row.id}` }}
                onClick={(e, column) => {
                  onRowClick && onRowClick(e, row, column);
                }}
                actionableButtonsOnHover={actionableButtonsOnHover}
                variant={variant}
                {...props}
              />
            );
          }),
        }}
        fixedHeaderContent={() => (
          <TableHeadRow
            columns={columns}
            stickyHeader
            variant={variant}
            sort={_sort}
            sortState={sortState}
          />
        )}
      />
    </Box>
  );
}
