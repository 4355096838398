import { Box, FormControl, Grid, MenuItem, Paper, SelectChangeEvent } from '@mui/material';
import { styled } from '@mui/material/styles';
import { colors, IconButton } from '@sweep-io/sweep-design';
import { Delete, Plus } from '@sweep-io/sweep-design/dist/icons';
import { SweepFieldTypes } from '../../../types/enums/SweepFieldTypes';
import { uniqueId } from '../../../lib/uniqueId';
import { SelectorValueTypes } from '../../../types/enums/SelectorValueTypes';
import { useSweepFieldsLabels } from '../../../sweep-fields/useSweepFieldsLabels';
import { NestedFieldsSelector } from '../../common/fieldsSelectors/NestedFieldsSelector';
import { SweepFieldsValueSelector } from '../../SweepFieldsValueSelector/SweepFieldsValueSelector';
import { SweepFieldsOperatorSelector } from '../../SweepFieldsOperatorSelector/SweepFieldsOperatorSelector';
import {
  ApiSweepFieldOperator,
  operatorHasAutomaticValue,
  SweepFieldOperator,
  SweepFieldOperatorsValues,
} from '../../SweepFieldsOperatorSelector/sweepFieldsTypesAndOperators';
import { PicklistSelector } from '../../common/rule-builder/selectors/PicklistSelector';
import SweepSelect from '../../common/SweepSelect';
import {
  concatenateSupportedTypes,
  greatestSupportedTypes,
  latestSupportedTypes,
  minMaxSupportedTypes,
  prioritizeSupportedTypes,
} from './helpers';

const NestedBox = styled(Box)({
  maxWidth: '500px',
});

interface MergeFieldRowProps {
  objectName?: string;
  field?: SweepCriterion;
  onChange: (field: SweepCriterion) => any;
  onDelete?: () => any;
  onAdd?: (field: SweepCriterion) => any;
  crmOrgId: string;
  readonly?: boolean;
  disableLookupItems: boolean;
  allFields?: SweepCriterion[];
}

const concatenateFieldsStruct = {
  properties: {
    valueSet: [
      { fullName: 'COMMA', label: 'Comma (,)', default: false },
      { fullName: 'SEMICOLON', label: 'Semicolon (;)', default: false },
      { fullName: 'PIPE', label: 'Pipe (|)', default: false },
      { fullName: 'SLASH', label: 'Slash (/)', default: false },
    ],
  },
};

export const MergeFieldRow = ({
  objectName,
  field,
  onChange,
  onDelete,
  onAdd,
  crmOrgId,
  readonly,
  disableLookupItems,
  allFields,
}: MergeFieldRowProps) => {
  const emptyField = {
    criterionId: uniqueId(),
    valueType: SelectorValueTypes.LITERAL,
    _fieldIds: [],
    fieldType: '',
    value: '',
    operator: '',
  };
  const _field = field || emptyField;
  const { getEnrichedNestedPath, getLabelsFromIds } = useSweepFieldsLabels();
  const hideValueField = operatorHasAutomaticValue(_field.operator as ApiSweepFieldOperator);

  const filterOperatorBy = (
    operatorList: {
      label: string;
      operator: SweepFieldOperator;
    }[],
    fieldType?: SweepFieldTypes,
  ) => {
    const newOperators = [
      { label: 'Is empty', operator: SweepFieldOperator.IS_NULL_TRUE },
      { label: 'Is not empty', operator: SweepFieldOperator.IS_NULL_FALSE },
      {
        label: 'Keep value from the master record',
        operator: SweepFieldOperator.MASTER_RECORD_VALUE,
      },
    ];

    // only show SAME_RECORD_AS is there are already records in the list
    if (allFields && allFields.length > 1 && getSameRecordsFields()?.length) {
      newOperators.push({ label: 'Same record as', operator: SweepFieldOperator.SAME_RECORD_AS });
    }

    if (fieldType && minMaxSupportedTypes.includes(fieldType)) {
      newOperators.push(
        { label: 'Max', operator: SweepFieldOperator.MAX },
        { label: 'Min', operator: SweepFieldOperator.MIN },
      );
    }

    if (fieldType && latestSupportedTypes.includes(fieldType)) {
      newOperators.push(
        { label: 'Latest', operator: SweepFieldOperator.MAX },
        { label: 'Earliest', operator: SweepFieldOperator.MIN },
      );
    }

    if (fieldType && greatestSupportedTypes.includes(fieldType)) {
      newOperators.push(
        { label: 'Greatest', operator: SweepFieldOperator.MAX },
        { label: 'Lowest', operator: SweepFieldOperator.MIN },
      );
    }

    if (fieldType && concatenateSupportedTypes.includes(fieldType)) {
      newOperators.push({
        label: 'Concatenate all text',
        operator: SweepFieldOperator.CONCATENATE,
      });
    }

    if (fieldType && prioritizeSupportedTypes.includes(fieldType)) {
      newOperators.push({
        label: 'Prioritize',
        operator: SweepFieldOperator.PRIORITIZE,
      });
    }

    return [...newOperators];
  };

  const getSameRecordsFields = () => {
    const _size = allFields?.length ?? 0;
    return allFields?.filter((el, index) => {
      if (el.operator !== SweepFieldOperator.CONCATENATE && index < _size - 1) {
        return el;
      }
    });
  };

  const getSameRecordsMenuItems = (items: SweepCriterion[]) => {
    return items.map((el) => {
      const fieldName = el?._fieldIds?.[0];
      return (
        <MenuItem key={el.criterionId} value={fieldName}>
          {getLabelsFromIds(el._fieldIds)}
        </MenuItem>
      );
    });
  };

  return (
    <Box sx={{ position: 'relative', mb: 1 }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Grid
          container
          flexWrap="nowrap"
          sx={{
            alignItems: 'center',
            gap: '8px',
            mr: '12px',
          }}
        >
          <Grid item xs={4} zeroMinWidth>
            <Box
              sx={{
                border: `1px solid ${colors.grey[300]}`,
                borderRadius: '4px',
                background: colors.white,
              }}
            >
              <NestedBox>
                <NestedFieldsSelector
                  disableLookupItemsResolve={disableLookupItems}
                  crmOrgId={crmOrgId}
                  objectType={objectName || ''}
                  nestedPath={getEnrichedNestedPath(_field._fieldIds)}
                  readonly={readonly}
                  onChange={(_sweepField) => {
                    onChange({
                      _fieldIds: _sweepField.fieldIds,
                      fieldType: _sweepField.fieldType,
                      criterionId: _field.criterionId,
                      valueType: SelectorValueTypes.LITERAL,
                      value: '',
                      operator: '',
                    });
                  }}
                />
              </NestedBox>
            </Box>
          </Grid>
          <Grid item xs={3} zeroMinWidth>
            <FormControl sx={{ width: '100%' }}>
              <SweepFieldsOperatorSelector
                placeholder={'Set logic'}
                filterOperatorBy={filterOperatorBy}
                removeBorders={false}
                operator={(_field.operator || undefined) as ApiSweepFieldOperator}
                value={_field.value as SweepFieldOperatorsValues}
                fieldType={_field.fieldType as SweepFieldTypes}
                onChange={(_operator, _value) => {
                  onChange({
                    ..._field,
                    operator: _operator,
                    value: _value ?? '',
                  });
                }}
                disabled={!Boolean(_field.fieldType) || readonly}
              />
            </FormControl>
          </Grid>
          <Grid item xs={5}>
            <Box>
              {(hideValueField || !_field.operator) && (
                <Paper
                  elevation={0}
                  sx={{
                    height: 40,
                    borderRadius: '4px',
                    backgroundColor: colors.grey[100],
                    border: '1px solid',
                    borderColor: colors.grey[300],
                  }}
                />
              )}

              {!hideValueField && _field.operator === SweepFieldOperator.CONCATENATE && (
                <PicklistSelector
                  chosenValues={_field.value as string}
                  placeholder={'Set value'}
                  isMultiple={false}
                  field={concatenateFieldsStruct as any}
                  onChange={(value) => {
                    onChange({
                      ..._field,
                      value: value,
                      valueType: SelectorValueTypes.LITERAL,
                    });
                  }}
                  crmOrgId={crmOrgId}
                />
              )}
              {!hideValueField && _field.operator === SweepFieldOperator.SAME_RECORD_AS && (
                <SweepSelect
                  FormControlProps={{
                    sx: { width: '100%' },
                  }}
                  SelectProps={{
                    placeholder: 'Set value',
                    value: getLabelsFromIds([_field.value as string]),
                    renderValue: (val: any) => val,
                    onChange: (event: SelectChangeEvent<unknown>) => {
                      const val = event.target.value as string;
                      onChange({
                        ..._field,
                        value: val,
                        valueType: SelectorValueTypes.LITERAL,
                      });
                    },
                  }}
                >
                  {getSameRecordsMenuItems(getSameRecordsFields() ?? [])}
                </SweepSelect>
              )}
              {!hideValueField &&
                _field.operator &&
                _field.operator !== SweepFieldOperator.CONCATENATE &&
                _field.operator !== SweepFieldOperator.SAME_RECORD_AS && (
                  <SweepFieldsValueSelector
                    placeholder="Value"
                    shouldBeDisabled={!_field._fieldIds.length || !_field.operator || readonly}
                    parentFieldType={_field.fieldType ?? ''}
                    initialValue={_field.value}
                    onChange={(value) => {
                      onChange({
                        ..._field,
                        value: value,
                        valueType: SelectorValueTypes.LITERAL,
                      });
                    }}
                    fieldIds={_field._fieldIds}
                    crmOrgId={crmOrgId}
                    valueType={_field.valueType || SelectorValueTypes.LITERAL}
                  />
                )}
            </Box>
          </Grid>
        </Grid>
        {!readonly && (
          <>
            <Box>
              <IconButton
                disabled={field === undefined}
                onClick={() => {
                  onDelete && onDelete();
                }}
                variant="flat"
                size="small"
              >
                <Delete />
              </IconButton>
            </Box>

            <Box>
              <IconButton
                disabled={field === undefined}
                onClick={() => {
                  onAdd && onAdd(emptyField);
                }}
                variant="flat"
                size="small"
              >
                <Plus />
              </IconButton>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};
