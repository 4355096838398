import { useDispatch, useSelector } from 'react-redux';
import {
  addAsyncResponse,
  deprecated_addAsyncResponse,
  addSyncMessage,
  selectCopilotState,
  reset,
} from './copilotReducer';
import { useCallback, useEffect } from 'react';
import { useCrmOrgsApiFacade } from '../../apis/facades/useCrmOrgsApiFacade';
import { ChatCompletionRequestMessageRole } from '@server/ai';
import { DateTime } from 'luxon';
import usePaywall from '../dashboard/header/usePaywall';
import { telemetry } from '../../telemetry';
import { useAiChat } from '../common/ai-chat/useAiChat';
import { useFeatureToggle } from '../common/useFeatureToggle';

interface OnConfirmProps {
  message: string;
  crmOrgId?: string | null;
}

//Product is to reset if 24 hours have passed since the last message
const checkIfShouldReset = (lastMessageDateTime: string) => {
  const diff = new Date().getTime() - new Date(lastMessageDateTime).getTime();
  const diffInHours = diff / 1000 / 60 / 60;
  return diffInHours > 24;
};

const SECONDS_INTERVAL = 60 * 60; //once an hour interval

const useCopilotChat = () => {
  const { unifiedAiEndpoint } = useFeatureToggle();
  const { onSendMessage } = useAiChat();
  const { messages, isLoading, error, sessionId } = useSelector(selectCopilotState);
  const dispatch = useDispatch();
  const { post_crmOrgCopilotChat } = useCrmOrgsApiFacade();
  const lastMessageDateTime = messages[messages.length - 1]?.createdAt;
  const { isPaywallActive } = usePaywall();
  const disableInput = isPaywallActive && messages.length > 0;

  const onConfirmOrgChat = useCallback(
    async ({ message, crmOrgId }: { message: string; crmOrgId?: string | null }) => {
      const createdAt = DateTime.now().toISO() ?? '';
      const newMessage = {
        role: ChatCompletionRequestMessageRole.USER,
        content: message,
        createdAt,
      };

      dispatch(addSyncMessage({ message: newMessage }));
      try {
        if (crmOrgId) {
          const { error, data } = await onSendMessage({
            payload: {
              crmOrgId,
              message,
              context: '',
              isFirstMessage: messages.length === 0,
              sessionId,
            },
          });

          const answeredAt = DateTime.now().toISO() ?? '';
          dispatch(
            addAsyncResponse({
              message: error ? undefined : [{ ...data[0], createdAt: answeredAt }],
              error,
            }),
          );
        } else {
          //to remove the "loading" state.
          // TODO implement "pending" mechanism? (check with product)
          dispatch(addAsyncResponse({}));
        }
      } catch (e) {
        dispatch(
          addAsyncResponse({
            error: 'Something went wrong, please try again later',
          }),
        );
        telemetry.captureError(e);
      }
    },
    [dispatch, onSendMessage, messages, sessionId],
  );

  const deprecated_onConfirm = useCallback(
    async ({ message, crmOrgId }: { message: string; crmOrgId?: string | null }) => {
      const createdAt = DateTime.now().toISO() ?? '';
      const newMessage = {
        role: ChatCompletionRequestMessageRole.USER,
        content: message,
        createdAt,
      };

      dispatch(addSyncMessage({ message: newMessage }));
      const updatedMessages = [...messages, newMessage];
      try {
        if (crmOrgId) {
          const { error, data } = await post_crmOrgCopilotChat({
            crmOrgId,
            payload: { messages: updatedMessages },
          });
          dispatch(deprecated_addAsyncResponse({ messages: error ? undefined : data, error }));
        } else {
          //to remove the "loading" state.
          // TODO implement "pending" mechanism? (check with product)
          dispatch(addAsyncResponse({}));
        }
      } catch (e) {
        dispatch(
          addAsyncResponse({
            error: 'Something went wrong, please try again later',
          }),
        );
        telemetry.captureError(e);
      }
    },
    [dispatch, post_crmOrgCopilotChat, messages],
  );

  const resetChat = useCallback(() => {
    dispatch(reset());
  }, [dispatch]);

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    if (lastMessageDateTime) {
      interval = setInterval(() => {
        const shouldReset = checkIfShouldReset(lastMessageDateTime);
        if (shouldReset) {
          dispatch(reset());
        }
      }, SECONDS_INTERVAL * 1000);
    }
    return () => clearInterval(interval);
  }, [dispatch, lastMessageDateTime]);

  const onConfirm = useCallback(
    async (props: OnConfirmProps) => {
      if (unifiedAiEndpoint) {
        return await onConfirmOrgChat(props);
      } else {
        return await deprecated_onConfirm(props);
      }
    },
    [deprecated_onConfirm, unifiedAiEndpoint, onConfirmOrgChat],
  );

  return {
    onConfirm,
    isLoading,
    messages,
    error,
    resetChat,
    disableInput,
  };
};

export default useCopilotChat;
