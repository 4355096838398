import SweepAvatar from './sweepAvatar.svg?react';
import styled from '@mui/material/styles/styled';
import { Typography, colors } from '@sweep-io/sweep-design';
import { humanizeDate, humanizeDateVariants } from '../../../helpers/humanizeDate';
import { ReactNode, useEffect } from 'react';
import { Stack, Box } from '@mui/material';
import ChatLoader from './ChatLoader';
import { MarkdownWrapper } from '../../MarkdownWrapper';

export const AiChatAssistantMessageBox = ({
  content,
  createdAt,
  scrollToBottom,
  errorState = false,
  isLoading = false,
}: {
  content: string | ReactNode;
  createdAt: string;
  scrollToBottom?: () => void;
  isLoading?: boolean;
  errorState?: boolean;
}) => {
  useEffect(() => {
    scrollToBottom && scrollToBottom();
  }, [content, scrollToBottom]);

  return (
    <Stack gap={1}>
      <Box sx={{ textAlign: 'left', pl: '30px' }}>
        <Typography variant="caption" color={colors.grey[700]}>
          {`AI Assistant ${
            createdAt
              ? `• ${humanizeDate({
                  dateOrTimestamp: createdAt ?? '',
                  variant: humanizeDateVariants.TIME_WITHOUT_DATE,
                })}`
              : ''
          }`}
        </Typography>
      </Box>
      <Box display="flex" gap={0.5} alignItems="flex-end">
        <Box display="flex" alignItems="flex-end">
          <SweepAvatar />
        </Box>

        {errorState && (
          <AssistantMessageBox p={1.5}>
            <Typography color={colors.blush[600]} variant="body">
              {content}
            </Typography>
          </AssistantMessageBox>
        )}
        {isLoading && (
          <Box alignSelf="center" pl={1.5}>
            <ChatLoader />
          </Box>
        )}
        {!errorState && !isLoading && (
          <AssistantMessageBox sx={{ pr: 1.5, pl: 1.5 }}>
            <MarkdownWrapper content={content} />
          </AssistantMessageBox>
        )}
      </Box>
    </Stack>
  );
};

const AssistantMessageBox = styled(Box)({
  borderRadius: '8px 8px 8px 2px',
  background: '#F0F4F8',
  maxWidth: '100%',
});
