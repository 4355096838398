import { Box, styled, TextField } from '@mui/material';
import FieldLabel from '../../../common/FieldLabel';
import { SweepFieldTypes } from '../../../../types/enums/SweepFieldTypes';
import { SlackActionType } from '../../../../types/enums/SlackActionType';
import { useFeatureToggle } from '../../../common/useFeatureToggle';
import { useMemo } from 'react';
import colors from '@sweep-io/sweep-design/dist/colors';

export const SlackActionRow = styled(Box)({
  display: 'flex',
  gap: '12px',
  marginBottom: '12px',
});

export const customButtonSx = {
  color: colors.blue[500],
  border: 'none',
  textTransform: 'unset',
  marginTop: '6px',
  p: '0',
  '& span': {
    fontSize: '14px',
    fontWeight: 500,
  },
  '&:hover': {
    background: 'transparent',
    color: colors.blue[600],
  },
};

export const useSlackActionEntryActions = () => {
  const { slackActionButtonsCreateRecord } = useFeatureToggle();
  const actions = useMemo(
    () => [
      {
        label: 'Update record',
        value: SlackActionType.UPDATE_RECORD,
        disabled: false,
      },
      {
        label: 'Create record',
        value: SlackActionType.CREATE_RECORD,
        disabled: Boolean(!slackActionButtonsCreateRecord),
      },
      {
        label: 'Update related record',
        value: SlackActionType.UPDATE__RELATED_RECORD,
        disabled: true,
      },
    ],
    [slackActionButtonsCreateRecord],
  );
  return actions;
};

export const showOnlyUpdatable = (field: SweepField) =>
  field.properties?.updateable !== false && field.fieldType !== SweepFieldTypes.serviceid;

export function SlackActionButtonTextField<TSlackAction extends SlackAction>({
  action,
  idx,
  onUpdateAction,
}: {
  action: TSlackAction;
  idx: number;
  onUpdateAction: (action: TSlackAction, index: number) => any;
}) {
  return (
    <Box>
      <FieldLabel label={'Button text'} markLabelAsRequired={true} />
      <TextField
        type={'text'}
        value={action.buttonText ?? ''}
        onChange={(event: { target: { value: string } }) => {
          const value = event.target.value;
          const updatedAction = { ...action, buttonText: value };
          onUpdateAction(updatedAction, idx);
        }}
        InputProps={{
          sx: {
            width: '242px',
          },
        }}
        placeholder={'This is button text'}
      />
    </Box>
  );
}
