import { createContext, useContext } from 'react';

interface CreateFieldContextType {
  funnelMapId?: string;
}

const CreateFieldContext = createContext<CreateFieldContextType>({});

const CreateFieldContextProvider = ({
  children,
  funnelMapId,
}: {
  children: React.ReactNode;
  funnelMapId?: string;
}) => {
  return <CreateFieldContext.Provider children={children} value={{ funnelMapId }} />;
};

const useCreateFieldContext = () => {
  const { funnelMapId } = useContext(CreateFieldContext);
  return { funnelMapId };
};

export { CreateFieldContextProvider, useCreateFieldContext };
