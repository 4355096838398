import { SlackConnectionStatus } from '../../../reducers/integrationsReducer';
import { SlackActionType } from '../../../types/enums/SlackActionType';

const isButtonTextValid = (buttonText: string = '') => Boolean(buttonText.trim());

const validateSlackActionUpdate = (action: UpdateRecordSlackAction) => {
  return isButtonTextValid(action.buttonText) && action.fields.length > 0;
};

const validateSlackActionCreate = (action: CreateRecordSlackAction) => {
  if (action.fields.length === 0) {
    return false;
  }
  if (!isButtonTextValid(action.buttonText)) {
    return false;
  }
  if (action.relationshipField !== undefined) {
    if (action.relationshipField.length !== 1) {
      return false;
    }
  }
  if (action.objectTypeName.recordType !== undefined) {
    if (action.objectTypeName.recordType.name === '') {
      return false;
    }
  }

  if (action.relationshipField !== undefined) {
    if (action.relationshipField.length !== 1) {
      return false;
    }
    if (action.relationshipField[0] === '') {
      return false;
    }
  }

  return true;
};

const validateSlackAction = (action: SlackAction) => {
  switch (action.type) {
    case SlackActionType.UPDATE_RECORD:
      return validateSlackActionUpdate(action);
    case SlackActionType.CREATE_RECORD:
      return validateSlackActionCreate(action);
    default:
      return false;
  }
};

export const validateSlackActionParams = (
  slackStatus: SlackConnectionStatus,
  actionParams?: DeepPartial<AutomationSlackActionParams>,
) => {
  if (slackStatus !== SlackConnectionStatus.Connected) {
    return false;
  }

  if (actionParams?.actions) {
    const isValid = actionParams.actions.every(validateSlackAction);
    if (!isValid) {
      return false;
    }
  }
  if (actionParams?.recipients && actionParams?.recipients.length > 0) {
    return true;
  }

  return false;
};
