import { ActionsMenu } from '../../../../common/actions-menu/ActionsMenu';
import { FieldsDialogContentOptions } from '../../../../../constants/fieldsManagementConsts';
import { FieldOrigin } from '../../../../../types/enums/FieldOrigin';
import usePermission from '../../../../common/permissions/usePermission';
import DeleteFieldDialog from './DeleteFieldDialog';
import { useState } from 'react';
import ManagePicklistDialog from './ManagePicklistDialog';
import { useDispatch } from 'react-redux';
import {
  createOrUpdateField,
  FieldUsageWithFieldMetadata,
  removeField,
  setDialogContent,
} from '../../../../../reducers/funnel-flow-page/fieldsReducer';
import { MoreActionsHorizontal } from '@sweep-io/sweep-design/dist/icons';

const enum FieldActions {
  Manage = 'Manage',
  Edit = 'Edit',
  Delete = 'Delete',
  Deactivate = 'Deactivate',
}

const FieldsTableMenu = ({
  field,
  crmOrgId,
  isLeadingField,
  isRollup,
}: {
  field: FieldUsageWithFieldMetadata;
  crmOrgId: string;
  isLeadingField: boolean;
  isRollup: boolean;
}) => {
  const dispatch = useDispatch();
  const [isConfirmDeleteOpened, setIsConfirmDeleteOpened] = useState(false);
  const [isManagePicklistOpened, setIsManagePicklistOpened] = useState(false);

  const [isAllowedEditBtn, isAllowedDeleteBtn] = usePermission([
    'edit:sweep-fields:update',
    'delete:sweep-fields',
  ]);

  const {
    field: { origin, fieldType },
    sections: { automations: usedInAutomations, stages: usedInStages },
  } = field;
  const fieldNotUsed = !usedInStages?.length && !usedInAutomations?.length;
  const actions = [];
  const addActions = !isRollup;
  if (addActions) {
    switch (origin) {
      case FieldOrigin.SWEEP_CUSTOM:
        actions.push({
          label: FieldActions.Edit,
          value: FieldActions.Edit,
          disabled: !isAllowedEditBtn,
        });
        if (fieldNotUsed) {
          actions.push({
            label: FieldActions.Delete,
            value: FieldActions.Delete,
            disabled: !isAllowedDeleteBtn,
          });
        }
        break;
      case FieldOrigin.SF_CUSTOM:
        actions.push({
          label: FieldActions.Edit,
          value: FieldActions.Edit,
          disabled: !isAllowedEditBtn,
        });
        break;
      case FieldOrigin.SF_STANDARD:
        if (fieldType === 'Picklist') {
          actions.push({
            label: FieldActions.Manage,
            value: FieldActions.Manage,
            disabled: !isAllowedEditBtn,
          });
        }
    }
  }

  return (
    <>
      <ActionsMenu
        actions={actions}
        onClick={(action) => {
          switch (action.value) {
            case FieldActions.Edit:
              dispatch(
                setDialogContent({
                  dialogContent: FieldsDialogContentOptions.EditField,
                  fieldToEdit: field,
                }),
              );
              break;
            case FieldActions.Manage:
              setIsManagePicklistOpened(true);
              break;
            case FieldActions.Delete:
              setIsConfirmDeleteOpened(true);
              break;
          }
        }}
        MenuButtonIcon={<MoreActionsHorizontal />}
        iconTooltip={
          !isAllowedEditBtn ? 'To modify the fields, please contact your admin.' : undefined
        }
      />
      <DeleteFieldDialog
        isOpened={isConfirmDeleteOpened}
        fieldToDelete={field.field}
        closeDialog={() => setIsConfirmDeleteOpened(false)}
        onDeleteCb={(field) => {
          if (field.id) {
            dispatch(removeField({ fieldId: field.id }));
          }
        }}
      />
      <ManagePicklistDialog
        isOpened={isManagePicklistOpened}
        fieldToManage={field.field}
        crmOrgId={crmOrgId}
        isLeadingField={isLeadingField}
        closeDialog={() => setIsManagePicklistOpened(false)}
        onUpdateFieldCb={(field) => {
          dispatch(createOrUpdateField({ field }));
        }}
      />
    </>
  );
};

export default FieldsTableMenu;
