import React, { useRef, useState } from 'react';
import { Box } from '@mui/material';
import Draggable from 'react-draggable';
import CommentPreview from './CommentPreview';
import CommentThread from './CommentThread';
import { CommentThreadProps } from '../../types/Comment';
import { useSelector } from 'react-redux';
import { selectUserInfoData } from '../../reducers/userInfoReducer';
import useComments from './useComments';
import { getAbsolutePosition, getPositionRelativeToFunnel } from './helper';
import { useViewport } from 'reactflow';

const CommentPreviewAndThread = ({
  commentThread,
  isCommentThreadOpened,
  openCommentThread,
  closeCommentThread,
  funnelMapId,
}: {
  commentThread: CommentThreadProps;
  isCommentThreadOpened: boolean;
  funnelMapId: string;
  openCommentThread: (commentThread: CommentThreadProps) => void;
  closeCommentThread: () => void;
}) => {
  const userInfo = useSelector(selectUserInfoData);
  const { updateThread } = useComments();
  const [isDragging, setIsDragging] = useState(false);
  const { zoom, x, y } = useViewport();
  const anchorRef = useRef<HTMLDivElement>(null);

  //delta is the distance between the mouse click and the comment's origin
  const [deltaX, setDeltaX] = useState(0);
  const [deltaY, setDeltaY] = useState(0);

  const position = {
    x: commentThread.positionX,
    y: commentThread.positionY,
  };
  const canDrag = commentThread.createdById === userInfo?.id;

  const content = (
    <div ref={anchorRef}>
      <Box
        sx={{
          transform: `scale(${1 / zoom})`,
          transformOrigin: 'bottom left',
          position: 'absolute',
          left: 0,
          bottom: 0,
        }}
      >
        <CommentPreview
          commentThread={commentThread}
          isCommentThreadOpened={isCommentThreadOpened}
        />
      </Box>
    </div>
  );

  return (
    <Box position="relative" sx={{ zIndex: isCommentThreadOpened ? 2 : 1 }}>
      {canDrag && (
        <Draggable
          axis="both"
          position={position}
          scale={zoom}
          onDrag={() => setIsDragging(true)}
          onStart={(e) => {
            e.preventDefault(); //to prevent "onClick" events of the children
            if ('clientX' in e) {
              const absolutePosition = getAbsolutePosition(position.x, position.y, x, y, zoom);
              setDeltaX(e.clientX - absolutePosition.positionX);
              setDeltaY(e.clientY - absolutePosition.positionY);
            }
          }}
          onStop={(e) => {
            if ('x' in e) {
              if (!isDragging) {
                openCommentThread(commentThread);
                return;
              }
              setIsDragging(false);
              updateThread({
                commentThreadId: commentThread.id,
                funnelMapId,
                ...getPositionRelativeToFunnel(e.x - deltaX, e.y - deltaY, x, y, zoom),
              });
            }
          }}
        >
          {content}
        </Draggable>
      )}

      {!canDrag && (
        <Box
          sx={{ position: 'absolute', top: position.y, left: position.x }}
          onClick={() => openCommentThread(commentThread)}
        >
          {content}
        </Box>
      )}

      {isCommentThreadOpened && (
        <CommentThread
          commentThread={commentThread}
          closeCommentThread={closeCommentThread}
          anchorEl={anchorRef?.current ?? undefined}
        />
      )}
    </Box>
  );
};

export default CommentPreviewAndThread;
