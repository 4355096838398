import { Stack } from '@mui/material';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectAssignments } from '../../reducers/global/assignmentReducers';
import { selectScheduledAssignments } from '../../reducers/global/scheduledAssignmentReducers';
import { RoutingTabs, useRoutingTabs } from './useRoutingTabs';
import {
  ConfigurationCanvasPanelContent,
  ConfigurationCanvasPanelHeader,
} from '../pages/configuration-canvas-panel';
import { useAssignmentGroups } from '../pages/assignments/members/useAssignmentGroups';
import useTerritories from '../../hooks/useTerritories';
import { RoutingTabsInfo } from './routingTabsInfo';
import RoutingTabsList, { RoutingListItem } from './RoutingTabsList';

export const RoutingTabElementsList = ({
  goToTab,
}: {
  goToTab: (tab: RoutingTabs) => void;
}) => {
  const { routingTab } = useRoutingTabs();
  const assignmentRules = useSelector(selectAssignments);
  const scheduledAssignments = useSelector(selectScheduledAssignments);
  const territories = useTerritories();
  const { assignmentGroups, crmOrgUsers } = useAssignmentGroups();

  const rulesElements: RoutingListItem[] = useMemo(
    () => [
      {
        label: RoutingTabsInfo[RoutingTabs.assignment].label,
        elements: assignmentRules ?? [],
        value: RoutingTabs.assignment,
      },
      {
        label: RoutingTabsInfo[RoutingTabs.scheduled].label,
        elements: scheduledAssignments ?? [],
        value: RoutingTabs.scheduled,
      },
    ],
    [assignmentRules, scheduledAssignments],
  );

  const setupElements: RoutingListItem[] = useMemo(
    () => [
      {
        label: 'Members',
        elements: crmOrgUsers ?? [],
        value: RoutingTabs.members,
      },
      {
        label: 'Groups',
        elements: assignmentGroups ?? [],
        value: RoutingTabs.groups,
      },
      {
        label: 'Territories',
        elements: territories ?? [],
        value: RoutingTabs.territories,
      },
    ],
    [assignmentGroups, crmOrgUsers, territories],
  );

  return (
    <>
      <ConfigurationCanvasPanelHeader>Routing</ConfigurationCanvasPanelHeader>
      <ConfigurationCanvasPanelContent>
        <Stack ml={-2} mr={-2} gap={4}>
          <RoutingTabsList
            title="Rules"
            items={rulesElements}
            onClick={(event: any, key: RoutingTabs) => {
              goToTab(key);
            }}
            activeItem={routingTab}
          />
          <RoutingTabsList
            title="Setup"
            items={setupElements}
            onClick={(event: any, key: RoutingTabs) => {
              goToTab(key);
            }}
            activeItem={routingTab}
          />
        </Stack>
      </ConfigurationCanvasPanelContent>
    </>
  );
};
