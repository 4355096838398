import { Box, Stack } from '@mui/material';
import { Button, colors, Typography } from '@sweep-io/sweep-design';
import WithConnectOrgPermission from '../../../../common/WithConnectOrgPermission';
import { VerticalFlexBox } from '../../../funnel-map-flow/templates/plugins/PluginDetail';

import React, { useCallback, useState } from 'react';
import ConnectToSfDialog from '../../../get-started/connect-org/ConnectToSfDialog';
import { useSelector } from 'react-redux';
import { selectProductionCrmOrg } from '../../../environments/environmentsReducer';
import { Check } from '@sweep-io/sweep-design/dist/icons';
import { selectConnectedHubspotOrg } from '../../../../../reducers/hubspotReducer';
import { HubspotConnectButton } from '../../../../dashboard/user-menu/HubspotConnectButton';
import { ConnectionLine, ConnectSweepTo } from '../../../../common/connect-sweep-to/ConnectSweepTo';

const boxStyle = {
  borderRadius: '12px',
  border: `1px solid ${colors.grey[300]}`,
};

const ConnectFrame = ({
  title,
  subtitle,
  connectTo,
  children,
}: {
  title: string;
  subtitle: string;
  connectTo: 'Salesforce' | 'Hubspot';
  children: React.ReactNode;
}) => {
  return (
    <Box
      sx={{
        width: '400px',
        height: '232px',
        ...boxStyle,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        button: {
          width: 'fit-content',
        },
      }}
    >
      <VerticalFlexBox gap="20px" alignItems="center">
        <ConnectSweepTo connectTo={connectTo} />
        <VerticalFlexBox gap={1}>
          <Typography variant="h4">{title}</Typography>
          <Typography variant="body" color={colors.grey[800]}>
            {subtitle}
          </Typography>
        </VerticalFlexBox>
        {children}
      </VerticalFlexBox>
    </Box>
  );
};

export const ConnectToSfAndHubspot = () => {
  const isProductionConnected = useSelector(selectProductionCrmOrg)?.isConnected;
  const isHubspotConnected = useSelector(selectConnectedHubspotOrg);
  const [isSfDialogOpen, setIsSfDialogOpen] = useState(false);
  const closeSfDialog = useCallback(() => {
    setIsSfDialogOpen(false);
  }, []);

  const connected = (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
      <Check color={colors.mint[500]} />
      <Typography variant="body" color={colors.mint[500]}>
        Connected
      </Typography>
    </Box>
  );

  return (
    <>
      <Stack gap={3} height="100%" alignItems="center" justifyContent="center">
        <Stack alignItems="center">
          <Stack gap={'40px'} alignItems="center" textAlign="center">
            <Typography variant="h1">
              to enable Campaign Enrollments, complete these 2 steps
            </Typography>
            <Stack gap={0} direction="row" alignItems="center" textAlign="center">
              <ConnectFrame
                title="1. Connect to Salesforce production"
                subtitle="to enable Hubspot integration"
                connectTo={'Salesforce'}
              >
                {isProductionConnected ? (
                  connected
                ) : (
                  <WithConnectOrgPermission>
                    <Button onClick={() => setIsSfDialogOpen(true)}>Connect</Button>
                  </WithConnectOrgPermission>
                )}
              </ConnectFrame>
              <ConnectionLine width="108px" />
              <ConnectFrame
                title="2. Connect your HubSpot"
                subtitle="and ensure the Salesforce app is installed"
                connectTo={'Hubspot'}
              >
                {isHubspotConnected ? connected : <HubspotConnectButton />}
              </ConnectFrame>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      {isSfDialogOpen && (
        <ConnectToSfDialog closeDialog={closeSfDialog} forceProduction onFinish={closeSfDialog} />
      )}
    </>
  );
};
