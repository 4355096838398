import { Box, Link, MenuItem } from '@mui/material';
import { colors, Typography } from '@sweep-io/sweep-design';
import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useAssignmentGroupsApiFacade } from '../../../../../apis/facades/useAssignmentGroupsApiFacade';
import { ROUTING_GROUPS } from '../../../../../constants/appRoutes';
import { useCrmOrgs } from '../../../environments/useCrmOrgs';
import { useErrorHandling } from '../../../../../hooks/useErrorHandling';
import { Warning as WarningIcon } from '@sweep-io/sweep-design/dist/icons';
import {
  openAddEnvDialog,
  selectProductionCrmOrg,
} from '../../../environments/environmentsReducer';
import { AssignmentToType } from '../../../../../types/enums/AssignmentToType';
import { SearchSelect } from '../../../../common/SearchSelect';
import SweepSelect from '../../../../common/SweepSelect';
import useSweepNavigate from '../../../../common/useSweepNavigate';
import {
  NestedFieldsSelector,
  NestedFieldsField,
} from '../../../../common/fieldsSelectors/NestedFieldsSelector';
import { ReferenceObjectType } from '../../../../../types/enums/ReferenceObjectType';
import { useSweepFieldsLabels } from '../../../../../sweep-fields/useSweepFieldsLabels';
import { ConnectedConnectCrmOrgDialog } from '../../../environments/ConnectCrmOrgDialog/ConnectedConnectCrmOrgDialog';

const WarningWithLink = ({
  text,
  linkText,
  onClick,
}: {
  text: string;
  linkText: string;
  onClick: () => any;
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        bottom: '-23px',
        width: '300px',
        gap: '2px',
      }}
    >
      <WarningIcon />
      <Typography variant="body">
        {text}
        <Link
          href=""
          sx={{ display: 'inline-block' }}
          onClick={(e) => {
            e.preventDefault();
            onClick();
          }}
        >
          {linkText}
        </Link>
      </Typography>
    </Box>
  );
};

const assignTypeData = {
  [AssignmentToType.User]: {
    optionsLabel: 'Specific User',
    searchPlaceholder: 'Search users',
    notifyPlaceholder: 'Choose User',
    connectEnvironmentText: 'To assign users, ',
  },
  [AssignmentToType.Team]: {
    optionsLabel: 'Assignment Group',
    searchPlaceholder: 'Search for Assignment Group',
    notifyPlaceholder: 'Choose Assignment Group',
    connectEnvironmentText: '',
  },
  [AssignmentToType.Queue]: {
    optionsLabel: 'Salesforce Queue',
    searchPlaceholder: 'Search queues',
    notifyPlaceholder: 'Choose Queue',
    connectEnvironmentText: 'To assign queues, ',
  },
  [AssignmentToType.Field]: {
    optionsLabel: 'Owner from existing field',
    searchPlaceholder: '',
    notifyPlaceholder: 'Choose field',
    connectEnvironmentText: '',
  },
};

const EmptyOptionsWarning = () => {
  return (
    <Box sx={{ width: '188px', padding: 1, display: 'flex', flexDirection: 'column', gap: 1 }}>
      <WarningIcon />
      <Typography variant="body">
        Create your first queue in Salesforce to assign to a queue
      </Typography>
    </Box>
  );
};

export const AssignToComponent = ({
  assignTo = {},
  onChange,
  objectType,
  readonly,
  crmOrgId,
}: {
  assignTo?: Partial<AssignmentsAssignTo>;
  onChange: (assignTo: Partial<AssignmentsAssignTo>) => any;
  objectType: string;
  readonly?: boolean;
  crmOrgId: string;
}) => {
  const dispatch = useDispatch();
  const { navigate } = useSweepNavigate();
  const { get_assignmentGroups } = useAssignmentGroupsApiFacade();
  const { getCrmOrgUsers, getCrmOrgQueues } = useCrmOrgs();
  const [showCreateFirstGroup, setShowCreateFirstGroup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showEmptyQueueWarning, setShowEmptyQueueWarning] = useState(false);
  const { errorHandlingBuilder } = useErrorHandling();
  const isProductionConnected = Boolean(useSelector(selectProductionCrmOrg));
  const removeLoading = () => setLoading(false);

  const [assignedToOptions, setAssignToOptions] = useState<{
    type?: AssignmentToType;
    options: { value: string; label: string }[];
  }>({ options: [] });

  const updateOptions = useCallback(
    async (assignToType?: AssignmentToType) => {
      if (assignedToOptions.type === assignToType) {
        return;
      }
      setShowEmptyQueueWarning(false);
      setLoading(true);
      setShowCreateFirstGroup(false);
      switch (assignToType) {
        case AssignmentToType.Team: {
          const { groups: assignmentGroups } = (await get_assignmentGroups()) || [];
          setShowCreateFirstGroup(assignmentGroups.length === 0);

          const options = assignmentGroups.map((team) => ({
            value: team.id,
            label: team.name,
          }));
          setAssignToOptions({
            type: AssignmentToType.Team,
            options,
          });
          break;
        }
        case AssignmentToType.User: {
          const users = await getCrmOrgUsers();
          if (users) {
            const options = users.map((user) => ({
              value: user.id,
              label: user.name,
            }));
            setAssignToOptions({
              type: AssignmentToType.User,
              options,
            });
          }

          break;
        }
        case AssignmentToType.Queue: {
          const queues = await getCrmOrgQueues(objectType);
          if (queues) {
            const options = queues.map((queue) => ({
              value: queue.id,
              label: queue.name,
            }));
            setAssignToOptions({
              type: AssignmentToType.Queue,
              options,
            });
            if (options.length === 0) {
              setShowEmptyQueueWarning(true);
            }
          }

          break;
        }
        default: {
          setAssignToOptions({ options: [] });
        }
      }
      removeLoading();
    },
    [assignedToOptions.type, getCrmOrgQueues, getCrmOrgUsers, get_assignmentGroups, objectType],
  );

  const updateOptionsErrorHandler = errorHandlingBuilder()
    .withErrorNotification('Error Loading Options')
    .withOnError(removeLoading);

  useEffect(() => {
    assignTo?.type && updateOptionsErrorHandler.execute(() => updateOptions(assignTo.type));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAssignTypeChange = useCallback(
    async (assignType: AssignmentToType) => {
      const fieldMapping = { ...assignTo.fieldMapping };
      onChange({ type: assignType, fieldMapping });
      setAssignToOptions({ type: assignType, options: [] });
      await updateOptionsErrorHandler.execute(() => updateOptions(assignType));
    },
    [assignTo.fieldMapping, onChange, updateOptions, updateOptionsErrorHandler],
  );

  const searchPlaceholder = assignTo?.type
    ? assignTypeData[assignTo.type as AssignmentToType].searchPlaceholder
    : 'Search';

  const notifyPlaceholder = assignTo?.type
    ? assignTypeData[assignTo.type as AssignmentToType].notifyPlaceholder
    : 'Choose how to notify';

  const availableOptions: AssignmentToType[] = [
    AssignmentToType.User,
    AssignmentToType.Team,
    AssignmentToType.Field,
  ];

  const areQueuesSupportedForObject = ['Lead', 'Case'].includes(objectType);

  if (areQueuesSupportedForObject) {
    availableOptions.push(AssignmentToType.Queue);
  }
  const showConnectEnvironment =
    !isProductionConnected &&
    [AssignmentToType.User, AssignmentToType.Queue].includes(assignTo.type);

  const { getEnrichedNestedPath } = useSweepFieldsLabels();

  return (
    <>
      <ConnectedConnectCrmOrgDialog />
      <Box sx={{ display: 'flex', gap: '16px' }}>
        <SweepSelect
          disabled={readonly}
          SelectProps={{
            value: assignTo?.type ?? '',
            onChange: (event: any) => handleAssignTypeChange(event.target.value),
            placeholder: 'Choose who to assign to',
            renderValue: (value) => assignTypeData[value as AssignmentToType].optionsLabel,
          }}
        >
          {availableOptions.map((type) => (
            <MenuItem key={type} value={type}>
              {assignTypeData[type].optionsLabel}
            </MenuItem>
          ))}
        </SweepSelect>
        {assignTo?.type && assignTo.type === AssignmentToType.Field && (
          <Box
            sx={{
              borderRadius: '4px',
              border: `1px solid ${colors.grey[300]}`,
              position: 'relative',
            }}
          >
            <NestedFieldsSelector
              isReferencedValue={true}
              restrictReferencesToObject={ReferenceObjectType.User}
              placeholder={'Choose field'}
              crmOrgId={crmOrgId || ''}
              objectType={objectType}
              nestedPath={getEnrichedNestedPath(assignTo?.fieldMapping?.fromField?.fieldIds)}
              onChange={async (sweepField: NestedFieldsField) => {
                onChange({
                  type: assignTo.type,
                  _name: sweepField.fieldLabels.join('.'),
                  id: sweepField.fieldIds[0],
                  fieldMapping: {
                    ...assignTo.fieldMapping,
                    fromField: {
                      fieldIds: sweepField.fieldIds,
                    },
                  },
                });
              }}
            />
          </Box>
        )}
        {assignTo?.type && assignTo.type !== AssignmentToType.Field && (
          <Box sx={{ position: 'relative' }}>
            <SearchSelect
              isLoading={loading}
              searchPlaceholder={searchPlaceholder}
              SelectProps={{
                placeholder: notifyPlaceholder,
                value: assignTo.id || '',
                disabled: !assignTo?.type || showConnectEnvironment || readonly,
                renderValue: (value) =>
                  assignedToOptions.options.find((option) => option.value === value)?.label ||
                  assignTo._name,
              }}
              onOpenCb={() => updateOptionsErrorHandler.execute(() => updateOptions(assignTo.type))}
              items={assignedToOptions.options.map(({ value, label }) => ({
                value,
                label,
              }))}
              onChange={(item) => {
                const _fieldMapping = { ...assignTo.fieldMapping };
                onChange({
                  type: assignTo.type,
                  _name: item.label,
                  id: item.value,
                  fieldMapping: _fieldMapping,
                });
              }}
              children={showEmptyQueueWarning && <EmptyOptionsWarning />}
            />
            {showCreateFirstGroup && (
              <WarningWithLink
                onClick={() => {
                  navigate(ROUTING_GROUPS);
                  return false;
                }}
                text="Create your first Assignment Group "
                linkText="here"
              />
            )}
            {showConnectEnvironment && (
              <WarningWithLink
                onClick={() => {
                  dispatch(openAddEnvDialog());
                  return false;
                }}
                text={assignTypeData[assignTo?.type as AssignmentToType].connectEnvironmentText}
                linkText="connect Production"
              />
            )}
          </Box>
        )}
      </Box>
    </>
  );
};
