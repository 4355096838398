import { telemetry } from '../../telemetry';
import { UserInfo } from '../../types/UserInfoTypes';

const cpTenantDomain = 'sweep';
const cpRouterName = 'inbound-router';

export const scheduleCall = (userInfo?: UserInfo) => {
  if (!userInfo) {
    telemetry.captureError(new Error('no userInfo was found'));
    return;
  }

  const { email } = userInfo;
  let firstname = '',
    lastname = '';

  if (userInfo.name) {
    const [part1, ...rest] = userInfo.name.split(' ');
    firstname = part1;
    lastname = rest.join(' ');
  }

  window.ChiliPiper.submit(cpTenantDomain, cpRouterName, {
    map: true,
    lead: {
      email,
      firstname,
      lastname,
    },
  });
};

export const openIntercom = (message?: string) => {
  if ((window as any).Intercom) {
    (window as any).Intercom('showMessages');
    (window as any).Intercom('showNewMessage', message);
  }
};
