import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { AccountOnboardingStep } from '@server/account-onboarding';
import {
  appRoutes,
  DEFAULT_ROUTE,
  RoutesToDisableRedirectToOnboarding,
} from '../constants/appRoutes';
import useSweepNavigate from '../components/common/useSweepNavigate';
import { useSelector } from 'react-redux';
import { selectUserInfoData } from '../reducers/userInfoReducer';
import { QuestionnaireStatus } from '@server/questionnaire';

const useOnboardingNavigation = () => {
  const { navigate } = useSweepNavigate();
  const user = useSelector(selectUserInfoData);
  const { pathname } = useLocation();

  useEffect(() => {
    const onboardingCompleted = user?.account?.onboardingStep === AccountOnboardingStep.Completed;
    const navigateToOnboarding =
      !onboardingCompleted && !RoutesToDisableRedirectToOnboarding.includes(pathname);

    if (navigateToOnboarding) {
      if (!pathname.startsWith(appRoutes.freeTierOnBoarding)) {
        navigate(appRoutes.freeTierOnBoarding);
      }
      return;
    }

    const questionnaireCompleted =
      user?.preferences?.questionnaire?.status === QuestionnaireStatus.COMPLETED;
    const navigateToQuestionnaire =
      !questionnaireCompleted && !RoutesToDisableRedirectToOnboarding.includes(pathname);

    if (navigateToQuestionnaire) {
      if (!pathname.startsWith(appRoutes.questionnaire)) {
        navigate(appRoutes.questionnaire);
      }
      return;
    }

    const navigateToDefault = pathname === '/';

    if (navigateToDefault) {
      navigate(DEFAULT_ROUTE);
    }
  }, [navigate, pathname, user?.account?.onboardingStep, user?.preferences?.questionnaire?.status]);
};

export default useOnboardingNavigation;
