import { Box } from '@mui/material';
import { PointAnimation } from '../../animations/lottie/lottieAnimations';

const POINT_SIZE = 32;

//These props are used to locate the *center* of the point
export type LocationProps = {
  top?: number;
  left?: number;
  right?: number;
  bottom?: number;
}

const WalkthroughPointAnimation = ({
  top,
  left,
  right,
  bottom,
}: LocationProps) => (
  <Box sx={{ position: 'absolute', pointerEvents: 'none', zIndex: 1, top, left, right, bottom }}>
    <Box sx={{ position: 'absolute', top: -(POINT_SIZE/2), left: -(POINT_SIZE/2) }}>
      <PointAnimation size={POINT_SIZE} />
    </Box>
  </Box>
);

export default WalkthroughPointAnimation;
