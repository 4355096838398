import { AssignmentGroupLimitTime } from '../../../../reducers/assignmentGroupTypes';
import { DateTime } from 'luxon';
import { Box, MenuItem } from '@mui/material';
import {
  AssignmentGroupLimitTimeLimitRate,
  WeekDay,
} from '../../../../reducers/assignmentGroupTypes';
import { ToggleGroup, Typography, colors } from '@sweep-io/sweep-design';
import SweepSelect from '../../../common/SweepSelect';
import { useCallback } from 'react';
import { set } from 'lodash';
import {
  TimezoneSelector,
  getSystemTimezone,
} from '../../../common/timezone-selector/TimezoneSelector';

type LimitMembersTimeVars = {
  groupLimit: DeepPartial<AssignmentGroupLimitTime> | null;
  resetAllMembersLimit?: number;
};

interface LimitMembersTimePanelProps {
  onChange: (onChangeProps: LimitMembersTimeVars) => void;
  vars: LimitMembersTimeVars;
  children?: JSX.Element | null;
}

const { Hour, Day, Week } = AssignmentGroupLimitTimeLimitRate;

const RESET_LIMIT_OPTIONS = [
  {
    label: 'Hour',
    value: Hour,
  },
  {
    label: 'Day',
    value: Day,
  },
  {
    label: 'Week',
    value: Week,
  },
];

export const LimitMembersTimePanel = ({ onChange, vars, children }: LimitMembersTimePanelProps) => {
  const { groupLimit } = vars;
  const limitRate = groupLimit?.details?.rate;
  const showTimezone = limitRate !== Hour;
  const showWeekDefinition = limitRate === Week;

  const onLimitRateChanged = useCallback(
    (limitRate: AssignmentGroupLimitTimeLimitRate) => {
      const newLimitMembersVars: LimitMembersTimeVars = Object.assign({}, vars);
      set(newLimitMembersVars, 'groupLimit.details.rate', limitRate);

      const userTimezone = getSystemTimezone();
      const userWeekStartDay: WeekDay = (DateTime.now()
        .startOf('week')
        .weekdayLong?.toLocaleUpperCase() ?? WeekDay.Monday) as WeekDay;

      const isLimitRateDayOrWeek = [Day, Week].includes(limitRate);
      if (isLimitRateDayOrWeek) {
        set(
          newLimitMembersVars,
          'groupLimit.details.timeDetails.timezone',
          (newLimitMembersVars.groupLimit?.details as { timezone?: string })?.timezone ??
            userTimezone ??
            undefined,
        );
      }
      if (limitRate === Week) {
        set(
          newLimitMembersVars,
          'groupLimit.details.timeDetails.weekStartDay',
          (newLimitMembersVars.groupLimit?.details?.timeDetails as { weekStartDay?: WeekDay })
            ?.weekStartDay ??
            userWeekStartDay ??
            undefined,
        );
      }
      onChange(newLimitMembersVars);
    },

    [onChange, vars],
  );

  return (
    <>
      <Box display="flex" gap={'12px'} alignItems="center">
        {children}
      </Box>
      <Box display="flex" gap={2} alignItems="center">
        <Box display="flex" flexDirection="column" gap="5px">
          <Typography variant="caption" color={colors.grey[800]}>
            Reset limit every
          </Typography>
          <ToggleGroup
            size="medium"
            options={RESET_LIMIT_OPTIONS}
            value={groupLimit?.details?.rate as string}
            onChange={(value) => onLimitRateChanged(value as AssignmentGroupLimitTimeLimitRate)}
          />
        </Box>
        <Box flexGrow={1} display="flex" gap={2}>
          {showTimezone && (
            <Box display="flex" flexDirection="column" gap="5px">
              <Typography variant="caption" color={colors.grey[800]}>
                Timezone
              </Typography>

              <TimezoneSelector
                timezone={(groupLimit?.details?.timeDetails as any)?.timezone}
                onChange={(timezone) => {
                  const newLimitMembersVars: LimitMembersTimeVars = Object.assign({}, vars);
                  set(newLimitMembersVars, 'groupLimit.details.timeDetails.timezone', timezone);
                  onChange(newLimitMembersVars);
                }}
                width="280px"
              />
            </Box>
          )}
          {showWeekDefinition && (
            <Box display="flex" flexDirection="column" gap="5px" flexGrow={1}>
              <Typography variant="caption" color={colors.grey[800]}>
                Week definition
              </Typography>
              <SweepSelect
                FormControlProps={{ fullWidth: true }}
                SelectProps={{
                  placeholder: 'Week definition',
                  value: (groupLimit?.details?.timeDetails as { weekStartDay?: WeekDay })
                    ?.weekStartDay,
                  onChange: (e) => {
                    const newLimitMembersVars: LimitMembersTimeVars = Object.assign({}, vars);
                    set(
                      newLimitMembersVars,
                      'groupLimit.details.timeDetails.weekStartDay',
                      String(e.target.value),
                    );

                    onChange(newLimitMembersVars);
                  },
                }}
              >
                {[
                  [WeekDay.Monday, 'Monday to Sunday'],
                  [WeekDay.Tuesday, 'Tuesday to Monday'],
                  [WeekDay.Wednesday, 'Wednesday to Tuesday'],
                  [WeekDay.Thursday, 'Thursday to Wednesday'],
                  [WeekDay.Friday, 'Friday to Thursday'],
                  [WeekDay.Saturday, 'Saturday to Friday'],
                  [WeekDay.Sunday, 'Sunday to Saturday'],
                ].map(([value, label]) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </SweepSelect>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export const validateLimitMembersPanel = (vars: LimitMembersTimeVars) => {
  const limitRate = vars.groupLimit?.details?.rate;
  const timezone = (vars.groupLimit?.details?.timeDetails as { timezone?: string })?.timezone;
  const weekStartDay = (vars.groupLimit?.details?.timeDetails as { weekStartDay?: string })
    ?.weekStartDay;

  const limitMembers = Boolean(vars.groupLimit);

  return (
    !limitMembers ||
    Boolean(limitRate === Hour) ||
    (Boolean(limitRate === Day) && Boolean(timezone)) ||
    (Boolean(limitRate === Week) && Boolean(timezone) && Boolean(weekStartDay))
  );
};
