import { Fragment } from 'react/jsx-runtime';
import { ReachedLastRow } from './ReachedLastRow';
import { TableEmptyStateComponentWrapper } from './TableEmptyStateComponentWrapper';
import { DataTableProps, DataTableRow, DataTableVariant } from './TableTypes';
import TableRow from './TableRow';
import { Draggable, DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd';
import { colors } from '@sweep-io/sweep-design';

export function TableBodyRows<TRow extends DataTableRow = any>({
  rows,
  TableEmptyStateComponent,
  provided, //from DnD
  reachedLastRow,
  containerRef,
  allowReorder,
  renderRow,
  isDragging,
  sxRowFunction,
  onRowClick,
  actionableButtonsOnHover,
  variant,
  columns,
}: Pick<
  DataTableProps<TRow>,
  | 'reachedLastRow'
  | 'containerRef'
  | 'rows'
  | 'columns'
  | 'TableEmptyStateComponent'
  | 'allowReorder'
  | 'renderRow'
  | 'sxRowFunction'
  | 'onRowClick'
  | 'actionableButtonsOnHover'
> & { provided?: any; isDragging?: boolean; variant: DataTableVariant }) {
  const getItemStyle = (
    isDragging: boolean,
    draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
  ) => ({
    backgroundColor: isDragging ? colors.grey[100] : '',
    ...draggableStyle,
  });

  const renderSingleRow = (row: TRow, rowIdx: number, rowKey: string) => {
    const renderedRow =
      renderRow &&
      renderRow({
        rowIdx,
        row,
        rowKey,
      });

    if (renderedRow) {
      return renderedRow;
    }

    if (!allowReorder) {
      return (
        <TableRow
          row={row}
          columns={columns}
          allowReorder={false}
          sxRowFunction={sxRowFunction}
          tableRowProps={{ id: `table_row_${rowIdx}` }}
          onClick={(e, column) => {
            onRowClick && onRowClick(e, row, column);
          }}
          actionableButtonsOnHover={actionableButtonsOnHover}
          variant={variant}
        />
      );
    }

    return (
      <Draggable draggableId={row.id} index={rowIdx} key={row.id}>
        {(provided, snapshot) => {
          return (
            <TableRow
              tableRowProps={{
                ref: provided.innerRef,
                ...provided.draggableProps,
              }}
              dragHandleProps={provided.dragHandleProps}
              row={row}
              columns={columns}
              allowReorder={true}
              sxRowFunction={sxRowFunction}
              isDragging={isDragging}
              inlineStyle={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
              onClick={(e, column) => {
                onRowClick && onRowClick(e, row, column);
              }}
              actionableButtonsOnHover={actionableButtonsOnHover}
              variant={variant}
            />
          );
        }}
      </Draggable>
    );
  };

  return (
    <>
      {!rows.length && (
        <TableEmptyStateComponentWrapper
          TableEmptyStateJsx={TableEmptyStateComponent}
          columnsCount={columns.length}
        />
      )}

      {rows.map((row, rowIdx) => (
        <Fragment key={`wrapper_${row.id}`}>
          {renderSingleRow(row, rowIdx, `base_row_${rowIdx}`)}
          {row?.nestedRows &&
            row.nestedRows.map((row: any, idx: number) =>
              renderSingleRow(row, idx, `nested_row_${row.id}`),
            )}
        </Fragment>
      ))}

      {provided?.placeholder}

      {rows.length === reachedLastRow?.rowsMaxCount && (
        <ReachedLastRow
          columnsCount={columns.length}
          reachedLastRowTxt={reachedLastRow.txt}
          scrollToTop={() => {
            containerRef?.current?.scrollTo({ top: 0, behavior: 'smooth' });
          }}
        />
      )}
    </>
  );
}
