import { useState } from 'react';
import {
  DataTableColumn,
  DataTableVariant,
  SortCompareTypes,
} from '../../../common/table/TableTypes';
import { cloneDeep, groupBy, keyBy, uniq } from 'lodash';

import {
  InlineAutocomplete,
  InlineAutocompleteItem,
} from '../InlineAutocomplete/InlineAutocomplete';
import { useAssignmentsApiWithReducer } from '../useAssignmentsApiWithReducer';
import { Box } from '@mui/material';
import AdvancedFilterDynamic from '../../../common/deprecated-advanced-filter/AdvancedFilterDynamic';
import { AssignmentGroup } from '../../../../reducers/assignmentGroupTypes';
import { Button, Tooltip, Typography, colors } from '@sweep-io/sweep-design';
import { SnoozeDialog } from './SnoozeDialog';
import { useDispatch } from 'react-redux';
import { setCrmOrgUsers, updateCrmOrgUser } from '../../../../reducers/crmOrgUsersReducer';
import { uniqueId } from '../../../../lib/uniqueId';
import { DateTime } from 'luxon';
import { AddTimeOffDialog } from './AddTimeoffDialog';
import { TimeOffHistoryDialog } from './TimeOffHistoryDialog';
import { HistoryTimeOffs } from './helpers';
import { FlexBox } from '../../../common/FlexBox';
import { SearchInput } from '../../../common/SearchInput';
import { filterItemsBySearch } from '../../../../lib/filterItemsBySearch';
import { changeSweepFamilyWeight } from '../../../../lib/sweepColorUtilities';
import { useErrorHandling } from '../../../../hooks/useErrorHandling';
import { SortOrder } from '../../../common/types';
import { useCrmOrgsApiFacade } from '../../../../apis/facades/useCrmOrgsApiFacade';
import { useRunOnce } from '../../../common/useRunOnce';
import { dataTableVariants } from '../../../common/table/dataTableVariants';
import { telemetry } from '../../../../telemetry';
import { VirtualScrollDataTable } from '../../../common/table/VirtualScrollDataTable';

const columns: DataTableColumn[] = [
  {
    field: 'name',
    headerName: 'Name',
    width: '240px',
    showSortIcon: true,
    sortCompare: {
      type: SortCompareTypes.Custom,
      compareFunction(a, b, order) {
        return a.data.name.localeCompare(b.data.name) * (order === SortOrder.ASC ? 1 : -1);
      },
    },
  },
  {
    field: 'assignmentGroups',
    headerName: 'Assignment Groups',
    className: 'assignment-members__assignment-groups',
  },
  {
    field: 'actions',
    headerName: '',
    width: '300px',
  },
];

const TooltipTitle = ({ title, subtitle }: { title: string; subtitle: string }) => {
  return (
    <Box lineHeight={'14px'}>
      <Typography variant="caption-bold" color={colors.grey[300]}>
        {title}
      </Typography>
      <br />
      <Typography variant="caption-bold" color={colors.grey[500]}>
        {subtitle}
      </Typography>
    </Box>
  );
};

interface MembersListProps {
  crmOrgUsers: CrmOrgUser[];
  assignmentGroups: AssignmentGroup[];
  crmOrgId: string;
}

const MembersList = ({ crmOrgUsers, assignmentGroups, crmOrgId }: MembersListProps) => {
  const dispatch = useDispatch();
  const { addUserToGroup, removeUserFromGroup, createNewAssignmentGroupWithMember } =
    useAssignmentsApiWithReducer();

  const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
  const [snoozeDialogUser, setSnoozeDialogUser] = useState<CrmOrgUser>();
  const [addTimeOffDialogUser, setAddTimeOffDialogUser] = useState<CrmOrgUser>();
  const [editTimeOffDialogUser, setEditTimeOffDialogUser] = useState<{
    user: CrmOrgUser;
    timeOffId: string;
  }>();
  const [historyDialogUser, setHistoryDialogUser] = useState<CrmOrgUser>();
  const [reopenHistoryDialog, setReopenHistoryDialog] = useState<boolean>(false);
  const [searchMembers, setSearchMembers] = useState<string>('');

  const [isCreatingSet, setIsCreatingSet] = useState<{ [userId: string]: boolean }>({});
  const { errorHandlingBuilder } = useErrorHandling();

  const closeSnoozeDialog = () => setSnoozeDialogUser(undefined);
  const closeAddTimeOffDialog = () => setAddTimeOffDialogUser(undefined);
  const closeEditTimeOffDialog = () => setEditTimeOffDialogUser(undefined);
  const closeHistoryDialog = () => setHistoryDialogUser(undefined);

  const { post_userTimeOff, delete_userTimeOff, put_userTimeOff } = useCrmOrgsApiFacade();

  const groupItems: InlineAutocompleteItem<any>[] = assignmentGroups.map((assignmentGroup) => {
    const bgColor = assignmentGroup.avatar?.emoji?.bgColor;
    return {
      label: assignmentGroup.name,
      labelDecorator: assignmentGroup.avatar?.emoji?.content,
      tagColor: bgColor ? changeSweepFamilyWeight(bgColor, 100) : undefined,
      value: assignmentGroup.id,
    };
  });

  const renderName = (crmOrgUser: CrmOrgUser) => {
    const timeOffs = new HistoryTimeOffs(crmOrgUser.timeOffs || []);
    const _activeSnooze = timeOffs.getActiveSnooze();
    const _hasActiveTimeOff = timeOffs.getActiveTimeOffUntil();

    return (
      <FlexBox gap="4px">
        <Typography variant={dataTableVariants[DataTableVariant.default].fontVariant}>
          {crmOrgUser.name}
        </Typography>
        {_activeSnooze && !_hasActiveTimeOff && (
          <Tooltip
            title={
              <TooltipTitle
                title="⏱️ Snoozed"
                subtitle={`Until ${_activeSnooze._dateTimeEndDate.toLocaleString(
                  DateTime.DATETIME_SHORT,
                )}`}
              />
            }
          >
            <Typography
              variant={dataTableVariants[DataTableVariant.default].fontVariant}
              color={colors.grey[500]}
            >
              ⏱️ Snoozed
            </Typography>
          </Tooltip>
        )}
        {_hasActiveTimeOff && (
          <Tooltip
            title={
              <TooltipTitle
                title="🚫 Unavailable"
                subtitle={`Until ${_hasActiveTimeOff._dateTimeEndDate.toLocaleString(
                  DateTime.DATETIME_SHORT,
                )}`}
              />
            }
          >
            <Typography
              variant={dataTableVariants[DataTableVariant.default].fontVariant}
              color={colors.grey[500]}
            >
              🚫 Unavailable
            </Typography>
          </Tooltip>
        )}
      </FlexBox>
    );
  };

  const renderUserGroups = ({
    groups = [],
    crmOrgUser,
  }: {
    groups: { groupId: string }[];
    crmOrgUser: CrmOrgUser;
  }) => {
    const selectedItemValues = groups.map((group) => group.groupId);
    return (
      <InlineAutocomplete
        placeholder="Add groups"
        items={groupItems}
        selectedItemValues={selectedItemValues}
        onSelectItem={(item) => {
          addUserToGroup(item.value, crmOrgUser);
          setSelectedGroups(Array.from(new Set([...selectedGroups, item.value])));
        }}
        onDeleteItem={(item) => {
          removeUserFromGroup(item.value, crmOrgUser.id);
        }}
        createInProgress={isCreatingSet[crmOrgUser.id]}
        onCreate={async (groupName) => {
          errorHandlingBuilder()
            .withErrorNotification('Error creating group')
            .withOnError(() => {
              delete isCreatingSet[crmOrgUser.id];
              setIsCreatingSet({ ...isCreatingSet });
            })
            .execute(async () => {
              const newIsCreatingSet = { ...isCreatingSet, [crmOrgUser.id]: true };
              setIsCreatingSet(newIsCreatingSet);
              const group = await createNewAssignmentGroupWithMember(groupName, crmOrgUser);
              setSelectedGroups(Array.from(new Set([...selectedGroups, group.id])));
              delete newIsCreatingSet[crmOrgUser.id];
              setIsCreatingSet({ ...newIsCreatingSet });
            });
        }}
        closeOnItemSelection={selectedItemValues.length === 0} // Close on first item selected.
      />
    );
  };

  const renderActions = (crmOrgUser: CrmOrgUser) => {
    const timeOffs = crmOrgUser.timeOffs || [];
    const showUnsnooze = Boolean(new HistoryTimeOffs(timeOffs).getActiveSnooze());
    const currentAndFutureTimeOffs = new HistoryTimeOffs(timeOffs).getCurrentAndFutureTimeOffs();
    const hasActiveTimeOffs = currentAndFutureTimeOffs.length > 0;
    const totalActiveTimeOffs = currentAndFutureTimeOffs.length;
    return (
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        gap={2}
        className="assignments-members-table__action-buttons"
      >
        {showUnsnooze && (
          <Button
            variant="link"
            startIconName="Timer"
            onClick={async () => {
              const timeOffsToDelete =
                crmOrgUser.timeOffs?.filter((timeOff) => {
                  return timeOff.type === 'SNOOZE';
                }) || [];

              // Delete all snooze time offs
              await Promise.all(
                timeOffsToDelete.map((timeOff) =>
                  delete_userTimeOff({
                    crmOrgId: crmOrgId,
                    timeOffId: timeOff.id,
                    userId: crmOrgUser.id,
                  }),
                ),
              );

              const timeOffs =
                crmOrgUser.timeOffs?.filter((timeOff) => {
                  return timeOff.type !== 'SNOOZE';
                }) || [];

              dispatch(
                updateCrmOrgUser({
                  crmOrgId: crmOrgId,
                  crmOrgUser: {
                    ...crmOrgUser,
                    timeOffs,
                  },
                }),
              );
            }}
          >
            Unsnooze
          </Button>
        )}
        {!showUnsnooze && (
          <Button
            variant="link"
            startIconName="Timer"
            onClick={() => setSnoozeDialogUser(crmOrgUser)}
          >
            Snooze
          </Button>
        )}
        {!hasActiveTimeOffs && (
          <Button
            variant="link"
            startIconName="Calendar"
            onClick={() => {
              setAddTimeOffDialogUser(crmOrgUser);
            }}
          >
            Add time off
          </Button>
        )}
        {hasActiveTimeOffs && (
          <Button
            variant="link"
            startIconName="Calendar"
            onClick={() => setHistoryDialogUser(crmOrgUser)}
          >
            Time off ({totalActiveTimeOffs})
          </Button>
        )}
      </Box>
    );
  };

  const groupsPerUserIdMap = groupBy(
    assignmentGroups
      .map((assignmentGroup) =>
        assignmentGroup.members.map((member) => {
          return {
            userId: member.userId,
            groupId: assignmentGroup.id,
          };
        }),
      )
      .flat(),
    'userId',
  );

  const assignmentsGroupListById = keyBy(assignmentGroups, 'id');
  const usedGroupItems = uniq(
    Object.values(groupsPerUserIdMap)
      .map((groups) => groups.map((g) => g.groupId))
      .flat(),
  )
    .map((id) => assignmentsGroupListById[id])
    .map((group) => ({
      label: group.name,
      value: group.id,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  useRunOnce(() => {
    // Sort by group existence, then by name.
    // But only when the page loads to avoid user jumps when
    // adding/removing users from groups.

    const sortedCrmOrgUsers = [...crmOrgUsers].sort((a, b) => {
      const userAHasGroups = Boolean(groupsPerUserIdMap[a.id]?.length);
      const userBHasGroups = Boolean(groupsPerUserIdMap[b.id]?.length);

      const sortByGroupSize = Number(userBHasGroups) - Number(userAHasGroups);

      if (sortByGroupSize === 0) {
        return a.name.localeCompare(b.name); // Sort by name if group size is the same.
      }
      return sortByGroupSize;
    });

    dispatch(setCrmOrgUsers({ crmOrgId, crmOrgUsers: sortedCrmOrgUsers }));
  });

  const filteredCrmOrgUsers = filterItemsBySearch<CrmOrgUser>(
    crmOrgUsers,
    searchMembers,
    (crmOrgUser) => crmOrgUser.name,
  );

  const rows = filteredCrmOrgUsers.map((crmOrgUser) => {
    return {
      id: crmOrgUser.id,
      name: renderName(crmOrgUser),
      assignmentGroups: renderUserGroups({ groups: groupsPerUserIdMap[crmOrgUser.id], crmOrgUser }),
      actions: renderActions(crmOrgUser),
      data: crmOrgUser,
    };
  });

  const renderFilters = () => {
    const items = [
      {
        label: '(No group)',
        value: '__no-group__',
      },
      ...usedGroupItems,
    ];

    return (
      <Box display="flex">
        <Box
          alignItems="center"
          sx={{
            flex: 1,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <AdvancedFilterDynamic
            items={items}
            selectedItems={selectedGroups}
            onSelectedItemsChange={setSelectedGroups}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            texts={{
              title: 'Show users from:',
              allSelected: 'All groups',
            }}
          />
          <Box>
            <SearchInput
              withFixedMagnifyingGlassIcon
              TextFieldProps={{
                value: searchMembers,
                sx: {
                  maxWidth: '270px',
                },
                onChange: (e) => {
                  setSearchMembers(e.target.value);
                },
                placeholder: 'Search members',
              }}
              onClearButtonClick={() => setSearchMembers('')}
            />
          </Box>
        </Box>
      </Box>
    );
  };
  const filteredRows = rows.filter((row) => {
    if (selectedGroups.length === 0) {
      return true;
    }
    const groups = groupsPerUserIdMap[row.id];
    if (!groups || groups.length === 0) {
      return selectedGroups.includes('__no-group__');
    }
    return groups.some((group) => selectedGroups.includes(group.groupId));
  });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      {renderFilters()}
      <Box
        height="calc(100vh - 235px)"
        sx={{
          '.MuiTableCell-root': {
            paddingTop: '2px !important',
            paddingBottom: '2px !important',
          },
          '.SweepDataTableRow .add-to-group': {
            visibility: 'hidden',
          },
          '.SweepDataTableRow:hover .add-to-group': {
            visibility: 'visible',
          },
          '.SweepDataTableRow  .assignments-members-table__action-buttons': {
            visibility: 'hidden',
          },
          '.SweepDataTableRow:hover .assignments-members-table__action-buttons': {
            visibility: 'visible',
          },
          'th.assignment-members__assignment-groups': {
            paddingLeft: '24px !important',
          },
        }}
      >
        <VirtualScrollDataTable
          columns={columns}
          rows={filteredRows}
          TableEmptyStateComponent={
            <Typography variant="caption">No results matching your filter</Typography>
          }
        />
      </Box>
      {snoozeDialogUser && (
        <SnoozeDialog
          user={snoozeDialogUser}
          onClose={closeSnoozeDialog}
          onConfirm={async (endDate, timezone) => {
            const timeOffs =
              snoozeDialogUser.timeOffs?.filter((timeOff) => {
                return timeOff.type !== 'SNOOZE';
              }) || [];
            const timeOff: CrmOrgUserTimeOff = {
              type: 'SNOOZE',
              description: '',
              id: uniqueId(),
              startDate: DateTime.now().toISO() || '',
              endDate,
              timezone,
            };
            errorHandlingBuilder()
              .withErrorNotification('Error snoozing users')
              .withOnError((e, addNotification) => {
                telemetry.captureError(e);
                // We have an optimistic update, so we need to revert it if the request fails.
                updateCrmOrgUser({
                  crmOrgId,
                  crmOrgUser: {
                    ...snoozeDialogUser,
                    timeOffs: [...timeOffs],
                  },
                });
                addNotification('Error snoozing user');
              })
              .execute(() => {
                post_userTimeOff({
                  crmOrgId,
                  timeOff,
                  userId: snoozeDialogUser.id,
                });
                dispatch(
                  updateCrmOrgUser({
                    crmOrgId,
                    crmOrgUser: {
                      ...snoozeDialogUser,
                      timeOffs: [...timeOffs, timeOff],
                    },
                  }),
                );
              });

            closeSnoozeDialog();
          }}
        />
      )}
      {addTimeOffDialogUser && (
        <AddTimeOffDialog
          user={addTimeOffDialogUser}
          onConfirm={async (
            description: string,
            startDate: string,
            endDate: string,
            timezone: string,
          ) => {
            const timeOffs = addTimeOffDialogUser.timeOffs || [];
            let newAddTimeOffDialogUser: CrmOrgUser = addTimeOffDialogUser;

            const timeOff: CrmOrgUserTimeOff = {
              type: 'TIME_OFF',
              description,
              id: uniqueId(),
              startDate,
              endDate,
              timezone,
            };
            errorHandlingBuilder()
              .withOnError((e, addNotification) => {
                telemetry.captureError(e);
                // We have an optimistic update, so we need to revert it if the request fails.
                newAddTimeOffDialogUser = {
                  ...addTimeOffDialogUser,
                  timeOffs: [...timeOffs],
                };
                updateCrmOrgUser({
                  crmOrgId,
                  crmOrgUser: newAddTimeOffDialogUser,
                });
                addNotification('Error creating time off');
              })
              .execute(() => {
                post_userTimeOff({
                  crmOrgId,
                  timeOff,
                  userId: addTimeOffDialogUser.id,
                });
                newAddTimeOffDialogUser = {
                  ...addTimeOffDialogUser,
                  timeOffs: [...timeOffs, timeOff],
                };
              });

            dispatch(
              updateCrmOrgUser({
                crmOrgId,
                crmOrgUser: newAddTimeOffDialogUser,
              }),
            );

            closeAddTimeOffDialog();
            if (reopenHistoryDialog) {
              setReopenHistoryDialog(false);
              setHistoryDialogUser(newAddTimeOffDialogUser);
            }
          }}
          onClose={() => {
            closeAddTimeOffDialog();
            if (reopenHistoryDialog) {
              setHistoryDialogUser(addTimeOffDialogUser);
              setReopenHistoryDialog(false);
            }
          }}
        />
      )}
      {editTimeOffDialogUser && (
        <AddTimeOffDialog
          user={editTimeOffDialogUser.user}
          timeOff={editTimeOffDialogUser.user.timeOffs?.find(
            (timeOff) => timeOff.id === editTimeOffDialogUser.timeOffId,
          )}
          onConfirm={(
            description: string,
            startDate: string,
            endDate: string,
            timezone: string,
          ) => {
            const timeOffs = cloneDeep(editTimeOffDialogUser.user.timeOffs || []);
            const idx = timeOffs.findIndex(
              (timeOff) => timeOff.id === editTimeOffDialogUser.timeOffId,
            );
            if (idx !== -1) {
              timeOffs[idx] = {
                type: 'TIME_OFF',
                description,
                id: timeOffs[idx].id,
                startDate,
                endDate,
                timezone,
              };
              errorHandlingBuilder()
                .withErrorNotification('Error updating time off')
                .execute(async () => {
                  await put_userTimeOff({
                    crmOrgId,
                    timeOff: timeOffs[idx],
                    userId: editTimeOffDialogUser.user.id,
                  });
                  dispatch(
                    updateCrmOrgUser({
                      crmOrgId,
                      crmOrgUser: {
                        ...editTimeOffDialogUser.user,
                        timeOffs,
                      },
                    }),
                  );
                  if (reopenHistoryDialog) {
                    setHistoryDialogUser({
                      ...editTimeOffDialogUser.user,
                      timeOffs,
                    });
                    setReopenHistoryDialog(false);
                  }
                })
                .finally(closeEditTimeOffDialog);
            } else {
              closeEditTimeOffDialog();
            }
          }}
          onClose={() => {
            if (reopenHistoryDialog) {
              setHistoryDialogUser(editTimeOffDialogUser.user);
              setReopenHistoryDialog(false);
            }
            closeEditTimeOffDialog();
          }}
        />
      )}
      {historyDialogUser && (
        <TimeOffHistoryDialog
          user={historyDialogUser}
          onClose={closeHistoryDialog}
          onAddTimeOff={() => {
            closeHistoryDialog();
            setReopenHistoryDialog(true);
            setAddTimeOffDialogUser(historyDialogUser);
          }}
          onDeleteTimeOff={async (timeOffId) => {
            const timeOffs = historyDialogUser.timeOffs?.filter(
              (timeOff) => timeOff.id !== timeOffId,
            );
            const newCrmOrgUser = {
              ...historyDialogUser,
              timeOffs,
            };
            errorHandlingBuilder()
              .withErrorNotification('Error deleting time off')
              .execute(async () => {
                await delete_userTimeOff({
                  crmOrgId,
                  timeOffId,
                  userId: newCrmOrgUser.id,
                });

                setHistoryDialogUser(newCrmOrgUser);

                dispatch(
                  updateCrmOrgUser({
                    crmOrgId,
                    crmOrgUser: newCrmOrgUser,
                  }),
                );
              });
          }}
          onEditTimeOff={(timeOffId) => {
            setEditTimeOffDialogUser({
              user: historyDialogUser,
              timeOffId,
            });
            closeHistoryDialog();
            setReopenHistoryDialog(true);
          }}
        />
      )}
    </Box>
  );
};

export default MembersList;
