import { Box } from '@mui/material';
import { Typography, colors } from '@sweep-io/sweep-design';
import { ItemsGroupSelector, ItemsGroupSelectorItem } from './ItemsGroupSelector';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useCrmOrgs } from '../../environments/useCrmOrgs';
import { useSelector } from 'react-redux';
import { AssignmentGroupMember } from '../../../../reducers/assignmentGroupTypes';
import keyBy from 'lodash/keyBy';
import ConnectToSfDialog from '../../get-started/connect-org/ConnectToSfDialog';
import AppExchangeButton from '../../../common/install-managed-package/AppExchangeButton';
import { selectDefaultCreationCrmOrgId } from '../../../../reducers/userInfoReducer';
import { telemetry } from '../../../../telemetry';

enum ErrorType {
  Mp = 'Mp', // Managed Package
  Unknown = 'Unknown',
}

const errorData = {
  [ErrorType.Mp]: {
    title: 'Sweep Managed Package must be installed',
    description:
      'To add users to your group, first install Sweep Managed Package in Salesforce, in your Production environment.',
  },
  [ErrorType.Unknown]: {
    title: 'Couldn’t access your Salesforce Users',
    description:
      'Due to technical reasons, we couldn’t access your users. Please reach out to Sweep for further assistance.',
  },
};

export const MembersPanel = ({
  members,
  onChange,
}: {
  members: AssignmentGroupMember[];
  onChange: (newMembers: AssignmentGroupMember[]) => void;
}) => {
  const { getCrmOrgUsers } = useCrmOrgs();
  const crmOrgId = useSelector(selectDefaultCreationCrmOrgId);
  const [serverError, setServerError] = useState<ErrorType>();
  const [isConnectSfDialogOpen, setIsConnectSfDialogOpen] = useState(false);
  const closeConnectSfDialog = useCallback(() => {
    setIsConnectSfDialogOpen(false);
  }, []);

  const [sfUserItems, setSfUserItems] = useState<ItemsGroupSelectorItem[]>();
  const selectedUserIds = members.map((member) => member.userId);

  const errorType = serverError;
  const originalGroupMembersByKey = keyBy(members, 'id');
  const sfUsersByKey = keyBy(sfUserItems, 'value');

  const currentFetchingCrmOrgId = useRef<string | null | undefined>();

  useEffect(() => {
    (async function () {
      if (currentFetchingCrmOrgId.current === crmOrgId) {
        return;
      }
      try {
        currentFetchingCrmOrgId.current = crmOrgId;
        const sfUsers = await getCrmOrgUsers(true);
        const assignmentMemberIds = members.map((member) => member.userId);
        const filterUnusedInactiveUsers = (sfUser: CrmOrgUser) =>
          sfUser.isActive || assignmentMemberIds?.includes(sfUser.id);

        if (sfUsers) {
          setSfUserItems(
            sfUsers.filter(filterUnusedInactiveUsers).map((user) => ({
              label: user.name,
              value: user.id,
            })),
          );
        }
      } catch (e: any) {
        const errorCode = e.response?.data?.sweepError;
        if (errorCode === 0) {
          setServerError(ErrorType.Mp);
        } else {
          setServerError(ErrorType.Unknown);
          telemetry.captureError(e);
        }
      }
    })();
  }, [crmOrgId, getCrmOrgUsers, members]);

  const setMembers = (newMemberIds: string[]) => {
    const newMembers = newMemberIds.map(
      (id) =>
        originalGroupMembersByKey[id] ?? {
          userId: id,
          dateAdded: Date.now(),
          name: sfUsersByKey[id]?.label || 'Unknown User',
          weight: 1,
          membershipActive: true,
          userActive: true,
        },
    );
    onChange(newMembers);
  };

  return (
    <>
      {isConnectSfDialogOpen && (
        <ConnectToSfDialog
          onFinish={closeConnectSfDialog}
          forceProduction={true}
          closeDialog={closeConnectSfDialog}
        />
      )}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <Typography variant="h4">Add Members</Typography>
        {!errorType && (
          <Box
            sx={{
              display: 'flex',
              height: '453px',
              minHeight: '200px',
            }}
          >
            <ItemsGroupSelector
              items={sfUserItems || []}
              loading={!Boolean(sfUserItems)}
              selectedItems={selectedUserIds}
              onSelectedItemsChange={setMembers}
            />
          </Box>
        )}
        {errorType && (
          <Box
            sx={{
              display: 'flex',
              height: '211px',
              border: '1px dashed',
              borderColor: colors.grey[300],
              borderRadius: '12px',
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: 3,
                flexDirection: 'column',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: '4px',
                  flexDirection: 'column',
                  color: colors.grey[800],
                }}
              >
                <Typography variant="body-bold">{errorData[errorType].title}</Typography>
                <Typography variant="body">{errorData[errorType].description}</Typography>
              </Box>
              <Box>{errorType === ErrorType.Mp && <AppExchangeButton />}</Box>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};
