import { TableContainer, Box } from '@mui/material';
import { StyledTable, StyledTableBody, StyledTableHead } from './StyledTableComponents';
import { useState, useCallback } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { DataTableProps, DataTableRow, DataTableVariant, SortCompareTypes } from './TableTypes';
import { keyBy } from 'lodash';
import { SortOrder } from '../types';
import { TableHeadRow } from './TableHeadRow';
import { TableLoader } from './TableLoader';
import { TableBodyRows } from './TableBodyRows';

/** Current limitation: "renderRow" is applicable only for non-draggable tables (when allowReorder is false) **/

export function DataTable<TRow extends DataTableRow = any>({
  rows: allRows,
  columns: allColumns,
  onOrderChange,
  allowReorder,
  defaultSortState,
  onSortChange,
  renderRow,
  sxRowFunction,
  sx,
  isLoading,
  tableMaxHeight,
  reachedLastRow,
  TableEmptyStateComponent,
  onRowClick,
  actionableButtonsOnHover = false,
  TableFooter,
  variant = DataTableVariant.default,
  containerRef,
  stickyHeader,
}: DataTableProps<TRow>) {
  const [isDragging, setIsDragging] = useState(false);
  const columnsByField = keyBy(allColumns, 'field');
  const [sortState, setSortState] = useState(defaultSortState);

  const columns = allColumns.filter((column) => !column.hidden);

  let rows = allRows;

  if (sortState && columnsByField[sortState.sortBy]?.sortCompare) {
    const orderModifier = sortState.sortOrder === SortOrder.ASC ? 1 : -1;
    const sortCompare = columnsByField[sortState.sortBy]?.sortCompare;

    switch (sortCompare?.type) {
      case SortCompareTypes.String:
        rows = rows.sort((a, b) => {
          const aVal = a[sortState.sortBy];
          const bVal = b[sortState.sortBy];
          return orderModifier * aVal.localeCompare(bVal);
        });
        break;

      case SortCompareTypes.Number:
        rows = rows.sort((a, b) => {
          const aVal = a[sortState.sortBy];
          const bVal = b[sortState.sortBy];
          return orderModifier * (aVal - bVal);
        });
        break;

      case SortCompareTypes.Custom:
        if (sortCompare.compareFunction) {
          rows = rows.sort((a, b) => {
            const aRow = a;
            const bRow = b;
            return sortCompare.compareFunction(aRow, bRow, sortState.sortOrder);
          });
        }
        break;

      default:
        break;
    }
  }

  const sort = useCallback(
    (field: string) => {
      setSortState((prevState) => {
        const order = prevState?.sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
        onSortChange &&
          onSortChange({
            sortBy: field,
            sortOrder: order,
          });

        return {
          sortBy: field,
          sortOrder: order,
        };
      });
    },
    [onSortChange],
  );

  const onDragEnd = (result: DropResult) => {
    setIsDragging(false);
    const { destination, source } = result;
    onOrderChange &&
      destination &&
      onOrderChange({
        sourceIndex: source.index,
        destinationIndex: destination.index,
      });
  };

  const onBeforeDragStart = () => {
    setIsDragging(true);
  };

  return (
    <Box
      sx={{
        maxHeight: tableMaxHeight ?? '100%',
        height: '100%',
        overflow: isLoading ? 'hidden' : 'auto',
        position: 'relative',
      }}
      data-testid="data-table"
      ref={containerRef}
    >
      <DragDropContext onDragEnd={onDragEnd} onBeforeDragStart={onBeforeDragStart}>
        <TableContainer sx={{ ...sx, overflow: 'unset', height: '100%' }}>
          <StyledTable>
            <StyledTableHead data-testid="data-table-head">
              <TableHeadRow
                stickyHeader={stickyHeader}
                columns={columns}
                sort={sort}
                sortState={sortState}
                variant={variant}
              />
            </StyledTableHead>

            <Droppable droppableId="droppable">
              {(provided) => (
                <StyledTableBody
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  sx={{
                    visibility: isLoading ? 'hidden' : 'visible',
                    position: 'relative',
                  }}
                >
                  <TableBodyRows
                    provided={provided}
                    rows={rows}
                    columns={columns}
                    TableEmptyStateComponent={TableEmptyStateComponent}
                    reachedLastRow={reachedLastRow}
                    containerRef={containerRef}
                    allowReorder={allowReorder}
                    renderRow={renderRow}
                    isDragging={isDragging}
                    sxRowFunction={sxRowFunction}
                    onRowClick={onRowClick}
                    actionableButtonsOnHover={actionableButtonsOnHover}
                    variant={variant}
                  />
                </StyledTableBody>
              )}
            </Droppable>
            {TableFooter}
          </StyledTable>
        </TableContainer>
      </DragDropContext>

      {isLoading && <TableLoader />}
    </Box>
  );
}
