import { Button } from '@sweep-io/sweep-design';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTerritoriesActions } from '../../../hooks/useTerritoriesActions';
import { useMemo, useState } from 'react';
import RestrictedTooltip from '../../common/permissions/RestrictedTooltip';
import usePermission from '../../common/permissions/usePermission';
import TerritoriesDialog from './TerritoriesDialog';
import { selectTerritoriesList } from '../../../reducers/territoriesReducer';
import { SweepSort } from '../../common/SweepSort';
import { Permission } from '@server/permissions';
import { useRunOnceWhenTruthy } from '../../common/useRunOnceWhenTruthy';
import TerritoryCard from './TerritoryCard';
import EmptyStateWithButton from '../../common/empty-state/EmptyStateWithButton';
import EarthImage from '../../common/empty-state/svgs/earth.svg?react';

const TerritoriesPageContent = () => {
  const territories = useSelector(selectTerritoriesList);
  const { createTerritory, updateTerritory, deleteTerritory } = useTerritoriesActions();
  const [territoriesDialogOpen, setTerritoriesDialogOpen] = useState(false);
  const permissionStr: Permission[] = [
    'create:territories',
    'edit:territories',
    'delete:territories',
  ];
  const [isAllowedBtnCreate, isAllowedBtnEdit, isAllowedBtnDelete] = usePermission(permissionStr);
  const [editableObject, setEditableObject] = useState<Partial<Territories>>();
  const [sortBy, setSortBy] = useState<string>('TERRITORY_NAME');

  const setEditAndOpenDialog = (territory?: Partial<Territories>) => {
    setEditableObject(territory);
    setTerritoriesDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setTerritoriesDialogOpen(false);
  };

  const handleUpdateObject = async (_territory: Territories, isEdit: boolean) => {
    isEdit ? await updateTerritory(_territory.id, _territory) : await createTerritory(_territory);
    setTerritoriesDialogOpen(false);
  };

  const handleDeleteObject = async (toDelete: Territories) => {
    await deleteTerritory(toDelete.id);
  };

  const territoriesToDisplay = useMemo(() => {
    if (territories) {
      const orderedAutomations = [...territories];

      orderedAutomations?.sort((territoryA, territoryB) => {
        switch (sortBy) {
          case 'TERRITORY_NAME': {
            return territoryA.name.localeCompare(territoryB.name);
          }
          case 'DATE_MODIFIED': {
            return (territoryB.updatedAt || '').localeCompare(territoryA.updatedAt || '');
          }
        }
        return 0;
      });
      return orderedAutomations;
    }
  }, [territories, sortBy]);

  return (
    <Box sx={{ position: 'relative' }}>
      {territoriesToDisplay?.length === 0 && (
        <Box pt="6vh">
          <EmptyStateWithButton
            image={<EarthImage />}
            line1="Optimize and create new assignment Territories"
            notAllowedTitle="To create a new territory, please contact your admin."
            permissionString={[permissionStr[0]]}
            onClick={() => setEditAndOpenDialog()}
          />
        </Box>
      )}
      {territoriesToDisplay?.length !== 0 && (
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              position: 'absolute',
              top: '-56px',
              right: '48px',
            }}
          >
            <Box sx={{ display: 'flex', gap: '16px' }}>
              <SweepSort
                selectedValue={sortBy}
                onChange={setSortBy}
                options={[
                  {
                    label: 'Territory name',
                    value: 'TERRITORY_NAME',
                  },
                  {
                    label: 'Date modified',
                    value: 'DATE_MODIFIED',
                  },
                ]}
              />
              <Box>
                <RestrictedTooltip
                  to={permissionStr}
                  notAllowedTitle={`To create a new territory, please contact your admin.`}
                >
                  <Button
                    onClick={() => setEditAndOpenDialog()}
                    startIconName="Plus"
                    variant="filled"
                    disabled={!isAllowedBtnCreate}
                  >
                    New territory
                  </Button>
                </RestrictedTooltip>
              </Box>
            </Box>
          </Box>
          <Box>
            {territoriesToDisplay?.map((territory: Territories) => {
              return (
                <TerritoryCard
                  key={territory.id}
                  territory={territory}
                  isAllowedBtnEdit={isAllowedBtnEdit}
                  isAllowedBtnDelete={isAllowedBtnDelete}
                  onEdit={() => setEditAndOpenDialog(territory)}
                  onDuplicate={() => {
                    const newItem = {
                      ...territory,
                      name: 'Copy of ' + territory.name,
                      id: undefined,
                    };
                    setEditAndOpenDialog(newItem);
                  }}
                  onDelete={() => {
                    handleDeleteObject(territory);
                  }}
                />
              );
            })}
          </Box>
        </>
      )}
      {territoriesDialogOpen && (
        <TerritoriesDialog
          isOpen={territoriesDialogOpen}
          onBackButtonClick={handleCloseDialog}
          initialTerritory={editableObject}
          onSave={handleUpdateObject}
          isAllowedBtn={isAllowedBtnEdit}
          readonly={!isAllowedBtnCreate || !isAllowedBtnEdit}
        />
      )}
    </Box>
  );
};

const TerritoriesPage = () => {
  const territories = useSelector(selectTerritoriesList);
  const { getTerritories } = useTerritoriesActions();
  useRunOnceWhenTruthy(async () => {
    await getTerritories();
  }, !!territories);

  return <TerritoriesPageContent />;
};

export default TerritoriesPage;
