import StartTrial from './StartTrial';
import { ReactNode } from 'react';
import { PaywallSource } from '../header/usePaywall';
import { TRIAL_TEXT_PRE } from './paywallConsts';

const PaywallBlockStartTrial = ({
  title,
  source,
  onFinishCb,
  onConfirmCb,
  featureDescription,
}: {
  title: string | ReactNode;
  featureDescription: string;
  source: PaywallSource;
  onConfirmCb?: () => void;
  onFinishCb?: () => void;
}) => {
  return (
    <StartTrial
      title={title}
      source={source}
      text={`${TRIAL_TEXT_PRE} ${featureDescription}.`}
      onFinishCb={onFinishCb}
      onConfirmCb={onConfirmCb}
      isDialogLayout={false}
    />
  );
};

export default PaywallBlockStartTrial;
