import { useMemo, useState } from 'react';
import { HoveredPin, documentationPanelType } from '../../../../documentation/types';
import { PanelConsumer } from '../../../../panels/PanelsContext';
import { useConfigurationCanvas } from '../../../configuration-canvas/public/useConfigurationCanvas';

import { DocumentationDialog } from '../../../../documentation/DocumentationDialog';
import { ConnectedNewFunnelDialog } from '../../../funnel-map-flow/new-funnel-dialog/NewFunnelDialog';
import { FunnelMapPluginPanels } from '../../../funnel-map-flow/templates/funnel-map-plugins/FunnelMapPluginPanels';
import { CanvasUnitedTemplatesDialog } from './CanvasUnitedTemplatesDialog';
import { automationsPanelType, playbookAlertsPanelType } from '../../../../Automations/panelTypes';
import { ConfigurationCanvasAutomationsPanel } from '../../CanvasAutomationsPanel';
import { useSelector } from 'react-redux';
import { selectCrmOrgAlerts } from '../../../../../reducers/global/alertsReducers';
import { selectCrmOrgAutomations } from '../../../../../reducers/global/automationReducers';
import { importFunnelPanelType } from '../../../funnel-map-flow/dialogs/import-funnel/importFunnelPanelType';
import { ImportFunnelWrapper } from '../../../funnel-map-flow/dialogs/import-funnel/ImportFunnelWrapper';
import {
  selectStageDialogStage,
  selectStageDialogFunnelId,
} from '../../../../../reducers/funnel-flow-page/stageDialogReducer';
import { stagePanelType } from '../../../funnel-map-flow/dialogs/stage/panelType';
import { StageDialog } from '../../../funnel-map-flow/dialogs/stage/StageDialog';
import ConnectedFunnelDialog from '../../../funnel-map-flow/dialogs/funnel-panel/ConnectedFunnelDialog';
import { funnelPanelType } from '../../../funnel-map-flow/dialogs/funnel-panel/funnelPanelTypes';
import { ConnectedGateDialog } from '../../../funnel-map-flow/dialogs/gates/ConnectedGateDialog';
import { gatePanelType } from '../../../funnel-map-flow/dialogs/gates/gatePanelType';
import FieldsManagementDialog from '../../../funnel-map-flow/dialogs/fields-management/FieldsManagementDialog';
import { selectIsFieldsDialogOpen } from '../../../../../reducers/funnel-flow-page/fieldsReducer';
import { automationsInFunnelMapScope } from '../../../../Automations/automationsInFunnelDataScope';

import { ConfigurationCanvasLeftPanel } from '../../../configuration-canvas-panel';
import { WithEnvConnectionErrorPanel } from './error-panel/EnvConnectionErrorPanel';
import { getFunnelsStepNames } from '../../../../documentation/helpers';
import { selectSingleObject } from '../../../../../reducers/documentationReducer';
import { useDocumentation } from '../../../../documentation/useDocumentation';
import { getRecordTypeNames } from '../../../../documentation/selected-object/utils';
import { AutomationsContextProvider } from '../../../../Automations/AutomationsContext';
import { alertsAutomationContextData } from '../../../../alerts/Alerts';
import { automationsAutomationContextData } from '../../CanvasAutomationPage';

const EMPTY_OBJECT = {};

export const CanvasUnitedFlow = () => {
  const { canvasFunnelMap, canvasCrmOrgId } = useConfigurationCanvas();

  const [, setHoveredPin] = useState<HoveredPin>();

  const { onCrmOrgIdChange } = useDocumentation();
  const automations = useSelector(selectCrmOrgAutomations(canvasCrmOrgId));
  const alerts = useSelector(selectCrmOrgAlerts(canvasCrmOrgId));
  const selectedStage = useSelector(selectStageDialogStage);
  const selectedStageDialogFunnelId = useSelector(selectStageDialogFunnelId);
  const isFieldsManagementDialogOpen = useSelector(selectIsFieldsDialogOpen);
  const activeTab = useSelector(selectSingleObject);

  const filteredAutomations = useMemo(
    () =>
      automationsInFunnelMapScope({
        automations,
        funnelsData: canvasFunnelMap.funnelsData,
      }),
    [automations, canvasFunnelMap.funnelsData],
  );
  const filteredAlerts = useMemo(
    () =>
      automationsInFunnelMapScope({
        automations: alerts,
        funnelsData: canvasFunnelMap.funnelsData,
      }),
    [alerts, canvasFunnelMap.funnelsData],
  );

  const stepNames =
    getFunnelsStepNames({
      funnelsData: canvasFunnelMap.funnelsData,
      recordTypesData: canvasFunnelMap.recordTypesData,
    })[activeTab?.objectType ?? ''] ?? [];

  const currentRecordTypesByObjectName = getRecordTypeNames({
    funnelsData: canvasFunnelMap.funnelsData,
    recordTypesData: canvasFunnelMap.recordTypesData,
  });

  return (
    <>
      <PanelConsumer panelType={documentationPanelType}>
        {({ isOpen, maybeClosePanel }) => (
          <>
            {isOpen && (
              <ConfigurationCanvasLeftPanel withExpandedModeButton>
                <WithEnvConnectionErrorPanel>
                  <DocumentationDialog
                    recordTypeNamesUsedInCanvas={currentRecordTypesByObjectName}
                    crmOrgId={canvasCrmOrgId}
                    isOpen
                    onCrmOrgIdChange={onCrmOrgIdChange}
                    setHoveredPin={setHoveredPin}
                    funnelsData={canvasFunnelMap.funnelsData}
                    recordTypesData={canvasFunnelMap.recordTypesData ?? EMPTY_OBJECT}
                    onCloseDialog={() => maybeClosePanel()}
                    hideAddObjectButton
                    stepNamesUsedInCanvas={stepNames}
                  />
                </WithEnvConnectionErrorPanel>
              </ConfigurationCanvasLeftPanel>
            )}
          </>
        )}
      </PanelConsumer>
      <CanvasUnitedTemplatesDialog />
      <FunnelMapPluginPanels isReadOnly={false} />
      <ConnectedNewFunnelDialog hideUseFieldsFrom />

      <PanelConsumer panelType={automationsPanelType}>
        {({ isOpen, maybeClosePanel }) => (
          <>
            {isOpen && (
              <AutomationsContextProvider value={automationsAutomationContextData}>
                <ConfigurationCanvasAutomationsPanel
                  automations={filteredAutomations}
                  onClose={maybeClosePanel}
                  disableCanvasTemplates
                />
              </AutomationsContextProvider>
            )}
          </>
        )}
      </PanelConsumer>
      <PanelConsumer panelType={playbookAlertsPanelType}>
        {({ isOpen, maybeClosePanel }) => (
          <>
            {isOpen && (
              <AutomationsContextProvider value={alertsAutomationContextData}>
                <ConfigurationCanvasAutomationsPanel
                  automations={filteredAlerts}
                  onClose={maybeClosePanel}
                  disableCanvasTemplates
                />
              </AutomationsContextProvider>
            )}
          </>
        )}
      </PanelConsumer>
      <PanelConsumer panelType={importFunnelPanelType}>
        {({ isOpen, maybeClosePanel }) =>
          isOpen && (
            <ImportFunnelWrapper onClose={() => maybeClosePanel()} crmOrgId={canvasCrmOrgId} />
          )
        }
      </PanelConsumer>
      {selectedStage && selectedStageDialogFunnelId && (
        <PanelConsumer panelType={stagePanelType} instanceId={selectedStage._stageId}>
          {({ maybeClosePanel, isOpen, setIsDirty }) => (
            <>
              {isOpen && (
                <StageDialog
                  readonly={false}
                  stage={selectedStage}
                  funnelId={selectedStageDialogFunnelId}
                  crmOrgId={canvasCrmOrgId}
                  closeDialog={() => maybeClosePanel()}
                  setIsDirty={setIsDirty}
                />
              )}
            </>
          )}
        </PanelConsumer>
      )}
      <PanelConsumer panelType={funnelPanelType}>
        {({ maybeClosePanel, isOpen, data }) => (
          <>
            {data && (
              <ConnectedFunnelDialog
                closeDialog={() => maybeClosePanel()}
                isOpen={isOpen}
                readonly={false}
                data={data}
                crmOrgId={canvasCrmOrgId}
                funnelMapId={canvasFunnelMap.id}
              />
            )}
          </>
        )}
      </PanelConsumer>
      <PanelConsumer panelType={gatePanelType}>
        {({ maybeClosePanel, isOpen, setIsDirty, data }) => (
          <>
            {data && (
              <ConnectedGateDialog
                crmOrgId={canvasCrmOrgId}
                readonly={false}
                closeDialog={() => maybeClosePanel()}
                setIsDirty={setIsDirty}
                isOpen={isOpen}
                data={data}
              />
            )}
          </>
        )}
      </PanelConsumer>
      {isFieldsManagementDialogOpen && (
        <FieldsManagementDialog crmOrgId={canvasCrmOrgId} funnelMapId={canvasFunnelMap.id} />
      )}
    </>
  );
};
