import { MenuItem, SelectChangeEvent } from '@mui/material';
import { useState, useCallback } from 'react';
import { useCrmOrgs } from '../pages/environments/useCrmOrgs';
import SweepSelect from '../common/SweepSelect';
import { telemetry } from '../../telemetry';

interface ChildRelationshipSelectorProps {
  ChildRelationshipObject?: ChildRelationshipObject;
  objectName: string;
  crmOrgId: string;
  onChange: (value?: ChildRelationshipObject) => any;
  readonly?: boolean;
  tempValueForDemo?: string;
}

export const ChildRelationshipSelector = ({
  ChildRelationshipObject,
  crmOrgId,
  objectName,
  onChange,
  readonly,
  tempValueForDemo = '',
}: ChildRelationshipSelectorProps) => {
  const [crmOrgChildRelationshipNames, setCrmOrgChildRelationshipNames] = useState<
    ChildRelationshipObject[]
  >([]);

  const { fetchChildRelationshipNames } = useCrmOrgs();

  const [isLoading, setIsLoading] = useState(false);

  const onOpen = useCallback(async () => {
    if (!isLoading) {
      setIsLoading(true);
      try {
        const { childRelationships } = await fetchChildRelationshipNames(crmOrgId, objectName);
        setCrmOrgChildRelationshipNames(childRelationships);
      } catch (error) {
        telemetry.captureError(error);
      }
      setIsLoading(false);
    }
  }, [crmOrgId, fetchChildRelationshipNames, isLoading, objectName]);

  // Removes duplicates
  const noDuplicatesChildRelationshipNames = crmOrgChildRelationshipNames.filter(
    (item, index, items) => items.findIndex((i) => i.objectType === item.objectType) === index,
  );

  return (
    <SweepSelect
      SelectProps={{
        disabled: readonly,
        placeholder: 'Choose a Record',
        defaultValue: '',
        value: ChildRelationshipObject?.childRelationshipName ?? tempValueForDemo,
        onChange: (event: SelectChangeEvent<unknown>) => {
          const val = event.target.value as string;

          const output = crmOrgChildRelationshipNames.find((d) => d.childRelationshipName === val);
          onChange(output);
        },
        renderValue: (val: any) => {
          const output = crmOrgChildRelationshipNames.find((d) => d.childRelationshipName === val);
          return output ? output?.objectTypeLabel : val;
        },
      }}
      isLoading={isLoading}
      onOpenCb={onOpen}
    >
      {noDuplicatesChildRelationshipNames.map((item) => (
        <MenuItem key={item.relationshipFieldId} value={item.childRelationshipName}>
          {item.objectTypeLabel}
        </MenuItem>
      ))}
    </SweepSelect>
  );
};
