import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectParsedObjectNames } from '../../reducers/parserReducer';
import { useLayouts } from './useLayouts';
import { selectLayoutsByObjectName } from '../../reducers/documentationReducer';
import { useConfigurationCanvas } from '../pages/configuration-canvas/public/useConfigurationCanvas';

/**
 * Long term there's an idea to put this info as part of parser response
 * (not that trivial because of performance - buttons and related lists are derived directly from SF)
 *
 * Short term solution: fetch full layouts info by objects that are used in the canvas, in the background
 * so user won't face long loading time
 *
 * NOTE: This scenario doesn't apply to search and objects outside favorites section
 */

export const useInitializeLayouts = () => {
  const { fetchLayoutsData } = useLayouts();
  const { canvasCrmOrgId: crmOrgId } = useConfigurationCanvas();
  const crmOrgParsedObjects = useSelector(selectParsedObjectNames);
  const layoutsByObjectName = useSelector(selectLayoutsByObjectName(crmOrgId ?? ''));
  const notFetchedObjects = useMemo(() => {
    const result: string[] = [];
    crmOrgParsedObjects.forEach((objectName) => {
      const { isLoading, layouts } = layoutsByObjectName?.[objectName] ?? {};

      if (!isLoading && !layouts) {
        result.push(objectName);
      }
    });

    return result;
  }, [crmOrgParsedObjects, layoutsByObjectName]);

  //preload layouts information - it can take a while
  useEffect(() => {
    if (crmOrgId && notFetchedObjects.length > 0) {
      (async () =>
        await Promise.all([
          notFetchedObjects.map((objectName) => {
            return fetchLayoutsData({ crmOrgId, objectName });
          }),
        ]))();
    }
  }, [fetchLayoutsData, notFetchedObjects, crmOrgId]);
};
