import { useSweepApi } from '../sweep';
import { useCallback } from 'react';

export interface Campaign extends CampaignBase {
  Id: string;
}
export interface CampaignBase {
  Name: string;
  ParentId?: string;
  Type?: string;
  Status?: string;
  StartDate?: string;
  EndDate?: string;
  ExpectedRevenue?: number;
  BudgetedCost?: number;
  ActualCost?: number;
  ExpectedResponse?: string;
  IsActive?: boolean;
  NumberSent?: number;
  Description?: string;
}

export const useCampaignsApiFacade = () => {
  const sweepApi = useSweepApi();
  return {
    get_campaigns: useCallback(async (): Promise<Campaign[]> => {
      const response = await sweepApi.get('/campaigns');
      return response.data;
    }, [sweepApi]),
    create_campaign: useCallback(
      async (campaign: CampaignBase): Promise<Campaign> => {
        const response = await sweepApi.post('/campaigns', campaign);
        return response.data;
      },
      [sweepApi],
    ),
  };
};
