import { Card, CardHeader, Box, CardContent } from '@mui/material';
import { Typography, colors } from '@sweep-io/sweep-design';
import { Users as UsersIcon, Calendar as CalendarIcon } from '@sweep-io/sweep-design/dist/icons';
import { AvatarPicture } from '../../../avatar/AvatarPicture';
import { AvatarType } from '../../../avatar/avatarTypes';
import { ActionsMenu } from '../../../common/actions-menu/ActionsMenu';
import { TruncatedTextTooltip } from '../../../common/TruncatedTextTooltip';
import usePermission from '../../../common/permissions/usePermission';

enum AssignmentActionsEnum {
  DELETE = 'DELETE',
  DUPLICATE = 'DUPLICATE',
}

export const ASSIGNMENTS_CARD_HEIGHT = '124px';

const AssignmentDetailsRow = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box display="flex">
      <Box
        width="auto"
        display="flex"
        gap="4px"
        alignItems="center"
        sx={{
          background: colors.grey[100],
          color: colors.night[900],
          padding: '4px 8px',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

interface AssignmentCardProps {
  name: string;
  avatar?: AvatarType;
  description: string;
  activeMembers: number;
  lastModified: string;
  onClick: () => any;
  onDuplicate: () => any;
  onDelete: () => any;
  readonly?: boolean;
}

export const GroupCard = ({
  name,
  description,
  activeMembers,
  lastModified,
  avatar,
  onClick,
  onDuplicate,
  onDelete,
  readonly,
}: AssignmentCardProps) => {
  const [isDeleteAllowed] = usePermission(['delete:assignment-groups']);
  return (
    <Card
      onClick={onClick}
      sx={{
        '& .MuiCardHeader-root, .MuiCardContent-root': {
          padding: 2,
        },
        '& .MuiCardContent-root': { paddingTop: 0, '&:last-child': { paddingBottom: 2 } },
        minWidth: '400px',
        boxShadow: 'none',
      }}
    >
      <CardHeader
        avatar={
          <Box sx={{ width: '40px', height: '40x' }}>
            <AvatarPicture dimension={40} avatar={avatar} isInactive={false} />
          </Box>
        }
        title={<TruncatedTextTooltip variant="h3">{name}</TruncatedTextTooltip>}
        subheader={
          <Typography variant="body" color={colors.grey[800]}>
            {description}
          </Typography>
        }
        action={
          <ActionsMenu // TODO: Change the Actions Menu to reflect the correct design?
            actions={[
              { label: 'Duplicate', value: AssignmentActionsEnum.DUPLICATE, disabled: readonly },
              {
                label: 'Delete',
                value: AssignmentActionsEnum.DELETE,
                disabled: readonly || !isDeleteAllowed,
              },
            ]}
            onClick={(action) => {
              switch (action.value) {
                case AssignmentActionsEnum.DUPLICATE:
                  onDuplicate();
                  break;
                case AssignmentActionsEnum.DELETE:
                  onDelete();
                  break;
              }
            }}
            iconTooltip={
              readonly
                ? 'To make changes to this assignment group, please contact your admin.'
                : undefined
            }
          />
        }
      />
      <CardContent>
        <Box display="flex" flexDirection="row" gap="8px" sx={{ color: colors.night[900] }}>
          <AssignmentDetailsRow>
            <UsersIcon />
            <Typography variant="body">Active members:</Typography>
            <Typography variant="body-bold">{activeMembers}</Typography>
          </AssignmentDetailsRow>
          <AssignmentDetailsRow>
            <CalendarIcon />
            <Typography variant="body">Last Modified:</Typography>
            <Typography variant="body-bold">{lastModified}</Typography>
          </AssignmentDetailsRow>
        </Box>
      </CardContent>
    </Card>
  );
};
