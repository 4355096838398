import { useCallback } from 'react';
import { useRoleGroupApiFacade } from '../../../apis/facades/useRoleGroupApiFacade';
import { useDispatch } from 'react-redux';
import {
  addRoleGroup as _addRoleGroup,
  updateRoleGroup as _updateRoleGroup,
  setIsLoading,
  setRoleGroups,
  deleteRoleGroup as _deleteRoleGroup,
} from '../../../reducers/roleGroupsReducer';
import { telemetry } from '../../../telemetry';
import { RoleGroupInterface } from '@server/role-group-interface';

export const useRoleManager = () => {
  const { get_roleGroups, post_roleGroup, put_roleGroup, delete_roleGroup } =
    useRoleGroupApiFacade();
  const dispatch = useDispatch();

  const fetchRoleGroups = useCallback(async () => {
    dispatch(setIsLoading({ isLoading: true }));

    try {
      const response = await get_roleGroups();
      dispatch(setRoleGroups({ roleGroups: response }));
    } catch (error) {
      dispatch(setIsLoading({ isLoading: false }));
      telemetry.captureError(error);
    }
  }, [get_roleGroups, dispatch]);

  const addRoleGroup = useCallback(
    async (roleGroup: Pick<RoleGroupInterface, 'name' & 'description' & 'roles'>) => {
      try {
        const response = await post_roleGroup(roleGroup);
        dispatch(_addRoleGroup({ roleGroup: response }));
      } catch (error) {
        telemetry.captureError(error);
      }
    },
    [post_roleGroup, dispatch],
  );

  const updateRoleGroup = useCallback(
    async (roleGroup: RoleGroupInterface) => {
      try {
        const response = await put_roleGroup(roleGroup);
        dispatch(_updateRoleGroup({ newRoleGroup: response }));
      } catch (error) {
        telemetry.captureError(error);
      }
    },
    [put_roleGroup, dispatch],
  );

  const saveRoleGroup = useCallback(
    async (roleGroup: RoleGroupInterface) => {
      const updateable = {
        name: roleGroup.name,
        description: roleGroup.description,
        roles: roleGroup.roles,
      } as RoleGroupInterface;

      if (roleGroup.id) {
        await updateRoleGroup({ ...updateable, id: roleGroup.id });
      } else {
        await addRoleGroup(updateable);
      }
    },
    [addRoleGroup, updateRoleGroup],
  );

  const deleteRoleGroup = useCallback(
    async (roleGroupId: string) => {
      try {
        dispatch(_deleteRoleGroup({ roleGroupId }));
        await delete_roleGroup(roleGroupId);
      } catch (error) {
        telemetry.captureError(error);
      }
    },
    [delete_roleGroup, dispatch],
  );

  return {
    fetchRoleGroups,
    saveRoleGroup,
    deleteRoleGroup,
  };
};
