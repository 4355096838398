export enum SweepError {
  ManagedPackageRequired,
  ProductionEnvMissing,
  CrmOrgNotFound,
  CrmOrgBadCredentials,
  CrmOrgLockTimeout, // Deprecated
  UserNotConnected,
  SfdcUserBadCredentials,
}

export type EnvConnectionError = {
  crmOrgId: string,
  error: SweepError.CrmOrgBadCredentials | SweepError.CrmOrgNotFound,
};
