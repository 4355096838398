import { Stack } from '@mui/material';
import { Typography } from '@sweep-io/sweep-design';
import {
  PageTemplateGrid,
  PageTemplateCard,
  PageTemplateActionButton,
  PageTemplateHeaderWrapper,
  getColorForTemplateCard,
} from '../../pages-templates';
import { DedupMatchingType } from '../../../../types/enums/DedupMatchingType';
import { ObjectTypeChip } from '../../../common/ObjectTypeChip';
import { useCallback } from 'react';

export const DedupTemplateContent = ({
  onSelect,
}: {
  onSelect: (object: string, type?: DedupMatchingType) => void;
}) => {
  const tag = (objectType: string) => <ObjectTypeChip label={objectType} objectType={objectType} />;
  const leadTag = tag('Lead');
  const contactTag = tag('Contact');
  const accountTag = tag('Account');

  const onSelectBuilder = useCallback(
    (object: string, type?: DedupMatchingType) => () => onSelect(object, type),
    [onSelect],
  );

  return (
    <Stack gap={4}>
      <Stack gap={2}>
        <Typography variant="body-medium">Deduplication</Typography>
        <PageTemplateGrid>
          <PageTemplateCard
            stripeColor1={getColorForTemplateCard('Lead')}
            onCardClick={onSelectBuilder('Lead', DedupMatchingType.LEAD_TO_LEAD_DEDUP)}
            actionsContent={
              <PageTemplateActionButton
                onClick={onSelectBuilder('Lead', DedupMatchingType.LEAD_TO_LEAD_DEDUP)}
              >
                Set up
              </PageTemplateActionButton>
            }
            description="Lead to lead deduplication"
            headerContent={<PageTemplateHeaderWrapper firstItem={leadTag} secondItem={leadTag} />}
          />
          <PageTemplateCard
            stripeColor1={getColorForTemplateCard('Contact')}
            onCardClick={onSelectBuilder('Contact', DedupMatchingType.CONTACT_TO_CONTACT_DEDUP)}
            actionsContent={
              <PageTemplateActionButton
                onClick={onSelectBuilder('Contact', DedupMatchingType.CONTACT_TO_CONTACT_DEDUP)}
              >
                Set up
              </PageTemplateActionButton>
            }
            description="Contact to contact deduplication"
            headerContent={
              <PageTemplateHeaderWrapper firstItem={contactTag} secondItem={contactTag} />
            }
          />
          <PageTemplateCard
            stripeColor1={getColorForTemplateCard('Account')}
            onCardClick={onSelectBuilder('Account', DedupMatchingType.ACCOUNT_TO_ACCOUNT_DEDUP)}
            actionsContent={
              <PageTemplateActionButton
                onClick={onSelectBuilder('Account', DedupMatchingType.ACCOUNT_TO_ACCOUNT_DEDUP)}
              >
                Set up
              </PageTemplateActionButton>
            }
            description="Account to account deduplication"
            headerContent={
              <PageTemplateHeaderWrapper firstItem={accountTag} secondItem={accountTag} />
            }
          />
          <PageTemplateCard
            stripeColor1={getColorForTemplateCard('Lead')}
            stripeColor2={getColorForTemplateCard('Contact')}
            onCardClick={onSelectBuilder('Lead', DedupMatchingType.LEAD_TO_CONTACT_DEDUP)}
            actionsContent={
              <PageTemplateActionButton
                onClick={onSelectBuilder('Lead', DedupMatchingType.LEAD_TO_CONTACT_DEDUP)}
              >
                Set up
              </PageTemplateActionButton>
            }
            description="Lead to contact deduplication"
            headerContent={
              <PageTemplateHeaderWrapper firstItem={leadTag} secondItem={contactTag} />
            }
          />
        </PageTemplateGrid>
      </Stack>
      <Stack gap={2}>
        <Typography variant="body-medium">Matching</Typography>
        <PageTemplateGrid>
          <PageTemplateCard
            stripeColor1={getColorForTemplateCard('Lead')}
            onCardClick={onSelectBuilder('Lead', DedupMatchingType.LEAD_TO_LEAD_MATCHING)}
            actionsContent={
              <PageTemplateActionButton
                onClick={onSelectBuilder('Lead', DedupMatchingType.LEAD_TO_LEAD_MATCHING)}
              >
                Set up
              </PageTemplateActionButton>
            }
            description="Lead to lead matching"
            headerContent={<PageTemplateHeaderWrapper firstItem={leadTag} secondItem={leadTag} />}
          />
          <PageTemplateCard
            stripeColor1={getColorForTemplateCard('Lead')}
            stripeColor2={getColorForTemplateCard('Account')}
            onCardClick={onSelectBuilder('Lead', DedupMatchingType.LEAD_TO_ACCOUNT_MATCHING)}
            actionsContent={
              <PageTemplateActionButton
                onClick={onSelectBuilder('Lead', DedupMatchingType.LEAD_TO_ACCOUNT_MATCHING)}
              >
                Set up
              </PageTemplateActionButton>
            }
            description="Lead to account matching"
            headerContent={
              <PageTemplateHeaderWrapper firstItem={leadTag} secondItem={accountTag} />
            }
          />
        </PageTemplateGrid>
      </Stack>
    </Stack>
  );
};
