import { Box } from '@mui/material';
import { IconButton } from '@sweep-io/sweep-design';
import { ChevronRight } from '@sweep-io/sweep-design/dist/icons';

export const ChevronButton = () => (
  <Box
    sx={{
      '& > .MuiButton-root': {
        width: '15px',
        height: '20px',
        margin: '-5px',
      },
    }}
  >
    <IconButton variant="link" size="small">
      <ChevronRight variant="default" />
    </IconButton>
  </Box>
);
