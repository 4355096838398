import { IconButton, Typography, colors } from '@sweep-io/sweep-design';
import { ChevronLeft, ViewInSalesforce } from '@sweep-io/sweep-design/dist/icons';
import { Box } from '@mui/material';
import isNil from 'lodash/isNil';
import { ConfigurationType, ConfigurationTypesWithIcon } from './types';
import RuleChips from '../RuleChips';
import StyledTooltip from '../../common/StyledTooltip';
import { DotDivider } from '../../common/DotDivider';
import { useDependencies } from './useDependencies';
import { getChips } from './helpers';
import { configurationItemTypeToIcon } from './latest-deployed/templates/organisms/helpers/icons';
import useObjectTypesWithFetch from '../../../hooks/useObjectTypesWithFetch';
import { InitialsAvatar } from '../InitialsAvatar';

interface DependenciesHeaderProps {
  configurationItem: ConfigurationItem;
  type: ConfigurationType;
  displayItemType: string;
  crmOrgId: string;
  historyLength?: number;
  stepNamesUsedInCanvas: string[];
}

export const DependenciesHeader = ({
  configurationItem,
  type,
  crmOrgId,
  historyLength,
  stepNamesUsedInCanvas,
  displayItemType,
}: DependenciesHeaderProps) => {
  const { objectTypesByName } = useObjectTypesWithFetch({ crmOrgId, useSfObjects: true });
  const { chips, RTchips } = getChips(
    configurationItem,
    stepNamesUsedInCanvas,
    objectTypesByName,
    type,
  );
  const { onGoBackInHistory } = useDependencies(crmOrgId, {});

  const isField = type === ConfigurationType.fields || type === ConfigurationType.rollups;
  const { label, link, annotations, usage } = configurationItem ?? {};
  const { changedBy, changedAt } = annotations ?? {};

  return (
    <Box
      display="flex"
      borderBottom={`1px solid${colors.grey[200]}`}
      p="16px 20px"
      gap={1}
      alignItems="center"
    >
      {historyLength && historyLength > 1 && (
        <IconButton variant="flat" size="tiny" onClick={onGoBackInHistory}>
          <ChevronLeft />
        </IconButton>
      )}

      <Box flex={1} display="flex" justifyContent="space-between" alignItems="flex-start">
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="h3" whiteSpace="break-spaces" wordBreak="break-word">
            {label}
          </Typography>

          <Box
            display="flex"
            flexDirection="row"
            gap={0.5}
            alignItems="center"
            sx={{ svg: { path: { stroke: colors.grey[700] } } }}
          >
            {type && (
              <>
                {configurationItemTypeToIcon[type as ConfigurationTypesWithIcon]}
                <Typography variant="caption" color={colors.grey[700]}>
                  {displayItemType}
                </Typography>
              </>
            )}

            {!!chips?.length && (
              <>
                <DotDivider />
                <RuleChips
                  chips={chips}
                  differentColorChips={RTchips}
                  differentColor={colors.grey[200]}
                />
              </>
            )}

            {isField && !isNil(usage) && !isNaN(usage) && (
              <Box display="flex" alignItems="center" gap={0.5}>
                <DotDivider />
                <Typography variant="caption" color={colors.grey[700]}>
                  {usage}% usage
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        <Box display="flex" gap={1} alignItems="center" ml={1}>
          {link && (
            <StyledTooltip placement="top" title="Open in Salesforce">
              <IconButton
                variant="outlined"
                size={'tiny'}
                onClick={() => window.open(link, '_blank')}
              >
                <ViewInSalesforce />
              </IconButton>
            </StyledTooltip>
          )}
          {changedBy && <InitialsAvatar changedAt={changedAt} userIdOrName={changedBy} />}
        </Box>
      </Box>
    </Box>
  );
};
