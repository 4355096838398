import { FetchStatus } from '@server/fetch_status.type';
import { ConnectCrmOrgState } from '../../environments/ConnectCrmOrgStateEnum';
import useConnectToSf, { GetEventToSend } from './useConnectToSf';
import { Box } from '@mui/material';
import SalesforceCloudWithText from './svgs/salesforceCloudWithText.svg?react';
import SalesforceCloudEmpty from './svgs/salesforceCloudEmpty.svg?react';
import SalesforceCloudStroke from './svgs/salesforceCloudStroke.svg?react';
import ConnectStartInDialog from './ConnectStartInDialog';
import ConnectProgressInDialog from './ConnectProgressInDialog';
import { CenteredCircularProgress } from '../../../common/CenteredCircularProgress';

const ConnectToSfInDialog = ({
  onFinish,
  forceProduction,
  getEventToSend,
}: {
  onFinish: () => void;
  forceProduction: boolean;
  getEventToSend?: GetEventToSend;
}) => {
  const { connectState, isSandbox, setIsSandboxCb, onConnectStartHandler } = useConnectToSf({
    getEventToSend,
    onFinish,
    forceProduction,
  });

  const isError = [ConnectCrmOrgState.ErrorConnecting, FetchStatus.Error].includes(connectState);
  const isNotConnected = connectState === ConnectCrmOrgState.NotConnected;
  const renderConnect = isError || isNotConnected;
  const isInProgress = [
    ConnectCrmOrgState.Connecting,
    //In the following statuses, the entire component is not supposed to be rendered
    //Until removed from dom, rendering "ConnectProgress" for smooth experience
    FetchStatus.Fetched,
    FetchStatus.InitialFetching,
    FetchStatus.Fetching,
  ].includes(connectState);

  return (
    <Box display="flex" height="100%" flex={1}>
      <Box
        sx={{
          flex: '0 0 296px',
          backgroundColor: '#00A4E6',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {isNotConnected && <SalesforceCloudWithText />}
        {isInProgress && <SalesforceCloudWithLoader />}
        {isError && <SalesforceCloudStroke />}
      </Box>
      {renderConnect && (
        <ConnectStartInDialog
          onConnectStart={() =>
            onConnectStartHandler({ isSandboxValue: forceProduction ? false : isSandbox })
          }
          forceProduction={forceProduction}
          setIsSandbox={setIsSandboxCb}
          isSandbox={isSandbox}
          isErrorState={isError}
        />
      )}
      {isInProgress && <ConnectProgressInDialog />}
    </Box>
  );
};

const SalesforceCloudWithLoader = () => {
  return (
    <Box position="relative">
      <SalesforceCloudEmpty />
      <CenteredCircularProgress
        circularProgressProps={{
          size: '32px',
          sx: {
            position: 'absolute',
            top: 'calc(50% - 16px)',
            right: 'calc(50% - 16px)',
            '.MuiCircularProgress-circle': { color: 'white' },
          },
        }}
      />
    </Box>
  );
};

export default ConnectToSfInDialog;
