import { WalkthroughDto } from './WalkthroughTypes';

export const InitialWalkthrough: WalkthroughDto = {
  documentationExploreConfig: false,
  documentationAi: false,
  documentationUtilizationTooltip: false,
  rollupsEmptyNew: false,
  rollupsEdit: false,
  automationsEmptyNew: false,
  automationsTrigger: false,
  automationsEdit: false,
  alertsEmptyNew: false,
  alertsTrigger: false,
  alertsEdit: false,
  assignmentsEmptyNew: false,
  assignmentsTrigger: false,
  assignmentsEdit: false,
  dedupeEmptyNew: false,
  dedupeEdit: false,
  routingSetupMembers: false,
  invite: false,
  envSelectorConnectProd: false,
  funnelsTemplates: false,
};
