import { RoleGroupInterface, RoleGroupType } from '@server/role-group-interface';
import { SweepRoles } from '../../../hooks/useUserInfo';

export const featureTranslations = {
  automations: 'Automations',
  alerts: 'Alerts',
  dedupeMatching: 'Dedupe & Matching',
  rollups: 'Rollups',
  funnelEditor: 'Funnel Editor',
  assignments: 'Assignments',
};

export const roleTranslations = {
  admin: 'Admin',
  viewer: 'Viewer',
  requestToDeploy: 'Request to deploy',
};

export const sortRoles = (roles: RoleGroupInterface[]) => {
  const systemOrder = [
    SweepRoles.Admin,
    SweepRoles.Collaborator,
    SweepRoles.Alert_Manager,
    SweepRoles.Viewer,
  ];

  return [...roles].sort((a, b) => {
    const aIsCustom = a.type === RoleGroupType.USER_CREATED;
    const bIsCustom = b.type === RoleGroupType.USER_CREATED;

    if (aIsCustom && bIsCustom) {
      return a.name.localeCompare(b.name);
    }

    if (aIsCustom && !bIsCustom) {
      return -1;
    }

    if (!aIsCustom && bIsCustom) {
      return 1;
    }

    if (!aIsCustom && !bIsCustom) {
      return systemOrder.indexOf(a.name as SweepRoles) - systemOrder.indexOf(b.name as SweepRoles);
    }

    return 0;
  });
};
