export enum ActionStatus {
  NOT_COMPLETED = 'NOT_COMPLETED',
  COMPLETED = 'COMPLETED',
}

export enum GetStartedActionIdDeprecated {
  introWatchVideo = 'introWatchVideo',
  documentationTakeTour = 'documentationTakeTour',
  documentationNavigate = 'documentationNavigate',
  adminRollupsNavigate = 'adminRollupsNavigate',
  adminChangeFeedNavigate = 'adminChangeFeedNavigate',
  automationsTakeTour = 'automationsTakeTour',
  automationsNavigate = 'automationsNavigate',
  slackTakeTour = 'slackTakeTour',
  slackNavigate = 'slackNavigate',
  collaborationTakeTour = 'collaborationTakeTour',
  collaborationNavigate = 'collaborationNavigate',
  routingTakeTour = 'routingTakeTour',
  routingSetupNavigate = 'routingSetupNavigate',
  routingAssignmentsNavigate = 'routingAssignmentsNavigate',
  routingDedupeNavigate = 'routingDedupeNavigate',
  templatesTakeTour = 'templatesTakeTour',
  templatesNavigate = 'templatesNavigate',
}

export enum GetStartedActionId {
  documentationNavigate = 'documentationNavigate',
  changeFeedNavigate = 'changeFeedNavigate',
  rollupsNavigate = 'rollupsNavigate',
  assignmentsNavigate = 'assignmentsNavigate',
  automationsNavigate = 'automationsNavigate',
  slackNavigate = 'slackNavigate',
  dedupeNavigate = 'dedupeNavigate',
  visualizeNavigate = 'visualizeNavigate',
  optimizeNavigate = 'optimizeNavigate',
}
