import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useEffect, useState } from 'react';
import { uniqueId } from '../../lib/uniqueId';
import {
  allOperators,
  ApiSweepFieldOperator,
  apiSweepOperatorWithValueToSweepFieldOperator,
  SweepFieldOperator,
  SweepFieldOperatorsValues,
  sweepFieldOperatorToApiSweepOperatorWithValue,
  sweepFieldTypesOperatorsMapping,
} from './sweepFieldsTypesAndOperators';
import SelectNoBorder from '../common/SelectNoBorder';
import { SweepFieldTypes } from '../../types/enums/SweepFieldTypes';
import { FieldContext } from '../../types/enums/FieldContext';

export const RELATIVE_DATE_OPERATORS = [
  ApiSweepFieldOperator.IN_LAST_X,
  ApiSweepFieldOperator.IN_NEXT_X,
  ApiSweepFieldOperator.GREATER_THAN_X,
  ApiSweepFieldOperator.GREATER_THAN_X_AGO,
];

export const TEXTFIELD_FOR_PICKLIST_OPERATORS = [
  ApiSweepFieldOperator.CONTAINS,
  ApiSweepFieldOperator.DOES_NOT_CONTAIN,
  ApiSweepFieldOperator.ENDS_WITH,
  ApiSweepFieldOperator.STARTS_WITH,
];

interface SweepFieldsOperatorSelectorProps {
  fieldType?: SweepFieldTypes;
  operator?: ApiSweepFieldOperator;
  value?: SweepFieldOperatorsValues;
  disabled?: boolean;
  placeholder?: string;
  onChange?: (operator: ApiSweepFieldOperator, value?: SweepFieldOperatorsValues) => any;
  showAny?: boolean;
  removeBorders?: boolean;
  filterOperatorBy?: (
    operatorList: { label: string; operator: SweepFieldOperator }[],
    fieldType?: SweepFieldTypes,
  ) => { label: string; operator: SweepFieldOperator }[];
  fieldContext?: FieldContext;
}

const SweepFieldsOperatorSelector = ({
  fieldType,
  operator,
  disabled,
  onChange,
  value,
  showAny,
  removeBorders = true,
  placeholder,
  filterOperatorBy,
  fieldContext,
}: SweepFieldsOperatorSelectorProps) => {
  const [sweepFieldOperator, setSweepFieldOperator] = useState<SweepFieldOperator | undefined>(
    operator ? apiSweepOperatorWithValueToSweepFieldOperator(operator, value) : undefined,
  );

  useEffect(() => {
    setSweepFieldOperator(
      operator ? apiSweepOperatorWithValueToSweepFieldOperator(operator, value) : undefined,
    );
  }, [operator, value]);

  const onSelectChange = (event: SelectChangeEvent<unknown>) => {
    if (onChange) {
      const sweepFieldValue = event.target.value;
      if (showAny && 'Any' === (sweepFieldValue as any)) {
        return onChange(ApiSweepFieldOperator.ANY, 'true');
      }
      const { apiOperator, value } = sweepFieldOperatorToApiSweepOperatorWithValue(
        sweepFieldValue as SweepFieldOperator,
      );

      onChange(apiOperator, value);
    }
  };

  const alwaysFilterBy = (operatorList: { label: string; operator: SweepFieldOperator }[]) => {
    let filtered = operatorList;
    const listOfOperatorsToRemove = [
      SweepFieldOperator.IS_CHANGED_FALSE,
      SweepFieldOperator.IS_CHANGED_TRUE,
    ];
    const removeHasChangedForTypes = [
      SweepFieldTypes.Summary,
      SweepFieldTypes.FormulaCheckbox,
      SweepFieldTypes.FormulaCurrency,
      SweepFieldTypes.FormulaDate,
      SweepFieldTypes.FormulaDateTime,
      SweepFieldTypes.FormulaNumber,
      SweepFieldTypes.FormulaPercent,
      SweepFieldTypes.FormulaText,
      SweepFieldTypes.FormulaTime,
    ];
    if (fieldType && removeHasChangedForTypes.includes(fieldType)) {
      filtered = operatorList.filter(({ operator }) => {
        return !listOfOperatorsToRemove.includes(operator);
      });
    }
    return filterOperatorBy ? filterOperatorBy(filtered, fieldType) : filtered;
  };

  let operatorsToShow = (fieldType && sweepFieldTypesOperatorsMapping[fieldType]) || allOperators;
  if (fieldContext === FieldContext.PRIOR_VALUE) {
    operatorsToShow = operatorsToShow.filter((operator) => {
      return ![
        SweepFieldOperator.IS_CHANGED_FALSE,
        SweepFieldOperator.IS_CHANGED_TRUE,
        SweepFieldOperator.HAS_INCREASED,
        SweepFieldOperator.HAS_DECREASED,
      ].includes(operator.operator);
    });
  }

  if (!showAny) {
    operatorsToShow = operatorsToShow.filter((operator) => {
      return operator.operator !== SweepFieldOperator.ANY;
    });
  }

  operatorsToShow = alwaysFilterBy(operatorsToShow);

  const SelectComponent = removeBorders ? SelectNoBorder : Select;

  return (
    <FormControl fullWidth>
      <SelectComponent
        placeholder={placeholder}
        name={`condition-operator-${uniqueId()}`}
        onChange={onSelectChange}
        value={sweepFieldOperator ?? ''}
        disabled={disabled}
      >
        {operatorsToShow.map((option) => (
          <MenuItem value={option.operator} key={option.label}>
            {option.label}
          </MenuItem>
        ))}
      </SelectComponent>
    </FormControl>
  );
};

export { SweepFieldsOperatorSelector };
