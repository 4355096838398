import usePaywall, { PaywallAction, PaywallSource } from '../../dashboard/header/usePaywall';
import PaywallBlockStartTrialDialog from '../../dashboard/paywall/PaywallBlockStartTrialDialog';
import PaywallBlockUpgradeDialog from '../../dashboard/paywall/PaywallBlockUpgradeDialog';
import UpgradeImage from '../../dashboard/header/generalUpgradeImage.svg?react';
import startCase from 'lodash/startCase';

const AutomationDeployPaywallBlock = ({
  closeDialog,
  entityNamePlural,
  objectName,
}: {
  closeDialog: () => void;
  entityNamePlural: string;
  objectName?: string;
}) => {
  const { paywallAction } = usePaywall({ source: PaywallSource.AUTOMATION_DEPLOY, objectName });

  if (paywallAction === PaywallAction.NONE) {
    return null;
  }

  const title = `Upgrade to deploy ${startCase(entityNamePlural)}`;
  const featureDescription = `deploying ${entityNamePlural} directly to Salesforce in one click`;

  if (paywallAction === PaywallAction.START_TRIAL) {
    return (
      <PaywallBlockStartTrialDialog
        closeDialog={closeDialog}
        title={title}
        featureDescription={featureDescription}
        source={PaywallSource.AUTOMATION_DEPLOY}
      />
    );
  }

  if (paywallAction === PaywallAction.UPGRADE) {
    return (
      <PaywallBlockUpgradeDialog
        closeDialog={closeDialog}
        img={<UpgradeImage />}
        title={title}
        featureDescription={featureDescription}
      />
    );
  }

  return null;
};

export default AutomationDeployPaywallBlock;
