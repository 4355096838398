import { MenuItem, SxProps, Divider, Box } from '@mui/material';
import { Typography, colors } from '@sweep-io/sweep-design';
import { useSelector } from 'react-redux';
import { selectUserInfoData } from '../../../reducers/userInfoReducer';
import {
  ShareNetwork as ShareNetworkIcon,
  Integrations as IntegrationsIcon,
  SignOut as SignOutIcon,
  User as UserIcon,
  Chat as ChatIcon,
  Calendar as CalendarIcon,
  Notification as NotificationIcon,
  ChevronRight,
} from '@sweep-io/sweep-design/dist/icons';
import { AvatarPicture } from '../../avatar/AvatarPicture';
import useLogout from '../../../auth/useLogout';
import { UserInfo } from '../../../types/UserInfoTypes';
import { getIsUserInactive } from '../../../lib/getIsUserActive';
import { openIntercom, scheduleCall } from '../customerSupportHelper';
import { ReactNode } from 'react';
import WithNotificationDot from '../../common/WithNotificationDot';

const MenuItemsUser = ({
  openMyProfileDialog,
  openOrgDialog,
  closeMenu,
  openIntegrationsDialog,
  openNotificationsPopover,
  hasUnreadNotifications,
}: {
  openMyProfileDialog: () => void;
  openOrgDialog: () => void;
  closeMenu: () => void;
  openIntegrationsDialog: () => void;
  openNotificationsPopover: () => void;
  hasUnreadNotifications: boolean;
}) => {
  const { logout } = useLogout();
  const userInfo = useSelector(selectUserInfoData);

  return (
    <>
      {userInfo?.email && (
        <UserMenuItem
          text={userInfo.email}
          user={userInfo}
          MenuItemPropsSx={{ '&:hover': { background: 'transparent', cursor: 'default' } }}
        />
      )}
      <Divider />

      <UserMenuItem
        text="My profile"
        callback={() => {
          closeMenu();
          openMyProfileDialog();
        }}
        startIcon={<UserIcon />}
      />

      <UserMenuItem
        text="Workspace"
        callback={() => {
          closeMenu();
          openOrgDialog();
        }}
        startIcon={<ShareNetworkIcon />}
      />

      <Divider />

      <UserMenuItem
        text="Chat with us"
        callback={() => {
          closeMenu();
          openIntercom();
        }}
        startIcon={<ChatIcon />}
      />

      <UserMenuItem
        text="Schedule a call"
        callback={() => {
          closeMenu();
          scheduleCall(userInfo);
        }}
        startIcon={<CalendarIcon />}
      />

      <Divider />

      <UserMenuItem
        text="Notification center"
        callback={openNotificationsPopover}
        startIcon={
          <WithNotificationDot showDot={hasUnreadNotifications}>
            <NotificationIcon />
          </WithNotificationDot>
        }
        endIcon={<ChevronRight />}
      />

      <UserMenuItem
        text="Integrations"
        callback={() => {
          closeMenu();
          openIntegrationsDialog();
        }}
        startIcon={<IntegrationsIcon />}
      />

      <UserMenuItem
        text="Log Out"
        MenuItemPropsSx={{
          color: colors.blush[600],
          '& svg path': {
            stroke: colors.blush[600],
          },
        }}
        callback={logout}
        startIcon={<SignOutIcon />}
      />
    </>
  );
};

const UserMenuItem = ({
  callback,
  text,
  MenuItemPropsSx,
  startIcon,
  endIcon,
  user,
}: {
  callback?: () => void;
  text: string;
  MenuItemPropsSx?: SxProps;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  user?: UserInfo;
}) => {
  return (
    <MenuItem
      onClick={callback}
      sx={{ display: 'flex', alignItems: 'center', gap: '8px', ...MenuItemPropsSx }}
    >
      {user && (
        <AvatarPicture
          avatar={{ emoji: user.emoji, imageUrl: user.imageUrl }}
          clickable={false}
          dimension={28}
          isInactive={getIsUserInactive(user?.status)}
        />
      )}
      {startIcon}
      <Typography variant="body">{text}</Typography>
      <Box ml="auto" display="flex" alignItems="center">
        {endIcon}
      </Box>
    </MenuItem>
  );
};

export { MenuItemsUser };
