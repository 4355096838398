// Do not put any dispatcher here.
// Only pure API calls
// The Facades should mimic exactly the corresponding API.

import { useCallback } from 'react';
import { useSweepApi } from '../../sweep';
import buildURLQuery from '../../../lib/buildURLQuery';
import { useDispatch } from 'react-redux';
import {
  assignFieldLabelsFromFunnelMap,
  assignFieldLabelsFromFunnelMaps,
} from '../../../sweep-fields/sweepFieldsLabelsReducer';
import { FunnelMapWithFields } from '../../../sweep-fields/types';

export interface FunnelMapPutPayload {
  name: string;
  defaultCrmOrgId?: string | null;
  funnels: { funnelId: string; row: number; column: number; isHidden: boolean }[];
  recordTypes?: {
    recordTypeName: string;
    objectName: string;
    row: number;
    column: number;
    leadingFieldName?: string;
    isHidden: boolean;
  }[];
  hubspotFunnels?: {
    id: string;
    row: number;
    column: number;
    objectName: string;
    leadingFieldName: string;
  }[];
}
interface FunnelMapPostPayload {
  name: string;
  defaultCrmOrgId?: string | null;
  funnels: any;
}

interface GetFunnelMapsPayload {
  includeFunnelsData?: boolean;
  includeFunnelId?: string;
}

const funnelMapsRoute = 'funnel-maps';

export const useFunnelMapApiFacade = () => {
  const sweepApi = useSweepApi();
  const dispatch = useDispatch();

  return {
    get_funnelMap: useCallback(
      async (id: string, includeFunnelsData?: boolean): Promise<FunnelMap | PartialFunnelMap> => {
        const response = await sweepApi.get(
          `/${funnelMapsRoute}/${id}?${buildURLQuery({ includeFunnelsData })}`,
        );

        const funnelMap = response.data as FunnelMapWithFields;
        dispatch(assignFieldLabelsFromFunnelMap({ funnelMap }));
        if (includeFunnelsData) {
          return funnelMap as FunnelMap;
        } else {
          return funnelMap as PartialFunnelMap;
        }
      },
      [dispatch, sweepApi],
    ),
    get_funnelMaps: useCallback(
      async ({
        includeFunnelsData,
        includeFunnelId,
      }: GetFunnelMapsPayload): Promise<FunnelMap[] | PartialFunnelMap[]> => {
        const getMapsResponse = await sweepApi.get(
          `/${funnelMapsRoute}?${buildURLQuery({ includeFunnelsData, includeFunnelId })}`,
        );

        if (includeFunnelsData) {
          const funnelMaps = getMapsResponse.data as FunnelMapWithFields[];
          dispatch(assignFieldLabelsFromFunnelMaps({ funnelMaps }));

          return funnelMaps as FunnelMap[];
        } else {
          return getMapsResponse.data as PartialFunnelMap[];
        }
      },
      [dispatch, sweepApi],
    ),
    post_funnelMap: useCallback(
      async (payload: FunnelMapPostPayload) => {
        const postResponse = await sweepApi.post(`/${funnelMapsRoute}`, payload);
        return postResponse.data as PartialFunnelMap;
      },
      [sweepApi],
    ),
    put_funnelMap: useCallback(
      async (id: string, payload: FunnelMapPutPayload) => {
        const response = await sweepApi.put(`/${funnelMapsRoute}/${id}`, payload);

        return response.data as FunnelMap | PartialFunnelMap;
      },
      [sweepApi],
    ),
    delete_funnelMap: useCallback(
      async (funnelMapId: string) => {
        await sweepApi.delete(`/${funnelMapsRoute}/${funnelMapId}`);
      },
      [sweepApi],
    ),
    get_recordTypesAndFunnels: useCallback(
      async ({ funnelMapId, crmOrgId }: { funnelMapId: string; crmOrgId: string }) => {
        const response = await sweepApi.get(
          `/${funnelMapsRoute}/${funnelMapId}/funnels-and-record-types?crmOrgId=${crmOrgId}`,
        );
        return response.data as ParsedRecordTypesAndFunnels;
      },
      [sweepApi],
    ),
    get_funnelMapSweepFields: useCallback(
      async (funnelMapId: string) => {
        const response = await sweepApi.get(`/${funnelMapsRoute}/${funnelMapId}/fields`);
        return response.data as FunnelMapFieldsUsageData;
      },
      [sweepApi],
    ),
    updateIsPinnedApi: useCallback(
      async (id: string, payload: { isPinned: boolean }) => {
        const response = await sweepApi.patch(`/${funnelMapsRoute}/${id}/isPinned`, payload);

        return response.data as PartialFunnelMap;
      },
      [sweepApi],
    ),
  };
};
