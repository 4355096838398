import { Box } from '@mui/material';
import pluralize from 'pluralize';
import { SearchInput } from '../../../common/SearchInput';
import { FlexBox } from '../../../common/FlexBox';
import { Button, colors, Typography } from '@sweep-io/sweep-design';
import { useCallback, useState } from 'react';
import { HubspotCampaignDialog } from './campaign-dialog/HubspotCampaignDialog';
import { HubspotCampaignContent } from './campaign-dialog/HubspotCampaignContent';
import { selectCampaigns, selectCampaignWorkflows } from '../../../../reducers/hubspotReducer';
import { useSelector } from 'react-redux';
import ListItemActionCard from '../../../common/ListItemActionCard';
import { Commands } from '../../../../types/enums/Common';
import { Edit } from '@sweep-io/sweep-design/dist/icons/Edit';
import { Delete } from '@sweep-io/sweep-design/dist/icons/Delete';
import { filterItemsBySearch } from '../../../../lib/filterItemsBySearch';
import { HubspotCampaignCtxProvider } from './campaign-dialog/HubspotCampaignCtx';
import { useLoadCampaignStatusAndTypePklValues } from './useLoadCampaignStatusAndTypePklValues';
import { Enrollment } from '@sweep-io/sweep-design/dist/icons/Enrollment';
import { useCanvasFilters } from '../../configuration-canvas-filters/useCanvasFilters';
import { HubspotFilterTypes } from './HubspotCanvasPanel';

const actionsMenuItems = [
  {
    label: Commands.Edit,
    value: Commands.Edit,
    actionIcon: <Edit />,
    addBottomDivider: true,
  },
  {
    label: Commands.Delete,
    value: Commands.Delete,
    actionIcon: <Delete color={colors.blush[600]} />,
    actionSx: { color: colors.blush[600] },
  },
];

const EnrolmentRulesByCampaign = ({ campaignId }: { campaignId: string }) => {
  const campaignActiveWorkflows = useSelector(selectCampaignWorkflows(campaignId)).length;
  if (campaignActiveWorkflows === 0) {
    return null;
  }
  return (
    <FlexBox alignItems="center" gap={1} mt={'2px'}>
      <Enrollment color={colors.mint[500]} />
      <Typography variant="body" color={colors.grey[700]}>
        {campaignActiveWorkflows} Active Enrolment {pluralize('Rule', campaignActiveWorkflows)}
      </Typography>
    </FlexBox>
  );
};

export const PanelCampaignsTab = ({ crmOrgId, hsOrgId }: { crmOrgId: string; hsOrgId: string }) => {
  const allCampaigns = useSelector(selectCampaigns);
  const [campaignDialog, setCampaignDialog] = useState<{
    open: boolean;
    campaignId?: string;
  }>();
  const { campaignStatusOptions, campaignTypeOptions } = useLoadCampaignStatusAndTypePklValues({
    crmOrgId,
  });

  const { filterValues: canvasFilterValues } = useCanvasFilters();

  const closeCampaignDialog = useCallback(() => setCampaignDialog(undefined), []);

  const newCampaign = useCallback(() => setCampaignDialog({ open: true }), []);

  const editCampaign = useCallback(
    (campaignId?: string) => () => {
      setCampaignDialog({ open: false });
      setTimeout(() => {
        setCampaignDialog({ open: true, campaignId });
      }, 0);
    },
    [],
  );

  const [search, setSearch] = useState('');
  const canvasFiltersCampaigns = allCampaigns?.filter((campaign) => {
    const activityFilter = canvasFilterValues[HubspotFilterTypes.ACTIVITY];
    if (activityFilter && activityFilter.selectedValues.length > 0) {
      if (campaign.IsActive) {
        if (!activityFilter.selectedValues.includes('active')) {
          return false;
        }
      } else {
        if (!activityFilter.selectedValues.includes('inactive')) {
          return false;
        }
      }
    }
    const statusFilter = canvasFilterValues[HubspotFilterTypes.CAMPAIGN_STATUS];
    if (statusFilter && statusFilter.selectedValues.length > 0) {
      if (!statusFilter.selectedValues.includes(campaign.Status || '')) {
        return false;
      }
    }

    const typeFilter = canvasFilterValues[HubspotFilterTypes.CAMPAIGN_TYPE];
    if (typeFilter && typeFilter.selectedValues.length > 0) {
      if (!typeFilter.selectedValues.includes(campaign.Type || '')) {
        return false;
      }
    }

    return true;
  });
  const filteredCampaigns = filterItemsBySearch(
    canvasFiltersCampaigns || [],
    search,
    (item) => item.Name,
  );

  return (
    <>
      <Box mt={1.5} id="panel-campaigns-tab" height="100%">
        <FlexBox
          sx={{
            ml: 2,
            mr: 2,
            mb: 2,
          }}
        >
          <Box
            display="flex"
            gap={1}
            flex={1}
            sx={{
              button: {
                pl: 3,
                pr: 3,
              },
            }}
          >
            <SearchInput
              TextFieldProps={{
                value: search,
                placeholder: 'Search',
                onChange: (e) => setSearch(e.target.value),
                fullWidth: true,
              }}
              withFixedMagnifyingGlassIcon={true}
              onClearButtonClick={() => setSearch('')}
            />
            <Button
              dataTestId="create-new-campaign-button"
              startIconName="Plus"
              size="small"
              onClick={newCampaign}
            >
              New
            </Button>
          </Box>
        </FlexBox>
        <Box sx={{ height: '100%', overflow: 'scroll' }}>
          {filteredCampaigns?.map((campaign) => {
            return (
              <ListItemActionCard
                key={campaign.Id}
                actionsMenuIconSize="tiny"
                title={campaign.Name}
                onLineClick={editCampaign(campaign.Id)}
                content={<EnrolmentRulesByCampaign campaignId={campaign.Id} />}
                actionsMenu={actionsMenuItems}
                isAllowedBtn={false}
                onActionMenuClick={(action) => {
                  switch (action) {
                    case Commands.Edit:
                      editCampaign(campaign.Id)();
                      break;
                    default:
                      break;
                  }
                }}
                onDeleteLine={function () {
                  throw new Error('Function not implemented.');
                }}
              />
            );
          })}
        </Box>
      </Box>
      {campaignDialog?.open && (
        <HubspotCampaignCtxProvider
          campaignStatusOptions={campaignStatusOptions}
          campaignTypeOptions={campaignTypeOptions}
          crmOrgId={crmOrgId}
          hsOrgId={hsOrgId}
          objectType="Contact"
        >
          <HubspotCampaignDialog isOpen={true} onClose={closeCampaignDialog}>
            <HubspotCampaignContent initialBaseCampaignId={campaignDialog.campaignId} />
          </HubspotCampaignDialog>
        </HubspotCampaignCtxProvider>
      )}
    </>
  );
};
