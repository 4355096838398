import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  selectSingleObject,
  selectSingleObjectActiveTab,
} from '../../../../reducers/documentationReducer';
import { selectCrmOrgAlerts } from '../../../../reducers/global/alertsReducers';
import { selectAssignments } from '../../../../reducers/global/assignmentReducers';
import { selectCrmOrgAutomations } from '../../../../reducers/global/automationReducers';
import { selectDedup, selectMatching } from '../../../../reducers/global/dedupMatchingReducers';
import { DocumentationTabTypes } from '../../../../types/enums/DocumentationTabTypes';
import { getDocumentationAutomationFilters } from '../../../pages/canvas-pages/automationFilters';
import { SweepCanvasFiltersMap } from '../../../pages/configuration-canvas-filters/filterTypes';
import { ConfigurationCanvasFilters } from '../../../pages/configuration-canvas/ConfigurationCanvasFilters';
import { dedupMatchingFilters } from '../../../pages/canvas-pages/dedup-and-matching/DedupMatching';
import {
  getFieldsTabFilters,
  getApexTabFilters,
  getSalesforceAutomationsTabFilters,
  getValidationRulesTabFilters,
  getAssignmentRulesFilter,
  getLayoutsTabFilters,
} from './filtersOptions';
import { selectScheduledAssignments } from '../../../../reducers/global/scheduledAssignmentReducers';
import { getDeployedVersions } from './utils';
import { selectGlobalDocumentationFieldsInOrg } from '../../../../reducers/parserReducer';

export const DocumentationFilters = ({
  crmOrgId,
  staticView,
}: {
  crmOrgId: string;
  staticView?: boolean;
}) => {
  const objectTypeName = useSelector(selectSingleObject);
  const fieldsWithUtilization = useSelector(selectGlobalDocumentationFieldsInOrg(crmOrgId));
  const automations = useSelector(selectCrmOrgAutomations(crmOrgId));
  const alerts = useSelector(selectCrmOrgAlerts(crmOrgId));
  const dedup = useSelector(selectDedup);
  const matching = useSelector(selectMatching);
  const assignments = useSelector(selectAssignments);
  const scheduledAssignments = useSelector(selectScheduledAssignments);

  const automationsMap = useMemo(
    () => ({
      [DocumentationTabTypes.APEX]: [],
      [DocumentationTabTypes.FIELDS]: [],
      [DocumentationTabTypes.VALIDATION_RULES]: [],
      [DocumentationTabTypes.SF_AUTOMATIONS]: [],
      [DocumentationTabTypes.RECORD_TYPES]: [],
      [DocumentationTabTypes.CARDS_LIST]: [],
      [DocumentationTabTypes.CPQ_DATA]: [],
      [DocumentationTabTypes.LAYOUTS]: [],
      [DocumentationTabTypes.SWEEP_AUTOMATIONS]: getDeployedVersions(automations),
      [DocumentationTabTypes.PLAYBOOK_ALERTS]: getDeployedVersions(alerts),
      [DocumentationTabTypes.MATCHING_DEDUPE]: getDeployedVersions([
        ...(matching ?? []),
        ...(dedup ?? []),
      ]),
      [DocumentationTabTypes.ASSIGNMENTS]: getDeployedVersions(assignments),
      [DocumentationTabTypes.SCHEDULED_ASSIGNMENTS]: getDeployedVersions(scheduledAssignments),
    }),
    [automations, alerts, matching, dedup, assignments, scheduledAssignments],
  );

  const activeTab = useSelector(selectSingleObjectActiveTab);

  const filters = useMemo(() => {
    switch (activeTab) {
      case DocumentationTabTypes.FIELDS:
        const fields = fieldsWithUtilization?.[objectTypeName?.objectType as any];
        return fields ? getFieldsTabFilters(fields as any) : [];

      case DocumentationTabTypes.APEX:
        return getApexTabFilters(objectTypeName?.objectType);

      case DocumentationTabTypes.LAYOUTS:
        return getLayoutsTabFilters(objectTypeName?.objectType);

      case DocumentationTabTypes.SF_AUTOMATIONS:
        return getSalesforceAutomationsTabFilters(objectTypeName?.objectType);

      case DocumentationTabTypes.VALIDATION_RULES:
        return getValidationRulesTabFilters(objectTypeName?.objectType);

      case DocumentationTabTypes.SWEEP_AUTOMATIONS:
        return getDocumentationAutomationFilters(automations || [], objectTypeName?.objectType);

      case DocumentationTabTypes.PLAYBOOK_ALERTS:
        return getDocumentationAutomationFilters(alerts || [], objectTypeName?.objectType);

      case DocumentationTabTypes.ASSIGNMENTS:
        return getAssignmentRulesFilter(objectTypeName?.objectType);

      case DocumentationTabTypes.MATCHING_DEDUPE:
        return dedupMatchingFilters;

      default:
        return {};
    }
  }, [
    activeTab,
    fieldsWithUtilization,
    objectTypeName?.objectType,
    automations,
    alerts,
  ]) as SweepCanvasFiltersMap;

  const _automations = useMemo(
    () => (activeTab ? automationsMap[activeTab] : []),
    [activeTab, automationsMap],
  );

  return (
    <ConfigurationCanvasFilters
      automations={_automations}
      filters={filters}
      staticView={staticView}
    />
  );
};
