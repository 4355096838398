import { Box, FormControl, Menu, MenuItem } from '@mui/material';
import { Button, colors } from '@sweep-io/sweep-design';
import FieldLabel from '../../common/FieldLabel';
import React, { ReactNode } from 'react';

import { ActionsMenu } from '../../common/actions-menu/ActionsMenu';
import GridLayoutDnd from '../../common/GridLayoutDnd';

import { SlackActionType } from '../../../types/enums/SlackActionType';
import { SlackActionEntry } from './slack-action-entry/SlackActionEntry';
import { useSlackActionEntryActions } from './slack-action-entry/common';

interface SlackAddActionPanelProps {
  slackActions?: SlackAction[];
  onAddAction: (type: SlackActionType) => any;
  onUpdateAction: (action: SlackAction, index: number) => any;
  onDeleteAction: (index: number) => any;
  onReorderAction: (actions: SlackAction[]) => any;
  crmOrgId: string;
  objectName: string;
}

const menuActions = [
  {
    label: 'Remove',
    value: 'Remove',
  },
];

const SlackActionDnDWrapper = ({
  children,
  onDelete,
  dragHandle,
}: {
  onDelete: () => any;
  children: ReactNode;
  dragHandle: ReactNode;
}) => {
  return (
    <Box
      sx={{
        borderBottom: `1px solid ${colors.grey[200]}`,
        mb: '12px',
        position: 'relative',
      }}
    >
      <Box sx={{ position: 'absolute', left: 0 }}>{dragHandle}</Box>
      <Box sx={{ pl: '36px' }}>
        <Box sx={{ position: 'absolute', right: 0 }}>
          <ActionsMenu actions={menuActions} onClick={onDelete} />
        </Box>
        {children}
      </Box>
    </Box>
  );
};

export const SlackAddActionPanel = ({
  slackActions,
  onAddAction,
  onUpdateAction,
  onDeleteAction,
  onReorderAction,
  crmOrgId,
  objectName,
}: SlackAddActionPanelProps) => {
  const isEmpty = !slackActions || (slackActions && slackActions.length === 0);

  const itemsToRender = slackActions?.map((action, idx) => {
    const id = 'item' + idx;
    return {
      id,
      value: action,
      renderFunction: (dragHandle: ReactNode) => (
        <SlackActionDnDWrapper
          onDelete={() => onDeleteAction(idx)}
          dragHandle={dragHandle}
          key={id}
        >
          <SlackActionEntry
            action={action}
            idx={idx}
            crmOrgId={crmOrgId}
            onUpdateAction={onUpdateAction}
            objectName={objectName}
          />
        </SlackActionDnDWrapper>
      ),
    };
  });

  return (
    <FormControl
      sx={{
        width: '100%',
        marginBottom: '20px',
        maxWidth: '1148px',
      }}
    >
      <Box
        sx={{
          marginBottom: '3px',
          '& div': {
            color: colors.grey[800],
            fontSize: '12px',
            fontWeight: '400',
          },
        }}
      >
        <FieldLabel label={'Action Buttons'} />
      </Box>

      {isEmpty && <SlackAddActionButton onAddAction={onAddAction} />}

      {!isEmpty && (
        <Box
          sx={{
            background: colors.white,
            border: `1px solid ${colors.grey[300]}`,
            borderRadius: '4px',
            p: '12px',
          }}
        >
          <GridLayoutDnd
            items={itemsToRender ?? []}
            onReorder={onReorderAction}
            itemsPerRow={1}
            readonly={false}
          />

          <Box>
            <SlackAddActionButton onAddAction={onAddAction} />
          </Box>
        </Box>
      )}
    </FormControl>
  );
};

const SlackAddActionButton = ({ onAddAction }: { onAddAction: (type: SlackActionType) => any }) => {
  const slackActionEntryActions = useSlackActionEntryActions();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box>
      <Button onClick={handleClick} startIconName="Plus" variant="link">
        Add New button
      </Button>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: '242px',
            mt: '12px',
          },
        }}
      >
        {slackActionEntryActions.map((action) => (
          <MenuItem
            key={action.label}
            onClick={(e) => {
              e.stopPropagation();
              onAddAction(action.value);
              handleClose();
            }}
            disabled={action.disabled}
          >
            {action.label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
