import { Box } from '@mui/material';
import { Typography, colors, Button } from '@sweep-io/sweep-design';
import { DIMENSION_AVATAR_MEDIUM } from '../../../constants';
import UserSummaryBasic from './UserSummaryBasic';
import { InviteeInfo, InviteeStatus } from '../../../types/UserInfoTypes';
import RestrictedTooltip from '../permissions/RestrictedTooltip';
import usePermission from '../permissions/usePermission';
import { useState } from 'react';
import { AxiosResponse } from 'axios';

export interface InviteeSummaryProps {
  user: InviteeInfo;
  onResend: (user: InviteeInfo) => Promise<AxiosResponse<any, any> | boolean | undefined>;
  onRemove: (userId: string) => Promise<AxiosResponse<any, any> | boolean | undefined>;
}

const InviteeSummary = ({ user, onResend, onRemove }: InviteeSummaryProps) => {
  const [isAllowedBtnCreate, isAllowedBtnDelete] = usePermission([
    'create:users:invite',
    'delete:users:invite',
  ]);
  const [isLoadingResent, setIsLoadingResent] = useState(false);
  const [isLoadingUndo, setIsLoadingUndo] = useState(false);
  const isExpired = InviteeStatus.InviteeStatusExpired === user.status;
  const userHeader = isExpired ? 'Invitation expired' : 'Pending invitation';

  return (
    <UserSummaryBasic className="user-summary" data-testid="user-summary">
      <div className="user-summary-avatar">
        <Box
          sx={{
            width: `${DIMENSION_AVATAR_MEDIUM}px`,
            height: `${DIMENSION_AVATAR_MEDIUM}px`,
            borderRadius: '40px',
            backgroundColor: colors.grey[100],
            border: `1px solid ${colors.grey[200]}`,
            textAlign: 'center',
            '& h1': {
              lineHeight: `${DIMENSION_AVATAR_MEDIUM}px`,
            },
          }}
        />
      </div>
      <Box
        display="flex"
        gap="8px"
        flex="1 0"
        flexDirection="column"
        overflow="hidden"
        className="text-info"
      >
        <Typography
          data-testid="user-summary-name"
          ellipsis
          variant="body-bold"
          color={colors.grey[700]}
        >
          {userHeader}
        </Typography>
        <Typography
          data-testid="user-summary-email"
          ellipsis
          variant="caption"
          color={colors.grey[700]}
        >
          {user.invitee}
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          gap: 1,
          '& .MuiButtonBase-root': {
            minWidth: 'auto',
            paddingRight: 1,
          },
        }}
      >
        <RestrictedTooltip
          to={['delete:users:invite']}
          notAllowedTitle={'To undo members invitations, please contact your admin.'}
        >
          <Button
            variant="link"
            linkColorText={{
              base: colors.grey[800],
              hover: colors.grey[700],
              selected: colors.grey[800],
            }}
            disabled={!isAllowedBtnDelete || isLoadingResent}
            onClick={async () => {
              setIsLoadingUndo(true);
              await onRemove(user.id ?? '');
              setIsLoadingUndo(false);
            }}
            loading={isLoadingUndo}
          >
            Undo
          </Button>
        </RestrictedTooltip>

        {isExpired && (
          <RestrictedTooltip
            to={['create:users:invite']}
            notAllowedTitle={'To resend members invitations, please contact your admin.'}
          >
            <Button
              variant="link"
              disabled={!isAllowedBtnCreate || isLoadingUndo}
              onClick={async () => {
                setIsLoadingResent(true);
                await onResend(user);
                setIsLoadingResent(false);
              }}
              loading={isLoadingResent}
            >
              Resend
            </Button>
          </RestrictedTooltip>
        )}
      </Box>
    </UserSummaryBasic>
  );
};

export { InviteeSummary };
