import { Stack, Box } from '@mui/material';
import { SweepFullLogo } from '../../common/SweepFullLogo';
import { ReactNode } from 'react';
import { Button, colors } from '@sweep-io/sweep-design';
import useLogout from '../../../auth/useLogout';

//These tags exist to send LinkedIn conversion event (part of marketing funnel)
//They should be placed right after signup, on the first screen of the onboarding
const CONVERSION_TAGS = (
  <>
    <img
      height="1"
      width="1"
      style={{ display: 'none' }}
      alt=""
      src="https://px.ads.linkedin.com/collect/?pid=4566474&conversionId=17240762&fmt=gif"
    />
    <img
      height="1"
      width="1"
      style={{ display: 'none' }}
      alt=""
      src="https://px.ads.linkedin.com/collect/?pid=4566474&conversionId=17240754&fmt=gif"
    />
  </>
);

const OnboardingLayout = ({
  children,
  withLogout,
}: {
  children: ReactNode;
  withLogout?: boolean;
}) => {
  const { logout } = useLogout();

  return (
    <Stack height="100%">
      {CONVERSION_TAGS}
      <Box
        sx={{
          width: '100%',
          height: '76px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '16px 32px',
          borderBottom: `1px solid ${colors.grey[200]}`,
          position: 'absolute',
          top: 0,
        }}
      >
        <SweepFullLogo />
        {withLogout && (
          <Button variant="link" onClick={logout}>
            Log out
          </Button>
        )}
      </Box>
      <Box flex={1} width="100%" display="flex" justifyContent="center" alignItems="center">
        {children}
      </Box>
    </Stack>
  );
};

export default OnboardingLayout;
