import _range from 'lodash/range';
import { Box, Stack } from '@mui/material';
import { Button } from '@sweep-io/sweep-design';
import { GroupCard } from '../groups/GroupCard';
import { humanizeDate } from '../../../helpers/humanizeDate';
import { DefaultCardsLayout } from '../../../common/CardsGridLayout';
import { GroupSettingsDialog } from '../group-settings/GroupSettingsDialog';
import { GroupSkeletonCard } from '../groups/GroupSkeletonCard';
import { SweepNotificationVariant } from '../../../../reducers/notificationsReducer';
import { useSweepNotifications } from '../../../notifications/useSweepNotifications';
import { ConfirmDeleteGroupDialog } from '.././groups/ConfirmDeleteGroupDialog';
import { useState } from 'react';
import RestrictedTooltip from '../../../common/permissions/RestrictedTooltip';
import usePermission from '../../../common/permissions/usePermission';
import { AssignmentGroupMember, AssignmentGroup } from '../../../../reducers/assignmentGroupTypes';
import { useGetFirstAvailableEmoji } from '../groups/useGetFirstAvailableEmoji';
import { useAssignmentsApiWithReducer } from '../useAssignmentsApiWithReducer';
import { Permission } from '@server/permissions';
import useConfirm from '../../../common/dialogs/ConfirmLeaveWithoutSave/useConfirm';
import EmptyStateWithButton from '../../../common/empty-state/EmptyStateWithButton';
import GroupsEmptyImage from '../../../common/empty-state/svgs/groupsEmpty.svg?react';
import { telemetry } from '../../../../telemetry';

const permissionString: Permission[] = ['create:assignment-groups'];
const ADD_NOT_ALLOWED_TITLE = 'To create a new assignment group, please contact your admin';

const countActiveMembers = (members: AssignmentGroupMember[]) =>
  members.filter((member) => member.membershipActive).length;

interface GroupsListProps {
  assignmentsGroupList?: AssignmentGroup[];
  onGroupClick: (groupId: string) => void;
}

export const GroupsList = ({ assignmentsGroupList, onGroupClick }: GroupsListProps) => {
  const {
    getAssignmentsGroupList,
    createNewAssignmentGroup,
    duplicateAssignmentGroup,
    removeAssignmentGroup,
  } = useAssignmentsApiWithReducer();

  const getFirstAvailableEmoji = useGetFirstAvailableEmoji();
  const { addNotification } = useSweepNotifications();

  const [isAllowedBtn] = usePermission(permissionString);

  const [membersDialogOpen, setMembersDialogOpen] = useState(false);
  const [showNewCardSkeleton, setShowNewCardSkeleton] = useState(false);
  const { openConfirm, onCancel, onConfirm } = useConfirm();

  const openMembersDialog = () => setMembersDialogOpen(true);

  const isEmpty = assignmentsGroupList?.length === 0;

  const onDeleteClick = async (group: AssignmentGroup) => {
    const isConfirmed = await openConfirm(
      <ConfirmDeleteGroupDialog
        assignmentGroup={group}
        onConfirm={onConfirm}
        handleClose={onCancel}
      />,
    );
    if (isConfirmed) {
      try {
        await removeAssignmentGroup(group.id);
      } catch (e) {
        telemetry.captureError(e);
        addNotification({
          message: 'Error removing assignment group',
          variant: SweepNotificationVariant.Error,
        });
        getAssignmentsGroupList();
      }
    }
  };

  return (
    <>
      {isEmpty && (
        <Stack pt="6vh">
          <EmptyStateWithButton
            image={<GroupsEmptyImage />}
            notAllowedTitle={ADD_NOT_ALLOWED_TITLE}
            permissionString={['create:assignment-groups']}
            line1="Optimize and create new assignment groups"
            onClick={openMembersDialog}
          />
        </Stack>
      )}
      {membersDialogOpen && (
        <GroupSettingsDialog
          open={membersDialogOpen}
          onClose={() => setMembersDialogOpen(false)}
          onSave={async (assignmentGroupData, resetAllMembersLimit) => {
            await createNewAssignmentGroup(
              assignmentGroupData as AssignmentGroup,
              resetAllMembersLimit,
            );
          }}
          defaultEmoji={getFirstAvailableEmoji()}
          elements={undefined}
        />
      )}
      {!isEmpty && (
        <Box position="relative">
          <Box
            sx={{
              position: 'absolute',
              right: '48px',
              top: '-52px',
            }}
          >
            {assignmentsGroupList && (
              <Box>
                <RestrictedTooltip to={permissionString} notAllowedTitle={ADD_NOT_ALLOWED_TITLE}>
                  <Button disabled={!isAllowedBtn} onClick={openMembersDialog} startIconName="Plus">
                    New group
                  </Button>
                </RestrictedTooltip>
              </Box>
            )}
          </Box>

          <DefaultCardsLayout minWidth="440px" marginTop="-4px">
            {assignmentsGroupList &&
              assignmentsGroupList?.map((group) => {
                const { id, name, description, createdAt, avatar, members } = group;
                return (
                  <GroupCard
                    readonly={!isAllowedBtn}
                    key={id}
                    name={name}
                    avatar={avatar}
                    description={description}
                    lastModified={
                      humanizeDate({
                        dateOrTimestamp: createdAt ?? '',
                      }) ?? ''
                    }
                    activeMembers={countActiveMembers(members) || 0}
                    onClick={() => onGroupClick(id)}
                    onDelete={() => onDeleteClick(group)}
                    onDuplicate={async () => {
                      try {
                        setShowNewCardSkeleton(true);
                        await duplicateAssignmentGroup(group);
                      } catch (e) {
                        telemetry.captureError(e);
                        addNotification({
                          message: 'Error duplicating assignment group',
                          variant: SweepNotificationVariant.Error,
                        });
                      }
                      setShowNewCardSkeleton(false);
                    }}
                  />
                );
              })}
            {!assignmentsGroupList && _range(0, 3).map((v) => <GroupSkeletonCard key={v} />)}
            {showNewCardSkeleton && <GroupSkeletonCard />}
          </DefaultCardsLayout>
        </Box>
      )}
    </>
  );
};
