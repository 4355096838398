import { AutomationType } from '../../types/enums/AutomationType';
import { DeployStatusForTable } from '../../types/enums/DeployStatusForTable';
import { useSweepApi } from '../sweep';
import { useCallback } from 'react';

const AutomationTypeToPath = {
  [AutomationType.Alert]: '/automations/alerts',
  [AutomationType.Default]: '/automations',
  [AutomationType.Dedupe]: '/automations/dedupe-matching',
  [AutomationType.Matching]: '/automations/dedupe-matching',
  [AutomationType.Assignment]: '/automations/assignments',
  [AutomationType.ScheduledAssignment]: '/automations/assignments',
  [AutomationType.ScheduledReport]: '/automations/alerts',
};

export const useAutomationsApiFacade = () => {
  const sweepApi = useSweepApi();
  return {
    get_automation: useCallback(
      async (automationId: string, crmOrgId?: string, readOnly: boolean = false) => {
        const response = await sweepApi.get(
          `/automations/${automationId}?orgId=${crmOrgId}&includeReadOnly=${readOnly}`,
        );
        return response.data as AutomationStructureNew;
      },
      [sweepApi],
    ),
    get_automations: useCallback(async () => {
      const response = await sweepApi.get(`/automations`);
      return response.data as AutomationStructureNew[];
    }, [sweepApi]),
    create_automation: useCallback(
      async (createAutomationDto: AutomationStructureNew) => {
        const { type } = createAutomationDto;
        const endpoint = AutomationTypeToPath[type];
        const response = await sweepApi.post(endpoint, createAutomationDto);
        return response.data as AutomationStructureNew;
      },
      [sweepApi],
    ),
    update_automation: useCallback(
      async (automationDto: AutomationStructureNew) => {
        const { type } = automationDto;
        const endpoint = AutomationTypeToPath[type];
        const response = await sweepApi.put(
          `${endpoint}/${automationDto.automationId}/${automationDto.versionId}`,
          automationDto,
        );
        return response.data as AutomationStructureNew;
      },
      [sweepApi],
    ),
    toggle_automation: useCallback(
      async (toggleAutomationDto: AutomationStructureNew) => {
        const { type } = toggleAutomationDto;
        const endpoint = AutomationTypeToPath[type];
        await sweepApi.patch(
          `${endpoint}/activation/${toggleAutomationDto.automationId}/${
            toggleAutomationDto?.deployedVersionId ?? toggleAutomationDto.versionId
          }`,
          toggleAutomationDto,
        );
      },
      [sweepApi],
    ),
    deploy_automation: useCallback(
      async (
        automationId: string,
        versionId: string,
        updateAutomationDto: AutomationStructureNew,
      ) => {
        const { type } = updateAutomationDto;
        const endpoint = AutomationTypeToPath[type];
        await sweepApi.post(
          `${endpoint}/deployment/${automationId}/${versionId}`,
          updateAutomationDto,
        );
      },
      [sweepApi],
    ),
    delete_automation: useCallback(
      async (automationId: string, automationType: AutomationType) => {
        const endpoint = AutomationTypeToPath[automationType];
        await sweepApi.delete(`${endpoint}/${automationId}`);
      },
      [sweepApi],
    ),
    create_read_only_automation: useCallback(
      async (createAutomationDto: AutomationStructureNew) => {
        // this is until we remove the status in the DTO
        const _createAutomationDto = { ...createAutomationDto };
        if (createAutomationDto.status === DeployStatusForTable.Pending) {
          _createAutomationDto.status = DeployStatusForTable.Draft;
        }
        const endpoint = '/automations/read-only';
        const response = await sweepApi.post(endpoint, _createAutomationDto);
        return response.data as AutomationStructureNew;
      },
      [sweepApi],
    ),
    patch_automation_admin_notification: useCallback(
      async (automationId: string, versionId: string, note: string) => {
        await sweepApi.patch(`/automations/adminNotification/${automationId}/${versionId}`, {
          note,
        });
      },
      [sweepApi],
    ),
  };
};
