import { colors } from '@sweep-io/sweep-design';
import { BaseDialog } from '../../common/dialogs/BaseDialog';
import { DASHBOARD_HEADER_HEIGHT_PX } from '../../../constants';
import { ReactNode } from 'react';

const WIDTH = '420px';

const DashboardRightPanel = ({
  closePanel,
  children,
  isOpen,
}: {
  closePanel: () => void;
  children: ReactNode;
  isOpen: boolean;
}) => {
  return (
    <BaseDialog
      open={isOpen}
      disableEscapeKeyDown={true}
      hideBackdrop={true}
      disableAutoFocus={true}
      onClose={closePanel}
      PaperProps={{
        sx: {
          pointerEvents: 'auto',
          padding: 0,
          margin: 0,
          height: `calc(100vh - ${DASHBOARD_HEADER_HEIGHT_PX})`,
          maxHeight: 'unset',
          width: WIDTH,
          boxShadow: 1,
          zIndex: 101,
          background: colors.white,
          overflow: 'hidden',
          position: 'absolute',
          top: DASHBOARD_HEADER_HEIGHT_PX,
          right: 0,
          left: 'auto',
          borderRadius: 0,
        },
      }}
      sx={{
        pointerEvents: 'none',
      }}
    >
      {children}
    </BaseDialog>
  );
};

export default DashboardRightPanel;
