import { useSweepFields } from '../../../../../../sweep-fields/useCachedSweepFields';
import { useCallback, useEffect, useState } from 'react';
import { FormikProps, useFormik } from 'formik';
import {
  EditMode,
  FunnelField,
  NewField,
  SWEEP_FIELD_NAME,
} from '../../../../../../constants/fieldsManagementConsts';
import isEqual from 'lodash/isEqual';
import { SweepNotificationVariant } from '../../../../../../reducers/notificationsReducer';
import { useSweepNotifications } from '../../../../../notifications/useSweepNotifications';
import cloneDeep from 'lodash/cloneDeep';
import { useCreateFieldContext } from './CreateFieldContext';
import {
  formatNumberPropsToField,
  formatNumberPropsToValues,
  isLeadingFieldPicklistOrderChanged,
  isNumberLikeFieldType,
} from '../helpers';
import { fieldsValidationSchema } from '../FieldsValidations';
import { SweepFieldTypes } from '../../../../../../types/enums/SweepFieldTypes';
import { telemetry } from '../../../../../../telemetry';

const defaultInitialValues = {
  objectName: undefined,
  [SWEEP_FIELD_NAME]: undefined,
  fieldType: undefined,
  isRequired: false,
  description: undefined,
  helpText: undefined,
  properties: {},
};

const useCreateEditField = ({
  initialValues,
  closeCreateDialog,
  crmOrgId,
  onCreateFieldCb,
  onUpdateFieldCb,
  isLeadingField,
}: {
  initialValues?: Partial<FunnelField>; //relevant for "edit"
  closeCreateDialog: () => void;
  onCreateFieldCb?: (newField: SweepField) => void;
  onUpdateFieldCb?: (updatedField: SweepField) => void;
  crmOrgId: string;
  isLeadingField?: boolean;
}) => {
  const { funnelMapId } = useCreateFieldContext();
  const { createField, editField } = useSweepFields();
  const { addNotification } = useSweepNotifications();
  const [isValid, setIsValid] = useState(false);

  const formik: FormikProps<NewField | Partial<FunnelField | SweepField>> = useFormik({
    initialValues: defaultInitialValues as NewField | Partial<FunnelField | SweepField>,
    validationSchema: fieldsValidationSchema,
    onSubmit: () => {},
  });
  const { resetForm, values, submitCount, submitForm } = formik;
  const isSubmitted = Boolean(submitCount);

  useEffect(() => {
    const checkValidation = async () => {
      try {
        await fieldsValidationSchema.validate(values);
        setIsValid(true);
      } catch {
        setIsValid(false);
      }
    };
    checkValidation();
  }, [values]);

  const handleConfirm = async (mode: EditMode) => {
    await submitForm();
    if (!isValid) {
      return;
    }
    if (!values.objectName || !values.fieldType) {
      telemetry.captureError(new Error('Create field error: Missing objectName or fieldType'));
      return;
    }
    if (mode === EditMode.CREATE) {
      try {
        const field = getFieldFromValues(values) as NewField;
        const newField = await createField({
          field,
          pinToFunnelMap: funnelMapId,
          crmOrgId,
        });
        onCreateFieldCb && onCreateFieldCb(newField);
        addNotification({
          message: `Field ${field[SWEEP_FIELD_NAME]} was created successfully`,
          variant: SweepNotificationVariant.Success,
        });
      } catch (errorMsg: any) {
        addNotification({
          message: errorMsg?.toString() ?? 'Unknown error',
          variant: SweepNotificationVariant.Error,
        });
        return;
      }
    }
    if (mode === EditMode.EDIT) {
      if (!isEqual(initialValues, values)) {
        const field = getFieldFromValues(values) as SweepField;
        const isPicklist = (SweepFieldTypes.Picklist, SweepFieldTypes.MultiselectPicklist).includes(
          values.fieldType,
        );
        const disableDeduce =
          isPicklist &&
          isLeadingField &&
          initialValues &&
          isLeadingFieldPicklistOrderChanged(initialValues, values);
        const enableDeduceValuesOrder = !disableDeduce;

        try {
          const updatedField = await editField({ field, crmOrgId, enableDeduceValuesOrder });
          onUpdateFieldCb && onUpdateFieldCb(updatedField);
        } catch (errorMsg: any) {
          addNotification({
            message: errorMsg?.toString() ?? 'Unknown error',
            variant: SweepNotificationVariant.Error,
          });
          return;
        }
      }
    }
    closeCreateDialog();
  };

  const reset = useCallback(() => {
    const defaultValues = initialValues ? getInitialValues(initialValues) : defaultInitialValues;

    resetForm({
      values: defaultValues,
    });
  }, [resetForm, initialValues]);

  return { ...formik, isSubmitted, handleConfirm, reset };
};

const convertValueToFormula = (value: DefaultValueType) => {
  if (typeof value === 'string') {
    return value ? `"${value}"` : undefined;
  }
  if (typeof value === 'number') {
    //BE request to send numbers as strings
    return value + '';
  }
  return value;
};

const checkIsPlainStringFormula = (value: DefaultValueType) => {
  if (!value || typeof value !== 'string') return false;
  const reg = new RegExp(/^"[^"]*"$/, 'g');
  const match = value.match(reg);
  return Boolean(match);
};

const convertFormulaToString = (formula: string | undefined) =>
  formula ? formula.slice(1, formula.length - 1) : formula;

/*
  SF expects text/number/date default value as a formula. In our case, it means to add "" around the input.
  "defaultValue" represents the value that appears in our form (can be edited by the user).
  "defaultValueFormula" is the value sent to SF.
*/
const getFieldFromValues = (values: NewField | Partial<FunnelField | SweepField>) => {
  const { properties } = values;
  const defaultValueFormula = convertValueToFormula(properties?.defaultValue);
  const field = {
    ...values,
    properties: {
      ...properties,
      defaultValueFormula,
    },
  } as typeof values;

  if (values.fieldType === SweepFieldTypes.Checkbox) {
    delete field.properties?.defaultValueFormula;
  } else {
    delete field.properties?.defaultValue;
  }

  if (field.properties && isNumberLikeFieldType(values.fieldType)) {
    field.properties = formatNumberPropsToField(field.properties);
  }
  if (field.properties && values.fieldType === SweepFieldTypes.MultiselectPicklist) {
    field.properties.visibleLines = 4; //Product decision to send it hard coded to the BE
  }

  return field;
};

/*
  defaultValue to keep the values that can be edited.
  defaultValueFormula to keep the values that can't be edited.
*/
const getInitialValues = (values: Partial<FunnelField>) => {
  const { defaultValueFormula } = values.properties ?? {};
  const res = cloneDeep(values);
  const isString = checkIsPlainStringFormula(defaultValueFormula);
  if (isString && res.properties) {
    res.properties.defaultValue = convertFormulaToString(defaultValueFormula);
    delete res.properties?.defaultValueFormula;
  }
  if (res.properties && isNumberLikeFieldType(res.fieldType)) {
    res.properties = formatNumberPropsToValues(res.properties);
  }
  return res;
};

export default useCreateEditField;
