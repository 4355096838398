export const defaultEmojis = [
  { content: '⚽️', bgColor: '#DBEFFF' },
  { content: '🦊', bgColor: '#FFC09D' },
  { content: '🦋', bgColor: '#DBEFFF' },
  { content: '😎', bgColor: '#B9F4A4' },
  { content: '🦄', bgColor: '#F8BCF2' },
  { content: '👑', bgColor: '#91EDDC' },
  { content: '🍿', bgColor: '#FFB09D' },
  { content: '🐝', bgColor: '#F8BCF2' },
  { content: '🥑', bgColor: '#B9F4A4' },
  { content: '🍕', bgColor: '#FFB09D' },
  { content: '🦈', bgColor: '#A5BEFF' },
  { content: '🐼', bgColor: '#7384C0' },
  { content: '🐬', bgColor: '#9BD4FF' },
  { content: '🐶', bgColor: '#B9F4A4' },
  { content: '🐙', bgColor: '#FFB09D' },
  { content: '🔥', bgColor: '#FFD28D' },
  { content: '🐞', bgColor: '#FFB09D' },
  { content: '🦁', bgColor: '#FFC09D' },
  { content: '🌚', bgColor: '#B2C1F8' },
  { content: '🌝', bgColor: '#FFEE94' },
  { content: '🐣', bgColor: '#FFEE94' },
  { content: '☕️', bgColor: '#7384C0' },
  { content: '🎸', bgColor: '#F8F9FA' },
  { content: '🚀', bgColor: '#9BD4FF' },
  { content: '🥂', bgColor: '#FFD28D' },
  { content: '🏀', bgColor: '#FFC09D' },
  { content: '👾', bgColor: '#C7ADFF' },
  { content: '💃', bgColor: '#FFB09D' },
  { content: '🎉', bgColor: '#F8BCF2' },
  { content: '🏎', bgColor: '#91EDDC' },
  { content: '🌊', bgColor: '#A5BEFF' },
  { content: '🐊', bgColor: '#B9F4A4' },
];
