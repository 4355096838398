import { Divider, ListItemText, MenuItem } from '@mui/material';
import { selectDefaultCreationCrmOrgId } from '../../../../reducers/userInfoReducer';
import { useSelector } from 'react-redux';
import { selectConnectedCrmOrgs } from '../environmentsReducer';
import { Fragment, useCallback } from 'react';
import { colors, Tag } from '@sweep-io/sweep-design';
import { StyledListItem } from '../../../common/StyledListItem';
import { Check } from '@sweep-io/sweep-design/dist/icons';
import useUserInfo from '../../../../hooks/useUserInfo';
import { useNavigateToEnv } from './useNavigateToEnv';
import WithConnectOrgPermission from '../../../common/WithConnectOrgPermission';
import WalkthroughPoint from '../../../common/walkthrough-points/WalkthroughPoint';
import { WalkthroughPointType } from '@server/walkthrough';
import useWalkthroughPoint from '../../../common/walkthrough-points/useWalkthroughPoint';
import usePermission from '../../../common/permissions/usePermission';

interface EnvMenuItemsProps {
  onConnectProdClick: () => void;
  onManageEnvClick: () => void;
  close: () => void;
  onChangeEnvCallback?: () => void;
}

export const EnvMenuItems = ({
  onConnectProdClick,
  onManageEnvClick,
  close,
  onChangeEnvCallback,
}: EnvMenuItemsProps) => {
  const defaultCreationCrmOrgId = useSelector(selectDefaultCreationCrmOrgId);
  const environments = useSelector(selectConnectedCrmOrgs);
  const { updateDefaultCreationCrmOrgId } = useUserInfo();
  const [isAllowedConnectProd] = usePermission(['create:orgs']);

  const [prodEnvs, sandboxEnvs] = environments.reduce(
    (acc, curr) => {
      curr.isMain ? acc[0].push(curr) : acc[1].push(curr);
      return acc;
    },
    [[] as CrmOrg[], [] as CrmOrg[]],
  );
  sandboxEnvs.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());

  const { navigateToEnvIfAppropriate } = useNavigateToEnv();

  const setDefaultCreationEnvironmentHandler = useCallback(
    (envId: string) => async () => {
      await updateDefaultCreationCrmOrgId(envId);
      navigateToEnvIfAppropriate(envId);
      close();
      onChangeEnvCallback && onChangeEnvCallback();
    },
    [close, navigateToEnvIfAppropriate, updateDefaultCreationCrmOrgId, onChangeEnvCallback],
  );

  const { markWalkthroughCompleted } = useWalkthroughPoint({
    point: WalkthroughPointType.envSelectorConnectProd,
  });

  const divider = <Divider sx={{ borderColor: colors.grey[200], mb: 1 }} />;

  const connectProdItem = () => {
    if (prodEnvs.length > 0) {
      return null;
    }
    return (
      <>
        <WithConnectOrgPermission>
          <MenuItem
            onClick={() => {
              markWalkthroughCompleted();
              onConnectProdClick();
            }}
          >
            {isAllowedConnectProd && (
              <WalkthroughPoint
                point={WalkthroughPointType.envSelectorConnectProd}
                top={15}
                left={170}
              />
            )}
            <ListItemText>Connect Production</ListItemText>
          </MenuItem>
        </WithConnectOrgPermission>
        {divider}
      </>
    );
  };
  const envs: CrmOrg[] = [...prodEnvs, ...sandboxEnvs];

  const renderItem = (env: CrmOrg) => {
    const color = env.isMain ? colors.mint[100] : colors.sun[100];
    const label = env.isMain ? 'Production' : 'Sandbox';
    const check =
      defaultCreationCrmOrgId === env.id ? <Check color={colors.blue[500]} /> : undefined;

    return (
      <StyledListItem
        title={env.name}
        ListItemTag={<Tag color={color} label={label} />}
        EndAdornment={check}
        onClick={setDefaultCreationEnvironmentHandler(env.id)}
      />
    );
  };

  return (
    <>
      {connectProdItem()}
      {envs.map((env) => (
        <Fragment key={env.id}>{renderItem(env)}</Fragment>
      ))}
      <Divider sx={{ borderColor: colors.grey[200], mb: 1 }} />
      <MenuItem onClick={onManageEnvClick}>
        <ListItemText>Manage environments</ListItemText>
      </MenuItem>
    </>
  );
};
