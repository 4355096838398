import { NavigateOptions, useLocation, useNavigate } from 'react-router';
import { useCallback } from 'react';
import { appRoutes } from '../../constants/appRoutes';

interface SweepNavigateOptions extends NavigateOptions {
  clearSearch?: boolean;
}

const useSweepNavigate = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryString = useLocation().search;

  const goBack = useCallback(
    (defaultPath?: string) => {
      const { state } = location;
      let destination: string | undefined = undefined;
      if (state) {
        Object.entries(state).forEach((entry) => {
          const [key, value] = entry as [string, string];
          if (key === 'from') {
            destination = value;
          }
        });
      }
      navigate((destination ?? defaultPath ?? appRoutes.home) + queryString);
    },
    [location, navigate, queryString],
  );

  const navigateWithHistory = useCallback(
    (destination: string, options?: SweepNavigateOptions) => {
      const { clearSearch, ...navigateOptions } = options ?? {};
      const _destination = clearSearch ? destination : destination + queryString;
      return navigate(_destination, {
        state: { from: location.pathname },
        ...navigateOptions,
      });
    },
    [navigate, location, queryString],
  );
  const removeQueryParams = useCallback(
    (param: string[]) => {
      const searchParams = new URLSearchParams(location.search);
      searchParams.forEach((value, key) => {
        if (param.includes(key)) {
          searchParams.delete(key);
        }
      });
      navigate(location.pathname + '?' + searchParams.toString());
    },
    [location.pathname, location.search, navigate],
  );

  return { goBack, navigate: navigateWithHistory, removeQueryParams };
};

export default useSweepNavigate;
