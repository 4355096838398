import { CSSObject, List, Theme, styled } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import {
  Automations,
  Binoculars,
  Devops,
  File,
  Funnels,
  Reporting,
  Rule,
  Users,
  PlaybookAlert,
  Play,
  Sparkle,
  Hubspot,
  Target,
} from '@sweep-io/sweep-design/dist/icons';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { DASHBOARD_HEADER_HEIGHT } from '../../constants';
import { appRoutes } from '../../constants/appRoutes';
import { selectDefaultCreationEnvironment } from '../pages/environments/environmentsReducer';
import {
  AlertsAnimation,
  AutomationsAnimation,
  DevOpsCenterAnimation,
  DocumentationAnimation,
  FileAnimation,
  FunnelsAnimation,
  ReportingAnimation,
  RollupsAnimation,
  TargetAnimation,
  UsersAnimation,
} from '../animations/lottie/lottieAnimations';
import usePermission from '../common/permissions/usePermission';
import useSweepNavigate from '../common/useSweepNavigate';
import { SidebarListItem } from './SidebarListItem';
import { useFeatureToggle } from '../common/useFeatureToggle';

const drawerWidth = 200;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} - 5px)`,
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
      '& .MuiDrawer-paper:hover': {
        ...openedMixin(theme),
      },
    }),
  }),
);

export const SidebarMenu = ({ open }: { open: boolean }) => {
  const defaultCreationEnv = useSelector(selectDefaultCreationEnvironment);

  const location = useLocation();
  const { navigate } = useSweepNavigate();
  const { pathname } = location;
  const [isSfChangeFeedAllowed] = usePermission(['read:sf-change-feed']);
  const envIdSuffix = defaultCreationEnv ? `/${defaultCreationEnv.id}` : '';
  const { sweepSupportChat, hubspotIntegration } = useFeatureToggle();

  return (
    <Drawer
      variant="permanent"
      id="sidebar-menu" // Do not remove this ID. used by DialogOnCanvas component
      open={open}
      PaperProps={{
        className: open ? 'open' : 'closed',
        sx: {
          top: DASHBOARD_HEADER_HEIGHT,
          '&.closed': {
            '.MuiListItemText-root': {
              opacity: 0,
              transition: 'opacity 0.2s',
            },
            '&:hover': {
              '.MuiListItemText-root': {
                opacity: 1,
              },
            },
          },
        },
      }}
    >
      <List sx={{ px: 1, py: 1.5 }}>
        <SidebarListItem
          text="Get Started"
          icon={<Play variant="default" />}
          animatedIcon={<Play variant="default" />}
          isSelected={pathname.startsWith(appRoutes.getStarted)}
          callback={() => {
            navigate(appRoutes.getStarted);
          }}
        />

        <SidebarListItem
          text="Funnel Editor"
          icon={<Funnels variant="default" />}
          animatedIcon={<FunnelsAnimation size={16} />}
          isSelected={pathname.startsWith(appRoutes.funnelMapsPage)}
          callback={() => {
            navigate(appRoutes.funnelMapsPage);
          }}
        />

        <SidebarListItem
          text="Documentation"
          icon={<Binoculars variant="default" />}
          animatedIcon={<DocumentationAnimation size={16} />}
          isSelected={pathname.startsWith(appRoutes.documentation)}
          callback={() => {
            navigate(appRoutes.documentation + envIdSuffix);
          }}
        />

        {isSfChangeFeedAllowed && (
          <SidebarListItem
            text="Change Feed"
            icon={<File variant="default" />}
            animatedIcon={<FileAnimation size={16} />}
            isSelected={pathname.startsWith(appRoutes.sfChangeFeed)}
            callback={() => {
              navigate(appRoutes.sfChangeFeed);
            }}
          />
        )}

        <SidebarListItem
          text="Rollups"
          icon={<Rule variant="default" />}
          animatedIcon={<RollupsAnimation size={16} />}
          isSelected={pathname.startsWith(appRoutes.rollups)}
          callback={() => {
            navigate(appRoutes.rollups);
          }}
        />

        <>
          <SidebarListItem
            text="Automations"
            icon={<Automations variant="default" />}
            animatedIcon={<AutomationsAnimation size={16} />}
            isSelected={pathname.startsWith(appRoutes.automations)}
            callback={() => {
              navigate(appRoutes.automations + envIdSuffix);
            }}
          />
          <SidebarListItem
            text="Alerts"
            icon={<PlaybookAlert variant="default" />}
            animatedIcon={<AlertsAnimation size={16} />}
            isSelected={pathname.startsWith(appRoutes.alerts)}
            callback={() => {
              navigate(appRoutes.alerts + envIdSuffix);
            }}
          />
          <SidebarListItem
            text="Routing"
            icon={<Users variant="default" />}
            animatedIcon={<UsersAnimation size={16} />}
            isSelected={pathname.startsWith(appRoutes.routing)}
            callback={() => {
              navigate(appRoutes.routing + envIdSuffix);
            }}
          />
          <SidebarListItem
            text="Dedupe & Matching"
            icon={<Target variant="default" />}
            animatedIcon={<TargetAnimation size={16} />}
            isSelected={pathname.startsWith(appRoutes.dedup)}
            callback={() => {
              navigate(appRoutes.dedup);
            }}
          />
        </>

        <SidebarListItem
          text="Reporting"
          icon={<Reporting variant="default" />}
          animatedIcon={<ReportingAnimation size={16} />}
          isSelected={pathname.startsWith(appRoutes.reports)}
          callback={() => {
            navigate(appRoutes.reports);
          }}
        />
        {hubspotIntegration && (
          <SidebarListItem
            text="Hubspot"
            icon={<Hubspot variant="default" />}
            isSelected={pathname.startsWith(appRoutes.hubspot)}
            callback={() => {
              navigate(appRoutes.hubspot);
            }}
          />
        )}

        <SidebarListItem
          text="Compare & Deploy"
          icon={<Devops variant="default" />}
          animatedIcon={<DevOpsCenterAnimation size={16} />}
          isSelected={pathname.startsWith(appRoutes.devopsCenter)}
          callback={() => {
            navigate(appRoutes.devopsCenter);
          }}
        />

        {sweepSupportChat && (
          <SidebarListItem
            text="Support AI"
            icon={<Sparkle variant="default" />}
            animatedIcon={<ReportingAnimation size={16} />} //TODO: add animation icon
            isSelected={pathname.startsWith(appRoutes.aiAssistant)}
            callback={() => {
              navigate(appRoutes.aiAssistant);
            }}
          />
        )}
      </List>
    </Drawer>
  );
};
