import { Box, Stack } from '@mui/material';
import { Typography } from '@sweep-io/sweep-design';
import useConfirm from '../../common/dialogs/ConfirmLeaveWithoutSave/useConfirm';
import PlayStorylaneModal from '../../pages/get-started/modals/PlayStorylaneModal';
import { DashboardRoutes } from '../../../constants/appRoutes';
import { useLocation } from 'react-router';
import { STORYLANE_URLS } from '../../common/url-consts';
import funnelsImg from './images/funnels.png';
import documentationImg from './images/documentation.png';
import automationsImg from './images/automations.png';
import changeFeedImg from './images/changeFeed.png';
import alertsImg from './images/alerts.png';
import devopsCenterImg from './images/devopsCenter.png';
import dedupeAndMatchingImg from './images/dedupe.png';
import reportingImg from './images/reporting.png';
import rollupsImg from './images/rollups.png';
import routingImg from './images/routing.png';
import { ReactNode } from 'react';

const imgWidth = '668px';
const imgStyle = { maxWidth: imgWidth, width: '100%' };
const altCommon = 'Check out our product tour';

const DATA_PER_ROUTE: {
  [key in DashboardRoutes]: { image: ReactNode; url: string; title: string };
} = {
  //"get started" doesn't require a "fetching page"
  [DashboardRoutes.getStarted]: {
    image: null,
    url: '',
    title: '',
  },
  [DashboardRoutes.funnelMapsPage]: {
    image: <img src={funnelsImg} alt={altCommon} style={imgStyle} />,
    url: STORYLANE_URLS.templates,
    title: 'Funnels',
  },
  [DashboardRoutes.documentation]: {
    image: <img src={documentationImg} alt={altCommon} style={imgStyle} />,
    url: STORYLANE_URLS.documentation,
    title: 'Documentation',
  },
  [DashboardRoutes.sfChangeFeed]: {
    image: <img src={changeFeedImg} alt={altCommon} style={imgStyle} />,
    url: STORYLANE_URLS.changeFeed,
    title: 'Change feed',
  },
  [DashboardRoutes.rollups]: {
    image: <img src={rollupsImg} alt={altCommon} style={imgStyle} />,
    url: STORYLANE_URLS.rollups,
    title: 'Rollups',
  },
  [DashboardRoutes.automations]: {
    image: <img src={automationsImg} alt={altCommon} style={imgStyle} />,
    url: STORYLANE_URLS.automations,
    title: 'Automations',
  },
  [DashboardRoutes.alerts]: {
    image: <img src={alertsImg} alt={altCommon} style={imgStyle} />,
    url: STORYLANE_URLS.slack,
    title: 'Alerts',
  },
  [DashboardRoutes.routing]: {
    image: <img src={routingImg} alt={altCommon} style={imgStyle} />,
    url: STORYLANE_URLS.routing,
    title: 'Routing',
  },
  [DashboardRoutes.reports]: {
    image: <img src={reportingImg} alt={altCommon} style={imgStyle} />,
    url: STORYLANE_URLS.reports,
    title: 'Reporting',
  },
  [DashboardRoutes.devopsCenter]: {
    image: <img src={devopsCenterImg} alt={altCommon} style={imgStyle} />,
    url: STORYLANE_URLS.devopsCenter,
    title: 'Compare & Deploy',
  },
  [DashboardRoutes.aiAssistant]: {
    image: <img src={devopsCenterImg} alt={altCommon} style={imgStyle} />, // TODO: add image
    url: STORYLANE_URLS.aiAssistant,
    title: 'AI Assistant',
  },
  [DashboardRoutes.hubspot]: {
    image: <img src={devopsCenterImg} alt={altCommon} style={imgStyle} />, // TODO: add image
    url: '/todo-url',
    title: 'Hubspot',
  },
  [DashboardRoutes.dedup]: {
    image: <img src={dedupeAndMatchingImg} alt={altCommon} style={imgStyle} />, // TODO: add image
    url: STORYLANE_URLS.dedupeAndMatching,
    title: 'Dedupe & Matching',
  },
};

const FetchingPageWithTour = () => {
  const { pathname } = useLocation();
  const { openConfirm, onCancel } = useConfirm();

  const currentMainRoute = '/' + pathname.split('/')[1];
  const { url, image, title } = DATA_PER_ROUTE[currentMainRoute as DashboardRoutes] || {};

  const onClick = () => {
    openConfirm(<PlayStorylaneModal closeDialog={onCancel} url={url} />);
  };
  return (
    <Stack height="100%" justifyContent="center" alignItems="center" p={4}>
      <Box mb={6} textAlign="center">
        <Typography variant="body">
          Scanning your Salesforce environment for the first time!
          <br />
          Check out our product tour and see how {title} works.
        </Typography>
      </Box>
      <Box
        onClick={onClick}
        sx={{ cursor: 'pointer', position: 'relative' }}
        maxWidth={imgWidth}
        width="100%"
      >
        {image}
      </Box>
    </Stack>
  );
};

export default FetchingPageWithTour;
