import { Box } from '@mui/material';
import { NoResults } from '../NoResults';
import { OCCUPIED_SPACE_MAIN_VIEW } from '../helpers';
import { TableHeaderVariant } from '../configuration-item-tables/shared/TableWithHeader';
import { CenteredCircularProgress } from '../../common/CenteredCircularProgress';
import { DependenciesTable } from '../dependencies/DependenciesTable';
import { ConfigurationType, NewDependencyProps } from '../dependencies/types';
import { prepareConfigItemMapStructure } from '../configuration-item-tables/shared/helpers';
import { useSelector } from 'react-redux';
import { selectDependenciesConfigurationItem } from '../../../reducers/documentationReducer';
import { SearchResponse } from './useUniversalSearch';
import { DependenciesSideDialog } from '../dependencies/DependenciesSideDialog';
import { useExpandedMode } from '../../pages/configuration-canvas/panels-reserve-space/ReserveSpaceForPanelsCtx';
import { useDependencies } from '../dependencies/useDependencies';
import { RecordTypesLabelsByNameAndObjectName } from '../types';
import { useFeatureToggle } from '../../common/useFeatureToggle';
import { UniversalSearchList } from './UniversalSearchList';
import { getSearchListItems } from './utils';
import { UniversalSearchEmptyState } from './UniversalSearchEmptyState';
import { SingleSelectFilterPopoverOption } from '../../common/single-select-filter/SingleSelectFilterPopover';

interface UniversalSearchContentProps {
  onChooseNewRule: (props: NewDependencyProps) => void;
  onListItemClick: (props: NewDependencyProps) => void;
  isLoadingDependency?: boolean;
  results?: SearchResponse;
  isLoadingResults?: boolean;
  tableHeaderVariant: TableHeaderVariant;
  crmOrgId: string;
  recordTypeNamesUsedInCanvas: RecordTypesLabelsByNameAndObjectName; //consider moving to context
  stepNamesUsedInCanvas: string[]; //consider moving to context
  searchTxt: string;
  filterOptions: SingleSelectFilterPopoverOption[];
  onSelectedFilterItem: (key?: ConfigurationType) => void;
  selectedFilterValue?: string;
}

export const UniversalSearchContent = ({
  onChooseNewRule,
  onListItemClick,
  isLoadingDependency,
  results,
  isLoadingResults,
  tableHeaderVariant,
  crmOrgId,
  recordTypeNamesUsedInCanvas,
  stepNamesUsedInCanvas,
  searchTxt,
  filterOptions,
  selectedFilterValue,
  onSelectedFilterItem,
}: UniversalSearchContentProps) => {
  const { searchV1 } = useFeatureToggle();

  const noResults = results && Object.values(results).every((result) => result?.length === 0);
  const selectedDependency = useSelector(selectDependenciesConfigurationItem);
  const firstHistoryItem = selectedDependency?.history?.[0];
  const { isExpandedMode } = useExpandedMode();
  const { configurationItem, dependenciesData, historyLength } = useDependencies(
    crmOrgId,
    recordTypeNamesUsedInCanvas,
  );

  return (
    <Box sx={{ display: 'flex', gap: 1, height: '100%', mr: -2, ml: -2 }}>
      <Box
        sx={{
          gap: 2,
          height: `calc(100vh - ${OCCUPIED_SPACE_MAIN_VIEW})`,
          width: '100%',
          overflow: 'auto',
          mt: 2,
        }}
      >
        {isLoadingResults && <CenteredCircularProgress />}

        {!isLoadingResults && noResults && searchTxt && (
          <Box pr={1} pl={2}>
            <NoResults />
          </Box>
        )}

        {!isLoadingResults && (!results || !searchTxt) && <UniversalSearchEmptyState />}

        {!searchV1 && !isLoadingResults && !noResults && searchTxt && (
          <Box pr={1} pl={2}>
            <DependenciesTable
              crmOrgId={crmOrgId}
              activeItemId={
                firstHistoryItem?.id
                  ? firstHistoryItem.id
                  : firstHistoryItem?.name + (firstHistoryItem?.objectName ?? '')
              }
              configurationItems={prepareConfigItemMapStructure(results)}
              onChooseNewRule={onChooseNewRule}
              headerVariant={tableHeaderVariant}
              listViewCounter={4}
              isLoadingDependency={isLoadingDependency}
              clearHistory={true}
            />
          </Box>
        )}

        {searchV1 && !isLoadingResults && !noResults && searchTxt && (
          <UniversalSearchList
            filterOptions={filterOptions}
            selectedFilterValue={selectedFilterValue}
            onSelectedFilterItem={onSelectedFilterItem}
            activeItemId={
              firstHistoryItem?.id
                ? firstHistoryItem.id
                : firstHistoryItem?.name + (firstHistoryItem?.objectName ?? '')
            }
            configurationItems={getSearchListItems(results)}
            onListItemClick={onListItemClick}
            searchTxt={searchTxt}
          />
        )}
      </Box>

      {isExpandedMode && configurationItem && dependenciesData?.parentType && (
        <Box height="calc(100% - 46px)" pt={2}>
          <DependenciesSideDialog
            crmOrgId={crmOrgId}
            configurationItem={configurationItem}
            type={dependenciesData?.parentType}
            stepNamesUsedInCanvas={stepNamesUsedInCanvas}
            recordTypeNamesUsedInCanvas={recordTypeNamesUsedInCanvas}
            historyLength={historyLength}
            staticView
          />
        </Box>
      )}
    </Box>
  );
};
