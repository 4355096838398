import { Box, MenuItem, SelectChangeEvent, Stack, TextField } from '@mui/material';
import { Section } from './atoms/Section';
import { colors, Typography } from '@sweep-io/sweep-design';
import { RoleGroupInterface, roleGroupsManagement, RoleGroupType } from '@server/role-group-interface';
import SweepSelect from '../../common/SweepSelect';
import { featureTranslations, roleTranslations } from './utils';
import { useRoleManagerContext } from './RoleManagerContext';

interface SingleRoleDetailsProps {
  role: RoleGroupInterface;
}

export const SingleRoleDetails = ({ role }: SingleRoleDetailsProps) => {
  const { updateRole } = useRoleManagerContext();
  const isEditable = role.type !== RoleGroupType.SYSTEM;

  return (
    <Stack>
      <Section headerTxt="Description">
        {!isEditable && (
          <Typography variant="body" color={colors.grey[800]}>
            {role.description}
          </Typography>
        )}

        {isEditable && (
          <TextField
            multiline
            rows={2}
            value={role.description ?? ''}
            onChange={(e) => updateRole('description', e.target.value)}
            placeholder="What is this role used for?"
          />
        )}
      </Section>

      <Section headerTxt="Permissions" withBorderBottom>
        <Box>
          {Object.keys(roleGroupsManagement).map((groupKey) => {
            const rolesByKey = roleGroupsManagement[groupKey as keyof typeof roleGroupsManagement];
            const values = Object.values(rolesByKey);
            const groupValue = role.roles?.find((role: string) => values.includes(role));
            const _groupKey = Object.keys(rolesByKey).filter(
              (key) => rolesByKey[key as keyof typeof rolesByKey] === groupValue,
            )?.[0];

            return (
              <Box
                key={groupKey}
                display="flex"
                alignItems="center"
                pl="2px"
                mt="6px"
                mb="12px"
                height="40px"
              >
                <Box minWidth="166px">
                  <Typography variant="caption">
                    {featureTranslations[groupKey as keyof typeof featureTranslations]}
                  </Typography>
                </Box>

                <Box width="100%">
                  {isEditable && (
                    <SweepSelect
                      FormControlProps={{ sx: { width: '100%' } }}
                      SelectProps={{
                        value: groupValue ?? '',
                        placeholder: 'Select',
                        onChange: (event: SelectChangeEvent<unknown>) => {
                          updateRole('roles', [
                            ...(role.roles ?? []),
                            event.target.value as string,
                          ]);
                        },
                      }}
                    >
                      {Object.keys(rolesByKey).map((roleKey) => (
                        <MenuItem
                          key={roleKey}
                          value={rolesByKey[roleKey as keyof typeof rolesByKey]}
                        >
                          {roleTranslations[roleKey as keyof typeof roleTranslations]}
                        </MenuItem>
                      ))}
                    </SweepSelect>
                  )}
                  {!isEditable && (
                    <Typography variant="caption">
                      {roleTranslations[_groupKey as keyof typeof roleTranslations]}
                    </Typography>
                  )}
                </Box>
              </Box>
            );
          })}
        </Box>
      </Section>
    </Stack>
  );
};
