import { Outlet } from 'react-router';
import useGlobalDataFetcherListener from '../../hooks/global-reducer/useGlobalDataFetcherListener';
import useSendPageVisitEvents from '../../hooks/useSendPageVisitEvents';
import useTrackPageViews from '../../hooks/useTrackPageViews';
import useUpdatePageTitles from '../../hooks/useUpdatePageTitles';
import useOnboardingNavigation from '../../hooks/useOnboardingNavigation';
import useAutoActivateTrial from '../../hooks/useAutoActivateTrial';

const Home = () => {
  useSendPageVisitEvents();
  useTrackPageViews();
  useUpdatePageTitles();
  useAutoActivateTrial();
  useOnboardingNavigation();
  useGlobalDataFetcherListener();

  return <Outlet />;
};

export default Home;
