import StartTrialDialog from './StartTrialDialog';
import { ReactNode } from 'react';
import { PaywallSource } from '../header/usePaywall';
import { TRIAL_TEXT_PRE } from './paywallConsts';

const PaywallBlockStartTrialDialog = ({
  closeDialog,
  onConfirmCb,
  source,
  title,
  featureDescription,
}: {
  closeDialog: () => void;
  onConfirmCb?: () => void;
  title: string | ReactNode;
  featureDescription: string;
  source: PaywallSource;
}) => {
  return (
    <StartTrialDialog
      closeDialog={closeDialog}
      text={`${TRIAL_TEXT_PRE} ${featureDescription}.`}
      title={title}
      source={source}
      onConfirmCb={onConfirmCb}
    />
  );
};

export default PaywallBlockStartTrialDialog;
