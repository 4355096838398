import { useLocation } from 'react-router-dom';
import { useCrmOrgs } from './useCrmOrgs';
import { useRunOnce } from '../../common/useRunOnce';
import { getCrmOrgTransientData } from './connectCrmSessionHelper';
import LoadingSweep from '../../common/LoadingSweep';
import { telemetry } from '../../../telemetry';

const SalesforceOAuth2Redirect = () => {
  const { search } = useLocation();
  const { setSalesforceOauthCode } = useCrmOrgs();

  useRunOnce(async () => {
    const urlSearchParams = new URLSearchParams(search);
    const code = urlSearchParams.get('code');
    const crmOrgIdToken = getCrmOrgTransientData();

    if (crmOrgIdToken && code) {
      try {
        await setSalesforceOauthCode({
          authCode: code,
          crmOrgId: crmOrgIdToken.crmOrgId,
          isSandbox: crmOrgIdToken.isSandbox,
          isOnboardingFastFetch: crmOrgIdToken.isOnboardingFastFetch,
        });
      } catch (error) {
        telemetry.captureError(error);
      }
    }
    window.close();
  });

  return <LoadingSweep />;
};

export { SalesforceOAuth2Redirect };
