import { Box, CircularProgress } from '@mui/material';
import { Typography, colors } from '@sweep-io/sweep-design';

export type DialogLoaderVariant = 'light' | 'dark';

export const DialogLoader = ({
  visible,
  variant = 'dark',
}: {
  visible?: boolean;
  variant?: DialogLoaderVariant;
}) => {
  if (!visible) {
    return null;
  }
  return (
    <Box sx={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0 }}>
      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          background: variant === 'light' ? colors.white : colors.black,
          zIndex: 999,
          opacity: variant === 'light' ? 0.8 : 0.4, //not 0.5 to differentiate from the normal dialog drop-shadow, so they don't blend (in case of a dialog on top of another dialog)
          top: 0,
          left: 0,
        }}
      />

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100%"
        flexDirection="column"
        gap={3}
        position="relative"
        zIndex={999}
      >
        <CircularProgress />
        {variant === 'light' && (
          <Typography color={colors.black} variant="body-bold">
            Loading...
          </Typography>
        )}
      </Box>
    </Box>
  );
};
