export enum DedupMatchingType {
  LEAD_TO_LEAD_DEDUP = 'LeadToLeadDedup',
  LEAD_TO_ACCOUNT_MATCHING = 'LeadToAccountMatching',
  LEAD_TO_CONTACT_DEDUP = 'LeadToContactDedup',
  LEAD_TO_LEAD_MATCHING = 'LeadToLeadMatching',
  ACCOUNT_TO_ACCOUNT_DEDUP = 'AccountToAccountDedup',
  CONTACT_TO_CONTACT_DEDUP = 'ContactToContactDedup',
}

export enum SweepFilterEndpoint {
  SOURCE = 'SOURCE',
  DESTINATION = 'DESTINATION',
  MATCH = 'MATCH',
}
