import { useContext, useMemo, useState } from 'react';
import {
  ConfigurationCanvasLeftPanel,
  ConfigurationCanvasPanelContent,
  ConfigurationCanvasPanelHeader,
} from '../../configuration-canvas-panel';
import { TabList } from '@sweep-io/sweep-design';
import { PanelCampaignsTab } from './PanelCampaignsTab';
import { useCampaignsApiFacade } from '../../../../apis/facades/useCampaignsApiFacade';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadCampaigns,
  selectCampaigns,
  startLoadingCampaigns,
} from '../../../../reducers/hubspotReducer';
import { selectDefaultCreationCrmOrgId } from '../../../../reducers/userInfoReducer';
import LoadingSweep from '../../../common/LoadingSweep';
import { useRunOnceWhenTruthy } from '../../../common/useRunOnceWhenTruthy';
import { useHubspot } from './useHubspot';
import { useHubspotWorkflows } from './workflow-dialog/useWorkflows';
import { ConfigurationCanvasFilters } from '../../configuration-canvas/ConfigurationCanvasFilters';
import {
  CanvasFilterTypes,
  SweepCanvasFiltersMap,
} from '../../configuration-canvas-filters/filterTypes';
import { HubspotCampaignCtx } from './campaign-dialog/HubspotCampaignCtx';

export enum HubspotFilterTypes {
  ACTIVITY = 'ACTIVITY',
  WORKFLOWS = 'WORKFLOWS',
  CAMPAIGN_STATUS = 'CAMPAIGN_STATUS',
  CAMPAIGN_TYPE = 'CAMPAIGN_TYPE',
}

const useLoadHubspotFieldsAndWorkflows = () => {
  const dispatch = useDispatch();
  const { get_campaigns } = useCampaignsApiFacade();
  const { getWorkflows } = useHubspotWorkflows();
  const crmOrgId = useSelector(selectDefaultCreationCrmOrgId);

  const { connectedHubspotOrg, loadHubspotFields } = useHubspot();

  useRunOnceWhenTruthy(
    async () => {
      const hsOrgId = connectedHubspotOrg?.id as string;
      dispatch(startLoadingCampaigns());

      const [campaigns] = await Promise.all([
        get_campaigns(),
        getWorkflows(hsOrgId),
        loadHubspotFields(hsOrgId, 'contacts'),
      ]);

      dispatch(loadCampaigns(campaigns));
    },

    Boolean(connectedHubspotOrg?.id),
  );

  return { crmOrgId, connectedHubspotOrg };
};

export const HubspotCanvasPanel = () => {
  const { connectedHubspotOrg, crmOrgId } = useLoadHubspotFieldsAndWorkflows();
  const [tab, setTab] = useState('campaigns');
  const campaigns = useSelector(selectCampaigns);
  const allStatuses = (campaigns || []).map((campaign) => campaign.Status);
  const { campaignStatusOptions, campaignTypeOptions } = useContext(HubspotCampaignCtx);

  const filters: SweepCanvasFiltersMap = useMemo(() => {
    const usedStatuses = Array.from(new Set(allStatuses));
    const statusItems = usedStatuses.map((status) => ({
      label: campaignStatusOptions.find((option) => option.name === status)?.label || status || '',
      value: status || '',
    }));

    const usedTypes = Array.from(new Set((campaigns || []).map((campaign) => campaign.Type)));
    const typeItems = usedTypes.map((type) => ({
      label: campaignTypeOptions.find((option) => option.name === type)?.label || type || '',
      value: type || '',
    }));

    return {
      [HubspotFilterTypes.ACTIVITY]: {
        type: CanvasFilterTypes.MULTI_GENERIC,
        position: 0,
        items: [
          { label: 'Active', value: 'active' },
          { label: 'Inactive', value: 'inactive' },
        ],
        label: 'Campaign Activity',
      },
      // [HubspotFilterTypes.WORKFLOWS]: {
      //   type: CanvasFilterTypes.MULTI_GENERIC,
      //   position: 1,
      //   items: (workflows || []).map((workflow) => ({
      //     label: workflow.name,
      //     value: workflow.id,
      //   })),
      //   label: 'Enrollment Rules',
      // },
      [HubspotFilterTypes.CAMPAIGN_STATUS]: {
        type: CanvasFilterTypes.MULTI_GENERIC,
        position: 2,
        items: statusItems,
        label: 'Campaign Status',
      },
      [HubspotFilterTypes.CAMPAIGN_TYPE]: {
        type: CanvasFilterTypes.MULTI_GENERIC,
        position: 2,
        items: typeItems,
        label: 'Campaign Type',
      },
    };
  }, [allStatuses, campaignStatusOptions, campaignTypeOptions, campaigns]);

  return (
    <ConfigurationCanvasLeftPanel
      sx={{
        '.configuration-canvas-panel-content': {
          overflow: 'hidden',
        },
      }}
    >
      {/* <WithEnvConnectionErrorPanel> */}
      <ConfigurationCanvasPanelHeader>Campaign Enrollments</ConfigurationCanvasPanelHeader>
      <ConfigurationCanvasPanelContent
        sx={{
          padding: [1, 0, 0, 0],
          flex: '1 1 auto',
          '& > div': { height: '100%' },
          '& > div > .MuiTabs-root': { pl: 2, pr: 2, display: 'none' },
          '.MuiTabPanel-root': { height: '100%', overflow: 'hidden' },
        }}
      >
        {!connectedHubspotOrg?.id || !crmOrgId ? (
          <LoadingSweep />
        ) : (
          <TabList
            value={tab}
            list={[
              {
                content: (
                  <PanelCampaignsTab crmOrgId={crmOrgId} hsOrgId={connectedHubspotOrg?.id} />
                ),
                label: 'Campaigns',
                value: 'campaigns',
              },
              { content: <>Setup Tab (TODO)</>, label: 'Setup', value: 'setup' },
            ]}
            onChange={(e, value) => setTab(value)}
          />
        )}
      </ConfigurationCanvasPanelContent>
      {/* </WithEnvConnectionErrorPanel> */}
      <ConfigurationCanvasFilters filters={filters} />
    </ConfigurationCanvasLeftPanel>
  );
};
