import { Menu, MenuItem } from '@mui/material';
import React, { useRef } from 'react';
import * as Icons from '@sweep-io/sweep-design/dist/icons';
import { IconButton } from '@sweep-io/sweep-design';

const ReplyMenu = ({
  isMenuOpen,
  openMenu,
  closeMenu,
  deleteReplyCb,
  activateEditReply,
  deleteText,
}: {
  isMenuOpen: boolean;
  openMenu: () => void;
  closeMenu: () => void;
  deleteReplyCb: () => void;
  activateEditReply: () => void;
  deleteText: string;
}) => {
  const buttonElem = useRef<any>();
  const anchorEl = buttonElem?.current;
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    event?.stopPropagation();
    openMenu();
  };
  const handleMenuClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event?.stopPropagation();
    closeMenu();
  };

  return (
    <>
      <IconButton
        ref={buttonElem}
        size="small"
        variant="flat"
        onClick={handleOpenMenu}
        pressed={isMenuOpen}
      >
        <Icons.MoreActionsVertical />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <ReplyMenuItem
          text="Edit"
          onClick={(event: any) => {
            event?.stopPropagation && event?.stopPropagation();
            activateEditReply();
          }}
        />

        <ReplyMenuItem
          text={deleteText}
          onClick={(event: any) => {
            event?.stopPropagation && event?.stopPropagation();
            deleteReplyCb();
          }}
        />
      </Menu>
    </>
  );
};

const ReplyMenuItem = ({
  onClick,
  text,
  disabled,
}: {
  onClick: (event: any) => void;
  text: string;
  disabled?: boolean;
}) => {
  return (
    <MenuItem
      disableRipple
      disableGutters
      disabled={disabled}
      onClick={onClick}
      sx={{
        width: '200px',
      }}
    >
      {text}
    </MenuItem>
  );
};

export default ReplyMenu;
