import { SweepFieldTypes } from './enums/SweepFieldTypes';

//All values according to SF team
export const TEXT_FIELD_TYPES = [
  SweepFieldTypes.Picklist,
  SweepFieldTypes.MultiselectPicklist,
  SweepFieldTypes.Text,
  SweepFieldTypes.FormulaText,
  SweepFieldTypes.Email,
  SweepFieldTypes.Html,
  SweepFieldTypes.EncryptedText,
  SweepFieldTypes.Url,
  SweepFieldTypes.Phone,
  SweepFieldTypes.LongTextArea,
  SweepFieldTypes.TextArea,
];

export const CHECKBOX_FIELD_TYPES = [SweepFieldTypes.Checkbox, SweepFieldTypes.FormulaCheckbox];
export const NUMBER_FIELD_TYPES = [
  SweepFieldTypes.Number,
  SweepFieldTypes.FormulaNumber,
  SweepFieldTypes.Percent,
  SweepFieldTypes.FormulaPercent,
  SweepFieldTypes.Currency,
  SweepFieldTypes.FormulaCurrency,
  SweepFieldTypes.Summary,
];
export const DATE_FIELD_TYPES = [SweepFieldTypes.Date, SweepFieldTypes.FormulaDate];
export const DATETIME_FIELD_TYPES = [SweepFieldTypes.DateTime, SweepFieldTypes.FormulaDateTime];
export const TIME_FIELD_TYPES = [SweepFieldTypes.Time, SweepFieldTypes.FormulaTime];
