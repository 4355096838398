import { Box } from '@mui/material';
import { Button } from '@sweep-io/sweep-design';
import { useDispatch, useSelector } from 'react-redux';
import { useSweepApi } from '../../../apis/sweep';
import { useIntegrations } from '../../../hooks/useIntegrations';
import {
  selectSlackIntegrationStatus,
  selectSlackUpgradeStatus,
  setSlackIntegrationStatus,
  SlackConnectionStatus,
} from '../../../reducers/integrationsReducer';
import { useRunOnce } from '../../common/useRunOnce';
import { waitForWindowToClose } from '../../pages/helper';
import { randomId, setSlackStateToken } from '../../../utils/slackOAuthStateUtils';
import RestrictedTooltip from '../../common/permissions/RestrictedTooltip';
import usePermission from '../../common/permissions/usePermission';
import { ButtonSize, ButtonVariants } from '@sweep-io/sweep-design/dist/components/Button/types';
import { selectProductionCrmOrg } from '../../pages/environments/environmentsReducer';
import usePopover from '../../../hooks/usePopover';
import { ActionsRequireMp } from '../../common/install-managed-package/getInstallManagedPackageText';
import InstallManagedPackagePopoverContent from '../../common/install-managed-package/InstallManagedPackagePopoverContent';
import IntegrationsButtonPopover from './IntegrationsButtonPopover';
import { ACTIONS_EVENTS } from '../../../services/events';
import useSendBiEvent from '../../../hooks/useSendBiEvent';
import { useFeatureToggle } from '../../common/useFeatureToggle';

const SERVER_URL = import.meta.env.VITE_SERVER_URL;

const getSlackRedirectToOAuthUrl = (state: string) =>
  `${SERVER_URL}/data-integrations/slack/redirect-to-oauth?state=${state}`;

interface SlackConnectButtonProps {
  variant?: ButtonVariants;
  label?: string;
  endIconName?: string;
  size?: ButtonSize;
  disabled?: boolean;
  onSuccess?: () => void;
}

export const SlackConnectButton = ({
  variant = 'filled',
  label,
  endIconName,
  size = 'large',
  disabled,
  onSuccess,
}: SlackConnectButtonProps) => {
  const { slackReports } = useFeatureToggle();
  const sendBiEvent = useSendBiEvent();
  const slackStatus = useSelector(selectSlackIntegrationStatus);
  const slackUpgradeStatus = useSelector(selectSlackUpgradeStatus);
  const sweepApi = useSweepApi();
  const dispatch = useDispatch();
  const integrations = useIntegrations();
  const productionEnvironment = useSelector(selectProductionCrmOrg);
  const managedPackagePopover = usePopover();
  const shouldDisplayUpgrade = slackReports && slackUpgradeStatus;

  const [isConnectSlackAllowed, isDisconnectSlackAllowed] = usePermission([
    'create:data-integrations:slack:oauth',
    'delete:data-integrations:slack',
  ]);

  useRunOnce(async () => {
    await integrations.getSlackStatus();
  });

  const onConnectToSlackClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (productionEnvironment?.isManagedPackageInstalled) {
      connectToSlack();
    } else {
      managedPackagePopover.openPopover(e);
    }
  };

  const connectToSlack = async () => {
    sendBiEvent({ name: ACTIONS_EVENTS.slackConnect });
    const id = randomId(15);
    setSlackStateToken(id);
    const _window = window.open(getSlackRedirectToOAuthUrl(id), '_blank');

    if (_window) {
      dispatch(setSlackIntegrationStatus(SlackConnectionStatus.Initialization));
      await waitForWindowToClose(_window);
      const status = await integrations.getSlackStatus();
      if (status === SlackConnectionStatus.Connected && onSuccess) {
        onSuccess();
      }
    } else {
      dispatch(setSlackIntegrationStatus(SlackConnectionStatus.Error));
    }
  };

  const disconnectSlack = () => {
    sweepApi.delete(`/data-integrations/slack`);
    dispatch(setSlackIntegrationStatus(SlackConnectionStatus.Initialization));
  };
  return (
    <>
      <IntegrationsButtonPopover
        open={managedPackagePopover.isPopoverOpen}
        anchorEl={managedPackagePopover.anchorElement}
        onClose={() => managedPackagePopover.closePopover()}
      >
        <InstallManagedPackagePopoverContent action={ActionsRequireMp.SLACK} />
      </IntegrationsButtonPopover>
      <Box>
        {shouldDisplayUpgrade && (
          <RestrictedTooltip
            to={['create:data-integrations:slack:oauth']}
            notAllowedTitle={'To adjust integration settings, please contact your admin.'}
          >
            <Button
              variant={variant}
              disabled={!isConnectSlackAllowed || disabled}
              loading={SlackConnectionStatus.Initialization === slackStatus}
              onClick={(e) => onConnectToSlackClick(e)}
              endIconName={endIconName}
              size={size}
              ref={managedPackagePopover.anchorRef}
            >
              {label ? label : 'Upgrade'}
            </Button>
          </RestrictedTooltip>
        )}
        {SlackConnectionStatus.Connected !== slackStatus && !shouldDisplayUpgrade ? (
          <RestrictedTooltip
            to={['create:data-integrations:slack:oauth']}
            notAllowedTitle={'To adjust integration settings, please contact your admin.'}
          >
            <Button
              variant={variant}
              disabled={!isConnectSlackAllowed || disabled}
              loading={SlackConnectionStatus.Initialization === slackStatus}
              onClick={(e) => onConnectToSlackClick(e)}
              endIconName={endIconName}
              size={size}
              ref={managedPackagePopover.anchorRef}
            >
              {label ? label : 'Connect'}
            </Button>
          </RestrictedTooltip>
        ) : (
          <RestrictedTooltip
            to={['delete:data-integrations:slack']}
            notAllowedTitle={'To adjust integration settings, please contact your admin.'}
          >
            <Button
              variant="outlined"
              onClick={disconnectSlack}
              disabled={!isDisconnectSlackAllowed}
            >
              Disconnect
            </Button>
          </RestrictedTooltip>
        )}
      </Box>
    </>
  );
};
