import { ForwardedRef, forwardRef, useEffect, useState } from 'react';
import {
  AdvancedFilterProps,
  AdvancedFilterRef,
  DeprecatedAdvancedFilter,
  itemToItemValue,
} from './DeprecatedAdvancedFilter';

/**
 * 1. When a new item is added to "items":
 *    1.1. “selected items” array is empty -> keep it empty
 *    1.2. “selected items” array is NOT empty -> add the new item to the list
 * 2. When an item is removed from "items":
 *    2.1. Filter it out of “selectedItems”
 */

//"ref" was added to avoid react warning in console ("forwardRef render functions accept exactly two parameters")

const AdvancedFilterDynamic = forwardRef<AdvancedFilterRef, AdvancedFilterProps>(function <
  DataT = any,
>(props: AdvancedFilterProps<DataT>, ref: ForwardedRef<AdvancedFilterRef>) {
  const { items, selectedItems, onSelectedItemsChange } = props;
  const [previousItems, setPreviousItems] = useState(items);

  const prevItemsCount = previousItems.length;
  const prevItemsValues = previousItems.map((item) => item.value);
  const currItemsCount = items.length;
  const currItemsValues = items.map((item) => item.value);

  useEffect(() => {
    if (prevItemsCount === currItemsCount) {
      return;
    }
    if (prevItemsCount > currItemsCount) {
      const removedItem = previousItems.find((item) => !currItemsValues.includes(item.value));
      if (removedItem) {
        const selectedWithoutRemoved = selectedItems.filter((value) => value !== removedItem.value);
        onSelectedItemsChange(
          selectedWithoutRemoved,
          items.filter((item) => selectedWithoutRemoved.includes(item.value)).map(itemToItemValue),
          items.length === selectedWithoutRemoved.length,
        );
      }
    }
    if (prevItemsCount < currItemsCount && selectedItems.length > 0) {
      const addedItem = items.find((item) => !prevItemsValues.includes(item.value));
      if (addedItem) {
        const selectedWithAdded = [...selectedItems, addedItem.value];
        onSelectedItemsChange(
          selectedWithAdded,
          items.filter((item) => selectedWithAdded.includes(item.value)).map(itemToItemValue),
          items.length === selectedWithAdded.length,
        );
      }
    }
    setPreviousItems(items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currItemsCount]);

  return <DeprecatedAdvancedFilter {...props} ref={ref} />;
});

export default AdvancedFilterDynamic;
