import { ConfirmDialog } from '../../../../common/dialogs/ConfirmDialog';
import { useSweepFields } from '../../../../../sweep-fields/useCachedSweepFields';

const DeleteFieldDialog = ({
  isOpened,
  fieldToDelete,
  closeDialog,
  onDeleteCb,
}: {
  isOpened: boolean;
  fieldToDelete: SweepField;
  closeDialog: () => void;
  onDeleteCb: (field: SweepField) => void;
}) => {
  const { deleteField } = useSweepFields();

  const handleConfirm = async () => {
    if (fieldToDelete) {
      await deleteField({ field: fieldToDelete });
      onDeleteCb(fieldToDelete);
    }
  };

  return (
    <ConfirmDialog
      isOpen={isOpened}
      title="Delete field"
      onCancel={closeDialog}
      handleClose={closeDialog}
      confirmText="Delete"
      confirmButtonType="error"
      onConfirm={handleConfirm}
      dialogSx={{ zIndex: '11111' }} //To be on top of "FieldManagementDialog"
    >
      Are you sure you want to to delete the field {fieldToDelete?.sweepFieldName} ?
    </ConfirmDialog>
  );
};

export default DeleteFieldDialog;
