import { useDispatch } from 'react-redux';
import {
  CloudingoConnectionStatus,
  setCloudingoIntegrationStatus,
  setSlackIntegrationFullStatus,
  SlackConnectionStatus,
} from '../reducers/integrationsReducer';
import { useDataIntegrationApiFacade } from '../apis/facades/useDataIntegrationApiFacade';
import { useCallback } from 'react';
import { setIsSlackRecipientsCached } from '../reducers/slackReducer';

const useIntegrations = () => {
  const dispatch = useDispatch();
  const { get_slack_status, get_cloudingo_status, get_slack_recipients } =
    useDataIntegrationApiFacade();

  const getSlackStatus = async () => {
    const response = await get_slack_status();
    const status = response.status as SlackConnectionStatus;
    const upgradeRequired = !!response.upgradeRequired;
    dispatch(setSlackIntegrationFullStatus({ slackIntegrationStatus: status, upgradeRequired }));
    return status;
  };

  const getCloudingoStatus = async () => {
    const response = await get_cloudingo_status();
    const status = response.status as CloudingoConnectionStatus;
    dispatch(setCloudingoIntegrationStatus(status));
    return status;
  };

  const getSlackRecipients = useCallback(
    async (idsArray?: string[], invalidateCache?: boolean) => {
      const response = await get_slack_recipients(idsArray, invalidateCache);
      dispatch(setIsSlackRecipientsCached({ isCached: response.isCached }));
      return response.data;
    },
    [dispatch, get_slack_recipients],
  );

  return {
    getSlackStatus,
    getCloudingoStatus,
    getSlackRecipients,
  };
};

export { useIntegrations };
