import { RoutingTabs } from '../components/routing/useRoutingTabs';

/* This routes represent the environment canvas pages */
/* On adding a new page please add them here */
export enum EnvironmentCanvasPagesRoutes {
  documentation = '/documentation',
  automations = '/automations',
  alerts = '/alerts',
  routing = '/routing',
  hubspot = '/hubspot',
  dedup = '/dedup',
}

enum OtherDashboardRoutes {
  getStarted = '/get-started',
  funnelMapsPage = '/funnels',
  sfChangeFeed = '/change-feed',
  rollups = '/rollups',
  reports = '/reports',
  devopsCenter = '/compare-and-deploy',
  aiAssistant = '/support-ai',
}

export type DashboardRoutes = EnvironmentCanvasPagesRoutes | OtherDashboardRoutes;
export const DashboardRoutes = { ...EnvironmentCanvasPagesRoutes, ...OtherDashboardRoutes };

export const appRoutes = {
  ...DashboardRoutes,
  external: '/external',
  salesforce: '/salesforce-oauth2-redirect',
  hubspotRedirect: '/hubspot-oauth2-redirect',
  demoComponents: '/demo-components',
  demoRuleBuilder: '/demo-rule-builder',
  home: '/',
  slack: '/slack/oauth2-redirect',
  version: '/version',
  freeTierOnBoarding: '/onboarding',
  canvasUnited: '/multi-funnel/flow',
  canvasUnitedSnapshot: '/multi-funnel/view',
  questionnaire: '/questionnaire',
};

export const ROUTING_TAB = 'tab';
export const ROUTING_GROUPS = `${appRoutes.routing}/${ROUTING_TAB}/${RoutingTabs.groups}`;
export const ROUTING_TERRITORIES = `${appRoutes.routing}/${ROUTING_TAB}/${RoutingTabs.territories}`;
export const ROUTING_MEMBERS = `${appRoutes.routing}/${ROUTING_TAB}/${RoutingTabs.members}`;
export const ROUTING_ASSIGNMENT_RULES = `${appRoutes.routing}/${ROUTING_TAB}/${RoutingTabs.assignment}`;
export const ROUTING_SCHEDULED_ASSIGNMENTS = `${appRoutes.routing}/${ROUTING_TAB}/${RoutingTabs.scheduled}`;

export const RoutesToSkipRedirectCallbackAuth0 = [appRoutes.slack, appRoutes.hubspotRedirect];
export const RoutesToDisableRedirectToOnboarding = [appRoutes.salesforce];
export const RoutesToOpenSideMenu = [appRoutes.getStarted];

export const RoutesThatRequireDefaultCreationEnvConnection = [
  appRoutes.documentation,
  appRoutes.automations,
  appRoutes.routing,
  appRoutes.rollups,
  appRoutes.alerts,
  appRoutes.devopsCenter,
];

export const DEFAULT_ROUTE = appRoutes.getStarted;
