import { Component } from 'react';
import { TableCell as MuiTableCell } from '@mui/material';
import classNames from 'classnames';
import { DataTableVariant, TableCellProps } from './TableTypes';
import { dataTableVariants } from './dataTableVariants';

/**

 The purpose of this component is to lock the width of the cells, so it won't change when reordering rows in table.
 based on: https://github.com/atlassian/react-beautiful-dnd/blob/master/stories/src/table/with-dimension-locking.jsx

 **/

type TableCellSnapshot = {
  width: number;
};

class TableCellLockedWidth extends Component<TableCellProps & { variant: DataTableVariant }> {
  ref: HTMLElement | undefined;

  getSnapshotBeforeUpdate(prevProps: TableCellProps): TableCellSnapshot | null {
    if (!this.ref) {
      return null;
    }

    const isDragStarting: boolean = Boolean(this.props.isDragging && !prevProps.isDragging);

    if (!isDragStarting) {
      return null;
    }

    const { width } = this.ref.getBoundingClientRect();

    const snapshot: TableCellSnapshot = { width };
    return snapshot;
  }

  componentDidUpdate(prevProps: TableCellProps, prevState: any, snapshot?: TableCellSnapshot) {
    const ref: HTMLElement | undefined = this.ref;
    if (!ref) {
      return;
    }

    if (snapshot) {
      if (ref.style.width === snapshot.width + 'px') {
        return;
      }
      ref.style.width = `${snapshot.width}px`;
      return;
    }

    if (this.props.isDragging) {
      return;
    }

    // inline styles not applied
    if (ref.style.width == null) {
      return;
    }

    // no snapshot and drag is finished - clear the inline styles
    // ref.style.removeProperty('height');
    ref.style.removeProperty('width');
  }

  setRef = (ref?: HTMLElement) => {
    this.ref = ref;
  };

  render() {
    return (
      <MuiTableCell
        sx={{
          padding: dataTableVariants[this.props.variant].cellPadding,
          height: dataTableVariants[this.props.variant].minRowHeight,

          color: dataTableVariants[this.props.variant].fontColor,
          '& .MuiTypography-root': {
            color: dataTableVariants[this.props.variant].fontColor,
          },
        }}
        className={classNames(this.props.column.className, 'SweepDataTableCell')}
        ref={this.setRef}
        onClick={this.props.onClick}
      >
        {this.props.children}
      </MuiTableCell>
    );
  }
}

export default TableCellLockedWidth;
