const CRM_ORG_EXPIRE_TOKEN_MS = 30 * 60000; // 30 Minutes
const CRM_ORG_TOKEN_KEY = 'CRM_ORG_TOKEN_KEY';

type CrmOrgTransientData = {
  crmOrgId: string;
  isSandbox: boolean;
  expires: number;
  isOnboardingFastFetch?: boolean;
};

const setCrmOrgTransientData = ({
  crmOrgId,
  isSandbox,
  isOnboardingFastFetch,
}: Omit<CrmOrgTransientData, 'expires'>) => {
  const data: CrmOrgTransientData = {
    crmOrgId,
    expires: Date.now() + CRM_ORG_EXPIRE_TOKEN_MS,
    isOnboardingFastFetch,
    isSandbox,
  };
  localStorage.setItem(CRM_ORG_TOKEN_KEY, JSON.stringify(data));
};

const getCrmOrgTransientData = (): CrmOrgTransientData | undefined => {
  const connectSalesforceCrmOrgToken = localStorage.getItem(CRM_ORG_TOKEN_KEY);
  if (!connectSalesforceCrmOrgToken) return;
  try {
    const crmOrgIdToken = JSON.parse(connectSalesforceCrmOrgToken) as CrmOrgTransientData;

    if (crmOrgIdToken.expires > Date.now()) {
      return crmOrgIdToken;
    }
    localStorage.removeItem(CRM_ORG_TOKEN_KEY);
  } catch {}
};

export { setCrmOrgTransientData, getCrmOrgTransientData };
