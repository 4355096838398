import Box from '@mui/material/Box/Box';
import { MenuItem, Popover, SxProps } from '@mui/material';
import { IconButton, __SWEEP_DESIGN_TYPOGRAPHY_STYLES___, colors } from '@sweep-io/sweep-design';
import { ChevronRight, House, MoreActionsHorizontal } from '@sweep-io/sweep-design/dist/icons';
import {
  useState,
  useEffect,
  useRef,
  useCallback,
  createRef,
  Fragment,
  useLayoutEffect,
} from 'react';
import styled from '@mui/material/styles/styled';
import Button from '@mui/material/Button/Button';
import useResizeObserver from 'use-resize-observer';
import { TruncatedTextTooltip } from '../../common/TruncatedTextTooltip';

/**
 * NOTE: design team will prepare new reusable component for design system
 * this one is temporary and for documentation only!
 */
interface Breadcrumb {
  value: string;
  label: string;
  onClick: (event: any, breadcrumb: { value: string; label: string }) => void;
}
interface BreadcrumbsProps {
  breadcrumbList: Breadcrumb[];
}

export const Breadcrumbs = ({ breadcrumbList }: BreadcrumbsProps) => {
  const [cutOverflowToIndex, setCutOverflowToIndex] = useState(0);
  const [hiddenBreadcrumbs, setHiddenBreadcrumbs] = useState<Breadcrumb[]>([]);
  const containerRef = createRef<HTMLElement>();
  const itemRefs = useRef<HTMLElement[]>([]);
  const ellipsisButtonRef = useRef<HTMLElement>();

  const updateVisibleItems = useCallback(() => {
    if (!containerRef.current || itemRefs.current.length < 2) {
      cutOverflowToIndex !== 0 && setCutOverflowToIndex(0);
      return;
    }

    const containerWidth = containerRef.current?.offsetWidth;
    const itemWidths = itemRefs.current.filter((ref) => !!ref).map((ref) => ref.offsetWidth);
    const ellipsisButtonWidth = ellipsisButtonRef?.current?.offsetWidth ?? 0;
    let totalWidth = ellipsisButtonWidth;
    let startIndex = itemWidths.length;

    for (let i = itemWidths.length - 1; i >= 0; i--) {
      totalWidth += itemWidths[i];
      if (totalWidth > containerWidth) {
        startIndex = i + 1;
        break;
      }
    }
    const idx = startIndex === breadcrumbList.length ? 0 : startIndex;

    if (idx !== cutOverflowToIndex) {
      setCutOverflowToIndex(startIndex === breadcrumbList.length ? 0 : startIndex);
    }
  }, [containerRef, itemRefs, breadcrumbList, cutOverflowToIndex]);

  useResizeObserver({
    ref: containerRef,
    onResize: () => updateVisibleItems(),
  });

  useEffect(() => {
    if (!containerRef.current || itemRefs.current.length > 2) {
      updateVisibleItems();
    }
  }, [breadcrumbList, updateVisibleItems, containerRef, itemRefs]);

  useLayoutEffect(() => {
    const isLast = (breadcrumbList?.length ?? 0) - 1 === cutOverflowToIndex;
    const _hidden = breadcrumbList.slice(1, isLast ? cutOverflowToIndex : cutOverflowToIndex + 1);
    //slice don't include last element and startOverflowFromIndex is last element we want to cut
    if (_hidden !== hiddenBreadcrumbs) {
      setHiddenBreadcrumbs(_hidden);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breadcrumbList, cutOverflowToIndex]);

  const renderLabel = (label: string) => {
    return breadcrumbList.length === 2 ? (
      <TruncatedTextTooltip variant="body-medium">{label}</TruncatedTextTooltip>
    ) : (
      label
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'nowrap',
        overflow: 'hidden',
        width: '100%',
      }}
      ref={containerRef}
    >
      {breadcrumbList.map((breadcrumb, idx) => {
        const isFirstItem = idx === 0;
        const isLastItem = idx === breadcrumbList.length - 1;
        const hideElement = hiddenBreadcrumbs.find((b) => b.value === breadcrumb.value);

        return (
          <Fragment key={'breadcrumb_' + breadcrumb.value}>
            {hiddenBreadcrumbs.length > 0 && idx === cutOverflowToIndex && (
              <BoxWithCenteredContent key={'ellipsis-button-' + breadcrumb.value}>
                <EllipsisPopover hiddenBreadcrumbs={hiddenBreadcrumbs} />
                <ChevronIcon />
              </BoxWithCenteredContent>
            )}

            <BoxWithCenteredContent
              key={breadcrumb.value}
              sx={{
                ...(breadcrumbList.length === 2 ? withTruncation : {}),
                ...(hideElement ? hideElementSx : {}),
              }}
              ref={(el) => (itemRefs.current[idx] = el as HTMLElement)}
            >
              {isFirstItem ? (
                <HouseIcon breadcrumb={breadcrumb} />
              ) : (
                <StyledButton
                  textColor={isLastItem ? colors.black : colors.grey[700]}
                  variant="link"
                  onClick={(e) => !isLastItem && breadcrumb.onClick(e, breadcrumb)}
                  sx={{
                    cursor: isLastItem ? 'default' : 'pointer',
                    ...(breadcrumbList.length === 2 ? withTruncation : {}),
                    maxWidth: 'auto',
                    minWidth: 0,
                    pl: 0.5,
                  }}
                >
                  {renderLabel(breadcrumb.label)}
                </StyledButton>
              )}
              {!isLastItem && <ChevronIcon sx={isFirstItem ? { pl: 0 } : {}} />}
            </BoxWithCenteredContent>
          </Fragment>
        );
      })}
    </Box>
  );
};

const withTruncation = {
  display: 'flex',
  minWidth: 0,
  maxWidth: 'calc(100% - 40px)',
};

//element needs to be rendered to measure its width
const hideElementSx = {
  visibility: 'hidden',
  position: 'absolute',
  top: '-5000000px',
};

const HouseIcon = ({ breadcrumb }: { breadcrumb: Breadcrumb }) => (
  <BoxWithCenteredContent>
    <IconButton variant="flat" size="tiny" onClick={(e) => breadcrumb.onClick(e, breadcrumb)}>
      <House color={colors.grey[700]} />
    </IconButton>
  </BoxWithCenteredContent>
);

const ChevronIcon = ({ sx }: { sx?: SxProps }) => (
  <BoxWithCenteredContent pl={0.5} sx={sx}>
    <ChevronRight color={colors.grey[700]} />
  </BoxWithCenteredContent>
);

const EllipsisPopover = ({ hiddenBreadcrumbs }: { hiddenBreadcrumbs: Breadcrumb[] }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  return (
    <Box>
      <Box onClick={(e) => setAnchorEl(e.currentTarget)}>
        <IconButton variant="flat" size="tiny" pressed={Boolean(anchorEl)}>
          <MoreActionsHorizontal color={colors.grey[800]} />
        </IconButton>
      </Box>

      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ p: 1 }}>
          {hiddenBreadcrumbs.map((breadcrumb) => {
            return (
              <MenuItem key={breadcrumb.value} onClick={(e) => breadcrumb.onClick(e, breadcrumb)}>
                {breadcrumb.label}
              </MenuItem>
            );
          })}
        </Box>
      </Popover>
    </Box>
  );
};

const BoxWithCenteredContent = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) =>
    prop !== 'textColor' && prop !== 'withEllipsis' && prop !== 'isActive',
})<{ textColor: string; withEllipsis?: boolean; isActive?: boolean }>(
  ({ textColor, withEllipsis, isActive }) => ({
    whiteSpace: 'nowrap',
    color: textColor,
    textOverflow: withEllipsis ? 'ellipsis' : '',
    overflow: withEllipsis ? 'hidden' : '',
    display: 'block',
    minWidth: 0,
    cursor: isActive ? 'default' : 'pointer',

    fontSize: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___['body-medium']?.fontSize,
    fontWeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___['body-medium']?.fontWeight,
    lineHeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___['body-medium']?.lineHeight,

    '&:hover': {
      color: colors.black,
    },
  }),
);
