import { ReactNode } from 'react';
import { Button } from '@sweep-io/sweep-design';
import usePermission from '../permissions/usePermission';
import { WalkthroughPointType } from '@server/walkthrough';
import { Permission } from '@server/permissions';
import RestrictedTooltip from '../permissions/RestrictedTooltip';
import WalkthroughPoint from '../walkthrough-points/WalkthroughPoint';
import EmptyState from './EmptyState';
import { ButtonSize } from '@sweep-io/sweep-design/dist/components/Button/types';

const EmptyStateWithButton = ({
  image,
  line1,
  line2,
  onClick,
  permissionString,
  notAllowedTitle,
  walkthroughPointType,
  buttonDisabled,
  buttonSize = 'large'
}: {
  image: ReactNode;
  line1: string;
  line2?: string;
  onClick: (v: any) => void;
  permissionString: Permission[];
  notAllowedTitle: string;
  walkthroughPointType?: WalkthroughPointType;
  buttonDisabled?: boolean;
  buttonSize?: ButtonSize
}) => {
  const [isAllowedBtn] = usePermission(permissionString);

  return (
    <EmptyState image={image} line1={line1} line2={line2}>
      <RestrictedTooltip to={permissionString} notAllowedTitle={notAllowedTitle}>
        <Button onClick={onClick} disabled={!isAllowedBtn || buttonDisabled} size={buttonSize}>
          Get started
        </Button>
      </RestrictedTooltip>
      {isAllowedBtn && walkthroughPointType && (
        <WalkthroughPoint point={walkthroughPointType} bottom={20} right={0} />
      )}
    </EmptyState>
  );
};

export default EmptyStateWithButton;
