import { Box, Stack } from '@mui/material';
import { ActionsRequireMp } from '../install-managed-package/getInstallManagedPackageText';
import AppExchangeButton from '../install-managed-package/AppExchangeButton';
import InstallManagedPackageContent from '../install-managed-package/InstallManagedPackageContent';
import { Typography, colors } from '@sweep-io/sweep-design';
import AppExchange from '../../common/install-managed-package/appExchange.svg?react';

export const NoManagedPackageError = ({ actionType }: { actionType: ActionsRequireMp }) => {
  return (
    <Stack justifyContent="center" alignItems="center" height="100%">
      <Box width="96px" mb={1.5}>
        <AppExchange />
      </Box>
      <Box mb={1} textAlign="center">
        <Typography variant="h3">Managed Package required</Typography>
      </Box>
      <Box mb={3}>
        <InstallManagedPackageContent
          action={actionType}
          textAlign="center"
          color={colors.grey[800]}
        />
      </Box>
      <AppExchangeButton />
    </Stack>
  );
};
