import { AvatarType } from '../components/avatar/avatarTypes';

export interface AssignmentGroupMember {
  userId: string;
  name: string;
  dateAdded: string;
  weight: number;
  membershipActive?: boolean;
  userActive?: boolean; // descriptive read only
  isUpNext?: boolean;
  limitValue?: number | null;
}

export enum AssignmentGroupLimitTimeLimitRate {
  Hour = 'HOUR',
  Day = 'DAY',
  Week = 'WEEK',
}

interface AssignmentGroupLimitAbstract {
  type: 'TIME_LIMIT' | 'CONDITIONAL_LIMIT';
}

export type AssignmentGroupLimit = AssignmentGroupConditionalLimit | AssignmentGroupLimitTime;

export interface AssignmentGroupConditionalLimitDetails {
  object: string;
  condition: SweepCondition;
}

export interface AssignmentGroupConditionalLimit extends AssignmentGroupLimitAbstract {
  type: 'CONDITIONAL_LIMIT';
  details: AssignmentGroupConditionalLimitDetails;
}

export interface AssignmentGroupLimitTime extends AssignmentGroupLimitAbstract {
  type: 'TIME_LIMIT';
  details: AssignmentGroupLimitTimeDetails;
}

export interface AssignmentGroupLimitTimeDetails {
  rate: AssignmentGroupLimitTimeLimitRate;
  timeDetails: HourLimitParams | DayLimitParams | WeekLimitParams;
}

export interface AssignmentGroupHourLimitTime extends AssignmentGroupLimitTimeDetails {
  rate: AssignmentGroupLimitTimeLimitRate.Hour;
  timeDetails: HourLimitParams;
}

export interface AssignmentGroupDayLimitTime extends AssignmentGroupLimitTimeDetails {
  rate: AssignmentGroupLimitTimeLimitRate.Day;
  timeDetails: DayLimitParams;
}

export interface AssignmentGroupWeekLimitTime extends AssignmentGroupLimitTimeDetails {
  rate: AssignmentGroupLimitTimeLimitRate.Week;
  timeDetails: WeekLimitParams;
}

export interface HourLimitParams {}

export interface DayLimitParams {
  timezone: string; // Ex: 'America/Los_Angeles', 'Europe/Paris'
}
export enum WeekDay {
  Sunday = 'SUNDAY',
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY',
}

export interface WeekLimitParams extends DayLimitParams {
  weekStartDay: WeekDay;
}

export interface AssignmentGroup {
  id: string;
  name: string;
  description: string;
  avatar?: AvatarType;
  members: AssignmentGroupMember[];
  createdAt?: string;
  updatedAt?: string;
  groupLimit?: AssignmentGroupLimit | null;
}
