import { Box, Paper, ButtonBase } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { colors } from '@sweep-io/sweep-design';

export interface OptionSelectorOption {
  label: string;
  value: string | number;
  disabled?: boolean;
}
interface OptionsSelectorButtonProps {
  options: OptionSelectorOption[];
  value?: string | number;
  defaultValue?: string | number;
  onChange?: (value: string | number) => any;
}

const CustomButton = styled(ButtonBase)({
  backgroundColor: 'transparent',
  fontFamily: 'Inter, sans-serif',
  borderRadius: '8px',
  color: 'black',
  cursor: 'pointer',
  border: 'none',
  height: '100%',
  width: '100%',
  fontSize: '12px',
  fontWeight: 500,
  '&:disabled,&[disabled]': {
    color: 'lightgray',
  },
});

export const OptionsSelectorButton = ({
  options,
  value: _value,
  defaultValue,
  onChange,
}: OptionsSelectorButtonProps) => {
  const [value, setValue] = useState(_value ?? defaultValue);

  useEffect(() => {
    setValue(_value);
  }, [_value]);

  const currentIdx = options.findIndex((option) => option.value === value);

  const optionsLength = options.length;

  const renderButton = (option: OptionSelectorOption) => (
    <Box sx={{ width: '100%', zIndex: 1 }} key={option.value}>
      <CustomButton
        disabled={option.disabled}
        onClick={() => {
          setValue(option.value);
          onChange && onChange(option.value);
        }}
      >
        {option.label}
      </CustomButton>
    </Box>
  );

  return (
    <>
      <Box
        sx={{
          height: '47px',
          background: colors.storm[70],
          borderRadius: '50px',
          boxSizing: 'border-box',
          position: 'relative',
        }}
      >
        {currentIdx >= 0 && (
          <Box
            sx={{
              width: `${100 / optionsLength}%`,
              position: 'absolute',
              height: '100%',
              boxSizing: 'border-box',
              padding: '8px',
              transition: 'all .2s ease',
              zIndex: 0,
            }}
            left={`${(100 / optionsLength) * currentIdx}%`}
          >
            <Paper
              sx={{
                background: '#fff',
                height: '100%',
                width: '100%',
                borderRadius: '100px',
              }}
              elevation={1}
            />
          </Box>
        )}
        <Box
          sx={{
            position: 'absolute',
            zIndex: 0,
            padding: '6px',
            width: '100%',
            height: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'nowrap',
              justifyContent: 'space-between',
              width: '100%',
              height: '100%',
            }}
          >
            {options.map(renderButton)}
          </Box>
        </Box>
      </Box>
    </>
  );
};
