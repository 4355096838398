import { Box } from '@mui/material';
import { colors, Typography, Badge, IconButton } from '@sweep-io/sweep-design/dist';
import { ChevronUp, ChevronDown } from '@sweep-io/sweep-design/dist/icons';
import type { ObjectWithPillsMap } from '../../../reducers/documentationReducer';

interface ObjectVirtualListHeaderProps {
  isCollapsedIn: boolean;
  setIsCollapsedIn: (panelState: { [key in keyof Partial<ObjectWithPillsMap>]: boolean }) => void;
  itemCounter: number;
  objectWithPillsMapKey: keyof ObjectWithPillsMap;
  title: string;
}

export const ObjectVirtualListHeader = ({
  isCollapsedIn,
  setIsCollapsedIn,
  itemCounter,
  title,
  objectWithPillsMapKey,
}: ObjectVirtualListHeaderProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        minHeight: '36px',
        p: 2,
        pb: 0,
        position: 'sticky',
        top: 0,
        background: colors.white,
        zIndex: 2,
        cursor: 'pointer',
      }}
      onClick={() => setIsCollapsedIn({ [objectWithPillsMapKey]: !isCollapsedIn })}
    >
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 1,
          alignItems: 'center',
          padding: '8px 0',
        }}
      >
        <Typography variant="body-medium">{title}</Typography>
        <Badge variant="secondary" label={itemCounter} />

        {itemCounter > 0 && (
          <IconButton variant="flat" size="tiny">
            {isCollapsedIn ? <ChevronUp /> : <ChevronDown />}
          </IconButton>
        )}
      </Box>
    </Box>
  );
};
