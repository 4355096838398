import ConnectToSfPage from '../../../../../dashboard/ConnectToSfPage';
import { EnvConnectionErrorPage } from '../../../../environments/env-connection-error-page/EnvConnectionErrorPage';
import { ConfigurationCanvasPanelContent } from '../../../../configuration-canvas-panel';
import { useUnhappyStates } from '../../../../environments/useUnhappyStates';
import FetchingPanel from '../../../../../dashboard/FetchingPanel';

export const WithEnvConnectionErrorPanel = ({ children }: { children: React.ReactNode }) => {
  const { connectionError, noConnectedOrg, isInitialFetch } = useUnhappyStates();

  if (noConnectedOrg) {
    return <ConnectToSfPage />;
  }

  if (isInitialFetch) {
    return (
      <ConfigurationCanvasPanelContent
        sx={{
          img: {
            width: '75%',
          },
        }}
      >
        <FetchingPanel />
      </ConfigurationCanvasPanelContent>
    );
  }

  if (connectionError) {
    return (
      <ConfigurationCanvasPanelContent
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <EnvConnectionErrorPage envConnectionError={connectionError} allowDeleteLastEnv={false} />
      </ConfigurationCanvasPanelContent>
    );
  }

  return children;
};
