import { Box } from '@mui/material';
import { IconButton, colors } from '@sweep-io/sweep-design';
import { ArrowRight, Delete } from '@sweep-io/sweep-design/dist/icons';
import {
  NestedFieldsSelector,
  NestedFieldsField,
} from '../../common/fieldsSelectors/NestedFieldsSelector';
import { useCallback, useState } from 'react';
import { useRunOnce } from '../../common/useRunOnce';
import { useSweepFields } from '../../../sweep-fields/useCachedSweepFields';
import { SweepFieldTypes } from '../../../types/enums/SweepFieldTypes';
import { ReferenceObjectType } from '../../../types/enums/ReferenceObjectType';
import { useSweepFieldsLabels } from '../../../sweep-fields/useSweepFieldsLabels';

interface FieldAssignmentRowProps {
  assignFromObject: string;
  assignToObject: string;
  crmOrgId: string;
  assignToPlaceholder?: string;
  assignFromPlaceholder?: string;
  mappingObject?: FieldMappingStruct;
  onChange?: (field: Partial<FieldMappingStruct>) => any;
  onDelete?: () => any;
  restrictToUser?: boolean;
  readonly?: boolean;
}
enum FieldsLocation {
  FROM_FIELD = 'fromField',
  TO_FIELD = 'toField',
}
export const FieldAssignmentRow = ({
  assignFromObject,
  assignToObject,
  crmOrgId,
  assignToPlaceholder,
  assignFromPlaceholder,
  mappingObject,
  onChange,
  onDelete,
  restrictToUser,
  readonly,
}: FieldAssignmentRowProps) => {
  const { getSweepFieldsById } = useSweepFields();
  const { getEnrichedNestedPath } = useSweepFieldsLabels();
  const [parentFieldType, setParentFieldType] = useState<SweepFieldTypes>();

  // Since we restrict the second field selection by the field type of the first field,
  // here we get the field type if the first field is already set.
  useRunOnce(async () => {
    if (mappingObject?.fromField?.fieldIds && !parentFieldType) {
      getFirstFieldType({ fieldIds: mappingObject?.fromField?.fieldIds });
    }
  });
  const getFirstFieldType = useCallback(
    async ({ fieldIds }: { fieldIds: string[] }) => {
      const fields = await getSweepFieldsById({
        fieldIds: [fieldIds[0]],
        crmOrgId,
      });
      const relevantField = fields[0];
      setParentFieldType(relevantField?.fieldType as SweepFieldTypes);
    },
    [getSweepFieldsById, crmOrgId],
  );

  const filterByFieldTypeExcludeAddressExcludeServiceid = useCallback(
    (field: SweepField, restrictByType: boolean) => {
      if (field.fieldType === SweepFieldTypes.Address) {
        return false;
      }
      if (field.fieldType === SweepFieldTypes.serviceid) {
        return false;
      }
      if (!restrictToUser && restrictByType) {
        return field.fieldType === parentFieldType;
      }
      return true;
    },
    [parentFieldType, restrictToUser],
  );

  const updateFieldAssignment = (sweepField: NestedFieldsField, prop: string) => {
    const newItem: SlackFieldsStruct = {
      fieldIds: sweepField.fieldIds,
    };
    // clear second field if first is changed
    let newField: FieldMappingStruct = {};
    if (prop === FieldsLocation.FROM_FIELD && !restrictToUser) {
      newField = {
        fromField: newItem,
        toField: undefined,
      };
    } else {
      newField = {
        ...mappingObject,
        [prop]: newItem,
      };
    }

    onChange && onChange(newField);
  };

  return (
    <Box sx={{ width: '50%', display: 'flex', gap: '12px', alignItems: 'center', mb: '8px' }}>
      <Box sx={{ flex: '1' }}>
        <Box
          sx={{
            backgroundColor: colors.white,
            borderRadius: '4px',
            border: `1px solid ${colors.grey[300]}`,
            marginTop: '2px',
          }}
        >
          <NestedFieldsSelector
            readonly={readonly}
            filterBy={(field) => filterByFieldTypeExcludeAddressExcludeServiceid(field, false)}
            disableLookupItemsResolve={!restrictToUser}
            isReferencedValue={restrictToUser}
            restrictReferencesToObject={restrictToUser ? ReferenceObjectType.User : undefined}
            placeholder={assignFromPlaceholder}
            crmOrgId={crmOrgId || ''}
            objectType={assignFromObject}
            nestedPath={getEnrichedNestedPath(mappingObject?.fromField?.fieldIds)}
            onChange={(sweepField: NestedFieldsField) => {
              updateFieldAssignment(sweepField, FieldsLocation.FROM_FIELD);
              setParentFieldType(sweepField.fieldType as SweepFieldTypes);
            }}
          />
        </Box>
      </Box>
      <Box sx={{ marginTop: '5px' }}>
        <ArrowRight />
      </Box>

      <Box sx={{ flex: '1' }}>
        <Box
          sx={{
            backgroundColor: colors.white,
            borderRadius: '4px',
            border: `1px solid ${colors.grey[300]}`,
            marginTop: '2px',
          }}
        >
          <NestedFieldsSelector
            disableLookupItemsResolve={!restrictToUser}
            filterBy={(field) => filterByFieldTypeExcludeAddressExcludeServiceid(field, true)}
            readonly={!mappingObject?.fromField || readonly}
            isReferencedValue={restrictToUser}
            restrictReferencesToObject={restrictToUser ? ReferenceObjectType.User : undefined}
            placeholder={assignToPlaceholder}
            crmOrgId={crmOrgId || ''}
            objectType={assignToObject}
            nestedPath={getEnrichedNestedPath(mappingObject?.toField?.fieldIds)}
            onChange={(sweepField: NestedFieldsField) => {
              updateFieldAssignment(sweepField, FieldsLocation.TO_FIELD);
            }}
          />
        </Box>
      </Box>
      {mappingObject && (
        <Box>
          <IconButton
            disabled={readonly}
            size="small"
            variant="flat"
            onClick={() => {
              onDelete && onDelete();
            }}
          >
            <Delete />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};
