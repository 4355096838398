import { humanizeDate } from '../../helpers/humanizeDate';
import { ConfigurationType, ConfigurationToName } from '../dependencies/types';
import { SearchListItem } from './types';
import { SearchResponse } from './useUniversalSearch';

export const configurationTypesOptions = [
  {
    value: ConfigurationType.apexClasses,
    label: ConfigurationToName[ConfigurationType.apexClasses],
  },
  {
    value: ConfigurationType.apexTriggers,
    label: ConfigurationToName[ConfigurationType.apexTriggers],
  },
  {
    value: ConfigurationType.approvalProcesses,
    label: ConfigurationToName[ConfigurationType.approvalProcesses],
  },
  { value: ConfigurationType.fields, label: ConfigurationToName[ConfigurationType.fields] },
  { value: ConfigurationType.flows, label: ConfigurationToName[ConfigurationType.flows] },
  {
    value: ConfigurationType.objects,
    label: ConfigurationToName[ConfigurationType.objects],
  },
  {
    value: ConfigurationType.processBuilderFlows,
    label: ConfigurationToName[ConfigurationType.processBuilderFlows],
  },
  {
    value: ConfigurationType.validationRules,
    label: ConfigurationToName[ConfigurationType.validationRules],
  },
  {
    value: ConfigurationType.workflowRules,
    label: ConfigurationToName[ConfigurationType.workflowRules],
  },
];

export const ID_NAME_SEPARATOR = '$%&';
export const generateFieldId = ({
  name,
  id,
  objectName,
}: {
  name: string;
  id?: string;
  objectName: string;
}) => (id ? id : ` ${ID_NAME_SEPARATOR}${name}${ID_NAME_SEPARATOR}${objectName}`);

export const getSearchListItems = (response?: SearchResponse): SearchListItem[] => {
  if (!response) {
    return [];
  }

  return Object.keys(response).reduce((acc: any, key) => {
    const itemsOfKey = response[key as keyof SearchResponse];

    const items = itemsOfKey?.map((item: any) => ({
      id: item.id ?? '',
      name: item.name,
      label: item.label,
      type: item.type,
      parentType: key,
      lastModifiedAtAndBy: item.annotations?.changedAt
        ? `Last modified ${humanizeDate({ dateOrTimestamp: item.annotations?.changedAt })}`
        : '',
      createdAtAndBy: item.annotations?.createdAt
        ? `Created by ${item.annotations?.createdBy} on ${humanizeDate({ dateOrTimestamp: item.annotations?.createdAt })}`
        : '',
      objectName: item.objectName ?? '',
      objectLabel: item.objectLabel,
    }));
    return [...acc, ...(items ?? [])];
  }, []);
};
