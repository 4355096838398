import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GlobalData, GlobalState } from './globalReducerTypes';
import { rollupReducers } from './rollupReducers';
import { automationReducers } from './automationReducers';
import { RootState } from '../index';
import { assignmentReducers } from './assignmentReducers';
import { alertsReducers } from './alertsReducers';
import { funnelMapsReducer } from './funnelMapsReducer';
import { dedupMatchingReducers } from './dedupMatchingReducers';
import { scheduledAssignmentReducers } from './scheduledAssignmentReducers';
import { scheduledReportReducers } from './scheduledReportReducers';

const initialState: GlobalState = {
  environments: {},
  loadingStatuses: {},
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setGlobalData: (
      state,
      action: PayloadAction<{
        crmOrgId: string;
        globalData: GlobalData;
      }>,
    ) => {
      const { crmOrgId, globalData } = action.payload;
      const { funnelMap, ...rest } = globalData;

      if (!state.environments[crmOrgId]) {
        state.environments[crmOrgId] = {
          data: globalData,
          ui: {
            shouldRefresh: false,
          },
        };
        state.loadingStatuses[crmOrgId] = {
          ...state.loadingStatuses[crmOrgId],
          isLoading: undefined,
          error: undefined,
        };

        return;
      }

      state.environments[crmOrgId].ui.shouldRefresh = false;
      state.environments[crmOrgId].data = {
        ...rest,
        funnelMap: state.environments[crmOrgId].data?.funnelMap ?? funnelMap,

      };
      state.loadingStatuses[crmOrgId] = {
        ...state.loadingStatuses[crmOrgId],
        isLoading: undefined,
        error: undefined,
      };
    },
    requestRefreshGlobal: (state, action: PayloadAction<{ crmOrgId: string }>) => {
      const { crmOrgId } = action.payload;
      if (!state.environments[crmOrgId]?.ui) {
        state.environments[crmOrgId] = {
          ui: {
            shouldRefresh: true
          }
        }
      }
      state.environments[crmOrgId].ui.shouldRefresh = true;
    },
    setGlobalIsLoading: (state, action: PayloadAction<{ crmOrgId: string }>) => {
      const { crmOrgId } = action.payload;
      state.loadingStatuses[crmOrgId] = state.loadingStatuses[crmOrgId] || {};
      state.loadingStatuses[crmOrgId].isLoading = true;
      state.loadingStatuses[crmOrgId].error = undefined;
    },
    setGlobalError: (state, action: PayloadAction<{ crmOrgId: string; error: any }>) => {
      const { crmOrgId, error } = action.payload;
      state.loadingStatuses[crmOrgId] = state.loadingStatuses[crmOrgId] || {};
      state.loadingStatuses[crmOrgId].isLoading = false;
      state.loadingStatuses[crmOrgId].error = error;
    },
    ...rollupReducers,
    ...automationReducers,
    ...assignmentReducers,
    ...alertsReducers,
    ...dedupMatchingReducers,
    ...funnelMapsReducer,
    ...scheduledAssignmentReducers,
    ...scheduledReportReducers,
  },
});

export const selectLoadingStatuses = (state: RootState) => state.global.loadingStatuses;

export const selectShouldRefresh = (crmOrgId: string) => (state: RootState) =>
  state.global.environments[crmOrgId]?.ui.shouldRefresh;

export const selectEnvironments = (state: RootState) => state.global.environments;

export const selectConfigurationCanvasFunnelMap = (crmOrgId: string) => (state: RootState) =>
  state.global.environments[crmOrgId]?.data?.funnelMap;

export const selectElementDependencies = (crmOrgId: string) => (state: RootState) =>
  state.global.environments[crmOrgId]?.data?.elementDependencies;

export const selectOrgSweepFields = (crmOrgId: string) => (state: RootState) =>
  state.global.environments[crmOrgId]?.data?.sweepFields;

export const selectFieldByFieldId = (crmOrgId: string, fieldId?: string) => (state: RootState) => {
  if (!fieldId) return undefined;
  state.global.environments[crmOrgId]?.data?.sweepFields[fieldId];
};

export const {
  setGlobalData,
  requestRefreshGlobal,
  setGlobalIsLoading,
  setGlobalError,

  addRollup,
  updateRollup,
  deleteRollup,
  updateDeployStatus,

  addAutomation,
  updateAutomation,
  toggleAutomation,
  deleteAutomation,
  updateAutomationDeployStatus,

  addAssignment,
  updateAssignment,
  toggleAssignment,
  deleteAssignment,
  updateAssignmentDeployStatus,

  addAlert,
  updateAlert,
  toggleAlert,
  deleteAlert,
  updateAlertDeployStatus,

  addDedupMatching,
  updateDedupMatching,
  toggleDedupMatching,
  deleteDedupMatching,
  updateDedupMatchingDeployStatus,

  setEnvironmentFunnelMap,
  addRecordTypeDataEntries,
  setLeadingFieldForRecordType,
  setRecordTypeDataValues,

  addScheduledAssignment,
  updateScheduledAssignment,
  toggleScheduledAssignment,
  deleteScheduledAssignment,
  updateScheduledAssignmentDeployStatus,

  addScheduledReport,
  updateScheduledReport,
  toggleScheduledReport,
  deleteScheduledReport,
  updateScheduledReportDeployStatus,
} = globalSlice.actions;

export default globalSlice.reducer;
