import { useDispatch, useSelector } from 'react-redux';
import { ConfigurationType, NewDependencyProps } from './types';
import {
  clearDependencies,
  goBackInHistory,
  selectDependenciesConfigurationItem,
  selectDependenciesHistory,
  selectLayoutsByObjectName,
  setConfigurationDependenciesForRule,
  setConfigurationUsedByForField,
  setConfigurationUsedByForRule,
  setDependenciesConfigurationItem,
  setLatestDeployment,
  setOpenAiDescriptionForRule,
} from '../../../reducers/documentationReducer';
import { useCrmOrgsApiFacade } from '../../../apis/facades/useCrmOrgsApiFacade';
import { useCallback } from 'react';
import extractErrorMsg from '../../helpers/extractErrorMsg';
import { useAutomationsApiFacade } from '../../../apis/facades/useAutomationsApiFacade';
import { useRollupsApiFacade } from '../../../apis/facades/useRollupsApiFacade';
import { RecordTypesLabelsByNameAndObjectName, SweepElementTypes } from '../types';
import { formatOpenAiResponse, isFieldType } from './helpers';
import { useGetConfigurationItem } from './useGetConfigurationItem';
import { useFeatureToggle } from '../../common/useFeatureToggle';
import { telemetry } from '../../../telemetry';
import { useLayouts } from '../useLayouts';

export const useDependencies = (
  crmOrgId: string,
  recordTypeNamesUsedInCanvas: RecordTypesLabelsByNameAndObjectName,
) => {
  const dispatch = useDispatch();
  const {
    post_crmOrgGenerateConfigurationGpt,
    get_crmOrgConfigurationConsumersFromSalto,
    get_crmOrgConfigurationDependenciesFromSalto,
    get_crmOrgFieldConsumerFromSalto,
  } = useCrmOrgsApiFacade();

  const { get_automation } = useAutomationsApiFacade();
  const { get_rollup } = useRollupsApiFacade();
  const { fetchLayoutsData } = useLayouts();

  const { aiGpt4OMini } = useFeatureToggle();

  const layoutsByObjectName = useSelector(selectLayoutsByObjectName(crmOrgId));
  const historyLength = useSelector(selectDependenciesHistory);
  const dependenciesData = useSelector(selectDependenciesConfigurationItem);
  const selectedDependency = useSelector(selectDependenciesConfigurationItem);
  const firstHistoryItem = selectedDependency?.history?.[0];

  const configurationItem = useGetConfigurationItem({ crmOrgId, recordTypeNamesUsedInCanvas });

  const handleSetConfigurationItem = useCallback(
    (props: NewDependencyProps) => {
      const { dependencyType, parentType, id, name, objectName, clearHistory } = props;
      if (isFieldType(parentType)) {
        dispatch(setDependenciesConfigurationItem(props));
        return;
      }

      if (parentType === ConfigurationType.layouts) {
        const layouts = layoutsByObjectName?.[objectName ?? ''];

        if (!layouts?.layouts && objectName) {
          fetchLayoutsData({ crmOrgId, objectName: objectName });
        }
      }

      dispatch(
        setDependenciesConfigurationItem({
          id,
          dependencyType,
          parentType,
          name,
          clearHistory,
          objectName,
        }),
      );
    },
    [dispatch, crmOrgId, fetchLayoutsData, layoutsByObjectName],
  );

  const getOpenAiDescriptionForRule = useCallback(
    async ({
      objectName,
      ruleType,
      ruleName,
      ruleId,
      crmOrgId,
    }: {
      objectName: string;
      ruleType: string;
      ruleName: string;
      ruleId: string;
      crmOrgId: string;
    }) => {
      try {
        const res = await post_crmOrgGenerateConfigurationGpt({
          crmOrgId,
          payload: {
            objectName,
            configurationKey: ruleType,
            configurationName: ruleName,
            useGpt4oMiniModel: aiGpt4OMini,
          },
        });
        const openAiDescription = formatOpenAiResponse(res);

        dispatch(
          setOpenAiDescriptionForRule({
            crmOrgId,
            openAiDescription,
            ruleId,
          }),
        );
      } catch (e) {
        telemetry.captureError(extractErrorMsg(e));
        dispatch(
          setOpenAiDescriptionForRule({
            crmOrgId,
            openAiDescription: {
              type: 'error',
              text: 'Something went wrong, please try again later',
            },
            ruleId,
          }),
        );
      }
    },
    [dispatch, post_crmOrgGenerateConfigurationGpt, aiGpt4OMini],
  );

  const getConfigurationDependencies = useCallback(
    async ({
      ruleType,
      ruleId,
      crmOrgId,
      objectName,
      configurationName,
    }: {
      ruleType: string;
      ruleId: string;
      crmOrgId: string;
      objectName: string;
      configurationName: string;
    }) => {
      try {
        const dependsOn = await get_crmOrgConfigurationDependenciesFromSalto({
          crmOrgId,
          configurationKey: ruleType,
          objectName,
          configurationName,
        });

        dispatch(
          setConfigurationDependenciesForRule({
            crmOrgId,
            ruleId,
            dependsOn,
          }),
        );
      } catch (e) {
        telemetry.captureError(extractErrorMsg(e));
      }
    },
    [dispatch, get_crmOrgConfigurationDependenciesFromSalto],
  );

  const getConfigurationUsedBy = useCallback(
    async ({
      ruleType,
      ruleId,
      crmOrgId,
      configurationName,
      objectName,
    }: {
      ruleType: string;
      ruleId: string;
      crmOrgId: string;
      configurationName: string;
      objectName: string;
    }) => {
      try {
        const usedBy = await get_crmOrgConfigurationConsumersFromSalto({
          crmOrgId,
          configurationKey: ruleType,
          configurationName,
          objectName,
        });

        dispatch(
          setConfigurationUsedByForRule({
            crmOrgId,
            ruleId,
            usedBy,
          }),
        );
      } catch (e) {
        telemetry.captureError(extractErrorMsg(e));
      }
    },
    [dispatch, get_crmOrgConfigurationConsumersFromSalto],
  );

  const getFieldConsumers = useCallback(
    async ({
      objectName,
      fieldName,
      id,
      crmOrgId,
    }: {
      objectName: string;
      fieldName: string;
      id: string;
      crmOrgId: string;
    }) => {
      try {
        const usedBy = await get_crmOrgFieldConsumerFromSalto({ crmOrgId, objectName, fieldName });

        dispatch(
          setConfigurationUsedByForField({
            crmOrgId,
            usedBy,
            fieldName,
            objectName,
            id,
          }),
        );
      } catch (e) {
        telemetry.captureError(extractErrorMsg(e));
      }
    },
    [dispatch, get_crmOrgFieldConsumerFromSalto],
  );

  const fetchLatestDeployedVersion = useCallback(
    async (sweepElementType: SweepElementTypes, elementId: string, crmOrgId: string) => {
      let element: any;

      switch (sweepElementType) {
        case ConfigurationType.sweepAutomation:
        case ConfigurationType.sweepAssignment:
        case ConfigurationType.sweepScheduledAssignment:
        case ConfigurationType.sweepAlert:
        case ConfigurationType.sweepMatching:
        case ConfigurationType.sweepDedupe:
          element = await get_automation(elementId, crmOrgId, true);
          break;
        case ConfigurationType.rollups:
          element = await get_rollup(elementId, crmOrgId, true);
          break;
        default:
          break;
      }

      if (element.readVersion) {
        dispatch(setLatestDeployment({ elementId, readOnlyElement: element.readVersion }));
        return;
      } else {
        telemetry.captureError(
          new Error('Read version was not returned for crmOrgId and element '),
          {
            crmOrgId,
            elementId,
          },
        );
        return;
      }
    },
    [dispatch, get_automation, get_rollup],
  );

  const handleCloseDependencies = useCallback(() => {
    dispatch(clearDependencies());
  }, [dispatch]);

  const onGoBackInHistory = useCallback(() => {
    dispatch(goBackInHistory());
  }, [dispatch]);

  return {
    handleSetConfigurationItem,
    getOpenAiDescriptionForRule,

    getConfigurationUsedBy,
    getConfigurationDependencies,
    getFieldConsumers,
    fetchLatestDeployedVersion,

    handleCloseDependencies,
    onGoBackInHistory,

    historyLength,
    dependenciesData,
    configurationItem,
    activeItemId: firstHistoryItem?.id
      ? firstHistoryItem.id
      : firstHistoryItem?.name + (firstHistoryItem?.objectName ?? ''),
  };
};
