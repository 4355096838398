import type { CommentThreadProps, MentionedUser } from '../../types/Comment';
import { Box, Popover } from '@mui/material';
import { useSelector } from 'react-redux';
import useComments from './useComments';
import { selectUserInfoData } from '../../reducers/userInfoReducer';
import { colors, IconButton, Typography } from '@sweep-io/sweep-design';
import CommentReply from './CommentReply';
import { AvatarPicture } from '../avatar/AvatarPicture';
import { DIMENSION_AVATAR_VERY_SMALL } from '../../constants';
import NewCommentReply from './NewCommentReply';
import { useState } from 'react';
import sortBy from 'lodash/sortBy';
import { Close } from '@sweep-io/sweep-design/dist/icons';
import uniqBy from 'lodash/uniqBy';
import keyBy from 'lodash/keyBy';
import { selectAccountUsers } from '../../reducers/accountUsersReducer';
import { isAllowedToResolve } from './helper';
import ResolveButton from './ResolveButton';
import UnreadButton from './UnreadButton';
import DeleteCommentDialog from './DeleteCommentDialog';
import { getIsUserInactive } from '../../lib/getIsUserActive';

const gapBetweenIconAndThreadHorizontal = -40;
const gapBetweenIconAndThreadVertical = 38;

const initialDeleteCommentState = {
  isConfirmDialogOpen: false,
  commentReplyIdToDelete: '',
};

const CommentThread = ({
  commentThread,
  closeCommentThread,
  anchorEl,
}: {
  commentThread: CommentThreadProps;
  closeCommentThread: () => void;
  anchorEl?: HTMLDivElement;
}) => {
  const { commentReplies } = commentThread;
  const [replyIdWithMenuOpen, setReplyIdWithMenuOpen] = useState<string | null>();
  const [deleteCommentState, setDeleteCommentState] = useState(initialDeleteCommentState);
  const { addReply, deleteReply, editReply, deleteThread, updateThread, updateThreadIsRead } =
    useComments();
  const currentUser = useSelector(selectUserInfoData);
  const { isRead, isResolved } = commentThread;
  const allowedToResolve = isAllowedToResolve(commentReplies, currentUser?.id);
  const accountUsers = keyBy(useSelector(selectAccountUsers), 'id');
  const participants = uniqBy(
    commentThread.commentReplies.map((reply) => accountUsers[reply.createdById]),
    'id',
  );

  const commentRepliesSorted = sortBy(commentReplies, (reply) => reply.createdAt);

  const updateResolveCommentThread = (isResolved: boolean) => {
    updateThread({
      commentThreadId: commentThread.id,
      funnelMapId: commentThread.funnelMapId,
      isResolved,
    });
  };

  const onAddReply = (commentBody: string, mentionedUserIds: MentionedUser[]) => {
    if (currentUser?.id) {
      addReply({
        commentThreadId: commentThread.id,
        funnelMapId: commentThread.funnelMapId,
        commentBody,
        userId: currentUser.id,
        mentionedUserIds,
        isRead: true,
      });
    }
  };

  const onDeleteReply = (replyId: string) => {
    deleteReply({
      commentThreadId: commentThread.id,
      funnelMapId: commentThread.funnelMapId,
      commentReplyId: replyId,
    });
  };

  const onDeleteThread = () => {
    deleteThread({
      commentThreadId: commentThread.id,
      funnelMapId: commentThread.funnelMapId,
    });
  };

  const onEditReply =
    (replyId: string) => (updatedBody: string, mentionedUserIds: MentionedUser[]) => {
      editReply({
        commentThreadId: commentThread.id,
        funnelMapId: commentThread.funnelMapId,
        commentReplyId: replyId,
        commentBody: updatedBody,
        mentionedUserIds,
      });
    };

  const markAsUnread = () => {
    updateThreadIsRead({
      commentThreadId: commentThread.id,
      funnelMapId: commentThread.funnelMapId,
      isRead: false,
    });
  };

  const onConfirmDelete = () => {
    if (deleteCommentState.commentReplyIdToDelete) {
      onDeleteReply(deleteCommentState.commentReplyIdToDelete);
    } else {
      onDeleteThread();
    }
  };

  return (
    <>
      {deleteCommentState.isConfirmDialogOpen && (
        <DeleteCommentDialog
          handleClose={() => setDeleteCommentState(initialDeleteCommentState)}
          onDelete={onConfirmDelete}
        />
      )}
      <Popover
        open={Boolean(anchorEl)}
        id="comment-popover"
        onClose={closeCommentThread}
        anchorEl={anchorEl}
        elevation={1}
        slotProps={{ paper: { sx: { padding: 1, maxWidth: '400px' } } }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: gapBetweenIconAndThreadVertical,
          horizontal:
            participants.length === 1
              ? gapBetweenIconAndThreadHorizontal
              : gapBetweenIconAndThreadHorizontal * 1.5,
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between" p={1}>
          <Typography variant="caption" color={colors.grey[800]}>
            Comment thread
          </Typography>
          <Box display="flex">
            {!isResolved && <UnreadButton markAsUnread={markAsUnread} isRead={Boolean(isRead)} />}
            {allowedToResolve && (
              <ResolveButton
                isResolved={isResolved}
                updateResolveCommentThread={updateResolveCommentThread}
              />
            )}
            <IconButton variant="flat" onClick={closeCommentThread}>
              <Close />
            </IconButton>
          </Box>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          sx={{
            maxHeight: '70vh',
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
        >
          {commentRepliesSorted.map((reply, index) => (
            <CommentReply
              key={reply.id ?? `reply-${index}`}
              reply={reply}
              deleteReplyCb={() => {
                setReplyIdWithMenuOpen(null);
                setDeleteCommentState({
                  isConfirmDialogOpen: true,
                  commentReplyIdToDelete: index === 0 ? '' : reply.id ?? '',
                });
              }}
              deleteText={index === 0 ? 'Delete entire thread' : 'Delete'}
              editReplyCb={onEditReply(reply.id ?? '')}
              isMenuOpen={replyIdWithMenuOpen === reply.id}
              openMenu={() => setReplyIdWithMenuOpen(reply.id)}
              closeMenu={() => setReplyIdWithMenuOpen(null)}
            />
          ))}
          <Box padding={1}>
            <AddReply onAddReply={onAddReply} />
          </Box>
        </Box>
      </Popover>
    </>
  );
};

const AddReply = ({
  onAddReply,
}: {
  onAddReply: (commentBody: string, mentionedUserIds: MentionedUser[]) => void;
}) => {
  const currentUserInfo = useSelector(selectUserInfoData);

  return (
    <Box display="flex" gap={1.5} alignItems="center">
      <AvatarPicture
        avatar={{ emoji: currentUserInfo?.emoji, imageUrl: currentUserInfo?.imageUrl }}
        dimension={DIMENSION_AVATAR_VERY_SMALL}
        clickable={false}
        isInactive={getIsUserInactive(currentUserInfo?.status)}
      />
      <NewCommentReply onConfirm={onAddReply} placeholder="Reply" />
    </Box>
  );
};

export default CommentThread;
