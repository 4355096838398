import { Box } from '@mui/material';
import { Button, IconButton } from '@sweep-io/sweep-design';
import { Close as CloseIcon } from '@sweep-io/sweep-design/dist/icons';

interface CreateEditDialogButtonsProps {
  confirmButtonContent: React.ReactNode;
  confirmButtonStartIcon?: string;
  onConfirm: (any: any) => any;
  confirmButtonDisabled?: boolean;
  showCancelButton?: boolean;
  onCancel?: () => any;
}

export const CreateEditDialogButtons = ({
  confirmButtonContent,
  confirmButtonStartIcon,
  onConfirm,
  confirmButtonDisabled,
  showCancelButton,
  onCancel: onCancelClick,
}: CreateEditDialogButtonsProps) => {
  return (
    <Box display="flex" gap={1}>
      <Button
        onClick={onConfirm}
        disabled={confirmButtonDisabled}
        startIconName={confirmButtonStartIcon}
      >
        {confirmButtonContent}
      </Button>
      {showCancelButton && (
        <IconButton variant="flat" onClick={onCancelClick} data-dd-action-name="close">
          <CloseIcon />
        </IconButton>
      )}
    </Box>
  );
};
