import { useCallback, useEffect, useState } from 'react';
import { Button, Grid, TableCell, TableRow, Box } from '@mui/material';
import { Typography, __SWEEP_DESIGN_TYPOGRAPHY_STYLES___, colors } from '@sweep-io/sweep-design';
import { appRoutes } from '../../../constants/appRoutes';
import { TruncatedTextTooltip } from '../../common/TruncatedTextTooltip';
import StyledTooltip from '../../common/StyledTooltip';
import useSweepNavigate from '../../common/useSweepNavigate';
import { uniqueId } from '../../../lib/uniqueId';
import { EditableLabel } from '../../common/EditableLabel';
import buildURLQuery from '../../../lib/buildURLQuery';
import { dataTableVariants } from '../../common/table/dataTableVariants';
import { DataTableVariant } from '../../common/table/TableTypes';
import TableCellLockedWidth from '../../common/table/TableCellLockedWidth';

export const funnelTableHeaders: FunnelTableHeaderProps[] = [
  { headerName: '#', field: 'index', width: '8%' },
  { headerName: 'Name', field: 'snapshotName', width: '18%' },
  { headerName: 'Date', field: 'createdAt', width: '20%' },
  { headerName: 'By', field: 'createdBy', width: '15%' },
  { headerName: 'Environment', field: 'environment', width: '15%' },
  { headerName: 'Steps', field: 'steps', width: '8%' },
  { headerName: 'Nurturing Buckets', field: 'nurtureBuckets', width: '10%' },
  { headerName: '', field: 'buttonArea', width: '8%' },
];

const FunnelVersionsTableRow = ({
  row,
  handleNameChange,
  onNavigateCallback,
  editableLabels,
}: FunnelVersionsTableRowProps) => {
  const { navigate } = useSweepNavigate();
  const [textFieldValue, setTextFieldValue] = useState(row.snapshotName);
  useEffect(() => {
    setTextFieldValue(row.snapshotName);
  }, [row.snapshotName]);

  const handleFinishEdit = (newName: string) => {
    if (newName?.length) {
      setTextFieldValue(newName);
      handleNameChange && handleNameChange(newName, row.id ?? '');
    } else {
      setTextFieldValue(row.snapshotName || '');
    }
  };

  const onViewClick = useCallback(() => {
    const baseRoute = appRoutes.canvasUnitedSnapshot;
    const route = `${baseRoute}/${row.funnelId}?${buildURLQuery({
      snapshotId: row.id,
      crmOrgId: row.crmOrgId,
      deploymentId: row.deploymentId,
      enableRestore: true,
    })}`;
    navigate(route);
    onNavigateCallback && onNavigateCallback();
  }, [navigate, onNavigateCallback, row.crmOrgId, row.deploymentId, row.funnelId, row.id]);

  return (
    <TableRow
      component={TableRow}
      key={`main_row_${uniqueId()}`}
      sx={{
        cursor: 'default',
        '& .MuiTableCell-root': {
          transition: '.5s',
        },
        '.MuiTableCell-root.row-index': {
          color: colors.grey[500],
        },
        '.row-environment, .row-environment circle': {
          color: colors.grey[500],
        },
      }}
    >
      {funnelTableHeaders.map(({ field }: { field: keyof FunnelTableHeaderPropsExtended }) => {
        if (field === 'buttonArea') {
          return (
            <TableCell
              key={uniqueId()}
              align={'right'}
              component={'th'}
              sx={{
                padding: '6px',
                '& span': {
                  display: 'inline-block',
                },
              }}
            >
              {!row.isOriginal && (
                //Box is to overcome the minWidth of the Button, which is too wide and casing horizontal scroll
                <Box maxWidth="100%" overflow="hidden">
                  <Button variant="link" onClick={onViewClick}>
                    View
                  </Button>
                </Box>
              )}
            </TableCell>
          );
        }

        return (
          <RenderCell
            key={uniqueId() + field}
            field={field}
            row={row}
            handleFinishEdit={handleFinishEdit}
            editableLabels={editableLabels || []}
            textFieldValue={textFieldValue}
          />
        );
      })}
    </TableRow>
  );
};

const RenderCell = ({
  field,
  row,
  editableLabels,
  handleFinishEdit,
  textFieldValue,
}: {
  field: any;
  row: FunnelVersionData;
  editableLabels: string[];
  handleFinishEdit: (newName: string) => void;
  textFieldValue: string;
}) => {
  switch (field) {
    case 'createdAt':
      return (
        <TableCellLockedWidth column={field} variant={DataTableVariant.default}>
          <StyledTooltip
            title={(row.isDeployment ? 'Deployed on ' : 'Last edited on ') + row.createdAt}
            placement={'top-start'}
            sx={{
              '& .MuiTooltip-tooltipPlacementTop': {
                marginBottom: '10px !important',
              },
            }}
          >
            <Box minWidth={0} display="flex">
              <Typography
                ellipsis
                variant={dataTableVariants[DataTableVariant.default].fontVariant}
              >
                {row.createdAt}
              </Typography>
            </Box>
          </StyledTooltip>
        </TableCellLockedWidth>
      );

    case 'environment':
      return (
        <TableCellLockedWidth column={field} variant={DataTableVariant.default}>
          <Grid container alignItems="center" flexWrap="nowrap" gap={0.5}>
            <TruncatedTextTooltip variant={dataTableVariants[DataTableVariant.default].fontVariant}>
              {row.environment}
            </TruncatedTextTooltip>
          </Grid>
        </TableCellLockedWidth>
      );

    default:
      if (editableLabels?.includes(field)) {
        return (
          <TableCellLockedWidth column={field} variant={DataTableVariant.default}>
            <EditableLabel
              padding="0"
              value={textFieldValue}
              readonly={row?.isOriginal}
              onValueConfirm={handleFinishEdit}
              minCharLength={1}
              inputSx={{
                fontSize:
                  __SWEEP_DESIGN_TYPOGRAPHY_STYLES___[
                    dataTableVariants[DataTableVariant.default].fontVariant
                  ]?.fontSize,
              }}
            />
          </TableCellLockedWidth>
        );
      }

      return (
        <TableCellLockedWidth column={field} variant={DataTableVariant.default}>
          <TruncatedTextTooltip variant={dataTableVariants[DataTableVariant.default].fontVariant}>
            {row[field as keyof FunnelVersionData]}
          </TruncatedTextTooltip>
        </TableCellLockedWidth>
      );
  }
};

export { FunnelVersionsTableRow };
