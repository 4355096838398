import { useState } from 'react';
import CopilotButton from './CopilotButton';
import DashboardRightPanel from './DashboardRightPanel';
import CopilotChatConnected from '../../copilot/CopilotChatConnected';

const CopilotChatDashboard = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openCopilot = () => {
    setIsOpen(true);
  };

  const closeCopilot = () => {
    setIsOpen(false);
  };

  return (
    <>
      <CopilotButton onClick={openCopilot} variant="primary" />
      <DashboardRightPanel isOpen={isOpen} closePanel={closeCopilot}>
        <CopilotChatConnected closeCopilot={closeCopilot}/>
      </DashboardRightPanel>
    </>
  );
};

export default CopilotChatDashboard;
