import { Box } from '@mui/material';
import { AutomationDialogContent } from '../../../../Automations/AutomationDialogContent';
import { AutomationFormVariant } from '../../../../../types/enums/AutomationFormVariant';
import { useCallback, useState } from 'react';
import useAutomationApiPerType from '../../../../Automations/useAutomationApiPerType';
import { selectFunnelsData } from '../../../../../reducers/multiFunnelFlowCanvasReducer';
import { useSelector } from 'react-redux';
import { selectDefaultCreationEnvironment } from '../../../environments/environmentsReducer';
import { assignmentsSortOptions, automationsSortOptions } from '../../../canvas-pages/consts';
import {
  AutomationsContextProvider,
  AutomationsContextType,
} from '../../../../Automations/AutomationsContext';

interface RightPanelAutomationsTabProps {
  readonly?: boolean;
  funnelDetails: FunnelDetails;
  automationVariant: AutomationFormVariant;
  automationContextData: AutomationsContextType;
  automations?: AutomationStructureNew[];
  onCreateData?: { newElement: Partial<AutomationStructureNew> };
  excludeAutomationsNotInFunnelMapObjects?: boolean;
}

export const RightPanelAutomationsTab = ({
  funnelDetails,
  automationVariant,
  automationContextData,
  automations,
  onCreateData,
  excludeAutomationsNotInFunnelMapObjects,
}: RightPanelAutomationsTabProps) => {
  const defaultCreationEnvironment = useSelector(selectDefaultCreationEnvironment);
  const crmOrgId = defaultCreationEnvironment?.id;
  const { onSaveOrCreate, onToggleActivation, onDeleteAutomation, onDeployAutomation } =
    useAutomationApiPerType({
      automationVariant,
    });
  const funnelsData = useSelector(selectFunnelsData);

  const [selectedFilters, setSelectedFilters] = useState<AutomationDialogFilters>({
    recordTypesIds: [],
    stepNames: [],
    searchText: '',
  });

  const _setSelectedFilters = useCallback((filters: Partial<AutomationDialogFilters>) => {
    setSelectedFilters((prev) => ({ ...prev, ...filters }));
  }, []);

  return (
    <Box flex={1}>
      {crmOrgId && (
        <AutomationsContextProvider value={automationContextData}>
          <AutomationDialogContent
            onSaveOrCreate={onSaveOrCreate}
            onToggleActivation={onToggleActivation}
            onDeleteAutomation={onDeleteAutomation}
            automations={automations ?? []}
            crmOrgId={crmOrgId}
            recordTypesData={{}} // "funnel map canvas" doesn't have recordTypesData (only "configuration canvas" has)
            funnelsData={funnelsData}
            sortOptions={
              automationVariant === AutomationFormVariant.ASSIGNMENT_RULES
                ? assignmentsSortOptions
                : automationsSortOptions
            }
            selectedFilters={selectedFilters}
            setSelectedFilters={_setSelectedFilters}
            disableCanvasTemplates
            onCreateData={onCreateData}
            excludeAutomationsNotInFunnelMapObjects={excludeAutomationsNotInFunnelMapObjects}
            filterFunnelIds={funnelsData ? Object.keys(funnelsData) : undefined}
            objectType={funnelDetails.leadingObject.objectName}
            hideFilters={true}
            onDeployAutomation={onDeployAutomation}
            isShowWideScreenPopup={true}
          />
        </AutomationsContextProvider>
      )}
    </Box>
  );
};
