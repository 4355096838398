import { fontFamily } from '@sweep-io/sweep-design/dist/theme/typography';
import { colors } from '@sweep-io/sweep-design';
import { ReactDiffViewerStylesOverride } from 'react-diff-viewer-continued';

export default {
  variables: {
    light: {
      diffViewerTitleBackground: colors.white,
      wordAddedBackground: colors.mint[300],
      addedBackground: colors.mint[100],
      wordRemovedBackground: colors.blush[300],
      removedBackground: colors.blush[100],
    },
  },
  marker: {
    background: colors.grey[100],
    'pre': {
      color: `${colors.grey[700]} !important`,
      fontSize: '20px',
    },
  },
  titleBlock: {
    'pre': {
      color: colors.black,
      fontSize: '14px',
      fontWeight: 600,
    }
  },
  contentText: {
    fontFamily,
    fontSize: '14px',
    paddingLeft: '10px',
    letterSpacing: 0,
    '.diff-section-title': {
      fontWeight: 500,
      paddingTop: '8px',
      display: 'flex',
      flexDirection: 'row',
    },
    '.diff-section-content': {
      paddingLeft: '18px'
    }
  }
} as ReactDiffViewerStylesOverride;
