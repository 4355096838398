import { useEffect, useMemo, useState } from 'react';
import { useCrmOrgsApiFacade } from '../../../apis/facades/useCrmOrgsApiFacade';
import { SearchSelect, SearchSelectItem } from '../SearchSelect';
import { ObjectTypeChip } from '../ObjectTypeChip';
import { highlightMatch } from '../../../lib/highlightMatch';

interface SweepObjectSelectorProps {
  crmOrgId: string;
  value?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  fullWidth?: boolean;
  filterBy?: (objectType: ObjectTypeName) => boolean;
}

const promotedObjectTypes = ['Lead', 'Account', 'Opportunity', 'Contact', 'Case'];

export const SweepObjectSelector = ({
  crmOrgId,
  onChange,
  value,
  disabled,
  fullWidth,
  filterBy,
}: SweepObjectSelectorProps) => {
  const { get_crmOrgsObjectTypes } = useCrmOrgsApiFacade();
  const [objectTypes, setObjectTypes] = useState<ObjectTypeName[]>([]);

  const objectTypesSet = useMemo(
    () => new Set(objectTypes.map(({ objectType }) => objectType)),
    [objectTypes],
  );

  useEffect(() => {
    const fetchObjectTypes = async () => {
      let { objectTypes } = await get_crmOrgsObjectTypes({ orgId: crmOrgId });
      if (filterBy) {
        objectTypes = objectTypes.filter((ot) => value === ot.objectType || filterBy(ot));
      }
      setObjectTypes(objectTypes);
    };
    if (crmOrgId) {
      fetchObjectTypes();
    }
  }, [crmOrgId, filterBy, get_crmOrgsObjectTypes, value]);

  const allObjects = objectTypes.filter(
    (objectType) => !promotedObjectTypes.includes(objectType.objectType),
  );

  const promotedItems: SearchSelectItem<ObjectTypeName>[] = promotedObjectTypes
    .filter((objectType) => objectTypesSet.has(objectType))
    .map((objectType) => ({
      label: objectType,
      value: objectType,
    }));

  const allItems: SearchSelectItem<ObjectTypeName>[] = allObjects.map((objectType) => ({
    label: objectType.label,
    value: objectType.objectType,
  }));

  const items: SearchSelectItem<ObjectTypeName>[] = [];
  if (promotedItems.length > 0) {
    items.push(
      ...[
        {
          label: 'Promoted',
          value: 'Promoted',
          isSection: true,
        },
        ...promotedItems,
      ],
    );
  }
  if (allItems.length > 0) {
    items.push(
      ...[
        {
          label: 'All',
          value: 'All',
          isSection: true,
        },
        ...allItems,
      ],
    );
  }

  return (
    <SearchSelect
      items={items}
      onChange={(item) => onChange && onChange(item.value)}
      SelectProps={{
        value,
        placeholder: 'e.g Lead, Opportunity',
      }}
      FormControlProps={{
        fullWidth,
      }}
      dataTestId="sweep-object-selector"
      itemRenderer={(item, search) => (
        <ObjectTypeChip objectType={item.label} label={highlightMatch(item.label, search)} />
      )}
      disabled={disabled}
    />
  );
};
