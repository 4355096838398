import { Button, colors, Typography } from '@sweep-io/sweep-design';
import { InfoDialog } from '../../dialogs/InfoDialog';
import { FlexBox } from '../../FlexBox';
import { Check } from '@sweep-io/sweep-design/dist/icons';
import { Box } from '@mui/material';
import { selectDefaultCreationEnvironment } from '../../../pages/environments/environmentsReducer';
import { useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { useCrmOrgs } from '../../../pages/environments/useCrmOrgs';
import { waitForWindowToClose } from '../../../pages/helper';
import { telemetry } from '../../../../telemetry';
import { retry } from 'ts-retry-promise';
import AssignPermissionSetButton from '../../assign-permission-set/AssignPermissionSetButton';
import { useRunOnceWhenTruthy } from '../../useRunOnceWhenTruthy';
import pluralize from 'pluralize';
import { MP_VERSION } from './consts';
import useSendBiEvent from '../../../../hooks/useSendBiEvent';
import { UI_EVENTS } from '../../../../services/events';
import { useRunOnce } from '../../useRunOnce';

const MP_CHECK_INTERVAL = 2000;
const MP_CHECK_TIMEOUT = 40000;

const InstallItem = ({
  bulletNumber,
  text,
  complete,
  children,
}: {
  bulletNumber: number;
  text: string;
  complete?: boolean;
  children?: React.ReactNode;
}) => {
  const textDecoration: any = complete ? 'line-through' : 'none';

  return (
    <Box height="56px" display="flex" flexDirection="column" alignContent="center">
      <FlexBox alignItems="center" alignContent={'space-around'}>
        <FlexBox alignItems="center" gap={1}>
          <Box width="24px">
            {complete ? (
              <Check color={colors.blue[500]} />
            ) : (
              <Typography variant="body">{bulletNumber}.</Typography>
            )}
          </Box>
          <Typography variant="body" color={colors.grey[800]} textDecoration={textDecoration}>
            {text}
          </Typography>
        </FlexBox>
        {!complete && children}
      </FlexBox>
    </Box>
  );
};

const useVerifyMpInstallation = () => {
  const { getCrmOrg } = useCrmOrgs();

  const asyncVerifyMpInstallation = async (crmOrgId: string) => {
    const getConnectedCrmOrg = async () => {
      try {
        const _crmOrg = await getCrmOrg(crmOrgId, true);
        return _crmOrg.isManagedPackageInstalled;
      } catch (err) {
        telemetry.captureError(err);
      }
      return false;
    };
    try {
      // Retry until the managed package is installed
      // or until the timeout is reached
      const isMpInstalled = await retry(getConnectedCrmOrg, {
        until: (isMpInstalled) => isMpInstalled,
        timeout: MP_CHECK_TIMEOUT,
        delay: MP_CHECK_INTERVAL,
      });
      return isMpInstalled;
    } catch (err) {
      telemetry.captureError(err);
    }
    return false;
  };

  return { asyncVerifyMpInstallation };
};

const InstallMpButton = () => {
  const crmOrg = useSelector(selectDefaultCreationEnvironment);
  const isManagedPackageInstalled = crmOrg?.isManagedPackageInstalled;
  const instanceUrl = crmOrg?.instanceUrl;
  const [isLoading, setIsLoading] = useState(false);

  const { asyncVerifyMpInstallation } = useVerifyMpInstallation();

  const onInstallMpClick = useCallback(async () => {
    if (!crmOrg?.id) {
      return;
    }
    const _window = window.open(
      `${instanceUrl}/packaging/installPackage.apexp?p0=${MP_VERSION}`,
      '_blank',
    );
    if (_window) {
      setIsLoading(true);
      await waitForWindowToClose(_window);
      await asyncVerifyMpInstallation(crmOrg?.id);
      setIsLoading(false);
    }
  }, [crmOrg?.id, instanceUrl, asyncVerifyMpInstallation]);

  useEffect(() => {
    if (isManagedPackageInstalled) {
      setIsLoading(false);
    }
  }, [isManagedPackageInstalled]);

  if (!crmOrg) {
    return null;
  }

  return (
    <>
      <Button
        onClick={onInstallMpClick}
        disabled={isManagedPackageInstalled || isLoading}
        variant="filled"
        size="small"
      >
        Install
      </Button>
    </>
  );
};

export const CompleteSfSetupDialog = ({
  cancelDialog,
  entityName,
  onCompleteItemsCallback,
  title,
}: {
  cancelDialog: () => void;
  entityName: string;
  onCompleteItemsCallback: () => void;
  title?: string;
}) => {
  const crmOrg = useSelector(selectDefaultCreationEnvironment);
  const isManagedPackageInstalled = crmOrg?.isManagedPackageInstalled;
  const isPermissionSetGroupAssigned = crmOrg?.isPermissionSetGroupAssigned;
  const sendBiEvent = useSendBiEvent();

  useRunOnceWhenTruthy(
    onCompleteItemsCallback,
    Boolean(isManagedPackageInstalled && isPermissionSetGroupAssigned),
  );

  const titleJsx = title ?? `Deploy your saved ${pluralize(entityName)} 🎉`;
  useRunOnce(() => {
    sendBiEvent({ name: UI_EVENTS.completeSetupOpen });
  });

  return (
    <InfoDialog
      handleClose={(e) => {
        e.stopPropagation();
        cancelDialog();
      }}
      open={true}
      PaperPropsSx={{ width: '592px', height: '320px' }}
      showCloseButton
      titleJsx={<Typography variant="h1">{titleJsx}</Typography>}
      dialogContentSx={{ padding: '0 24px' }}
    >
      <Typography variant="body" color={colors.grey[800]}>
        Complete the following 3 simple steps to see it live in Salesforce:
      </Typography>
      <FlexBox sx={{ marginTop: 3 }} flexDirection="column">
        <InstallItem bulletNumber={1} text="Connect to Salesforce" complete />
        <InstallItem
          bulletNumber={2}
          text="Install the Managed Package"
          complete={isManagedPackageInstalled}
        >
          <InstallMpButton />
        </InstallItem>
        <InstallItem
          bulletNumber={3}
          text="Assign Sweep Permission Set Group"
          complete={isPermissionSetGroupAssigned}
        >
          <AssignPermissionSetButton />
        </InstallItem>
      </FlexBox>
    </InfoDialog>
  );
};
