import { useCallback, useEffect, useState } from 'react';
import { Box, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { DateTime } from 'luxon';
import { BaseDialog } from '../../../common/dialogs/BaseDialog';
import { Button, Typography, colors } from '@sweep-io/sweep-design';
import {
  TimezoneSelector,
  getSystemTimezone,
} from '../../../common/timezone-selector/TimezoneSelector';
import { FlexBox } from '../../../common/FlexBox';
import { DatetimeSelector } from '../../../common/rule-builder/selectors/DatetimeSelector';
import { changeDateTimeTimezone } from './helpers';
import SweepTextField from '../../../common/SweepTextField';

interface AddTimeOffDialogProps {
  user: { name: string };
  timeOff?: CrmOrgUserTimeOff;
  onConfirm: (
    description: string,
    startDate: string,
    endDate: string,
    timezone: string,
  ) => void | Promise<void>;
  onClose: () => void;
}

export const AddTimeOffDialog = ({ user, onConfirm, onClose, timeOff }: AddTimeOffDialogProps) => {
  const [description, setDescription] = useState<string>(timeOff ? timeOff.description : '');
  const [timezone, setTimezone] = useState(() =>
    timeOff ? timeOff.timezone : getSystemTimezone(),
  );
  // date time now in the selected timezone
  const dateTimeNowInTimezone = DateTime.now()
    .setZone(timezone)
    .setZone(getSystemTimezone(), { keepLocalTime: true });

  const [startDate, setStartDate] = useState<DateTime | null>(() =>
    timeOff ? DateTime.fromISO(timeOff.startDate, { zone: timezone }) : dateTimeNowInTimezone,
  );
  const [endDate, setEndDate] = useState<DateTime | null>(() =>
    timeOff ? DateTime.fromISO(timeOff.endDate, { zone: timezone }) : DateTime.now().endOf('day'),
  );

  const _setTimezone = useCallback(
    (tz: string) => {
      setStartDate(null);
      setEndDate(null);
      setTimezone(tz);
    },
    [setTimezone],
  );

  const calculateCurrentValue = useCallback(
    () => ({
      startDate: startDate ? changeDateTimeTimezone(startDate, timezone) : null,
      endDate: endDate ? changeDateTimeTimezone(endDate, timezone) : null,
    }),
    [endDate, startDate, timezone],
  );

  const [currentValue, setCurrentValue] = useState(() => calculateCurrentValue());

  useEffect(() => {
    setCurrentValue(calculateCurrentValue());
  }, [calculateCurrentValue]);

  const isValid =
    currentValue.startDate && currentValue.endDate && currentValue.startDate < currentValue.endDate;

  return (
    <BaseDialog title={`Time off for  ${user.name}`} open={true} onClose={() => {}}>
      <DialogTitle sx={{ paddingBottom: 3 }}>Time off for {user.name}</DialogTitle>
      <DialogContent>
        <Box width="529px">
          <FlexBox flexDirection="column" gap={2}>
            <FlexBox flexDirection="column" gap={3}>
              <SweepTextField
                value={description}
                onChange={(e) => {
                  setDescription(e?.target.value || '');
                }}
                label="Description (optional)"
              />
              <FlexBox gap={1} alignItems="center" flexDirection="row">
                <DatetimeSelector
                  label="Start date"
                  shouldBeDisabled={false}
                  onChange={setStartDate}
                  value={startDate}
                  minDateTime={dateTimeNowInTimezone}
                />
                <DatetimeSelector
                  label="End date"
                  shouldBeDisabled={false}
                  onChange={setEndDate}
                  value={endDate ?? undefined}
                  minDateTime={startDate ?? dateTimeNowInTimezone}
                />
              </FlexBox>
            </FlexBox>
            <FlexBox gap={1} alignItems="center">
              <Typography variant="caption" color={colors.grey[800]}>
                Timezone:
              </Typography>
              <TimezoneSelector timezone={timezone} onChange={_setTimezone} />
            </FlexBox>
          </FlexBox>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={!isValid}
          onClick={() => {
            isValid &&
              onConfirm(
                description,
                currentValue.startDate?.toISO() || '',
                currentValue.endDate?.toISO() || '',
                timezone,
              );
          }}
        >
          Save
        </Button>
      </DialogActions>
    </BaseDialog>
  );
};
