import { Box } from '@mui/material';
import { Typography, colors } from '@sweep-io/sweep-design';
import { SlackConnectButton } from './SlackConnectButton';
import { SlackLogo, HubspotLogo } from '../../../icons/generalIcons';
import { ReactNode } from 'react';
import { HubspotConnectButton } from './HubspotConnectButton';
import { useFeatureToggle } from '../../common/useFeatureToggle';
import { CloudingoConnectButton } from './CloudingoConnectButton';

export const IntegrationsTab = () => {
  const { hubspotIntegration } = useFeatureToggle();
  return (
    <Box sx={{ paddingTop: '16px' }}>
      <Typography variant="h2">Integrations</Typography>
      <IntegrationOption
        name="Slack"
        logo={<SlackLogo />}
        description="Add Salesforce Alerts to Monitor your GTM Playbooks"
        actionButton={<SlackConnectButton />}
      />

      {hubspotIntegration && (
        <IntegrationOption
          name="Hubspot"
          logo={<HubspotLogo />}
          description="Connect HubSpot to visualize funnels and manage campaign enrollments"
          actionButton={<HubspotConnectButton />}
        />
      )}
      <IntegrationOption
        name="Cloudingo"
        logo={
          <img
            src="https://asset.brandfetch.io/ido-ApkhlX/idpHL_OSQO.jpeg"
            width={40}
            height={40}
          />
        }
        description="Merge duplicates with Cloudingo"
        actionButton={<CloudingoConnectButton />}
      />
    </Box>
  );
};

const IntegrationOption = ({
  name,
  logo,
  description,
  actionButton,
}: {
  name: string;
  logo: ReactNode;
  description: string;
  actionButton: ReactNode;
}) => {
  return (
    <Box
      sx={{
        border: '1px solid',
        borderColor: colors.grey[300],
        borderRadius: '8px',
        padding: '20px',
        display: 'flex',
        flexDirection: 'row',
        gap: '24px',
        alignItems: 'center',
        marginTop: '32px',
        '&:hover': {
          borderColor: colors.blue[500],
        },
      }}
    >
      <Box sx={{ height: '40px' }}>{logo}</Box>
      <Box sx={{ flex: '1' }}>
        <Box sx={{ paddingBottom: '4px' }}>
          <Typography variant="h4">{name}</Typography>
        </Box>
        <Typography variant="body">{description}</Typography>
      </Box>
      {actionButton}
    </Box>
  );
};
