import { useSelector } from 'react-redux';
import { selectUserWalkthroughPoint } from '../../../reducers/userInfoReducer';
import { useCallback } from 'react';
import useUserInfo from '../../../hooks/useUserInfo';
import { WalkthroughPointType } from '@server/walkthrough';
import { useRunOnceWhenTruthy } from '../useRunOnceWhenTruthy';

const useWalkthroughPoint = ({
  point,
  shouldMarkAsCompleted,
}: {
  point?: WalkthroughPointType;
  shouldMarkAsCompleted?: boolean;
}) => {
  const isCompleted = useSelector(selectUserWalkthroughPoint(point));
  const { updateWalkthroughPointComplete } = useUserInfo();

  const markWalkthroughCompleted = useCallback(() => {
    if (point && !isCompleted) {
      updateWalkthroughPointComplete(point);
    }
  }, [point, updateWalkthroughPointComplete, isCompleted]);

  useRunOnceWhenTruthy(() => {
    markWalkthroughCompleted();
  }, !!shouldMarkAsCompleted && !!point);

  return {
    // showWalkthroughPoint: point && !isCompleted,
    showWalkthroughPoint: false, // Product asked to turn off the feature for now
    markWalkthroughCompleted,
  };
};

export default useWalkthroughPoint;
