import { Box, Stack } from '@mui/material';
import {
  ConfigurationToName,
  ConfigurationType,
  configurationTypeToIcon,
  NewDependencyProps,
} from '../dependencies/types';
import { SearchListItem } from './types';
import { colors, Tag, Typography } from '@sweep-io/sweep-design';
import { Virtuoso } from 'react-virtuoso';
import { TruncatedTextTooltip } from '../../common/TruncatedTextTooltip';
import { useExpandedMode } from '../../pages/configuration-canvas/panels-reserve-space/ReserveSpaceForPanelsCtx';
import { CenteredRowWithGap } from './atoms/CenteredRowWithGap';
import { WrapperWithDotDivider } from './molecules/WrapperWithDotDivider';
import { highlightMatch } from '../../../lib/highlightMatch';
import pluralize from 'pluralize';
import { SingleSelectFilterPopoverOption } from '../../common/single-select-filter/SingleSelectFilterPopover';

interface UniversalSearchListProps {
  configurationItems: SearchListItem[];
  onListItemClick: (props: NewDependencyProps) => void;
  activeItemId?: string;
  searchTxt: string;
  filterOptions: SingleSelectFilterPopoverOption[];
  onSelectedFilterItem: (key?: ConfigurationType) => void;
  selectedFilterValue?: string;
}

export const UniversalSearchList = ({
  configurationItems,
  onListItemClick,
  activeItemId,
  searchTxt,
  filterOptions,
  selectedFilterValue,
  onSelectedFilterItem,
}: UniversalSearchListProps) => {
  const { isExpandedMode } = useExpandedMode();
  const itemsCount = configurationItems.length;
  const selectedFilter = filterOptions.find(
    (filterOption) => filterOption.value === selectedFilterValue,
  );

  if (!itemsCount) {
    return <></>;
  }

  return (
    <Stack height="100%">
      <Box p={2} pt={0} display="flex" alignItems="center" gap={1}>
        {!!selectedFilter && (
          <Tag
            size="medium"
            color={colors.grey[200]}
            label={selectedFilter.label}
            onDelete={() => onSelectedFilterItem(undefined)}
          />
        )}
        <Typography color={colors.grey[700]} variant="body">
          {itemsCount} {pluralize('result', itemsCount)}
        </Typography>
      </Box>

      <Virtuoso
        style={{ height: '100%', width: '100%' }}
        data={configurationItems}
        itemContent={(index, item) => {
          return (
            <Box
              key={item.id}
              p={2}
              gap={0.5}
              sx={{
                width: '100%',
                background: activeItemId === item.id ? colors.grey[100] : 'initial',
                '&:hover': { background: colors.grey[100], cursor: 'pointer' },
              }}
              onClick={() =>
                onListItemClick({
                  id: item.id,
                  name: item.name,
                  dependencyType: item.type,
                  parentType: item.parentType,
                  objectName: item.objectName,
                  clearHistory: true,
                })
              }
            >
              <CenteredRowWithGap>
                {isExpandedMode && (
                  <Stack alignItems="center">{configurationTypeToIcon[item.parentType]}</Stack>
                )}
                <TruncatedTextTooltip variant="body" turnOffElipsis={isExpandedMode}>
                  {highlightMatch(item.label, searchTxt)}
                </TruncatedTextTooltip>
                <Box>
                  <Tag label={ConfigurationToName[item.parentType]} />
                </Box>
              </CenteredRowWithGap>

              <CenteredRowWithGap ml={isExpandedMode ? 3 : 0}>
                <TruncatedTextTooltip
                  variant="caption"
                  color={colors.grey[700]}
                  turnOffElipsis={isExpandedMode}
                >
                  API Name: {item.name}
                </TruncatedTextTooltip>

                {isExpandedMode && (
                  <>
                    <WrapperWithDotDivider txt={ConfigurationToName[item.parentType]} />
                    {item.lastModifiedAtAndBy && (
                      <WrapperWithDotDivider txt={item.lastModifiedAtAndBy} />
                    )}
                    {item.createdAtAndBy && <WrapperWithDotDivider txt={item.createdAtAndBy} />}
                  </>
                )}
              </CenteredRowWithGap>
            </Box>
          );
        }}
      />
    </Stack>
  );
};
