import { useLottie } from 'lottie-react';
import wandLoaderData from './json/wand_loader.json';
import globeData from './json/globe.json';
import reportingData from './json/reporting.json';
import usersData from './json/users.json';
import funnelsData from './json/funnels.json';
import notificationData from './json/notification.json';
import fileData from './json/file.json';
import rollupsData from './json/rollups.json';
import devOpsCenterData from './json/devops_center.json';
import documentationData from './json/documentation.json';
import automationsData from './json/automations.json';
import alertsData from './json/alerts.json';
import walkthroughPointData from './json/walkthrough_point.json';
import targetData from './json/target.json';

const LottieAnimation = ({ animationData, size }: { animationData: any; size: number }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const style = {
    height: size,
    width: size,
    display: 'flex',
  };

  const { View } = useLottie(defaultOptions, style);
  return View;
};

const WandLoader = ({ size }: { size: number }) => (
  <LottieAnimation animationData={wandLoaderData} size={size} />
);

const FunnelsAnimation = ({ size }: { size: number }) => (
  <LottieAnimation animationData={funnelsData} size={size} />
);

const GlobeAnimation = ({ size }: { size: number }) => (
  <LottieAnimation animationData={globeData} size={size} />
);

const ReportingAnimation = ({ size }: { size: number }) => (
  <LottieAnimation animationData={reportingData} size={size} />
);

const UsersAnimation = ({ size }: { size: number }) => (
  <LottieAnimation animationData={usersData} size={size} />
);

const NotificationAnimation = ({ size }: { size: number }) => (
  <LottieAnimation animationData={notificationData} size={size} />
);

const FileAnimation = ({ size }: { size: number }) => (
  <LottieAnimation animationData={fileData} size={size} />
);

const DocumentationAnimation = ({ size }: { size: number }) => (
  <LottieAnimation animationData={documentationData} size={size} />
);

const AutomationsAnimation = ({ size }: { size: number }) => (
  <LottieAnimation animationData={automationsData} size={size} />
);

const RollupsAnimation = ({ size }: { size: number }) => (
  <LottieAnimation animationData={rollupsData} size={size} />
);

const AlertsAnimation = ({ size }: { size: number }) => (
  <LottieAnimation animationData={alertsData} size={size} />
);

const DevOpsCenterAnimation = ({ size }: { size: number }) => (
  <LottieAnimation animationData={devOpsCenterData} size={size} />
);

const PointAnimation = ({ size }: { size: number }) => (
  <LottieAnimation animationData={walkthroughPointData} size={size} />
);

const TargetAnimation = ({ size }: { size: number }) => (
  <LottieAnimation animationData={targetData} size={size} />
);

export {
  WandLoader,
  GlobeAnimation,
  FunnelsAnimation,
  ReportingAnimation,
  UsersAnimation,
  NotificationAnimation,
  FileAnimation,
  RollupsAnimation,
  DevOpsCenterAnimation,
  DocumentationAnimation,
  AutomationsAnimation,
  AlertsAnimation,
  PointAnimation,
  TargetAnimation,
};
