import { memo } from 'react';
import { NodeProps } from 'reactflow';
import { getObjectTypeColor } from '../helpers/getObjectTypeColor';
import { GhostNodeData, NodeEntityTypes } from '../canvasTypes';

import { Button, __SWEEP_DESIGN_TYPOGRAPHY_STYLES___ } from '@sweep-io/sweep-design';

import { SweepNode } from './SweepNode';
import { NodeComponent } from './components/SweepNodeComponent';

const GhostNode = ({
  id,
  data: { objectType, onNodeClick, parentId },
}: NodeProps<GhostNodeData>) => {
  const { light: backgroundColor, connection: handleColor } = getObjectTypeColor(
    objectType as ObjectTypeValues,
  );

  return (
    <SweepNode>
      <NodeComponent
        sx={{
          background: backgroundColor,
          borderColor: handleColor,
          display: 'flex',
          alignItems: 'center',
          width: 'fit-content',

          padding: '0 12px',
          '& > .MuiButton-root': {
            ...__SWEEP_DESIGN_TYPOGRAPHY_STYLES___.h4,
          },
        }}
      >
        <Button
          variant="link"
          endIconName="ArrowRight"
          onClick={(e) => {
            onNodeClick?.({
              event: e,
              id,
              parentId,
              entity: {
                type: NodeEntityTypes.GhostStepButton,
              },
            });
          }}
        >
          Show Business Process
        </Button>
      </NodeComponent>
    </SweepNode>
  );
};

const _memoGhostNode = memo(GhostNode);

export { _memoGhostNode as GhostNode };
