import { useNodes, useReactFlow, useViewport } from 'reactflow';
import { Minus as ZoomMinusIcon } from '@sweep-io/sweep-design/dist/icons';
import { Plus as ZoomPlusIcon } from '@sweep-io/sweep-design/dist/icons';
import { Typography, Button, colors, IconButton } from '@sweep-io/sweep-design';
import { Box, Divider } from '@mui/material';
import { VerticalCenteredBox } from '../pages/environments/styledComponents';
import { useSweepCanvasFitView } from './useSweepCanvasFitView';

interface SweepCanvasControlsProps {
  onRedPillClick?: any;
  isPreview2?: boolean;
  noFitView?: boolean;
  controlsRightMargin?: number;
}

const SweepCanvasControls = ({
  onRedPillClick,
  isPreview2,
  noFitView,
  controlsRightMargin = 0,
}: SweepCanvasControlsProps) => {
  const reactFlowInstance = useReactFlow();
  const { zoom } = useViewport();
  const { zoomTo } = reactFlowInstance;
  const { fitView } = useSweepCanvasFitView();
  const nodes = useNodes();
  const disableFitViewButton = nodes.length === 0;
  const duration = 400;

  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: isPreview2 ? '8px' : '16px',
        right: (isPreview2 ? 8 : 16) + controlsRightMargin + 'px',
        zIndex: 10,
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '8px',
      }}
    >
      <VerticalCenteredBox
        sx={{
          backgroundColor: colors.white,
          padding: '7px 12px',
          border: `1px solid ${colors.grey[300]}`,
          borderRadius: '6px',
          boxShadow: '0px 2px 25px rgba(0, 0, 0, 0.1)',
          width: noFitView ? '105px' : '212px',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <VerticalCenteredBox sx={{ gap: 1 }}>
          <Typography color={colors.grey[800]} variant="body">
            {Math.round(zoom * 100)}%
          </Typography>

          <Box display="flex" justifyContent="center">
            <Box component="span" data-testid="zoom-out-button">
              <IconButton
                variant="flat"
                size="tiny"
                onClick={() => {
                  if (zoom <= 0.25) {
                    zoomTo(0.1, { duration });
                  } else {
                    zoomTo(zoom - 0.25, { duration });
                  }
                }}
              >
                <ZoomMinusIcon variant="default" />
              </IconButton>
            </Box>

            <IconButton
              variant="flat"
              size="tiny"
              onClick={() => {
                if (zoom <= 0.1) {
                  zoomTo(0.25, { duration });
                } else {
                  zoomTo(zoom + 0.25, { duration });
                }
              }}
              data-testid="zoom-in-button"
            >
              <ZoomPlusIcon variant="default" />
            </IconButton>
          </Box>
        </VerticalCenteredBox>

        {!noFitView && (
          <>
            <Divider orientation="vertical" variant="middle" flexItem sx={{ margin: '3px 4px' }} />

            <Box component="span" data-testid="fit-view-button">
              <Button
                variant="link"
                type="secondary"
                size="small"
                startIconName="Minimize"
                disabled={disableFitViewButton}
                onClick={() => {
                  fitView({ duration: 400 });
                }}
              >
                Fit View
              </Button>
            </Box>
          </>
        )}
      </VerticalCenteredBox>
      {onRedPillClick && (
        <Button
          variant="link"
          startIconName="SweepBug"
          onClick={() => {
            onRedPillClick();
          }}
        >
          Red pill
        </Button>
      )}
    </Box>
  );
};

export { SweepCanvasControls };
