import { Box } from '@mui/material';
import { Button } from '@sweep-io/sweep-design';
import {
  NestedFieldsSelector,
  NestedFieldsField,
} from '../../../../common/fieldsSelectors/NestedFieldsSelector';
import DraggableFieldSelector from '../../../../common/fieldsSelectors/DraggableFieldSelector';
import { useMemo, ReactNode, useCallback } from 'react';
import GridLayoutDnd, { GridItem } from '../../../../common/GridLayoutDnd';
import { useSweepFieldsLabels } from '../../../../../sweep-fields/useSweepFieldsLabels';
import { getNestedPath } from '../../../../Automations/helper';

const itemsPerRow = 2;

const AdditionalFields = ({
  additionalFields = [],
  onChange,
  crmOrgId,
  objectType,
  readonly,
}: {
  additionalFields?: AdditionalField[];
  onChange: (additionalFields: AdditionalField[]) => void;
  readonly: boolean;
  crmOrgId: string;
  objectType: string;
}) => {
  const addItem = (fieldToAdd: NestedFieldsField) => {
    const updatedArray = [
      ...additionalFields,
      {
        fieldIds: fieldToAdd.fieldIds,
        _fieldLabels: fieldToAdd.fieldLabels,
      },
    ];
    onChange(updatedArray);
  };

  const deleteItem = useCallback(
    (indexToDelete: number) => {
      const updatedArray = [...additionalFields];
      updatedArray.splice(indexToDelete, 1);
      onChange(updatedArray);
    },
    [additionalFields, onChange],
  );
  const { getLabelFromIdsWithObjectType } = useSweepFieldsLabels();

  const itemsToRender: GridItem[] = useMemo(
    () =>
      additionalFields.map((field, index) => {
        const id = field.fieldIds.join('-');
        const path = getNestedPath(field.fieldIds, getLabelFromIdsWithObjectType(field.fieldIds));

        const _onChange = (updatedField: NestedFieldsField) => {
          const updatedAdditionalFields = additionalFields.map((field, _index) => {
            return index === _index
              ? {
                  fieldIds: updatedField.fieldIds,
                  _fieldLabels: updatedField.fieldLabels,
                }
              : field;
          });
          onChange(updatedAdditionalFields);
        };

        return {
          id,
          value: field,
          renderFunction: (dragHandle: ReactNode, isOverlay: boolean) => (
            <DraggableFieldSelector
              readonly={readonly}
              crmOrgId={crmOrgId}
              objectType={objectType}
              nestedPath={path || []}
              onChange={(updatedField: NestedFieldsField) => {
                _onChange(updatedField);
              }}
              onDeleteCb={() => deleteItem(index)}
              dragHandle={dragHandle}
              isOverlay={isOverlay}
            />
          ),
        };
      }),
    [
      additionalFields,
      crmOrgId,
      deleteItem,
      getLabelFromIdsWithObjectType,
      objectType,
      onChange,
      readonly,
    ],
  );

  const renderCustomButton = useCallback((props: any) => {
    return (
      <Button key="add_fields" variant="link" startIconName="Plus" {...props}>
        Add fields
      </Button>
    );
  }, []);

  return (
    <Box>
      {!!itemsToRender.length && (
        <Box mb={'12px'} mt={'12px'}>
          <GridLayoutDnd
            items={itemsToRender}
            onReorder={onChange}
            itemsPerRow={itemsPerRow}
            readonly={readonly}
          />
        </Box>
      )}
      {!readonly && (
        <NestedFieldsSelector
          crmOrgId={crmOrgId}
          objectType={objectType}
          nestedPath={[]}
          onChange={(updatedField: NestedFieldsField) => {
            addItem(updatedField);
          }}
          CustomButtonComponent={renderCustomButton}
        />
      )}
    </Box>
  );
};

export default AdditionalFields;
