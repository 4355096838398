import {
  ConfigurationCanvasProvider,
  useConfigurationCanvasContext,
} from './ConfigurationCanvasContext';
import { ConfigurationPages } from './configurationPages';
import { PanelsProvider } from '../../panels/PanelsContext';
import { CanvasContextProvider } from '../../multi-canvas/CanvasContext';
import { useParams } from 'react-router';
import LoadingSweep from '../../common/LoadingSweep';
import { EnvironmentPageLoader, useLoadEnvironmentCanvas } from './EnvironmentPageLoader';
import { setEnvironmentFunnelMap } from '../../../reducers/global/globalReducer';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import noop from 'lodash/noop';
import { RecordTypeLeadingObjectProvider } from './canvas-container/record-type-leading-object-panel/recordTypeLeadingObjectPanelCtx';
import {
  setLeadingFieldForRecordType as setLeadingFieldForRecordTypeAction,
  setRecordTypeDataValues as setRecordTypeDataValuesAction,
  addRecordTypeDataEntries as addRecordTypeDataEntriesAction,
} from '../../../reducers/global/globalReducer';
import { EnvironmentCanvasPagesRoutes } from '../../../constants/appRoutes';
import { selectProductionCrmOrg } from '../environments/environmentsReducer';

const ConfigurationCanvasWrapper = ({ page }: { page: ConfigurationPages }) => {
  const { canvasCrmOrgId, showOnlyDeployedElementsInPills } = useConfigurationCanvasContext();

  if (!canvasCrmOrgId) return <LoadingSweep />;

  return (
    <EnvironmentPageLoader
      configurationPage={page}
      crmOrgId={canvasCrmOrgId}
      showOnlyDeployedElementsInPills={!!showOnlyDeployedElementsInPills}
    />
  );
};

interface ConfigurationCanvasProps {
  page: ConfigurationPages;
  showOnlyDeployedElementsInPills?: boolean;
  forceProduction?: boolean;
}

const InnerConfigurationCanvas = ({
  page,
  showOnlyDeployedElementsInPills,
  forceProduction,
}: ConfigurationCanvasProps) => {
  const dispatch = useDispatch();
  const params = useParams<{ crmOrgId: string }>();
  const productionOrgId = useSelector(selectProductionCrmOrg)?.id;

  let crmOrgId = params.crmOrgId ?? '';
  if (forceProduction && productionOrgId) {
    crmOrgId = productionOrgId;
  }

  const setCanvasFunnelMap = useCallback(
    (funnelMap: FunnelMap) => {
      dispatch(
        setEnvironmentFunnelMap({
          crmOrgId,
          funnelMap,
        }),
      );
    },
    [crmOrgId, dispatch],
  );

  const setLeadingFieldAndValuesForRecordType = useCallback(
    ({
      recordTypeApiName,
      leadingField,
      recordTypeDataValues,
      recordTypeName,
    }: {
      recordTypeApiName: string;
      recordTypeName: string;
      recordTypeDataValues: PicklistValue[];
      leadingField: string;
      crmOrgId: string;
    }) => {
      dispatch(
        setLeadingFieldForRecordTypeAction({
          crmOrgId,
          recordTypeApiName,
          recordTypeName,
        }),
      );
      dispatch(
        setRecordTypeDataValuesAction({
          crmOrgId,
          recordTypeDataValues,
          recordTypeApiName,
          leadingField,
        }),
      );
    },
    [crmOrgId, dispatch],
  );

  const addRecordTypeDataEntries = useCallback(
    ({ crmOrgId, recordTypes }: { crmOrgId: string; recordTypes: SingleRecordType[] }) => {
      dispatch(addRecordTypeDataEntriesAction({ crmOrgId, recordTypes }));
    },
    [dispatch],
  );

  const { envFunnelMap, loadedEnvMapId } = useLoadEnvironmentCanvas(crmOrgId);

  const envLoadedAndUpdated = crmOrgId && envFunnelMap && loadedEnvMapId === crmOrgId;

  if (!envLoadedAndUpdated && !envFunnelMap) return <LoadingSweep />;
  return (
    <CanvasContextProvider>
      <PanelsProvider confirmOpen={noop}>
        <RecordTypeLeadingObjectProvider
          setLeadingFieldAndValuesForRecordType={setLeadingFieldAndValuesForRecordType}
          addRecordTypeDataEntries={addRecordTypeDataEntries}
        >
          <ConfigurationCanvasProvider
            appCrmOrgId={crmOrgId}
            canvasCrmOrgId={crmOrgId}
            appFunnelMap={envFunnelMap}
            canvasFunnelMap={envFunnelMap}
            setCanvasFunnelMap={setCanvasFunnelMap}
            onSweepElementsChange={noop}
            showOnlyDeployedElementsInPills={!!showOnlyDeployedElementsInPills}
          >
            <ConfigurationCanvasWrapper page={page} />
          </ConfigurationCanvasProvider>
        </RecordTypeLeadingObjectProvider>
      </PanelsProvider>
    </CanvasContextProvider>
  );
};

const AppConfigurationCanvas = (
  props: ConfigurationCanvasProps & { route: EnvironmentCanvasPagesRoutes },
) => {
  /*
    Since we are importing ConfigurationCanvas on the APP ,
    To make sure we use the same instance of the component
    And not re-render it on every route change
    We can't use hooks here has it triggers a very nasty warning
    So we wrap it in a inner component
  */
  return <InnerConfigurationCanvas {...props} />;
};

export default AppConfigurationCanvas;
