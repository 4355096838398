import { ReactNode } from 'react';
import { SxProps, Theme } from '@mui/material';
import { SortOrder } from '../types';
import { SweepTypographyVariants } from '@sweep-io/sweep-design/dist/components/Typography/types';

export enum SortCompareTypes {
  String = 'string',
  Number = 'number',
  Custom = 'custom',
}

export type SortCompareFunction<TRow extends DataTableRow = any> = (
  a: TRow,
  b: TRow,
  order: SortOrder,
) => number;

export interface SortCompareType {
  type: SortCompareTypes;
}
export interface SortCompareCustom<TRow extends DataTableRow = any> extends SortCompareType {
  type: SortCompareTypes.Custom;
  compareFunction: (a: TRow, b: TRow, order: SortOrder) => number;
}
interface SortCompareString extends SortCompareType {
  type: SortCompareTypes.String;
}
interface SortCompareNumber extends SortCompareType {
  type: SortCompareTypes.Number;
}

export interface DataTableColumn<TRow extends DataTableRow = any> {
  field: string;
  headerName: ReactNode;
  width?: number | string;
  showDragHandle?: boolean;
  showSortIcon?: boolean;
  className?: string;
  hidden?: boolean;
  sortCompare?: SortCompareString | SortCompareNumber | SortCompareCustom<TRow>;
}

export type TableCellProps<TRow extends DataTableRow = any> = {
  children: ReactNode;
  isDragging?: boolean;
  column: DataTableColumn<TRow>;
  onClick?: React.MouseEventHandler<HTMLTableCellElement>;
};

export type DataTableOnOrderChangeClbk = ({
  sourceIndex,
  destinationIndex,
}: {
  sourceIndex: number;
  destinationIndex: number;
}) => void;

export type DataTableOnSortChangeClbk = (props: { sortBy: string; sortOrder: SortOrder }) => void;

export interface DataTableRow {
  id: string;
  nestedRows?: DataTableRow[];
  _animation?: string;
  disabled?: boolean;
  [key: string]: any;
}

export type RenderRow<TRow extends DataTableRow = any> = ({
  rowIdx,
  row,
  rowKey,
}: {
  rowIdx?: number;
  row?: TRow;
  rowKey?: string;
}) => ReactNode | undefined;

export type DataTableSortStateProps = {
  sortBy: string;
  sortOrder: SortOrder;
};

export enum DataTableVariant {
  narrow = 'NARROW',
  default = 'DEFAULT',
}

export interface DataTableProps<TRow extends DataTableRow = any> {
  columns: DataTableColumn<TRow>[]; // Header columns definition
  rows: TRow[]; // Rows data
  /**
   * Callback function when row order changes.
   * This event is triggered when a row is dragged and dropped to a new position.
   **/
  onOrderChange?: DataTableOnOrderChangeClbk;
  allowReorder?: boolean;
  defaultSortState?: DataTableSortStateProps;
  onSortChange?: DataTableOnSortChangeClbk; // Callback function when sort state changes
  sxRowFunction?: (isDragging: boolean) => SxProps<Theme>;
  renderRow?: RenderRow;
  sx?: SxProps<Theme>;
  isLoading?: boolean;
  stickyHeader?: boolean;
  tableMaxHeight?: string;
  reachedLastRow?: {
    txt: string;
    rowsMaxCount: number;
  };
  TableEmptyStateComponent?: React.ReactNode;
  onRowClick?: (
    e: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
    row: TRow,
    columnData: DataTableColumn<TRow>,
  ) => void;
  actionableButtonsOnHover?: boolean;
  TableFooter?: React.ReactNode;
  variant?: DataTableVariant;
  containerRef?: React.RefObject<HTMLDivElement>;
  rowTypographyVariant?: SweepTypographyVariants;
}
