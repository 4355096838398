import OnboardingLayout from './onboarding/OnboardingLayout';
import { Button, colors, Typography } from '@sweep-io/sweep-design';
import CreateAccountImg from './onboarding/createAccountImg.svg?react';
import { useCallback, useEffect } from 'react';
import { Stack, Box } from '@mui/material';
import useUserInfo from '../../hooks/useUserInfo';
import { useSweepNotifications } from '../notifications/useSweepNotifications';
import { SweepNotificationVariant } from '../../reducers/notificationsReducer';
import { useAuth0 } from '@auth0/auth0-react';
import { telemetry } from '../../telemetry';

const VerificationNeeded = ({
  authUserId,
  authUserEmail,
}: {
  authUserId?: string;
  authUserEmail?: string;
}) => {
  const { error: authError } = useAuth0();
  const { appState } = (authError as any) ?? {}; //"appState" is not part of "Error" type, even though it IS returned as part of it.
  const { resendVerificationEmail } = useUserInfo();
  const { addNotification } = useSweepNotifications();

  const onResendClick = useCallback(async (): Promise<any> => {
    if (authUserId) {
      try {
        await resendVerificationEmail(authUserId);
        addNotification({
          message: 'Verification email was successfully resent',
          variant: SweepNotificationVariant.Success,
        });
      } catch (e) {
        telemetry.captureError(e);
        addNotification({
          message: 'Error sending verification email',
          variant: SweepNotificationVariant.Error,
        });
      }
    }
  }, [resendVerificationEmail, authUserId, addNotification]);

  useEffect(() => {
    window.history.replaceState({}, '', appState?.returnTo ?? window.location.pathname);
  }, [appState?.returnTo]);

  return (
    <OnboardingLayout withLogout={true}>
      <Stack
        height="100%"
        alignItems="center"
        justifyContent="center"
        gap={2}
        width="400px"
        textAlign="center"
      >
        <CreateAccountImg />
        <Stack display="flex" justifyContent="center" alignItems="center" gap={1}>
          <Typography variant="h2">Verify your email</Typography>
          <Typography variant="body" color={colors.grey[800]}>
            We've sent an email {authUserEmail ? `to ${authUserEmail}` : ''} to verify your email
            address and activate your account.
          </Typography>
          {authUserId && (
            <Box display="flex" gap={0.5} alignItems="center" justifyContent="center">
              <Button variant="link" onClick={onResendClick}>
                Click here
              </Button>
              <Typography variant="body" color={colors.grey[800]}>
                if you did not receive an email
              </Typography>
            </Box>
          )}
        </Stack>
      </Stack>
    </OnboardingLayout>
  );
};

export default VerificationNeeded;
