import {
  Dialog,
  DialogTitle,
  FormControl,
  DialogContent,
  DialogActions,
  Grid,
  FormHelperText,
  Box,
} from '@mui/material';
import { Button, Typography } from '@sweep-io/sweep-design';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getCrmOrgById } from '../../environments/environmentsReducer';
import { setNewFunnelTransientData } from '../../../../reducers/multiFunnelFlowNoHistoryReducer';
import {
  closeNewFunnelDialog,
  selectNewFunnelDialogData,
} from '../../../../reducers/newFunnelReducer';
import FieldLabel from '../../../common/FieldLabel';
import SweepTextField from '../../../common/SweepTextField';
import { selectFunnelMap } from '../../../../reducers/multiFunnelFlowCanvasReducer';
import { SweepObjectSelector } from '../../../common/sweep-object-field-selectors/SweepObjectSelector';
import { useCallback, useState } from 'react';
import { LeadingFieldSelector } from '../../../common/sweep-object-field-selectors/LeadingFieldSelector';
import useNewFunnelDialogForm, { NewFunnelDialogFormValues } from './useNewFunnelDialogForm';
import { EnvironmentNameTagWithPrefix } from '../../../common/EnvironmentNameTagWithPrefix';
import { getDataFromSFFieldName } from '../../rollups/rollupHelpers';

interface NewFunnelDialogProps {
  crmOrgId: string;
  crmOrg: { name: string; isMain: boolean };
  name?: string;
  onCancel: () => any;
  onConfirm: (formValues: NewFunnelDialogFormValues, sweepField: SweepField) => any;
  hideUseFieldsFrom?: boolean;
}

const NewFunnelDialog = ({
  crmOrgId,
  crmOrg,
  name: initialName,
  onCancel,
  onConfirm,
  hideUseFieldsFrom,
}: NewFunnelDialogProps) => {
  const [currentField, setCurrentField] = useState<SweepField>();

  const onSubmit = useCallback(
    (formValues: NewFunnelDialogFormValues) => onConfirm(formValues, currentField as SweepField),
    [currentField, onConfirm],
  );

  const { handleChange, values, errors, isSubmitted, isSubmitting, isValid, submitForm } =
    useNewFunnelDialogForm({
      initialName: initialName || '',
      onSubmit,
    });

  const onLeadingFieldSelectorChange = useCallback(
    (value: string, sweepField: SweepField) => {
      handleChange('field')(value);
      setCurrentField(sweepField);
    },
    [handleChange],
  );

  return (
    <Dialog
      open
      onClose={onCancel}
      PaperProps={{
        sx: {
          width: '452px',
          minHeight: '238px',
        },
      }}
    >
      <DialogTitle>
        <Typography variant="h2">New Funnel</Typography>
        {!hideUseFieldsFrom && (
          <Box mt={1}>
            <EnvironmentNameTagWithPrefix
              crmOrgName={crmOrg.name}
              isMain={crmOrg.isMain}
              prefixText="Uses fields from "
            />
          </Box>
        )}
      </DialogTitle>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          submitForm();
        }}
      >
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            paddingTop: '16px',
          }}
        >
          <SweepTextField
            FormControlProps={{ fullWidth: true }}
            label="Funnel Name"
            markLabelAsRequired
            id="funnel-name"
            autoFocus
            TextFieldProps={{
              fullWidth: true,
              placeholder: 'Type a name for the funnel',

              value: values.name,
              onChange: handleChange('name'),
              error: isSubmitted && Boolean(errors.name),
              helperText: isSubmitted ? errors.name : '',
              inputProps: {
                'data-testid': 'funnel-name-input',
              },
            }}
          />
          <FormControl fullWidth>
            <FieldLabel
              label={'Choose object'}
              infoTooltipTitle={'Salesforce object that represents this funnel'}
              showTooltip
              markLabelAsRequired
            />
            <SweepObjectSelector
              crmOrgId={crmOrgId}
              onChange={(value) => {
                handleChange('objectType')(value);
              }}
              value={values.objectType}
            />

            {isSubmitted && errors.objectType && (
              <FormHelperText error>{errors.objectType}</FormHelperText>
            )}
          </FormControl>
          <FormControl fullWidth>
            <FieldLabel
              label={'Field'}
              infoTooltipTitle={'Salesforce leading field that represents this funnel'}
              showTooltip
              markLabelAsRequired
            />
            <LeadingFieldSelector
              crmOrgId={crmOrgId}
              objectType={values.objectType}
              value={values.field}
              onChange={onLeadingFieldSelectorChange}
              disabled={!values.objectType}
            />
            {isSubmitted && errors.field && <FormHelperText error>{errors.field}</FormHelperText>}
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Grid item xs={2} ml={'-6px'} mr={'2px'}>
            <Button
              variant="outlined"
              size="large"
              onClick={onCancel}
              disabled={isSubmitting}
              dataTestId="dialog-cancel-button"
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              htmlType="submit"
              size="large"
              loading={isSubmitting}
              disabled={isSubmitted && !isValid}
              dataTestId="dialog-create-button"
            >
              Create
            </Button>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export const ConnectedNewFunnelDialog = ({
  hideUseFieldsFrom,
}: {
  hideUseFieldsFrom?: boolean;
}) => {
  const dispatch = useDispatch();

  const dialogData = useSelector(selectNewFunnelDialogData);
  const environment = useSelector(getCrmOrgById(dialogData?.crmOrgId));
  const funnelMap = useSelector(selectFunnelMap);

  const onConfirm = useCallback(
    ({ name, field, objectType }: NewFunnelDialogFormValues, sweepField: SweepField) => {
      const sweepFieldName = sweepField.sweepFieldName as string;
      const sfFieldName = sweepField.sfFieldName as string;
      const isFirstFunnel = !!funnelMap?.funnelsData && !Object.keys(funnelMap?.funnelsData).length;

      const isPlacingFunnel = !isFirstFunnel; //first funnel should be placed automatically

      const [, fieldName] = getDataFromSFFieldName(sfFieldName);

      dispatch(
        setNewFunnelTransientData({
          name,
          position: { row: 0, column: 0 },
          leadingObject: {
            _leadingFieldId: field,
            _leadingFieldLabels: [objectType, sweepFieldName],
            fieldName: fieldName,
            objectName: objectType,
          },
          isPlacingFunnel,
        }),
      );
      dispatch(closeNewFunnelDialog());
    },
    [dispatch, funnelMap?.funnelsData],
  );
  const onCancel = useCallback(() => {
    dispatch(closeNewFunnelDialog());
  }, [dispatch]);

  if (!dialogData) {
    return null;
  }

  return (
    <NewFunnelDialog
      {...dialogData}
      crmOrg={{ name: environment?.name ?? '', isMain: !!environment?.isMain }}
      onCancel={onCancel}
      onConfirm={onConfirm}
      hideUseFieldsFrom={hideUseFieldsFrom}
    />
  );
};
