import { Box, ListSubheader, MenuItem } from '@mui/material';
import { ReactNode, useMemo, useState } from 'react';
import { SearchInput } from './SearchInput';
import SweepSelect, { SweepSelectProps } from './SweepSelect';
import { Typography, colors } from '@sweep-io/sweep-design';
import StyledTooltip from './StyledTooltip';
import keyBy from 'lodash/keyBy';
import { highlightMatch } from '../../lib/highlightMatch';

export interface SearchSelectItem<T = any> {
  value: string;
  label: string; // Renders in the list. This is also used for the search
  tooltip?: ReactNode;
  data?: T;
  isSection?: boolean;
}

interface SearchSelectProps<T = any> extends Omit<SweepSelectProps, 'children' | 'renderValue'> {
  items: SearchSelectItem<T>[];
  valueRenderer?: (item: SearchSelectItem<T>) => React.ReactNode;
  itemRenderer?: (item: SearchSelectItem<T>, search?: string) => React.ReactNode;
  onChange?: (item: SearchSelectItem<T>) => void;
  searchPlaceholder?: string;
  children?: ReactNode;
}

const defaultItemRenderer = (item: SearchSelectItem, search?: string) => (
  <>{highlightMatch(item.label, search)}</>
);

export function SearchSelect<T = any>({
  items,
  valueRenderer,
  onChange,
  searchPlaceholder,
  children,
  itemRenderer = defaultItemRenderer,
  ...sweepSelectProps
}: SearchSelectProps<T>) {
  const [search, setSearch] = useState('');
  const [localItems, setLocalItems] = useState<SearchSelectItem<T>[]>();
  const [localValue, setLocalValue] = useState(
    sweepSelectProps?.SelectProps?.value || sweepSelectProps?.SelectProps?.defaultValue,
  );

  const itemsByKey = useMemo(() => keyBy(items, 'value'), [items]);

  return (
    <SweepSelect
      {...sweepSelectProps}
      MenuProps={{
        ...sweepSelectProps.MenuProps,
        autoFocus: false,
        sx: {
          '.MuiList-root': {
            paddingTop: 0,
          },
        },
      }}
      SelectProps={{
        ...sweepSelectProps.SelectProps,
        onChange: (event) => {
          setLocalValue(event.target.value as string);
          const item = itemsByKey[event.target.value as string];
          item && onChange && onChange(item);
        },
        inputProps: {
          ...sweepSelectProps.SelectProps?.inputProps,
          onChange: (event) => {
            setLocalValue((event.target as any).value);
            sweepSelectProps.SelectProps?.inputProps?.onChange &&
              sweepSelectProps.SelectProps?.inputProps?.onChange(event);
          },
        },
      }}
      renderValue={(value) => {
        const item = items.find((item) => item.value === value);
        if (valueRenderer) {
          return valueRenderer(item as SearchSelectItem<T>);
        }
        return <>{item?.label || value}</>;
      }}
      onOpenCb={(event) => {
        setSearch('');
        setLocalItems(undefined);
        sweepSelectProps.onOpenCb && sweepSelectProps.onOpenCb(event);
      }}
    >
      {!Boolean(sweepSelectProps.isLoading) && !children && (
        <ListSubheader
          sx={{
            display: 'flex',
            alignItems: 'center',
            lineHeight: 1,
            borderColor: colors.grey[300],
            margin: '0px -8px',
            padding: '14px 15px 15px',
            position: 'sticky',
            gap: '12px',
          }}
        >
          <SearchInput
            withFixedMagnifyingGlassIcon
            placeholder={searchPlaceholder}
            TextFieldProps={{
              sx: { minWidth: '270px' },
              value: search,
              autoFocus: true,
              onChange: (event) => {
                const value = event.target.value;
                setSearch(value);
                if (value) {
                  setLocalItems(
                    items.filter((item) => {
                      if (item.isSection) {
                        return false;
                      }
                      return (
                        localValue === item.value ||
                        item.label.toUpperCase().includes(event.target.value.toUpperCase())
                      );
                    }),
                  );
                } else {
                  setLocalItems(undefined);
                }
              },
              onKeyDown: (e) => {
                if (e.key !== 'Escape') {
                  // Prevents autoselecting item while typing (default Select behaviour)
                  e.stopPropagation();
                }
              },
            }}
          />
        </ListSubheader>
      )}
      {!children && <MenuItem value="" hidden />}
      {!children &&
        (localItems || items).map((item) =>
          item.isSection ? (
            <MenuItem key={item.value} value={item.value} disabled>
              <StyledTooltip title={item.tooltip} key={item.value} placement="right">
                <Typography variant="caption-bold">{item.label}</Typography>
              </StyledTooltip>
            </MenuItem>
          ) : (
            <MenuItem value={item.value} key={item.value} autoFocus={item.value === localValue}>
              <StyledTooltip
                title={item.tooltip}
                PopperProps={{
                  sx: {
                    zIndex: 999999, // Needs to be higher than the dropdown of the select
                  },
                }}
                placement="right"
              >
                <Box>{itemRenderer ? itemRenderer(item, search) : item.label}</Box>
              </StyledTooltip>
            </MenuItem>
          ),
        )}
      {children}
    </SweepSelect>
  );
}
