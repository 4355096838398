import { Menu, Box, MenuItem } from '@mui/material';
import { ChevronDown, Devops } from '@sweep-io/sweep-design/dist/icons';
import { Button, Typography } from '@sweep-io/sweep-design';
import usePopover from '../../../hooks/usePopover';
import SplitButton from './SplitButton';
import { useState } from 'react';

const menuItemSx = { display: 'flex', alignItems: 'center', gap: 1 };

const SaveDraftButton = ({
  onSaveClick,
  disabled,
  onShowDiff,
}: {
  onSaveClick: () => Promise<void>;
  disabled?: boolean;
  onShowDiff?: () => void;
}) => {
  const { isPopoverOpen, closePopover, openPopover, anchorElement, anchorRef } = usePopover();
  const [isLoading, setIsLoading] = useState(false);

  const callAsyncWithLoading = async (fn: () => Promise<void>) => {
    setIsLoading(true);
    await fn();
    setIsLoading(false);
  };

  const showDiffItem = Boolean(onShowDiff);
  const shouldRenderMenu = showDiffItem;

  const renderMenu = () => {
    return (
      <Menu
        anchorEl={anchorElement}
        open={isPopoverOpen}
        onClose={() => closePopover()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          sx={menuItemSx}
          onClick={() => {
            onShowDiff?.();
            closePopover();
          }}
        >
          <Devops />
          <Typography variant="body">Review Changes</Typography>
        </MenuItem>
      </Menu>
    );
  };

  const buttonText = 'Save draft';

  return (
    <Box display="flex" gap="12px">
      {showDiffItem && (
        <>
          <Box display="inline-flex" ref={anchorRef}>
            <SplitButton
              leftText={buttonText}
              leftButtonProps={{
                onClick: async () => {
                  onSaveClick && callAsyncWithLoading(onSaveClick);
                },
                disabled,
              }}
              rightButtonProps={{
                onClick: openPopover,
                pressed: isPopoverOpen,
                disabled,
              }}
              rightIcon={<ChevronDown />}
              isLoading={isLoading}
            />
          </Box>
          {renderMenu()}
        </>
      )}

      {!shouldRenderMenu && (
        <Button
          loading={isLoading}
          disabled={disabled}
          onClick={() => {
            onSaveClick && callAsyncWithLoading(onSaveClick);
          }}
        >
          {buttonText}
        </Button>
      )}
    </Box>
  );
};

export default SaveDraftButton;
