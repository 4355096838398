import { Box } from '@mui/material';
import { CollapseList, ListGroup } from '../../../../../common/CollapseList';
import { CenteredCircularProgress } from '../../../../../common/CenteredCircularProgress';
import { FetchStatus } from '@server/fetch_status.type';
import { LoadingNewEnvironment } from './LoadingNewEnvironment';
import { NoResults } from './NoResults';
import { useRef } from 'react';

interface ImportFunnelViewProps {
  listDataStructure: ListGroup[];
  isLoading?: boolean;
  currentCrmOrg?: CrmOrg;
}

export const ImportFunnelView = ({
  listDataStructure,
  isLoading,
  currentCrmOrg,
}: ImportFunnelViewProps) => {
  const searchRef = useRef<any>(null);

  const isFetching = [FetchStatus.Fetching, FetchStatus.InitialFetching].includes(currentCrmOrg?.fetchStatus);

  return (
    <>
      {isLoading ? (
        <CenteredCircularProgress />
      ) : (
        <Box
          sx={{
            width: '100%',
            '& .collapse-list': {
              p: 0,
              '& .MuiListItem-root': {
                '.buttons-on-hover': {
                  visibility: 'hidden',
                },

                '&:hover .buttons-on-hover': {
                  visibility: 'visible',
                },
              },
            },
            '& .search-input-wrapper': {
              padding: '0 0 16px 0',
            },
            height: 'calc(100% - 70px)',
          }}
        >
          {isFetching && <LoadingNewEnvironment />}
          {!isFetching && (
            <CollapseList
              isOpen
              groups={listDataStructure}
              openAllExpanded={false}
              showSearchField
              withCounter
              withDivider
              noItemsFoundJsx={
                !!listDataStructure.length ? (
                  <NoResults onClearSearch={() => searchRef.current.clearSearch()} />
                ) : undefined
              }
              ref={searchRef}
            />
          )}
        </Box>
      )}
    </>
  );
};
