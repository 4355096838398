import { isProdEnvIndeed } from '../environment';
import { BiEvent } from './events';

//This is to indicate which BI services are should be enabled.
const ENABLE_MAP = {
  mixpanel: false,
};
import mixpanel from 'mixpanel-browser';

export type BiUser = {
  id: string;
  email: string;
  name: string;
  role: string;
  accountId?: string;
  accountName?: string;
  accountType?: string;
  questionnaireRole?: string;
  questionnaireUseCases?: string;
};

const initMixpanel = () => {
  mixpanel.init('a51065b3a9d0329648a8c5545173ae11', {
    track_pageview: true,
    persistence: 'localStorage',
    ignore_dnt: true,
    api_host: 'https://bi.sweep.io',
  });
};

const setUserInMixpanel = (user: BiUser, utmParams: Record<string, any>) => {
  mixpanel.identify(user.id);
  mixpanel.people.set({
    $name: user.name,
    $email: user.email,
    role: user.role,
    accountId: user.accountId,
    accountName: user.accountName,
    plan: user.accountType,
    questionnaireRole: user.questionnaireRole,
    questionnaireUseCases: user.questionnaireUseCases,
    ...utmParams,
  });
};

const logoutUserInMixpanel = () => {
  mixpanel.reset();
};

const sendEvent = (event: BiEvent) => {
  mixpanel.track(event.name, event.props);
};

export const initBi = (force = false) => {
  if (isProdEnvIndeed() || force) {
    ENABLE_MAP.mixpanel = true;
  }
  if (ENABLE_MAP.mixpanel) initMixpanel();
};

export const setUserDetailsForBi = (user: BiUser, utmParams: Record<string, any>) => {
  if (ENABLE_MAP.mixpanel) setUserInMixpanel(user, utmParams);
};

export const sendEventInMixpanel = (event: BiEvent) => {
  if (ENABLE_MAP.mixpanel) sendEvent(event);
};

export const logoutUserBI = () => {
  if (ENABLE_MAP.mixpanel) logoutUserInMixpanel();
};
