import { WithConfigurationCanvas } from '../configuration-canvas/WithConfigurationCanvas';
import { useConfigurationCanvas } from '../configuration-canvas/public/useConfigurationCanvas';
import { useSelector } from 'react-redux';
import { selectCrmOrgAutomations } from '../../../reducers/global/automationReducers';
import { VisibilityLayers } from '../../../types/enums/VisibilityLayers';
import { VisibilityMap } from '../../../types/VisibilityTypes';
import useAutomationsCanvasInteractions from './useAutomationsCanvasInteractions';
import { AutomationFormVariant } from '../../../types/enums/AutomationFormVariant';
import { VisibilityElementMap } from '../configuration-canvas/types';
import useEntitiesToHighlight from './useEntitiesToHighlight';
import { ConfigurationCanvasAutomationsPanel } from './CanvasAutomationsPanel';
import {
  AutomationsContextProvider,
  AutomationsContextType,
} from '../../Automations/AutomationsContext';
import { UI_EVENTS } from '../../../services/events';
import { WalkthroughPointType } from '@server/walkthrough';
import BoxEmptyImage from '../../common/empty-state/svgs/boxEmpty.svg?react';
import { AutomationType } from '../../../types/enums/AutomationType';

const automationsPageVisibilityMap: Partial<VisibilityMap> = {
  [VisibilityLayers.NURTURING_STEPS]: true,
  [VisibilityLayers.COMMENTS]: false,
  [VisibilityLayers.SWEEP_AUTOMATIONS]: true,
  [VisibilityLayers.FUNNEL_SWEEP_AUTOMATIONS]: true,
};
const visibilityElements: VisibilityElementMap = {
  type: 'map',
  visibilityMap: automationsPageVisibilityMap,
};

export const automationsAutomationContextData: AutomationsContextType = {
  automationType: AutomationType.Default,
  permissions: {
    create: 'create:automations',
    edit: 'edit:automations',
    deploy: 'deploy:automations',
    delete: 'delete:automations',
  },
  emptyStateTitle: 'Create your first automation',
  emptyStateImage: <BoxEmptyImage />,
  defaultName: 'New Automation',
  placeholder: 'New Automation',
  nameInMessage: 'automations',
  nameInMessageSingular: 'automation',
  walkthroughPointEmptyNew: WalkthroughPointType.automationsEmptyNew,
  walkthroughPointEdit: WalkthroughPointType.automationsEdit,
  walkthroughPointTrigger: WalkthroughPointType.automationsTrigger,
  openEvent: UI_EVENTS.automationsOpen,
  automationVariant: AutomationFormVariant.AUTOMATIONS,
  templateSupport: true,
};

export const CanvasAutomationPage = () => {
  const { canvasCrmOrgId } = useConfigurationCanvas();

  const { onEntityClick } = useAutomationsCanvasInteractions();
  const automationsVariant = AutomationFormVariant.AUTOMATIONS;
  const { entitiesToHighlight } = useEntitiesToHighlight(automationsVariant);
  const automations = useSelector(selectCrmOrgAutomations(canvasCrmOrgId));

  return (
    <WithConfigurationCanvas
      visibilityElements={visibilityElements}
      onFunnelStepClick={onEntityClick}
      onRecordTypeStepClick={onEntityClick}
      onFunnelLabelPillClick={onEntityClick}
      onRecordTypeLabelPillClick={onEntityClick}
      highlightEntities={entitiesToHighlight}
    >
      <AutomationsContextProvider value={automationsAutomationContextData}>
        <ConfigurationCanvasAutomationsPanel automations={automations} />
      </AutomationsContextProvider>
    </WithConfigurationCanvas>
  );
};
