import { Typography, Button } from '@sweep-io/sweep-design';
import { useCallback, useState } from 'react';
import { SweepNotificationVariant } from '../../../reducers/notificationsReducer';
import { ConfirmDialog } from '../../common/dialogs/ConfirmDialog';
import { useSweepNotifications } from '../../notifications/useSweepNotifications';
import { telemetry } from '../../../telemetry';

interface ResetPasswordDialogProps {
  onConfirmCb?: () => any;
  email: string;
}

const ResetPasswordButton = ({ onConfirmCb, email }: ResetPasswordDialogProps) => {
  const [showResetDialog, setShowResetDialog] = useState(false);
  const { addNotification } = useSweepNotifications();

  const displayNotification = useCallback(
    (type: string) => {
      let message = `An Email with a reset password link was sent to ${email}, please
      check your email`;
      let variant: SweepNotificationVariant = SweepNotificationVariant.Success;

      if (type === 'error') {
        message = `Sending email failed, please try again`;
        variant = SweepNotificationVariant.Error;
      }

      addNotification({
        message,
        variant,
      });
    },
    [addNotification, email],
  );

  return (
    <div data-testid="reset-password-container">
      <Button
        dataTestId="reset-password-button"
        variant="link"
        onClick={() => setShowResetDialog(true)}
      >
        <Typography variant="caption-bold" textDecoration="underline">
          Reset password
        </Typography>
      </Button>

      <ConfirmDialog
        title="Reset Your Password"
        confirmText="Send Reset Link"
        onConfirm={async () => {
          if (!onConfirmCb) return;

          try {
            await onConfirmCb();
            displayNotification('success');
          } catch (error) {
            telemetry.captureError(error);
            displayNotification('error');
          }
        }}
        isOpen={showResetDialog}
        handleClose={() => setShowResetDialog(false)}
        PaperProps={{
          sx: {
            width: 380,
          },
        }}
        confirmButtonDataTestId="confirm-button-reset"
      >
        <Typography variant="body">
          Would you like us to send you an email with a password reset link and instructions?
        </Typography>
      </ConfirmDialog>
    </div>
  );
};

export { ResetPasswordButton };
