import { Box, Collapse } from '@mui/material';
import { useState } from 'react';
import { ChevronUp, ChevronDown } from '@sweep-io/sweep-design/dist/icons';
import { Button, Typography, colors } from '@sweep-io/sweep-design';
import { ArrowRight } from '@sweep-io/sweep-design/dist/icons';
import { IconForPluginType } from '../../../templates/plugins/icons/IconForPluginType';
import { useSweepCanvas } from '../../../../../multi-canvas/useSweepCanvas';

interface StageDialogCollapsePanelProps {
  children: React.ReactElement;
  defaultIsCollapsed: boolean;
  from: string;
  to: string;
  totalConditions?: number;
  isPluginType?: PluginTypes;
  funnelId: string;
}

const StageDialogCollapsePanel = ({
  defaultIsCollapsed,
  children,
  from,
  to,
  totalConditions,
  isPluginType,
  funnelId,
}: StageDialogCollapsePanelProps) => {
  const [isCollapsed, setIsCollapsed] = useState(defaultIsCollapsed);
  const showConditions = totalConditions !== undefined;
  const { clickPluginButton } = useSweepCanvas();

  return (
    <Box
      sx={{
        background: colors.white,
        borderRadius: '8px',
        border: '1px solid',
        borderColor: colors.grey[300],
        padding: '24px 18px',
        marginY: 2,
        position: 'relative',
        cursor: !isCollapsed ? 'pointer' : 'default',
        minHeight: '74px',
        '&:hover': {
          background: !isCollapsed ? colors.storm[50] : colors.white,
        },
      }}
      onClick={() => !isCollapsed && setIsCollapsed((prevState) => !prevState)}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box display="flex" alignItems="center" gap={0.5}>
            <Typography variant="body-bold">{from}</Typography>
            <ArrowRight />
            <Typography variant="body-bold">{to}</Typography>
          </Box>

          {!isCollapsed && showConditions && (
            <Box sx={{ paddingLeft: '10px' }}>
              <Typography variant="caption-bold">
                {totalConditions} Condition
                {totalConditions !== 1 ? 's' : ''}
              </Typography>
            </Box>
          )}
        </Box>
        <Box sx={{ display: 'flex', gap: 2 }}>
          {isPluginType && (
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Button
                variant="link"
                startIconName="SeeIn"
                onClick={(e) => {
                  e.stopPropagation();
                  clickPluginButton(isPluginType, funnelId);
                }}
              >
                Edit in Plugin
              </Button>
              <IconForPluginType pluginType={isPluginType} size={24} />
            </Box>
          )}
          <Box
            sx={{
              '& .MuiButton-root': {
                minWidth: '30px',
              },
            }}
          >
            <Button
              variant="link"
              onClick={() => isCollapsed && setIsCollapsed((prevState) => !prevState)}
            >
              <Typography color={colors.black} variant="body">
                {isCollapsed ? <ChevronUp /> : <ChevronDown />}
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
      <Collapse in={isCollapsed}>{children}</Collapse>
    </Box>
  );
};

export { StageDialogCollapsePanel };
