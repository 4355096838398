import { Box, SxProps, TableRow as MuiTableRow, TableRowProps, Theme } from '@mui/material';
import TableCellLockedWidth from './TableCellLockedWidth';
import { TruncatedTextTooltip } from '../TruncatedTextTooltip';
import { Reorder as ReorderIcon } from '@sweep-io/sweep-design/dist/icons';
import { DataTableColumn, DataTableRow, DataTableVariant } from './TableTypes';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { colors } from '@sweep-io/sweep-design';
import classNames from 'classnames';
import { dataTableVariants } from './dataTableVariants';

interface RowProps<TRow extends DataTableRow> {
  row: TRow;
  columns: DataTableColumn<TRow>[];
  allowReorder?: boolean;
  sxRowFunction?: (isDragging: boolean) => SxProps<Theme>;
  tableRowProps?: TableRowProps;
  dragHandleProps?: DraggableProvidedDragHandleProps | null;
  isDragging?: boolean;
  inlineStyle?: any;
  onClick?: (
    e: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
    columnData: DataTableColumn<TRow>,
  ) => void;
  actionableButtonsOnHover?: boolean;
  variant: DataTableVariant;
}

function TableRow<TRow extends DataTableRow = any>({
  row,
  columns,
  allowReorder,
  sxRowFunction,
  tableRowProps,
  dragHandleProps,
  isDragging,
  inlineStyle,
  onClick,
  actionableButtonsOnHover,
  variant,
  ...props
}: RowProps<TRow>) {
  const sx = sxRowFunction ? sxRowFunction(Boolean(isDragging)) : {};

  return (
    <MuiTableRow
      data-testid="data-table-row"
      sx={{
        minHeight: '60px', //design team asked for it, if your design doesn't match please talk to them
        backgroundColor: row.isActive ? colors.grey[100] : 'transparent',
        '& .actions': {
          opacity: actionableButtonsOnHover ? 0 : 1,
        },
        '&:hover .actions': {
          opacity: 1,
        },

        ...sx,
        animation: row._animation ? row._animation : '',
        cursor: row.isClickable ? 'pointer' : 'default',
      }}
      className="SweepDataTableRow"
      {...tableRowProps}
      style={inlineStyle}
      {...props}
    >
      {columns.map((column, index) => {
        const cell = row[column.field];
        const isActionColumn = column.field === '__actions';
        const isPlainText = typeof cell === 'string';
        const cellContent = isPlainText ? (
          <Box
            sx={{ display: 'flex', alignItems: 'center' }}
            className={classNames({
              actions: isActionColumn,
            })}
          >
            <TruncatedTextTooltip
              variant={dataTableVariants[variant].fontVariant}
              color={row.disabled ? dataTableVariants[variant].fontColor : undefined}
            >
              {cell}
            </TruncatedTextTooltip>
          </Box>
        ) : (
          <Box
            className={classNames({
              actions: isActionColumn,
            })}
            sx={{
              display: 'flex',
              alignItems: 'center',
              '& .MuiTypography-root': {
                color: dataTableVariants[variant].fontColor,
              },
              '& .avatar .MuiTypography-root': {
                color: colors.blue[500],
              },
            }}
          >
            {cell}
          </Box>
        );

        return (
          <TableCellLockedWidth
            key={`row${row.id}-col${index}`}
            column={column}
            isDragging={isDragging}
            onClick={(e) => {
              onClick && onClick(e, column);
            }}
            variant={variant}
          >
            {allowReorder && column.showDragHandle ? (
              <Box
                sx={{
                  width: 'fit-content',
                  display: 'flex',
                  gap: '12px',
                  alignItems: 'center',
                  justifyContent: 'center',

                  color: dataTableVariants[variant].fontColor,
                  '& .MuiTypography-root': {
                    color: dataTableVariants[variant].fontColor,
                  },
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'grab',
                  }}
                >
                  <Box {...dragHandleProps} display="flex">
                    <ReorderIcon />
                  </Box>
                </Box>
                {cellContent}
              </Box>
            ) : (
              <>{cellContent}</>
            )}
          </TableCellLockedWidth>
        );
      })}
    </MuiTableRow>
  );
}

export default TableRow;
