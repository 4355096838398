import { Stack } from '@mui/material';
import { Role } from './role/Role';
import { useSelector } from 'react-redux';
import { selectIsLoadingRoleGroups, selectRoleGroups } from '../../../reducers/roleGroupsReducer';
import { RoleGroupInterface } from '@server/role-group-interface';
import { useRoleManager } from './useRoleManager';
import { CenteredCircularProgress } from '../../common/CenteredCircularProgress';
import { useRunOnce } from '../../common/useRunOnce';
import { sortRoles } from './utils';

interface RoleListProps {
  setEditRole: (role: RoleGroupInterface) => void;
}

export const RoleList = ({ setEditRole }: RoleListProps) => {
  const roles = useSelector(selectRoleGroups);
  const isLoading = useSelector(selectIsLoadingRoleGroups);
  const { fetchRoleGroups } = useRoleManager();

  useRunOnce(async () => {
    if (!roles.length) {
      fetchRoleGroups();
    }
  });

  const sortedRoles = sortRoles(roles ?? []);

  return (
    <Stack pt={2.5}>
      {isLoading && <CenteredCircularProgress />}
      {!isLoading &&
        sortedRoles.map((role) => (
          <Role role={role} key={role.id} onClick={() => setEditRole(role)} />
        ))}
    </Stack>
  );
};
