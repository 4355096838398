import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';
import { UserInfo } from '../types/UserInfoTypes';
import { GetStartedDto } from '../components/pages/get-started/getStartedTypes';
import { GetStartedDto as GetStartedDtoDeprecated } from '../components/pages/get-started-deprecated/getStartedTypes';
import { AccountOnboardingStep } from '@server/account-onboarding';
import { WalkthroughDto } from '../components/common/walkthrough-points/WalkthroughTypes';
import { WalkthroughPointType } from '@server/walkthrough';
import { QuestionnaireDto } from '../components/pages/questionnaire/questionnaireTypes';

export interface UserInfoState {
  data?: UserInfo;
  isLoading: boolean;
  error?: string;
}

const initialState: UserInfoState = {
  data: undefined,
  isLoading: true,
};

export const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    setUserFetchSuccess: (state, action: PayloadAction<UserInfo>) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    setUserFetchError: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    setUserInfo: (state, action: PayloadAction<Partial<UserInfo>>) => {
      state.data = { ...state.data, ...action.payload };
    },
    setUserGetStartedDeprecated: (state, action: PayloadAction<GetStartedDtoDeprecated>) => {
      state.data = {
        ...state.data,
        preferences: { ...state.data?.preferences, getStartedDeprecated: action.payload },
      };
    },
    setUserGetStarted: (state, action: PayloadAction<GetStartedDto>) => {
      state.data = {
        ...state.data,
        preferences: { ...state.data?.preferences, getStarted: action.payload },
      };
    },
    setUserWalkthrough: (state, action: PayloadAction<WalkthroughDto>) => {
      state.data = {
        ...state.data,
        preferences: { ...state.data?.preferences, walkthrough: action.payload },
      };
    },
    updateOnboardingStep: (state, action: PayloadAction<AccountOnboardingStep>) => {
      if (!state.data || !state.data.account) {
        return;
      }
      state.data.account.onboardingStep = action.payload;
    },
    setCanvasPanelModeExpanded: (state, action: PayloadAction<{ isExpanded: boolean }>) => {
      state.data = {
        ...state.data,
        preferences: {
          ...state.data?.preferences,
          canvasPanelModeExpanded: action.payload.isExpanded,
        },
      };
    },
    setTrialEndDate: (state, action: PayloadAction<{ trialEndDate: string }>) => {
      if (!state.data || !state.data.account) {
        return;
      }
      const { trialEndDate } = action.payload;
      state.data.account.trialEndDate = trialEndDate;
    },
    setUserQuestionnaire: (state, action: PayloadAction<QuestionnaireDto>) => {
      state.data = {
        ...state.data,
        preferences: { ...state.data?.preferences, questionnaire: action.payload },
      };
    },
  },
});

export const {
  setUserInfo,
  setUserFetchSuccess,
  setUserFetchError,
  setUserGetStarted,
  setUserGetStartedDeprecated,
  updateOnboardingStep,
  setUserWalkthrough,
  setTrialEndDate,
  setCanvasPanelModeExpanded,
  setUserQuestionnaire,
} = userInfoSlice.actions;

export const selectUserInfoData = (state: RootState) => state.userInfo.data;

export const selectUserGetStartedDeprecated = (state: RootState) =>
  state.userInfo.data?.preferences?.getStartedDeprecated;

export const selectUserGetStarted = (state: RootState) =>
  state.userInfo.data?.preferences?.getStarted;

export const selectUserWalkthroughPoint = (point?: WalkthroughPointType) => (state: RootState) =>
  point ? state.userInfo.data?.preferences?.walkthrough?.[point] : undefined;

export const selectUserQuestionnaire = (state: RootState) =>
  state.userInfo.data?.preferences?.questionnaire;

export const selectUserInterests = (state: RootState) =>
  state.userInfo.data?.preferences?.questionnaire?.data?.interests;

export const selectDefaultCreationCrmOrgId = (state: RootState) =>
  state.userInfo.data?.preferences?.defaultCreationCrmOrgId;

export const selectCanvasPanelModeExpanded = (state: RootState) =>
  state.userInfo.data?.preferences?.canvasPanelModeExpanded;

export default userInfoSlice.reducer;
