import { ConfirmDialog } from '../common/dialogs/ConfirmDialog';
import { Typography } from '@sweep-io/sweep-design';

const DeleteCommentDialog = ({
  onDelete,
  handleClose,
}: {
  onDelete: () => void;
  handleClose: () => void;
}) => (
  <ConfirmDialog
    title="Delete comment"
    confirmText="Delete"
    confirmButtonType="error"
    onConfirm={onDelete}
    isOpen
    handleClose={handleClose}
    PaperProps={{
      sx: {
        width: '452px',
      },
    }}
  >
    <Typography variant="body">
      Are you sure you want to delete this comment? <br />
      This action cannot be undone.
    </Typography>
  </ConfirmDialog>
);
export default DeleteCommentDialog;
