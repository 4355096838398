import { Box } from '@mui/material';
import { RecordTypesFilter } from './RecordTypesFilter';
import { Fragment, useEffect, useMemo } from 'react';
import { StepsFilter } from './StepsFilter';
import { CanvasFilterTypes, SweepCanvasFiltersMap } from './filterTypes';
import { ObjectsFilter } from './ObjectsFilter';
import { MultiGenericFilter } from './MultiGenericFilter';

import { useCanvasFilters } from './useCanvasFilters';

import {
  selectCanvasFilterSelectedValues,
  selectCanvasFiltersAutomations,
  selectCanvasFiltersCrmOrgId,
  selectCanvasFiltersFilters,
  selectCanvasFiltersFunnelMap,
  setCanvasFiltersAutomations,
  setCanvasFiltersCrmOrgId,
  setCanvasFiltersFilters,
  setCanvasFiltersFunnelMap,
} from './canvasFiltersReducer';
import { useDispatch, useSelector } from 'react-redux';
import { useRunOnce } from '../../common/useRunOnce';
import { ClearButton } from './ClearButton';
import { colors } from '@sweep-io/sweep-design';
import { ToggleFilter } from './ToggleFilter';

export interface CanvasFiltersProps {
  funnelMap?: FunnelMap;
  crmOrgId?: string;
  filters: SweepCanvasFiltersMap;
  automations?: AutomationStructureNew[];
  hideClearButton?: boolean;
}

export const CanvasFilters = ({
  funnelMap: _funnelMap,
  crmOrgId: _crmOrgId,
  filters: _filters,
  automations: _automations,
  hideClearButton,
}: CanvasFiltersProps) => {
  const dispatch = useDispatch();
  const filters = useSelector(selectCanvasFiltersFilters);
  const canvasFiltersValues = useSelector(selectCanvasFilterSelectedValues);
  const funnelMap = useSelector(selectCanvasFiltersFunnelMap);
  const crmOrgId = useSelector(selectCanvasFiltersCrmOrgId);
  const automations = useSelector(selectCanvasFiltersAutomations);

  const { clearAllValues } = useCanvasFilters();

  useRunOnce(() => {
    // Clear all values when component is mounted
    clearAllValues();
  });

  useEffect(() => {
    if (_funnelMap) {
      dispatch(setCanvasFiltersFunnelMap({ funnelMap: _funnelMap }));
    }
  }, [_funnelMap, dispatch]);

  useEffect(() => {
    if (_crmOrgId) {
      dispatch(setCanvasFiltersCrmOrgId({ crmOrgId: _crmOrgId }));
    }
  }, [_crmOrgId, dispatch]);

  useEffect(() => {
    if (_filters) {
      dispatch(setCanvasFiltersFilters({ filters: _filters }));
    }
  }, [_filters, dispatch]);

  useEffect(() => {
    if (_automations) {
      dispatch(setCanvasFiltersAutomations({ automations: _automations }));
    }
  }, [_automations, dispatch]);

  const funnelsData = useMemo(() => _funnelMap?.funnelsData || {}, [_funnelMap?.funnelsData]);

  const recordTypesData = useMemo(
    () => funnelMap?.recordTypesData || {},
    [funnelMap?.recordTypesData],
  );

  if (!filters || !crmOrgId || !funnelsData) {
    return null;
  }
  const showClear =
    !hideClearButton &&
    Object.entries(filters).length > 1 &&
    Object.values(canvasFiltersValues).some((values) => values.length > 0);

  const renderFilters = () => {
    return Object.entries(filters)
      .sort(([, filterA], [, filterB]) => (filterA.position || 0) - (filterB.position || 0))
      .map(([filterKey, filter]) => {
        switch (filter.type) {
          case CanvasFilterTypes.RECORD_TYPE: {
            let filteredObjects = undefined;
            if (filter.objectsFilter?.type === 'reference') {
              filteredObjects = canvasFiltersValues[filter.objectsFilter.filterRef];
            }
            if (filter.objectsFilter?.type === 'values') {
              filteredObjects = filter.objectsFilter.values;
            }

            return (
              <RecordTypesFilter
                filterKey={filterKey}
                funnelsData={funnelsData}
                recordTypesData={recordTypesData}
                crmOrgId={crmOrgId}
                automations={automations}
                filteredObjects={filteredObjects}
                key={filterKey}
              />
            );
          }
          case CanvasFilterTypes.STEP: {
            const filteredRecordTypes = filter.recordTypeFilterRef
              ? canvasFiltersValues[filter.recordTypeFilterRef]
              : undefined;

            // const objectsFilter =  filter.recordTypeFilterRef ? (filters[filter.recordTypeFilterRef] as RecordTypesCanvasFilter)
            let filteredObjects: string[] | undefined = undefined;

            if (filter.recordTypeFilterRef) {
              const _filter = filters[filter.recordTypeFilterRef];
              if (_filter.type === CanvasFilterTypes.RECORD_TYPE) {
                filteredObjects =
                  _filter.objectsFilter?.type === 'reference'
                    ? canvasFiltersValues[_filter.objectsFilter.filterRef]
                    : _filter.objectsFilter?.values;
              }
            }

            return (
              <StepsFilter
                filterKey={filterKey}
                funnelsData={funnelsData}
                recordTypesData={recordTypesData}
                filteredRecordTypes={filteredRecordTypes}
                automations={automations}
                filteredObjects={filteredObjects}
                key={filterKey}
                filterButtonDataTestId="steps-filter-button"
              />
            );
          }
          case CanvasFilterTypes.OBJECT: {
            return (
              <ObjectsFilter
                filterKey={filterKey}
                funnelsData={funnelsData}
                recordTypesData={recordTypesData}
                crmOrgId={crmOrgId}
                key={filterKey}
                automations={automations}
              />
            );
          }

          case CanvasFilterTypes.MULTI_GENERIC:
            if (!filter.items.length) {
              return <Fragment key={filterKey} />; //to prevent component from rendering for dynamically created items lists
            }
            return (
              <MultiGenericFilter
                filterKey={filterKey}
                items={filter.items}
                key={filterKey}
                label={filter.label}
              />
            );
          case CanvasFilterTypes.TOGGLE_FILTER:
            return (
              <ToggleFilter filterKey={filterKey} checked={filter.checked} label={filter.label} />
            );
          default:
            return null;
        }
      });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 1,
        'div > button.MuiButtonBase-root:not(:hover), > button.MuiButtonBase-root:not(:hover)': {
          backgroundColor: colors.white,
        },
      }}
    >
      {renderFilters()}
      {showClear && <ClearButton onClick={clearAllValues}>Clear</ClearButton>}
    </Box>
  );
};
