import { Box, Collapse, MenuItem, SelectChangeEvent } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { ChevronDown, ChevronUp, Scales } from '@sweep-io/sweep-design/dist/icons';
import { Typography } from '@sweep-io/sweep-design';
import { DedupMatchingType } from '../../../types/enums/DedupMatchingType';
import SweepSelect from '../../common/SweepSelect';
import { useSweepFields } from '../../../sweep-fields/useCachedSweepFields';
import { useRunOnce } from '../../common/useRunOnce';
import { SelectorValueTypes } from '../../../types/enums/SelectorValueTypes';
import { useSweepFieldsLabels } from '../../../sweep-fields/useSweepFieldsLabels';
import { settingsPanelLayout } from './helpers';

interface SettingsTieBreakersPanelProps {
  readonly?: boolean;
  action: MatchingActionParams | DeduplicationActionParams;
  crmOrgId: string;
  objectType: string;
  type: DedupMatchingType;
  onChange: (condition?: SweepCondition[]) => any;
}

export enum TieBreakersOperatorTypes {
  Latest = 'LATEST',
  Earliest = 'EARLIEST',
}

const LAST_MODIFIED_DATE = 'Last Modified Date';
const CREATED_DATE = 'Created Date';
const LAST_ACTIVITY = 'Last Activity';

enum TieBreakersLabels {
  LatestModifiedDate = 'Latest modified date',
  LatestCreatedDate = 'Latest created date',
  LatestActivityDate = 'Latest activity date',
  EarliestModifiedDate = 'Earliest modified date',
  EarliestCreatedDate = 'Earliest created date',
  EarliestActivityDate = 'Earliest activity date',
}

type TieBreakersTypesProps = {
  fieldName: string;
  operator: TieBreakersOperatorTypes;
  label: string;
  value: string;
};

const labelsByType = {
  [DedupMatchingType.LEAD_TO_LEAD_DEDUP]: {
    subTitle:
      'Set up Tie breakers to determine the best match when there are multiple duplicates to a lead',
  },
  [DedupMatchingType.LEAD_TO_CONTACT_DEDUP]: {
    subTitle:
      'Set up Tie breakers to determine the best match when there are multiple duplicates to a lead',
  },
  [DedupMatchingType.LEAD_TO_ACCOUNT_MATCHING]: {
    subTitle:
      'Set up Tie breakers to determine the best match when there are multiple account matches to a lead',
  },
  [DedupMatchingType.LEAD_TO_LEAD_MATCHING]: {
    subTitle: 'Set up Tie breakers to determine the best match when there are multiple options',
  },
  [DedupMatchingType.ACCOUNT_TO_ACCOUNT_DEDUP]: {
    subTitle:
      'Set up Tie breakers to determine the best match when there are multiple duplicates to an account',
  },
  [DedupMatchingType.CONTACT_TO_CONTACT_DEDUP]: {
    subTitle:
      'Set up Tie breakers to determine the best match when there are multiple duplicates to a contact',
  },
};

const TieBreakersTypes: TieBreakersTypesProps[] = [
  {
    fieldName: LAST_MODIFIED_DATE,
    operator: TieBreakersOperatorTypes.Latest,
    value: TieBreakersLabels.LatestModifiedDate,
    label: 'Latest modified date',
  },
  {
    fieldName: CREATED_DATE,
    operator: TieBreakersOperatorTypes.Latest,
    value: TieBreakersLabels.LatestCreatedDate,
    label: 'Latest created date',
  },
  {
    fieldName: LAST_ACTIVITY,
    operator: TieBreakersOperatorTypes.Latest,
    value: TieBreakersLabels.LatestActivityDate,
    label: 'Latest activity date',
  },
  {
    fieldName: LAST_MODIFIED_DATE,
    operator: TieBreakersOperatorTypes.Earliest,
    value: TieBreakersLabels.EarliestModifiedDate,
    label: 'Earliest modified date',
  },
  {
    fieldName: CREATED_DATE,
    operator: TieBreakersOperatorTypes.Earliest,
    value: TieBreakersLabels.EarliestCreatedDate,
    label: 'Earliest created date',
  },

  {
    fieldName: LAST_ACTIVITY,
    operator: TieBreakersOperatorTypes.Earliest,
    value: TieBreakersLabels.EarliestActivityDate,
    label: 'Earliest activity date',
  },
];

const SettingsTieBreakersPanel = ({
  readonly,
  action,
  crmOrgId,
  objectType,
  type,
  onChange,
}: SettingsTieBreakersPanelProps) => {
  const { searchSweepFields } = useSweepFields();
  const [objectFields, setObjectFields] = useState<SweepField[]>();
  const { getLabelFromIdsWithObjectType } = useSweepFieldsLabels();

  const getObjectFields = async () => {
    const { sweepFields } = await searchSweepFields({
      objectType: [objectType],
      crmOrgId,
    });
    const fields = sweepFields[objectType].fields;
    setObjectFields(fields);
  };

  const onTieBreakerChange = useCallback(
    (val: string) => {
      // from the value given get the right fields from the object.
      const currentTieBreaker = TieBreakersTypes.find((d) => d.value === val);
      const currentField = objectFields?.find(
        (el) => el.sweepFieldName === currentTieBreaker?.fieldName,
      );

      if (currentField) {
        const _criteria = {
          criterionId: currentField?.id ?? '',
          _fieldIds: [currentField?.id ?? ''],
          _fieldLabels: [objectType, currentField?.sweepFieldName ?? ''],
          operator: currentTieBreaker?.operator ?? '',
          value: '',
          valueType: SelectorValueTypes.LITERAL,
          fieldType: currentField.fieldType,
        };

        onChange([{ criteriaLogic: '1', criteria: [_criteria] }]);
      }
    },
    [objectFields, objectType, onChange],
  );

  const firstTieBreaker = action?.tieBreakers?.[0].criteria?.[0];

  const setDefaultTieBreaker = useCallback(() => {
    if (firstTieBreaker === undefined) {
      onTieBreakerChange(TieBreakersLabels.LatestModifiedDate);
    }
  }, [firstTieBreaker, onTieBreakerChange]);

  const [isPanelExpanded, setIsPanelExpanded] = useState(!!firstTieBreaker);
  useRunOnce(async () => {
    await getObjectFields();
  });

  useEffect(() => {
    setDefaultTieBreaker();
  }, [setDefaultTieBreaker]);

  const getValue = (val?: SweepCriterion) => {
    const _labels = getLabelFromIdsWithObjectType(val?._fieldIds ? val?._fieldIds : []);
    const output = TieBreakersTypes.find(
      (d) => d.operator === val?.operator && d.fieldName === _labels[1],
    );
    return output?.label ?? TieBreakersLabels.LatestModifiedDate;
  };

  return (
    <Box sx={{ ...settingsPanelLayout }}>
      <Collapse in={isPanelExpanded} collapsedSize={24}>
        <Box className="panelHeader" onClick={() => setIsPanelExpanded(!isPanelExpanded)}>
          <Box sx={{ display: 'flex', gap: '16px' }}>
            <Scales variant="large" />
            <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '2px' }}>
              <Typography variant="body-bold">Tie breakers</Typography>
              <Typography variant="body">{labelsByType[type].subTitle}</Typography>
            </Box>
          </Box>
          <Box>
            {isPanelExpanded ? <ChevronUp variant="large" /> : <ChevronDown variant="large" />}
          </Box>
        </Box>
        <Box sx={{ marginTop: '16px', marginLeft: '44px' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '14px',
              alignItems: 'center',
            }}
          >
            <Typography variant="body">Choose how to determine best match:</Typography>
            <Box>
              <SweepSelect
                SelectProps={{
                  disabled: readonly,
                  placeholder: 'Choose rule',
                  value: getValue(firstTieBreaker) ?? '',
                  onChange: (event: SelectChangeEvent<unknown>) => {
                    const val = event.target.value as string;
                    onTieBreakerChange(val);
                  },
                }}
              >
                {TieBreakersTypes.map((type) => (
                  <MenuItem key={type.label} value={type.value}>
                    {type.label}
                  </MenuItem>
                ))}
              </SweepSelect>
            </Box>
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
};

export default SettingsTieBreakersPanel;
