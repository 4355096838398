import { Box } from '@mui/material';
import {
  ActionableDataTable,
  ActionableDataTableActionTypes,
  ActionableDataTableRow,
} from '../../common/table/ActionableDataTable';
import { DataTableColumn, DataTableOnOrderChangeClbk } from '../../common/table/TableTypes';
import { colors } from '@sweep-io/sweep-design';
import { useSweepFieldsLabels } from '../../../sweep-fields/useSweepFieldsLabels';
import { uniqueId } from '../../../lib/uniqueId';

interface SlackField<T> {
  fieldIds: string[];
  isRequired?: boolean;
  fieldData: T;
}

interface SlackFieldsProps<
  T extends SlackActionFields | AutomationActionField | SlackFieldsStruct,
> {
  onChange: (fields: SlackField<T>[], fieldsData: T[]) => any;
  actionFields: SlackField<T>[];
  readonly?: boolean;
}

interface SlackFieldDataRow {
  id: string;
  priority: string;
  value: string;
}

interface SlackField<T> {
  fieldIds: string[];
  isRequired?: boolean;
  fieldData: T;
}

export function SlackFields<
  T extends SlackActionFields | AutomationActionField | SlackFieldsStruct,
>({ onChange, actionFields, readonly }: SlackFieldsProps<T>) {
  const { getLabelFromIdsWithObjectType } = useSweepFieldsLabels();
  const columns: DataTableColumn<SlackFieldDataRow>[] = [
    { field: 'handle', headerName: '', showDragHandle: true, width: '10%' },
    { field: 'value', headerName: '', showDragHandle: false },
  ];

  let rows: (SlackFieldDataRow & ActionableDataTableRow)[] = [];
  if (actionFields?.length > 0) {
    rows = actionFields?.map((rule, idx) => {
      return {
        id: uniqueId(),
        priority: '' + idx,
        value: getLabelFromIdsWithObjectType(rule.fieldIds).join('.'),
        disabledActions: rule.isRequired ? [ActionableDataTableActionTypes.DELETE] : [],
      };
    });
  }

  const onOrderChange: DataTableOnOrderChangeClbk = ({ sourceIndex, destinationIndex }) => {
    const newFields = [...actionFields];
    newFields.splice(sourceIndex, 1);
    newFields.splice(destinationIndex, 0, actionFields[sourceIndex]);
    onChange(
      newFields,
      newFields.map((f) => f.fieldData),
    );
  };

  return (
    <Box
      className={readonly ? 'readonly' : ''}
      sx={{
        width: '444px',
        border: actionFields?.length > 0 ? `1px solid ${colors.grey[300]}` : 'none',
        borderRadius: '3px',
        '& thead': {
          display: 'none',
        },
        '& td.SweepDataTableCell.MuiTableCell-root:first-of-type': {
          width: '40px',
          paddingLeft: '12px !important',
        },
        '&:not(.readonly) td:last-of-type ': {
          width: '60px',
          '& button': {
            marginRight: '15px',
          },
        },
        '& td >div': {
          fontSize: '12px',
          fontWeight: 500,
        },
        '& td.SweepDataTableCell.MuiTableCell-root': {
          padding: '0px !important',
          height: 40,
        },
        '& .SweepActionableDataActions': {
          height: '40px',
        },
      }}
    >
      {rows && rows.length > 0 && (
        <ActionableDataTable
          allowReorder={!readonly}
          columns={columns}
          rows={rows}
          onOrderChange={onOrderChange}
          actions={
            readonly
              ? []
              : [
                  {
                    type: ActionableDataTableActionTypes.DELETE,
                    confirm: false,
                  },
                ]
          }
          onActionClick={({ actionType, idx }) => {
            switch (actionType) {
              case ActionableDataTableActionTypes.DELETE:
                const newFields = [...actionFields];
                newFields.splice(idx, 1);
                onChange(
                  newFields,
                  newFields.map((f) => f.fieldData),
                );
                break;
            }
          }}
        />
      )}
    </Box>
  );
}
