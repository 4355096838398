import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ParsedRecordType,
  ParsedRule,
  ParserResponse,
} from '../components/documentation/ParserTypes';
import {
  calcParsedRecordTypes,
  calcParsedRules,
  mergeFields,
} from '../components/parser/parserUtils';
import { DocumentationPills, ParsedFieldsByObject } from './global/globalReducerTypes';
import { RootState } from '.';
import { selectEnvironments } from './global/globalReducer';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { MASTER_KEY } from '../components/pages/funnel-map-flow/dialogs/import-funnel/utils';
import {
  selectCrmOrgsMap,
  selectDefaultCreationEnvironment,
} from '../components/pages/environments/environmentsReducer';

/**
 * NOTE: PLEASE USE THIS REDUCER WHEN RETRIEVING ANY PARSER DATA!
 *
 * By design most of parser data is kept in globalReducer as it's refreshed with global changes
 * This reducer keeps data parsed on demand and RETURNS ALL COMBINED PARSER DATA
 *
 */

export interface ParserState {
  rulesFromObjectsParsedOnDemand: { [crmOrgId: string]: ParsedRule[] };
  recordTypesFromObjectsParsedOnDemand: { [crmOrgId: string]: ParsedRecordType[] };
  fieldsFromObjectsParsedOnDemand: { [crmOrgId: string]: ParsedFieldsByObject };
  objectsParsedOnDemand: { [crmOrgId: string]: string[] };
  pillsFromObjectsParsedOnDemand: { [crmOrgId: string]: DocumentationPills };
  transientObjects: { [crmOrgId: string]: { [objectName: string]: boolean } };
}

const initialState: ParserState = {
  objectsParsedOnDemand: {},
  rulesFromObjectsParsedOnDemand: {},
  recordTypesFromObjectsParsedOnDemand: {},
  fieldsFromObjectsParsedOnDemand: {},
  pillsFromObjectsParsedOnDemand: {},
  transientObjects: {},
};

export const parserSlice = createSlice({
  name: 'parser',
  initialState,
  reducers: {
    setObjectsParsedOnDemand: (
      state,
      action: PayloadAction<{
        response: ParserResponse;
        objectNames: string[];
        crmOrgId: string;
        fieldsParsedOnDemand: ParsedFieldsByObject;
        pills: DocumentationPills;
      }>,
    ) => {
      const { objectNames, crmOrgId, response, fieldsParsedOnDemand, pills } = action.payload;
      const oldObjectNames = state.objectsParsedOnDemand[crmOrgId] ?? [];
      state.objectsParsedOnDemand[crmOrgId] = [...oldObjectNames, ...objectNames];

      const oldRules = state.rulesFromObjectsParsedOnDemand[crmOrgId] ?? [];
      state.rulesFromObjectsParsedOnDemand[crmOrgId] = [...oldRules, ...calcParsedRules(response)];

      const oldRecordTypes = state.recordTypesFromObjectsParsedOnDemand[crmOrgId] ?? [];
      state.recordTypesFromObjectsParsedOnDemand[crmOrgId] = [
        ...oldRecordTypes,
        ...calcParsedRecordTypes(response),
      ];

      const oldFields = state.fieldsFromObjectsParsedOnDemand[crmOrgId] ?? {};
      state.fieldsFromObjectsParsedOnDemand[crmOrgId] = { ...oldFields, ...fieldsParsedOnDemand };

      const oldPills = state.pillsFromObjectsParsedOnDemand[crmOrgId] ?? {};
      state.pillsFromObjectsParsedOnDemand[crmOrgId] = { ...oldPills, ...pills };
    },
    setTransientObjects: (
      state,
      action: PayloadAction<{
        objectName: string;
        crmOrgId: string;
        isObjectBeingParsed: boolean;
      }>,
    ) => {
      const { crmOrgId, objectName, isObjectBeingParsed } = action.payload;

      if (!state.transientObjects[crmOrgId]) {
        state.transientObjects[crmOrgId] = {};
      }

      state.transientObjects[crmOrgId][objectName] = isObjectBeingParsed;
    },
  },
});

export const { setObjectsParsedOnDemand, setTransientObjects } = parserSlice.actions;

export const selectObjectsParsedOnDemand = (state: RootState) => state.parser.objectsParsedOnDemand;

export const selectTransientObjects = (crmOrgId: string) => (state: RootState) =>
  state.parser.transientObjects[crmOrgId];

export const selectRulesFromObjectsParsedOnDemand = (state: RootState) =>
  state.parser.rulesFromObjectsParsedOnDemand;

export const selectRecordTypesFromObjectsParsedOnDemand = (state: RootState) =>
  state.parser.recordTypesFromObjectsParsedOnDemand;

export const selectFieldsFromObjectParsedOnDemand = (state: RootState) =>
  state.parser.fieldsFromObjectsParsedOnDemand;

export const selectGlobalDocumentationFieldsInOrg = (crmOrgId: string) => (state: RootState) =>
  state.global.environments[crmOrgId]?.data?.documentation.parsedFields;

export const selectGlobalDocumentationData = (crmOrgId: string) => (state: RootState) =>
  state.global.environments[crmOrgId]?.data?.documentation;

export const selectGlobalDocumentationPills = (crmOrgId: string) => (state: RootState) =>
  state.global.environments[crmOrgId]?.data?.documentation.pills;

export const selectParsedObjectNames = createSelector(
  [selectObjectsParsedOnDemand, selectEnvironments, selectDefaultCreationEnvironment],
  (objectsParsedOnDemand, globalEnvironments, crmOrg) => {
    const crmOrgId = crmOrg?.id;
    if (!crmOrgId) return [];

    const objects = objectsParsedOnDemand[crmOrgId] ?? [];
    const globalObjects =
      globalEnvironments[crmOrgId]?.data?.documentation?.parserObjectNames ?? [];

    return uniq([...objects, ...globalObjects]);
  },
);

export const selectParsedRules = createSelector(
  [selectRulesFromObjectsParsedOnDemand, selectEnvironments, selectDefaultCreationEnvironment],
  (rulesFromObjectsParsedOnDemand, globalEnvironments, crmOrg) => {
    const crmOrgId = crmOrg?.id;
    if (!crmOrgId) return [];
    const rules = rulesFromObjectsParsedOnDemand[crmOrgId] ?? [];
    const globalRules = globalEnvironments[crmOrgId]?.data?.documentation?.parsedRules ?? [];

    return uniqBy([...rules, ...globalRules], 'id');
  },
);

export const selectParsedRecordTypes = createSelector(
  [
    selectRecordTypesFromObjectsParsedOnDemand,
    selectEnvironments,
    selectCrmOrgsMap,
    selectDefaultCreationEnvironment,
  ],
  (rtFromObjectsParsedOnDemand, globalEnvironments, crmOrgsMap, crmOrg) => {
    const crmOrgId = crmOrg?.id;
    if (!crmOrgId) return [];

    const _crmOrgObjects = crmOrgsMap[crmOrgId]?.objectTypes ?? [];

    const rts = rtFromObjectsParsedOnDemand[crmOrgId] ?? [];
    const globalRts = globalEnvironments[crmOrgId]?.data?.documentation?.parsedRecordTypes ?? [];

    const missingRecordTypes: ParsedRecordType[] = [];
    rts?.forEach((rt) => {
      if (
        globalRts?.findIndex(
          (recordType) =>
            recordType.name === rt.name && recordType.objectApiName === rt.objectApiName,
        ) === -1
      ) {
        missingRecordTypes.push(rt);
      }
    });

    const parsedRecordTypes = [...globalRts, ...missingRecordTypes].map((rt) => {
      //By product definition if in Documentation we display MASTER RT it should display object label as label
      if (rt.name === MASTER_KEY) {
        const objectLabel = _crmOrgObjects?.find((ob) => ob.objectType === rt.objectApiName)?.label;
        return { ...rt, label: objectLabel ?? rt.objectApiName };
      }

      return rt;
    });

    return parsedRecordTypes;
  },
);

export const selectParsedFields = createSelector(
  [selectFieldsFromObjectParsedOnDemand, selectEnvironments, selectDefaultCreationEnvironment],
  (fieldsFromObjectsParsedOnDemand, globalEnvironments, crmOrg) => {
    const crmOrgId = crmOrg?.id;
    if (!crmOrgId) return {} as ParsedFieldsByObject;

    const fields = fieldsFromObjectsParsedOnDemand[crmOrgId] ?? {};
    const globalFields = globalEnvironments[crmOrgId]?.data?.documentation?.parsedFields ?? {};

    return mergeFields(globalFields, fields, {}) as ParsedFieldsByObject | undefined;
  },
);

export const selectPillsFromObjectParsedOnDemand = (crmOrgId: string) => (state: RootState) =>
  state.parser.pillsFromObjectsParsedOnDemand[crmOrgId];

export default parserSlice.reducer;
