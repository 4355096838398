import { useState, useCallback, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDocumentation } from '../useDocumentation';
import uniqueId from 'lodash/uniqueId';
import { useCrmOrgsApiFacade } from '../../../apis/facades/useCrmOrgsApiFacade';
import {
  selectUniversalSearch,
  setUniversalSearchIsResultLoading,
  setUniversalSearchResults,
  setDependenciesConfigurationItem,
  setUniversalSearchTxt,
  clearUniversalSearch,
  setUniversalSearchFilter,
  clearDependencies,
  addConfigurationItems,
  selectLoader,
  selectObjects,
  selectLayoutsByObjectName,
} from '../../../reducers/documentationReducer';
import { OPTION_ALL, getTypeOptionsWithCounter } from '../../common/single-select-filter/utils';
import { ConfigurationType, NewDependencyProps } from '../dependencies/types';
import { configurationTypesOptions } from './utils';
import useObjectTypesWithFetch from '../../../hooks/useObjectTypesWithFetch';
import { useFeatureToggle } from '../../common/useFeatureToggle';
import { telemetry } from '../../../telemetry';
import { useLayouts } from '../useLayouts';

export type SearchResponse = {
  //should be the same as BE
  [ConfigurationType.apexClasses]: ConfigurationInstanceData[];
  [ConfigurationType.apexTriggers]: ConfigurationInstanceData[];
  [ConfigurationType.approvalProcesses]: ConfigurationInstanceData[];
  [ConfigurationType.fields]: FieldData[];
  [ConfigurationType.flows]: FlowMetadataRecordProperties[];
  [ConfigurationType.objects]: NameProperties[];
  [ConfigurationType.processBuilderFlows]: FlowMetadataRecordProperties[];
  [ConfigurationType.validationRules]: ConfigurationInstanceData[];
  [ConfigurationType.workflowRules]: ConfigurationInstanceData[];
};

export const useUniversalSearch = (crmOrgId: string) => {
  const dispatch = useDispatch();

  const universalSearch = useSelector(selectUniversalSearch);
  const {
    results,
    prevSearchText,
    searchText,
    isResultsLoading,
    isUniversalSearchListOpen,
    filterKey,
  } = universalSearch ?? {};

  const { searchByLabel } = useFeatureToggle();
  const { fetchLayoutsData } = useLayouts();

  const { isLoading: _isLoadingObjects, objectTypesByName } = useObjectTypesWithFetch({
    crmOrgId,
    useSfObjects: true,
  });

  const showLoader = useSelector(selectLoader(crmOrgId));
  const layoutsByObjectName = useSelector(selectLayoutsByObjectName(crmOrgId));

  const filterOptions = [
    OPTION_ALL,
    ...getTypeOptionsWithCounter(
      configurationTypesOptions,
      (type) => results?.[type.value as keyof SearchResponse]?.length ?? 0,
    ),
  ];

  const filteredResults =
    filterKey && filterKey !== OPTION_ALL.value
      ? ({ [filterKey]: results?.[filterKey as keyof SearchResponse] } as SearchResponse)
      : results;

  const [isLoadingDependency, setIsLoadingDependency] = useState(false);

  const { get_universalSearchResults } = useCrmOrgsApiFacade();
  const { onObjectNotInFunnelMapClick, dispatchSingleObjectName } = useDocumentation();

  const objects = useSelector(selectObjects);

  const onClearButtonClick = useCallback(() => {
    dispatch(clearUniversalSearch());
    dispatch(clearDependencies());
  }, [dispatch]);

  const onSearchKeydown = useCallback(
    async (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (!crmOrgId) {
        return null;
      }

      if (event.key === 'Enter' && searchText !== '' && searchText?.trim() !== prevSearchText) {
        const loadingId = uniqueId();
        dispatch(setUniversalSearchIsResultLoading({ isLoading: true, id: loadingId }));

        try {
          const results = await get_universalSearchResults({
            crmOrgId,
            searchTxt: searchText ?? '',
            searchLabel: searchByLabel,
          });
          dispatch(setUniversalSearchResults({ results, loadingId }));
        } catch (e) {
          telemetry.captureError(e);
          dispatch(setUniversalSearchResults({ results: {} as SearchResponse, loadingId }));
        }
      } else if (event.key === 'Enter' && searchText === '') {
        onClearButtonClick();
      }
    },
    [
      get_universalSearchResults,
      searchByLabel,
      dispatch,
      prevSearchText,
      crmOrgId,
      searchText,
      onClearButtonClick,
    ],
  );

  const onListItemClick = useCallback(
    async (props: NewDependencyProps) => {
      const { parentType, dependencyType, name, id, objectName, clearHistory } = props;
      let item: ConfigurationItem | NameProperties | undefined;

      const configurationItems = results?.[parentType as keyof SearchResponse];

      switch (parentType) {
        case ConfigurationType.objects:
          const objectTypeName = objects.favorites?.find((object) => object.objectType === name);

          if (!!objectTypeName) {
            dispatchSingleObjectName(objectTypeName);
          } else {
            const _objectTypeName = objectTypesByName[name];
            setIsLoadingDependency(true);

            if (_objectTypeName) {
              await onObjectNotInFunnelMapClick(_objectTypeName);
            } else {
              telemetry.captureError(new Error('Couldnt find objectName for:'), { name });
            }
            setIsLoadingDependency(false);
          }
          break;

        case ConfigurationType.layouts:
          const layouts = layoutsByObjectName?.[objectName ?? ''];

          if (!layouts?.layouts && objectName) {
            setIsLoadingDependency(true);
            await fetchLayoutsData({ crmOrgId, objectName: objectName });
            setIsLoadingDependency(false);
          }
          break;

        case ConfigurationType.fields:
          item = configurationItems?.find(
            (_item: any) => _item.name === name && _item.objectName === objectName,
          );
          break;

        default:
          item = configurationItems?.find((_item: any) => _item.id === id);
          break;
      }

      if (!item) {
        telemetry.captureError(
          `Dependency item not found id: ${id}, parentType: ${parentType}, dependencyType: ${dependencyType} in object: ${objectName}`,
        );
        return;
      }

      //if user wants to drill inside search results the item needs to be added to configuration items first
      dispatch(
        addConfigurationItems({
          crmOrgId,
          newConfigurationItems: { [parentType]: [item] } as any,
        }),
      );

      dispatch(
        setDependenciesConfigurationItem({
          id,
          parentType,
          dependencyType: dependencyType ?? '',
          name,
          objectName,
          clearHistory,
        }),
      );
    },
    [
      dispatch,
      onObjectNotInFunnelMapClick,
      dispatchSingleObjectName,
      crmOrgId,
      results,
      objectTypesByName,
      objects.favorites,
      layoutsByObjectName,
      fetchLayoutsData,
    ],
  );

  //To be deprecated
  const onChooseNewRule = useCallback(
    async (props: NewDependencyProps) => {
      const { parentType, dependencyType, name, id, objectName, clearHistory } = props;

      if (parentType === ConfigurationType.objects) {
        const objectTypeName = objects.favorites?.find((object) => object.objectType === name);

        if (!!objectTypeName) {
          dispatchSingleObjectName(objectTypeName);
        } else {
          const _objectTypeName = objectTypesByName[name];
          setIsLoadingDependency(true);

          if (_objectTypeName) {
            await onObjectNotInFunnelMapClick(_objectTypeName);
          } else {
            telemetry.captureError(new Error('Couldnt find objectName for:'), { name });
          }
          setIsLoadingDependency(false);
        }
      } else {
        if (parentType === ConfigurationType.layouts) {
          const layouts = layoutsByObjectName?.[objectName ?? ''];

          if (!layouts?.layouts && objectName) {
            setIsLoadingDependency(true);
            await fetchLayoutsData({ crmOrgId, objectName: objectName });
            setIsLoadingDependency(false);
          }
        }

        const _id = parentType === ConfigurationType.fields ? '' : id; //fields don't have real ids
        const configurationItems = results?.[parentType as keyof SearchResponse];

        const item = configurationItems?.find((_item: any) =>
          parentType === ConfigurationType.fields
            ? _item.name === name && _item.objectName === objectName
            : _item.id === id,
        );

        //if user wants to drill inside search results the item needs to be added to configuration items first
        dispatch(
          addConfigurationItems({
            crmOrgId,
            newConfigurationItems: { [parentType]: [item] } as any,
          }),
        );

        dispatch(
          setDependenciesConfigurationItem({
            id: _id,
            parentType,
            dependencyType: dependencyType ?? '',
            name,
            objectName,
            clearHistory,
          }),
        );
      }
    },
    [
      dispatch,
      onObjectNotInFunnelMapClick,
      dispatchSingleObjectName,
      crmOrgId,
      results,
      objectTypesByName,
      objects.favorites,
      layoutsByObjectName,
      fetchLayoutsData,
    ],
  );

  const onSearchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      dispatch(setUniversalSearchTxt({ searchTxt: event ? event.target.value : '' }));
    },
    [dispatch],
  );

  const onSelectedFilterItem = useCallback(
    (filterKey?: ConfigurationType) => {
      dispatch(setUniversalSearchFilter({ filterKey }));
    },
    [dispatch],
  );

  return {
    onSearchKeydown,
    onChooseNewRule,
    onClearButtonClick,
    onSearchChange,
    onSelectedFilterItem,
    searchTxt: searchText,
    isLoadingDependency,
    isLoadingObjects: _isLoadingObjects || showLoader,
    isUniversalSearchListOpen,
    isLoadingResults: isResultsLoading,
    results: filteredResults,
    filterOptions,
    selectedFilterValue: filterKey,
    onListItemClick,
  };
};
