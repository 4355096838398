import { Box, MenuItem, Popover, SelectChangeEvent } from '@mui/material';
import { Button, Typography, colors } from '@sweep-io/sweep-design';
import { useRef, useState } from 'react';
import { ErrorPopover } from './ErrorPopover';
import SweepSelect from './SweepSelect';
import { SFDCObjectType } from '../../types/enums/SFDCObjectType';
import { AutomationNotifyEmail } from '../Automations/AutomationNotifyEmail';
import { SlackNotificationAction } from '../Automations/slack/SlackNotificationAction';
import slackSampleImg from '../Automations/images/slackSample.png';

export const enum NotifyWays {
  EMAIL = 'SEND_EMAIL',
  SLACK = 'SEND_SLACK_MESSAGE',
}

const NotificationTypes = [
  { type: NotifyWays.EMAIL, label: 'Send Email' },
  { type: NotifyWays.SLACK, label: 'Post to Slack' },
];

export const NotifyUsersWrapper = ({
  notificationType,
  readonly,
  crmOrgId,
  setNotificationType,
  onChange,
  action,
  displayError,
  objectName,
}: {
  notificationType?: NotifyWays;
  readonly?: boolean;
  crmOrgId: string;
  setNotificationType: (notificationType: NotifyWays) => void;
  onChange: (newObj: any) => void;
  action: any;
  displayError?: boolean;
  objectName: SFDCObjectType;
}) => {
  const criterionRef = useRef<HTMLDivElement | unknown>();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isSampleOpen = Boolean(anchorEl);

  return (
    <>
      <Box
        ref={criterionRef}
        sx={{
          background: colors.grey[100],
          padding: '24px 24px 0',
          borderRadius: '8px 8px 0 0',
          marginTop: '12px',
          marginBottom: '-22px',
        }}
      >
        <Typography variant="caption" color={colors.grey[800]}>
          Choose how to notify
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
          <SweepSelect
            FormControlProps={{
              sx: { display: 'block' },
            }}
            SelectProps={{
              disabled: readonly,
              value: action?.actionType ?? '',
              onChange: (event: SelectChangeEvent<unknown>) => {
                const val = event.target.value;
                setNotificationType(val as NotifyWays);
                const newObj = { ...action, actionType: val };
                onChange(newObj);
              },
              renderValue: (val: any) => {
                const output = NotificationTypes.filter((d) => d.type === val);
                if (output.length > 0) {
                  return output[0].label ?? '';
                }
              },
            }}
          >
            {NotificationTypes.map((item) => (
              <MenuItem key={item.type} value={item.type}>
                {item.label}
              </MenuItem>
            ))}
          </SweepSelect>
          {notificationType === NotifyWays.SLACK && (
            <Box sx={{ ml: 2 }}>
              <Button
                onClick={(e) => setAnchorEl(e.currentTarget)}
                startIconName={isSampleOpen ? 'Hide' : 'Show'}
                variant="link"
              >
                {isSampleOpen ? 'Hide example' : 'Show example'}
              </Button>
              <Popover
                open={isSampleOpen}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <Box sx={{ p: '4px', pb: 0 }}>
                  <img src={slackSampleImg} width={'665px'} />
                </Box>
              </Popover>
            </Box>
          )}
        </Box>
      </Box>

      {notificationType === NotifyWays.EMAIL && (
        <AutomationNotifyEmail
          action={action as EmailAutomationAction}
          crmOrgId={crmOrgId}
          onChange={onChange}
          objectName={objectName}
          readonly={readonly}
        />
      )}
      {notificationType === NotifyWays.SLACK && (
        <SlackNotificationAction
          readonly={readonly}
          action={action as SlackAutomationAction}
          crmOrgId={crmOrgId}
          onChange={onChange}
          objectName={objectName}
        />
      )}

      <ErrorPopover
        isOpen={!!displayError}
        errorText="Make sure all fields are filled"
        criterionRef={criterionRef.current || undefined}
        onClose={() => {}}
      />
    </>
  );
};
