import { Box } from '@mui/material';
import { Button, Typography, colors } from '@sweep-io/sweep-design';
import { GroupMembersTable } from './GroupMembersTable';
import { useSelector } from 'react-redux';
import { useCallback, useMemo, useState } from 'react';
import {
  AssignmentGroupDialogElements,
  GroupSettingsDialog,
} from '../group-settings/GroupSettingsDialog';
import { useSweepNotifications } from '../../../notifications/useSweepNotifications';
import { SweepNotificationVariant } from '../../../../reducers/notificationsReducer';
import { AvatarPicture } from '../../../avatar/AvatarPicture';
import { selectAssignmentGroupById } from '../../../../reducers/assignmentGroupsPageReducer';
import { useAssignmentsApiWithReducer } from '../useAssignmentsApiWithReducer';
import { GroupMembersSkeletonTable } from './GroupMembersSkeletonTable';
import RestrictedTooltip from '../../../common/permissions/RestrictedTooltip';
import usePermission from '../../../common/permissions/usePermission';
import { AssignmentGroup, AssignmentGroupMember } from '../../../../reducers/assignmentGroupTypes';
import { cloneDeep } from 'lodash';
import { SearchInput } from '../../../common/SearchInput';
import { useRunOnce } from '../../../common/useRunOnce';
import { telemetry } from '../../../../telemetry';

const ONLY_MEMBERS_ELEMENTS = [AssignmentGroupDialogElements.Members];
const AVATARS_AND_DESCRIPTION_AND_LIMIT_MEMBERS_ELEMENTS = [
  AssignmentGroupDialogElements.AvatarAndDescription,
  AssignmentGroupDialogElements.LimitMembers,
];

const GroupMembersList = ({
  onBackClick,
  groupId,
}: {
  onBackClick: () => void;
  groupId: string;
}) => {
  const { addNotification } = useSweepNotifications();
  const [membersDialogOpen, setMembersDialogOpen] = useState(false);
  const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const assignmentGroupData = useSelector(selectAssignmentGroupById(groupId));
  const [isAllowedBtn] = usePermission(['edit:assignment-groups']);

  const closeSettingsDialog = useCallback(() => {
    setSettingsDialogOpen(false);
  }, []);

  const closeMembersDialog = useCallback(() => setMembersDialogOpen(false), []);

  const { getAssignmentGroupData, saveAssignmentGroupData } = useAssignmentsApiWithReducer();

  const writableAssignmentGroupData = useMemo(
    () => cloneDeep(assignmentGroupData),
    [assignmentGroupData],
  );

  const partialSave = useCallback(
    async (assignmentGroup: Partial<AssignmentGroup>, resetAllMembersLimit?: number) => {
      if (!writableAssignmentGroupData) {
        return;
      }
      try {
        await saveAssignmentGroupData(
          { ...writableAssignmentGroupData, ...assignmentGroup },
          resetAllMembersLimit,
        );
      } catch (e) {
        telemetry.captureError(e);
        addNotification({
          message: 'Error Saving Assignment Group',
          variant: SweepNotificationVariant.Error,
        });
      }
    },
    [addNotification, writableAssignmentGroupData, saveAssignmentGroupData],
  );

  useRunOnce(() => {
    (async function () {
      try {
        await getAssignmentGroupData(groupId);
      } catch (e) {
        telemetry.captureError(e);
        addNotification({
          message: 'Error Loading Assignment Groups',
          variant: SweepNotificationVariant.Error,
        });
      }
    })();
  });

  return (
    <Box
      sx={{
        position: 'relative',
        top: '-30px',
        pointerEvents: 'none',
      }}
    >
      <Box sx={{ pointerEvents: 'all', display: 'flex', width: 'fit-content' }}>
        <Button variant="link" onClick={onBackClick} startIconName="ChevronLeft">
          Back to groups
        </Button>
      </Box>
      {!writableAssignmentGroupData && <GroupMembersSkeletonTable />}
      {writableAssignmentGroupData && (
        <>
          <Box
            p={0}
            pt="10px"
            display="flex"
            flexDirection="column"
            gap="10px"
            sx={{ pointerEvents: 'all' }}
          >
            <Box display="flex" justifyContent="space-between">
              <Box display="flex" gap={3}>
                <Box sx={{ width: '72px', height: '72px' }}>
                  <AvatarPicture
                    dimension={72}
                    avatar={writableAssignmentGroupData?.avatar}
                    isInactive={false}
                  />
                </Box>
                <Box display="flex" alignItems="center">
                  <Box display="flex" flexDirection="column" gap="4px">
                    <Typography variant="h1-bold">{writableAssignmentGroupData?.name}</Typography>
                    <Typography variant="body" color={colors.grey[700]}>
                      {writableAssignmentGroupData?.description}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ height: '40px', gap: 1, display: 'flex', marginTop: '6px' }}>
                <RestrictedTooltip
                  to={['edit:assignment-groups']}
                  notAllowedTitle={
                    'To modify the settings of this assignment group, please contact your admin.'
                  }
                >
                  <Button
                    disabled={!isAllowedBtn}
                    startIconName="Settings"
                    onClick={() => setSettingsDialogOpen(true)}
                    variant="outlined"
                  >
                    Group settings
                  </Button>
                </RestrictedTooltip>
                <RestrictedTooltip
                  to={['edit:assignment-groups']}
                  notAllowedTitle={
                    'To modify the settings of this assignment group, please contact your admin.'
                  }
                >
                  <Button
                    disabled={!isAllowedBtn}
                    startIconName="UserPlus"
                    onClick={() => setMembersDialogOpen(true)}
                  >
                    Manage members
                  </Button>
                </RestrictedTooltip>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" gap={2}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h4">
                  {writableAssignmentGroupData?.members.length} Group Member
                  {writableAssignmentGroupData?.members &&
                  writableAssignmentGroupData.members.length > 1
                    ? 's'
                    : ''}
                </Typography>
                <Box>
                  <SearchInput
                    withFixedMagnifyingGlassIcon
                    TextFieldProps={{
                      value: searchText,
                      placeholder: 'Search',
                      onChange: (e) => {
                        setSearchText(e.target.value);
                      },
                    }}
                    onClearButtonClick={() => setSearchText('')}
                  />
                </Box>
              </Box>
              {writableAssignmentGroupData && (
                <GroupMembersTable
                  groupData={writableAssignmentGroupData}
                  filterFunc={(member: AssignmentGroupMember) =>
                    member.name.toLowerCase().includes(searchText.toLowerCase())
                  }
                />
              )}
            </Box>
          </Box>
          {writableAssignmentGroupData && membersDialogOpen && (
            <GroupSettingsDialog
              open
              assignmentGroupData={writableAssignmentGroupData}
              onClose={closeMembersDialog}
              onSave={partialSave}
              elements={ONLY_MEMBERS_ELEMENTS}
            />
          )}
          {writableAssignmentGroupData && settingsDialogOpen && (
            <GroupSettingsDialog
              open
              assignmentGroupData={writableAssignmentGroupData}
              onClose={closeSettingsDialog}
              onSave={partialSave}
              elements={AVATARS_AND_DESCRIPTION_AND_LIMIT_MEMBERS_ELEMENTS}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default GroupMembersList;
