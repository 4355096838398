import { Box, Stack } from '@mui/material';
import { Button, Typography } from '@sweep-io/sweep-design';
import PinkButton from './PinkButton';
import { ReactNode } from 'react';
import usePaywall from '../header/usePaywall';
import { scheduleCall } from '../customerSupportHelper';
import { useSelector } from 'react-redux';
import { selectUserInfoData } from '../../../reducers/userInfoReducer';

const PaywallBlockUpgrade = ({
  title,
  featureDescription,
  img,
}: {
  title: string | ReactNode;
  featureDescription: string;
  img: ReactNode;
}) => {
  const userInfo = useSelector(selectUserInfoData);
  const { openIntercomForUpgrade } = usePaywall();
  return (
    <Stack alignItems="center" gap={2}>
      <Box>{img}</Box>
      <Box textAlign="center" maxWidth="550px">
        <Typography variant="h1">{title}</Typography>
      </Box>
      <Box textAlign="center" maxWidth="500px" mb={2}>
        <Typography variant="body">
          Book a meeting to access premium features like {featureDescription}.
        </Typography>
      </Box>
      <Box display="flex" gap={2} justifyContent="center" alignItems="center">
        <Button startIconName="Chats" onClick={openIntercomForUpgrade} variant="outlined">
          Chat with us
        </Button>
        <PinkButton
          onClick={() => {
            scheduleCall(userInfo);
          }}
        >
          Book a meeting
        </PinkButton>
      </Box>
    </Stack>
  );
};

export default PaywallBlockUpgrade;
