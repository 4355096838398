import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { RoleGroupInterface, RoleGroupType } from '@server/role-group-interface';
import { FormikProps, useFormik } from 'formik';
import { roleValidationSchema } from './validationSchema';
import { useRunOnceWhenTruthy } from '../../common/useRunOnceWhenTruthy';
import isEqual from 'lodash/isEqual';

interface RoleManagerContextType {
  setRoleManager: Dispatch<SetStateAction<boolean>>;
  editRole?: RoleGroupInterface;
  setEditRole: (role?: RoleGroupInterface) => void;
  headerText: string;
  isRoleManagerOpen: boolean;
}

const RoleManagerContext = createContext<RoleManagerContextType>({
  setEditRole: () => {},
  setRoleManager: () => {},
  headerText: 'Manage roles',
  isRoleManagerOpen: false,
});

export const RoleManagerProvider = ({ children }: { children: React.ReactNode }) => {
  const [editRole, setEditRole] = useState<RoleGroupInterface>();
  const headerText = editRole ? editRole.name : 'Manage roles';
  const [isRoleManagerOpen, setRoleManager] = useState(false);

  return (
    <RoleManagerContext.Provider
      value={{ editRole, setEditRole, headerText, isRoleManagerOpen, setRoleManager }}
    >
      {children}
    </RoleManagerContext.Provider>
  );
};

export const useRoleManagerContext = () => {
  const { editRole, setEditRole, headerText, isRoleManagerOpen, setRoleManager } =
    useContext(RoleManagerContext);
  const [initialEditRole, setInitialEditRole] = useState(editRole);

  useRunOnceWhenTruthy(() => {
    setInitialEditRole(editRole);
    validateForm();
  }, !!editRole);

  const formik: FormikProps<RoleGroupInterface> = useFormik({
    initialValues: editRole ?? ({} as RoleGroupInterface),
    validationSchema: roleValidationSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: () => {},
  });

  const { setFieldValue, resetForm, isValid, values, validateForm } = formik;

  const updateRole = useCallback(
    (key: string, value: string | string[]) => {
      setEditRole({ ...editRole, [key]: value } as RoleGroupInterface);
    },
    [editRole, setEditRole],
  );

  useEffect(() => {
    if (editRole) {
      Object.keys(editRole).forEach((key) => {
        setFieldValue(key, editRole[key as keyof RoleGroupInterface]).then(() => validateForm());
      });
    }
  }, [editRole, setFieldValue, validateForm]);

  const goBack = useCallback(() => {
    setEditRole();
    resetForm();
    validateForm();
  }, [setEditRole, resetForm, validateForm]);

  const isSystemRole = editRole?.type === RoleGroupType.SYSTEM;

  return {
    editRole:
      editRole && Object.keys(editRole).length > 0 ? (isSystemRole ? editRole : values) : undefined,
    isValid,
    updateRole,
    initializeEditRole: setEditRole,
    goBack,
    headerText,
    isRoleManagerOpen,
    setRoleManager,
    hasChanges: !isEqual(editRole, initialEditRole),
  };
};
