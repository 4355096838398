import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';

export enum CloudingoConnectionStatus {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
}
export enum SlackConnectionStatus {
  Connected = 'connected',
  Error = 'error',
  InvalidCredentials = 'invalid_credentials',
  Initialization = 'initialization',
  NotInitialized = 'not_initialized',
}

export interface IntegrationsState {
  slackIntegrationStatus: SlackConnectionStatus;
  upgradeRequired?: boolean;
  cloudingoIntegrationStatus: CloudingoConnectionStatus;
}

const initialState: IntegrationsState = {
  slackIntegrationStatus: SlackConnectionStatus.NotInitialized,
  upgradeRequired: false,
  cloudingoIntegrationStatus: CloudingoConnectionStatus.DISABLED,
};

export const integrationsSlice = createSlice({
  name: 'integrations',
  initialState,
  reducers: {
    setSlackIntegrationStatus: (state, action: PayloadAction<SlackConnectionStatus>) => {
      state.slackIntegrationStatus = action.payload;
    },
    setSlackIntegrationFullStatus: (
      state,
      action: PayloadAction<{
        slackIntegrationStatus: SlackConnectionStatus;
        upgradeRequired: boolean;
      }>,
    ) => {
      state.slackIntegrationStatus = action.payload.slackIntegrationStatus;
      state.upgradeRequired = action.payload.upgradeRequired ?? false;
    },
    setCloudingoIntegrationStatus: (state, action: PayloadAction<CloudingoConnectionStatus>) => {
      state.cloudingoIntegrationStatus = action.payload;
    },
  },
});

export const {
  setSlackIntegrationStatus,
  setSlackIntegrationFullStatus,
  setCloudingoIntegrationStatus,
} = integrationsSlice.actions;

export const selectSlackIntegrationStatus = (state: RootState) =>
  state.integrations.slackIntegrationStatus;
export const selectCloudingoIntegrationStatus = (state: RootState) =>
  state.integrations.cloudingoIntegrationStatus;

export const selectSlackUpgradeStatus = (state: RootState) => state.integrations.upgradeRequired;

export default integrationsSlice.reducer;
