import { Box, Divider, Tooltip } from '@mui/material';
import { useState } from 'react';
import { Button } from '@sweep-io/sweep-design';
import { useSelector } from 'react-redux';
import { selectCrmOrgs } from '../../environments/environmentsReducer';
import usePermission from '../../../common/permissions/usePermission';
import { selectUserInfoData } from '../../../../reducers/userInfoReducer';
import { SweepRoles } from '../../../../hooks/useUserInfo';
import { UNTITLED_FUNNEL_MAP_NAME } from '../../../../constants';
import { FunnelMapSaveData } from '../../funnel-map-flow/auto-save/useAutoSave';
import DeployDialog from '../../funnel-map-flow/dialogs/deploy/DeployDialog';
import DeploymentChangesDialog from '../../funnel-map-flow/dialogs/deployment-changes/DeploymentChangesDialog';
import { InvitationsDialog } from '../../funnel-map-flow/dialogs/invitations/InvitationsDialog';
import { RestoreDialog } from '../../funnel-map-flow/dialogs/restore/RestoreDialog';
import { useFunnelMapReadOnly } from '../../funnel-map-flow/read-only/useFunnelMapReadOnly';
import RequestChangesContainer from '../../funnel-map-flow/request-changes';
import { AutoSave } from '../../funnel-map-flow/auto-save/AutoSave';
import { EnvSelector } from '../../environments/connect-env/EnvSelector';
import { useConfigurationCanvas } from '../../configuration-canvas/public/useConfigurationCanvas';
import { usePanels } from '../../../panels/PanelsContext';
import usePaywall from '../../../dashboard/header/usePaywall';
import CanvasDeployPaywallBlock from './CanvasDeployPaywallBlock';
import CopilotChatCanvas from './canvas-page/CopilotChatCanvas';
import { useFeatureToggle } from '../../../common/useFeatureToggle';
import { usePageHelpers } from './usePageHelpers';

export interface FunnelMapRightMenuProps {
  funnelMap: FunnelMap;
  lastUpdatedAt?: string;
  funnelMapEmpty?: boolean;
}

export const CanvasUnitedRightMenu = ({
  lastUpdatedAt,
  funnelMap,
  funnelMapEmpty,
}: FunnelMapRightMenuProps) => {
  const { onRestoreCallback, restoreFunnelName, versionName, funnelName } = useFunnelMapReadOnly();
  const { maybeCloseActivePanel } = usePanels();
  const { canvasCrmOrgId } = useConfigurationCanvas();
  const { funnelId, snapshotId } = usePageHelpers();
  const { copilot } = useFeatureToggle();

  const [isRestorePopupOpen, setIsRestorePopupOpen] = useState(false);
  const [isDeployDialogOpen, setIsDeployDialogOpen] = useState(false);
  const [isInvitationDialogOpen, setIsInvitationDialogOpen] = useState(false);

  const [isAllowDeploy] = usePermission(['create:deploy-job']);
  const userInfo = useSelector(selectUserInfoData);

  const orgs = useSelector(selectCrmOrgs);

  const { isPaywallActive } = usePaywall();
  const [isPaywallDialogOpen, setIsPaywallDialogOpen] = useState(false);

  const onDeployClick = () => {
    if (isPaywallActive) {
      setIsPaywallDialogOpen(true);
    } else {
      setIsDeployDialogOpen(true);
    }
  };

  const renderDeployButton = () => {
    if (userInfo?.role === SweepRoles.Collaborator) {
      return (
        <Tooltip title="Request changes">
          <span>
            <Button size="small" onClick={onDeployClick} endIconName="SweepBug">
              Request to deploy
            </Button>
          </span>
        </Tooltip>
      );
    }

    let tooltipText = undefined;
    if (funnelMapEmpty) {
      tooltipText = 'In order to deploy you need to add at least one funnel';
    }

    if (orgs.length === 0) {
      tooltipText = 'In order to deploy you need to connect at least one Salesforce environment';
    }

    if (!isAllowDeploy) {
      tooltipText = `You don't have permissions to deploy`;
    }

    return (
      <Tooltip title={tooltipText}>
        <span>
          <Button
            size="small"
            onClick={onDeployClick}
            disabled={Boolean(tooltipText)}
            endIconName="SweepBug"
          >
            Deploy
          </Button>
        </span>
      </Tooltip>
    );
  };

  const { id, funnels, name } = funnelMap;
  const funnelMapSaveData: FunnelMapSaveData = {
    id,
    funnels,
    name,
  };

  const menuContentEdit = (
    <>
      {lastUpdatedAt && (
        <AutoSave
          funnelMapLastUpdatedAt={lastUpdatedAt}
          funnelMapSaveData={funnelMapSaveData}
          funnelsData={funnelMap.funnelsData}
        />
      )}

      <InvitationButton openInvitationDialog={() => setIsInvitationDialogOpen(true)} />

      <RequestChangesContainer funnelIds={Object.keys(funnelMap.funnelsData)} />
      {renderDeployButton()}

      {isPaywallDialogOpen && (
        <CanvasDeployPaywallBlock closeDialog={() => setIsPaywallDialogOpen(false)} />
      )}
    </>
  );

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '12px',
        }}
        data-testid="funnel-flow-right-menu"
      >
        {copilot && <CopilotChatCanvas />}

        <EnvSelector
          variant="icon-button"
          menuProps={{
            anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
            transformOrigin: { vertical: 'top', horizontal: 'right' },
          }}
          allowDeleteLastEnv={false}
          onChangeEnvCallback={maybeCloseActivePanel}
        />
        <Divider orientation="vertical" variant="middle" flexItem />
        {menuContentEdit}
      </Box>

      {isDeployDialogOpen && (
        <DeployDialog
          open
          setIsOpen={setIsDeployDialogOpen}
          initialCrmOrgId={canvasCrmOrgId}
          funnels={Object.values(funnelMap.funnelsData)}
          funnelMapId={id}
        />
      )}

      <DeploymentChangesDialog />

      {isRestorePopupOpen && lastUpdatedAt && (
        <RestoreDialog
          funnelName={restoreFunnelName}
          onConfirm={() =>
            funnelId &&
            funnelName &&
            snapshotId &&
            onRestoreCallback({ funnelId, funnelName, snapshotId })
          }
          closeDialog={() => setIsRestorePopupOpen(false)}
          versionName={versionName}
          versionDate={lastUpdatedAt}
        />
      )}

      {isInvitationDialogOpen && (
        <InvitationsDialog
          funnelMapId={funnelMap.id}
          funnelMapName={funnelMap.name ?? UNTITLED_FUNNEL_MAP_NAME}
          isOpen={isInvitationDialogOpen}
          onClose={() => setIsInvitationDialogOpen(false)}
        />
      )}
    </>
  );
};

const InvitationButton = ({ openInvitationDialog }: { openInvitationDialog: () => void }) => (
  <Button size="small" variant="outlined" onClick={openInvitationDialog}>
    Share
  </Button>
);
