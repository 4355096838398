import { Box } from '@mui/material';
import { Typography, colors } from '@sweep-io/sweep-design';
import { DIMENSION_AVATAR_SMALL } from '../../../constants';
import { UserInfo } from '../../../types/UserInfoTypes';
import { AvatarPicture } from '../../avatar/AvatarPicture';
import UserSummaryBasic from '../../common/invite-users/UserSummaryBasic';
import { getIsUserInactive } from '../../../lib/getIsUserActive';
import { useFeatureToggle } from '../../common/useFeatureToggle';

interface UserSummaryProps {
  user?: UserInfo;
  isActive?: boolean;
}

const UserSummaryForHeader = ({ user = {}, isActive }: UserSummaryProps) => {
  const { name, status } = user || {};
  const { paywall } = useFeatureToggle();

  return (
    <UserSummaryBasic
      className={`header-user-summary ${isActive ? 'active' : ''}`}
      data-testid="header-user-summary"
    >
      <div className="user-summary-avatar">
        <AvatarPicture
          avatar={{ emoji: user.emoji, imageUrl: user.imageUrl }}
          dimension={DIMENSION_AVATAR_SMALL}
          clickable={true}
          isInactive={getIsUserInactive(status)}
          data-testid="user-summary-avatar-header"
        />
      </div>
      {!paywall && <Box
        display="flex"
        flexDirection="column"
        alignItems="end"
        overflow="hidden"
        className="text-info"
      >
        <Typography
          ellipsis
          variant="body-bold"
          color={colors.white}
        >
          {name}
        </Typography>
      </Box>}
    </UserSummaryBasic>
  );
};

export { UserSummaryForHeader };
