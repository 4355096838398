import { AutomationFormVariant } from '../types/enums/AutomationFormVariant';
import RedoIcon from '../components/Automations/images/template_icons/redo.svg?react';
import NurturingIcon from '../components/Automations/images/template_icons/nurturing.svg?react';
import EnrichLeadsIcon from '../components/Automations/images/template_icons/enrichLeads.svg?react';
import UserPlusIcon from '../components/Automations/images/template_icons/userPlus.svg?react';
import NewLeadIcon from '../components/Automations/images/template_icons/newLead.svg?react';
import StaleDealIcon from '../components/Automations/images/template_icons/staleDeal.svg?react';
import NotifyIcon from '../components/Automations/images/template_icons/notify.svg?react';
import ClosedWonIcon from '../components/Automations/images/template_icons/closedWon.svg?react';
import { Globe } from '@sweep-io/sweep-design/dist/icons';
import { AutomationType } from '../types/enums/AutomationType';
import { SelectorValueTypes } from '../types/enums/SelectorValueTypes';
import { AutomationTypes } from '../types/enums/AutomationTypes';
import { FieldContext } from '../types/enums/FieldContext';
import { SlackActionType } from '../types/enums/SlackActionType';
import { AutomationActionType } from '../types/enums/AutomationActionType';
import { AssignmentToType } from '../types/enums/AssignmentToType';
import { SweepFieldTypes } from '../types/enums/SweepFieldTypes';
import { SweepFieldOperator } from '../components/SweepFieldsOperatorSelector/sweepFieldsTypesAndOperators';

export interface AutoTemplatesStruct {
  icon: JSX.Element;
  fieldsList: string[];
  automation: Partial<AutomationStructureNew>;
}

export const automationTemplates: {
  [key in AutomationFormVariant]: AutoTemplatesStruct[];
} = {
  [AutomationFormVariant.AUTOMATIONS]: [
    {
      icon: <RedoIcon />,
      fieldsList: [
        'Opportunity.StageName',
        'Opportunity.CloseDate',
        'Opportunity.Name',
        'Opportunity.AccountId',
        'Opportunity.Amount',
      ],
      automation: {
        isActive: true,
        type: AutomationType.Default,
        automationDetails: {
          automationParams: {
            fieldUpdate: {
              _leadingFieldId: 'Opportunity.StageName',
              _leadingFieldLabels: ['Opportunity', 'Stage'],
              fieldType: 'Picklist',
              operator: 'EQUALS',
              operatorValue: 'Closed Won',
            },
          },
          actions: [
            {
              _id: '63a15370-bd67-4e02-991c-d8f9abf9bd03',
              actionType: 'UPDATE_RECORDS',
              actionParams: {
                _fields: [
                  {
                    _id: '780b16c9-ea44-47f4-8c21-704f0c26c31a',
                    _fieldIds: ['Opportunity.CloseDate'],
                    fieldType: 'Date',
                    valueType: SelectorValueTypes.RELATIVE,
                    value: 'today',
                  },
                ],
                thisRecord: true,
              },
            },
            {
              _id: 'e6a256d6-479b-4fc0-a54c-1cabea68f681',
              actionType: 'CREATE_RECORD',
              actionParams: {
                objectTypeName: {
                  objectType: 'Opportunity',
                  label: 'Opportunity',
                },
                _fields: [
                  {
                    _id: '1',
                    _fieldIds: ['Opportunity.CloseDate'],
                    _isMandatory: true,
                    fieldType: 'Date',
                    valueType: SelectorValueTypes.FORMULA,
                    value: 'Opportunity.CloseDate + 365',
                    valueFieldIdsArray: [['Opportunity.CloseDate']],
                  },
                  {
                    _id: '2',
                    _fieldIds: ['Opportunity.Name'],
                    _isMandatory: true,
                    fieldType: 'Text',
                    valueType: SelectorValueTypes.FORMULA,
                    value:
                      '"Renewal: " & Opportunity.Account.Name &" " & TEXT(YEAR(Opportunity.CloseDate+365))',
                    valueFieldIdsArray: [
                      ['Opportunity.AccountId', 'Account.Name'],
                      ['Opportunity.CloseDate'],
                    ],
                  },
                  {
                    _id: '3',
                    _fieldIds: ['Opportunity.StageName'],
                    _isMandatory: true,
                    fieldType: 'Picklist',
                    valueType: SelectorValueTypes.LITERAL,
                    value: 'Prospecting',
                  },
                  {
                    _id: 'd36968ef-7b51-4ef4-b985-ed5316847ce8',
                    _fieldIds: ['Opportunity.AccountId'],
                    fieldType: 'Lookup',
                    valueType: SelectorValueTypes.REFERENCE,
                    value: [
                      {
                        value: 'Opportunity.AccountId',
                        label: 'Account ID',
                      },
                    ] as any,
                  },
                  {
                    _id: '752db256-6d1c-45b2-ae0d-97c064660704',
                    _fieldIds: ['Opportunity.Amount'],
                    fieldType: 'Currency',
                    valueType: SelectorValueTypes.REFERENCE,
                    value: [
                      {
                        value: 'Opportunity.Amount',
                        label: 'Amount',
                      },
                    ],
                  },
                ],
                relationsRecordsToClone: [
                  {
                    _isActive: true,
                    objectType: 'OpportunityLineItem',
                    lookupFields: [
                      {
                        _fieldIds: ['OpportunityLineItem.OpportunityId'],
                        fieldType: 'Lookup',
                        valueType: SelectorValueTypes.REFERENCE,
                        value: [
                          {
                            value: 'Opportunity.Id',
                            label: 'Opportunity ID',
                          },
                        ],
                      },
                    ],
                  },
                  {
                    _isActive: true,
                    objectType: 'OpportunityContactRole',
                    lookupFields: [
                      {
                        _fieldIds: ['OpportunityContactRole.OpportunityId'],
                        fieldType: 'Lookup',
                        valueType: SelectorValueTypes.REFERENCE,
                        value: [
                          {
                            value: 'Opportunity.Id',
                            label: 'Opportunity ID',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            },
          ],
          triggerType: AutomationTypes.FieldUpdate,
        },
        name: 'Create renewal opportunities for closed won deals',
        objectName: 'Opportunity',
      },
    },
    {
      icon: <NurturingIcon />,
      fieldsList: [
        'Opportunity.StageName',
        'Opportunity.CloseDate',
        'Task.Priority',
        'Task.Status',
        'Task.WhatId',
        'Task.ActivityDate',
      ],
      automation: {
        isActive: true,
        type: AutomationType.Default,
        automationDetails: {
          automationParams: {
            fieldUpdate: {
              _leadingFieldId: 'Opportunity.StageName',
              _leadingFieldLabels: ['Opportunity', 'Stage'],
              fieldType: 'Picklist',
              operator: 'EQUALS',
              operatorValue: 'Closed Lost',
            },
          },
          actions: [
            {
              _id: '076ad740-0776-496c-8f31-9cc892ede65c',
              actionType: 'UPDATE_RECORDS',
              actionParams: {
                _fields: [
                  {
                    _id: '49eee347-51ee-4b09-8728-97e4f065538e',
                    _fieldIds: ['Opportunity.CloseDate'],
                    fieldType: 'Date',
                    valueType: SelectorValueTypes.RELATIVE,
                    value: 'today',
                  },
                ],
                thisRecord: true,
              },
            },
            {
              _id: 'b5b364c2-c5d8-47dd-bd33-2eda3cc48c8a',
              actionType: 'CREATE_RECORD',
              actionParams: {
                objectTypeName: {
                  objectType: 'Task',
                  label: 'Task',
                },
                _fields: [
                  {
                    _id: '4',
                    _fieldIds: ['Task.Priority'],
                    _isMandatory: true,
                    fieldType: 'Picklist',
                    valueType: SelectorValueTypes.LITERAL,
                    value: 'High',
                  },
                  {
                    _id: '5',
                    _fieldIds: ['Task.Status'],
                    _isMandatory: true,
                    fieldType: 'Picklist',
                    valueType: SelectorValueTypes.LITERAL,
                    value: 'Not Started',
                  },
                  {
                    _id: 'a1bf9c4c-865e-4c9d-bba0-aa18605862c2',
                    _fieldIds: ['Task.WhatId'],
                    fieldType: 'Lookup',
                    valueType: SelectorValueTypes.REFERENCE,
                    value: [
                      {
                        value: 'Opportunity.Id',
                        label: 'Opportunity ID',
                      },
                    ] as any,
                  },
                  {
                    _id: '33361dbd-2330-4d44-a327-46c2f85f338d',
                    _fieldIds: ['Task.ActivityDate'],
                    fieldType: 'Date',
                    valueType: SelectorValueTypes.RELATIVE,
                    value: 'today+270',
                  },
                ],
              },
            },
          ],
          triggerType: AutomationTypes.FieldUpdate,
        },
        name: 'Follow up on closed lost deals',
        objectName: 'Opportunity',
      },
    },
    {
      icon: <UserPlusIcon />,
      fieldsList: [
        'Lead.LastModifiedDate',
        'Task.Priority',
        'Task.Status',
        'Task.Subject',
        'Task.WhoId',
      ],
      automation: {
        isActive: true,
        type: AutomationType.Default,
        automationDetails: {
          automationParams: {
            dateArrive: {
              relativeTime: 'after',
              sourceField: {
                _leadingFieldId: 'Lead.LastModifiedDate',
                _leadingFieldLabels: ['Lead', 'Last Modified Date'],
              },
              offsetValue: '30',
              offsetType: 'Days',
            },
          },
          actions: [
            {
              _id: '100a5362-6f1a-49b3-b62e-5c200b38b70d',
              actionType: 'CREATE_RECORD',
              actionParams: {
                objectTypeName: {
                  objectType: 'Task',
                  label: 'Task',
                },
                _fields: [
                  {
                    _id: '6',
                    _fieldIds: ['Task.Priority'],
                    _isMandatory: true,
                    fieldType: 'Picklist',
                    valueType: SelectorValueTypes.LITERAL,
                    value: 'High',
                  },
                  {
                    _id: '7',
                    _fieldIds: ['Task.Status'],
                    _isMandatory: true,
                    fieldType: 'Picklist',
                    valueType: SelectorValueTypes.LITERAL,
                    value: 'Not Started',
                  },
                  {
                    _id: 'e76b6a23-3de5-41bd-a800-da9464c7ea6a',
                    _fieldIds: ['Task.Subject'],
                    fieldType: 'Picklist',
                    valueType: SelectorValueTypes.LITERAL,
                    value: 'Email',
                  },
                  {
                    _id: '1ccccbf7-5334-4b6d-baa3-c07d6f96e27e',
                    _fieldIds: ['Task.WhoId'],
                    fieldType: 'Lookup',
                    valueType: SelectorValueTypes.REFERENCE,
                    value: [
                      {
                        value: 'Lead.Id',
                        label: 'Lead ID',
                      },
                    ] as any,
                  },
                ],
              },
            },
          ],
          triggerType: AutomationTypes.DateArrive,
        },
        name: 'Create tasks to check out untouched leads',
        objectName: 'Lead',
      },
    },
    {
      icon: <EnrichLeadsIcon />,
      fieldsList: [
        'Account.OwnerId',
        'Contact.OwnerId',
        'Opportunity.IsClosed',
        'Opportunity.OwnerId',
      ],
      automation: {
        isActive: true,
        type: AutomationType.Default,
        automationDetails: {
          automationParams: {
            fieldUpdate: {
              _leadingFieldId: 'Account.OwnerId',
              _leadingFieldLabels: ['Account', 'Owner ID'],
              fieldType: 'Lookup',
              operator: 'ANY',
              operatorValue: 'true',
            },
          },
          actions: [
            {
              _id: '5293e3e9-1d92-46d0-9627-d28daf6af99a',
              actionType: 'UPDATE_RECORDS',
              actionParams: {
                _fields: [
                  {
                    _id: 'c9069412-a6d4-495b-852c-c9b00bddc129',
                    _fieldIds: ['Contact.OwnerId'],
                    fieldType: 'Lookup',
                    valueType: SelectorValueTypes.REFERENCE,
                    value: [
                      {
                        value: 'Account.OwnerId',
                        label: 'Owner ID',
                      },
                    ] as any,
                  },
                ],
                thisRecord: false,
                childRelationshipFieldName: {
                  relationshipFieldId: 'Contact.AccountId',
                  fieldName: 'Contacts',
                  childRelationshipName: 'Contacts',
                  objectType: 'Contact',
                  objectTypeLabel: 'Contact',
                },
                isCriteria: false,
              },
            },
            {
              _id: '7834e569-ae0b-4d20-a48e-6c03d8db6650',
              actionType: 'UPDATE_RECORDS',
              actionParams: {
                _fields: [
                  {
                    _id: '8d2868e3-2245-4272-85e3-55785ca6f93e',
                    _fieldIds: ['Opportunity.OwnerId'],
                    fieldType: 'Lookup',
                    valueType: SelectorValueTypes.REFERENCE,
                    value: [
                      {
                        value: 'Account.OwnerId',
                        label: 'Owner ID',
                      },
                    ] as any,
                  },
                ],
                thisRecord: false,
                childRelationshipFieldName: {
                  relationshipFieldId: 'Opportunity.AccountId',
                  fieldName: 'Opportunities',
                  childRelationshipName: 'Opportunities',
                  objectType: 'Opportunity',
                  objectTypeLabel: 'Opportunity',
                },
                isCriteria: true,
                criteria: {
                  criteria: [
                    {
                      criterionId: 'fb54a4c7-1c30-4c67-a1f0-7631f0c39c16',
                      _fieldIds: ['Opportunity.IsClosed'],
                      fieldContext: FieldContext.CURRENT_VALUE,
                      fieldType: 'Checkbox',
                      operator: 'EQUALS',
                      valueType: 'Literal',
                      value: 'false',
                    },
                  ],
                  criteriaLogic: '1',
                },
              },
            },
          ],
          triggerType: AutomationTypes.FieldUpdate,
        },
        name: 'Reassign contacts and opps when account owner changes',

        objectName: 'Account',
      },
    },
  ],
  [AutomationFormVariant.ASSIGNMENT_RULES]: [
    {
      icon: <Globe />,
      fieldsList: ['Lead.OwnerId', 'Lead.Address'],
      automation: {
        isActive: true,
        type: AutomationType.Assignment,
        automationDetails: {
          automationParams: {},
          actions: [
            {
              _id: 'aca66e84-c5b3-4bac-b484-1bd1208c10b5',
              actionType: AutomationActionType.AssignmentRules,
              actionParams: {
                assignmentRules: [
                  {
                    _id: '87668ab6-da0c-475e-a928-ffd3b3d89d00',
                    name: 'Assign to east coast',
                    assignedTo: {
                      type: AssignmentToType.Team,
                      id: '756ed700-aba4-47ca-83bb-7309193d95c8', //TODO how to handle?
                      _name: 'SDR Team',
                      fieldMapping: {
                        toField: {
                          fieldIds: ['Lead.OwnerId'],
                        },
                      },
                    },
                    when: {
                      criteria: {
                        criteria: [
                          {
                            criterionId: '96597e1d-79f6-4b66-abd4-26eb2aece2fd',
                            _fieldIds: ['Lead.Address'],
                            fieldContext: FieldContext.CURRENT_VALUE,
                            fieldType: SweepFieldTypes.Address,
                            operator: SweepFieldOperator.IS_CONTAINED_IN,
                            valueType: 'Territory',
                            value: '13a6f99f-690f-4637-bc2d-4265edaf8f46', //TODO how to handle?
                          },
                        ],
                        criteriaLogic: '1',
                      },
                    },
                  },
                  {
                    _id: 'cf8a7f13-2b04-4e3e-bb3d-870bdea9925c',
                    name: 'Assign to west coast',
                    assignedTo: {
                      type: AssignmentToType.Team,
                      id: '756ed700-aba4-47ca-83bb-7309193d95c8', //TODO how to handle?
                      _name: 'SDR Team',
                      fieldMapping: {
                        toField: {
                          fieldIds: ['Lead.OwnerId'],
                        },
                      },
                    },
                    when: {
                      criteria: {
                        criteria: [
                          {
                            criterionId: '96597e1d-79f6-4b66-abd4-26eb2aece2fd',
                            _fieldIds: ['Lead.Address'],
                            fieldContext: FieldContext.CURRENT_VALUE,
                            fieldType: SweepFieldTypes.Address,
                            operator: SweepFieldOperator.IS_CONTAINED_IN,
                            valueType: 'Territory',
                            value: '9e09f9c4-649a-4f18-80ed-d966f65ec4b5', //TODO how to handle?
                          },
                        ],
                        criteriaLogic: '1',
                      },
                    },
                  },
                ],
              },
            },
          ],
          triggerType: AutomationTypes.OnUpsert,
        },
        name: 'Distribute leads by territory',
        objectName: 'Lead',
      },
    },
  ],
  [AutomationFormVariant.DEDUP_MATCHING]: [],
  [AutomationFormVariant.PLAYBOOK_ALERT]: [
    {
      icon: <ClosedWonIcon />,
      fieldsList: [
        'Opportunity.AccountId',
        'Opportunity.StageName',
        'Opportunity.Name',
        'Opportunity.OwnerId',
        'Account.Name',
        'Opportunity.Amount',
        'Opportunity.CloseDate',
      ],
      automation: {
        isActive: true,
        type: AutomationType.Alert,
        automationDetails: {
          automationParams: {
            fieldUpdate: {
              _leadingFieldId: 'Opportunity.StageName',
              _leadingFieldLabels: ['Opportunity', 'Stage'],
              fieldType: 'Picklist',
              operator: 'EQUALS',
              operatorValue: 'Closed Won',
            },
          },
          actions: [
            {
              _id: '6da34019-2a33-48fd-a883-39a262f086ba',
              actionType: 'SEND_SLACK_MESSAGE',
              actionParams: {
                message: {
                  template: '{{{0}}} Congrats on the new Closed Won! {{{1}}} ',
                  variables: [
                    {
                      fieldIds: ['Opportunity.OwnerId'],
                    },
                    {
                      fieldIds: ['Opportunity.Name'],
                    },
                  ],
                },
                fields: [
                  {
                    fieldIds: ['Opportunity.AccountId', 'Account.Name'],
                  },
                  {
                    fieldIds: ['Opportunity.Amount'],
                  },
                  {
                    fieldIds: ['Opportunity.CloseDate'],
                  },
                ],
                recipients: [
                  {
                    type: 'Salesforce',
                    value: {
                      fieldIds: ['Opportunity.OwnerId'],
                    },
                  },
                ],
                actions: [],
              },
            },
          ],
          triggerType: AutomationTypes.FieldUpdate,
        },
        name: 'Celebrate closed won deals',
        objectName: 'Opportunity',
      },
    },
    {
      icon: <NotifyIcon />,
      fieldsList: [
        'Opportunity.OwnerId',
        'Opportunity.AccountId',
        'Account.Name',
        'Opportunity.Name',
        'Opportunity.Amount',
        'Opportunity.IsClosed',
        'User.Name',
      ],
      automation: {
        isActive: true,
        type: AutomationType.Alert,
        automationDetails: {
          automationParams: {},
          actions: [
            {
              _id: 'de39d59b-75b4-43a2-8d80-6947f648eb97',
              actionType: 'SEND_SLACK_MESSAGE',
              actionParams: {
                message: {
                  template: 'A new opportunity has been created for {{{0}}}!',
                  variables: [
                    {
                      fieldIds: ['Opportunity.AccountId', 'Account.Name'],
                    },
                  ],
                },
                fields: [
                  {
                    fieldIds: ['Opportunity.Name'],
                  },
                  {
                    fieldIds: ['Opportunity.Amount'],
                  },
                  {
                    fieldIds: ['Opportunity.IsClosed'],
                  },
                  {
                    fieldIds: ['Opportunity.OwnerId', 'User.Name'],
                  },
                ],
                recipients: [
                  {
                    type: 'Salesforce',
                    value: {
                      fieldIds: ['Opportunity.OwnerId'],
                    },
                  },
                ],
                actions: [],
              },
            },
          ],
          triggerType: AutomationTypes.OnCreate,
        },
        name: 'Notify sales team on newly created opportunities',
        objectName: 'Opportunity',
      },
    },
    {
      icon: <StaleDealIcon />,
      fieldsList: [
        'Opportunity.StageName',
        'Opportunity.Name',
        'Opportunity.CloseDate',
        'Opportunity.IsClosed',
        'Opportunity.OwnerId',
        'User.Name',
      ],
      automation: {
        isActive: true,
        type: AutomationType.Alert,
        automationDetails: {
          automationParams: {
            dateArrive: {
              relativeTime: 'after',
              sourceField: {
                _leadingFieldId: 'Opportunity.CloseDate',
                _leadingFieldLabels: ['Opportunity', 'Close Date'],
              },
              offsetValue: '1',
              offsetType: 'Days',
            },
          },
          when: {
            isCriteria: true,
            criteria: {
              criteria: [
                {
                  criterionId: '6c70106c-9149-4c55-b7d4-8cd2a0abc812',
                  _fieldIds: ['Opportunity.IsClosed'],
                  fieldContext: FieldContext.CURRENT_VALUE,
                  fieldType: 'Checkbox',
                  operator: 'EQUALS',
                  valueType: 'Literal',
                  value: 'false',
                },
              ],
              criteriaLogic: '1',
            },
          },
          actions: [
            {
              _id: '45d7b34a-0660-4d3e-bf34-ae09dd44ed0f',
              actionType: 'SEND_SLACK_MESSAGE',
              actionParams: {
                message: {
                  template:
                    '{{{0}}}, {{{1}}} is past its close date. Please update the close date or mark as closed lost',
                  variables: [
                    {
                      fieldIds: ['Opportunity.OwnerId', 'User.Name'],
                    },
                    {
                      fieldIds: ['Opportunity.Name'],
                    },
                  ],
                },
                fields: [
                  {
                    fieldIds: ['Opportunity.Name'],
                  },
                  {
                    fieldIds: ['Opportunity.StageName'],
                  },
                  {
                    fieldIds: ['Opportunity.CloseDate'],
                  },
                ],
                recipients: [
                  {
                    type: 'Salesforce',
                    value: {
                      fieldIds: ['Opportunity.OwnerId'],
                    },
                  },
                ],
                actions: [
                  {
                    buttonText: 'Update Close Date',
                    fields: [
                      {
                        fieldIds: ['Opportunity.CloseDate'],
                        isRequired: false,
                      },
                    ],
                    type: SlackActionType.UPDATE_RECORD,
                  },
                  {
                    buttonText: 'Mark as Lost',
                    fields: [
                      {
                        fieldIds: ['Opportunity.StageName'],
                        isRequired: false,
                      },
                    ],
                    type: SlackActionType.UPDATE_RECORD,
                  },
                ],
              },
            },
          ],
          triggerType: AutomationTypes.DateArrive,
        },
        name: 'Alert owner & leadership when opportunities become stale',
        objectName: 'Opportunity',
      },
    },
    {
      icon: <NewLeadIcon />,
      fieldsList: ['Lead.OwnerId', 'Lead.Name', 'Lead.Company', 'Lead.Country'],
      automation: {
        isActive: true,
        type: AutomationType.Alert,
        automationDetails: {
          automationParams: {},
          actions: [
            {
              _id: 'ac11f4ed-c540-4f48-ba9e-4a6f732c93ef',
              actionType: 'SEND_SLACK_MESSAGE',
              actionParams: {
                message: {
                  template:
                    'New Lead, {{{0}}} from {{{1}}} is assigned to you! Please follow up and kick off the sales process!',
                  variables: [
                    {
                      fieldIds: ['Lead.Name'],
                    },
                    {
                      fieldIds: ['Lead.Company'],
                    },
                  ],
                },
                fields: [
                  {
                    fieldIds: ['Lead.Name'],
                  },
                  {
                    fieldIds: ['Lead.Company'],
                  },
                  {
                    fieldIds: ['Lead.Country'],
                  },
                ],
                recipients: [
                  {
                    type: 'Salesforce',
                    value: {
                      fieldIds: ['Lead.OwnerId'],
                    },
                  },
                ],
                actions: [],
              },
            },
          ],
          triggerType: AutomationTypes.OnCreate,
        },
        name: 'Notify reps on new assigned leads',
        objectName: 'Lead',
      },
    },
  ],
  [AutomationFormVariant.SCHEDULED_ASSIGNMENTS]: [],
  [AutomationFormVariant.SCHEDULED_REPORTS]: [],
};
