import { Box } from '@mui/material';
import { IconButton } from '@sweep-io/sweep-design';
import { Notification as NotificationIcon } from '@sweep-io/sweep-design/dist/icons';
import { useState } from 'react';
import { NotificationsPopover } from './NotificationsPopover';
import { NotificationAnimation } from '../../animations/lottie/lottieAnimations';
import useNotifications from '../header/useNotifications';

export const NotificationCenter = () => {
  const { notifications, totalUnread } = useNotifications();
  const [isHovered, setIsHovered] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isOpen = Boolean(anchorEl);

  return (
    <>
      <Box
        position="relative"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <IconButton
          onClick={handleClick}
          variant="dark"
          badgeLabel={totalUnread}
          badgeVariant="dot"
          pressed={isOpen}
          size="small"
        >
          {isHovered ? <NotificationAnimation size={16} /> : <NotificationIcon />}
        </IconButton>
      </Box>

      <NotificationsPopover
        notifications={notifications}
        onClose={handleClose}
        anchorEl={anchorEl}
        isOpen={isOpen}
      />
    </>
  );
};
