import { useMemo } from 'react';
import { AutomationActionType } from '../../../types/enums/AutomationActionType';
import { getDeployedVersions } from '../../documentation/selected-object/filters/utils';
import {
  CanvasFilterTypes,
  MultiGenericFilterItem,
  SweepCanvasFiltersMap,
} from '../configuration-canvas-filters/filterTypes';
import { AutomationCanvasFilter } from './canvasAutomationsTypes';
import { useAutomationsContext } from '../../Automations/AutomationsContext';
import { useFeatureToggle } from '../../common/useFeatureToggle';

const getActionFilterItems = (automations: AutomationStructureNew[]) => {
  const items: MultiGenericFilterItem[] = [];

  const actionsInUse = new Set(
    automations
      .map(({ automationDetails: { actions } }) =>
        actions.map(({ actionType }) => actionType as AutomationActionType),
      )
      .flat(),
  );

  if (actionsInUse.has(AutomationActionType.CreateRecord)) {
    items.push({ value: AutomationActionType.CreateRecord, label: 'Create Record' });
  }
  if (actionsInUse.has(AutomationActionType.UpdateRecords)) {
    items.push({ value: AutomationActionType.UpdateRecords, label: 'Update Record' });
  }
  if (actionsInUse.has(AutomationActionType.SendEmail)) {
    items.push({ value: AutomationActionType.SendEmail, label: 'Send Email' });
  }
  if (actionsInUse.has(AutomationActionType.Slack)) {
    items.push({ value: AutomationActionType.Slack, label: 'Post to Slack' });
  }
  if (actionsInUse.has(AutomationActionType.ConvertLead)) {
    items.push({ value: AutomationActionType.ConvertLead, label: 'Convert Lead' });
  }
  if (actionsInUse.has(AutomationActionType.SlackDealRoom)) {
    items.push({ value: AutomationActionType.SlackDealRoom, label: 'Create Deal Room' });
  }
  if (actionsInUse.has(AutomationActionType.SlackAddMember)) {
    items.push({ value: AutomationActionType.SlackAddMember, label: 'Add Members to Deal Room' });
  }

  return items;
};

export const useGetAutomationFilters = (automations: AutomationStructureNew[] = []) => {
  const { permissions } = useAutomationsContext();
  const { requestToDeploy } = useFeatureToggle();
  const automationsFilters = useMemo((): SweepCanvasFiltersMap => {
    const regularFilters: SweepCanvasFiltersMap = {
      [AutomationCanvasFilter.OBJECTS]: { type: CanvasFilterTypes.OBJECT, position: 0 },
      [AutomationCanvasFilter.RECORD_TYPES]: {
        type: CanvasFilterTypes.RECORD_TYPE,
        position: 1,
        objectsFilter: {
          type: 'reference',
          filterRef: AutomationCanvasFilter.OBJECTS,
        },
      },
      [AutomationCanvasFilter.STEPS]: {
        type: CanvasFilterTypes.STEP,
        recordTypeFilterRef: AutomationCanvasFilter.RECORD_TYPES,
        position: 2,
      },
      [AutomationCanvasFilter.ACTIONS]: {
        type: CanvasFilterTypes.MULTI_GENERIC,
        position: 3,
        label: 'Action',
        items: getActionFilterItems(automations),
      },
    };
    if (requestToDeploy && permissions?.deploy) {
      regularFilters[AutomationCanvasFilter.DEPLOYMENT_REQUEST] = {
        type: CanvasFilterTypes.TOGGLE_FILTER,
        position: 4,
        label: 'Show only deployment requests',
        checked: false,
      };
    }

    return regularFilters;
  }, [automations, permissions?.deploy, requestToDeploy]);

  return automationsFilters;
};

export const getDocumentationAutomationFilters = (
  automations: AutomationStructureNew[],
  objectFilter = '',
) => ({
  [AutomationCanvasFilter.RECORD_TYPES]: {
    type: CanvasFilterTypes.RECORD_TYPE,
    position: 1,
    objectsFilter: {
      type: 'values',
      values: [objectFilter],
    },
  },
  [AutomationCanvasFilter.STEPS]: {
    type: CanvasFilterTypes.STEP,
    recordTypeFilterRef: AutomationCanvasFilter.RECORD_TYPES,
    position: 2,
  },
  [AutomationCanvasFilter.ACTIONS]: {
    type: CanvasFilterTypes.MULTI_GENERIC,
    position: 3,
    label: 'Action',
    items: getActionFilterItems(getDeployedVersions(automations) ?? []),
  },
});
