import { SFDCObjectType } from '../types/enums/SFDCObjectType';
import { FormikErrors } from 'formik';
import { SweepFieldTypes } from '../types/enums/SweepFieldTypes';

//accessors to the fields table:
export const USED_IN_STAGES = 'usedInStages';
export const USED_IN_AUTOMATIONS = 'usedInAutomations';
export const USED_IN_FUNNELS = 'usedInFunnels';
export const SWEEP_FIELD_NAME = 'sweepFieldName';

//modes for "create/edit" field dialog
export enum EditMode {
  EDIT = 'EDIT',
  CREATE = 'CREATE',
  READONLY = 'READONLY',
}

export type NewField = {
  objectName: SFDCObjectType;
  [SWEEP_FIELD_NAME]: string;
  fieldType: SweepFieldTypes;
  isRequired: boolean;
  description?: string;
  helpText?: string;
  properties: SweepFieldProperties;
};

export type FunnelField = Partial<SweepField> & {
  isLeadingField: boolean;
  [USED_IN_STAGES]?: string[];
  [USED_IN_AUTOMATIONS]?: string[];
};

type FieldTypeLabels = {
  [fieldType in SweepFieldTypes]?: string;
};

export const NumberLikeFieldTypes = [
  SweepFieldTypes.Number,
  SweepFieldTypes.Currency,
  SweepFieldTypes.Percent,
];

export const TextLikeFieldTypes = [
  SweepFieldTypes.Text,
  SweepFieldTypes.TextArea,
  SweepFieldTypes.LongTextArea,
];

export const FunnelFieldLabels: FieldTypeLabels = {
  [SweepFieldTypes.Checkbox]: 'Checkbox',
  [SweepFieldTypes.Currency]: 'Currency',
  [SweepFieldTypes.Date]: 'Date',
  [SweepFieldTypes.DateTime]: 'Date & Time',
  [SweepFieldTypes.Email]: 'Email',
  [SweepFieldTypes.Geolocation]: 'Geolocation',
  [SweepFieldTypes.Number]: 'Number',
  [SweepFieldTypes.Percent]: 'Percent',
  [SweepFieldTypes.Phone]: 'Phone',
  [SweepFieldTypes.Picklist]: 'Picklist',
  [SweepFieldTypes.MultiselectPicklist]: 'Picklist (Multi-select)',
  [SweepFieldTypes.Text]: 'Text',
  [SweepFieldTypes.TextArea]: 'Text Area',
  [SweepFieldTypes.Time]: 'Time',
  [SweepFieldTypes.Url]: 'URL',
  [SweepFieldTypes.Lookup]: 'Lookup',
  [SweepFieldTypes.serviceid]: 'Service Id',
  [SweepFieldTypes.Hierarchy]: 'Hierarchy',
  [SweepFieldTypes.MasterDetail]: 'MasterDetail',
  [SweepFieldTypes.Address]: 'Address',
  [SweepFieldTypes.FormulaText]: 'Formula (Text)',
  [SweepFieldTypes.FormulaCheckbox]: 'Formula (Checkbox)',
  [SweepFieldTypes.FormulaCurrency]: 'Formula (Currency)',
  [SweepFieldTypes.FormulaDate]: 'Formula (Date)',
  [SweepFieldTypes.FormulaDateTime]: 'Formula (Date/Time)',
  [SweepFieldTypes.FormulaNumber]: 'Formula (Number)',
  [SweepFieldTypes.FormulaPercent]: 'Formula (Percent)',
  [SweepFieldTypes.FormulaTime]: 'Formula (Time)',
  [SweepFieldTypes.AutoNumber]: 'Auto Number',
  [SweepFieldTypes.LongTextArea]: 'Long Text Area',
};

export interface AttributeProps {
  setFieldValue: (fieldName: string, value: any) => void;
  values: NewField | Partial<FunnelField>;
  errors: FormikErrors<NewField | Partial<FunnelField>>;
  mode: EditMode;
  disabled?: boolean;
  isLeadingField?: boolean;
}

export const textAreaCommonProps = {
  multiline: true,
  autoFocus: false,
  inputProps: {
    maxLength: 255,
  },
};

//SF api names inside "properties"
export const DISPLAY_LOCATION_IN_DECIMAL: keyof SweepFieldProperties = 'displayLocationInDecimal';
export const SCALE: keyof SweepFieldProperties = 'scale'; //Used by currency, number, percent, geolocation
export const PRECISION: keyof SweepFieldProperties = 'precision'; //Used by currency, number, percent
export const LENGTH: keyof SweepFieldProperties = 'length'; //UI ONLY PROP for currency, number, percent. Used by BE for text.
export const IS_UNIQUE: keyof SweepFieldProperties = 'unique';
export const CASE_SENSITIVE: keyof SweepFieldProperties = 'caseSensitive';
export const DEFAULT_VALUE: keyof SweepFieldProperties = 'defaultValue';
export const DEFAULT_VALUE_FORMULA: keyof SweepFieldProperties = 'defaultValueFormula';

export enum FieldsDialogContentOptions {
  FieldsTable = 'FieldsTable',
  CreateField = 'CreateField',
  EditField = 'EditField',
}

export const FIELD_LABEL_MAX_CHARS = 40;

// SF rules for number-like fields:
export const SCALE_INPUT_PROPS = { min: 0, max: 17, step: 1 };
export const LENGTH_INPUT_PROPS = { min: 1, max: 18, step: 1 };
export const LONGTEXTAREA_INPUT_PROPS = { min: 0, max: 131072 };
export const TEXT_INPUT_PROPS = { min: 0, max: 255 };

export const TEXT_LENGTH_LABEL = 'Length';
export const LENGTH_LABEL = 'Length (number of digits to the left of the decimal point)';
export const SCALE_LABEL = 'Decimal Places (number of digits to the right of the decimal point)';
