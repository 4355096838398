import { SelectChangeEvent, MenuItem, Box } from '@mui/material';
import SweepSelect from '../../common/SweepSelect';
import usePermission from '../../common/permissions/usePermission';
import { Typography, colors } from '@sweep-io/sweep-design';
import { SweepRoles } from '../../../hooks/useUserInfo';
import { useState } from 'react';
import { roleDescriptions } from './consts';

interface RolesDropdownProps {
  userId?: string;
  userRole?: SweepRoles;
  accountRoles: SweepRoles[];
  onChangeRoleCb: (userId: string, newRole: SweepRoles) => void;
  onUserRemove?: (userId: string) => void;
  disableRoleDropdown?: boolean;
}

export const RolesDropdown = ({
  userId,
  userRole,
  accountRoles,
  onChangeRoleCb,
  disableRoleDropdown,
  onUserRemove,
}: RolesDropdownProps) => {
  const [isAllowedBtn, isAllowedBtnDelete] = usePermission(['edit:users:roles', 'delete:users']);
  const [_userRole, setUserRole] = useState(userRole);

  if (disableRoleDropdown || !isAllowedBtn || !accountRoles?.length) {
    return (
      <Box sx={{ paddingRight: 1 }}>
        <Typography ellipsis variant="body">
          {userRole}
        </Typography>
      </Box>
    );
  }

  return (
    <SweepSelect
      removeBorders
      SelectProps={{
        renderValue: (val) => <>{val ?? ''}</>,
        value: _userRole ?? '',
        onChange: (event: SelectChangeEvent<unknown>) => {
          event.preventDefault();
          const val = event.target.value as string;

          if (val === 'remove_user' && userId) {
            onUserRemove && onUserRemove(userId);
          } else {
            const role = val as SweepRoles;
            onChangeRoleCb(userId || '', role);
            setUserRole(role);
          }
        },
        MenuProps: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        },
        sx: {
          '& .MuiSelect-select': {
            paddingRight: 3,
          },
        },
      }}
    >
      {accountRoles.map((role) => (
        <MenuItem
          key={role}
          value={role}
          sx={{
            width: '299px',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
            <Typography variant="body">{role}</Typography>
            <Typography variant="caption" color={colors.grey[700]}>
              {roleDescriptions[role]}
            </Typography>
          </Box>
        </MenuItem>
      ))}

      {isAllowedBtnDelete && onUserRemove && userId && (
        <MenuItem value="remove_user" sx={{ color: colors.blush[500] }}>
          Remove
        </MenuItem>
      )}
    </SweepSelect>
  );
};
