import { Box, Alert } from '@mui/material';
import { Warning as WarningIcon } from '@sweep-io/sweep-design/dist/icons';
import { SlackConnectionStatus } from '../../../reducers/integrationsReducer';
import { SlackConnectButton } from '../../dashboard/user-menu/SlackConnectButton';
import { Button, colors } from '@sweep-io/sweep-design';
import ConnectToSfDialog from '../../pages/get-started/connect-org/ConnectToSfDialog';
import { useCallback, useState } from 'react';
import WithConnectOrgPermission from '../../common/WithConnectOrgPermission';

export const SlackNotConnected = ({
  hasProduction,
  slackStatus,
}: {
  hasProduction: boolean;
  slackStatus: SlackConnectionStatus;
}) => {
  const [isConnectToSfDialogOpen, setIsConnectToSfDialogOpen] = useState(false);

  const openDialog = () => {
    setIsConnectToSfDialogOpen(true);
  };

  const closeDialog = useCallback(() => {
    setIsConnectToSfDialogOpen(false);
  }, []);

  const getAction = () => {
    if (!hasProduction) {
      return (
        <WithConnectOrgPermission>
          <Button variant="link" onClick={openDialog}>
            Connect
          </Button>
        </WithConnectOrgPermission>
      );
    }
    if (slackStatus !== SlackConnectionStatus.Connected) {
      return <SlackConnectButton variant="link" />;
    }
  };
  return (
    <>
      {isConnectToSfDialogOpen && (
        <ConnectToSfDialog
          onFinish={closeDialog}
          forceProduction={true}
          closeDialog={closeDialog}
        />
      )}
      <Box
        sx={{
          width: 'fit-content',
        }}
      >
        <Alert
          icon={<WarningIcon />}
          sx={{
            background: colors.grey[100],
            fontWeight: '500',
            borderRadius: '4px',
            color: colors.night[900],
            alignItems: 'center',
            '& .MuiAlert-action': {
              padding: 0,
              margin: '0 0 0 40px',
            },
          }}
          severity="warning"
          action={getAction()}
        >
          {!hasProduction
            ? 'To send Slack notifications, First add Production environment to Sweep'
            : 'To send Slack notifications, connect Slack to your Production environment'}
        </Alert>
      </Box>
    </>
  );
};
