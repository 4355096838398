import usePaywall, { PaywallAction, PaywallSource } from '../../dashboard/header/usePaywall';
import { Box, Stack } from '@mui/material';
import { colors, Typography } from '@sweep-io/sweep-design';
import { useState } from 'react';
import PinkButton from '../../dashboard/paywall/PinkButton';
import ArrowRightSmall from './arrowRightSmall.svg?react';
import { telemetry } from '../../../telemetry';

const AiChatPaywallBlockMessage = () => {
  const { paywallAction, openIntercomForUpgrade, startTrial } = usePaywall({
    source: PaywallSource.AI_CHAT,
  });

  const [isLoading, setIsLoading] = useState(false);

  const onActivateClick = async () => {
    setIsLoading(true);
    try {
      await startTrial(PaywallSource.AI_CHAT);
    } catch (e) {
      telemetry.captureError(e);
    }
    setIsLoading(false);
  };

  if (!paywallAction || paywallAction === PaywallAction.NONE) {
    return null;
  }

  const isStartTrial = paywallAction === PaywallAction.START_TRIAL;
  const text = isStartTrial
    ? `I'd be happy to answer that for you, but first, you need to activate your free trial.`
    : `I'd be happy to answer that for you, but first, you need to upgrade.`;

  return (
    <Stack gap="10px" p={1.5}>
      <Typography variant="body" color={colors.grey[800]}>
        {text}
      </Typography>
      <PinkButton
        onClick={isStartTrial ? onActivateClick : openIntercomForUpgrade}
        loading={isLoading}
        size="tiny"
      >
        <Box display="flex" alignItems="center" gap="6px">
          <Typography variant="caption-bold">
            {isStartTrial ? 'Start free trial' : 'Upgrade'}
          </Typography>
          <ArrowRightSmall />
        </Box>
      </PinkButton>
    </Stack>
  );
};

export default AiChatPaywallBlockMessage;
