import { useDeployments } from '../../../../hooks/useDeployments';
import { useRunOnce } from '../../../common/useRunOnce';
import { useRunOnceWhenTruthy } from '../../../common/useRunOnceWhenTruthy';
import { useFunnelMapReadOnly } from './useFunnelMapReadOnly';
import { clearFunnelMap } from '../../../../reducers/multiFunnelFlowCanvasReducer';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  setDeployment,
  setHasFetchingErrors,
  setIsPageLoading,
} from '../../../../reducers/multiFunnelFlowNoHistoryReducer';
import { useLoadCrmOrgs } from '../../../../hooks/useLoadCrmOrgs';
import { telemetry } from '../../../../telemetry';
import { usePageHelpers } from '../../canvas-pages/united-canvas/usePageHelpers';

export const useInitializeReadOnly = () => {
  const { createTemporaryFunnelMap, funnel } = useFunnelMapReadOnly();
  const { funnelId, snapshotId, deploymentId, closeDialogs, isLoading } = usePageHelpers();
  const { fetchDeployment } = useDeployments();

  useLoadCrmOrgs();

  const dispatch = useDispatch();

  useRunOnce(() => dispatch(setIsPageLoading({ isPageLoading: true })));

  useRunOnceWhenTruthy(
    async () => {
      if (!funnelId) {
        dispatch(setHasFetchingErrors({ hasErrors: true }));
        return;
      }

      await createTemporaryFunnelMap({ funnelId, snapshotId });

      try {
        if (deploymentId) {
          const deployment = await fetchDeployment({ deploymentId });
          if (deployment) {
            dispatch(setDeployment({ deployment }));
          }
        }
      } catch (error) {
        telemetry.captureError(error);
        dispatch(setHasFetchingErrors({ hasErrors: true }));
      }

      dispatch(setIsPageLoading({ isPageLoading: false }));
    },
    Boolean(funnelId && isLoading && !funnel),
  );

  //clear on component unmount:
  useEffect(() => {
    return () => {
      dispatch(clearFunnelMap());
      closeDialogs();
    };
  }, [dispatch, closeDialogs]);
};
