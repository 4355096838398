import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';
import { WorkflowDto } from '../components/pages/canvas-pages/canvas-hubspot-page/types';
import { HubspotField } from '../components/pages/canvas-pages/canvas-hubspot-page/hubspot.types';
import { Campaign } from '../apis/facades/useCampaignsApiFacade';

export interface HubspotState {
  hubspotOrgs: {
    data?: HubspotOrg[];
    isLoading: boolean;
  };
  campaigns: {
    data?: Campaign[];
    isLoading: boolean;
  };
  workflows: {
    data?: WorkflowDto[];
    isLoading: boolean;
  };
  hubspotFields: {
    data: HubspotField[];
    isLoading: boolean;
  };
}

const initialState: HubspotState = {
  hubspotOrgs: { isLoading: false },
  campaigns: { isLoading: false },
  workflows: { isLoading: false },
  hubspotFields: { data: [], isLoading: false },
};

export const hubspotSlice = createSlice({
  name: 'hubspot',
  initialState,
  reducers: {
    setHubspotOrgs: (state, action: PayloadAction<HubspotOrg[]>) => {
      state.hubspotOrgs.data = action.payload;
      state.hubspotOrgs.isLoading = false;
    },
    addOrUpdateHubspotOrg: (state, action: PayloadAction<HubspotOrg>) => {
      const hsOrgToAdd = action.payload;
      if (state.hubspotOrgs.data) {
        const existingOrg = state.hubspotOrgs.data.find((hsOrg) => hsOrg.id === hsOrgToAdd.id);
        if (existingOrg) {
          state.hubspotOrgs.data = state.hubspotOrgs.data.map((hsOrg) => {
            if (hsOrg.id === existingOrg.id) {
              return hsOrgToAdd;
            } else {
              return hsOrg;
            }
          });
          return;
        }
        state.hubspotOrgs.data = [...state.hubspotOrgs.data, action.payload];
      } else {
        state.hubspotOrgs.data = [action.payload];
      }
    },
    deleteHubspotOrg: (state, action: PayloadAction<{ hsOrgId: string }>) => {
      state.hubspotOrgs.data = state.hubspotOrgs.data?.filter(
        (hsOrg) => hsOrg.id !== action.payload.hsOrgId,
      );
    },
    startLoadingCampaigns: (state) => {
      state.campaigns.isLoading = true;
    },
    loadCampaigns: (state, action: PayloadAction<Campaign[]>) => {
      state.campaigns.data = action.payload;
      state.campaigns.isLoading = false;
    },
    addCampaign: (state, action: PayloadAction<Campaign>) => {
      if (state.campaigns.data) {
        state.campaigns.data = [...state.campaigns.data, action.payload];
      } else {
        state.campaigns.data = [action.payload];
      }
    },
    startLoadingWorkflows: (state) => {
      state.workflows.isLoading = true;
    },
    loadWorkflows: (state, action: PayloadAction<WorkflowDto[]>) => {
      state.workflows.data = action.payload;
      state.workflows.isLoading = false;
    },
    addWorkflow: (state, action: PayloadAction<WorkflowDto>) => {
      if (state.workflows.data) {
        state.workflows.data = [...state.workflows.data, action.payload];
      } else {
        state.workflows.data = [action.payload];
      }
    },
    updateWorkflow: (state, action: PayloadAction<WorkflowDto>) => {
      if (!state.workflows.data) {
        state.workflows.data = [];
      }

      state.workflows.data = (state.workflows.data || []).map((workflow) => {
        if (workflow.id === action.payload.id) {
          return action.payload;
        }
        return workflow;
      });
    },
    startLoadingHubspotFields: (state) => {
      state.hubspotFields.isLoading = true;
    },
    loadHubspotFields: (state, action: PayloadAction<HubspotField[]>) => {
      state.hubspotFields.data = action.payload;
      state.hubspotFields.isLoading = false;
    },
  },
});

export const {
  setHubspotOrgs,
  addOrUpdateHubspotOrg,
  addCampaign,
  loadCampaigns,
  startLoadingCampaigns,
  deleteHubspotOrg,
  startLoadingWorkflows,
  loadWorkflows,
  addWorkflow,
  updateWorkflow,
  startLoadingHubspotFields,
  loadHubspotFields,
} = hubspotSlice.actions;

export const selectHubspotOrgs = (state: RootState) => state.hubspot.hubspotOrgs.data;
export const selectConnectedHubspotOrg = (state: RootState) =>
  state.hubspot.hubspotOrgs.data?.find((hsOrg) => hsOrg.isConnected);
export const selectHubspotOrgsHasLoaded = (state: RootState) =>
  Boolean(state.hubspot.hubspotOrgs.data);
export const selectIsLoadingHsOrgs = (state: RootState) => state.hubspot.hubspotOrgs.isLoading;

export const selectCampaigns = (state: RootState) => state.hubspot.campaigns.data;
export const selectCampaignsIsLoading = (state: RootState) => state.hubspot.campaigns.isLoading;
export const selectCampaignById = (campaignId: string) => (state: RootState) =>
  state.hubspot.campaigns.data?.find((campaign) => campaign.Id === campaignId);

export const selectWorkflows = (state: RootState) => state.hubspot.workflows.data;
export const selectWorkflowsIsLoading = (state: RootState) => state.hubspot.workflows.isLoading;
export const selectWorkflowById = (workflowId?: string) => (state: RootState) =>
  state.hubspot.workflows.data?.find((workflow) => workflow.hubspotWorkflowId === workflowId);
export const selectCampaignWorkflows = (campaignId: string) => (state: RootState) =>
  state.hubspot.workflows.data?.filter((workflow) =>
    workflow.workflowDetails.actions.some((action) => action.campaignId === campaignId),
  ) || [];

export const selectHubspotFields = (state: RootState) => state.hubspot.hubspotFields.data;
export const selectHubspotFieldsIsLoading = (state: RootState) =>
  state.hubspot.hubspotFields.isLoading;

export const selectHubspotFieldByName = (name: string) => (state: RootState) =>
  state.hubspot.hubspotFields.data.find((field) => field.name === name);

export default hubspotSlice.reducer;
