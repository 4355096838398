import type { LayoutData, OpenAiDescription } from '../components/documentation/ParserTypes';
import type { ConfigurationType } from '../components/documentation/dependencies/types';
import type { SearchResponse } from '../components/documentation/universal-search/useUniversalSearch';
import type { ObjectWithPills } from '../components/documentation/types';
import type { DocumentationPills } from './global/globalReducerTypes';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';
import { DocumentationTabTypes } from '../types/enums/DocumentationTabTypes';
import uniqWith from 'lodash/uniqWith';
import isEqual from 'lodash/isEqual';
import clone from 'lodash/clone';
import {
  ReadOnlyAutomation,
  ReadOnlyDedupMatching,
  ReadOnlyRollup,
} from '@server/read-only-elements.types';
import { defaultSortOptionsMap } from '../components/documentation/selected-object/sortUtilsAndConsts';
import { getObjectsWithPillsPerCategory } from '../components/documentation/cpq/utils';
import { generateFieldId } from '../components/documentation/universal-search/utils';

export interface SelectedDependency {
  id: string;
  name: string;
  dependencyType?: string;
  parentType: ConfigurationType;
  objectName?: string;
}

export interface TabFiltersAndSort {
  selectedStageValue?: string;
  selectedTypeValue?: string;
  selectedUsageValue?: string;
  selectedRecordTypeValue?: string;
  sortKey?: string;
}

export interface ObjectWithPillsMap {
  favorites: ObjectWithPills[];
  standardObjects: ObjectWithPills[];
  cpqObjects: ObjectWithPills[];
  customObjects: ObjectWithPills[];
}

export interface LayoutsByObjectName {
  [objectName: string]: { isLoading: boolean; layouts: LayoutData[] };
}

export interface DocumentationState {
  objects: ObjectWithPillsMap;
  parsedConfigurationItems: {
    [crmOrgId: string]: ConfigurationItem[]; //contains dependsOn/usedBy configuration items
  };
  dependsOnIdsForRules: {
    [crmOrgId: string]: { [ruleId: string]: ConfigurationTypesWithIds };
  };
  usedByIdsForFields: {
    //some of the fields don't have ids so it's generated via generateFieldId function
    [crmOrgId: string]: { [fieldIdOrObjectNameWithFieldName: string]: ConfigurationTypesWithIds };
  };
  usedByIdsForRules: {
    [crmOrgId: string]: { [ruleId: string]: ConfigurationTypesWithIds };
  };
  openAIDescriptions: {
    [crmOrgId: string]: { [ruleId: string]: OpenAiDescription };
  };
  dependencies?: SelectedDependency & {
    history: SelectedDependency[];
  };
  sweepElementsLatestDeployments: {
    [sweepElementId: string]: ReadOnlyAutomation | ReadOnlyDedupMatching | ReadOnlyRollup;
  };
  layouts: {
    [crmOrgId: string]: LayoutsByObjectName;
  };
  singleObject?: {
    objectTypeName?: ObjectTypeName;
    activeTab?: DocumentationTabTypes;
    searchTxt?: string;
    filtersAndSort: TabFiltersAndSort;
  };
  universalSearch?: {
    searchText?: string;
    results?: SearchResponse;
    selectedConfigurationType?: ConfigurationType;
    isResultsLoading?: boolean;
    loadingId?: string;
    isUniversalSearchListOpen?: boolean;
    prevSearchText?: string;
    filterKey?: string;
  };
  showLoader: boolean;
}

const initialState: DocumentationState = {
  objects: {
    favorites: [],
    standardObjects: [],
    cpqObjects: [],
    customObjects: [],
  },
  parsedConfigurationItems: {},
  dependsOnIdsForRules: {},
  usedByIdsForFields: {},
  usedByIdsForRules: {},
  openAIDescriptions: {},
  layouts: {},
  sweepElementsLatestDeployments: {}, //only for sweep elements
  showLoader: false,
};

export const documentationSlice = createSlice({
  name: 'documentation',
  initialState,
  reducers: {
    setObjects: (
      state,
      action: PayloadAction<{
        recordTypesData: RecordTypesData;
        funnelsData: FunnelsData;
        objectTypeNames: ObjectTypeName[];
        pills?: DocumentationPills;
        pillsFromObjectsParsedOnDemand?: DocumentationPills;
        transientObjects: { [objectName: string]: boolean };
      }>,
    ) => {
      const {
        funnelsData,
        recordTypesData,
        objectTypeNames,
        pills,
        pillsFromObjectsParsedOnDemand = {},
        transientObjects,
      } = action.payload;

      const { cpq, favorites, customObjectsNotInFM, standardObjectsNotInFunnelMap } =
        getObjectsWithPillsPerCategory({
          recordTypesData,
          funnelsData,
          objectTypeNames,
          pills,
          pillsFromObjectsParsedOnDemand,
          transientObjects,
        });

      state.objects = {
        favorites,
        standardObjects: standardObjectsNotInFunnelMap,
        cpqObjects: cpq,
        customObjects: customObjectsNotInFM,
      };
    },
    setObjectName: (
      state,
      action: PayloadAction<{
        objectTypeName?: ObjectTypeName;
      }>,
    ) => {
      const { objectTypeName } = action.payload;

      state.singleObject = {
        objectTypeName: objectTypeName,
        activeTab: DocumentationTabTypes.CARDS_LIST,
        searchTxt: '',
        filtersAndSort: {},
      };

      state.dependencies = undefined;
    },
    setSingleObjectName: (
      state,
      action: PayloadAction<{
        singleObjectName?: ObjectTypeName;
        tab?: DocumentationTabTypes;
      }>,
    ) => {
      const { singleObjectName, tab } = action.payload;

      if (!singleObjectName) {
        state.singleObject = undefined;
        return;
      }

      state.singleObject = {
        objectTypeName: singleObjectName,
        activeTab: tab,
        searchTxt: '',
        filtersAndSort: {
          sortKey: tab ? defaultSortOptionsMap[tab] : '', //if tab is empty, list of element types is displayed
        },
      };
    },
    clearDocumentationDialog: (state) => {
      state.singleObject = undefined;
      state.dependencies = undefined;
      state.universalSearch = undefined;
    },
    openPredefinedStepRulesInSingleObjectScreen: (
      state,
      action: PayloadAction<{
        singleObjectApiName: string;
        selectedStepName?: string;
        selectedRecordTypeValue?: string;
        defaultActiveTab: DocumentationTabTypes;
        objects?: ObjectTypeName[];
      }>,
    ) => {
      const {
        singleObjectApiName,
        selectedStepName,
        defaultActiveTab,
        objects,
        selectedRecordTypeValue,
      } = action.payload;

      state.singleObject = {
        objectTypeName: objects?.find((object) => object.objectType === singleObjectApiName),
        searchTxt: '',
        activeTab: defaultActiveTab,
        filtersAndSort: {
          ...state.singleObject?.filtersAndSort,
          selectedStageValue: selectedStepName,
          selectedRecordTypeValue,
        },
      };

      state.dependencies = undefined;
      state.universalSearch = undefined;
    },
    clearFilters: (state) => {
      if (state.singleObject) {
        state.singleObject.filtersAndSort = {};
      }
    },
    setDependenciesConfigurationItem: (
      state,
      action: PayloadAction<{
        id: string;
        parentType: ConfigurationType;
        dependencyType?: string;
        name: string;
        objectName?: string; //objectName is relevant ONLY for fields as they don't have explicit id and field name is unique only within objectName
        clearHistory?: boolean;
      }>,
    ) => {
      const { id, parentType, dependencyType, name, objectName, clearHistory } = action.payload;
      const history = clearHistory ? [] : state.dependencies?.history ?? [];
      state.dependencies = {
        id,
        name,
        parentType,
        dependencyType,
        objectName: objectName ?? '',
        history: [
          ...history,
          { id, parentType, dependencyType, name, objectName: objectName ?? '' },
        ],
      };
    },
    setLatestDeployment: (
      state,
      action: PayloadAction<{
        elementId: string;
        readOnlyElement: ReadOnlyAutomation | ReadOnlyDedupMatching | ReadOnlyRollup;
      }>,
    ) => {
      const { readOnlyElement: readOnlyAutomation, elementId } = action.payload;
      state.sweepElementsLatestDeployments[elementId] = readOnlyAutomation;
    },
    clearDependencies: (state) => {
      state.dependencies = undefined;
      state.sweepElementsLatestDeployments = {};
    },
    goBackInHistory: (state) => {
      const dependenciesHistory = state.dependencies?.history ?? [];

      if (state.dependencies && dependenciesHistory?.length > 1) {
        const lastIdx = state.dependencies.history.length - 1;
        state.dependencies.history.splice(lastIdx, 1);

        const prev = state.dependencies.history[lastIdx - 1];

        if (prev) {
          state.dependencies = {
            id: prev.id,
            name: prev.name,
            parentType: prev.parentType,
            dependencyType: prev.dependencyType,
            objectName: prev.objectName,
            history: state.dependencies.history,
          };
        } else {
          state.dependencies.history = [];
        }
      }
    },
    setTab: (
      state,
      action: PayloadAction<{
        tab: DocumentationTabTypes;
      }>,
    ) => {
      state.singleObject = {
        ...state.singleObject,
        activeTab: action.payload.tab,
        searchTxt: '',
        filtersAndSort: {
          sortKey: defaultSortOptionsMap[action.payload.tab],
        },
      };
      state.dependencies = undefined;
    },
    setFiltersAndSort: (state, action: PayloadAction<{ filtersAndSort: TabFiltersAndSort }>) => {
      if (state.singleObject) {
        state.singleObject = {
          ...state.singleObject,
          filtersAndSort: action.payload.filtersAndSort,
        };
      }
    },
    setSingleObjectSearchTxt: (state, action: PayloadAction<{ searchTxt: string }>) => {
      if (state.singleObject) {
        state.singleObject = {
          ...state.singleObject,
          searchTxt: action.payload.searchTxt,
        };
      }
    },
    setUniversalSearchTxt: (state, action: PayloadAction<{ searchTxt: string }>) => {
      state.universalSearch = {
        ...state.universalSearch,
        searchText: action.payload.searchTxt,
      };
    },
    setUniversalSearchIsResultLoading: (
      state,
      action: PayloadAction<{ isLoading: boolean; id: string }>,
    ) => {
      const prevSearchText = state.universalSearch?.searchText;
      state.universalSearch = {
        ...state.universalSearch,
        isResultsLoading: action.payload.isLoading,
        loadingId: action.payload.id,
        prevSearchText: prevSearchText ? prevSearchText.trim() : '',
        isUniversalSearchListOpen: true,
      };
    },
    setUniversalSearchListIsOpen: (state, action: PayloadAction<{ isOpen: boolean }>) => {
      const { isOpen } = action.payload;
      state.universalSearch = {
        ...state.universalSearch,
        isUniversalSearchListOpen: isOpen,
      };
    },
    setUniversalSearchResults: (
      state,
      action: PayloadAction<{ results: SearchResponse; loadingId: string }>,
    ) => {
      const currentId = state.universalSearch?.loadingId;
      state.universalSearch = {
        ...state.universalSearch,
        isResultsLoading: !(currentId === action.payload.loadingId), //to continue displaying loader regardless how many times user change query while waiting for response
        results: action.payload.results,
      };
    },
    setUniversalSearchFilter: (
      state,
      action: PayloadAction<{ filterKey: ConfigurationType | undefined }>,
    ) => {
      state.universalSearch = {
        ...state.universalSearch,
        filterKey: action.payload.filterKey,
      };
    },
    clearUniversalSearch: (state) => {
      state.universalSearch = undefined;
    },
    setConfigurationDependenciesForRule: (
      state,
      action: PayloadAction<{
        crmOrgId: string;
        ruleId: string;
        dependsOn: ConfigurationDependencies;
      }>,
    ) => {
      const { crmOrgId, ruleId, dependsOn } = action.payload;

      const oldParsedConfigItems = clone(state.parsedConfigurationItems[crmOrgId] ?? []);
      const { parsedConfigurationItemsIdsToType, parsedConfigurationItems } =
        getItemsForDependencies(dependsOn, oldParsedConfigItems);

      state.parsedConfigurationItems[crmOrgId] = [...parsedConfigurationItems];

      const oldDependencyIds = state.dependsOnIdsForRules[crmOrgId];
      state.dependsOnIdsForRules[crmOrgId] = {
        ...oldDependencyIds,
        [ruleId]: parsedConfigurationItemsIdsToType,
      };
    },
    updateOrAddFieldToParsedConfigurationItems: (
      state,
      action: PayloadAction<{
        crmOrgId: string;
        field: FieldMetadataRecordProperties;
        objectName: string;
      }>,
    ) => {
      const { crmOrgId, field, objectName } = action.payload;

      let currentParsedConfigurationItems = state.parsedConfigurationItems[crmOrgId] ?? [];
      const isField = currentParsedConfigurationItems.find((item) => item.id === field.id);

      if (isField) {
        currentParsedConfigurationItems = currentParsedConfigurationItems.map((item) =>
          item.id === field.id && field.objectName === objectName ? field : item,
        );
      } else {
        currentParsedConfigurationItems.push(field);
      }

      state.parsedConfigurationItems[crmOrgId] = currentParsedConfigurationItems;
    },
    addConfigurationItems: (
      state,
      action: PayloadAction<{
        crmOrgId: string;
        newConfigurationItems: SearchResponse;
      }>,
    ) => {
      const { crmOrgId, newConfigurationItems } = action.payload;

      const oldConfigurationItems = state.parsedConfigurationItems[crmOrgId] ?? [];
      const { parsedConfigurationItems } = getItemsForDependencies(
        newConfigurationItems,
        oldConfigurationItems,
      );

      state.parsedConfigurationItems[crmOrgId] = parsedConfigurationItems;
    },
    setConfigurationUsedByForField: (
      state,
      action: PayloadAction<{
        crmOrgId: string;
        fieldName: string;
        usedBy: ConfigurationConsumers;
        objectName: string;
        id?: string;
      }>,
    ) => {
      const { crmOrgId, usedBy, fieldName, objectName, id } = action.payload;

      const oldParsedConfigItems = state.parsedConfigurationItems[crmOrgId] ?? [];
      const { parsedConfigurationItemsIdsToType, parsedConfigurationItems } =
        getItemsForDependencies(usedBy, oldParsedConfigItems);
      state.parsedConfigurationItems[crmOrgId] = parsedConfigurationItems;

      const oldUsedByIds = state.usedByIdsForFields[crmOrgId];
      const _id = generateFieldId({ id, name: fieldName, objectName });

      state.usedByIdsForFields[crmOrgId] = {
        ...oldUsedByIds,
        [_id]: parsedConfigurationItemsIdsToType,
      };
    },
    setConfigurationUsedByForRule: (
      state,
      action: PayloadAction<{
        crmOrgId: string;
        ruleId: string;
        usedBy: ConfigurationConsumers;
      }>,
    ) => {
      const { crmOrgId, ruleId, usedBy } = action.payload;

      const oldParsedConfigItems = state.parsedConfigurationItems[crmOrgId] ?? [];
      const { parsedConfigurationItemsIdsToType, parsedConfigurationItems } =
        getItemsForDependencies(usedBy, oldParsedConfigItems);

      state.parsedConfigurationItems[crmOrgId] = parsedConfigurationItems;
      const oldUsedByIds = state.usedByIdsForRules[crmOrgId];
      state.usedByIdsForRules[crmOrgId] = {
        ...oldUsedByIds,
        [ruleId]: parsedConfigurationItemsIdsToType,
      };
    },
    setOpenAiDescriptionForRule: (
      state,
      action: PayloadAction<{
        crmOrgId: string;
        openAiDescription: OpenAiDescription;
        ruleId: string;
      }>,
    ) => {
      const { crmOrgId, openAiDescription, ruleId } = action.payload;

      const oldDescriptions = state.openAIDescriptions[crmOrgId];
      state.openAIDescriptions[crmOrgId] = {
        ...oldDescriptions,
        [ruleId]: openAiDescription,
      };
    },
    setShowLoader: (state, action: PayloadAction<{ showLoader: boolean }>) => {
      state.showLoader = action.payload.showLoader;
    },
    setLayouts: (
      state,
      action: PayloadAction<{
        crmOrgId: string;
        objectName: string;
        isLoading: boolean;
        layouts?: LayoutData[];
      }>,
    ) => {
      const { crmOrgId, objectName, isLoading, layouts } = action.payload;

      if (!state.layouts[crmOrgId]) {
        state.layouts[crmOrgId] = {
          [objectName]: {
            isLoading,
            layouts: layouts ?? [],
          },
        };
        return;
      }

      state.layouts[crmOrgId][objectName] = {
        isLoading,
        layouts: layouts ?? [],
      };
    },
  },
});

// helper function to extract ids from ConfigurationConsumers | ConfigurationDependencies
const getItemsForDependencies = (
  values: ConfigurationConsumers | ConfigurationDependencies,
  storedParsedDependencies: ConfigurationItem[],
): {
  parsedConfigurationItemsIdsToType: ConfigurationTypesWithIds;
  parsedConfigurationItems: ConfigurationItem[];
} => {
  let helperArray: ConfigurationItem[] = [];
  const dependenciesIds = {} as ConfigurationTypesWithIds;

  Object.keys(values).forEach((depKey) => {
    const key = depKey as ConfigurationType;
    //any as workaround,
    // TS is complaining for some of the keys not being valid key for ConfigurationConsumers | ConfigurationDependencies,
    //as Consumers and Dependencies don't contain ALL of the ConfigurationItemType keys
    const _values = values as any;
    const arr: ConfigurationItem[] = _values[key];
    const idsOnly: string[] = arr.map((item) => item.id);

    dependenciesIds[key] = idsOnly;
    helperArray = helperArray.concat(arr);
  });

  return {
    parsedConfigurationItemsIdsToType: dependenciesIds,
    parsedConfigurationItems: uniqWith(
      [...clone(storedParsedDependencies), ...helperArray],
      isEqual,
    ),
  };
};

export const {
  setSingleObjectName,
  clearDocumentationDialog,
  openPredefinedStepRulesInSingleObjectScreen,
  clearFilters,
  setTab,
  setFiltersAndSort,
  setSingleObjectSearchTxt,

  setObjectName,
  setObjects,

  setLayouts,
  setLatestDeployment,
  setDependenciesConfigurationItem,
  clearDependencies,
  goBackInHistory,

  setUniversalSearchTxt,
  setUniversalSearchIsResultLoading,
  setUniversalSearchResults,
  clearUniversalSearch,
  setUniversalSearchFilter,
  setUniversalSearchListIsOpen,

  setConfigurationDependenciesForRule,
  updateOrAddFieldToParsedConfigurationItems,
  addConfigurationItems,
  setConfigurationUsedByForField,
  setConfigurationUsedByForRule,
  setOpenAiDescriptionForRule,
  setShowLoader,
} = documentationSlice.actions;

export const selectLoader = (crmOrgId: string) => (state: RootState) =>
  state.documentation.showLoader && !state.global.environments[crmOrgId]?.data?.documentation;

export const selectSingleObject = (state: RootState) =>
  state.documentation.singleObject?.objectTypeName;

export const selectSingleObjectActiveTab = (state: RootState) =>
  state.documentation.singleObject?.activeTab;

export const selectSingleObjectFilters = (state: RootState) =>
  state.documentation.singleObject?.filtersAndSort;

export const selectSingleObjectTabSortKey = (state: RootState) =>
  state.documentation.singleObject?.filtersAndSort?.sortKey;

export const selectSingleObjectSearchTxt = (state: RootState) =>
  state.documentation.singleObject?.searchTxt;

export const selectDependenciesConfigurationItem = (state: RootState) =>
  state.documentation.dependencies;

export const selectLatestDeployment = (elementId: string) => (state: RootState) =>
  state.documentation.sweepElementsLatestDeployments[elementId];

export const selectDependenciesHistory = (state: RootState) =>
  state.documentation.dependencies?.history?.length ?? 0;

export const selectUniversalSearch = (state: RootState) => state.documentation.universalSearch;
export const selectObjects = (state: RootState) => state.documentation.objects;

export const selectOpenAiDescriptionForRule =
  (ruleId: string, crmOrgId: string) => (state: RootState) =>
    state.documentation.openAIDescriptions[crmOrgId]?.[ruleId];

export const selectParsedConfigurationItems = (crmOrgId: string) => (state: RootState) =>
  state.documentation.parsedConfigurationItems?.[crmOrgId];

export const selectDependencyByType =
  (type: 'dependsOnIdsForRules' | 'usedByIdsForRules' | 'usedByIdsForFields') =>
  (state: RootState) =>
    state.documentation[type];

export const selectLayoutsByObjectName = (crmOrgId: string) => (state: RootState) =>
  state.documentation.layouts[crmOrgId];

export default documentationSlice.reducer;
