import _keyBy from 'lodash/keyBy';
import { useCallback } from 'react';
import { FunnelField } from '../constants/fieldsManagementConsts';
import extractErrorMsg from '../components/helpers/extractErrorMsg';
import {
  GetSweepFieldsIdsByNamesResponse,
  SweepFieldsGetParams,
  useSweepFieldsApiFacade,
  SweepFieldsDtoResponse,
  SweepFieldsDto,
} from '../apis/facades/useSweepFieldsApiFacade';
import { telemetry } from '../telemetry';

export interface SweepFieldsDtoWithRefObjectNames {
  objectType: string;
  fields: SweepField[];
  queryFields?: Set<string>;
}

export type SweepFieldsByObjectType = {
  [objectType: string]: SweepFieldsDtoWithRefObjectNames;
};

export interface SweepFieldsMapWithReferencesType {
  _sweepFieldsMap: SweepFieldsByObjectType;
}

export interface RefreshValueSetResponse {
  data: {
    before?: SweepFieldsDto;
    after?: SweepFieldsDto;
    error?: string;
  };
}

let fieldsCache: { [urlAsCacheKey: string]: SweepFieldsDtoResponse } = {};

const getSweepFieldLabel = (field?: SweepField) =>
  field?.sweepFieldName || field?.sfFieldName || '';

const getSweepFieldValue = (field?: SweepField) =>
  field?.sfFieldName || field?.sweepFieldName || '';

const sortSweepFieldByLabel = (fieldA: SweepField, fieldB: SweepField) =>
  getSweepFieldLabel(fieldA).localeCompare(getSweepFieldLabel(fieldB));

const sortSweepFieldByLeadingCandidate = (fieldA: SweepField, fieldB: SweepField) =>
  Number(fieldB.isLeadingCandidate) - Number(fieldA.isLeadingCandidate);

const sortSweepFieldByLeadingCandidateAndLabel = (fieldA: SweepField, fieldB: SweepField) =>
  sortSweepFieldByLeadingCandidate(fieldA, fieldB) || sortSweepFieldByLabel(fieldA, fieldB);

const sortSweepFields = (sweepFields: SweepFieldsDto) => ({
  ...sweepFields,
  fields: sweepFields.fields.sort(sortSweepFieldByLeadingCandidateAndLabel),
});

const clearSweepFieldsCache = () => {
  fieldsCache = {};
};

const useCachedSweepFields = () => {
  const {
    get_sweepFields,
    get_sweepFieldsIdsByNames,
    post_sweepField,
    put_sweepField,
    delete_sweepField,
    post_refreshSweepFieldValueSet,
  } = useSweepFieldsApiFacade();

  const getSweepFields = useCallback(
    async (params: SweepFieldsGetParams): Promise<SweepFieldsDtoResponse> => {
      const cacheKey = JSON.stringify(params);
      const sweepFieldsResponse: SweepFieldsDtoResponse =
        fieldsCache[cacheKey] || (await get_sweepFields(params));

      if (!sweepFieldsResponse.complete) {
        clearSweepFieldsCache();
      }

      if (!fieldsCache[cacheKey] && sweepFieldsResponse.complete) {
        fieldsCache[cacheKey] = sweepFieldsResponse;
      }

      return {
        ...sweepFieldsResponse,
        sweepFields: sweepFieldsResponse.sweepFields.map(sortSweepFields),
      };
    },
    [get_sweepFields],
  );

  const searchSweepFields = useCallback(
    async (
      params: SweepFieldsGetParams,
    ): Promise<{
      complete: boolean;
      isFirstLoad: boolean;
      sweepFields: SweepFieldsByObjectType;
    }> => {
      const { complete, sweepFields } = await getSweepFields(params);

      const filterLookupFieldsWithoutObjs = (field: SweepField) =>
        field.fieldType !== 'Lookup' || field.objectNames !== undefined;

      const sweepFieldsMap: SweepFieldsDtoWithRefObjectNames[] = sweepFields.map((_sweepFields) => {
        const queryFields = _sweepFields.queryFields
          ? new Set(_sweepFields.queryFields)
          : undefined;

        return {
          objectType: _sweepFields.objectType,
          queryFields,
          fields: _sweepFields.fields
            .map((sweepField): SweepField => {
              const ref = _sweepFields.references?.find((ref) => ref.fieldId === sweepField.id);
              const objectNames = ref?.objectNames;
              return {
                ...sweepField,
                objectNames,
              };
            })
            .filter(filterLookupFieldsWithoutObjs),
        };
      });

      const isFirstLoad = sweepFields.length === 0 && !complete;
      return {
        complete,
        isFirstLoad,
        sweepFields: _keyBy(sweepFieldsMap, 'objectType'),
      };
    },
    [getSweepFields],
  );

  const getDefaultSweepField = useCallback(
    async ({ crmOrgId }: { crmOrgId: string }) => {
      const { sweepFields } = await searchSweepFields({
        objectType: ['Lead'],
        crmOrgId,
      });
      const statusFieldId = sweepFields['Lead'].fields.find(
        (field) => field.sfFieldName === 'Lead.Status',
      )?.id;
      if (statusFieldId) {
        return {
          _leadingFieldLabels: ['Lead', 'Status'],
          _leadingFieldId: statusFieldId,
        };
      }
    },
    [searchSweepFields],
  );

  const getSweepFieldsById = useCallback(
    async (query: { fieldIds: string[]; crmOrgId?: string }) => {
      try {
        const response = await get_sweepFields({
          ...query,
          includeSiblings: false,
        });

        return response.sweepFields.flatMap((sfs: any) => sfs.fields) as SweepField[];
      } catch (e) {
        return [];
      }
    },
    [get_sweepFields],
  );

  const getSweepFieldIdsByName = useCallback(
    async ({
      fieldNames,
      crmOrgId,
    }: {
      fieldNames: string[];
      crmOrgId: string;
    }): Promise<GetSweepFieldsIdsByNamesResponse> => {
      try {
        return get_sweepFieldsIdsByNames({ fieldNames, crmOrgId });
      } catch (e: any) {
        const error = e.response?.data?.message;
        throw new Error(error);
      }
    },
    [get_sweepFieldsIdsByNames],
  );

  const createField = async ({
    field,
    pinToFunnelMap,
    crmOrgId,
  }: {
    field: SweepField;
    pinToFunnelMap?: string;
    crmOrgId: string;
  }) => {
    const body: SweepField = { ...field, pinToFunnelMap };
    try {
      const response = await post_sweepField({ crmOrgId, field: body });
      clearSweepFieldsCache();
      return response;
    } catch (e) {
      telemetry.captureError(e);
      const error = extractErrorMsg(e);
      throw new Error(`Create field error: ${error}`);
    }
  };

  const editField = async ({
    field,
    crmOrgId,
    enableDeduceValuesOrder,
  }: {
    field: SweepField | FunnelField;
    crmOrgId?: string;
    enableDeduceValuesOrder?: boolean;
  }) => {
    try {
      if (!field.id) {
        throw new Error('Field id is missing');
      }
      const updatedField = await put_sweepField({
        crmOrgId,
        field,
        fieldId: field.id,
        enableDeduceValuesOrder,
      });

      clearSweepFieldsCache();
      return updatedField;
    } catch (e) {
      telemetry.captureError(e);
      const error = extractErrorMsg(e);
      throw new Error(`Update field error: ${error}`);
    }
  };

  const deleteField = async ({ field }: { field: SweepField }) => {
    if (!field.id) return;
    try {
      await delete_sweepField({ field });
      clearSweepFieldsCache();
    } catch (e) {
      const error = extractErrorMsg(e);
      throw new Error(`Delete field error: ${error}`);
    }
  };

  const refreshFieldValueSet = async ({
    fieldId,
    crmOrgId,
    refreshFromCrmOrgId,
  }: {
    fieldId: string;
    crmOrgId: string;
    refreshFromCrmOrgId: string;
  }) => {
    const response = await post_refreshSweepFieldValueSet({
      fieldId,
      crmOrgId,
      refreshFromCrmOrgId,
    });
    clearSweepFieldsCache();
    return response as RefreshValueSetResponse;
  };

  return {
    searchSweepFields,
    getSweepFieldLabel,
    getSweepFieldValue,
    getDefaultSweepField,
    clearSweepFieldsCache,
    createField,
    editField,
    deleteField,
    getSweepFieldsById,
    getSweepFields,
    getSweepFieldIdsByName,
    refreshFieldValueSet,
  };
};

export { useCachedSweepFields as useSweepFields, clearSweepFieldsCache };
