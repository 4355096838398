import { ReactNode, useMemo } from 'react';
import { UI_EVENTS } from '../../services/events';
import { AutomationType } from '../../types/enums/AutomationType';
import { WalkthroughPointType } from '@server/walkthrough';
import { Permission } from '@server/permissions';
import React from 'react';
import { DeployButtonContextProvider } from '../common/deploy-button/DeployButtonContext';
import { AutomationFormVariant } from '../../types/enums/AutomationFormVariant';

export interface AutomationsContextType {
  automationType: AutomationType;
  emptyStateTitle: string;
  emptyStateSubTitle?: string;
  emptyStateImage: ReactNode;
  defaultName: string;
  placeholder: string;
  nameInMessage: string;
  nameInMessageSingular: string;
  walkthroughPointEmptyNew?: WalkthroughPointType;
  walkthroughPointEdit?: WalkthroughPointType;
  walkthroughPointTrigger?: WalkthroughPointType;
  openEvent: UI_EVENTS;
  automationVariant: AutomationFormVariant;
  templateSupport: boolean;
  permissions: {
    create: Permission;
    edit: Permission;
    deploy: Permission;
    delete: Permission;
  };
  initialAutomationJson?: DeepPartial<AutomationStructureNew>;
}
const AutomationsFormContext = React.createContext<
  AutomationsContextType & {
    initialized: boolean;
    automationJson: DeepPartial<AutomationStructureNew>;
    setAutomationJson: (automationJson: DeepPartial<AutomationStructureNew>) => void;
  }
>({
  initialized: false,
  automationType: AutomationType.Default,
  permissions: {
    create: 'create:automations',
    edit: 'edit:automations',
    deploy: 'deploy:automations',
    delete: 'delete:automations',
  },
  emptyStateTitle: '',
  emptyStateImage: '',
  defaultName: '',
  placeholder: '',
  nameInMessage: '',
  nameInMessageSingular: '',
  walkthroughPointEmptyNew: undefined,
  walkthroughPointEdit: undefined,
  walkthroughPointTrigger: undefined,
  openEvent: UI_EVENTS.alertsOpen,
  automationVariant: AutomationFormVariant.AUTOMATIONS,
  templateSupport: true,
  automationJson: {},
  setAutomationJson: () => {},
});

export const useAutomationsContext = () => {
  const context = React.useContext(AutomationsFormContext);
  if (!context.initialized) {
    throw new Error(
      'useAutomationsContext must be used within a AutomationsFormContext. Use AutomationsContextProvider to wrap your component.',
    );
  }
  return context;
};

export const AutomationsContextProvider = ({
  children,
  value,
}: {
  children: ReactNode;
  value: AutomationsContextType;
}) => {
  const [automationJson, setAutomationJson] = React.useState<DeepPartial<AutomationStructureNew>>(
    value.initialAutomationJson ?? {},
  );
  const formContextValue = useMemo(
    () => ({ ...value, automationJson, setAutomationJson, initialized: true }),
    [automationJson, value],
  );
  const deployButtonContextValue = useMemo(
    () => ({
      permissions: value.permissions,
      entityNamePlural: value.nameInMessage,
      entityName: value.nameInMessageSingular,
    }),
    [value],
  );

  return (
    <AutomationsFormContext.Provider value={formContextValue}>
      <DeployButtonContextProvider value={deployButtonContextValue}>
        {children}
      </DeployButtonContextProvider>
    </AutomationsFormContext.Provider>
  );
};
