export enum WalkthroughPointType {
  documentationExploreConfig = 'documentationExploreConfig',
  documentationAi = 'documentationAi',
  documentationUtilizationTooltip = 'documentationUtilizationTooltip',
  rollupsEmptyNew = 'rollupsEmptyNew',
  rollupsEdit = 'rollupsEdit',
  automationsEmptyNew = 'automationsEmptyNew',
  automationsTrigger = 'automationsTrigger',
  automationsEdit = 'automationsEdit',
  alertsEmptyNew = 'alertsEmptyNew',
  alertsTrigger = 'alertsTrigger',
  alertsEdit = 'alertsEdit',
  assignmentsEmptyNew = 'assignmentsEmptyNew',
  assignmentsTrigger = 'assignmentsTrigger',
  assignmentsEdit = 'assignmentsEdit',
  dedupeEmptyNew = 'dedupeEmptyNew',
  dedupeEdit = 'dedupeEdit',
  routingSetupMembers = 'routingSetupMembers',
  invite = 'invite',
  envSelectorConnectProd = 'envSelectorConnectProd',
  funnelsTemplates = 'funnelsTemplates',
}
