import { useCallback } from 'react';
import { Box, Grid } from '@mui/material';
import { StyledRadio } from '../../../../common/StyledComponents';
import { EditableLabel } from '../../../../common/EditableLabel';
import {
  ActionableDataTable,
  ActionableDataTableActionTypes,
} from '../../../../common/table/ActionableDataTable';

const columns = [
  {
    field: 'default',
    headerName: 'Default',
    width: '100px',
    showDragHandle: true,
  },
  { field: 'value', headerName: 'Value', width: '300px', showDragHandle: false },
];

interface PicklistTableRow {
  id: string;
  default: JSX.Element;
  value: JSX.Element;
}

const PicklistTable = ({
  valueSet,
  handleChange,
  isLeadingField,
}: {
  valueSet: PicklistValue[];
  handleChange: (valueSet: PicklistValue[]) => void;
  isLeadingField?: boolean;
}) => {
  const reorderRows = useCallback(
    ({ sourceIndex, destinationIndex }: { sourceIndex: number; destinationIndex: number }) => {
      const result = [...valueSet];
      result.splice(sourceIndex, 1);
      result.splice(destinationIndex, 0, valueSet[sourceIndex]);
      handleChange(result);
    },
    [valueSet, handleChange],
  );

  const updateValue = useCallback(
    (index: number) => (value: string) => {
      const updatedValueSet = valueSet.map((v, i) => {
        return i === index ? { ...v, label: value, fullName: value } : v;
      });
      handleChange(updatedValueSet);
    },
    [handleChange, valueSet],
  );

  const updateIsDefault = useCallback(
    (index: number) => () => {
      const currentValue = valueSet[index].default;
      const updatedValueSet = valueSet.map((v, i) => {
        return { ...v, default: i === index ? !currentValue : false };
      });
      handleChange(updatedValueSet);
    },
    [handleChange, valueSet],
  );

  const rows: PicklistTableRow[] = valueSet.map((picklistValue, idx) => {
    const { default: isDefault, fullName, label } = picklistValue;

    return {
      ...picklistValue,
      id: fullName,
      default: (
        <Grid container justifyContent="center" alignItems="center">
          <StyledRadio checked={isDefault} onClick={updateIsDefault(idx)} />
        </Grid>
      ),
      value: isLeadingField ? (
        <Box>{label}</Box>
      ) : (
        <Box sx={{ transform: 'translateX(-8px)' }}>
          {/* translateX is to compensate on the left padding coming from the editable label, and keep the value aligned with the header */}
          <EditableLabel
            value={label ?? ''}
            onValueConfirm={updateValue(idx)}
            maxCharLength={255}
            minCharLength={1}
            inputSx={{ fontSize: 12, fontWeight: 400 }}
          />
        </Box>
      ),
    };
  });

  return (
    <ActionableDataTable
      allowReorder
      columns={columns}
      rows={rows}
      onOrderChange={reorderRows}
      actions={[
        {
          type: ActionableDataTableActionTypes.DELETE,
          confirm: true,
          confirmButtonOkLabel: 'Yes, Delete',
          confirmButtonCancelLabel: 'Cancel',
          confirmTitle: 'Delete value',
          confirmSubtitle: 'Are you sure you want to delete this value?',
        },
      ]}
      onActionClick={({ actionType, idx }) => {
        switch (actionType) {
          case ActionableDataTableActionTypes.DELETE:
            const updatedValueSet = valueSet.filter((v, i) => idx !== i);
            handleChange(updatedValueSet);
        }
      }}
    />
  );
};

export default PicklistTable;
