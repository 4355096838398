import { Paper } from '@mui/material';
import { Button, Typography, colors, IconButton } from '@sweep-io/sweep-design';
import { Close, Info, CheckCircle, Warning, Prohibit } from '@sweep-io/sweep-design/dist/icons';
import { VerticalCenteredBox } from '../pages/environments/styledComponents';
import { SweepNotificationVariant } from '../../reducers/notificationsReducer';

interface NotificationAlertProps {
  message: React.ReactNode;
  onClose: () => any;
  severity?: SweepNotificationVariant;
  action?: {
    actionText: string;
    onActionClick: () => any;
  };
  details?: string;
}

const colorsMap = ({ variant }: { variant: SweepNotificationVariant }) => {
  const possibilities = {
    [SweepNotificationVariant.Success]: {
      background: colors.mint[100],
    },
    [SweepNotificationVariant.Error]: {
      background: colors.blush[300],
    },
    [SweepNotificationVariant.Default]: {
      background: colors.grey[200],
    },
    [SweepNotificationVariant.Info]: {
      background: colors.mint[100],
    },
    [SweepNotificationVariant.Warning]: {
      background: colors.sun[300],
    },
  };

  return possibilities[variant];
};

const severityIcon = {
  [SweepNotificationVariant.Success]: CheckCircle,
  [SweepNotificationVariant.Error]: Prohibit,
  [SweepNotificationVariant.Default]: Info,
  [SweepNotificationVariant.Info]: Info,
  [SweepNotificationVariant.Warning]: Warning,
};

export const NotificationAlert = ({
  message,
  onClose,
  severity = SweepNotificationVariant.Success,
  action,
  details,
}: NotificationAlertProps) => {
  const SeverityIcon = severityIcon[severity];
  const mappedColors = colorsMap({ variant: severity });
  const textColor = colors.night[900];

  return (
    <Paper
      elevation={4}
      sx={{
        width: '480px',
        borderRadius: '4px',
        padding: 1,
        paddingLeft: 2,
        background: mappedColors.background,
      }}
    >
      <VerticalCenteredBox
        sx={{
          padding: '0',
          justifyContent: 'space-between',
        }}
      >
        <VerticalCenteredBox sx={{ gap: 1 }}>
          <SeverityIcon color={textColor} />

          <Typography color={textColor} variant="caption-bold">
            {message}
          </Typography>
        </VerticalCenteredBox>
        <VerticalCenteredBox sx={{ gap: 1 }}>
          {action && (
            <Button variant="link" size="small" onClick={action.onActionClick}>
              <Typography textDecoration="underline" color={textColor} variant="caption-bold">
                {action.actionText}
              </Typography>
            </Button>
          )}

          <IconButton size="small" variant="link" onClick={onClose}>
            <Close />
          </IconButton>
        </VerticalCenteredBox>
      </VerticalCenteredBox>
      {details && <VerticalCenteredBox ml={3}>
        <Typography ellipsis whiteSpace="normal" color={textColor} variant="caption">
          {details}
        </Typography>
      </VerticalCenteredBox>}
    </Paper>
  );
};
