import { Box, CircularProgress, SxProps, TextField } from '@mui/material';
import { Typography, Tag } from '@sweep-io/sweep-design';
import { forwardRef } from 'react';

export interface TagItem {
  value: string;
  label: string;
  startIconName?: string;
  color?: string;
  onDelete?: (value: string) => void;
  isLoader?: boolean;
}

export interface TextFieldWithTagsProps {
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  tags: TagItem[];
  onAddValue?: (value: string) => any;
  isValueValid?: (value: string) => boolean;
  placeholder?: string;
  error?: string;
  readonly?: boolean;
  startText?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  autoFocus?: boolean;
  value?: string;
  sx?: SxProps;
  hideDeleteButton?: boolean;
}

export const TextFieldWithTags = forwardRef<HTMLDivElement, TextFieldWithTagsProps>(
  (
    {
      onChange,
      tags,
      onAddValue,
      isValueValid,
      error,
      placeholder: inputPlaceholder = '',
      readonly,
      startText,
      autoFocus,
      onClick,
      value,
      sx,
      hideDeleteButton,
    },
    ref,
  ) => {
    const handleKeyDown = (evt: any) => {
      if (!onAddValue) return;
      if (['Enter', 'Tab', ','].includes(evt.key) || evt.type === 'blur') {
        evt.preventDefault();
        const value = evt.target.value.trim();
        if (value && (!isValueValid || isValueValid(value))) {
          evt.target.value = '';
          onAddValue(value);
        }
      }
    };

    const renderTags = () => {
      if (tags.length === 0 && !startText) return null;
      return (
        <Box
          sx={{
            gap: '8px',
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          {startText && (
            <Box marginRight="12px">
              <Typography variant="body" key="Typography">
                {startText}
              </Typography>
            </Box>
          )}

          {tags.map((tag) =>
            tag.isLoader ? (
              <CircularProgress key={tag.value + 'loader'} size={20} />
            ) : (
              <Tag
                key={tag.value}
                onDelete={
                  readonly || hideDeleteButton
                    ? undefined
                    : () => {
                        tag.onDelete && tag.onDelete(tag.value);
                      }
                }
                label={tag.label}
                startIconName={tag.startIconName}
                color={tag.color}
              />
            ),
          )}
        </Box>
      );
    };

    return (
      <TextField
        disabled={readonly}
        ref={ref}
        onBlur={handleKeyDown}
        onChange={onChange}
        onKeyDown={handleKeyDown}
        error={Boolean(error)}
        helperText={error}
        onClick={onClick}
        autoFocus={autoFocus}
        value={value}
        InputProps={{
          placeholder: inputPlaceholder,
          sx: {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'flex-start',
            rowGap: '8px',
            paddingTop: '10px',
            paddingBottom: '10px',
            '& input': {
              minWidth: '30px',
              width: 'auto',
              paddingTop: '0',
              paddingBottom: 0,
              alignSelf: 'center',
            },
          },
          disabled: readonly,
          startAdornment: renderTags(),
        }}
        sx={{
          ...sx,
        }}
      />
    );
  },
);
