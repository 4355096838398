import { Box } from '@mui/material';
import { Button } from '@sweep-io/sweep-design';

export const ListItemButtons = ({
  onPreviewClick,
  onAddClick,
}: {
  onPreviewClick: () => void;
  onAddClick: () => void;
}) => (
  <Box sx={{ display: 'flex', gap: 1 }} className="buttons-on-hover">
    <Button variant="outlined" size="small" onClick={onPreviewClick}>
      Preview
    </Button>
    <Button variant="filled" size="small" onClick={onAddClick}>
      Add
    </Button>
  </Box>
);
