import Box from '@mui/material/Box';
import { Typography, Button } from '@sweep-io/sweep-design';
import { useCallback } from 'react';

export const TalkToProductExpert = ({ closeDialog }: { closeDialog: () => void }) => {
  const onButtonClick = useCallback(() => {
    closeDialog && closeDialog();
    if ((window as any).Intercom) {
      (window as any).Intercom('showMessages');
      (window as any).Intercom('showNewMessage');
    }
  }, [closeDialog]);

  return (
    <Box display="flex" alignItems="center" gap={0.2}>
      <Typography variant="body">Need help?</Typography>
      <Button type="primary" variant="link" onClick={onButtonClick}>
        Talk to a product expert
      </Button>
    </Box>
  );
};
