import React, { useEffect } from 'react';
import CreateEditFieldForm from './CreateEditFieldForm';
import useCreateEditField from './useCreateEditField';
import { EditMode, FunnelField } from '../../../../../../constants/fieldsManagementConsts';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Button, IconButton, Typography } from '@sweep-io/sweep-design';
import { Close as CloseIcon } from '@sweep-io/sweep-design/dist/icons/Close';

const CreateEditField = ({
  crmOrgId,
  onCreateFieldCb,
  mode,
  initialValues,
  onUpdateFieldCb,
  handleTryCloseDialog,
  isLeadingField,
  closeDialog,
  setIsPristine,
}: {
  crmOrgId: string;
  onCreateFieldCb?: (newField: SweepField) => void;
  onUpdateFieldCb?: (updatedField: SweepField) => void;
  mode: EditMode;
  initialValues?: Partial<FunnelField>;
  handleTryCloseDialog: () => void;
  isLeadingField?: boolean;
  closeDialog: () => void;
  setIsPristine: (isPristine: boolean) => void;
}) => {
  const isEditMode = mode === EditMode.EDIT;

  const {
    errors,
    values: updatedValues,
    reset: resetForm,
    handleConfirm,
    setFieldValue,
    isSubmitted,
    dirty,
  } = useCreateEditField({
    initialValues,
    crmOrgId,
    closeCreateDialog: closeDialog,
    onCreateFieldCb,
    onUpdateFieldCb,
    isLeadingField,
  });

  useEffect(() => {
    resetForm();
    // Needs to be called only upon mount. if adding "resetForm" to the dep array,
    // it is being called also when "ConfirmDestructiveDialog" is opened, causing the form to be reset
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsPristine(!dirty);
  }, [dirty, setIsPristine]);

  return (
    <>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }} component="div">
        <Typography variant="h1-bold">{isEditMode ? 'Edit' : 'Create new'} Field</Typography>
        <IconButton variant="flat" onClick={handleTryCloseDialog}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <CreateEditFieldForm
          mode={mode}
          crmOrgId={crmOrgId}
          setFieldValue={setFieldValue}
          values={updatedValues}
          errors={isSubmitted ? errors : {}}
          isLeadingField={isLeadingField}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleTryCloseDialog} variant="outlined">
          Cancel
        </Button>
        <Button onClick={() => handleConfirm(mode)}>{isEditMode ? 'Save' : 'Create'}</Button>
      </DialogActions>
    </>
  );
};

export default CreateEditField;
