import { Box, SxProps, Theme } from '@mui/material';
import { colors } from '@sweep-io/sweep-design';
import React from 'react';

export interface ConfigurationCanvasPanelProps {
  children: React.ReactNode | React.ReactNode;
  width?: number;
  sx?: SxProps<Theme>;
  skipSizeAllocation?: boolean;
  isExpandedMode?: boolean;
  id?: string;
}

export const ConfigurationCanvasPanel = ({
  children,
  width,
  sx,
  positionSx,
  isExpandedMode,
  id,
}: ConfigurationCanvasPanelProps & { positionSx?: SxProps<Theme> }) => {
  const _sx = { ...positionSx, ...sx };
  return (
    <Box
      sx={{
        width: isExpandedMode ? '100%' : `${width}px`,
        transition: '1s width',
        margin: '0',
        height: '100%',
        maxHeight: '100vh',
        boxShadow: 1,
        zIndex: 101,
        padding: '20px 0',
        background: colors.white,
        display: 'flex',
        flexDirection: 'column',
        //THIS PANEL CANNOT HAVE OVERFLOW HIDDEN - THEY MIGHT BE ITEMS OPENED WITH POSITION ABSOLUTE IN IT
        //overflow: 'hidden',
        gap: 2,
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        alignContent: 'stretch',
        ..._sx,
      }}
      className="configuration-canvas-panel"
      id={id}
    >
      {children}
    </Box>
  );
};
