import { DateTime } from 'luxon';
import { useState, useCallback } from 'react';
import {
  AiChatWithOrgPayload,
  useCrmOrgsApiFacade,
} from '../../../../apis/facades/useCrmOrgsApiFacade';
import { ChatCompletionRequestMessageRole } from '@server/ai';
import { useFeatureToggle } from '../../../common/useFeatureToggle';
import { AiChatMessage } from '../../../common/ai-chat/aiChatTypes';
import usePaywall from '../../../dashboard/header/usePaywall';
import { telemetry } from '../../../../telemetry';
import { createContextText, useAiChat } from '../../../common/ai-chat/useAiChat';
import { uniqueId } from '../../../../lib/uniqueId';

interface OnConfirmProps {
  message: string;
  crmOrgId: string;
  objectName: string;
  configurationName: string;
  configurationKey: string;
}

interface OnConfirmForFieldsProps {
  message: string;
  crmOrgId: string;
  objectName: string;
  fieldName: string;
}

export const useConfigurationChat = () => {
  const { unifiedAiEndpoint } = useFeatureToggle();
  const { post_crmOrgConfigurationChat, post_crmOrgFieldChat } = useCrmOrgsApiFacade();
  const { onSendMessage } = useAiChat();

  const [sessionId] = useState(uniqueId());
  const [messages, setMessages] = useState<AiChatMessage[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const { isPaywallActive } = usePaywall();
  const disableInput = isPaywallActive && messages.length > 0;

  const onConfirmOrgChat = useCallback(
    async ({
      message,
      crmOrgId,
      objectName,
      configurationName,
      configurationKey,
    }: OnConfirmProps) => {
      error && setError('');

      const payload: Omit<AiChatWithOrgPayload, 'crmOrgId'> = {
        message,
        sessionId,
        context: createContextText({ configurationName, configurationKey, objectName }),
        isFirstMessage: messages.length === 0,
      };

      const createdAt = DateTime.now().toISO() ?? '';
      const _messages: AiChatMessage[] = [
        ...messages,
        { role: ChatCompletionRequestMessageRole.USER, content: message, createdAt },
      ];
      setMessages(_messages);
      setIsLoading(true);

      try {
        const _response = await onSendMessage({
          payload: { ...payload, crmOrgId },
        });

        if (_response.error) {
          setError(_response.error);
          setIsLoading(false);
          return;
        }

        const lastResponse = _response.data.pop();
        if (lastResponse) {
          setMessages([..._messages, lastResponse]);
        }
      } catch (e) {
        setError('Something went wrong, please try again later');
        telemetry.captureError(e);
      }

      setIsLoading(false);
    },
    [error, messages, onSendMessage, sessionId],
  );

  const deprecated_onConfirm = useCallback(
    async ({
      message,
      crmOrgId,
      objectName,
      configurationName,
      configurationKey,
    }: OnConfirmProps) => {
      error && setError('');
      const createdAt = DateTime.now().toISO() ?? '';
      let _messages: AiChatMessage[] = [
        ...messages,
        { role: ChatCompletionRequestMessageRole.USER, content: message, createdAt },
      ];
      setMessages(_messages);
      setIsLoading(true);

      try {
        const _response = await post_crmOrgConfigurationChat({
          crmOrgId,
          payload: { objectName, configurationName, configurationKey, messages: _messages },
        });

        if (_response.error) {
          setError(_response.error);
          setIsLoading(false);
          return;
        }

        const _hasSystemPrompt = _messages[0].role === ChatCompletionRequestMessageRole.SYSTEM;

        if (!_hasSystemPrompt) {
          //BE expects system prompt being sent
          const systemPrompt = _response.data.shift();

          if (systemPrompt && systemPrompt.role === ChatCompletionRequestMessageRole.SYSTEM) {
            _messages = [systemPrompt, ..._messages];
          }
        }

        const lastResponse = _response.data.pop();
        if (lastResponse) {
          setMessages([..._messages, lastResponse]);
        }
      } catch (e) {
        setError('Something went wrong, please try again later');
        telemetry.captureError(e);
      }

      setIsLoading(false);
    },
    [messages, post_crmOrgConfigurationChat, error],
  );

  const onConfirmOrgChatForFields = useCallback(
    async ({ message, crmOrgId, objectName, fieldName }: OnConfirmForFieldsProps) => {
      error && setError('');
      const createdAt = DateTime.now().toISO() ?? '';
      const _messages: AiChatMessage[] = [
        ...messages,
        { role: ChatCompletionRequestMessageRole.USER, content: message, createdAt },
      ];
      setMessages(_messages);
      setIsLoading(true);

      const payload: Omit<AiChatWithOrgPayload, 'crmOrgId'> = {
        message,
        sessionId,
        context: createContextText({ fieldName, objectName }),
        isFirstMessage: messages.length === 0,
      };

      try {
        const _response = await onSendMessage({
          payload: { ...payload, crmOrgId },
        });

        if (_response.error) {
          setError(_response.error);
          setIsLoading(false);
          return;
        }

        const lastResponse = _response.data.pop();
        if (lastResponse) {
          setMessages([..._messages, lastResponse]);
        }
      } catch (e) {
        setError('Something went wrong, please try again later');
        telemetry.captureError(e);
      }

      setIsLoading(false);
    },
    [messages, onSendMessage, sessionId, error],
  );

  const deprecated_onConfirmForFields = useCallback(
    async ({ message, crmOrgId, objectName, fieldName }: OnConfirmForFieldsProps) => {
      error && setError('');
      const createdAt = DateTime.now().toISO() ?? '';
      let _messages: AiChatMessage[] = [
        ...messages,
        { role: ChatCompletionRequestMessageRole.USER, content: message, createdAt },
      ];
      setMessages(_messages);
      setIsLoading(true);

      try {
        const _response = await post_crmOrgFieldChat({
          crmOrgId,
          payload: { objectName, fieldName, messages: _messages },
        });

        if (_response.error) {
          setError(_response.error);
          setIsLoading(false);
          return;
        }

        const _hasSystemPrompt = _messages[0].role === ChatCompletionRequestMessageRole.SYSTEM;

        if (!_hasSystemPrompt) {
          //BE expects system prompt being sent
          const systemPrompt = _response.data.shift();

          if (systemPrompt && systemPrompt.role === ChatCompletionRequestMessageRole.SYSTEM) {
            _messages = [systemPrompt, ..._messages];
          }
        }

        const lastResponse = _response.data.pop();
        if (lastResponse) {
          setMessages([..._messages, lastResponse]);
        }
      } catch (e) {
        setError('Something went wrong, please try again later');
        telemetry.captureError(e);
      }

      setIsLoading(false);
    },
    [messages, post_crmOrgFieldChat, error],
  );

  const onConfirmForFields = useCallback(
    (props: OnConfirmForFieldsProps) => {
      if (unifiedAiEndpoint) {
        onConfirmOrgChatForFields(props);
      } else {
        deprecated_onConfirmForFields(props);
      }
    },
    [deprecated_onConfirmForFields, unifiedAiEndpoint, onConfirmOrgChatForFields],
  );

  const onConfirm = useCallback(
    async (props: OnConfirmProps) => {
      if (unifiedAiEndpoint) {
        return await onConfirmOrgChat(props);
      } else {
        return await deprecated_onConfirm(props);
      }
    },
    [onConfirmOrgChat, unifiedAiEndpoint, deprecated_onConfirm],
  );

  return {
    isLoading,
    messages,
    error,
    onConfirm,
    onConfirmForFields,
    disableInput,
  };
};
