import { useDispatch, useSelector } from 'react-redux';
import {
  selectConnectedCrmOrgs,
  selectCrmOrgsAreReady,
  selectCrmOrgsWasLoaded,
  setOrgsAreReady,
} from './environmentsReducer';
import { selectDefaultCreationCrmOrgId } from '../../../reducers/userInfoReducer';
import { useCallback, useEffect, useRef } from 'react';
import useUserInfo from '../../../hooks/useUserInfo';
import { useLoadCrmOrgs } from '../../../hooks/useLoadCrmOrgs';

export const useSelectDefaultCreationEnvHeuristic = () => {
  useLoadCrmOrgs();
  const dispatch = useDispatch();
  const crmOrgs = useSelector(selectConnectedCrmOrgs);
  const defaultCreationCrmOrgId = useSelector(selectDefaultCreationCrmOrgId);
  const crmOrgsWasLoaded = useSelector(selectCrmOrgsWasLoaded);
  const crmOrgsAreReady = useSelector(selectCrmOrgsAreReady);

  const { updateDefaultCreationCrmOrgId } = useUserInfo();

  const currentUpdatingDefaultCreationCrmOrgId = useRef<string | null>();

  const runDefaultCreationEnvHeuristic = useCallback(async () => {
    const exitingCrmOrgIds: string[] = crmOrgs.map((crmOrg) => crmOrg.id);

    const defaultCreationCrmOrgIdIsValid = defaultCreationCrmOrgId
      ? exitingCrmOrgIds.includes(defaultCreationCrmOrgId)
      : !exitingCrmOrgIds.length;

    if (defaultCreationCrmOrgIdIsValid) {
      dispatch(setOrgsAreReady());
      return;
    }

    let newCrmOrgId: string | null | undefined;

    if (!exitingCrmOrgIds.length && defaultCreationCrmOrgId) {
      // If there are no CRM orgs, and the default creation CRM org ID is set,
      // it means the last org was deleted, so we should set the default creation CRM org ID to null
      newCrmOrgId = null;
    } else {
      if (!defaultCreationCrmOrgId) {
        if (exitingCrmOrgIds.length) {
          // If there are CRM orgs, and the default creation CRM org ID is not set,
          // assign the first CRM org ID to the default creation CRM org ID
          newCrmOrgId = exitingCrmOrgIds[0];
        } else {
          // We should never reach this state, so if we do, it means that the defaultCreationCrmOrgIdIsValid
          // changed and is invalid
          throw new Error('Invalid state. Check defaultCreationCrmOrgIdIsValid logic');
        }
      } else {
        if (!exitingCrmOrgIds.includes(defaultCreationCrmOrgId)) {
          // If there are CRM orgs, and the default creation CRM org ID is set,
          // but the CRM org with the default creation CRM org ID is deleted,
          // assign the first CRM org ID to the default creation CRM org ID
          newCrmOrgId = exitingCrmOrgIds[0];
        } else {
          // We should never reach this state, so if we do, it means that the defaultCreationCrmOrgIdIsValid
          // changed and is invalid
          throw new Error('Invalid state. Check defaultCreationCrmOrgIdIsValid logic');
        }
      }
    }

    if (currentUpdatingDefaultCreationCrmOrgId.current === newCrmOrgId) {
      // If the new default creation CRM org ID is the same as the current one,
      // It means that it is being currently updated, so we should not update it again
      return;
    } else {
      currentUpdatingDefaultCreationCrmOrgId.current = newCrmOrgId;
    }

    await updateDefaultCreationCrmOrgId(newCrmOrgId);
    dispatch(setOrgsAreReady());
  }, [crmOrgs, defaultCreationCrmOrgId, dispatch, updateDefaultCreationCrmOrgId]);

  useEffect(() => {
    // Run this only if crmOrgsWasLoaded is true
    if (crmOrgsWasLoaded) {
      runDefaultCreationEnvHeuristic();
    }
  }, [crmOrgsWasLoaded, dispatch, runDefaultCreationEnvHeuristic]);

  return {
    crmOrgsAreReady,
  };
};
