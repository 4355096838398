import { TableRow as MuiTableRow, TableCell as MuiTableCell } from '@mui/material';
import { Button, Typography, colors } from '@sweep-io/sweep-design';

export const ReachedLastRow = ({
  columnsCount,
  reachedLastRowTxt,
  scrollToTop,
}: {
  reachedLastRowTxt: string;
  columnsCount: number;
  scrollToTop: () => void;
}) => (
  <MuiTableRow sx={{ maxHeight: '44px', height: '44px' }}>
    <MuiTableCell
      colSpan={columnsCount} //needed to cover all the columns
      sx={{
        position: 'absolute',
        bottom: 0,
        textAlign: 'center',
        background: colors.grey[100],
        width: '100%',
        height: '44px',
        maxHeight: '44px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '4px',
      }}
    >
      <Typography variant="body-bold" color={colors.night[900]}>
        {reachedLastRowTxt}
      </Typography>
      <Button variant="link" onClick={scrollToTop}>
        Back to the top
      </Button>
    </MuiTableCell>
  </MuiTableRow>
);
