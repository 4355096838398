import { TabList } from '@sweep-io/sweep-design';
import { useCallback, useContext, useEffect, useState } from 'react';
import { HubspotCampaignForm } from './HubspotCampaignForm';
import { Campaign, CampaignBase } from '../../../../../apis/facades/useCampaignsApiFacade';
import { selectCampaignById } from '../../../../../reducers/hubspotReducer';
import { useSelector } from 'react-redux';
import { EnrollmentRulesTab } from './EnrollmentRulesTab';
import { useCampaigns } from './useCampaigns';
import { isEqual } from 'lodash';
import { HubspotCampaignCtx } from './HubspotCampaignCtx';

interface HubspotCampaignContentProps {
  initialBaseCampaignId?: string;
}

const emptyCampaign: CampaignBase = {
  Name: '',
};

export const HubspotCampaignContent = ({ initialBaseCampaignId }: HubspotCampaignContentProps) => {
  const [baseCampaignId, setBaseCampaignId] = useState(initialBaseCampaignId);

  const baseCampaign = useSelector(selectCampaignById(baseCampaignId || ''));

  const { createCampaign } = useCampaigns();
  const [tab, setTab] = useState<'settings' | 'rules'>('settings');

  const [editingCampaign, setEditingCampaign] = useState<
    Campaign | (CampaignBase & { Id?: string })
  >(baseCampaign || emptyCampaign);

  useEffect(() => {
    // If base campaign changes, reset editing campaign
    // This happens when a new campaign is created
    setEditingCampaign(baseCampaign ?? emptyCampaign);
  }, [baseCampaign]);

  const [isSaving, setIsSaving] = useState(false);

  const { setIsPristine } = useContext(HubspotCampaignCtx);
  const isPristine = isEqual(editingCampaign, baseCampaign);

  useEffect(() => {
    setIsPristine(isPristine);
  }, [isPristine, setIsPristine]);

  const handleCampaignChange = useCallback((partialCampaign: Partial<CampaignBase>) => {
    setEditingCampaign((workflow) => ({ ...workflow, ...partialCampaign }));
  }, []);

  const handleCampaignDeployCreate = useCallback(async () => {
    setIsSaving(true);
    const savedCampaign = await createCampaign(editingCampaign);
    setBaseCampaignId(savedCampaign.Id);
    setIsSaving(false);
    setTab('rules');
  }, [editingCampaign, createCampaign]);

  const handleCampaignDeployUpdate = useCallback(async () => {
    setIsSaving(true);
    // await updatedCampaign(editingCampaign); // API not yet implemented
    alert('Update not yet implemented');
    setIsSaving(false);
  }, []);

  const isUpdateMode = Boolean(baseCampaign?.Id);

  return (
    <TabList
      list={[
        {
          content: (
            <HubspotCampaignForm
              campaign={editingCampaign}
              onChange={handleCampaignChange}
              onDeploy={isUpdateMode ? handleCampaignDeployUpdate : handleCampaignDeployCreate}
              isSaving={isSaving}
            />
          ),
          label: 'Campaign Settings',
          value: 'settings',
        },
        {
          content: (
            <EnrollmentRulesTab
              campaignId={baseCampaign?.Id}
              campaignName={baseCampaign?.Name || ''}
            />
          ),
          label: 'Enrollment Rules',
          value: 'rules',
        },
      ]}
      value={tab}
      onChange={(e, tab) => {
        setTab(tab);
      }}
    />
  );
};
