import { Typography, Radio } from '@sweep-io/sweep-design';
import { Box, Collapse, FormControl, TextField } from '@mui/material';
import { StyledRadioGroup } from '../../common/StyledComponents';
import { useCallback, useEffect, useState } from 'react';
import { SFDCObjectType } from '../../../types/enums/SFDCObjectType';
import { ChevronDown, ChevronUp, Timer } from '@sweep-io/sweep-design/dist/icons';
import { DedupMatchingType, SweepFilterEndpoint } from '../../../types/enums/DedupMatchingType';
import {
  DedupMergeActionType,
  StyledFormControlLabel,
  getFiltersByType,
  getFiltersByTypeToSet,
  getFollowUpAction,
  getMergeRecordWebhookAction,
  getNotifyAction,
  settingsPanelLayout,
} from './helpers';
import { uniqueId } from '../../../lib/uniqueId';
import { AutomationActionType } from '../../../types/enums/AutomationActionType';
import {
  CloudingoConnectionStatus,
  selectCloudingoIntegrationStatus,
} from '../../../reducers/integrationsReducer';
import { useSelector } from 'react-redux';
import { useRunOnce } from '../../common/useRunOnce';
import { useIntegrations } from '../../../hooks/useIntegrations';
import useObjectTypesWithFetch from '../../../hooks/useObjectTypesWithFetch';
import { ConvertLeadConvertedStatus } from '../../Automations/ConvertLeadConvertedStatus';
import SettingsFilterPanel from './SettingsFilterPanel';
import SettingsTieBreakersPanel from './SettingsTieBreakersPanel';
import SettingsTriggerUpdatePanel from './SettingsTriggerUpdatePanel';
import SettingsCloudingoPanel from './SettingsCloudingoPanel';
import { MatchingNotification } from './MatchingNotification';
import { useFeatureToggle } from '../../common/useFeatureToggle';
import MergeRecordFieldsFilterPanel from './MergeRecordFieldsFilterPanel';
import SetMasterRecordPanel from './SetMasterRecordPanel';
import MatchingLogicFilterPanel from './MatchingLogicFilterPanel';

interface DuplicateSameObjectProps {
  readonly?: boolean;
  objectName: SFDCObjectType;
  targetObjectName?: SFDCObjectType;
  dedupObject: DeepPartial<AutomationStructureNew>;
  crmOrgId: string;
  onChange: (automationJson: (DeepPartial<AutomationAction> | undefined)[]) => any;
  onChangeDelay: (_delay?: DelayedActions) => any;
  onChangeTrigger: (type: AutomationTypes) => any;
}

export const DuplicateSameObject = ({
  readonly,
  objectName,
  targetObjectName,
  dedupObject,
  crmOrgId,
  onChange,
  onChangeDelay,
  onChangeTrigger,
}: DuplicateSameObjectProps) => {
  const { dedupeForHiBob } = useFeatureToggle();

  const [actionsArray, setActionsArray] = useState<(DeepPartial<AutomationAction> | undefined)[]>(
    dedupObject.automationDetails?.actions || [],
  );
  const integrations = useIntegrations();
  const cloudingoStatus = useSelector(selectCloudingoIntegrationStatus);
  useRunOnce(async () => {
    await integrations.getCloudingoStatus();
  });
  const [isDelayPanelExpanded, setDelayPanelExpanded] = useState(
    !!dedupObject.automationDetails?.delay,
  );
  const { objectTypesByName } = useObjectTypesWithFetch({ crmOrgId });
  const lowerCaseObjectLabel =
    objectTypesByName[objectName]?.label?.toLowerCase() ?? objectName.toLowerCase();
  const lowerCaseTargetObjectLabel =
    objectTypesByName[targetObjectName ?? '']?.label?.toLowerCase() ??
    targetObjectName?.toLowerCase();

  useEffect(() => {
    dedupObject && setActionsArray(dedupObject?.automationDetails?.actions || []);
  }, [dedupObject]);

  const getDedupAction = useCallback(() => {
    return actionsArray[0] as DeduplicationAutomationAction;
  }, [actionsArray]);

  const handleDedupeAction = useCallback(
    (toUpdate: Partial<DeduplicationActionParams>) => {
      const tempActionMatch = { ...getDedupAction() };
      const newObj: DeduplicationAutomationAction = {
        ...tempActionMatch,
        actionParams: {
          ...tempActionMatch.actionParams,
          ...toUpdate,
        },
      };
      const newArr = actionsArray.map((el) => (el?.actionType === 'DEDUP' ? newObj : el));
      setActionsArray(newArr);
      onChange(newArr);
    },
    [actionsArray, getDedupAction, onChange],
  );

  const setFiltersByType = (filter: SweepFilter | undefined, endpoint: SweepFilterEndpoint) => {
    const ret = getFiltersByTypeToSet(dedupObject, filter, endpoint);
    handleDedupeAction({ filters: ret });
  };

  const handleUpdateMultipleFollowUpAction = (actionToUpdate: AutomationAction[]) => {
    if (actionToUpdate && actionToUpdate.length > 0) {
      const tempAction: AutomationConditionalActions = {
        _id: getDedupAction()?.actionParams?.followUpActions?.[0]?._id ?? uniqueId(),
        _name: 'follow up actions',
        actions: actionToUpdate,
      };
      handleDedupeAction({ followUpActions: [tempAction] });
    } else {
      handleDedupeAction({ followUpActions: undefined });
    }
  };

  const handleNotifyAction = (
    automationAction: EmailAutomationAction | SlackAutomationAction | null,
  ) => {
    const otherActions = getFollowUpAction(dedupObject).filter(
      (action) =>
        action.actionType !== AutomationActionType.SendEmail &&
        action.actionType !== AutomationActionType.Slack,
    );
    if (automationAction) {
      handleUpdateMultipleFollowUpAction([...otherActions, automationAction]);
    } else {
      handleUpdateMultipleFollowUpAction(otherActions);
    }
  };

  const handleMergeRecordsWebhookAction = (data?: DeduplicationMergeRecordsWebhookAction) => {
    const otherActions = getFollowUpAction(dedupObject).filter(
      (action) => action.actionType !== AutomationActionType.mergeRecordsWebhook,
    );
    if (data) {
      const tempActionMatch = { ...getDedupAction() };
      const tempAction: AutomationConditionalActions = {
        _id: getDedupAction()?.actionParams?.followUpActions?.[0]?._id ?? uniqueId(),
        _name: 'follow up actions',
        actions: [...otherActions, data],
      };
      const newObj: DeduplicationAutomationAction = {
        ...tempActionMatch,
        actionParams: {
          ...tempActionMatch.actionParams,
          followUpActions: [tempAction],
          mergeActionType: DedupMergeActionType.OVERRIDE_NOTHING,
        },
      };
      const newArr = actionsArray.map((el) => (el?.actionType === 'DEDUP' ? newObj : el));
      setActionsArray(newArr);
      onChange(newArr);
    } else {
      handleUpdateMultipleFollowUpAction(otherActions);
    }
  };

  const isLeadToContactDedup =
    getDedupAction().actionParams?.dmType === DedupMatchingType.LEAD_TO_CONTACT_DEDUP;

  const isNotMergeManually =
    getDedupAction()?.actionParams.mergeActionType !== DedupMergeActionType.OVERRIDE_NOTHING;

  return (
    <>
      {actionsArray.length && (
        <>
          <Box>
            <Box>
              <Typography variant="h4">When a duplicate is found</Typography>
              <StyledRadioGroup
                className="alignTop"
                sx={{ marginTop: '12px' }}
                value={getDedupAction()?.actionParams.mergeActionType ?? ''}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const _val = (event.target as HTMLInputElement).value;
                  const _masterRecordFilter =
                    _val === DedupMergeActionType.OVERRIDE_NOTHING
                      ? undefined
                      : getDedupAction()?.actionParams.masterRecordFilter;
                  const _mergeFields =
                    _val === DedupMergeActionType.OVERRIDE_NOTHING
                      ? undefined
                      : getDedupAction()?.actionParams.mergeFields;
                  handleDedupeAction({
                    mergeActionType: _val,
                    convertedStatus: undefined,
                    masterRecordFilter: _masterRecordFilter,
                    mergeFields: _mergeFields,
                  });
                }}
              >
                <StyledFormControlLabel
                  disabled={readonly}
                  value={DedupMergeActionType.OVERRIDE_NOTHING}
                  control={<Radio />}
                  label={
                    <>
                      <Typography variant="body">{`Match ${lowerCaseObjectLabel}s and merge manually`}</Typography>
                      <br />
                      <Typography variant="caption">
                        {`To view all ${lowerCaseObjectLabel}s with duplicates and merge manually, see 'Duplicate ${lowerCaseObjectLabel}s'
                      List View in Salesforce.`}
                      </Typography>
                    </>
                  }
                />
                {!isLeadToContactDedup && (
                  <StyledFormControlLabel
                    disabled={readonly}
                    value={DedupMergeActionType.OVERRIDE_NEW_VALUES}
                    control={<Radio />}
                    label={
                      <>
                        <Typography variant="body">{`Enrich existing ${lowerCaseObjectLabel} and merge`}</Typography>
                        <br />
                        <Typography variant="caption">
                          {`Replace empty fields with values from the new ${lowerCaseObjectLabel}.`}
                        </Typography>
                      </>
                    }
                  />
                )}
                {!isLeadToContactDedup && (
                  <StyledFormControlLabel
                    disabled={readonly}
                    value={DedupMergeActionType.OVERRIDE_ALL}
                    control={<Radio />}
                    label={
                      <>
                        <Typography variant="body">{`Override existing ${lowerCaseObjectLabel} and merge`}</Typography>
                        <br />
                        <Typography variant="caption">
                          Replace fields in the existing record with non-empty values from the new
                          record.
                        </Typography>
                      </>
                    }
                  />
                )}
                {isLeadToContactDedup && (
                  <StyledFormControlLabel
                    disabled={readonly}
                    value={DedupMergeActionType.OVERRIDE_BY_CONVERT}
                    control={<Radio />}
                    label={
                      <>
                        <Typography variant="body">{`Convert ${lowerCaseObjectLabel} into the duplicate contact`}</Typography>
                        <br />
                        <Typography variant="caption">
                          Automatically merge lead and add information into empty contact field
                        </Typography>
                      </>
                    }
                  />
                )}
                {getDedupAction()?.actionParams.mergeActionType ===
                  DedupMergeActionType.OVERRIDE_BY_CONVERT && (
                  <Box
                    sx={{
                      paddingLeft: '30px',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                  >
                    <ConvertLeadConvertedStatus
                      readonly={readonly}
                      value={getDedupAction()?.actionParams.convertedStatus ?? ''}
                      crmOrgId={crmOrgId}
                      onChange={(val) => {
                        handleDedupeAction({ convertedStatus: val });
                      }}
                    />
                  </Box>
                )}
              </StyledRadioGroup>
            </Box>

            <MatchingNotification
              readonly={readonly}
              objectName={objectName}
              crmOrgId={crmOrgId}
              action={getNotifyAction(dedupObject)}
              onChange={handleNotifyAction}
            ></MatchingNotification>

            <Box sx={{ marginTop: '40px' }}>
              <Typography variant="h4">Settings</Typography>
            </Box>

            <Box>
              <SettingsFilterPanel
                readonly={readonly}
                title={`Triggering ${lowerCaseObjectLabel}s filters`}
                filters={getFiltersByType(dedupObject, SweepFilterEndpoint.SOURCE)}
                type={getDedupAction().actionParams?.dmType ?? DedupMatchingType.LEAD_TO_LEAD_DEDUP}
                crmOrgId={crmOrgId ?? ''}
                objectType={objectName}
                onChange={(filter) => setFiltersByType(filter, SweepFilterEndpoint.SOURCE)}
              />
            </Box>
            <Box>
              <SettingsFilterPanel
                readonly={readonly}
                subType={1}
                title={`Existing ${lowerCaseTargetObjectLabel || lowerCaseObjectLabel}s filters`}
                filters={getFiltersByType(dedupObject, SweepFilterEndpoint.DESTINATION)}
                type={getDedupAction().actionParams?.dmType ?? DedupMatchingType.LEAD_TO_LEAD_DEDUP}
                crmOrgId={crmOrgId ?? ''}
                objectType={isLeadToContactDedup ? SFDCObjectType.Contact : objectName}
                onChange={(filter) => setFiltersByType(filter, SweepFilterEndpoint.DESTINATION)}
              />
            </Box>

            {dedupeForHiBob && (
              <Box>
                <MatchingLogicFilterPanel
                  matchingLogic={getDedupAction()?.actionParams.matchingLogic}
                  readonly={readonly}
                  crmOrgId={crmOrgId ?? ''}
                  objectType={objectName}
                  referenceObjectType={isLeadToContactDedup ? SFDCObjectType.Contact : objectName}
                  onChange={(filter) => {
                    handleDedupeAction({ matchingLogic: filter });
                  }}
                />
              </Box>
            )}
            {dedupeForHiBob && isNotMergeManually && (
              <Box>
                <MergeRecordFieldsFilterPanel
                  mergeRecordsFields={getDedupAction()?.actionParams.mergeFields ?? []}
                  readonly={readonly}
                  crmOrgId={crmOrgId ?? ''}
                  objectType={objectName}
                  onChange={(fields) => {
                    handleDedupeAction({ mergeFields: fields });
                  }}
                />
              </Box>
            )}
            {dedupeForHiBob && isNotMergeManually && (
              <Box>
                <SetMasterRecordPanel
                  setMasterRecord={getDedupAction()?.actionParams.masterRecordFilter}
                  readonly={readonly}
                  crmOrgId={crmOrgId ?? ''}
                  objectType={objectName}
                  referenceObjectType={isLeadToContactDedup ? SFDCObjectType.Contact : objectName}
                  onChange={(condition) => {
                    handleDedupeAction({ masterRecordFilter: condition });
                  }}
                />
              </Box>
            )}

            <SettingsTriggerUpdatePanel
              automationDetails={dedupObject.automationDetails}
              objectLabel={lowerCaseObjectLabel}
              onChange={(type: AutomationTypes) => {
                onChangeTrigger(type);
              }}
            />

            <Box>
              <SettingsFilterPanel
                readonly={readonly}
                subType={2}
                title={`Match filters`}
                filters={getFiltersByType(dedupObject, SweepFilterEndpoint.MATCH)}
                type={getDedupAction().actionParams?.dmType ?? DedupMatchingType.LEAD_TO_LEAD_DEDUP}
                crmOrgId={crmOrgId ?? ''}
                objectType={objectName}
                referenceObjectType={isLeadToContactDedup ? SFDCObjectType.Contact : objectName}
                onChange={(filter) => setFiltersByType(filter, SweepFilterEndpoint.MATCH)}
              />
            </Box>
            <Box>
              <SettingsTieBreakersPanel
                readonly={readonly}
                action={getDedupAction().actionParams}
                type={getDedupAction().actionParams?.dmType ?? DedupMatchingType.LEAD_TO_LEAD_DEDUP}
                crmOrgId={crmOrgId ?? ''}
                objectType={objectName}
                onChange={(_tieBreakers: any) => {
                  handleDedupeAction({ tieBreakers: _tieBreakers });
                }}
              />
            </Box>
            <Box>
              <Box sx={{ ...settingsPanelLayout }}>
                <Collapse in={isDelayPanelExpanded} collapsedSize={24}>
                  <Box
                    className="panelHeader"
                    onClick={() => setDelayPanelExpanded(!isDelayPanelExpanded)}
                  >
                    <Box sx={{ display: 'flex', gap: '16px' }}>
                      <Timer variant="large" />
                      <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '2px' }}>
                        <Typography variant="body-bold">Wait for enrichment</Typography>
                        <Typography variant="body">
                          {`Make sure enrichment takes place before ${lowerCaseObjectLabel}s are being evaluated for
                          duplicates`}
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      {isDelayPanelExpanded ? (
                        <ChevronUp variant="large" />
                      ) : (
                        <ChevronDown variant="large" />
                      )}
                    </Box>
                  </Box>
                  <Box sx={{ marginTop: '16px', marginLeft: '44px' }}>
                    <StyledRadioGroup
                      sx={{ marginTop: '12px' }}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        const val = (event.target as HTMLInputElement).value;
                        if (val) {
                          const tempDelay: DelayedActions = {
                            offsetType: val,
                          };
                          onChangeDelay(tempDelay);
                        } else {
                          onChangeDelay(undefined);
                        }
                      }}
                      value={dedupObject.automationDetails?.delay?.offsetType ?? ''}
                    >
                      <StyledFormControlLabel
                        disabled={readonly}
                        value=""
                        control={<Radio />}
                        label={
                          <>
                            <Typography variant="body">Don't wait</Typography>
                          </>
                        }
                      />

                      <StyledFormControlLabel
                        disabled={readonly}
                        value="Minutes"
                        control={<Radio />}
                        label={
                          <>
                            <Typography variant="body">Wait for</Typography>
                            <FormControl sx={{ m: '0 12px', verticalAlign: 'baseline' }}>
                              <TextField
                                disabled={readonly}
                                error={
                                  dedupObject.automationDetails?.delay &&
                                  !dedupObject.automationDetails?.delay.offsetValue
                                }
                                type={'number'}
                                value={dedupObject.automationDetails?.delay?.offsetValue ?? ''}
                                onChange={(event: { target: { value: any } }) => {
                                  const val = event.target.value;
                                  if (val) {
                                    const tempDelay: DelayedActions = {
                                      ...dedupObject.automationDetails?.delay,
                                      offsetValue: val,
                                    };
                                    onChangeDelay(tempDelay);
                                  } else {
                                    onChangeDelay(undefined);
                                  }
                                }}
                                InputProps={{
                                  inputProps: { min: 1 },
                                  sx: {
                                    width: '85px',
                                  },
                                }}
                                placeholder={'Value'}
                              />
                            </FormControl>
                            <Typography variant="body">Minutes</Typography>
                          </>
                        }
                      />
                    </StyledRadioGroup>
                  </Box>
                </Collapse>
              </Box>
            </Box>

            {cloudingoStatus === CloudingoConnectionStatus.ENABLED && (
              <SettingsCloudingoPanel
                action={getMergeRecordWebhookAction(dedupObject)}
                onChange={(data?: DeduplicationMergeRecordsWebhookAction) => {
                  handleMergeRecordsWebhookAction(data);
                }}
              />
            )}
          </Box>
        </>
      )}
    </>
  );
};
