import { useImportFunnel } from './useImportFunnel';
import { ImportFunnelView } from './main-view/ImportFunnelView';
import { PreviewWrapper } from './preview/PreviewWrapper';
import { PreviewHeader } from './preview/PreviewHeader';
import { DialogLoader } from '../../../../common/dialogs/DialogLoader';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { clearObjects } from '../../../../../reducers/importFunnelReducer';
import { TEMPLATES_DIALOG_WIDTH } from '../../templates/dialog/consts';
import {
  ConfigurationCanvasLeftPanel,
  ConfigurationCanvasPanelContent,
  ConfigurationCanvasPanelHeader,
} from '../../../configuration-canvas-panel';
import { WithEnvConnectionErrorPanel } from '../../../canvas-pages/united-canvas/canvas-page/error-panel/EnvConnectionErrorPanel';

export const ImportFunnelWrapper = ({
  onClose,
  crmOrgId,
}: {
  onClose: () => void;
  crmOrgId: string;
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearObjects()); //clear objects every time component is unmounted
    };
  }, [dispatch]);

  const {
    isDialogLoading,
    listDataStructure,
    selectedRecordType,
    selectedLeadingField,
    isRecordTypeLoading,
    selectedItemFunnelStructure,
    onSelectLeadingField,
    onAddItemToMapClick,
    isMapItemLoading,
    backToMainView,
    currentCrmOrg,
  } = useImportFunnel({ onDialogClose: onClose, crmOrgId });

  const displayPreviewView =
    isRecordTypeLoading || selectedRecordType || selectedItemFunnelStructure;

  return (
    <ConfigurationCanvasLeftPanel width={TEMPLATES_DIALOG_WIDTH}>
      <WithEnvConnectionErrorPanel>
        {!displayPreviewView && (
          <ConfigurationCanvasPanelHeader onClose={onClose}>
            Import Funnel to Canvas
          </ConfigurationCanvasPanelHeader>
        )}

        {displayPreviewView && (
          <ConfigurationCanvasPanelHeader>
            <PreviewHeader
              funnelStructure={selectedItemFunnelStructure}
              selectedRecordType={selectedRecordType}
              isLoadingButton={isMapItemLoading}
              onAddItemToMapClick={onAddItemToMapClick}
              backToMainView={backToMainView}
              isError={!!selectedRecordType && !selectedRecordType.leadingFields?.length}
              crmOrgId={currentCrmOrg?.id ?? ''}
            />
          </ConfigurationCanvasPanelHeader>
        )}
        <ConfigurationCanvasPanelContent sx={{ overflow: 'hidden' }}>
          <DialogLoader visible={isMapItemLoading} />
          {!displayPreviewView && (
            <ImportFunnelView
              listDataStructure={listDataStructure}
              isLoading={isDialogLoading}
              currentCrmOrg={currentCrmOrg}
            />
          )}

          {displayPreviewView && (
            <PreviewWrapper
              selectedRecordType={selectedRecordType}
              funnelStructure={selectedItemFunnelStructure}
              selectedLeadingField={selectedLeadingField}
              onSelectLeadingField={onSelectLeadingField}
              isPanelLoading={isRecordTypeLoading}
            />
          )}
        </ConfigurationCanvasPanelContent>
      </WithEnvConnectionErrorPanel>
    </ConfigurationCanvasLeftPanel>
  );
};
