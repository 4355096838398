import { Box } from '@mui/material';
import { CenteredCircularProgress } from '../common/CenteredCircularProgress';
import { AutomationDialogContent } from '../Automations/AutomationDialogContent';
import { AutomationFormVariant } from '../../types/enums/AutomationFormVariant';
import { useSelector } from 'react-redux';
import { selectDefaultCreationEnvironment } from '../pages/environments/environmentsReducer';
import useAutomationApiPerType from '../Automations/useAutomationApiPerType';
import { ConfigurationCanvasFilters } from '../pages/configuration-canvas/ConfigurationCanvasFilters';
import { useMemo } from 'react';
import { AutomationCanvasFilter } from '../pages/canvas-pages/canvasAutomationsTypes';
import {
  CanvasFilterTypes,
  SweepCanvasFiltersMap,
} from '../pages/configuration-canvas-filters/filterTypes';
import { selectScheduledAssignments } from '../../reducers/global/scheduledAssignmentReducers';
import { scheduledAssignmentsSortOptions } from '../pages/canvas-pages/consts';
import {
  AutomationsContextProvider,
  AutomationsContextType,
  useAutomationsContext,
} from '../Automations/AutomationsContext';
import DrawerClockImage from '../common/empty-state/svgs/drawerClock.svg?react';
import { AutomationType } from '../../types/enums/AutomationType';
import { UI_EVENTS } from '../../services/events';
import { useFeatureToggle } from '../common/useFeatureToggle';

export const scheduledAssignmentRuleAutomationContextData: AutomationsContextType = {
  automationType: AutomationType.ScheduledAssignment,
  permissions: {
    create: 'create:assignments',
    edit: 'edit:assignments',
    deploy: 'deploy:assignments',
    delete: 'delete:assignments',
  },
  emptyStateTitle: 'Create your first Scheduled assignment',
  emptyStateImage: <DrawerClockImage />,
  defaultName: 'New Scheduled Assignment',
  placeholder: 'New Scheduled Assignment',
  nameInMessage: 'scheduled assignments',
  nameInMessageSingular: 'scheduled assignment',
  openEvent: UI_EVENTS.scheduledAssignmentsOpen,
  automationVariant: AutomationFormVariant.SCHEDULED_ASSIGNMENTS,
  templateSupport: false,
  emptyStateSubTitle: `to manage re-engagement and outbound campaigns, redistribute leads after an employee leaves the company and more`,
};
interface ScheduledAssignmentsProps {
  recordTypesData: RecordTypesData;
  funnelsData: FunnelsData;
  externalFilter?: (assignment: AutomationStructureNew) => boolean;
  disableCanvasTemplates?: boolean;
  selectedFilters: AutomationDialogFilters;
  setSelectedFilters: (filters: Partial<AutomationDialogFilters>) => void;
  setHoveredItem?: (automationId?: string | undefined) => void;
}

const useGetScheduledAssignmentFilters = () => {
  const { permissions } = useAutomationsContext();
  const { requestToDeploy } = useFeatureToggle();
  const filters = useMemo(() => {
    const assignmentRuleFilters: SweepCanvasFiltersMap = {
      [AutomationCanvasFilter.OBJECTS]: { type: CanvasFilterTypes.OBJECT, position: 0 },
    };
    if (requestToDeploy && permissions?.deploy) {
      assignmentRuleFilters[AutomationCanvasFilter.DEPLOYMENT_REQUEST] = {
        type: CanvasFilterTypes.TOGGLE_FILTER,
        position: 4,
        label: 'Show only deployment requests',
        checked: false,
      };
    }
    return assignmentRuleFilters;
  }, [permissions?.deploy, requestToDeploy]);
  return filters;
};

const ScheduledAssignments = ({
  recordTypesData,
  funnelsData,
  externalFilter,
  disableCanvasTemplates,
  selectedFilters,
  setSelectedFilters,
  setHoveredItem,
}: ScheduledAssignmentsProps) => {
  const scheduledAssignments = useSelector(selectScheduledAssignments);
  const crmOrg = useSelector(selectDefaultCreationEnvironment);
  const { onSaveOrCreate, onToggleActivation, onDeleteAutomation, onDeployAutomation } =
    useAutomationApiPerType({ automationVariant: AutomationFormVariant.SCHEDULED_ASSIGNMENTS });

  const assignmentsToDisplay = externalFilter
    ? scheduledAssignments?.filter(externalFilter)
    : scheduledAssignments;

  const filters = useGetScheduledAssignmentFilters();

  if (!scheduledAssignments) return <CenteredCircularProgress />;

  return (
    <>
      <Box pt={2} pb={2} flex={1}>
        {crmOrg && (
          <AutomationDialogContent
            automations={assignmentsToDisplay ?? []}
            onDeleteAutomation={onDeleteAutomation}
            crmOrgId={crmOrg.id}
            onSaveOrCreate={onSaveOrCreate}
            recordTypesData={recordTypesData}
            funnelsData={funnelsData}
            onToggleActivation={onToggleActivation}
            sortOptions={scheduledAssignmentsSortOptions}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            disableCanvasTemplates={disableCanvasTemplates}
            setHoveredItem={setHoveredItem}
            onDeployAutomation={onDeployAutomation}
          />
        )}
      </Box>
      <ConfigurationCanvasFilters filters={filters} automations={scheduledAssignments} />
    </>
  );
};

const ScheduledAssignmentsWithContext = (scheduledAssignmentProps: ScheduledAssignmentsProps) => {
  return (
    <AutomationsContextProvider value={scheduledAssignmentRuleAutomationContextData}>
      <ScheduledAssignments {...scheduledAssignmentProps} />
    </AutomationsContextProvider>
  );
};

export default ScheduledAssignmentsWithContext;
