import usePaywall from './usePaywall';
import { useEffect, useRef } from 'react';
import useConfirm from '../../common/dialogs/ConfirmLeaveWithoutSave/useConfirm';
import TrialCarousel from '../paywall/TrialCarousel';

//This hook makes the Trial Into Carousel appear when a trial starts
const usePaywallTrialCarousel = () => {
  const { isPaywallActive } = usePaywall();
  const isPaywallActiveInitial = useRef(isPaywallActive);
  const { openConfirm, onCancel } = useConfirm();

  useEffect(() => {
    if (isPaywallActiveInitial.current && !isPaywallActive) {
      openConfirm(<TrialCarousel closeDialog={onCancel} disableOpenAnimation={false} />);
      isPaywallActiveInitial.current = false;
    }
  }, [openConfirm, isPaywallActive, onCancel]);
};

export default usePaywallTrialCarousel;
