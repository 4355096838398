import { WalkthroughPointType } from '@server/walkthrough';
import useWalkthroughPoint from './useWalkthroughPoint';
import WalkthroughPointAnimation, { LocationProps } from './WalkthroughPointAnimation';

type WalkthroughPointProps = LocationProps & { point: WalkthroughPointType };

const WalkthroughPoint = ({ point, ...locationProps }: WalkthroughPointProps) => {
  const { showWalkthroughPoint } = useWalkthroughPoint({
    point,
  });
  return showWalkthroughPoint ? <WalkthroughPointAnimation {...locationProps} /> : null;
};

export default WalkthroughPoint;
