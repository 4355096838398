import { IconButton } from '@sweep-io/sweep-design';
import * as Icons from '@sweep-io/sweep-design/dist/icons';
import RestrictedTooltip from '../../../../common/permissions/RestrictedTooltip';
import { useTurnOffCommentsModeFirst } from '../../../canvas-pages/united-canvas/left-menu/useTurnOffCommentsModeFirst';
import { usePanels } from '../../../../panels/PanelsContext';
import { templatesPanelType } from '../dialog/panelTypes';
import { Box } from '@mui/material';
import WalkthroughPoint from '../../../../common/walkthrough-points/WalkthroughPoint';
import { WalkthroughPointType } from '@server/walkthrough';
import useWalkthroughPoint from '../../../../common/walkthrough-points/useWalkthroughPoint';

interface LeftMenuTemplatesProps {
  crmOrgId: string;
  readonly?: boolean;
}

export const UnitedLeftMenuTemplates = ({ readonly }: LeftMenuTemplatesProps) => {
  const { isPanelOpen } = usePanels();
  const isTemplatesOpen = isPanelOpen(templatesPanelType);

  const turnOffCommentsModeFirst = useTurnOffCommentsModeFirst();

  const { maybeOpenPanel } = usePanels();
  const { markWalkthroughCompleted } = useWalkthroughPoint({
    point: WalkthroughPointType.funnelsTemplates,
  });

  if (readonly) {
    return null;
  }

  return (
    <Box position="relative">
      <WalkthroughPoint point={WalkthroughPointType.funnelsTemplates} right={8} bottom={5} />
      <RestrictedTooltip
        to={['create:funnels', 'edit:funnels']}
        notAllowedTitle={'To add new funnels, please contact your admin.'}
        allowedTitle="Templates"
        component={(isAllowed) => (
          <>
            {isAllowed && (
              <WalkthroughPoint
                point={WalkthroughPointType.funnelsTemplates}
                right={8}
                bottom={5}
              />
            )}
            <IconButton
              variant="flat"
              onClick={() =>
                turnOffCommentsModeFirst(() => {
                  markWalkthroughCompleted();
                  maybeOpenPanel({ panelType: templatesPanelType });
                })
              }
              disabled={!isAllowed}
              pressed={isTemplatesOpen}
            >
              <Icons.Layout variant="large" />
            </IconButton>
          </>
        )}
        placement="bottom"
      />
    </Box>
  );
};
