import { Box } from '@mui/material';
import { colors, Typography } from '@sweep-io/sweep-design';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { DIMENSION_AVATAR_VERY_SMALL } from '../../constants';
import { selectUserById } from '../../reducers/accountUsersReducer';
import { selectUserInfoData } from '../../reducers/userInfoReducer';
import { CommentReplyProps, MentionedUser } from '../../types/Comment';
import { AvatarPicture } from '../avatar/AvatarPicture';
import { humanizedTimeAgo } from './helper';
import NewCommentReply from './NewCommentReply';
import ReplyMenu from './ReplyMenu';
import { TextWithMentions } from './TextWithMentions';
import { INACTIVE_USER_TXT_COLOR } from '../../constants/user';
import { getIsUserInactive } from '../../lib/getIsUserActive';

const CommentReply = ({
  reply,
  isMenuOpen,
  openMenu,
  closeMenu,
  deleteReplyCb,
  editReplyCb,
  deleteText,
}: {
  reply: CommentReplyProps;
  isMenuOpen: boolean;
  openMenu: () => void;
  closeMenu: () => void;
  deleteReplyCb: () => void;
  editReplyCb: (commentBody: string, mentionedUserIds: MentionedUser[]) => void;
  deleteText: string;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const { createdById, createdAt, commentBody } = reply;
  const creatorUserInfo = useSelector(selectUserById(createdById));
  const currentUserInfo = useSelector(selectUserInfoData);
  const createdAtString = humanizedTimeAgo(createdAt ?? '');
  const [isHover, setIsHover] = useState(false);

  const allowEdit = creatorUserInfo && currentUserInfo && creatorUserInfo.id === currentUserInfo.id;
  const isCreatorUserInactive = getIsUserInactive(creatorUserInfo?.status);

  return (
    <Box
      display="flex"
      gap={1.5}
      padding={1}
      borderRadius={1}
      bgcolor={isHover ? colors.grey[100] : colors.white}
      onMouseOver={() => setIsHover(true)}
      onMouseOut={() => setIsHover(false)}
    >
      <AvatarPicture
        dimension={DIMENSION_AVATAR_VERY_SMALL}
        avatar={creatorUserInfo}
        isInactive={isCreatorUserInactive}
      />

      {!isEditing && (
        <Box display="flex" flexDirection="column" gap={1} flex={1} maxWidth="90%">
          <Box display="flex" gap={1} alignItems="center">
            <Typography
              variant="body-bold"
              color={isCreatorUserInactive ? INACTIVE_USER_TXT_COLOR : colors.black}
              ellipsis
            >
              {!!creatorUserInfo?.name
                ? creatorUserInfo?.name
                : creatorUserInfo?.email?.split('@')[0]}
            </Typography>
            <Typography variant="caption" color={colors.grey[700]} ellipsis>
              {createdAtString}
            </Typography>
          </Box>
          <Typography
            variant="body"
            color={isCreatorUserInactive ? INACTIVE_USER_TXT_COLOR : colors.black}
          >
            <TextWithMentions text={commentBody} />
          </Typography>
        </Box>
      )}

      {isEditing && (
        <NewCommentReply
          initialValue={commentBody}
          onConfirm={(commentBody: string, mentionedUserIds: MentionedUser[]) => {
            editReplyCb(commentBody, mentionedUserIds);
            setIsEditing(false);
            closeMenu();
          }}
        />
      )}

      {allowEdit && !isEditing && (
        <Box visibility={isHover ? 'visible' : 'hidden'}>
          <ReplyMenu
            isMenuOpen={isHover ? isMenuOpen : false}
            openMenu={openMenu}
            closeMenu={closeMenu}
            deleteReplyCb={deleteReplyCb}
            activateEditReply={() => setIsEditing(true)}
            deleteText={deleteText}
          />
        </Box>
      )}
    </Box>
  );
};

export default CommentReply;
