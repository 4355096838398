import { useFlags } from 'launchdarkly-react-client-sdk';
import useQueryParams from '../../hooks/useQueryParams';

export const useFeatureToggle = () => {
  const query = useQueryParams();

  const debugTools = query.has('debugTools');

  // Please remove feature toggles no longer in use when merging an experiment
  // This list should have all currently used feature toggle flags.
  const {
    reportsComingSoonTag,
    orgFactoryReset,
    hubspotIntegration,
    sfObjectTypes,
    fieldsInDevopsCenter,
    sweepSupportChat,
    slackReports,
    priorValue,
    priorValueSlackMessages,
    copilot,
    dedupeForHiBob,
    paywall,
    fieldsPreviewInDevopsCenter,
    searchByLabel,
    aiGpt4OMini,
    verifyUrlAction,
    fieldsChat,
    slackActionButtonsCreateRecord,
    userRoles,
    unifiedAiEndpoint,
    assignmentsFte,
    storeChannelIdInSfField,
    requestToDeploy,
    searchV1,
  } = useFlags();

  return {
    debugTools,
    reportsComingSoonTag,
    orgFactoryReset,
    hubspotIntegration,
    sfObjectTypes,
    sweepSupportChat,
    fieldsInDevopsCenter,
    slackReports,
    priorValue,
    priorValueSlackMessages,
    copilot,
    dedupeForHiBob,
    paywall,
    searchByLabel,
    fieldsPreviewInDevopsCenter,
    aiGpt4OMini,
    verifyUrlAction,
    fieldsChat,
    slackActionButtonsCreateRecord,
    userRoles,
    unifiedAiEndpoint,
    assignmentsFte,
    storeChannelIdInSfField,
    requestToDeploy,
    searchV1,
  };
};
