import { Box } from '@mui/material';
import { Button, Typography } from '@sweep-io/sweep-design';
import { InviteUsersInputWithList } from '../../common/invite-users/InviteUsersInputWithList';
import { useFeatureToggle } from '../../common/useFeatureToggle';
import { SweepRoles } from '../../../hooks/useUserInfo';
import { useRoleManagerContext } from '../role-manager/RoleManagerContext';

const OrgInfoTab = ({ accountName, userRole }: { accountName?: string; userRole?: string }) => {
  const { userRoles } = useFeatureToggle();
  const { setRoleManager } = useRoleManagerContext();
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      data-testid="org-info"
      sx={{ zIndex: 1 }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          margin: '16px 0 24px 0',
        }}
      >
        <Typography ellipsis variant="h2">
          {accountName ? accountName : 'Workspace'}
        </Typography>
        {userRoles && userRole === SweepRoles.Admin && (
          <Button variant="outlined" onClick={() => setRoleManager(true)} size="small">
            Manage roles
          </Button>
        )}
      </Box>

      <InviteUsersInputWithList />
    </Box>
  );
};

export { OrgInfoTab };
