import { Box } from '@mui/material';
import { IconButton } from '@sweep-io/sweep-design';
import { Close as CloseIcon } from '@sweep-io/sweep-design/dist/icons';
import { AutomationsDeployButton } from '../deploy-button/AutomationDeployButton';

interface CreateEditDialogDeployButtonsProps {
  onDeployOrSave: (crmOrgIds: string[]) => Promise<{ versionId?: string; automationId?: string; isError?: string }>;
  confirmButtonDisabled?: boolean;
  showCancelButton?: boolean;
  onCancel?: () => any;
  disableDiff?: boolean;
  disableRequestToDeployDialog?: boolean;
}

export const CreateEditDialogDeployButtons = ({
  confirmButtonDisabled,
  showCancelButton,
  onCancel,
  onDeployOrSave,
  disableDiff,
  disableRequestToDeployDialog,
}: CreateEditDialogDeployButtonsProps) => {
  return (
    <Box display="flex" gap={1}>
      <AutomationsDeployButton
        onDeployOrSave={onDeployOrSave}
        disabled={confirmButtonDisabled}
        isWithPaywall
        disableDiff={disableDiff}
        disableRequestToDeployDialog={disableRequestToDeployDialog}
      />
      {showCancelButton && (
        <IconButton variant="flat" onClick={onCancel} data-dd-action-name="close">
          <CloseIcon />
        </IconButton>
      )}
    </Box>
  );
};
