import { useDispatch, useSelector } from 'react-redux';
import { selectShortTemplates } from '../dialog/templatesDialogReducer';
import { Box, Grid } from '@mui/material';
import { Button, IconButton } from '@sweep-io/sweep-design';
import { Plus } from '@sweep-io/sweep-design/dist/icons';
import { openNewFunnelDialog } from '../../../../../reducers/newFunnelReducer';
import { FunnelPreviewBaseCard, FunnelPreviewCard } from '../../../../common/FunnelPreviewCard';
import { ShortTemplateInterface } from '../templatesTypes';

interface TemplateCardPreviewProps {
  shortTemplate: ShortTemplateInterface;
  onLinkClick: () => void;
  onButtonClick: () => void;
}

const TemplateCardPreview = ({
  shortTemplate,
  onLinkClick,
  onButtonClick,
}: TemplateCardPreviewProps) => (
  <FunnelPreviewCard
    linkText={shortTemplate.name}
    buttonText={'Use Template'}
    onLinkClick={onLinkClick}
    onButtonClick={onButtonClick}
    imageUrl={shortTemplate.imageUrl}
  />
);

export const TemplatesTab = ({
  onSelectTemplate,
  onAddTemplate,
  crmOrgId,
  closeDialog,
}: {
  onAddTemplate: (templateId: string) => void;
  onSelectTemplate: (templateId: string) => void;
  crmOrgId: string;
  closeDialog: () => void;
}) => {
  const dispatch = useDispatch();
  const shortTemplates = useSelector(selectShortTemplates);

  const onNewTemplateClick = () => {
    dispatch(openNewFunnelDialog({ crmOrgId }));
    closeDialog();
  };

  return (
    <Grid container spacing={3} marginTop={0}>
      <Grid item xs={6}>
        <FunnelPreviewBaseCard
          cardContent={
            <Box
              onClick={onNewTemplateClick}
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <IconButton variant="outlined" onClick={onNewTemplateClick}>
                <Plus />
              </IconButton>
            </Box>
          }
          linkContent={
            <Button variant="link" type="secondary" size="large" onClick={onNewTemplateClick}>
              Start from scratch
            </Button>
          }
        />
      </Grid>
      {shortTemplates.map((template) => (
        <Grid item xs={6} key={template.id}>
          <TemplateCardPreview
            shortTemplate={template}
            onLinkClick={() => onSelectTemplate(template.id)}
            onButtonClick={() => onAddTemplate(template.id)}
          />
        </Grid>
      ))}
    </Grid>
  );
};
