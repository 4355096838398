import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { uniqueId } from '../../lib/uniqueId';
import { SweepNotification, addNotification } from '../../reducers/notificationsReducer';

export const useSweepNotifications = () => {
  const dispatch = useDispatch();

  const _addNotification = useCallback(
    ({
      variant,
      message,
      action,
      keepOpen,
       details
    }: Pick<SweepNotification, 'variant' | 'message' | 'action' | 'keepOpen' | 'details'>) => {
      const key = uniqueId();
      const sweepNotification: SweepNotification = {
        key,
        variant,
        message,
        action,
        keepOpen,
        details,
      };
      dispatch(addNotification(sweepNotification));
      return key;
    },
    [dispatch],
  );

  return {
    addNotification: _addNotification,
  };
};
