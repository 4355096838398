import { CreateEditDialogDeployButtons } from './CreateEditDialogDeployButtons';
import {
  CreateEditDialogHeaderBase,
  CreateEditDialogHeaderBaseProps,
} from './CreateEditDialogHeaderBase';

export interface AutomationsCreateEditDialogHeaderProps extends CreateEditDialogHeaderBaseProps {
  onCancelClick?: () => any;
  onDeployOrSave: (
    crmOrgIds: string[],
  ) => Promise<{ versionId?: string; automationId?: string; isError?: string }>;
  buttonDisabled?: boolean;
  showCancelButton?: boolean;
}

export const AutomationsCreateEditDialogHeader = ({
  onDeployOrSave,
  buttonDisabled,
  showCancelButton,
  onCancelClick,
  rightSideContent,
  ...restProps
}: AutomationsCreateEditDialogHeaderProps) => {
  return (
    <CreateEditDialogHeaderBase
      {...restProps}
      rightSideContent={
        <>
          {rightSideContent}
          <CreateEditDialogDeployButtons
            onDeployOrSave={onDeployOrSave}
            confirmButtonDisabled={buttonDisabled}
            showCancelButton={showCancelButton}
            onCancel={onCancelClick}
          />
        </>
      }
    />
  );
};
