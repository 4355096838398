import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { ParserResponse, RecordTypeData } from '../documentation/ParserTypes';
import extractErrorMsg from '../helpers/extractErrorMsg';
import {
  selectTransientObjects,
  selectParsedObjectNames,
  setTransientObjects,
  setObjectsParsedOnDemand,
} from '../../reducers/parserReducer';
import { selectEnvironments } from '../../reducers/global/globalReducer';
import { calcParsedFields, calcPills } from './parserUtils';
import { useCrmOrgsApiFacade } from '../../apis/facades/useCrmOrgsApiFacade';
import { GlobalDto } from '../../reducers/global/globalReducerTypes';
import { useConfigurationCanvas } from '../pages/configuration-canvas/public/useConfigurationCanvas';
import { useFetchGlobal } from '../../hooks/global-reducer/useFetchGlobal';
import useObjectTypesWithFetch from '../../hooks/useObjectTypesWithFetch';
import { telemetry } from '../../telemetry';

interface UseParserProps {
  parseObject: (objectName: string) => Promise<ParserResponse | undefined>;
  parseObjectOnDemand: (objectType: string, objectLabel: string) => void;
  getRecordType: ({
    crmOrgId,
    objectApiName,
    recordTypeName,
  }: {
    crmOrgId: string;
    objectApiName: string;
    recordTypeName: string;
  }) => Promise<RecordTypeData>;
  objectsBeingParsed: { [objectName: string]: boolean };
}

const useParser = ({ crmOrgId }: { crmOrgId: string }): UseParserProps => {
  const { canvasFunnelMap } = useConfigurationCanvas();
  const { fetchGlobal } = useFetchGlobal();

  const dispatch = useDispatch();

  const { get_crmOrgParseSingleObject, post_crmOrgParse, get_objectTypeRecordType } =
    useCrmOrgsApiFacade();

  const globalEnvironments = useSelector(selectEnvironments);
  const parsedObjectNames = useSelector(selectParsedObjectNames);

  const { objectTypes: crmOrgObjectTypes } = useObjectTypesWithFetch({
    crmOrgId,
    useSfObjects: true,
  });
  const objectsBeingParsed = useSelector(selectTransientObjects(crmOrgId));

  const toggleIsObjectBeingParsed = useCallback(
    ({ objectName, isBeingParsed }: { objectName: string; isBeingParsed: boolean }) => {
      dispatch(
        setTransientObjects({
          objectName: objectName,
          isObjectBeingParsed: isBeingParsed,
          crmOrgId,
        }),
      );
    },
    [crmOrgId, dispatch],
  );

  //this call saves object info in parser table
  const parseObject = useCallback(
    async (objectName: string) => {
      const newObject = crmOrgObjectTypes?.find((object) => object.objectType === objectName);

      if (newObject && crmOrgId) {
        const currentObjectTypes =
          crmOrgObjectTypes
            ?.filter((objectType) => parsedObjectNames?.includes(objectType.objectType))
            .map((obj) => ({ name: obj.objectType, label: obj.label })) ?? [];

        const objectTypes = [
          ...currentObjectTypes,
          { label: newObject.label, name: newObject.objectType },
        ];

        toggleIsObjectBeingParsed({ objectName, isBeingParsed: true });

        try {
          const payload = { objectTypes, activeOnly: true };
          const data = await post_crmOrgParse({ crmOrgId, payload });
          await fetchGlobal({ crmOrgId });
          toggleIsObjectBeingParsed({ objectName, isBeingParsed: false });

          return data as ParserResponse;
        } catch (e) {
          toggleIsObjectBeingParsed({ objectName, isBeingParsed: false });

          telemetry.captureError(e, { message: extractErrorMsg(e) });
        }
      }
    },
    [
      crmOrgId,
      post_crmOrgParse,
      crmOrgObjectTypes,
      parsedObjectNames,
      fetchGlobal,
      toggleIsObjectBeingParsed,
    ],
  );

  //this call doesn't save object info in parser table
  const parseObjectOnDemand = useCallback(
    async (objectApiName: string, objectLabel: string) => {
      const rollups = globalEnvironments?.[crmOrgId]?.data?.rollups;

      if (!parsedObjectNames.includes(objectApiName)) {
        const response = await get_crmOrgParseSingleObject({
          crmOrgId,
          objectName: objectApiName,
          objectLabel,
        });

        const fieldsParsedOnDemand = calcParsedFields(response, rollups ?? []);

        //update store with new info
        const pills = calcPills({
          parser: response,
          funnels: canvasFunnelMap.funnelsData,
          recordTypes: {},
        } as GlobalDto);

        dispatch(
          setObjectsParsedOnDemand({
            crmOrgId,
            objectNames: [objectApiName],
            response,
            fieldsParsedOnDemand,
            pills,
          }),
        );
        return response;
      }
    },
    [
      dispatch,
      get_crmOrgParseSingleObject,
      crmOrgId,
      globalEnvironments,
      parsedObjectNames,
      canvasFunnelMap,
    ],
  );

  const getRecordType = async ({
    crmOrgId,
    objectApiName,
    recordTypeName,
  }: {
    crmOrgId: string;
    objectApiName: string;
    recordTypeName: string;
  }) => {
    return await get_objectTypeRecordType({
      crmOrgId,
      objectApiName,
      recordTypeName,
    });
  };

  return {
    objectsBeingParsed,
    parseObject,
    parseObjectOnDemand,
    getRecordType,
  };
};

export default useParser;
