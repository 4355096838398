import { DialogContent, SxProps, Box, DialogActions } from '@mui/material';
import { isString } from 'lodash';
import { Typography, IconButton } from '@sweep-io/sweep-design';
import { Close } from '@sweep-io/sweep-design/dist/icons';
import { SweepTypographyVariants } from '@sweep-io/sweep-design/dist/components/Typography/types';
import { BaseDialog, BaseDialogProps } from './BaseDialog';
import { DialogLoader } from './DialogLoader';
import { IconButtonSize } from '@sweep-io/sweep-design/dist/components/IconButton/types';

interface Props extends BaseDialogProps {
  titleJsx?: string | React.ReactNode;
  handleClose: (event: any) => any;
  PaperPropsSx?: SxProps;
  children: React.ReactNode;
  dialogSx?: SxProps;
  dialogContentSx?: SxProps;
  showCloseButton?: boolean;
  titleTypographyVariant?: SweepTypographyVariants;
  showLoader?: boolean;
  closeButtonSize?: IconButtonSize;
  dialogActions?: React.ReactNode;
}

const InfoDialog = ({
  titleJsx,
  handleClose,
  PaperPropsSx,
  children,
  dialogSx,
  dialogContentSx,
  showCloseButton,
  titleTypographyVariant = 'h1-bold',
  showLoader,
  closeButtonSize = 'large',
  dialogActions,
  ...rest
}: Props) => {
  const closeDialog = (event: any) => {
    handleClose && handleClose(event);
  };

  return (
    <BaseDialog
      keepMounted
      onClose={closeDialog}
      PaperProps={{
        sx: { ...PaperPropsSx },
      }}
      sx={dialogSx}
      {...rest}
    >
      {!titleJsx && showCloseButton && (
        <Box
          sx={{ position: 'absolute', right: '24px', top: '24px', zIndex: 2 }}
          className={'infoDialogCloseBtn'}
        >
          <IconButton variant="flat" size={closeButtonSize} onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
      )}

      {titleJsx && (
        <Box
          sx={{
            padding: '24px 24px 8px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {isString(titleJsx) ? (
            <Typography
              data-testid="alert-dialog-title"
              variant={titleTypographyVariant}
              whiteSpace="break-spaces"
            >
              {titleJsx}
            </Typography>
          ) : (
            <>{titleJsx}</>
          )}

          {showCloseButton && (
            <Box>
              <IconButton variant="flat" size={closeButtonSize} onClick={handleClose}>
                <Close />
              </IconButton>
            </Box>
          )}
        </Box>
      )}

      <DialogContent sx={dialogContentSx}>{children}</DialogContent>
      <DialogLoader visible={showLoader} />
      {dialogActions && <DialogActions>{dialogActions}</DialogActions>}
    </BaseDialog>
  );
};

export { InfoDialog };
