import { useParams } from 'react-router';
import { useCallback } from 'react';
import { appRoutes, ROUTING_TAB } from '../../constants/appRoutes';
import useSweepNavigate from '../common/useSweepNavigate';

export enum RoutingTabs {
  assignment = 'assignment',
  scheduled = 'scheduled',
  members = 'members',
  groups = 'groups',
  territories = 'territories',
}

export const useRoutingTabs = () => {
  const { routingTab } = useParams<{
    routingTab: RoutingTabs;
    crmOrgId: string;
  }>();
  const { navigate } = useSweepNavigate();

  const changeTab = useCallback(
    (destinationTab: RoutingTabs, crmOrgId: string) => {
      navigate(`${appRoutes.routing}/${crmOrgId}/${ROUTING_TAB}/${destinationTab}`, {
        replace: true,
      });
    },
    [navigate],
  );
  const clearTab = useCallback(
    (crmOrgId: string) => {
      navigate(`${appRoutes.routing}/${crmOrgId}`, { replace: true });
    },
    [navigate],
  );

  return {
    routingTab,
    changeTab,
    clearTab,
  };
};
