import { Box, Chip, FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Delete } from '@sweep-io/sweep-design/dist/icons';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIsPristine } from '../../hooks/useIsPristine';
import { uniqueId } from '../../lib/uniqueId';
import { AutomationActionType } from '../../types/enums/AutomationActionType';
import { ActionsMenu } from '../common/actions-menu/ActionsMenu';
import { CreateEditDialog } from '../common/create-edit-dialog/CreateEditDialog';
import { IconButton, Typography, colors } from '@sweep-io/sweep-design';
import usePermission from '../common/permissions/usePermission';
import { CustomHeader } from '../common/rule-builder/CustomHeader';
import { SweepFieldsRuleBuilder } from '../common/sweep-condition-rule-builder/SweepConditionRuleBuilder';
import {
  MultipleRuleBuildersProvider,
  useRefForMultipleRuleBuilders,
  useValidateAllAndReturnIsValid,
} from '../common/sweep-condition-rule-builder/MultipleRuleBuildersContext';
import { AssignmentToType } from '../../types/enums/AssignmentToType';
import isEmpty from 'lodash/isEmpty';
import { useAssignmentGroupsApiFacade } from '../../apis/facades/useAssignmentGroupsApiFacade';
import { AssignToComponent } from '../pages/funnel-map-flow/dialogs/stage/AssignToComponent';
import cloneDeep from 'lodash/cloneDeep';
import { AssignmentOwnershipSelector } from '../Automations/AssignmentOwnershipSelector';
import { AutomationNotifyEmail } from '../Automations/AutomationNotifyEmail';
import { SlackNotificationAction } from '../Automations/slack/SlackNotificationAction';
import { AutomationActionRows } from '../Automations/AutomationActionRows';
import { SlackAddMemberAction } from '../Automations/slack/SlackAddMemberAction';
import { useSelector } from 'react-redux';
import {
  SlackConnectionStatus,
  selectSlackIntegrationStatus,
} from '../../reducers/integrationsReducer';
import { useIntegrations } from '../../hooks/useIntegrations';
import { useRunOnce } from '../common/useRunOnce';
import useConfirm from '../common/dialogs/ConfirmLeaveWithoutSave/useConfirm';
import { ConfirmLeaveWithoutSaveDialog } from '../common/dialogs/ConfirmLeaveWithoutSave/ConfirmLeaveWithoutSaveDialog';
import { ActionObjectTypesAutocomplete } from '../Automations/ActionObjectTypesAutocomplete';
import SweepSelect from '../common/SweepSelect';
import { useAutomationsContext } from '../Automations/AutomationsContext';

interface AssignmentsDialogProps {
  initialAssignmentRule?: AutomationAssignmentRule;
  onCreateEdit: (assignmentRule: AutomationAssignmentRule) => any;
  crmOrgId: string;
  objectType: string;
  onCancel: () => any;
  editMode?: boolean;
  objectTypes?: ObjectTypeNameAndTypes[];
}

//TODO replace with Tag from sweep/design
const CustomChip = styled(Chip)({
  '&.MuiChip-sizeSmall': {
    borderRadius: '6px',
    height: '28px',
    '.MuiChip-label': {
      fontSize: 12,
      fontWeight: 500,
      padding: '8px',
    },
  },
});

const SectionBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

type FollowUpActionSelectOptions =
  | AutomationActionType.SendEmail
  | AutomationActionType.Slack
  | 'DO_NOT_NOTIFY';

interface FormType {
  id: string;
  name: string;
  exitCriteria: SweepCondition;
  assignedTo: Partial<AssignmentsAssignTo>;
  assignedToFallback?: Partial<AssignmentsAssignTo>;
  followUpActions: {
    action: Partial<AutomationAction>;
    actionSelectOption?: FollowUpActionSelectOptions;
  }[];
}

const isActionValid = (action: Partial<AutomationAction>) => {
  if (action.actionType === AutomationActionType.SendEmail) {
    const emailAction = action as Partial<EmailAutomationAction>;
    const areEmailsValid =
      emailAction.actionParams?.toEmailRecipients &&
      emailAction.actionParams.toEmailRecipients.length > 0;
    return emailAction.actionParams?.emailTemplate && areEmailsValid;
  }
  if (action.actionType === AutomationActionType.Slack) {
    const slackAction = action as Partial<SlackAutomationAction>;
    return slackAction.actionParams?.message && slackAction.actionParams?.recipients?.length;
  }
  if (
    action.actionType === AutomationActionType.UpdateRecords ||
    action.actionType === AutomationActionType.CreateRecord
  ) {
    const fieldsAction = action as Partial<UpdateOrCreateAutomationAction>;
    const isFieldValid = (field: AutomationActionField) =>
      action?.actionType === AutomationActionType.UpdateRecords
        ? field._fieldInfo?.properties?._required === true
          ? field.fieldType && field._id && field.value
          : field.fieldType && field._id
        : field.fieldType && field._id && field.value;

    if (!fieldsAction?.actionParams?._fields) {
      return false;
    }
    if (fieldsAction?.actionParams?._fields.length === 0) {
      return false;
    }
    return !fieldsAction.actionParams._fields.find((field) => !isFieldValid(field));
  }

  return true;
};

const isEmptySweepCriterion = (entry: SweepCriterion) => {
  return !Boolean(entry.fieldType || entry.operator || entry.value);
};

const isEmptyAction = (entry: AutomationAction) => {
  return !Boolean(entry.actionType || (entry as AutomationAction).actionParams);
};

const hasOnlyOneCriteriaAndItIsEmpty = (assignmentRule: AutomationAssignmentRule) => {
  if (assignmentRule.when?.criteria?.criteria) {
    if (
      assignmentRule.when.criteria.criteria.length === 1 &&
      isEmptySweepCriterion(assignmentRule.when.criteria.criteria[0])
    ) {
      return true;
    }
  }
  return false;
};

const hasOnlyOneFollowUpAndItIsEmpty = (assignmentRule: AutomationAssignmentRule) => {
  if (assignmentRule.followUpActions && assignmentRule.followUpActions[0].actions.length === 1) {
    if (isEmptyAction(assignmentRule.followUpActions[0].actions[0])) {
      return true;
    }
  }
  return false;
};

const sanitizeAssignmentRule = (assignmentRule: AutomationAssignmentRule) => {
  const cloned = cloneDeep(assignmentRule);
  if (
    hasOnlyOneCriteriaAndItIsEmpty(assignmentRule) ||
    assignmentRule.when?.criteria?.criteria.length === 0
  ) {
    delete cloned.when;
  }
  if (
    hasOnlyOneFollowUpAndItIsEmpty(assignmentRule) ||
    assignmentRule.followUpActions?.length === 0
  ) {
    delete cloned.followUpActions;
  }
  return cloned;
};

const AssignmentRuleDialog = ({
  initialAssignmentRule,
  onCreateEdit,
  crmOrgId,
  objectType,
  onCancel,
  editMode,
  objectTypes,
}: AssignmentsDialogProps) => {
  const slackStatus = useSelector(selectSlackIntegrationStatus);
  const integrations = useIntegrations();
  const [recordTypesList, setRecordTypes] = useState<RecordTypeStruct[]>();

  useRunOnce(async () => {
    await integrations.getSlackStatus();
  });

  const { permissions } = useAutomationsContext();
  const [isAllowedBtn] = usePermission([permissions.create]);

  const formVarsFromInitialConditionalAction = (): FormType => {
    const { criteria = [], criteriaLogic = '' } = initialAssignmentRule?.when?.criteria || {};

    return {
      id: initialAssignmentRule?._id || uniqueId(),
      name: initialAssignmentRule?.name || 'New assignment rule',
      exitCriteria: {
        criteria,
        criteriaLogic,
      },
      assignedTo: initialAssignmentRule?.assignedTo || {},
      assignedToFallback: initialAssignmentRule?.assignedToFallback,
      followUpActions:
        initialAssignmentRule?.followUpActions && initialAssignmentRule.followUpActions[0]
          ? initialAssignmentRule.followUpActions[0].actions.map((action) => ({
              action: action,
              actionSelectOption: (action.actionType as FollowUpActionSelectOptions) || '',
            }))
          : [],
    };
  };

  const [formVars, setFormVars] = useState<FormType>(formVarsFromInitialConditionalAction);

  const isPristine = useIsPristine(formVars);
  const { openConfirm } = useConfirm();

  const isAssignmentsFormValid = useMemo(() => {
    const isNameValid = formVars.name.length > 0;
    //temporary solution - @alvaromorais to adjust types with SWEEP-4917
    const isAssignToValid =
      formVars.assignedTo.type === AssignmentToType.Field
        ? formVars.assignedTo._name
        : formVars.assignedTo.type && formVars.assignedTo.id && formVars.assignedTo._name; // TODO there's issue when formVars.assignTo._name is missing, work around is to re-select the assignedTo
    const areActionsValid = formVars.followUpActions.every(({ action }) => isActionValid(action));

    return isNameValid && isAssignToValid && areActionsValid;
  }, [formVars]);

  const ruleBuilderRef = useRefForMultipleRuleBuilders();
  const validateAllAndReturnIsValid = useValidateAllAndReturnIsValid();

  const applyCurrentAssignment = useCallback(() => {
    if (formVars.followUpActions && formVars.followUpActions.length) {
      formVars.followUpActions = formVars.followUpActions.filter((el) => el.actionSelectOption);
    }
    const updatedAssignmentRule: AutomationAssignmentRule = {
      _id: formVars.id,
      name: formVars.name,
      assignedTo: formVars.assignedTo as AssignmentsAssignTo, // TODO. Check this partial / Validate
      assignedToFallback: formVars.assignedToFallback as AssignmentsAssignTo, // TODO. Check this partial / Validate
      when: {
        criteria: formVars.exitCriteria,
      },
      followUpActions:
        formVars.followUpActions && formVars.followUpActions.length
          ? [
              {
                _id: uniqueId(),
                _name: '',
                actions: formVars.followUpActions.map(
                  (followUpAction) => followUpAction.action as AutomationAction,
                ),
              },
            ]
          : undefined, // TODO. Check this partial / Validate
    };

    const isValid =
      hasOnlyOneCriteriaAndItIsEmpty(updatedAssignmentRule) || validateAllAndReturnIsValid();

    if (isValid) {
      const sanitizedRule = sanitizeAssignmentRule(updatedAssignmentRule);
      onCreateEdit(sanitizedRule);
    }
  }, [formVars, validateAllAndReturnIsValid, onCreateEdit]);

  const clearAction = (actionIndx: number) => {
    if (formVars.followUpActions.length > 1) {
      const updatedFollowUpActions = [...formVars.followUpActions];
      updatedFollowUpActions.splice(actionIndx, 1);
      setFormVars((prev) => ({ ...prev, followUpActions: updatedFollowUpActions }));
    } else {
      setFormVars((prev) => ({ ...prev, followUpActions: [{ action: {} }] }));
    }
  };
  const addAction = () => {
    const updatedFollowUpActions = [...formVars.followUpActions, { action: {} }];
    setFormVars((prev) => ({ ...prev, followUpActions: updatedFollowUpActions }));
  };

  const getRecordTypePerObject = (action: any) => {
    const currentObject = (action as UpdateOrCreateAutomationAction)?.actionParams?.objectTypeName
      ?.objectType;
    const result = objectTypes?.find((obj) => {
      return obj.objectType === currentObject;
    });
    return result?.recordTypes;
  };
  const hasRecordTypes = (_action: Partial<UpdateOrCreateAutomationAction>) => {
    return (
      _action.actionParams?.objectTypeName?.recordType ||
      (recordTypesList && recordTypesList.length > 1)
    );
  };

  const closeOrConfirmClose = async () => {
    if (!isPristine) {
      const isConfirmed = await openConfirm(<ConfirmLeaveWithoutSaveDialog />);
      if (!isConfirmed) {
        return;
      }
    }
    onCancel();
  };
  /* Show Fallback */
  /* Only shows if the group has a limit */
  const { get_assignmentGroups } = useAssignmentGroupsApiFacade();
  const [showFallback, setShowFallback] = useState(false);

  const updateShowFallback = useCallback(async () => {
    const { groups } = await get_assignmentGroups();
    const group = groups.find((group) => group.id === formVars.assignedTo.id);
    if (group) {
      setShowFallback(Boolean(group.groupLimit));
    } else {
      setShowFallback(false);
    }
  }, [formVars.assignedTo?.id, get_assignmentGroups]);

  useEffect(() => {
    updateShowFallback();
  }, [updateShowFallback]);
  /* End Show Fallback */

  return (
    <CreateEditDialog
      open
      onClose={closeOrConfirmClose}
      HeaderProps={{
        hideBackButton: true,
        showCancelButton: true,
        buttonContent: editMode ? 'Save Changes' : 'Create Rule',
        buttonStartIcon: 'Automations',
        onCancelClick: closeOrConfirmClose,
        onChange: (name: string) => {
          setFormVars({
            ...formVars,
            name,
          });
        },
        onButtonClick: () => {
          applyCurrentAssignment();
        },
        placeholder: 'Add assignment name',
        value: formVars.name,
        buttonDisabled: !isAssignmentsFormValid || !isAllowedBtn,
        editDisabled: !isAllowedBtn,
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '36px', paddingBottom: 3 }}>
        <SectionBox>
          <Box>
            <CustomChip
              label="WHEN"
              sx={{
                backgroundColor: colors.gum[500],
                color: colors.white,
              }}
            />
          </Box>
          <Box>
            <Box
              sx={{
                backgroundColor: colors.grey[100],
                paddingTop: '22px',
                borderRadius: '16px',
                '.rbHeader': {
                  padding: 0,
                  margin: 0,
                },
                '.rule-builder-logic-preview': {
                  marginRight: '22px',
                },
              }}
            >
              <CustomHeader customHeader="Those conditions are met" />
              <SweepFieldsRuleBuilder
                ref={ruleBuilderRef}
                readOnly={!isAllowedBtn}
                sweepCondition={formVars.exitCriteria}
                onChange={function (newSweepCondition: SweepCondition) {
                  setFormVars({
                    ...formVars,
                    exitCriteria: {
                      ...formVars.exitCriteria,
                      ...newSweepCondition,
                    },
                  });
                }}
                crmOrgId={crmOrgId}
                objectType={objectType}
              />
            </Box>
          </Box>
        </SectionBox>
        <SectionBox>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <CustomChip
              label="ASSIGN TO"
              sx={{
                backgroundColor: colors.blush[300],
              }}
            />
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ActionsMenu
                actions={[
                  {
                    label: 'Clear',
                    value: 'Clear',
                    disabled: !isAllowedBtn,
                  },
                  {
                    label: 'Add Another Action',
                    value: 'Add',
                    disabled: !isAllowedBtn,
                  },
                ]}
                onClick={(action) => {
                  switch (action.value) {
                    case 'Clear':
                      setFormVars({
                        ...formVars,
                        assignedTo: {},
                      });
                      break;
                    case 'Add': {
                      addAction();
                      break;
                    }
                  }
                }}
              />
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" gap={3}>
            <AssignToComponent
              crmOrgId={crmOrgId}
              readonly={!isAllowedBtn}
              assignTo={formVars.assignedTo}
              onChange={(assignTo) => {
                setFormVars({
                  ...formVars,
                  assignedTo: assignTo,
                  assignedToFallback: undefined,
                });
              }}
              objectType={objectType}
            />
            <Box>
              <AssignmentOwnershipSelector
                crmOrgId={crmOrgId}
                readonly={!isAllowedBtn}
                assignTo={formVars.assignedTo}
                onChange={(assignTo) => {
                  setFormVars({
                    ...formVars,
                    assignedTo: assignTo,
                    assignedToFallback: formVars?.assignedToFallback
                      ? {
                          ...formVars?.assignedToFallback,
                          fieldMapping: assignTo.fieldMapping,
                        }
                      : undefined,
                  });
                }}
                objectType={objectType}
              />
            </Box>
            {formVars.assignedTo.type === AssignmentToType.Team && showFallback && (
              <Box display="flex" gap="12px" alignItems="center">
                <Typography variant="body">
                  If all members reached their limit, assign to:
                </Typography>
                <AssignToComponent
                  crmOrgId={crmOrgId}
                  readonly={!isAllowedBtn}
                  assignTo={formVars.assignedToFallback}
                  onChange={(assignedToFallback) => {
                    let _assignedToFallback = isEmpty(assignedToFallback)
                      ? undefined
                      : assignedToFallback;
                    if (_assignedToFallback) {
                      _assignedToFallback = {
                        ..._assignedToFallback,
                        fieldMapping: {
                          ..._assignedToFallback?.fieldMapping,
                          ...formVars.assignedTo.fieldMapping,
                        },
                      };
                    }
                    setFormVars({
                      ...formVars,
                      assignedToFallback: _assignedToFallback,
                    });
                  }}
                  objectType={objectType}
                />
                {formVars.assignedToFallback && (
                  <Box>
                    <IconButton
                      disabled={false}
                      onClick={() => {
                        setFormVars({
                          ...formVars,
                          assignedToFallback: undefined,
                        });
                      }}
                      variant="flat"
                      size="small"
                    >
                      <Delete />
                    </IconButton>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </SectionBox>
        <SectionBox>
          {formVars.followUpActions.map((followUpAction, actionIndex) => (
            <Box key={followUpAction.action._id ?? actionIndex}>
              <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
                <CustomChip
                  label="AND THEN"
                  sx={{
                    backgroundColor: colors.lemon[500],
                    color: colors.black,
                  }}
                />
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <ActionsMenu
                    actions={[
                      {
                        label: 'Clear',
                        value: 'Clear',
                        disabled: !isAllowedBtn,
                      },
                      {
                        label: 'Add Another Action',
                        value: 'Add',
                        disabled:
                          !isAllowedBtn || followUpAction.actionSelectOption === 'DO_NOT_NOTIFY',
                      },
                    ]}
                    onClick={(action) => {
                      switch (action.value) {
                        case 'Clear':
                          clearAction(actionIndex);
                          break;
                        case 'Add': {
                          addAction();
                          break;
                        }
                      }
                    }}
                  />
                </Box>
              </Box>
              <Box sx={{ display: 'flex', gap: '16px' }}>
                <Box>
                  <Select
                    disabled={!isAllowedBtn}
                    placeholder={'Do this'}
                    value={followUpAction.actionSelectOption ?? ''}
                    onChange={(event) => {
                      const updatedAction = cloneDeep(followUpAction);
                      updatedAction.actionSelectOption = event.target
                        .value as FollowUpActionSelectOptions;
                      switch (event.target.value) {
                        case AutomationActionType.SendEmail: {
                          updatedAction.action._id = followUpAction.action._id ?? uniqueId();
                          updatedAction.action.actionType = AutomationActionType.SendEmail;
                          updatedAction.action.actionParams = undefined;
                          break;
                        }
                        case AutomationActionType.Slack: {
                          updatedAction.action._id = followUpAction.action._id ?? uniqueId();
                          updatedAction.action.actionType = AutomationActionType.Slack;
                          updatedAction.action.actionParams = undefined;
                          break;
                        }
                        case AutomationActionType.UpdateRecords: {
                          updatedAction.action._id = followUpAction.action._id ?? uniqueId();
                          updatedAction.action.actionType = AutomationActionType.UpdateRecords;
                          updatedAction.action.actionParams = { thisRecord: true };
                          break;
                        }
                        case AutomationActionType.SlackAddMember: {
                          updatedAction.action._id = followUpAction.action._id ?? uniqueId();
                          updatedAction.action.actionType = AutomationActionType.SlackAddMember;
                          updatedAction.action.actionParams = undefined;
                          break;
                        }
                        case AutomationActionType.CreateRecord: {
                          updatedAction.action._id = followUpAction.action._id ?? uniqueId();
                          updatedAction.action.actionType = AutomationActionType.CreateRecord;
                          updatedAction.action.actionParams = undefined;
                          break;
                        }
                      }
                      const updatedFollowUpActions = [...formVars.followUpActions];
                      updatedFollowUpActions[actionIndex] = updatedAction;

                      setFormVars((prev) => ({
                        ...prev,
                        followUpActions: updatedFollowUpActions,
                      }));
                    }}
                  >
                    <MenuItem value={''} disabled hidden>
                      Disabled
                    </MenuItem>
                    <MenuItem value={AutomationActionType.SendEmail}>Send Email</MenuItem>
                    <MenuItem value={AutomationActionType.Slack}>Notify to Slack</MenuItem>
                    <MenuItem value={AutomationActionType.UpdateRecords}>Update record</MenuItem>
                    <MenuItem value={AutomationActionType.CreateRecord}>Create record</MenuItem>
                    {slackStatus === SlackConnectionStatus.Connected && (
                      <MenuItem value={AutomationActionType.SlackAddMember}>
                        Add members to deal room
                      </MenuItem>
                    )}
                  </Select>
                </Box>

                {followUpAction.action?.actionType === AutomationActionType.CreateRecord && (
                  <Box>
                    <FormControl sx={{ minWidth: '160px', marginRight: '8px', width: '100%' }}>
                      <ActionObjectTypesAutocomplete
                        _action={followUpAction.action as UpdateOrCreateAutomationAction}
                        crmOrgId={crmOrgId}
                        disabled={!isAllowedBtn}
                        onChange={(automationAction) => {
                          const updateFollowUpActions = [...formVars.followUpActions];
                          updateFollowUpActions[actionIndex].action = automationAction;
                          setFormVars((prev) => ({
                            ...prev,
                            followUpActions: updateFollowUpActions,
                          }));
                          setRecordTypes(getRecordTypePerObject(followUpAction.action));
                        }}
                      />
                    </FormControl>
                  </Box>
                )}
                {followUpAction.action?.actionType === AutomationActionType.CreateRecord &&
                  followUpAction.action?.actionParams?.objectTypeName &&
                  hasRecordTypes(followUpAction.action) && (
                    <Box>
                      <SweepSelect
                        FormControlProps={{
                          sx: { minWidth: '140px' },
                        }}
                        SelectProps={{
                          disabled: !isAllowedBtn,
                          placeholder: 'Choose Record Type',
                          value:
                            (followUpAction.action as UpdateOrCreateAutomationAction).actionParams
                              ?.objectTypeName?.recordType?.name ?? '',
                          renderValue: (val: any) => {
                            if (!recordTypesList) {
                              setRecordTypes(getRecordTypePerObject(followUpAction.action));
                            }
                            const recordType = recordTypesList?.find((type) => type?.name === val);
                            return recordType?.label ?? val ?? '';
                          },
                          onChange: (event: SelectChangeEvent<unknown>) => {
                            const val = event.target.value;
                            const getRecordTypeByName = recordTypesList?.find(
                              (type) => type?.name === val,
                            );
                            const newObjectTypeName = {
                              ...(followUpAction.action as UpdateOrCreateAutomationAction)
                                .actionParams?.objectTypeName,
                              recordType: getRecordTypeByName,
                            };
                            const automationAction = {
                              ...(followUpAction.action as UpdateOrCreateAutomationAction),
                              actionParams: {
                                ...(followUpAction.action as UpdateOrCreateAutomationAction)
                                  ?.actionParams,
                                objectTypeName: newObjectTypeName as ObjectTypeName,
                              },
                            };

                            const updateFollowUpActions = [...formVars.followUpActions];
                            updateFollowUpActions[actionIndex].action = automationAction;
                            setFormVars((prev) => ({
                              ...prev,
                              followUpActions: updateFollowUpActions,
                            }));
                          },
                        }}
                      >
                        {recordTypesList?.map((type) => (
                          <MenuItem key={type.name} value={type.name}>
                            {type.label}
                          </MenuItem>
                        ))}
                      </SweepSelect>
                    </Box>
                  )}
              </Box>
              {followUpAction.action.actionType === AutomationActionType.SendEmail && (
                <AutomationNotifyEmail
                  action={followUpAction.action as EmailAutomationAction}
                  crmOrgId={crmOrgId}
                  onChange={(automationAction) => {
                    const updateFollowUpActions = [...formVars.followUpActions];
                    updateFollowUpActions[actionIndex].action = automationAction;
                    setFormVars((prev) => ({
                      ...prev,
                      followUpActions: updateFollowUpActions,
                    }));
                  }}
                  objectName={objectType}
                />
              )}
              {followUpAction.action.actionType === AutomationActionType.Slack && (
                <SlackNotificationAction
                  readonly={false}
                  action={formVars.followUpActions[actionIndex].action as SlackAutomationAction}
                  crmOrgId={crmOrgId}
                  onChange={(automationAction) => {
                    const updateFollowUpActions = [...formVars.followUpActions];
                    updateFollowUpActions[actionIndex].action = automationAction;
                    setFormVars((prev) => ({
                      ...prev,
                      followUpActions: updateFollowUpActions,
                    }));
                  }}
                  objectName={objectType}
                />
              )}
              {followUpAction.action.actionType === AutomationActionType.UpdateRecords && (
                <Box className="automationFieldLineWrapper" sx={{ width: '100%', mt: 2 }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                    <AutomationActionRows
                      initialObjectName={objectType}
                      disableLookupItems={false}
                      hideRecordTypeIdField={true}
                      objectName={objectType}
                      cmOrgId={crmOrgId}
                      onChange={(fields) => {
                        const updateFollowUpActions = cloneDeep(formVars.followUpActions);
                        (
                          updateFollowUpActions[actionIndex]
                            .action as UpdateOrCreateAutomationAction
                        ).actionParams._fields = fields;
                        setFormVars((prev) => ({
                          ...prev,
                          followUpActions: updateFollowUpActions,
                        }));
                      }}
                      fields={
                        (
                          formVars.followUpActions[actionIndex]
                            .action as UpdateOrCreateAutomationAction
                        ).actionParams?._fields || []
                      }
                    />
                  </Box>
                </Box>
              )}
              {followUpAction.action.actionType === AutomationActionType.SlackAddMember && (
                <SlackAddMemberAction
                  action={
                    formVars.followUpActions[actionIndex].action as SlackDealRoomAutomationAction
                  }
                  crmOrgId={crmOrgId}
                  onChange={(automationAction) => {
                    const updateFollowUpActions = [...formVars.followUpActions];
                    updateFollowUpActions[actionIndex].action = automationAction;
                    setFormVars((prev) => ({
                      ...prev,
                      followUpActions: updateFollowUpActions,
                    }));
                  }}
                  objectName={objectType}
                />
              )}

              {followUpAction.action.actionType === AutomationActionType.CreateRecord &&
                followUpAction.action.actionParams?.objectTypeName && (
                  <Box sx={{ display: 'flex', mt: 1 }}>
                    <Box
                      sx={{
                        marginRight: '12px',
                        paddingTop: '10px',
                        flex: '0 0 auto',
                        maxWidth: '160px',
                        width: '160px',
                      }}
                    >
                      <Typography variant="body">
                        <>
                          Set the new{' '}
                          <b>{followUpAction.action.actionParams?.objectTypeName?.label}</b>
                          ’s field value(s):
                        </>
                      </Typography>
                    </Box>
                    <Box className="automationFieldLineWrapper" sx={{ width: '100%' }}>
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                        <AutomationActionRows
                          showCreatableFields={true}
                          disableLookupItems={true}
                          hideRecordTypeIdField={true}
                          readonly={!isAllowedBtn}
                          objectName={
                            followUpAction.action?.actionParams?.objectTypeName?.objectType
                          }
                          cmOrgId={crmOrgId}
                          initialObjectName={objectType}
                          onChange={(fields) => {
                            const updateFollowUpActions = cloneDeep(formVars.followUpActions);
                            (
                              updateFollowUpActions[actionIndex]
                                .action as UpdateOrCreateAutomationAction
                            ).actionParams._fields = fields;
                            setFormVars((prev) => ({
                              ...prev,
                              followUpActions: updateFollowUpActions,
                            }));
                          }}
                          fields={
                            (
                              formVars.followUpActions[actionIndex]
                                .action as UpdateOrCreateAutomationAction
                            ).actionParams?._fields || []
                          }
                        />
                      </Box>
                    </Box>
                  </Box>
                )}
            </Box>
          ))}
        </SectionBox>
      </Box>
    </CreateEditDialog>
  );
};

const AssignmentRuleDialogWithProviders = (props: AssignmentsDialogProps) => (
  <MultipleRuleBuildersProvider>
    <AssignmentRuleDialog {...props} />
  </MultipleRuleBuildersProvider>
);

export { AssignmentRuleDialogWithProviders as AssignmentRuleDialog };
