import { AdvancedFilterItemValue } from '../../common/deprecated-advanced-filter/DeprecatedAdvancedFilter';

export enum CanvasFilterTypes {
  STEP = 'step',
  RECORD_TYPE = 'recordType',
  AUTOMATIONS_STEP = 'automationsStep',
  OBJECT = 'object',
  MULTI_GENERIC = 'multipleGeneric',
  TOGGLE_FILTER = 'toggleFilter',
}
export interface SweepCanvasFilter {
  type: CanvasFilterTypes;
  position?: number;
}

export interface StepsCanvasFilter extends SweepCanvasFilter {
  type: CanvasFilterTypes.STEP;
  recordTypeFilterRef?: string;
}

export interface AutomationsStepsCanvasFilter extends SweepCanvasFilter {
  type: CanvasFilterTypes.AUTOMATIONS_STEP;
  recordTypeFilterRef?: string;
}

interface RecordTypeObjectsFilter {
  type: 'reference' | 'values';
}

interface RecordTypeObjectsFilterReference extends RecordTypeObjectsFilter {
  type: 'reference';
  filterRef: string;
}

interface RecordTypeObjectsFilterValues extends RecordTypeObjectsFilter {
  type: 'values';
  values: string[];
}

export interface RecordTypesCanvasFilter extends SweepCanvasFilter {
  type: CanvasFilterTypes.RECORD_TYPE;
  objectsFilter?: RecordTypeObjectsFilterValues | RecordTypeObjectsFilterReference;
}

export interface ObjectsCanvasFilter extends SweepCanvasFilter {
  type: CanvasFilterTypes.OBJECT;
}

export interface SingleGenericFilterItem {
  label: string;
  value: string;
  badge?: number;
}

export interface MultiGenericFilterItem {
  label: string;
  value: string;
  isSectionItem?: boolean;
}

export interface MultiGenericCanvasFilter extends SweepCanvasFilter {
  type: CanvasFilterTypes.MULTI_GENERIC;
  items: MultiGenericFilterItem[];
  label: string;
}

export interface ToggleCanvasFilter extends SweepCanvasFilter {
  type: CanvasFilterTypes.TOGGLE_FILTER;
  checked: boolean;
  label: string;
}

export type SweepCanvasFilters =
  | StepsCanvasFilter
  | RecordTypesCanvasFilter
  | ObjectsCanvasFilter
  | AutomationsStepsCanvasFilter
  | MultiGenericCanvasFilter
  | ToggleCanvasFilter;

export interface SweepCanvasFiltersMap {
  [key: string]: SweepCanvasFilters;
}

export interface StepsFilterData {
  recordTypeIds: string[];
  objectName: string;
  funnelId?: string;
  fullName?: string;
}

export interface CanvasValuedFilters<DataT = any> {
  selectedValues: string[];
  selectedValuesData: AdvancedFilterItemValue<DataT>[];
}
