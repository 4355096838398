import { Snackbar, Slide, SlideProps, Box, SnackbarCloseReason, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  closeNotification,
  removeNotification,
  selectNotifications,
  SweepNotification,
} from '../../reducers/notificationsReducer';
import { NotificationAlert } from './NotificationAlert';

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="left" />;
}

export const Notifications = () => {
  const notifications = useSelector(selectNotifications);
  const dispatch = useDispatch();

  const onClose = (notification: SweepNotification, reason?: SnackbarCloseReason) => {
    if (notification.keepOpen && reason && ['clickaway', 'escapeKeyDown'].includes(reason)) {
      return;
    }
    dispatch(closeNotification(notification.key));

    setTimeout(() => {
      dispatch(removeNotification(notification.key));
    }, 1000);
  };

  return (
    <Stack
      gap={1}
      sx={{
        position: 'fixed',
        zIndex: 1502,
        top: '72px',
        right: '16px',
      }}
    >
      {notifications.map((notification) => {
        return (
          <Snackbar
            key={notification.key}
            sx={{
              position: 'relative', //to overwrite MUI style
              whiteSpace: 'pre-line',
            }}
            open={!notification.dismissed}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            TransitionComponent={SlideTransition}
            autoHideDuration={notification.keepOpen ? null : 3000}
            onClose={(event, reason) => onClose(notification, reason)}
          >
            <Box>
              <NotificationAlert
                onClose={() => onClose(notification)}
                severity={notification.variant}
                message={notification.message}
                action={
                  notification.action
                    ? {
                        actionText: notification.action.actionText,
                        onActionClick: () =>
                          notification.action?.reduxAction &&
                          dispatch(notification.action.reduxAction),
                      }
                    : undefined
                }
                details={notification.details}
              />
            </Box>
          </Snackbar>
        );
      })}
    </Stack>
  );
};
