import { FormControl, MenuItem } from '@mui/material';
import { HsPropertyTypes, HsFilterOperatorsOperators } from '../hubspot.types';
import SelectNoBorder from '../../../../common/SelectNoBorder';
import { getLabelsForOperators } from './hubspotTypesAndOperators';

interface HubspotFieldsOperatorSelectorProps {
  hsPropertyType: HsPropertyTypes;
  operator?: HsFilterOperatorsOperators;
  onChange: (operator: HsFilterOperatorsOperators) => any;
  disabled?: boolean;
}

export const HubspotFieldsOperatorSelector = ({
  onChange,
  operator,
  hsPropertyType,
  disabled,
}: HubspotFieldsOperatorSelectorProps) => {
  const operatorsToShow = getLabelsForOperators(hsPropertyType);
  return (
    <FormControl fullWidth>
      <SelectNoBorder
        placeholder={''}
        onChange={(e) => onChange(e.target.value as HsFilterOperatorsOperators)}
        value={operator || ''}
        disabled={disabled}
        MenuProps={{
          sx: {
            '& .disabled-item': {
              display: 'none',
            },
          },
        }}
      >
        <MenuItem value={''} disabled className="disabled-item"></MenuItem>
        {operatorsToShow.map((option) => (
          <MenuItem value={option.operator} key={option.operator}>
            {option.label}
          </MenuItem>
        ))}
      </SelectNoBorder>
    </FormControl>
  );
};
