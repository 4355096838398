import { Box, Alert, Dialog, DialogContent, DialogTitle, DialogActions } from '@mui/material';
import { Button, Typography } from '@sweep-io/sweep-design';

import ErrorIcon from '@mui/icons-material/Error';
import { useEffect, useState } from 'react';
import { retry } from 'ts-retry-promise';
import { useCrmOrgs } from '../useCrmOrgs';
import { CenteredCircularProgress } from '../../../common/CenteredCircularProgress';
import { OptionsSelectorButton } from '../../../common/OptionsSelectorButton';
import { setCrmOrgTransientData } from '../connectCrmSessionHelper';
import { EnvironmentTypes } from '../EnvironmentTypeEnum';
import { envDescriptions } from '../envDescriptions';
import { useRunOnce } from '../../../common/useRunOnce';
import { waitForWindowToClose } from '../../helper';
import useQueryParams from '../../../../hooks/useQueryParams';
import { colors } from '@sweep-io/sweep-design';
import { ConnectCrmOrgState } from '../ConnectCrmOrgStateEnum';
import WithConnectOrgPermission from '../../../common/WithConnectOrgPermission';
import { telemetry } from '../../../../telemetry';

interface ConnectCrmOrgDialogProps {
  isOpen: boolean;
  crmOrg?: CrmOrg; // If crmOrg is not provided it creates a new one
  onClose?: () => any;
  onCancel?: () => any;
  onEnvConnectionSuccess?: (crmOrg: CrmOrg) => any;
  onConnectStateChange?: (connectState: ConnectCrmOrgState) => any;
  onCallback?: () => void;
  connectAsProductionOnly?: boolean;
  dialogTitle?: string;
  confirmText?: string;
}

const ConnectCrmOrgDialog = ({
  crmOrg,
  onClose,
  isOpen,
  onConnectStateChange,
  connectAsProductionOnly,
  onCallback,

  dialogTitle,
  confirmText,
}: ConnectCrmOrgDialogProps) => {
  const {
    getCrmOrg,
    getSalesforceRedirectToOAuthUrl,
    createSalesforceCrmOrg,
    getConnectedCrmOrgs,
  } = useCrmOrgs();

  const [selectedEnv, setSelectedEnv] = useState<EnvironmentTypes>();
  const [productionEnv, setProductionEnv] = useState<CrmOrg>();
  const [localCrmOrg, setLocalCrmOrg] = useState(crmOrg);

  const [connectState, setConnectState] = useState<ConnectCrmOrgState>(
    ConnectCrmOrgState.NotConnected,
  );

  useEffect(() => {
    onConnectStateChange && onConnectStateChange(connectState);
  }, [connectState, onConnectStateChange]);

  const [loading, setLoading] = useState(true);
  const queryParams = useQueryParams();

  useRunOnce(() => {
    (async function () {
      try {
        const crmOrgs = await getConnectedCrmOrgs();

        const hasDevEnvs = () => crmOrgs.some((crmOrg) => !crmOrg.isMain && !crmOrg.isSandbox);

        const _productionEnv = crmOrgs.find((crmOrg) => crmOrg.isMain && !crmOrg.isSandbox);

        const firstAvailableEnv = () => {
          if (!_productionEnv) {
            return EnvironmentTypes.Production;
          }
          if (!hasDevEnvs()) {
            return EnvironmentTypes.Development;
          }
          return EnvironmentTypes.Sandbox;
        };
        setProductionEnv(_productionEnv);
        setSelectedEnv(firstAvailableEnv());
      } catch (error) {
        telemetry.captureError(error);
      } finally {
        setLoading(false);
      }
    })();
  });

  const verifyThatOrgIsConnected = async (crmOrgId: string) => {
    const getConnectedCrmOrg = async () => {
      try {
        const _crmOrg = await getCrmOrg(crmOrgId, true);
        if (_crmOrg.isConnected) {
          return _crmOrg;
        } else {
          return null;
        }
      } catch (err) {
        telemetry.captureError(err);
        return null;
      }
    };
    try {
      // Retries up to 40 seconds until the the org returns isConnected
      // Fails if timeout
      const _crmOrg = await retry(getConnectedCrmOrg, {
        until: (t) => t !== null,
        timeout: 40000,
        delay: 2000,
      });

      if (_crmOrg) {
        onClose && onClose();
      } else {
        setConnectState(ConnectCrmOrgState.ErrorConnecting);
      }
    } catch (err) {
      telemetry.captureError(err);
      setConnectState(ConnectCrmOrgState.ErrorConnecting);
    }
  };

  const connect = async () => {
    if (!selectedEnv) {
      return;
    }
    const forceMainForDebugging = queryParams.has('forceMain') ?? false;
    const isSandbox = selectedEnv === EnvironmentTypes.Sandbox;
    const isMain = selectedEnv === EnvironmentTypes.Production || forceMainForDebugging;

    let _crmOrg = localCrmOrg;
    if (!_crmOrg) {
      _crmOrg = await createSalesforceCrmOrg({ isMain, isSandbox });

      setLocalCrmOrg(_crmOrg);
    }

    if (_crmOrg) {
      setCrmOrgTransientData({ crmOrgId: _crmOrg.id, isSandbox });
    }

    const _window = window.open(await getSalesforceRedirectToOAuthUrl(_crmOrg.id), '_blank');

    if (_window && _crmOrg) {
      setConnectState(ConnectCrmOrgState.Connecting);
      await waitForWindowToClose(_window);
      await verifyThatOrgIsConnected(_crmOrg.id);
      onCallback && onCallback();
    } else {
      setConnectState(ConnectCrmOrgState.ErrorConnecting);
    }
  };

  return (
    <Dialog
      disableEscapeKeyDown
      open={isOpen}
      onClose={(event, reason) => reason !== 'backdropClick' && onClose && onClose()}
      PaperProps={{
        sx: {
          boxShadow: '0px 40px 100px rgba(0, 0, 0, 0.1)',
          borderRadius: '12px',
          maxWidth: connectAsProductionOnly ? '381px' : 'auto',
        },
      }}
    >
      {loading && (
        <DialogContent
          sx={{
            width: 482,
            minHeight: '245px',
            padding: '24px',
            paddingTop: '100px',
          }}
        >
          <CenteredCircularProgress />
        </DialogContent>
      )}
      {!loading && (
        <>
          <DialogTitle
            sx={{
              padding: connectAsProductionOnly ? '24px 36px 8px 24px' : '',
            }}
          >
            <Typography variant="h2">{dialogTitle ?? 'Add New Environment'}</Typography>
          </DialogTitle>
          <DialogContent
            style={{
              width: connectAsProductionOnly ? '100%' : 482,
              padding: connectAsProductionOnly ? '0 24px' : '24px 32px',
              minHeight: connectAsProductionOnly ? 'auto' : '301px',
            }}
          >
            {connectState === ConnectCrmOrgState.Connecting && (
              <Box paddingTop={'100px'}>
                <CenteredCircularProgress circularProgressProps={{ size: 30 }} />
                <Box display="flex" justifyContent="center" alignItems="center" paddingTop={'15px'}>
                  <Typography variant="body">
                    Keep your Salesforce tab open while Sweep connects
                  </Typography>
                </Box>
              </Box>
            )}
            {connectState === ConnectCrmOrgState.NotConnected && !connectAsProductionOnly && (
              <NotConnectedEnvContent
                selectedEnv={selectedEnv}
                setSelectedEnv={setSelectedEnv}
                productionEnv={productionEnv}
                envDescriptions={envDescriptions}
              />
            )}

            {connectState === ConnectCrmOrgState.NotConnected && connectAsProductionOnly && (
              <ConnectAsProductionOnlyContent />
            )}

            {connectState === ConnectCrmOrgState.Connected && (
              <>
                <Alert severity="success">Connected Successfully!</Alert>
                <Box style={{ textAlign: 'center', marginTop: 40 }}>
                  <Button onClick={onClose}>Close</Button>
                </Box>
              </>
            )}
            {connectState === ConnectCrmOrgState.ErrorConnecting && (
              <>
                <Box style={{ textAlign: 'center', marginTop: 40 }}>
                  <ErrorIcon
                    sx={{
                      color: colors.blush[500],
                      fontSize: '42px',
                    }}
                  />
                  <Typography variant="h2">Connection Error</Typography>
                  <p>
                    <Typography variant="body">
                      Could not connect to Salesforce, make sure you have the right login details
                      and try again
                    </Typography>
                  </p>
                </Box>
              </>
            )}
          </DialogContent>
          <DialogActions sx={{ p: !connectAsProductionOnly ? 4 : 3 }}>
            {connectState === ConnectCrmOrgState.NotConnected && (
              <>
                <Button variant="outlined" onClick={onClose}>
                  Cancel
                </Button>
                <WithConnectOrgPermission>
                  <Button
                    onClick={connect}
                    disabled={
                      !Boolean(selectedEnv) ||
                      (productionEnv && selectedEnv === EnvironmentTypes.Production)
                    }
                  >
                    {confirmText ?? 'Connect to Salesforce'}
                  </Button>
                </WithConnectOrgPermission>
              </>
            )}
            {connectState === ConnectCrmOrgState.ErrorConnecting && (
              <>
                <Button variant="outlined" onClick={onClose}>
                  Cancel
                </Button>
                <Button onClick={() => setConnectState(ConnectCrmOrgState.NotConnected)}>
                  Try Again
                </Button>
              </>
            )}
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

const ConnectAsProductionOnlyContent = () => {
  return (
    <>
      In order to activate reporting packages, please connect Sweep to your Production Salesforce
      environment
    </>
  );
};

const NotConnectedEnvContent = ({
  productionEnv,
  selectedEnv,
  setSelectedEnv,
  envDescriptions,
}: {
  productionEnv?: CrmOrg;
  selectedEnv?: EnvironmentTypes;
  setSelectedEnv: (value: EnvironmentTypes) => void;
  envDescriptions: any;
}) => {
  return (
    <Box>
      <Box
        style={{
          marginTop: '8px',
          display: 'flex',
          flexDirection: 'column',
          gap: '48px',
        }}
      >
        <OptionsSelectorButton
          options={[
            {
              value: EnvironmentTypes.Production,
              label: 'Production',
            },
            {
              value: EnvironmentTypes.Sandbox,
              label: 'Sandbox',
            },
            {
              value: EnvironmentTypes.Development,
              label: 'Development',
            },
          ]}
          value={selectedEnv}
          onChange={(value) => setSelectedEnv(value as EnvironmentTypes)}
        />
        <Typography variant="body">
          {selectedEnv === EnvironmentTypes.Production && productionEnv ? (
            <>
              Your environment <strong>{productionEnv?.name}</strong> ({productionEnv?.instanceUrl})
              is connected as Production.
              <br />
              <br />
              You can only have one Salesforce Production environment connected to Sweep at the same
              time.
            </>
          ) : (
            selectedEnv && envDescriptions[selectedEnv]
          )}
        </Typography>
      </Box>
    </Box>
  );
};

export { ConnectCrmOrgDialog };
