import { Box } from '@mui/material';
import { ArrowUp, ArrowDown } from '@sweep-io/sweep-design/dist/icons';
import { TruncatedTextTooltip } from '../TruncatedTextTooltip';
import { SortOrder } from '../types';
import { dataTableVariants } from './dataTableVariants';
import { TableRow as MuiTableRow, TableCell as MuiTableCell } from '@mui/material';
import { DataTableColumn, DataTableSortStateProps, DataTableVariant } from './TableTypes';
import { IconButton, colors } from '@sweep-io/sweep-design';

const stickyHeaderStyles = {
  zIndex: 3,
  position: 'sticky',
  top: '0px',
  background: colors.white,
};

interface TableHeadProps {
  columns: DataTableColumn[];
  stickyHeader?: boolean;
  variant: DataTableVariant;
  sortState?: DataTableSortStateProps;
  sort: (columnName: string) => void;
}

export const TableHeadRow = ({
  columns,
  stickyHeader,
  variant,
  sortState,
  sort,
}: TableHeadProps) => {
  const columnWidth = 100 / columns.length; //to prevent jumping when content width changes in table with virtual scroll

  return (
    <MuiTableRow
      sx={{
        ...(stickyHeader ? stickyHeaderStyles : {}),
      }}
    >
      {columns.map((column) => {
        const isSortable = column.sortCompare || column.showSortIcon;

        return (
          <MuiTableCell
            sx={{
              padding: dataTableVariants[variant].cellPadding,
              cursor: isSortable ? 'pointer' : 'default',
              '&:hover ': {
                background: isSortable ? colors.grey[100] : 'initial',
              },
              height: '40px', //design team asked for it, if your design doesn't match please talk to them
            }}
            onClick={() => sort && sort(column.field)}
            key={column.field}
            width={column.width ?? columnWidth + '%'}
            className={column.className}
            data-testid={`data-table-header-${column.field}`}
          >
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'nowrap',
                alignItems: 'center',
                '& > .MuiButtonBase-root': {
                  opacity: sortState?.sortBy === column.field ? 1 : 0,
                  marginLeft: '5px',
                },
                '&:hover .MuiButtonBase-root': { opacity: 1, transition: '.5s' },
              }}
            >
              <TruncatedTextTooltip
                color={colors.black}
                variant={dataTableVariants[variant].headerFontVariant}
              >
                {column.headerName}
              </TruncatedTextTooltip>

              {column.showSortIcon && (
                <IconButton variant="link" size="tiny">
                  {sortState?.sortBy === column.field && sortState?.sortOrder === SortOrder.ASC ? (
                    <ArrowUp color={colors.grey[500]} />
                  ) : (
                    <ArrowDown color={colors.grey[500]} />
                  )}
                </IconButton>
              )}
            </Box>
          </MuiTableCell>
        );
      })}
    </MuiTableRow>
  );
};
