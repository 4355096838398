import { AppBar, Box, Toolbar } from '@mui/material';
import { IconButton, Tooltip, colors } from '@sweep-io/sweep-design';
import { MenuCollapse, MenuExpand } from '@sweep-io/sweep-design/dist/icons';
import { DASHBOARD_HEADER_HEIGHT } from '../../../constants';
import { SweepLogoIcon } from '../../../icons/generalIcons';
import { NotificationCenter } from '../notification-center/NotificationCenter';
import UserMenu from './UserMenu';
import { DashboardHeaderEnvSelector } from '../../pages/environments/connect-env/DashboardHeaderEnvSelector';
import DashboardHeaderFetchProgressBar from '../fetch-progress-bar/DashboardHeaderFetchProgressBar';
import { CustomerSupportEntryPoint } from '../customer-support/CustomerSupportEntryPoint';
import { useFeatureToggle } from '../../common/useFeatureToggle';
import HeaderDivider from './HeaderDivider';
import CopilotChatDashboard from '../copilot/CopilotChatDashboard';
import PaywallDashboardHeader from './PaywallDashboardHeader';

const DashboardHeader = ({
  sidebarMenuOpen,
  toggle,
}: {
  sidebarMenuOpen: boolean;
  toggle: () => void;
}) => {
  const { copilot, paywall } = useFeatureToggle();

  return (
    <AppBar sx={{ backgroundColor: colors.night[900], zIndex: 1300 }} position="sticky">
      <Toolbar
        variant="dense"
        sx={{
          justifyContent: 'space-between',
          minHeight: DASHBOARD_HEADER_HEIGHT,
          '&': {
            paddingLeft: '10px',
          },
        }}
      >
        <Box display="flex" alignItems="center" gap={1}>
          <Tooltip title={sidebarMenuOpen ? 'Collapse menu' : 'Expand menu'}>
            <IconButton variant="dark" size="small" onClick={toggle}>
              {sidebarMenuOpen ? <MenuCollapse /> : <MenuExpand />}
            </IconButton>
          </Tooltip>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '12px',
              height: '100%',
            }}
          >
            <SweepLogoIcon />
            <DashboardHeaderEnvSelector />
            <DashboardHeaderFetchProgressBar />
          </Box>
        </Box>
        <Box display="flex" gap={paywall ? 2 : 1} alignItems="center">
          {copilot && <CopilotChatDashboard />}
          {!paywall && <CustomerSupportEntryPoint />}
          {copilot && !paywall && <HeaderDivider />}
          {!paywall && <NotificationCenter />}
          {paywall && <PaywallDashboardHeader />}
          <UserMenu />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default DashboardHeader;
