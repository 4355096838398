import { Button, Typography, colors } from '@sweep-io/sweep-design';
import { Box, TableFooter, TableRow } from '@mui/material';
import { useCallback, useState } from 'react';
import { ActionableDataTable } from '../../../common/table/ActionableDataTable';
import { DataTableColumn, DataTableRow, DataTableVariant } from '../../../common/table/TableTypes';
import { DotDivider } from '../../../common/DotDivider';

export enum TableHeaderVariant {
  withBackground = 'WITH_BACKGROUND',
  withoutBackground = 'WITHOUT_BACKGROUND',
  justResultCounter = 'RESULT_COUNTER',
}

export interface TableWithHeaderProps {
  tableHeader: string;
  columns: DataTableColumn[];
  rows: DataTableRow[];
  headerVariant?: TableHeaderVariant;
  shortListViewCounter?: number;
  onRowClick?: (
    e: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
    row: DataTableRow,
    columnData: DataTableColumn,
  ) => void;
}

export const TableWithHeader = ({
  tableHeader,
  columns,
  rows,
  headerVariant = TableHeaderVariant.withoutBackground,
  shortListViewCounter,
  onRowClick,
}: TableWithHeaderProps) => {
  const [rowsLength, setRowsLength] = useState(shortListViewCounter ?? rows.length);
  const displayLoadMoreButton = !!(shortListViewCounter && rows.length > shortListViewCounter);

  const onTableLoadMoreClick = useCallback(() => {
    const hasMore = rowsLength < rows.length;

    if (hasMore) {
      setRowsLength(rows.length);
    } else {
      setRowsLength(shortListViewCounter ?? rows.length);
    }
  }, [rowsLength, rows, shortListViewCounter]);

  const withBackground = headerVariant === TableHeaderVariant.withBackground;

  return (
    <Box sx={{ mr: '2px' }} pb={withBackground ? 2 : 0}>
      <Box>
        {(withBackground || headerVariant === TableHeaderVariant.withoutBackground) && (
          <Box
            sx={{
              background: withBackground ? colors.grey[100] : 'transparent',
              p: withBackground ? '1px 8px' : 0,
              borderRadius: 0.5,
              mb: 1,
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            {!withBackground && <DotDivider dotColor={colors.black} />}

            <Typography
              variant={withBackground ? 'body-bold' : 'body-medium'}
              color={withBackground ? colors.grey[800] : colors.black}
            >
              {tableHeader}
            </Typography>
          </Box>
        )}

        {TableHeaderVariant.justResultCounter === headerVariant && (
          <Box sx={{ pb: 3 }}>
            <Typography variant="caption">{rows.length} results</Typography>
          </Box>
        )}
      </Box>
      <Box pl={2}>
        <ActionableDataTable
          columns={columns}
          rows={rows.slice(0, rowsLength)}
          onRowClick={onRowClick}
          actionableButtonsOnHover
          TableFooter={
            displayLoadMoreButton ? (
              <ShowMoreFooter onClick={onTableLoadMoreClick} hasMore={rowsLength < rows.length} />
            ) : null
          }
          variant={DataTableVariant.narrow}
        />
      </Box>
    </Box>
  );
};

const ShowMoreFooter = ({ onClick, hasMore }: { onClick: () => void; hasMore: boolean }) => {
  return (
    <TableFooter>
      <TableRow>
        <Button
          variant="link"
          onClick={onClick}
          type="secondary"
          endIconName={hasMore ? 'ChevronDown' : 'ChevronUp'}
        >
          {hasMore ? 'Load more' : 'Show less'}
        </Button>
      </TableRow>
    </TableFooter>
  );
};
