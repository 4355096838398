import { Box, Popover, Button as MUIButton } from '@mui/material';
import { Typography, __SWEEP_DESIGN_TYPOGRAPHY_STYLES___, colors } from '@sweep-io/sweep-design';
import { UnreadComment, Calendar } from '@sweep-io/sweep-design/dist/icons';
import { useCallback } from 'react';
import { StyledListItem } from '../../common/StyledListItem';
import { useSelector } from 'react-redux';
import { selectUserInfoData } from '../../../reducers/userInfoReducer';
import { useFeatureToggle } from '../../common/useFeatureToggle';
//TODO replace with correct icon when available
import { Plus as PlusSignIcon } from '@sweep-io/sweep-design/dist/icons';
import usePopover from '../../../hooks/usePopover';
import { DASHBOARD_BUTTON_HOVER_COLOR } from '../dashboardHelper';
import { openIntercom, scheduleCall } from '../customerSupportHelper';

const intercomEntryPointId = 'INTERCOM_ENTRY_POINT';

export const CustomerSupportEntryPoint = () => {
  const userInfo = useSelector(selectUserInfoData);
  const { copilot } = useFeatureToggle();
  const { isPopoverOpen, closePopover, openPopover, anchorElement, anchorRef } = usePopover();

  const onScheduleCallClick = useCallback(() => {
    closePopover();
    scheduleCall(userInfo);
  }, [closePopover, userInfo]);

  const onLiveChatClick = useCallback(() => {
    closePopover();
    openIntercom();
  }, [closePopover]);

  //by product definition: only free users should see support button
  //to make it work in dev env please pass force = true to initIntercom func
  if (userInfo?.account?.type !== 'Free') {
    return;
  }

  return (
    <Box display="flex" alignItems="center">
      {!copilot && (
        <MUIButton
          ref={anchorRef}
          sx={{
            background: colors.lilac[300],
            borderColor: isPopoverOpen ? colors.white : colors.lilac[300],
            color: colors.night[900],
            padding: '2px 12px',
            height: '24px',

            '&:hover': {
              background: colors.lilac[300],
              borderColor: colors.white,
            },
          }}
          onClick={openPopover}
        >
          <Typography color={colors.night[900]} variant="body">
            Need help?
          </Typography>
        </MUIButton>
      )}

      {copilot && (
        <MUIButton
          ref={anchorRef}
          sx={{
            background: isPopoverOpen ? DASHBOARD_BUTTON_HOVER_COLOR : 'transparent',
            border: 'none',
            padding: '11px 16px',
            height: '32px',
            fontWeight: 500,

            '&:hover': {
              background: DASHBOARD_BUTTON_HOVER_COLOR,
            },
          }}
          onClick={openPopover}
        >
          <Box display="flex" gap="6px" alignItems="center">
            <PlusSignIcon color={colors.white} />
            <Typography color={colors.white} variant="body-medium">
              Talk to us
            </Typography>
          </Box>
        </MUIButton>
      )}

      <Popover
        onClose={closePopover}
        anchorEl={anchorElement}
        open={isPopoverOpen}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Box sx={{ p: 1, width: '200px' }}>
          <StyledListItem
            id={intercomEntryPointId}
            sx={{ m: 0, color: colors.black, font: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.body }}
            title="Live chat"
            StartIcon={<UnreadComment />}
            onClick={onLiveChatClick}
          />

          <StyledListItem
            sx={{ m: 0, color: colors.black, font: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.body }}
            title="Schedule a call"
            StartIcon={<Calendar />}
            onClick={onScheduleCallClick}
          />
        </Box>
      </Popover>
    </Box>
  );
};
