import { Box, Button, ButtonBase, TableCell } from '@mui/material';
import { useState, ReactNode } from 'react';
import {
  Copy as CopyIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@sweep-io/sweep-design/dist/icons';
import { DataTable } from './DataTable';
import { DataTableColumn, DataTableProps, DataTableRow, RenderRow } from './TableTypes';
import { Typography, colors } from '@sweep-io/sweep-design';

export type DataTableOnActionClickClbk = ({
  idx,
  actionType,
}: {
  idx: number;
  actionType: ActionableDataTableActionTypes;
  row: DataTableRow;
}) => any;

export enum ActionableDataTableActionTypes {
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  DUPLICATE = 'DUPLICATE',
}
type ActionableDataTableActionItemType<T = string> = T | ((idx: number, row: DataTableRow) => T);
export interface ActionableDataTableAction {
  type: ActionableDataTableActionTypes;
  confirm?: boolean;
  confirmTitle?: ActionableDataTableActionItemType;
  confirmSubtitle?: ActionableDataTableActionItemType;
  confirmButtonCancelLabel?: ActionableDataTableActionItemType;
  confirmButtonOkLabel?: ActionableDataTableActionItemType;
  confirmActionIcon?: ActionableDataTableActionItemType<ReactNode | undefined>;
}

const dataTableActionsIconMap = {
  [ActionableDataTableActionTypes.EDIT]: <EditIcon />,
  [ActionableDataTableActionTypes.DELETE]: <DeleteIcon />,
  [ActionableDataTableActionTypes.DUPLICATE]: <CopyIcon />,
};

interface ActionableDataTableOnlyProps {
  actions?: ActionableDataTableAction[];
  onActionClick?: DataTableOnActionClickClbk;
}

export interface ActionableDataTableRow extends DataTableRow {
  disabledActions?: ActionableDataTableActionTypes[];
}

type ActionableDataTableProps<TRow extends ActionableDataTableRow = any> = DataTableProps<TRow> &
  ActionableDataTableOnlyProps;

const ACTION_ICON_WIDTH = 16;
const ACTIONS_PADDING = 48;

export function ActionableDataTable<TRow extends ActionableDataTableRow = any>({
  rows,
  columns,
  actions,
  onActionClick,
  allowReorder,
  actionableButtonsOnHover,
  ...dataTableProps
}: ActionableDataTableProps<TRow>) {
  const [confirmAction, setConfirmAction] = useState<{
    action: ActionableDataTableAction;
    rowIndex: number;
  }>();

  const hasActions = Boolean(actions?.length);
  const width = ((actions?.length || 0) + 1) * ACTION_ICON_WIDTH + ACTIONS_PADDING;

  const columnsWithAction: DataTableColumn[] = hasActions
    ? [
        ...columns,
        {
          field: '__actions',
          headerName: ' ',
          width: width + 'px',
        },
      ]
    : columns;

  const onActionClickWithPossibleConfirmation = ({
    idx,
    action,
  }: {
    idx: number;
    action: ActionableDataTableAction;
  }) => {
    if (action.confirm) {
      setConfirmAction({ action, rowIndex: idx });
    } else {
      onActionClick &&
        onActionClick({
          actionType: action.type,
          idx: idx,
          row: rows[idx],
        });
    }
  };

  const ResizedIcon = ({ children }: { children: ReactNode }) => (
    <Box
      sx={{
        svg: {
          width: '16px',
          height: '16px',
        },
        'svg path': {
          stroke: colors.grey[800],
        },
      }}
    >
      {children}
    </Box>
  );

  const rowsWithActions = hasActions
    ? rows.map((row, rowIdx) => ({
        ...row,
        __actions: row.disabled ? null : (
          <Box className="SweepActionableDataActions">
            {actions
              ?.filter((action) => !row.disabledActions?.includes(action.type))
              .map((action) => (
                <ButtonBase
                  key={action.type}
                  disableRipple
                  onClick={(e) => {
                    e.stopPropagation();
                    onActionClickWithPossibleConfirmation({
                      action,
                      idx: rowIdx,
                    });
                  }}
                >
                  <ResizedIcon>{dataTableActionsIconMap[action.type]}</ResizedIcon>
                </ButtonBase>
              ))}
          </Box>
        ),
      }))
    : rows;

  const renderRow: RenderRow = ({ rowIdx, row }) => {
    if (!confirmAction || confirmAction.rowIndex !== rowIdx) {
      return undefined;
    }
    const processActionItem = (action?: ActionableDataTableActionItemType<string | ReactNode>) => {
      if (!action) {
        return;
      }
      if (typeof action === 'function') {
        return action(rowIdx, row);
      } else {
        return action;
      }
    };
    return (
      <TableCell colSpan={columnsWithAction.length - 1} sx={{ padding: '0' }}>
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{
            border: '1px solid',
            borderColor: colors.blush[500],
            background: colors.grey[100],
            color: colors.grey[800],
            padding: '12px 24px',
          }}
        >
          <Box display="flex" gap="8px" sx={{ alignItems: 'center' }}>
            <ResizedIcon>
              {processActionItem(confirmAction.action.confirmActionIcon) ||
                dataTableActionsIconMap[confirmAction.action.type]}
            </ResizedIcon>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h4">
                {processActionItem(confirmAction.action.confirmTitle) || 'Confirm'}
              </Typography>
              <Typography variant="body">
                {processActionItem(confirmAction.action.confirmSubtitle) || 'Are you sure?'}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '8px',
            }}
          >
            <Button
              sx={{ height: '32px' }}
              size="small"
              color="secondary"
              onClick={() => setConfirmAction(undefined)}
            >
              {processActionItem(confirmAction.action.confirmButtonCancelLabel) || 'Cancel'}
            </Button>
            <Button
              sx={{ height: '32px' }}
              size="small"
              color="error"
              onClick={() => {
                setConfirmAction(undefined);
                onActionClick &&
                  onActionClick({
                    actionType: confirmAction.action.type,
                    idx: rowIdx,
                    row: rows[rowIdx],
                  });
              }}
            >
              {processActionItem(confirmAction.action.confirmButtonOkLabel) || 'Ok'}
            </Button>
          </Box>
        </Box>
      </TableCell>
    );
  };

  return (
    <DataTable
      {...dataTableProps}
      allowReorder={!confirmAction && allowReorder}
      columns={columnsWithAction}
      rows={rowsWithActions}
      renderRow={renderRow}
      sxRowFunction={(isDragging: boolean) => ({
        '.SweepActionableDataActions': {
          display: 'none',
        },
        '&:hover .SweepActionableDataActions': {
          display: isDragging ? 'none' : 'flex',
          gap: '16px',
          justifyContent: 'flex-end',
        },
        ...(dataTableProps.sxRowFunction ? dataTableProps.sxRowFunction(isDragging) : {}),
      })}
      actionableButtonsOnHover={actionableButtonsOnHover}
    />
  );
}
