import { Box, Typography } from '@mui/material';
import { Button, colors } from '@sweep-io/sweep-design';

export const NoResults = ({ onClearSearch }: { onClearSearch: () => void }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 2 }}>
      <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
        <Typography variant="h4">No results found</Typography>
        <Typography variant="caption" color={colors.grey[800]}>
          Try clearing your filters or search for different keywords
        </Typography>
      </Box>
      <Button variant="link" onClick={onClearSearch}>
        Clear Search
      </Button>
    </Box>
  );
};
