import { useCallback } from 'react';
import { useConfigurationCanvasContext } from './ConfigurationCanvasContext';
import { useFunnelMapApiFacade } from '../../../apis/facades/funnel-map/useFunnelMapApiFacade';
import { funnelMapToFunnelMapPutPayload } from '../../../apis/facades/funnel-map/funnelMapToFunnelMapPutPayload';

export const useSave = () => {
  const { setLastFunnelMapInServer } = useConfigurationCanvasContext();

  const { put_funnelMap } = useFunnelMapApiFacade();

  const saveFunnelMap = useCallback(
    async (funnelMap: FunnelMap) => {
      setLastFunnelMapInServer(funnelMap);

      const funnelMapPutPayload = {
        ...funnelMapToFunnelMapPutPayload(funnelMap),
      };

      await put_funnelMap(funnelMap.id, funnelMapPutPayload);
    },
    [put_funnelMap, setLastFunnelMapInServer],
  );

  return {
    saveFunnelMap,
  };
};
