import { Box, Divider } from '@mui/material';
import { Typography, colors, Button, Tag } from '@sweep-io/sweep-design';
import { revertToLatestAssignmentGroupInServer } from '../../../../reducers/assignmentGroupsPageReducer';
import {
  ActionableDataTable,
  ActionableDataTableActionTypes,
} from '../../../common/table/ActionableDataTable';
import { StyledSwitch } from '../../../common/StyledSwitch';
import { humanizeDate } from '../../../helpers/humanizeDate';
import { useCallback, useEffect, useState } from 'react';
import { Warning as WarningIcon } from '@sweep-io/sweep-design/dist/icons';
import { useAssignmentsApiWithReducer } from '../useAssignmentsApiWithReducer';
import { SweepNotificationVariant } from '../../../../reducers/notificationsReducer';
import { useSweepNotifications } from '../../../notifications/useSweepNotifications';
import { useDispatch } from 'react-redux';
import StyledTooltip from '../../../common/StyledTooltip';
import usePermission from '../../../common/permissions/usePermission';
import { DataTableColumn, DataTableRow, DataTableVariant } from '../../../common/table/TableTypes';
import { AssignmentGroup, AssignmentGroupMember } from '../../../../reducers/assignmentGroupTypes';
import { IntegerPicker } from './IntegerPicker';
import { Percentage } from '../../../common/Percentage';
import { Permission } from '@server/permissions';
import { dataTableVariants } from '../../../common/table/dataTableVariants';
import { telemetry } from '../../../../telemetry';

const TABLE_VARIANT = DataTableVariant.default;
const HEADER_FONT_VARIANT = dataTableVariants[TABLE_VARIANT].headerFontVariant;

const getColumns = (showLimit?: boolean): DataTableColumn[] => [
  {
    field: 'drag',
    headerName: '',
    showDragHandle: true,
    width: 30,
  },
  {
    field: 'active',
    headerName: 'Active',
    width: 86,
  },
  {
    field: 'name',
    headerName: 'Name',
  },
  {
    field: 'limit',
    headerName: (
      <Box paddingLeft="45px">
        <Typography variant={HEADER_FONT_VARIANT}>Limit</Typography>
      </Box>
    ),
    hidden: !showLimit,
  },
  {
    field: 'weight',
    headerName: (
      <Box paddingLeft="45px">
        <Typography variant={HEADER_FONT_VARIANT}>Weight distribution</Typography>
      </Box>
    ),
  },
  {
    field: 'dateAdded',
    headerName: 'Date added',
    width: 150,
  },
];

export const GroupMembersTable = ({
  groupData,
  filterFunc,
}: {
  groupData: AssignmentGroup;
  filterFunc: (member: AssignmentGroupMember) => boolean;
}) => {
  const {
    optimisticSaveWithDebounceAssignmentGroupData,
    deleteAssignmentGroupMemberAtIdx,
    patchAssignmentGroupMemberActiveStatusAtIdx,
    patchAssignmentGroupMemberWeightAtIdx,
    patchAssignmentGroupMemberLimitAtIdx,
  } = useAssignmentsApiWithReducer();
  const { addNotification } = useSweepNotifications();

  const dispatch = useDispatch();
  const [localMembers, setLocalMembers] = useState(groupData.members);
  const permissionString: Permission[] = ['edit:assignment-groups'];
  const [isAllowedBtn] = usePermission(permissionString);

  useEffect(() => {
    setLocalMembers(groupData.members);
  }, [groupData.members]);

  const withErrorNotificationAndRevert = useCallback(
    async (fn: Function, errorMessage = 'Error Saving Assignment Group') => {
      try {
        await fn();
      } catch (e) {
        telemetry.captureError(e);
        addNotification({
          message: errorMessage,
          variant: SweepNotificationVariant.Error,
        });
        dispatch(revertToLatestAssignmentGroupInServer());
      }
    },
    [addNotification, dispatch],
  );

  const totalEnabledWeights = localMembers?.reduce((acc, curr) => {
    if (curr.membershipActive) {
      acc += curr.weight;
    }
    return acc;
  }, 0);

  const rows =
    localMembers?.map((member, index) => {
      const {
        dateAdded,
        userId: id,
        name,
        weight,
        membershipActive,
        isUpNext,
        userActive,
        limitValue: limit,
      } = member;
      const disabled = !userActive || !isAllowedBtn;
      const color = disabled ? colors.grey[500] : colors.black;

      const tooltip = disabled
        ? !isAllowedBtn
          ? 'To modify the settings of this assignment group, please contact your admin.'
          : 'Reactivate this user in Salesforce to enable actions within group'
        : '';

      return {
        id,
        index,
        name: (
          <StyledTooltip title={tooltip}>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
                color,
              }}
            >
              <Typography variant="body">{name}</Typography>
              {isUpNext && <Tag label="Up Next" color={colors.mint[100]} />}
            </Box>
          </StyledTooltip>
        ),
        active: (
          <StyledTooltip title={tooltip}>
            <Box>
              <StyledSwitch
                disabled={disabled}
                onChange={(e, checked) =>
                  withErrorNotificationAndRevert(() =>
                    patchAssignmentGroupMemberActiveStatusAtIdx(groupData, index, checked),
                  )
                }
                checked={membershipActive}
              />
            </Box>
          </StyledTooltip>
        ),
        dateAdded: (
          <StyledTooltip title={tooltip}>
            <Box color={color}>
              <Typography variant="body">
                {humanizeDate({
                  dateOrTimestamp: dateAdded,
                })}
              </Typography>
            </Box>
          </StyledTooltip>
        ),
        weight: (
          <Box display="flex" alignItems="center" gap={1}>
            <Box className="show-on-hover">
              <IntegerPicker
                disabled={disabled}
                value={weight}
                onChange={(weight) => {
                  const newWeight = weight === null ? 1 : weight;
                  withErrorNotificationAndRevert(() =>
                    patchAssignmentGroupMemberWeightAtIdx(groupData, index, newWeight),
                  );
                }}
                min={1}
                max={99}
              />
            </Box>
            {membershipActive && (
              <Box flex={1}>
                <Percentage numerator={weight} denominator={totalEnabledWeights as number} active />
              </Box>
            )}
          </Box>
        ),
        limit: (
          <Box display="flex">
            <Box className="show-on-hover" display="flex" gap={2} alignItems="center">
              <IntegerPicker
                disabled={disabled}
                placeholder="n/a"
                value={limit ?? null}
                onChange={(limit) => {
                  withErrorNotificationAndRevert(() =>
                    patchAssignmentGroupMemberLimitAtIdx(groupData, index, limit ?? null),
                  );
                }}
                min={0}
                max={99}
                zeroAsEmpty
              />
              {Boolean(limit) && (
                <>
                  <Divider orientation="vertical" variant="middle" sx={{ height: '24px' }} />
                  <Button
                    variant="link"
                    type="secondary"
                    onClick={() =>
                      withErrorNotificationAndRevert(() =>
                        patchAssignmentGroupMemberLimitAtIdx(groupData, index, null),
                      )
                    }
                  >
                    Remove limit
                  </Button>
                </>
              )}
            </Box>
          </Box>
        ),
        data: member,
      };
    }) || [];

  const actions = !isAllowedBtn
    ? []
    : [
        {
          type: ActionableDataTableActionTypes.DELETE,
          confirm: true,
          confirmButtonOkLabel: 'Delete',
          confirmButtonCancelLabel: 'Cancel',
          confirmTitle: (idx: number, row: DataTableRow) =>
            row.data.userActive
              ? `Remove group member "${row.data.name}" from "${groupData.name}" group?`
              : `Remove non active member Salesforce user?`,
          confirmSubtitle: (idx: number, row: DataTableRow) =>
            row.data.userActive
              ? `Removing "${row.data.name}" from the group will adjust the weight distribution among the remaining members.`
              : 'Please note that once members who are not active in Salesforce are removed from the assignment group, they will not ' +
                'be able to be reassigned to any group until they are re-activated in Salesforce.',
          confirmActionIcon: (idx: number, row: DataTableRow) => {
            return row.data.userActive ? undefined : <WarningIcon color={colors.blush[500]} />;
          },
        },
      ];

  const showLimit = Boolean(groupData.groupLimit);
  const columns = getColumns(showLimit);
  const filteredLocalMembers = localMembers.filter(filterFunc);
  const idsToDisplay = filteredLocalMembers.map((member) => member.userId);
  const rowsToDisplay = rows.filter((row) => idsToDisplay.includes(row.id));

  return (
    <ActionableDataTable
      allowReorder={isAllowedBtn && rowsToDisplay.length === rows.length}
      columns={columns}
      rows={rowsToDisplay}
      onOrderChange={({ sourceIndex, destinationIndex }) => {
        if (!localMembers || !groupData.members) {
          return;
        }

        let newMembers = [...localMembers];

        delete newMembers[sourceIndex];
        if (sourceIndex < destinationIndex) {
          destinationIndex++;
        }
        newMembers.splice(destinationIndex, 0, localMembers[sourceIndex]);
        newMembers = newMembers.filter((member) => member);

        setLocalMembers(newMembers);
        withErrorNotificationAndRevert(() =>
          optimisticSaveWithDebounceAssignmentGroupData({ ...groupData, members: newMembers }),
        );
      }}
      actions={actions}
      onActionClick={({ actionType, row }) => {
        switch (actionType) {
          case ActionableDataTableActionTypes.DELETE:
            withErrorNotificationAndRevert(
              () => deleteAssignmentGroupMemberAtIdx(groupData, row.index),
              'Error deleting group',
            );
            break;
        }
      }}
      sxRowFunction={(isDragging: boolean) => ({
        '& .show-on-hover': {
          button: { opacity: 0 },
          '.MuiOutlinedInput-root fieldset': { borderColor: 'transparent' },
          '.MuiDivider-root': { opacity: 0 },
        },
        '&:hover .show-on-hover': {
          button: { opacity: isDragging ? 0 : 'initial' },
          '.MuiDivider-root': { opacity: 1 },
        },
      })}
      TableEmptyStateComponent={
        <Box width="100%" textAlign="center" pt={2}>
          <Typography variant="caption" color={colors.grey[800]}>
            No results found
          </Typography>
        </Box>
      }
      variant={TABLE_VARIANT}
    />
  );
};
