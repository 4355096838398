import { Box, Popover } from '@mui/material';
import { colors, IconButton } from '@sweep-io/sweep-design';
import { Delete, MoreActionsVertical, NotePencil, Show } from '@sweep-io/sweep-design/dist/icons';
import { Dispatch, SetStateAction, useState } from 'react';
import { StyledMenuItem } from './StyledMenuItem';
import { useRoleManagerContext } from '../RoleManagerContext';
import { RoleGroupInterface, RoleGroupType } from '@server/role-group-interface';
import ConfirmDestructiveDialog from '../../../common/dialogs/ConfirmDestructiveDialog';
import { useRoleManager } from '../useRoleManager';

export const ActionMenu = ({
  role,
  anchorEl,
  setAnchorEl,
}: {
  role: RoleGroupInterface;
  anchorEl?: HTMLButtonElement;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | undefined>>;
}) => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const { initializeEditRole } = useRoleManagerContext();
  const { deleteRoleGroup } = useRoleManager();

  const isCustomRole = role.type === RoleGroupType.USER_CREATED;

  const renderCustomRoleMenu = () => (
    <>
      <StyledMenuItem text="Edit" icon={<NotePencil />} onClick={() => initializeEditRole(role)} />
      <StyledMenuItem
        color={colors.blush[500]}
        text="Delete"
        icon={<Delete color={colors.blush[500]} />}
        onClick={() => setIsDeleteDialogOpen(true)}
      />
    </>
  );

  const renderSystemRoleMenu = () => (
    <StyledMenuItem text="View" icon={<Show />} onClick={() => initializeEditRole(role)} />
  );

  return (
    <Box onClick={(e) => e.stopPropagation()}>
      <ConfirmDestructiveDialog
        onConfirm={() => deleteRoleGroup(role.id)}
        onClose={() => {
          setIsDeleteDialogOpen(false);
          setAnchorEl(undefined);
        }}
        open={isDeleteDialogOpen}
        confirmText={'Delete'}
        cancelText={'Cancel'}
        title={'Are you sure you want to delete?'}
      >
        Delete {role.name} role permanently?
      </ConfirmDestructiveDialog>

      <IconButton
        size="small"
        variant="flat"
        onClick={(event) => {
          event.stopPropagation();
          setAnchorEl(event.currentTarget);
        }}
      >
        <MoreActionsVertical />
      </IconButton>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(undefined)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box
          sx={{ p: 1, width: '217px' }}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          {isCustomRole && renderCustomRoleMenu()}
          {!isCustomRole && renderSystemRoleMenu()}
        </Box>
      </Popover>
    </Box>
  );
};
