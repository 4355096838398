import { Box, CircularProgress, CircularProgressProps } from '@mui/material';

interface CenteredCircularProgressProps {
  circularProgressProps?: CircularProgressProps;
}

const CenteredCircularProgress = ({
  circularProgressProps = {
    size: 50,
    color: 'primary',
  },
}: CenteredCircularProgressProps) => (
  <Box display="flex" justifyContent="center" alignItems="center" minHeight="100%">
    <CircularProgress {...circularProgressProps} />
  </Box>
);

export { CenteredCircularProgress };
