import { ReactNode } from 'react';
import { SelectedDependency } from '../../../reducers/documentationReducer';
import {
  Apex,
  Assign,
  Automations,
  Clock,
  CPQ,
  Fields,
  Layout,
  PlaybookAlert,
  RecordType,
  Reporting,
  SalesforceAutomations,
  Target,
  ValidationRules,
} from '@sweep-io/sweep-design/dist/icons';

export enum ConfigurationType {
  validationRules = 'validationRules',
  approvalProcesses = 'approvalProcesses',
  workflowRules = 'workflowRules',
  flows = 'flows',
  processBuilderFlows = 'processBuilderFlows',
  apexTriggers = 'apexTriggers',
  apexClasses = 'apexClasses',
  fields = 'fields',
  reports = 'reports',
  layouts = 'layouts',
  emailTemplates = 'emailTemplates',
  objects = 'objects',
  sweepAutomation = 'automations',
  sweepAlert = 'alerts',
  sweepDedupe = 'dedup',
  sweepMatching = 'matching',
  sweepAssignment = 'assignments',
  sweepScheduledAssignment = 'scheduledAssignments',
  rollups = 'rollups',
  cpqData = 'cpqData',
  recordTypes = 'recordTypes',
}

export const SWEEP_AUTOMATION_TEMPLATE_TYPES = [
  ConfigurationType.sweepAutomation,
  ConfigurationType.sweepAlert,
  ConfigurationType.sweepAssignment,
  ConfigurationType.sweepScheduledAssignment,
];

export const SWEEP_DEDUP_MATCHING_TEMPLATE_TYPES = [
  ConfigurationType.sweepDedupe,
  ConfigurationType.sweepMatching,
];

export const ConfigurationToName: { [key in ConfigurationType]: string } = {
  [ConfigurationType.validationRules]: 'Validation Rule',
  [ConfigurationType.workflowRules]: 'Workflow Rule',
  [ConfigurationType.flows]: 'Flow',
  [ConfigurationType.processBuilderFlows]: 'Process Builder',
  [ConfigurationType.approvalProcesses]: 'Approval Process',
  [ConfigurationType.apexTriggers]: 'Apex Trigger',
  [ConfigurationType.apexClasses]: 'Apex Class',
  [ConfigurationType.reports]: 'Report',
  [ConfigurationType.layouts]: 'Layout',
  [ConfigurationType.fields]: 'Field',
  [ConfigurationType.objects]: 'Object',
  [ConfigurationType.emailTemplates]: 'Email Template',
  [ConfigurationType.sweepAutomation]: 'Automation',
  [ConfigurationType.sweepAlert]: 'Alert',
  [ConfigurationType.sweepDedupe]: 'Deduplication',
  [ConfigurationType.sweepMatching]: 'Matching',
  [ConfigurationType.sweepAssignment]: 'Assignment',
  [ConfigurationType.sweepScheduledAssignment]: 'Scheduled Assignment',
  [ConfigurationType.rollups]: 'Rollup',
  [ConfigurationType.cpqData]: 'CPQ Configuration',
  [ConfigurationType.recordTypes]: 'Record Type',
};

export const configurationTypeToIcon: { [key in ConfigurationType]: ReactNode } = {
  [ConfigurationType.fields]: <Fields />,
  [ConfigurationType.apexTriggers]: <Apex />,
  [ConfigurationType.apexClasses]: <Apex />,
  [ConfigurationType.validationRules]: <ValidationRules />,
  [ConfigurationType.workflowRules]: <SalesforceAutomations />,
  [ConfigurationType.flows]: <SalesforceAutomations />,
  [ConfigurationType.processBuilderFlows]: <SalesforceAutomations />,
  [ConfigurationType.approvalProcesses]: <SalesforceAutomations />,
  [ConfigurationType.reports]: <Reporting />,
  [ConfigurationType.layouts]: <Layout />,
  [ConfigurationType.objects]: <Layout />,
  [ConfigurationType.emailTemplates]: <Layout />,
  [ConfigurationType.sweepAutomation]: <Automations />,
  [ConfigurationType.sweepAlert]: <PlaybookAlert />,
  [ConfigurationType.sweepDedupe]: <Target />,
  [ConfigurationType.sweepMatching]: <Target />,
  [ConfigurationType.sweepAssignment]: <Assign />,
  [ConfigurationType.sweepScheduledAssignment]: <Clock />,
  [ConfigurationType.rollups]: <Fields />,
  [ConfigurationType.cpqData]: <CPQ />,
  [ConfigurationType.recordTypes]: <RecordType />,
};

export const CONFIGURATION_ITEMS_FOR_DEPENDS_ON = [
  ConfigurationType.validationRules,
  ConfigurationType.flows,
  ConfigurationType.processBuilderFlows,
  ConfigurationType.apexTriggers,
  ConfigurationType.apexClasses,
  ConfigurationType.approvalProcesses,
  ConfigurationType.workflowRules,
  ConfigurationType.sweepAutomation,
  ConfigurationType.sweepAlert,
  ConfigurationType.sweepDedupe,
  ConfigurationType.sweepMatching,
  ConfigurationType.rollups,
  ConfigurationType.sweepAssignment,
  ConfigurationType.sweepScheduledAssignment,
  ConfigurationType.cpqData,
  ConfigurationType.layouts,
];

export const CONFIGURATIONS_FOR_USED_BY = [
  ConfigurationType.flows,
  ConfigurationType.processBuilderFlows,
  ConfigurationType.apexClasses,
  ConfigurationType.fields,
  ConfigurationType.rollups,
  ConfigurationType.sweepAssignment,
  ConfigurationType.sweepScheduledAssignment,
  ConfigurationType.cpqData,
];

export type NewDependencyProps = SelectedDependency & {
  clearHistory?: boolean;
};

export type ConfigurationTypesWithIcon =
  | ConfigurationType.sweepAlert
  | ConfigurationType.sweepAutomation
  | ConfigurationType.sweepAssignment
  | ConfigurationType.sweepScheduledAssignment;
