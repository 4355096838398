import { Role } from './permissions';

export enum RoleGroupType {
  SYSTEM = 'SYSTEM',
  USER_CREATED = 'USER_CREATED',
}

export interface RoleGroupInterface {
  id: string;
  name: string;
  description?: string;
  type: RoleGroupType;
  roles: Role[];
  createdAt: Date;
  createdById: string;
  updatedAt: Date;
  updatedById?: string;
}

//TODO: connect all of below with real roles when exist
const automationRoles = {
  viewer: 'AutomationViewer',
  admin: 'AutomationAdmin',
  requestToDeploy: 'AutomationRequestToDeploy',
};

const alertsRoles = {
  viewer: 'AlertViewer',
  admin: 'AlertAdmin',
  requestToDeploy: 'AlertRequestToDeploy',
};

const assignmentsRoles = {
  viewer: 'AssignmentsViewer',
  admin: 'AssignmentsAdmin',
  requestToDeploy: 'AssignmentsRequestToDeploy',
};

const dedupeAndMatchingRoles = {
  viewer: 'DedupeAndMatchingViewer',
  admin: 'DedupeAndMatchingAdmin',
  requestToDeploy: 'DedupeAndMatchingRequestToDeploy',
};

const funnelEditorRoles = {
  viewer: 'FunnelEditorViewer',
  admin: 'FunnelEditorAdmin',
  requestToDeploy: 'FunnelEditorRequestToDeploy',
};

const rollupsRoles = {
  viewer: 'RollupViewer',
  admin: 'RollupAdmin',
  requestToDeploy: 'RollupRequestToDeploy',
};

export const roleGroupsManagement = {
  automations: automationRoles,
  alerts: alertsRoles,
  assignments: assignmentsRoles,
  dedupeMatching: dedupeAndMatchingRoles,
  rollups: rollupsRoles,
  funnelEditor: funnelEditorRoles,
};
