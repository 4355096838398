import usePaywall, { PaywallAction, PaywallSource } from '../../../dashboard/header/usePaywall';
import PaywallBlockUpgradeDialog from '../../../dashboard/paywall/PaywallBlockUpgradeDialog';
import UpgradeImage from '../../../dashboard/header/generalUpgradeImage.svg?react';
import PaywallBlockStartTrialDialog from '../../../dashboard/paywall/PaywallBlockStartTrialDialog';

const TITLE = 'Upgrade to deploy Funnels';
const FEATURE_DESCRIPTION = 'deploying funnels directly to Salesforce in one click';

const CanvasDeployPaywallBlock = ({ closeDialog }: { closeDialog: () => void }) => {
  const { paywallAction } = usePaywall({ source: PaywallSource.FUNNEL_DEPLOY });

  if (paywallAction === PaywallAction.NONE) {
    return null;
  }

  if (paywallAction === PaywallAction.START_TRIAL) {
    return (
      <PaywallBlockStartTrialDialog
        closeDialog={closeDialog}
        title={TITLE}
        featureDescription={FEATURE_DESCRIPTION}
        source={PaywallSource.FUNNEL_DEPLOY}
      />
    );
  }

  if (paywallAction === PaywallAction.UPGRADE) {
    return (
      <PaywallBlockUpgradeDialog
        closeDialog={closeDialog}
        img={<UpgradeImage />}
        title={TITLE}
        featureDescription={FEATURE_DESCRIPTION}
      />
    );
  }

  return null;
};

export default CanvasDeployPaywallBlock;
