import { useCallback, useMemo } from 'react';
import { BiEvent } from '../services/events';
import { useUnhappyStates } from '../components/pages/environments/useUnhappyStates';
import useSendBiEvent from './useSendBiEvent';

const useSendBiEventIfConnected = () => {
  const sendBiEvent = useSendBiEvent();
  const { connectionError, noConnectedOrg, isInitialFetch } = useUnhappyStates();

  const sfConnected = useMemo(
    () => !connectionError && !noConnectedOrg && !isInitialFetch,
    [noConnectedOrg, connectionError, isInitialFetch],
  );

  const sendEvent = useCallback(
    (event: BiEvent) => {
      if (sfConnected) {
        sendBiEvent(event);
      }
    },
    [sendBiEvent, sfConnected],
  );

  return sendEvent;
};

export default useSendBiEventIfConnected;
