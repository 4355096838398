import { Permission } from '@server/permissions';
import React, { ReactNode, useMemo } from 'react';

interface DeployButtonContextValue {
  entityNamePlural: string;
  entityName: string;
  permissions: {
    create: Permission;
    edit: Permission;
    deploy: Permission;
    delete: Permission;
  };
}

const DeployButtonContext = React.createContext<
  DeployButtonContextValue & {
    initialized: boolean;
  }
>({
  initialized: false,
  permissions: {
    create: 'create:automations',
    edit: 'edit:automations',
    deploy: 'deploy:automations',
    delete: 'delete:automations',
  },
  entityNamePlural: '',
  entityName: '',
});

export const useDeployButtonContext = () => {
  const context = React.useContext(DeployButtonContext);
  if (!context.initialized) {
    throw new Error(
      'useDeployButtonContext must be used within a DeployButtonContext. Use DeployButtonContextProvider to wrap your component.',
    );
  }
  return context;
};

export const DeployButtonContextProvider = ({
  children,
  value,
}: {
  children: ReactNode;
  value: DeployButtonContextValue;
}) => {
  const _value = useMemo(() => ({ ...value, initialized: true }), [value]);

  return <DeployButtonContext.Provider value={_value}>{children}</DeployButtonContext.Provider>;
};
