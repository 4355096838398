export const isDevEnv = import.meta.env.DEV;
// export const isTestEnv = import.meta.env.TEST;
export const isProdEnv = import.meta.env.PROD;

export const environment = isProdEnv ? 'production' : 'development';

/**
 * isProdEnv flag that is specified in environment lies.
 * Its values is set as env variable during the client-side build.
 * We want to enable bi features to avoid garbage in the tools only in real production.
 */
export const isProdEnvIndeed = () => window.location.host === 'app.sweep.io';
