import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';
import {
  CommentReplyProps,
  CommentThreadProps,
  CommentThreadUpdateRequest,
  MentionedUser,
  FilterByOptions,
  FilterSortState,
  SortByOptions,
} from '../types/Comment';

interface FunnelMapComments {
  data: CommentThreadProps[];
  isResolvedFetched: boolean;
  showResolved: boolean;
}

export interface CommentsState {
  filterSortState: FilterSortState;
  commentThreads: {
    [funnelMapId: string]: FunnelMapComments;
  };
}

const initialState: CommentsState = {
  filterSortState: {
    filterBy: FilterByOptions.SHOW_ALL,
    sortBy: SortByOptions.SORT_BY_DATE,
    searchTerm: '',
  },
  commentThreads: {},
};

export const comments = createSlice({
  name: 'comments',
  reducers: {
    clearCommentsSearchTerm: (state) => {
      state.filterSortState.searchTerm = '';
    },
    createCommentThread: (
      state,
      action: PayloadAction<{ success: boolean; data: CommentThreadProps }>,
    ) => {
      const { data } = action.payload;
      const { funnelMapId, id } = data;
      if (!state.commentThreads[funnelMapId]) {
        state.commentThreads[funnelMapId] = {
          data: [],
          isResolvedFetched: false,
          showResolved: false,
        };
      }
      const existingComment = state.commentThreads[funnelMapId].data.find(
        (thread) => thread.id === id,
      );
      if (existingComment) {
        // a second dispatched action regarding the same comment, meaning this user is the comment's creator, hence isRead is "true" and for the rest its "false"
        existingComment.isRead = true;
        return;
      }
      state.commentThreads[funnelMapId].data.push({ ...data, isRead: false });
    },
    fetchedComments: (
      state,
      action: PayloadAction<{
        funnelMapId: string;
        commentThreads: CommentThreadProps[];
        isResolvedFetched: boolean;
      }>,
    ) => {
      const { funnelMapId, commentThreads, isResolvedFetched } = action.payload;
      state.commentThreads[funnelMapId] = {
        data: commentThreads,
        isResolvedFetched,
        showResolved: false,
      };
    },
    updateCommentThread: (
      state,
      action: PayloadAction<{ success: boolean; data: CommentThreadUpdateRequest }>,
    ) => {
      const { data } = action.payload;
      const { id, funnelMapId, positionX, positionY, isResolved, isRead } = data;
      if (!state.commentThreads[funnelMapId]) {
        return;
      }
      const relevantThreadIndex = findCommentThreadIndex(
        state.commentThreads[funnelMapId].data,
        id,
      );
      if (relevantThreadIndex > -1) {
        const relevantThread = state.commentThreads[funnelMapId].data[relevantThreadIndex];
        state.commentThreads[funnelMapId].data[relevantThreadIndex] = {
          ...relevantThread,
          positionX: positionX ?? relevantThread.positionX,
          positionY: positionY ?? relevantThread.positionY,
          isResolved: isResolved ?? relevantThread.isResolved,
          isRead: isRead ?? relevantThread.isRead,
        };
      }
    },
    createCommentReply: (
      state,
      action: PayloadAction<{
        success: boolean;
        data: {
          commentReply: CommentReplyProps;
          commentThreadId: string;
          funnelMapId: string;
          transientId: string;
          isRead?: boolean;
        };
      }>,
    ) => {
      const { data } = action.payload;
      const { funnelMapId, commentThreadId, commentReply, transientId, isRead } = data;

      //transientId is used to identify this comment that is added in the optimistic action, so it won't be added again when WS message comes
      if (!state.commentThreads[funnelMapId]) {
        return;
      }
      const relevantThread = findCommentThread(
        state.commentThreads[funnelMapId].data,
        commentThreadId,
      );
      if (relevantThread) {
        const existingReplyIndex = relevantThread.commentReplies.findIndex(
          (rep) => rep.transientId === transientId,
        );
        if (existingReplyIndex > -1) {
          // a second dispatched action regarding the same reply, meaning this user is the reply's creator, hence isRead is "true" and for the rest its "false"
          relevantThread.isRead = true;
          relevantThread.commentReplies[existingReplyIndex] = commentReply;
          return;
        }
        relevantThread.isRead = Boolean(isRead);
        relevantThread.commentReplies.push({ ...commentReply, transientId });
      }
    },
    deleteCommentReply: (
      state,
      action: PayloadAction<{
        success: boolean;
        data: { commentReplyId: string; commentThreadId: string; funnelMapId: string };
      }>,
    ) => {
      const { data } = action.payload;
      const { funnelMapId, commentThreadId, commentReplyId } = data;
      if (!state.commentThreads[funnelMapId]) {
        return;
      }
      const relevantThread = findCommentThread(
        state.commentThreads[funnelMapId].data,
        commentThreadId,
      );
      if (relevantThread) {
        relevantThread.commentReplies = relevantThread.commentReplies.filter(
          (rep) => rep.id !== commentReplyId,
        );
      }
    },
    deleteCommentThread: (
      state,
      action: PayloadAction<{
        success: boolean;
        data: { commentThreadId: string; funnelMapId: string };
      }>,
    ) => {
      const { data } = action.payload;
      const { funnelMapId, commentThreadId } = data;
      if (!state.commentThreads[funnelMapId]) {
        return;
      }
      const relevantThreadIndex = findCommentThreadIndex(
        state.commentThreads[funnelMapId].data,
        commentThreadId,
      );
      if (relevantThreadIndex > -1) {
        state.commentThreads[funnelMapId].data.splice(relevantThreadIndex, 1);
      }
    },
    editCommentReply: (
      state,
      action: PayloadAction<{
        success: boolean;
        data: {
          commentReplyId: string;
          commentThreadId: string;
          funnelMapId: string;
          commentBody: string;
          updatedAt?: string;
          mentionedUserIds: MentionedUser[];
        };
      }>,
    ) => {
      const { data } = action.payload;
      const {
        funnelMapId,
        commentThreadId,
        commentReplyId,
        commentBody,
        updatedAt,
        mentionedUserIds,
      } = data;
      if (!state.commentThreads[funnelMapId]) {
        return;
      }
      const relevantThreadIndex = findCommentThreadIndex(
        state.commentThreads[funnelMapId].data,
        commentThreadId,
      );
      if (relevantThreadIndex > -1) {
        const relevantThread = state.commentThreads[funnelMapId].data[relevantThreadIndex];
        const relevantReplyIndex = relevantThread.commentReplies.findIndex(
          (reply) => reply.id === commentReplyId,
        );
        const relevantReply = relevantThread.commentReplies[relevantReplyIndex];
        relevantThread.commentReplies[relevantReplyIndex] = {
          ...relevantReply,
          commentBody,
          mentionedUserIds,
          updatedAt: updatedAt ?? relevantReply.updatedAt,
        };
      }
    },
    setFilter: (state, action: PayloadAction<FilterByOptions>) => {
      state.filterSortState.filterBy = action.payload;
    },
    setSortBy: (state, action: PayloadAction<SortByOptions>) => {
      state.filterSortState.sortBy = action.payload;
    },
    setSearchTerm: (state, action: PayloadAction<string>) => {
      state.filterSortState.searchTerm = action.payload;
    },
    setShowResolved: (
      state,
      action: PayloadAction<{ funnelMapId: string; showResolved: boolean }>,
    ) => {
      const { funnelMapId, showResolved } = action.payload;
      state.commentThreads[funnelMapId].showResolved = showResolved;
    },
  },
  initialState,
});

const findCommentThreadIndex = (comments: CommentThreadProps[], commentId: string) =>
  comments.findIndex((comment) => comment.id === commentId);

const findCommentThread = (comments: CommentThreadProps[], commentId: string) =>
  comments.find((comment) => comment.id === commentId);

export default comments.reducer;
export const {
  clearCommentsSearchTerm,
  createCommentThread,
  fetchedComments,
  updateCommentThread,
  createCommentReply,
  deleteCommentReply,
  editCommentReply,
  deleteCommentThread,
  setFilter,
  setSortBy,
  setShowResolved,
  setSearchTerm,
} = comments.actions;

export const selectCommentsByFunnel = (funnelMapId: string) => (state: RootState) =>
  state.comments.commentThreads[funnelMapId]?.data;

export const selectFilterSortState = (state: RootState) => state.comments.filterSortState;

export const selectShowResolved = (funnelMapId: string) => (state: RootState) =>
  state.comments.commentThreads[funnelMapId]?.showResolved;

export const selectIsResolvedFetched = (funnelMapId: string) => (state: RootState) =>
  state.comments.commentThreads[funnelMapId]?.isResolvedFetched;
