import useSendBiEvent from '../../../../hooks/useSendBiEvent';
import { useCrmOrgs } from '../../environments/useCrmOrgs';
import { useCallback, useState } from 'react';
import { FetchStatus } from '@server/fetch_status.type';
import { ConnectCrmOrgState } from '../../environments/ConnectCrmOrgStateEnum';
import { EnvironmentTypes } from '../../environments/EnvironmentTypeEnum';
import { useRunOnceWhenTruthy } from '../../../common/useRunOnceWhenTruthy';
import { ACTIONS_EVENTS, SIGN_UP_EVENTS, GET_STARTED_EVENTS } from '../../../../services/events';
import { telemetry } from '../../../../telemetry';

export type GetEventToSend = (
  isSandbox: boolean,
) => SIGN_UP_EVENTS | ACTIONS_EVENTS | GET_STARTED_EVENTS;

const useConnectToSf = ({
  getEventToSend,
  onFinish,
  forceProduction,
}: {
  getEventToSend?: GetEventToSend;
  forceProduction: boolean;
  onFinish?: () => void;
}) => {
  const sendBiEvent = useSendBiEvent();
  const { connectOrg } = useCrmOrgs();
  const [connectState, setConnectState] = useState<ConnectCrmOrgState | FetchStatus>(
    ConnectCrmOrgState.NotConnected,
  );
  const [isSandbox, setIsSandbox] = useState(false);

  const setIsSandboxCb = useCallback((value: boolean) => {
    setIsSandbox(value);
  }, []);

  const onSuccess = useCallback(
    (crmOrg: CrmOrg, isSandboxValue: boolean) => {
      const defaultEvent = isSandboxValue
        ? ACTIONS_EVENTS.sfSandboxConnect
        : ACTIONS_EVENTS.sfProdConnect;
      const eventToSend = getEventToSend ? getEventToSend(isSandboxValue) : defaultEvent;
      sendBiEvent({
        name: eventToSend,
        props: {
          instanceUrl: crmOrg.instanceUrl,
          connectedUser: crmOrg.connectedUser,
        },
      });
      onFinish && onFinish();
      setConnectState(crmOrg.fetchStatus);
    },
    [getEventToSend, onFinish, sendBiEvent],
  );

  const onConnectStartHandler = useCallback(
    async ({
      isSandboxValue,
      forceMain,
      isDevelopment,
    }: {
      isSandboxValue: boolean;
      forceMain?: boolean;
      isDevelopment?: boolean;
    }) => {
      try {
        const isSandboxValueToUse = isSandboxValue ?? isSandbox;
        setConnectState(ConnectCrmOrgState.Connecting);
        const crmOrg = await connectOrg(
          null,
          isSandboxValueToUse
            ? EnvironmentTypes.Sandbox
            : isDevelopment
              ? EnvironmentTypes.Development
              : EnvironmentTypes.Production,
          forceMain,
        );
        if (crmOrg.isConnected) {
          onSuccess(crmOrg, isSandboxValueToUse);
        } else {
          setConnectState(ConnectCrmOrgState.ErrorConnecting);
        }
      } catch (error) {
        telemetry.captureError(error);
      }
    },
    [isSandbox, connectOrg, onSuccess],
  );

  //If we should connect Production, can skip the first step
  useRunOnceWhenTruthy(() => {
    onConnectStartHandler({ isSandboxValue: false });
  }, forceProduction);

  return { connectState, isSandbox, setIsSandboxCb, onConnectStartHandler };
};

export default useConnectToSf;
