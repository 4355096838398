import { Box } from '@mui/material';
import dependenciesBlurredImage from './dependenciesBlurredImage.png';
import upgradeImage from './upgradeImage.png';
import usePaywall, { PaywallAction, PaywallSource } from '../../dashboard/header/usePaywall';
import PaywallBlockUpgrade from '../../dashboard/paywall/PaywallBlockUpgrade';
import PaywallBlockStartTrial from '../../dashboard/paywall/PaywallBlockStartTrial';

const TITLE = 'Upgrade to access custom object Documentation';
const FEATURE_DESCRIPTION = 'AI-powered documentation for any Salesforce object';

const DependenciesPaywallBlock = ({ objectName }: { objectName?: string }) => {
  const { paywallAction } = usePaywall({ source: PaywallSource.DEPENDENCIES, objectName });

  if (paywallAction === PaywallAction.NONE) {
    return null;
  }
  return (
    <Box sx={{ overflow: 'hidden', position: 'relative' }}>
      <img src={dependenciesBlurredImage} width={'100%'} alt="Dependencies paywall image" />
      <Box
        sx={{
          position: 'absolute',
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          top: '20%',
          padding: '0 74px',
        }}
      >
        {paywallAction === PaywallAction.START_TRIAL && (
          <PaywallBlockStartTrial
            title={TITLE}
            featureDescription={FEATURE_DESCRIPTION}
            source={PaywallSource.DEPENDENCIES}
          />
        )}
        {paywallAction === PaywallAction.UPGRADE && (
          <PaywallBlockUpgrade
            title={TITLE}
            featureDescription={FEATURE_DESCRIPTION}
            img={<img src={upgradeImage} width="148px" alt="Dependencies paywall image" />}
          />
        )}
      </Box>
    </Box>
  );
};

export default DependenciesPaywallBlock;
